// SortableList.js

import React, { useState } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import {
  List,
  ListItem,
  ListItemText,
  IconButton,
  ListItemSecondaryAction,
  Box,
  Chip,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  Typography,
  TextField,
  Button,
} from '@mui/material';
import { Delete as DeleteIcon } from '@mui/icons-material';

function SortableList({
  items,
  onDragEnd,
  onDelete,
  onAddItem,
  renderItem,
  isChip = false,
  matchingOption,
  onMatchingOptionChange,
  showMatchingOptions = false,
}) {
  const [newItemValue, setNewItemValue] = useState('');

  return (
    <Box>
      {showMatchingOptions && (
        <Box sx={{ mb: 2 }}>
          <Typography variant="subtitle1" gutterBottom>
            マッチングの動作を選択してください。
          </Typography>
          <FormControl component="fieldset">
            <RadioGroup value={matchingOption} onChange={onMatchingOptionChange}>
              <FormControlLabel value="first" control={<Radio />} label="マッチした最初の値のみを適用" />
              <FormControlLabel value="priority" control={<Radio />} label="優先順位に基づいて適用" />
              <FormControlLabel value="all" control={<Radio />} label="マッチしたすべての値を適用" />
            </RadioGroup>
          </FormControl>
        </Box>
      )}
      {/* 新しいアイテムの追加 */}
      <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
        <TextField
          label="新しい値"
          value={newItemValue}
          onChange={(e) => setNewItemValue(e.target.value)}
          size="small"
        />
        <Button
          variant="contained"
          onClick={() => {
            if (newItemValue.trim()) {
              onAddItem(newItemValue.trim());
              setNewItemValue('');
            }
          }}
          sx={{ ml: 2 }}
        >
          追加
        </Button>
      </Box>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable-list">
          {(provided) => (
            <Box
              ref={provided.innerRef}
              {...provided.droppableProps}
              sx={{ maxHeight: '400px', overflowY: 'auto' }}
            >
              {items.map((item, index) => (
                <Draggable
                  key={item.id || item.value || index}
                  draggableId={item.id || item.value || index.toString()}
                  index={index}
                >
                  {(providedDraggable) => (
                    isChip ? (
                      <Chip
                        ref={providedDraggable.innerRef}
                        {...providedDraggable.draggableProps}
                        {...providedDraggable.dragHandleProps}
                        label={`${item.label || item.value} (優先度: ${index + 1})`}
                        onDelete={() => onDelete(index)}
                        sx={{ margin: '4px' }}
                      />
                    ) : (
                      <ListItem
                        ref={providedDraggable.innerRef}
                        {...providedDraggable.draggableProps}
                        {...providedDraggable.dragHandleProps}
                      >
                        {renderItem ? renderItem(item, index) : (
                          <ListItemText
                            primary={`${item.primary} (優先度: ${index + 1})`}
                            secondary={item.secondary}
                          />
                        )}
                        <ListItemSecondaryAction>
                          <IconButton edge="end" onClick={() => onDelete(item.id)}>
                            <DeleteIcon />
                          </IconButton>
                        </ListItemSecondaryAction>
                      </ListItem>
                    )
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </Box>
          )}
        </Droppable>
      </DragDropContext>
    </Box>
  );
}

export default SortableList;
