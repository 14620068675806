// ColumnSettingsManager.js

import React, { useState } from 'react';
import { DragDropContext, Droppable, Draggable } from '@hello-pangea/dnd';
import {
  Box,
  Button,
  TextField,
  Select,
  MenuItem,
  Typography,
  IconButton,
  Paper,
  Grid,
  InputLabel,
  FormControl,
} from '@mui/material';
import { Delete as DeleteIcon, Edit as EditIcon, Save as SaveIcon } from '@mui/icons-material';

function ColumnSettingsManager({
  columns,
  columnOrderTemplates,
  setColumnOrderTemplates,
  applyColumnTemplate,
  selectedTemplateName,
  setSelectedTemplateName,
  setColumnOrder,
  columnWidth,
  setColumnWidth,
}) {
  // カラム順序テンプレート管理のための状態と関数
  const [newTemplateName, setNewTemplateName] = useState('');
  const [editTemplateName, setEditTemplateName] = useState('');
  const [editColumnOrder, setEditColumnOrder] = useState([]);

  // テンプレートの追加
  const addTemplate = () => {
    if (newTemplateName && !columnOrderTemplates[newTemplateName]) {
      setColumnOrderTemplates({
        ...columnOrderTemplates,
        [newTemplateName]: columns,
      });
      setNewTemplateName('');
    }
  };

  // テンプレートの削除
  const deleteTemplate = (templateName) => {
    const updatedTemplates = { ...columnOrderTemplates };
    delete updatedTemplates[templateName];
    setColumnOrderTemplates(updatedTemplates);
    if (selectedTemplateName === templateName) {
      setSelectedTemplateName('');
      applyColumnTemplate(''); // デフォルトのカラム順序に戻す
    }
  };

  // テンプレートの編集開始
  const startEditTemplate = (templateName) => {
    setEditTemplateName(templateName);
    setEditColumnOrder(columnOrderTemplates[templateName]);
  };

  // テンプレートの編集適用
  const applyEditTemplateChanges = () => {
    // テンプレートを更新
    setColumnOrderTemplates({
      ...columnOrderTemplates,
      [editTemplateName]: editColumnOrder,
    });
    // 編集モードを終了
    setEditTemplateName('');
    setEditColumnOrder([]);

    // 直接カラム順序を更新
    const newColumnOrder = editColumnOrder.filter((col) => columns.includes(col));
    const remainingColumns = columns.filter((col) => !newColumnOrder.includes(col));
    setColumnOrder([...newColumnOrder, ...remainingColumns]);
  };

  // カラム順序の編集（ドラッグ＆ドロップを使用）
  const onDragEnd = (result) => {
    if (!result.destination) return;
    const newOrder = Array.from(editColumnOrder);
    const [movedItem] = newOrder.splice(result.source.index, 1);
    newOrder.splice(result.destination.index, 0, movedItem);
    setEditColumnOrder(newOrder);
  };

  // カラム幅の変更
  const handleColumnWidthChange = (e) => {
    setColumnWidth(Number(e.target.value));
  };

  return (
    <Paper elevation={3} sx={{ padding: 1, marginBottom: 2 }}>
      <Typography variant="h7" gutterBottom>
        カラム設定マネージャー
      </Typography>

      {/* Gridコンテナで2つの機能を横並びに配置 */}
      <Grid container spacing={2} alignItems="flex-start">
        {/* カラム幅の設定（全12のうち2を使用） */}
        <Grid item xs={12} md={2}>
          <FormControl variant="outlined" fullWidth size="small">
            <TextField
              label="カラム幅 (px)"
              type="number"
              value={columnWidth}
              onChange={handleColumnWidthChange}
              InputProps={{ inputProps: { min: 50 } }}
              size="small"
            />
          </FormControl>
        </Grid>

        {/* カラム順序テンプレートの管理（残りを使用） */}
        <Grid item xs={12} md={10}>
          <Box>
            {/* 新しいテンプレートの追加 */}
            <Grid container spacing={1} alignItems="center">
              <Grid item xs={12} sm={6}>
                <TextField
                  label="新しいテンプレート名"
                  value={newTemplateName}
                  onChange={(e) => setNewTemplateName(e.target.value)}
                  fullWidth
                  size="small"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={addTemplate}
                  fullWidth
                  size="small"
                >
                  追加
                </Button>
              </Grid>
            </Grid>

            {/* テンプレートの選択 */}
            <Box sx={{ marginTop: 2 }}>
              <FormControl variant="outlined" fullWidth size="small">
                <InputLabel id="template-select-label">テンプレートを選択</InputLabel>
                <Select
                  labelId="template-select-label"
                  value={selectedTemplateName}
                  onChange={(e) => {
                    const templateName = e.target.value;
                    setSelectedTemplateName(templateName);
                    applyColumnTemplate(templateName);
                  }}
                  label="テンプレートを選択"
                  size="small"
                >
                  <MenuItem value="">
                    <em>なし</em>
                  </MenuItem>
                  {Object.keys(columnOrderTemplates).map((templateName) => (
                    <MenuItem key={templateName} value={templateName}>
                      {templateName}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>

            {/* 編集・削除ボタン */}
            {selectedTemplateName && (
              <Box sx={{ marginTop: 1, textAlign: 'right' }}>
                <Button
                  variant="outlined"
                  color="primary"
                  startIcon={<EditIcon />}
                  onClick={() => startEditTemplate(selectedTemplateName)}
                  size="small"
                  sx={{ marginRight: 1 }}
                >
                  編集
                </Button>
                <Button
                  variant="outlined"
                  color="secondary"
                  startIcon={<DeleteIcon />}
                  onClick={() => deleteTemplate(selectedTemplateName)}
                  size="small"
                >
                  削除
                </Button>
              </Box>
            )}
          </Box>
        </Grid>
      </Grid>

      {/* テンプレートの編集 */}
      {editTemplateName && (
        <Box sx={{ marginTop: 2 }}>
          <Typography variant="subtitle1">テンプレートの編集: {editTemplateName}</Typography>
          <Paper
            variant="outlined"
            sx={{
              padding: 1,
              marginTop: 1,
              overflowX: 'auto',
            }}
          >
            <DragDropContext onDragEnd={onDragEnd}>
              <Droppable droppableId="editColumns" direction="horizontal">
                {(provided) => (
                  <Box
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                    sx={{
                      display: 'flex',
                      padding: 1,
                      minHeight: '50px',
                    }}
                  >
                    {editColumnOrder.map((colKey, index) => (
                      <Draggable key={colKey} draggableId={colKey} index={index}>
                        {(provided) => (
                          <Paper
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            sx={{
                              padding: 1,
                              marginRight: 1,
                              minWidth: '60px',
                              textAlign: 'center',
                              cursor: 'move',
                            }}
                          >
                            {colKey}
                          </Paper>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </Box>
                )}
              </Droppable>
            </DragDropContext>
          </Paper>
          <Box sx={{ marginTop: 1, textAlign: 'right' }}>
            <Button
              variant="contained"
              color="primary"
              startIcon={<SaveIcon />}
              onClick={applyEditTemplateChanges}
              size="small"
              sx={{ marginRight: 1 }}
            >
              保存
            </Button>
            <Button
              variant="outlined"
              onClick={() => setEditTemplateName('')}
              size="small"
            >
              キャンセル
            </Button>
          </Box>
        </Box>
      )}
    </Paper>
  );
}

export default ColumnSettingsManager;





// // ColumnSettingsManager.js

// import React, { useState } from 'react';
// import { DragDropContext, Droppable, Draggable } from '@hello-pangea/dnd';

// function ColumnSettingsManager({
//   columns,
//   columnOrderTemplates,
//   setColumnOrderTemplates,
//   applyColumnTemplate,
//   selectedTemplateName,
//   setSelectedTemplateName,
//   setColumnOrder,
//   columnWidth,
//   setColumnWidth,
// }) {
//   // カラム順序テンプレート管理のための状態と関数
//   const [newTemplateName, setNewTemplateName] = useState('');
//   const [editTemplateName, setEditTemplateName] = useState('');
//   const [editColumnOrder, setEditColumnOrder] = useState([]);

//   // テンプレートの追加
//   const addTemplate = () => {
//     if (newTemplateName && !columnOrderTemplates[newTemplateName]) {
//       setColumnOrderTemplates({
//         ...columnOrderTemplates,
//         [newTemplateName]: columns,
//       });
//       setNewTemplateName('');
//     }
//   };

//   // テンプレートの削除
//   const deleteTemplate = (templateName) => {
//     const updatedTemplates = { ...columnOrderTemplates };
//     delete updatedTemplates[templateName];
//     setColumnOrderTemplates(updatedTemplates);
//     if (selectedTemplateName === templateName) {
//       setSelectedTemplateName('');
//       applyColumnTemplate(''); // デフォルトのカラム順序に戻す
//     }
//   };

//   // テンプレートの編集開始
//   const startEditTemplate = (templateName) => {
//     setEditTemplateName(templateName);
//     setEditColumnOrder(columnOrderTemplates[templateName]);
//   };

//   // テンプレートの編集適用
//   const applyEditTemplateChanges = () => {
//     // テンプレートを更新
//     setColumnOrderTemplates({
//       ...columnOrderTemplates,
//       [editTemplateName]: editColumnOrder,
//     });
//     // 編集モードを終了
//     setEditTemplateName('');
//     setEditColumnOrder([]);

//     // 直接カラム順序を更新
//     const newColumnOrder = editColumnOrder.filter((col) => columns.includes(col));
//     const remainingColumns = columns.filter((col) => !newColumnOrder.includes(col));
//     setColumnOrder([...newColumnOrder, ...remainingColumns]);
//   };

//   // カラム順序の編集（ドラッグ＆ドロップを使用）
//   const onDragEnd = (result) => {
//     if (!result.destination) return;
//     const newOrder = Array.from(editColumnOrder);
//     const [movedItem] = newOrder.splice(result.source.index, 1);
//     newOrder.splice(result.destination.index, 0, movedItem);
//     setEditColumnOrder(newOrder);
//   };

//   // カラム幅の変更
//   const handleColumnWidthChange = (e) => {
//     setColumnWidth(Number(e.target.value));
//   };

//   return (
//     <div style={{ marginBottom: '20px' }}>
//       <h3>カラム設定マネージャー</h3>

//       {/* カラム幅の設定 */}
//       <div style={{ marginBottom: '20px' }}>
//         <label htmlFor="columnWidthInput">カラムの幅 (px): </label>
//         <input
//           id="columnWidthInput"
//           type="number"
//           value={columnWidth}
//           onChange={handleColumnWidthChange}
//           style={{ width: '60px', marginLeft: '10px' }}
//         />
//       </div>

//       {/* カラム順序テンプレートの管理 */}
//       <div style={{ marginBottom: '20px' }}>
//         <h4>カラム順序テンプレートの管理</h4>
//         <div style={{ marginBottom: '10px' }}>
//           <input
//             type="text"
//             value={newTemplateName}
//             onChange={(e) => setNewTemplateName(e.target.value)}
//             placeholder="新しいテンプレート名"
//             style={{ marginRight: '10px' }}
//           />
//           <button onClick={addTemplate}>追加</button>
//         </div>
//         <div style={{ marginBottom: '10px' }}>
//           <label htmlFor="columnTemplateSelect">テンプレートを選択: </label>
//           <select
//             id="columnTemplateSelect"
//             value={selectedTemplateName}
//             onChange={(e) => {
//               const templateName = e.target.value;
//               setSelectedTemplateName(templateName);
//               applyColumnTemplate(templateName);
//             }}
//             style={{ marginLeft: '10px' }}
//           >
//             <option value="">テンプレートを選択</option>
//             {Object.keys(columnOrderTemplates).map((templateName) => (
//               <option key={templateName} value={templateName}>
//                 {templateName}
//               </option>
//             ))}
//           </select>
//         </div>
//         {selectedTemplateName && (
//           <div style={{ marginBottom: '10px' }}>
//             <button
//               onClick={() => startEditTemplate(selectedTemplateName)}
//               style={{ marginRight: '10px' }}
//             >
//               編集
//             </button>
//             <button onClick={() => deleteTemplate(selectedTemplateName)}>削除</button>
//           </div>
//         )}
//         {editTemplateName && (
//           <div style={{ marginTop: '20px' }}>
//             <h4>テンプレートの編集: {editTemplateName}</h4>
//             <DragDropContext onDragEnd={onDragEnd}>
//               <Droppable droppableId="editColumns" direction="horizontal">
//                 {(provided) => (
//                   <ul
//                     ref={provided.innerRef}
//                     {...provided.droppableProps}
//                     style={{
//                       display: 'flex', // 横並びに変更
//                       listStyleType: 'none',
//                       padding: '10px',
//                       margin: '0',
//                       border: '1px solid #ccc',
//                       borderRadius: '4px',
//                       overflowX: 'auto', // 横スクロールを有効化
//                     }}
//                   >
//                     {editColumnOrder.map((colKey, index) => (
//                       <Draggable key={colKey} draggableId={colKey} index={index}>
//                         {(provided) => (
//                           <li
//                             ref={provided.innerRef}
//                             {...provided.draggableProps}
//                             {...provided.dragHandleProps}
//                             style={{
//                               padding: '8px',
//                               margin: '0 4px', // 横方向の間隔
//                               border: '1px solid #eee',
//                               backgroundColor: '#fafafa',
//                               borderRadius: '4px',
//                               minWidth: '100px',
//                               textAlign: 'center',
//                               ...provided.draggableProps.style,
//                             }}
//                           >
//                             {colKey}
//                           </li>
//                         )}
//                       </Draggable>
//                     ))}
//                     {provided.placeholder}
//                   </ul>
//                 )}
//               </Droppable>
//             </DragDropContext>
//             <div style={{ marginTop: '10px' }}>
//               <button
//                 onClick={applyEditTemplateChanges}
//                 style={{ marginRight: '10px' }}
//               >
//                 保存
//               </button>
//               <button onClick={() => setEditTemplateName('')}>キャンセル</button>
//             </div>
//           </div>
//         )}
//       </div>
//     </div>
//   );
// }

// export default ColumnSettingsManager;
