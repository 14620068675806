// descriptionCache.js

// キャッシュの管理
let descriptionCache = {};

// キャッシュをローカルストレージに保存
export function saveDescriptionCacheToLocalStorage() {
  localStorage.setItem('generatedDescriptions', JSON.stringify(descriptionCache));
}

// キャッシュをローカルストレージから読み込み
export function loadDescriptionCacheFromLocalStorage() {
  const savedCache = localStorage.getItem('generatedDescriptions');
  if (savedCache) {
    descriptionCache = JSON.parse(savedCache);
  }
}

// キャッシュから説明を取得
export function getCachedDescription(cacheKey) {
  return descriptionCache[cacheKey];
}

// キャッシュに説明を保存
export function setCachedDescription(cacheKey, generatedDescription) {
  descriptionCache[cacheKey] = generatedDescription;
  saveDescriptionCacheToLocalStorage();
}

// キャッシュをクリア
export function clearDescriptionCache() {
  descriptionCache = {};
  saveDescriptionCacheToLocalStorage();
}
