// ProductFilter.js

import React, { useState, useEffect } from 'react';
import {
  TextField,
  Button,
  FormControlLabel,
  Checkbox,
  Box,
  Typography,
  RadioGroup,
  Radio,
  FormControl,
  FormLabel,
} from '@mui/material';

const ProductFilter = ({ products, setFilteredProducts }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [showDuplicates, setShowDuplicates] = useState(false);
  const [duplicateTarget, setDuplicateTarget] = useState('both'); // 重複チェック対象
  const [filterTarget, setFilterTarget] = useState('both'); // フィルター対象

  // フィルタリングを適用
  useEffect(() => {
    let filtered = [...products];

    // 指定のワードが含まれている商品を検索
    if (searchTerm.trim() !== '') {
      filtered = filtered.filter((product) => {
        const title = product.title.toLowerCase();
        const generatedTitle = product.generatedTitle.toLowerCase();
        const term = searchTerm.toLowerCase();

        if (filterTarget === 'original') {
          return title.includes(term);
        } else if (filterTarget === 'generated') {
          return generatedTitle.includes(term);
        } else {
          // both
          return title.includes(term) || generatedTitle.includes(term);
        }
      });
    }

    // 重複するタイトルを表示
    if (showDuplicates) {
      const titleCounts = {};
      filtered.forEach((product) => {
        let titleToCheck = '';
        if (duplicateTarget === 'original') {
          titleToCheck = product.title.toLowerCase();
        } else if (duplicateTarget === 'generated') {
          titleToCheck = product.generatedTitle.toLowerCase();
        } else {
          // both
          titleToCheck = (product.generatedTitle || product.title).toLowerCase();
        }

        if (titleToCheck) {
          titleCounts[titleToCheck] = (titleCounts[titleToCheck] || 0) + 1;
        }
      });

      filtered = filtered.filter((product) => {
        let titleToCheck = '';
        if (duplicateTarget === 'original') {
          titleToCheck = product.title.toLowerCase();
        } else if (duplicateTarget === 'generated') {
          titleToCheck = product.generatedTitle.toLowerCase();
        } else {
          // both
          titleToCheck = (product.generatedTitle || product.title).toLowerCase();
        }
        return titleCounts[titleToCheck] > 1;
      });
    }

    setFilteredProducts(filtered);
  }, [searchTerm, showDuplicates, duplicateTarget, filterTarget, products, setFilteredProducts]);

  // フィルターをリセット
  const handleReset = () => {
    setSearchTerm('');
    setShowDuplicates(false);
    setDuplicateTarget('both');
    setFilterTarget('both');
    setFilteredProducts(products); // 全商品を表示
  };

  return (
    <Box sx={{ p: 2 }}>
      <Typography variant="h6">フィルター</Typography>
      <TextField
        label="検索ワード"
        variant="outlined"
        fullWidth
        margin="normal"
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        placeholder="タイトル内のワードで検索"
      />

      {/* フィルター対象の選択 */}
      <FormControl component="fieldset" sx={{ mt: 2 }}>
        <FormLabel component="legend">フィルター対象</FormLabel>
        <RadioGroup
          aria-label="filter-target"
          name="filter-target"
          value={filterTarget}
          onChange={(e) => setFilterTarget(e.target.value)}
        >
          <FormControlLabel value="original" control={<Radio />} label="元のタイトル" />
          <FormControlLabel value="generated" control={<Radio />} label="生成されたタイトル" />
          <FormControlLabel value="both" control={<Radio />} label="両方" />
        </RadioGroup>
      </FormControl>

      {/* 重複チェック */}
      <FormControlLabel
        control={
          <Checkbox
            checked={showDuplicates}
            onChange={(e) => setShowDuplicates(e.target.checked)}
          />
        }
        label="重複するタイトルを表示"
        sx={{ mt: 2 }}
      />

      {/* 重複チェック対象の選択 */}
      {showDuplicates && (
        <FormControl component="fieldset" sx={{ mt: 2 }}>
          <FormLabel component="legend">重複チェック対象</FormLabel>
          <RadioGroup
            aria-label="duplicate-target"
            name="duplicate-target"
            value={duplicateTarget}
            onChange={(e) => setDuplicateTarget(e.target.value)}
          >
            <FormControlLabel value="original" control={<Radio />} label="元のタイトル" />
            <FormControlLabel value="generated" control={<Radio />} label="生成されたタイトル" />
            <FormControlLabel value="both" control={<Radio />} label="両方" />
          </RadioGroup>
        </FormControl>
      )}

      {/* フィルターをリセット */}
      <Button
        variant="outlined"
        color="primary"
        onClick={handleReset}
        sx={{ mt: 2 }}
      >
        フィルターをリセット
      </Button>
    </Box>
  );
};

export default ProductFilter;







// // ProductFilter.js

// import React, { useState, useEffect } from 'react';
// import {
//   TextField,
//   Button,
//   FormControlLabel,
//   Checkbox,
//   Box,
//   Typography,
// } from '@mui/material';

// const ProductFilter = ({ products, setFilteredProducts }) => {
//   const [searchTerm, setSearchTerm] = useState('');
//   const [showDuplicates, setShowDuplicates] = useState(false);
//   const [sortAlphabetically, setSortAlphabetically] = useState(false);

//   // フィルタリングとソートを適用
//   useEffect(() => {
//     let filtered = [...products];

//     // 指定のワードが含まれている商品を検索
//     if (searchTerm.trim() !== '') {
//       filtered = filtered.filter(
//         (product) =>
//           product.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
//           product.generatedTitle.toLowerCase().includes(searchTerm.toLowerCase())
//       );
//     }

//     // タイトルの重複を探す
//     if (showDuplicates) {
//       const titleCounts = {};
//       filtered.forEach((product) => {
//         const title = product.generatedTitle || product.title;
//         if (title) {
//           titleCounts[title] = (titleCounts[title] || 0) + 1;
//         }
//       });
//       filtered = filtered.filter((product) => {
//         const title = product.generatedTitle || product.title;
//         return titleCounts[title] > 1;
//       });
//     }

//     // アルファベット順に並び替え
//     if (sortAlphabetically) {
//       filtered.sort((a, b) => {
//         const titleA = (a.generatedTitle || a.title || '').toLowerCase();
//         const titleB = (b.generatedTitle || b.title || '').toLowerCase();
//         if (titleA < titleB) return -1;
//         if (titleA > titleB) return 1;
//         return 0;
//       });
//     }

//     setFilteredProducts(filtered);
//   }, [searchTerm, showDuplicates, sortAlphabetically, products, setFilteredProducts]);

//   return (
//     <Box sx={{ p: 2 }}>
//       <Typography variant="h6">フィルターとソート</Typography>
//       <TextField
//         label="検索ワード"
//         variant="outlined"
//         fullWidth
//         margin="normal"
//         value={searchTerm}
//         onChange={(e) => setSearchTerm(e.target.value)}
//         placeholder="タイトル内のワードで検索"
//       />
//       <FormControlLabel
//         control={
//           <Checkbox
//             checked={showDuplicates}
//             onChange={(e) => setShowDuplicates(e.target.checked)}
//           />
//         }
//         label="重複するタイトルを表示"
//       />
//       <FormControlLabel
//         control={
//           <Checkbox
//             checked={sortAlphabetically}
//             onChange={(e) => setSortAlphabetically(e.target.checked)}
//           />
//         }
//         label="アルファベット順に並び替え"
//       />
//       {/* リセットボタンを追加 */}
//       <Button
//         variant="outlined"
//         color="primary"
//         onClick={() => {
//           setSearchTerm('');
//           setShowDuplicates(false);
//           setSortAlphabetically(false);
//         }}
//         sx={{ mt: 2 }}
//       >
//         フィルターをリセット
//       </Button>
//     </Box>
//   );
// };

// export default ProductFilter;
