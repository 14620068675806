// ImageOperations.js

import React, { useState, useCallback, useEffect, useRef } from 'react';
import {
  Button,
  Typography,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Paper,
  Grid,
  IconButton,
  DialogContentText,
  TextField,
  MenuItem,
  Select,
  FormControl,
  Pagination,
  CircularProgress,
  Tooltip,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import CloseIcon from '@mui/icons-material/Close';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import DescriptionIcon from '@mui/icons-material/Description';
import { DragDropContext, Droppable, Draggable } from '@hello-pangea/dnd';
import {
  getStorage,
  ref as storageRef,
  uploadBytes,
  getDownloadURL,
} from 'firebase/storage';
import { v4 as uuidv4 } from 'uuid';
import axios from 'axios';
import performImageAnalysis from './ImageAnalysis'; // 画像解析関数をインポート
import ImageAnalysis from './ImageAnalysis';


function ImageOperations({
  data,
  setData,
  startRow,
  endRow,
  calculateMaxEndRow,
  isProcessing,
  setIsProcessing,
  setError,
  showSnackbar,
  openPreviewDialog,
  setOpenPreviewDialog,
  apiKey, // OpenAIのAPIキー
}) {
  const [previewImages, setPreviewImages] = useState([]);
  const [displayedPreviewImages, setDisplayedPreviewImages] = useState([]);
  const [selectedImages, setSelectedImages] = useState(new Set());
  const [selectedProductForEdit, setSelectedProductForEdit] = useState(null);
  const [isAddImageDialogOpen, setIsAddImageDialogOpen] = useState(false);
  const [newImageFile, setNewImageFile] = useState(null);
  const [newImageURL, setNewImageURL] = useState('');
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [openProductDeleteConfirmDialog, setOpenProductDeleteConfirmDialog] = useState(false);
  const [productToDelete, setProductToDelete] = useState(null);

  const [itemsPerPage, setItemsPerPage] = useState(50); // 1ページあたりの表示件数
  const [currentPage, setCurrentPage] = useState(1); // 現在のページ番号

  const [previewDialogMode, setPreviewDialogMode] = useState('edit'); // 'edit' または 'analyze'
  const [isAnalyzing, setIsAnalyzing] = useState(false);

  const theme = useTheme();

  // スクロール可能なコンテナのrefを追加
  const scrollableContainerRef = useRef(null);
  const [showScrollTopButton, setShowScrollTopButton] = useState(false); // スクロールトップボタンの表示制御

  const handleOpenPreviewDialog = useCallback(() => {
    try {
      if (!startRow || !endRow || isNaN(startRow) || isNaN(endRow)) {
        throw new Error('Start RowとEnd Rowは有効な数字である必要があります。');
      }

      const picUrlKey = Object.keys(data[0]).find(
        (key) => key.toLowerCase() === 'picurl'
      );
      const titleKey = Object.keys(data[0]).find(
        (key) => key.toLowerCase() === 'title'
      );

      if (!picUrlKey) {
        throw new Error('PicURLカラムが見つかりません。');
      }

      const actualStartRow = parseInt(startRow) - 1; // インデックスは0から開始
      const maxEndRow = calculateMaxEndRow();
      const actualEndRow = Math.min(parseInt(endRow) - 1, maxEndRow - 1);

      if (actualStartRow < 0 || actualEndRow < actualStartRow) {
        throw new Error('Start RowとEnd Rowが有効ではありません。');
      }

      let previewItems = [];

      // すべての画像を取得
      for (let i = actualStartRow; i <= actualEndRow; i++) {
        const item = data[i];
        const picUrl = item[picUrlKey];
        const imageUrls = picUrl ? picUrl.split('|') : [];
        const title = item[titleKey] || `商品 ${i + 1}`;
        const images = imageUrls.map((url, idx) => ({
          url,
          imageIndex: idx + 1,
        }));
        if (images.length > 0) {
          previewItems.push({
            title,
            rowIndex: i,
            images,
          });
        }
      }

      setPreviewImages(previewItems);
      setSelectedImages(new Set()); // すべて未選択
      setCurrentPage(1); // ページをリセット
    } catch (err) {
      const errorMessage = `プレビューの準備中にエラーが発生しました: ${err.message}`;
      setError(errorMessage);
      console.error(errorMessage, err);
    }
  }, [data, startRow, endRow, calculateMaxEndRow, setError]);

  // openPreviewDialogがtrueになったときにプレビューを準備
  useEffect(() => {
    if (openPreviewDialog) {
      handleOpenPreviewDialog();
    } else {
      // ダイアログが閉じられたときにプレビュー画像をクリア
      setPreviewImages([]);
      setSelectedImages(new Set());
    }
  }, [openPreviewDialog, handleOpenPreviewDialog]);

  // ページネーションの処理
  useEffect(() => {
    if (itemsPerPage === 'ALL') {
      setDisplayedPreviewImages(previewImages);
    } else {
      const startIndex = (currentPage - 1) * itemsPerPage;
      const endIndex = startIndex + itemsPerPage;
      setDisplayedPreviewImages(previewImages.slice(startIndex, endIndex));
    }
  }, [previewImages, currentPage, itemsPerPage]);

  // currentPageが変更されたときにスクロール位置をトップに戻す
  useEffect(() => {
    if (scrollableContainerRef.current) {
      scrollableContainerRef.current.scrollTop = 0;
    }
  }, [currentPage]);

  // スクロール位置に応じてスクロールトップボタンの表示を制御
  useEffect(() => {
    const handleScroll = () => {
      if (scrollableContainerRef.current) {
        const scrollTop = scrollableContainerRef.current.scrollTop;
        setShowScrollTopButton(scrollTop > 200);
      }
    };

    const container = scrollableContainerRef.current;
    if (container) {
      container.addEventListener('scroll', handleScroll);
      return () => container.removeEventListener('scroll', handleScroll);
    }
  }, [scrollableContainerRef]);

  const totalPages = itemsPerPage === 'ALL' ? 1 : Math.ceil(previewImages.length / itemsPerPage);

  const handleItemsPerPageChange = (event) => {
    const value = event.target.value === 'ALL' ? 'ALL' : parseInt(event.target.value);
    setItemsPerPage(value);
    setCurrentPage(1); // ページをリセット
  };

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  const toggleImageSelection = (url) => {
    setSelectedImages((prevSelected) => {
      const newSelected = new Set(prevSelected);
      if (newSelected.has(url)) {
        newSelected.delete(url);
      } else {
        newSelected.add(url);
      }
      return newSelected;
    });
  };

  const handleDelete = useCallback(async () => {
    setIsProcessing(true);
    setError('');

    try {
      if (!startRow || !endRow || isNaN(startRow) || isNaN(endRow)) {
        throw new Error('Start RowとEnd Rowは有効な数字である必要があります。');
      }

      const picUrlKey = Object.keys(data[0]).find(
        (key) => key.toLowerCase() === 'picurl'
      );

      if (!picUrlKey) {
        throw new Error('PicURLカラムが見つかりません。');
      }

      const actualStartRow = parseInt(startRow) - 1; // インデックスは0から開始
      const maxEndRow = calculateMaxEndRow();
      const actualEndRow = Math.min(parseInt(endRow) - 1, maxEndRow - 1);

      if (actualStartRow < 0 || actualEndRow < actualStartRow) {
        throw new Error('Start RowとEnd Rowが有効ではありません。');
      }

      // データの更新
      const updatedData = [...data];
      for (let i = actualStartRow; i <= actualEndRow; i++) {
        const item = { ...updatedData[i] };
        const originalImages = item[picUrlKey]
          ? item[picUrlKey].split('|')
          : [];
        const newImages = originalImages.filter(
          (url) => !selectedImages.has(url)
        );
        item[picUrlKey] = newImages.join('|');
        updatedData[i] = item;
      }

      setData(updatedData);

      // プレビュー画像を更新
      const updatedPreviewImages = previewImages.map((product) => {
        const item = updatedData[product.rowIndex];
        const picUrl = item[picUrlKey];
        const imageUrls = picUrl ? picUrl.split('|') : [];
        const images = imageUrls.map((url, idx) => ({
          url,
          imageIndex: idx + 1,
        }));
        return {
          ...product,
          images,
        };
      });
      setPreviewImages(updatedPreviewImages);

      const finalMessage = `画像の削除が完了しました。`;
      showSnackbar(finalMessage);
    } catch (err) {
      const errorMessage = `画像の削除に失敗しました: ${err.message}`;
      setError(errorMessage);
      showSnackbar(errorMessage);
      console.error(errorMessage, err);
    } finally {
      setIsProcessing(false);
      setOpenConfirmDialog(false);
      setOpenPreviewDialog(false);
    }
  }, [
    data,
    startRow,
    endRow,
    selectedImages,
    calculateMaxEndRow,
    setData,
    previewImages,
    setIsProcessing,
    setError,
    showSnackbar,
    setOpenPreviewDialog,
  ]);

  const handleAddImageClick = (product) => {
    setSelectedProductForEdit(product);
    setIsAddImageDialogOpen(true);
  };

  const handleImageFileChange = (e) => {
    if (e.target.files && e.target.files[0]) {
      setNewImageFile(e.target.files[0]);
      setNewImageURL('');
    }
  };

  const handleAddImage = async () => {
    const storage = getStorage();

    try {
      let imageUrl = newImageURL;

      if (newImageFile) {
        // Firebase Storage に画像をアップロード
        const fileExtension = newImageFile.name.split('.').pop();
        const storageReference = storageRef(
          storage,
          `images/${uuidv4()}.${fileExtension}`
        );
        await uploadBytes(storageReference, newImageFile);
        imageUrl = await getDownloadURL(storageReference);
      }

      if (!imageUrl) {
        alert('画像のURLを入力するか、画像ファイルをアップロードしてください。');
        return;
      }

      // データの更新
      const picUrlKey = Object.keys(data[0]).find(
        (key) => key.toLowerCase() === 'picurl'
      );
      const updatedData = [...data];
      const item = { ...updatedData[selectedProductForEdit.rowIndex] };
      const imageUrls = item[picUrlKey] ? item[picUrlKey].split('|') : [];
      imageUrls.push(imageUrl);
      item[picUrlKey] = imageUrls.join('|');
      updatedData[selectedProductForEdit.rowIndex] = item;
      setData(updatedData);

      // previewImagesを更新
      const updatedPreviewImages = previewImages.map((product) => {
        if (product.rowIndex === selectedProductForEdit.rowIndex) {
          const images = [
            ...product.images,
            { url: imageUrl, imageIndex: product.images.length + 1 },
          ];
          return {
            ...product,
            images,
          };
        }
        return product;
      });
      setPreviewImages(updatedPreviewImages);

      setIsAddImageDialogOpen(false);
      setNewImageFile(null);
      setNewImageURL('');
    } catch (error) {
      console.error('画像の追加中にエラーが発生しました:', error);
      alert('画像の追加に失敗しました。再度お試しください。');
    }
  };

  // 商品削除の関数
  const handleDeleteProduct = (rowIndex) => {
    setProductToDelete(rowIndex);
    setOpenProductDeleteConfirmDialog(true);
  };

  // 削除を確定する関数
  const confirmDeleteProduct = (rowIndex) => {
    // データから商品を削除
    const updatedData = data.filter((_, index) => index !== rowIndex);
    setData(updatedData);

    // プレビュー画像から商品を削除
    const updatedPreviewImages = previewImages.filter(
      (product) => product.rowIndex !== rowIndex
    );

    // インデックスを再計算
    const recalculatedPreviewImages = updatedPreviewImages.map((product) => {
      return {
        ...product,
        rowIndex:
          product.rowIndex > rowIndex ? product.rowIndex - 1 : product.rowIndex,
      };
    });

    setPreviewImages(recalculatedPreviewImages);
  };

  // ドラッグ終了時のハンドラー
  const handleDragEnd = (result, product) => {
    if (!result.destination) {
      return;
    }

    const { source, destination } = result;

    const productIndex = previewImages.findIndex(
      (p) => p.rowIndex === product.rowIndex
    );
    if (productIndex === -1) {
      return;
    }

    const images = Array.from(previewImages[productIndex].images);
    const [movedImage] = images.splice(source.index, 1);
    images.splice(destination.index, 0, movedImage);

    // previewImagesを更新
    const updatedPreviewImages = [...previewImages];
    updatedPreviewImages[productIndex] = {
      ...previewImages[productIndex],
      images,
    };
    setPreviewImages(updatedPreviewImages);

    // dataを更新
    const picUrlKey = Object.keys(data[0]).find(
      (key) => key.toLowerCase() === 'picurl'
    );
    const updatedData = [...data];
    const item = { ...updatedData[product.rowIndex] };
    item[picUrlKey] = images.map((img) => img.url).join('|');
    updatedData[product.rowIndex] = item;
    setData(updatedData);
  };

  // 画像解析を行う関数
  const handleImageAnalysis = async () => {
    setIsProcessing(true);
    setError('');

    try {
      const updatedData = [...data];
      const picUrlKey = Object.keys(data[0]).find(
        (key) => key.toLowerCase() === 'picurl'
      );

      if (!picUrlKey) {
        throw new Error('PicURLカラムが見つかりません。');
      }

      if (selectedImages.size === 0) {
        throw new Error('解析する画像を選択してください。');
      }

      for (const selectedImageUrl of selectedImages) {
        // 対応する商品（行）を見つける
        const itemIndex = data.findIndex((item) => {
          const picUrl = item[picUrlKey];
          const imageUrls = picUrl ? picUrl.split('|') : [];
          return imageUrls.includes(selectedImageUrl);
        });

        if (itemIndex !== -1) {
          const item = updatedData[itemIndex];
          // 画像を解析して説明を取得
          // const description = await performImageAnalysis(selectedImageUrl, apiKey);
          const description = await ImageAnalysis(selectedImageUrl, apiKey);
          // 既存の説明がある場合は追記、それ以外は新規作成
          if (item['jp_image_description']) {
            item['jp_image_description'] += `\n\n${description}`;
          } else {
            item['jp_image_description'] = description;
          }
        }
      }

      setData(updatedData);
      showSnackbar('選択された画像の解析が完了し、データが更新されました。');
    } catch (error) {
      setError(`画像解析中にエラーが発生しました: ${error.message}`);
      console.error(error);
    } finally {
      setIsProcessing(false);
      setOpenPreviewDialog(false);
    }
  };

  return (
    <>
      {/* 画像解析ボタンを追加 */}
      <Button
        variant="contained"
        color="primary"
        startIcon={<DescriptionIcon />}
        onClick={() => {
          setPreviewDialogMode('analyze');
          setOpenPreviewDialog(true);
        }}
        disabled={isProcessing}
        sx={{ mt: 2 }}
      >
        画像解析
      </Button>

      {/* 以下、既存のコード... */}
      {/* プレビューダイアログ */}
      <Dialog
        open={openPreviewDialog}
        onClose={() => setOpenPreviewDialog(false)}
        maxWidth="xl"
        fullWidth
      >
        <DialogTitle>
          {previewDialogMode === 'edit' ? '画像編集・削除' : '画像解析'}
          <IconButton
            aria-label="close"
            onClick={() => setOpenPreviewDialog(false)}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          {/* 表示件数の選択 */}
          <Box sx={{ mb: 2, display: 'flex', alignItems: 'center' }}>
            <Typography variant="body2" sx={{ mr: 2 }}>
              表示件数:
            </Typography>
            <FormControl variant="outlined" size="small">
              <Select
                value={itemsPerPage}
                onChange={handleItemsPerPageChange}
                sx={{ minWidth: 120 }}
              >
                <MenuItem value={50}>50</MenuItem>
                <MenuItem value={100}>100</MenuItem>
                <MenuItem value={150}>150</MenuItem>
                <MenuItem value={200}>200</MenuItem>
                <MenuItem value="ALL">ALL</MenuItem>
              </Select>
            </FormControl>
          </Box>
          <Typography variant="body2" sx={{ mb: 2 }}>
            {previewDialogMode === 'edit'
              ? '削除したい画像の「×」をクリックしてください。画像をドラッグ＆ドロップで並び替えることもできます。'
              : '解析したい画像をクリックして選択してください。'}
          </Typography>
          <Box
            ref={scrollableContainerRef} // ここでrefを設定
            sx={{ maxHeight: '70vh', overflowY: 'auto', position: 'relative' }}
          >
            {displayedPreviewImages.map((product, index) => (
              <Box key={index} sx={{ mb: 4 }}>
                <Typography variant="h6">{product.title}</Typography>

                {/* 商品を削除するボタンを追加 */}
                {previewDialogMode === 'edit' && (
                  <Button
                    variant="outlined"
                    color="error"
                    onClick={() => handleDeleteProduct(product.rowIndex)}
                    sx={{ mb: 2 }}
                  >
                    商品を削除
                  </Button>
                )}

                <DragDropContext
                  onDragEnd={(result) => handleDragEnd(result, product)}
                >
                  <Droppable
                    droppableId={`droppable-${product.rowIndex}`}
                    direction="horizontal"
                  >
                    {(provided) => (
                      <Grid
                        container
                        spacing={2}
                        ref={provided.innerRef}
                        {...provided.droppableProps}
                      >
                        {product.images.map((image, idx) => (
                          <Draggable
                            key={image.url}
                            draggableId={image.url}
                            index={idx}
                            isDragDisabled={previewDialogMode === 'analyze'}
                          >
                            {(provided) => (
                              <Grid
                                item
                                xs={3}
                                sm={2}
                                md={1.5}
                                lg={1}
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                              >
                                <Paper
                                  elevation={1}
                                  sx={{
                                    p: 1,
                                    position: 'relative',
                                    opacity: selectedImages.has(image.url)
                                      ? 0.4
                                      : 1,
                                    border: selectedImages.has(image.url)
                                      ? '2px solid blue'
                                      : 'none',
                                  }}
                                  onClick={() =>
                                    toggleImageSelection(image.url)
                                  }
                                >
                                  {previewDialogMode === 'edit' && (
                                    <IconButton
                                      aria-label="delete"
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        toggleImageSelection(image.url);
                                      }}
                                      sx={{
                                        position: 'absolute',
                                        top: 0,
                                        right: 0,
                                        color: (theme) =>
                                          theme.palette.error.main,
                                      }}
                                    >
                                      <CloseIcon />
                                    </IconButton>
                                  )}
                                  <img
                                    src={image.url}
                                    alt={`Row ${product.rowIndex + 1} Image ${
                                      image.imageIndex
                                    }`}
                                    style={{ width: '100%', height: 'auto' }}
                                    loading="lazy"
                                  />
                                  <Typography variant="caption">
                                    画像 {idx + 1}
                                  </Typography>
                                </Paper>
                              </Grid>
                            )}
                          </Draggable>
                        ))}

                        {provided.placeholder}

                        {/* プラスアイコンのDraggableアイテム */}
                        {previewDialogMode === 'edit' && (
                          <Draggable
                            key={`add-image-${product.rowIndex}`}
                            draggableId={`add-image-${product.rowIndex}`}
                            index={product.images.length}
                            isDragDisabled={true} // ドラッグ不可に設定
                          >
                            {(provided) => (
                              <Grid
                                item
                                xs={3}
                                sm={2}
                                md={1.5}
                                lg={1}
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                              >
                                <Paper
                                  elevation={1}
                                  sx={{
                                    p: 1,
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    height: '100%',
                                    border: '2px dashed #1976d2',
                                    color: '#1976d2',
                                    cursor: 'pointer',
                                  }}
                                  onClick={() => handleAddImageClick(product)}
                                >
                                  <Typography variant="h4">+</Typography>
                                </Paper>
                              </Grid>
                            )}
                          </Draggable>
                        )}
                      </Grid>
                    )}
                  </Droppable>
                </DragDropContext>
              </Box>
            ))}
          </Box>
          {/* スクロールトップボタン */}
          {showScrollTopButton && (
            <IconButton
              onClick={() => {
                if (scrollableContainerRef.current) {
                  scrollableContainerRef.current.scrollTo({ top: 0, behavior: 'smooth' });
                }
              }}
              sx={{
                position: 'fixed',
                bottom: 100,
                right: 40,
                zIndex: 1000,
                backgroundColor: theme.palette.background.paper,
                '&:hover': {
                  backgroundColor: theme.palette.grey[300],
                },
              }}
            >
              <KeyboardArrowUpIcon />
            </IconButton>
          )}
          {/* ページネーション */}
          {totalPages > 1 && (
            <Box sx={{ mt: 2, display: 'flex', justifyContent: 'center' }}>
              <Pagination
                count={totalPages}
                page={currentPage}
                onChange={handlePageChange}
                color="primary"
              />
            </Box>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenPreviewDialog(false)}>閉じる</Button>
          {previewDialogMode === 'edit' ? (
            <Button
              onClick={() => {
                setOpenConfirmDialog(true);
              }}
              color="primary"
              variant="contained"
              disabled={selectedImages.size === 0}
            >
              削除実行
            </Button>
          ) : (
            <Button
              onClick={handleImageAnalysis}
              color="primary"
              variant="contained"
              disabled={selectedImages.size === 0}
            >
              解析実行
            </Button>
          )}
        </DialogActions>
      </Dialog>

      {/* 画像追加ダイアログ */}
      <Dialog
        open={isAddImageDialogOpen}
        onClose={() => setIsAddImageDialogOpen(false)}
      >
        <DialogTitle>画像を追加</DialogTitle>
        <DialogContent>
          <Typography variant="body2" sx={{ mb: 2 }}>
            画像のURLを入力するか、画像ファイルをアップロードしてください。
          </Typography>
          <TextField
            label="画像URL"
            value={newImageURL}
            onChange={(e) => setNewImageURL(e.target.value)}
            fullWidth
            sx={{ mb: 2 }}
          />
          <Typography variant="body2" sx={{ mb: 1 }}>
            または
          </Typography>
          <Button variant="contained" component="label">
            画像を選択
            <input
              type="file"
              accept="image/*"
              hidden
              onChange={handleImageFileChange}
            />
          </Button>
          {newImageFile && (
            <Typography variant="body2" sx={{ mt: 1 }}>
              選択されたファイル: {newImageFile.name}
            </Typography>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setIsAddImageDialogOpen(false);
              setNewImageFile(null);
              setNewImageURL('');
            }}
          >
            キャンセル
          </Button>
          <Button onClick={handleAddImage} color="primary" variant="contained">
            追加
          </Button>
        </DialogActions>
      </Dialog>

      {/* 画像削除確認ダイアログ */}
      <Dialog
        open={openConfirmDialog}
        onClose={() => setOpenConfirmDialog(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{'確認'}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            選択された画像が商品データから削除されます。よろしいですか？
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenConfirmDialog(false)} color="primary">
            キャンセル
          </Button>
          <Button onClick={handleDelete} color="primary" autoFocus>
            実行
          </Button>
        </DialogActions>
      </Dialog>

      {/* 商品削除確認ダイアログ */}
      <Dialog
        open={openProductDeleteConfirmDialog}
        onClose={() => setOpenProductDeleteConfirmDialog(false)}
      >
        <DialogTitle>商品を削除</DialogTitle>
        <DialogContent>
          <DialogContentText>
            この商品を削除してもよろしいですか？
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenProductDeleteConfirmDialog(false)}>
            キャンセル
          </Button>
          <Button
            onClick={() => {
              confirmDeleteProduct(productToDelete);
              setOpenProductDeleteConfirmDialog(false);
            }}
            color="error"
          >
            削除
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default ImageOperations;





// // ImageOperations.js

// import React, { useState, useCallback, useEffect, useRef } from 'react';
// import {
//   Button,
//   Typography,
//   Box,
//   Dialog,
//   DialogActions,
//   DialogContent,
//   DialogTitle,
//   Paper,
//   Grid,
//   IconButton,
//   DialogContentText,
//   TextField,
//   MenuItem,
//   Select,
//   FormControl,
//   Pagination,
//   CircularProgress,
// } from '@mui/material';
// import { useTheme } from '@mui/material/styles';
// import CloseIcon from '@mui/icons-material/Close';
// import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
// import DescriptionIcon from '@mui/icons-material/Description';
// import { DragDropContext, Droppable, Draggable } from '@hello-pangea/dnd';
// import {
//   getStorage,
//   ref as storageRef,
//   uploadBytes,
//   getDownloadURL,
// } from 'firebase/storage';
// import { v4 as uuidv4 } from 'uuid';
// import axios from 'axios';
// import ImageAnalysis from './ImageAnalysis'; // 追加

// function ImageOperations({
//   data,
//   setData,
//   startRow,
//   endRow,
//   calculateMaxEndRow,
//   isProcessing,
//   setIsProcessing,
//   setError,
//   showSnackbar,
//   openPreviewDialog,
//   setOpenPreviewDialog,
//   apiKey, // OpenAIのAPIキー
// }) {
//   const [previewImages, setPreviewImages] = useState([]);
//   const [displayedPreviewImages, setDisplayedPreviewImages] = useState([]);
//   const [selectedImages, setSelectedImages] = useState(new Set());
//   const [selectedProductForEdit, setSelectedProductForEdit] = useState(null);
//   const [isAddImageDialogOpen, setIsAddImageDialogOpen] = useState(false);
//   const [newImageFile, setNewImageFile] = useState(null);
//   const [newImageURL, setNewImageURL] = useState('');
//   const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
//   const [openProductDeleteConfirmDialog, setOpenProductDeleteConfirmDialog] = useState(false);
//   const [productToDelete, setProductToDelete] = useState(null);

//   const [itemsPerPage, setItemsPerPage] = useState(50); // 1ページあたりの表示件数
//   const [currentPage, setCurrentPage] = useState(1); // 現在のページ番号

//   const [isImageAnalysisDialogOpen, setIsImageAnalysisDialogOpen] = useState(false);
//   const [isAnalyzing, setIsAnalyzing] = useState(false);

//   const theme = useTheme();

//   // スクロール可能なコンテナのrefを追加
//   const scrollableContainerRef = useRef(null);
//   const [showScrollTopButton, setShowScrollTopButton] = useState(false); // スクロールトップボタンの表示制御

//   const handleOpenPreviewDialog = useCallback(() => {
//     try {
//       if (!startRow || !endRow || isNaN(startRow) || isNaN(endRow)) {
//         throw new Error('Start RowとEnd Rowは有効な数字である必要があります。');
//       }

//       const picUrlKey = Object.keys(data[0]).find(
//         (key) => key.toLowerCase() === 'picurl'
//       );
//       const titleKey = Object.keys(data[0]).find(
//         (key) => key.toLowerCase() === 'title'
//       );

//       if (!picUrlKey) {
//         throw new Error('PicURLカラムが見つかりません。');
//       }

//       const actualStartRow = parseInt(startRow) - 1; // インデックスは0から開始
//       const maxEndRow = calculateMaxEndRow();
//       const actualEndRow = Math.min(parseInt(endRow) - 1, maxEndRow - 1);

//       if (actualStartRow < 0 || actualEndRow < actualStartRow) {
//         throw new Error('Start RowとEnd Rowが有効ではありません。');
//       }

//       let previewItems = [];

//       // すべての画像を取得
//       for (let i = actualStartRow; i <= actualEndRow; i++) {
//         const item = data[i];
//         const picUrl = item[picUrlKey];
//         const imageUrls = picUrl ? picUrl.split('|') : [];
//         const title = item[titleKey] || `商品 ${i + 1}`;
//         const images = imageUrls.map((url, idx) => ({
//           url,
//           imageIndex: idx + 1,
//         }));
//         if (images.length > 0) {
//           previewItems.push({
//             title,
//             rowIndex: i,
//             images,
//           });
//         }
//       }

//       setPreviewImages(previewItems);
//       setSelectedImages(new Set()); // すべて未選択
//       setCurrentPage(1); // ページをリセット
//     } catch (err) {
//       const errorMessage = `プレビューの準備中にエラーが発生しました: ${err.message}`;
//       setError(errorMessage);
//       console.error(errorMessage, err);
//     }
//   }, [data, startRow, endRow, calculateMaxEndRow, setError]);

//   // openPreviewDialogがtrueになったときにプレビューを準備
//   useEffect(() => {
//     if (openPreviewDialog) {
//       handleOpenPreviewDialog();
//     } else {
//       // ダイアログが閉じられたときにプレビュー画像をクリア
//       setPreviewImages([]);
//       setSelectedImages(new Set());
//     }
//   }, [openPreviewDialog, handleOpenPreviewDialog]);

//   // ページネーションの処理
//   useEffect(() => {
//     if (itemsPerPage === 'ALL') {
//       setDisplayedPreviewImages(previewImages);
//     } else {
//       const startIndex = (currentPage - 1) * itemsPerPage;
//       const endIndex = startIndex + itemsPerPage;
//       setDisplayedPreviewImages(previewImages.slice(startIndex, endIndex));
//     }
//   }, [previewImages, currentPage, itemsPerPage]);

//   // currentPageが変更されたときにスクロール位置をトップに戻す
//   useEffect(() => {
//     if (scrollableContainerRef.current) {
//       scrollableContainerRef.current.scrollTop = 0;
//     }
//   }, [currentPage]);

//   // スクロール位置に応じてスクロールトップボタンの表示を制御
//   useEffect(() => {
//     const handleScroll = () => {
//       if (scrollableContainerRef.current) {
//         const scrollTop = scrollableContainerRef.current.scrollTop;
//         setShowScrollTopButton(scrollTop > 200);
//       }
//     };

//     const container = scrollableContainerRef.current;
//     if (container) {
//       container.addEventListener('scroll', handleScroll);
//       return () => container.removeEventListener('scroll', handleScroll);
//     }
//   }, [scrollableContainerRef]);

//   const totalPages = itemsPerPage === 'ALL' ? 1 : Math.ceil(previewImages.length / itemsPerPage);

//   const handleItemsPerPageChange = (event) => {
//     const value = event.target.value === 'ALL' ? 'ALL' : parseInt(event.target.value);
//     setItemsPerPage(value);
//     setCurrentPage(1); // ページをリセット
//   };

//   const handlePageChange = (event, value) => {
//     setCurrentPage(value);
//   };

//   const toggleImageSelection = (url) => {
//     setSelectedImages((prevSelected) => {
//       const newSelected = new Set(prevSelected);
//       if (newSelected.has(url)) {
//         newSelected.delete(url);
//       } else {
//         newSelected.add(url);
//       }
//       return newSelected;
//     });
//   };

//   const handleDelete = useCallback(async () => {
//     setIsProcessing(true);
//     setError('');

//     try {
//       if (!startRow || !endRow || isNaN(startRow) || isNaN(endRow)) {
//         throw new Error('Start RowとEnd Rowは有効な数字である必要があります。');
//       }

//       const picUrlKey = Object.keys(data[0]).find(
//         (key) => key.toLowerCase() === 'picurl'
//       );

//       if (!picUrlKey) {
//         throw new Error('PicURLカラムが見つかりません。');
//       }

//       const actualStartRow = parseInt(startRow) - 1; // インデックスは0から開始
//       const maxEndRow = calculateMaxEndRow();
//       const actualEndRow = Math.min(parseInt(endRow) - 1, maxEndRow - 1);

//       if (actualStartRow < 0 || actualEndRow < actualStartRow) {
//         throw new Error('Start RowとEnd Rowが有効ではありません。');
//       }

//       // データの更新
//       const updatedData = [...data];
//       for (let i = actualStartRow; i <= actualEndRow; i++) {
//         const item = { ...updatedData[i] };
//         const originalImages = item[picUrlKey]
//           ? item[picUrlKey].split('|')
//           : [];
//         const newImages = originalImages.filter(
//           (url) => !selectedImages.has(url)
//         );
//         item[picUrlKey] = newImages.join('|');
//         updatedData[i] = item;
//       }

//       setData(updatedData);

//       // プレビュー画像を更新
//       const updatedPreviewImages = previewImages.map((product) => {
//         const item = updatedData[product.rowIndex];
//         const picUrl = item[picUrlKey];
//         const imageUrls = picUrl ? picUrl.split('|') : [];
//         const images = imageUrls.map((url, idx) => ({
//           url,
//           imageIndex: idx + 1,
//         }));
//         return {
//           ...product,
//           images,
//         };
//       });
//       setPreviewImages(updatedPreviewImages);

//       const finalMessage = `画像の削除が完了しました。`;
//       showSnackbar(finalMessage);
//     } catch (err) {
//       const errorMessage = `画像の削除に失敗しました: ${err.message}`;
//       setError(errorMessage);
//       showSnackbar(errorMessage);
//       console.error(errorMessage, err);
//     } finally {
//       setIsProcessing(false);
//       setOpenConfirmDialog(false);
//       setOpenPreviewDialog(false);
//     }
//   }, [
//     data,
//     startRow,
//     endRow,
//     selectedImages,
//     calculateMaxEndRow,
//     setData,
//     previewImages,
//     setIsProcessing,
//     setError,
//     showSnackbar,
//     setOpenPreviewDialog,
//   ]);

//   const handleAddImageClick = (product) => {
//     setSelectedProductForEdit(product);
//     setIsAddImageDialogOpen(true);
//   };

//   const handleImageFileChange = (e) => {
//     if (e.target.files && e.target.files[0]) {
//       setNewImageFile(e.target.files[0]);
//       setNewImageURL('');
//     }
//   };

//   const handleAddImage = async () => {
//     const storage = getStorage();

//     try {
//       let imageUrl = newImageURL;

//       if (newImageFile) {
//         // Firebase Storage に画像をアップロード
//         const fileExtension = newImageFile.name.split('.').pop();
//         const storageReference = storageRef(
//           storage,
//           `images/${uuidv4()}.${fileExtension}`
//         );
//         await uploadBytes(storageReference, newImageFile);
//         imageUrl = await getDownloadURL(storageReference);
//       }

//       if (!imageUrl) {
//         alert('画像のURLを入力するか、画像ファイルをアップロードしてください。');
//         return;
//       }

//       // データの更新
//       const picUrlKey = Object.keys(data[0]).find(
//         (key) => key.toLowerCase() === 'picurl'
//       );
//       const updatedData = [...data];
//       const item = { ...updatedData[selectedProductForEdit.rowIndex] };
//       const imageUrls = item[picUrlKey] ? item[picUrlKey].split('|') : [];
//       imageUrls.push(imageUrl);
//       item[picUrlKey] = imageUrls.join('|');
//       updatedData[selectedProductForEdit.rowIndex] = item;
//       setData(updatedData);

//       // previewImagesを更新
//       const updatedPreviewImages = previewImages.map((product) => {
//         if (product.rowIndex === selectedProductForEdit.rowIndex) {
//           const images = [
//             ...product.images,
//             { url: imageUrl, imageIndex: product.images.length + 1 },
//           ];
//           return {
//             ...product,
//             images,
//           };
//         }
//         return product;
//       });
//       setPreviewImages(updatedPreviewImages);

//       setIsAddImageDialogOpen(false);
//       setNewImageFile(null);
//       setNewImageURL('');
//     } catch (error) {
//       console.error('画像の追加中にエラーが発生しました:', error);
//       alert('画像の追加に失敗しました。再度お試しください。');
//     }
//   };

//   // 商品削除の関数
//   const handleDeleteProduct = (rowIndex) => {
//     setProductToDelete(rowIndex);
//     setOpenProductDeleteConfirmDialog(true);
//   };

//   // 削除を確定する関数
//   const confirmDeleteProduct = (rowIndex) => {
//     // データから商品を削除
//     const updatedData = data.filter((_, index) => index !== rowIndex);
//     setData(updatedData);

//     // プレビュー画像から商品を削除
//     const updatedPreviewImages = previewImages.filter(
//       (product) => product.rowIndex !== rowIndex
//     );

//     // インデックスを再計算
//     const recalculatedPreviewImages = updatedPreviewImages.map((product) => {
//       return {
//         ...product,
//         rowIndex:
//           product.rowIndex > rowIndex ? product.rowIndex - 1 : product.rowIndex,
//       };
//     });

//     setPreviewImages(recalculatedPreviewImages);
//   };

//   // ドラッグ終了時のハンドラー
//   const handleDragEnd = (result, product) => {
//     if (!result.destination) {
//       return;
//     }

//     const { source, destination } = result;

//     const productIndex = previewImages.findIndex(
//       (p) => p.rowIndex === product.rowIndex
//     );
//     if (productIndex === -1) {
//       return;
//     }

//     const images = Array.from(previewImages[productIndex].images);
//     const [movedImage] = images.splice(source.index, 1);
//     images.splice(destination.index, 0, movedImage);

//     // previewImagesを更新
//     const updatedPreviewImages = [...previewImages];
//     updatedPreviewImages[productIndex] = {
//       ...previewImages[productIndex],
//       images,
//     };
//     setPreviewImages(updatedPreviewImages);

//     // dataを更新
//     const picUrlKey = Object.keys(data[0]).find(
//       (key) => key.toLowerCase() === 'picurl'
//     );
//     const updatedData = [...data];
//     const item = { ...updatedData[product.rowIndex] };
//     item[picUrlKey] = images.map((img) => img.url).join('|');
//     updatedData[product.rowIndex] = item;
//     setData(updatedData);
//   };

//   // 画像解析を行う関数
//   const handleImageAnalysis = async () => {
//     setIsProcessing(true);
//     setError('');

//     try {
//       const actualStartRow = parseInt(startRow) - 1;
//       const actualEndRow = parseInt(endRow) - 1;

//       const picUrlKey = Object.keys(data[0]).find(
//         (key) => key.toLowerCase() === 'picurl'
//       );

//       if (!picUrlKey) {
//         throw new Error('PicURLカラムが見つかりません。');
//       }

//       const updatedData = [...data];

//       for (let i = actualStartRow; i <= actualEndRow; i++) {
//         const item = updatedData[i];
//         const picUrl = item[picUrlKey];
//         const imageUrls = picUrl ? picUrl.split('|') : [];

//         if (imageUrls.length > 0) {
//           // メイン画像（1枚目）のURLを取得
//           const mainImageUrl = imageUrls[0];

//           // 画像を解析して説明を取得
//           const description = await ImageAnalysis(mainImageUrl, apiKey);

//           // データに説明を追加
//           item['jp_image_description'] = description;
//         }
//       }

//       setData(updatedData);
//       showSnackbar('画像解析が完了し、データが更新されました。');
//     } catch (error) {
//       setError(`画像解析中にエラーが発生しました: ${error.message}`);
//       console.error(error);
//     } finally {
//       setIsProcessing(false);
//     }
//   };

//   return (
//     <>
//       {/* 既存のコード... */}

//       {/* 画像解析ボタンを追加 */}
//       <Button
//         variant="contained"
//         color="primary"
//         startIcon={<DescriptionIcon />}
//         onClick={() => setIsImageAnalysisDialogOpen(true)}
//         disabled={isProcessing}
//         sx={{ mt: 2 }}
//       >
//         画像解析
//       </Button>

//       {/* 画像解析ダイアログ */}
//       <Dialog
//         open={isImageAnalysisDialogOpen}
//         onClose={() => setIsImageAnalysisDialogOpen(false)}
//         maxWidth="sm"
//         fullWidth
//       >
//         <DialogTitle>画像解析の実行</DialogTitle>
//         <DialogContent>
//           <Typography variant="body2" sx={{ mb: 1 }}>
//             {startRow} 行目から {endRow} 行目までの商品のメイン画像を解析し、説明を生成します。
//           </Typography>
//           <Typography variant="body2">
//             生成後にシートビュー最後尾にjp_image_descriptionカラムが追加されます。
//           </Typography>
//           {isAnalyzing && <CircularProgress sx={{ mt: 2 }} />}
//         </DialogContent>
//         <DialogActions>
//           <Button
//             onClick={() => setIsImageAnalysisDialogOpen(false)}
//             disabled={isAnalyzing}
//           >
//             キャンセル
//           </Button>
//           <Button
//             onClick={async () => {
//               setIsAnalyzing(true);
//               await handleImageAnalysis();
//               setIsAnalyzing(false);
//               setIsImageAnalysisDialogOpen(false);
//             }}
//             color="primary"
//             variant="contained"
//             disabled={isAnalyzing}
//           >
//             実行
//           </Button>
//         </DialogActions>
//       </Dialog>

//       {/* 以下、既存のコード... */}
//       {/* プレビューダイアログ */}
//       <Dialog
//         open={openPreviewDialog}
//         onClose={() => setOpenPreviewDialog(false)}
//         maxWidth="xl"
//         fullWidth
//       >
//         <DialogTitle>
//           画像編集・削除
//           <IconButton
//             aria-label="close"
//             onClick={() => setOpenPreviewDialog(false)}
//             sx={{
//               position: 'absolute',
//               right: 8,
//               top: 8,
//               color: (theme) => theme.palette.grey[500],
//             }}
//           >
//             <CloseIcon />
//           </IconButton>
//         </DialogTitle>
//         <DialogContent>
//           {/* 表示件数の選択 */}
//           <Box sx={{ mb: 2, display: 'flex', alignItems: 'center' }}>
//             <Typography variant="body2" sx={{ mr: 2 }}>
//               表示件数:
//             </Typography>
//             <FormControl variant="outlined" size="small">
//               <Select
//                 value={itemsPerPage}
//                 onChange={handleItemsPerPageChange}
//                 sx={{ minWidth: 120 }}
//               >
//                 <MenuItem value={50}>50</MenuItem>
//                 <MenuItem value={100}>100</MenuItem>
//                 <MenuItem value={150}>150</MenuItem>
//                 <MenuItem value={200}>200</MenuItem>
//                 <MenuItem value="ALL">ALL</MenuItem>
//               </Select>
//             </FormControl>
//           </Box>
//           <Typography variant="body2" sx={{ mb: 2 }}>
//             削除したい画像の「×」をクリックしてください。画像をドラッグ＆ドロップで並び替えることもできます。
//           </Typography>
//           <Box
//             ref={scrollableContainerRef} // ここでrefを設定
//             sx={{ maxHeight: '70vh', overflowY: 'auto', position: 'relative' }}
//           >
//             {displayedPreviewImages.map((product, index) => (
//               <Box key={index} sx={{ mb: 4 }}>
//                 <Typography variant="h6">{product.title}</Typography>

//                 {/* 商品を削除するボタンを追加 */}
//                 <Button
//                   variant="outlined"
//                   color="error"
//                   onClick={() => handleDeleteProduct(product.rowIndex)}
//                   sx={{ mb: 2 }}
//                 >
//                   商品を削除
//                 </Button>

//                 <DragDropContext
//                   onDragEnd={(result) => handleDragEnd(result, product)}
//                 >
//                   <Droppable
//                     droppableId={`droppable-${product.rowIndex}`}
//                     direction="horizontal"
//                   >
//                     {(provided) => (
//                       <Grid
//                         container
//                         spacing={2}
//                         ref={provided.innerRef}
//                         {...provided.droppableProps}
//                       >
//                         {product.images.map((image, idx) => (
//                           <Draggable
//                             key={image.url}
//                             draggableId={image.url}
//                             index={idx}
//                           >
//                             {(provided) => (
//                               <Grid
//                                 item
//                                 xs={3}
//                                 sm={2}
//                                 md={1.5}
//                                 lg={1}
//                                 ref={provided.innerRef}
//                                 {...provided.draggableProps}
//                                 {...provided.dragHandleProps}
//                               >
//                                 <Paper
//                                   elevation={1}
//                                   sx={{
//                                     p: 1,
//                                     position: 'relative',
//                                     opacity: selectedImages.has(image.url)
//                                       ? 0.4
//                                       : 1,
//                                   }}
//                                 >
//                                   <IconButton
//                                     aria-label="delete"
//                                     onClick={() =>
//                                       toggleImageSelection(image.url)
//                                     }
//                                     sx={{
//                                       position: 'absolute',
//                                       top: 0,
//                                       right: 0,
//                                       color: (theme) =>
//                                         theme.palette.error.main,
//                                     }}
//                                   >
//                                     <CloseIcon />
//                                   </IconButton>
//                                   <img
//                                     src={image.url}
//                                     alt={`Row ${product.rowIndex + 1} Image ${
//                                       image.imageIndex
//                                     }`}
//                                     style={{ width: '100%', height: 'auto' }}
//                                     loading="lazy"
//                                   />
//                                   <Typography variant="caption">
//                                     画像 {idx + 1}
//                                   </Typography>
//                                 </Paper>
//                               </Grid>
//                             )}
//                           </Draggable>
//                         ))}

//                         {provided.placeholder}

//                         {/* プラスアイコンのDraggableアイテム */}
//                         <Draggable
//                           key={`add-image-${product.rowIndex}`}
//                           draggableId={`add-image-${product.rowIndex}`}
//                           index={product.images.length}
//                           isDragDisabled={true} // ドラッグ不可に設定
//                         >
//                           {(provided) => (
//                             <Grid
//                               item
//                               xs={3}
//                               sm={2}
//                               md={1.5}
//                               lg={1}
//                               ref={provided.innerRef}
//                               {...provided.draggableProps}
//                             >
//                               <Paper
//                                 elevation={1}
//                                 sx={{
//                                   p: 1,
//                                   display: 'flex',
//                                   alignItems: 'center',
//                                   justifyContent: 'center',
//                                   height: '100%',
//                                   border: '2px dashed #1976d2',
//                                   color: '#1976d2',
//                                   cursor: 'pointer',
//                                 }}
//                                 onClick={() => handleAddImageClick(product)}
//                               >
//                                 <Typography variant="h4">+</Typography>
//                               </Paper>
//                             </Grid>
//                           )}
//                         </Draggable>
//                       </Grid>
//                     )}
//                   </Droppable>
//                 </DragDropContext>
//               </Box>
//             ))}
//           </Box>
//           {/* スクロールトップボタン */}
//           {showScrollTopButton && (
//             <IconButton
//               onClick={() => {
//                 if (scrollableContainerRef.current) {
//                   scrollableContainerRef.current.scrollTo({ top: 0, behavior: 'smooth' });
//                 }
//               }}
//               sx={{
//                 position: 'fixed',
//                 bottom: 100,
//                 right: 40,
//                 zIndex: 1000,
//                 backgroundColor: theme.palette.background.paper,
//                 '&:hover': {
//                   backgroundColor: theme.palette.grey[300],
//                 },
//               }}
//             >
//               <KeyboardArrowUpIcon />
//             </IconButton>
//           )}
//           {/* ページネーション */}
//           {totalPages > 1 && (
//             <Box sx={{ mt: 2, display: 'flex', justifyContent: 'center' }}>
//               <Pagination
//                 count={totalPages}
//                 page={currentPage}
//                 onChange={handlePageChange}
//                 color="primary"
//               />
//             </Box>
//           )}
//         </DialogContent>
//         <DialogActions>
//           <Button onClick={() => setOpenPreviewDialog(false)}>閉じる</Button>
//           <Button
//             onClick={() => {
//               setOpenConfirmDialog(true);
//             }}
//             color="primary"
//             variant="contained"
//           >
//             削除実行
//           </Button>
//         </DialogActions>
//       </Dialog>

//       {/* 画像追加ダイアログ */}
//       <Dialog
//         open={isAddImageDialogOpen}
//         onClose={() => setIsAddImageDialogOpen(false)}
//       >
//         <DialogTitle>画像を追加</DialogTitle>
//         <DialogContent>
//           <Typography variant="body2" sx={{ mb: 2 }}>
//             画像のURLを入力するか、画像ファイルをアップロードしてください。
//           </Typography>
//           <TextField
//             label="画像URL"
//             value={newImageURL}
//             onChange={(e) => setNewImageURL(e.target.value)}
//             fullWidth
//             sx={{ mb: 2 }}
//           />
//           <Typography variant="body2" sx={{ mb: 1 }}>
//             または
//           </Typography>
//           <Button variant="contained" component="label">
//             画像を選択
//             <input
//               type="file"
//               accept="image/*"
//               hidden
//               onChange={handleImageFileChange}
//             />
//           </Button>
//           {newImageFile && (
//             <Typography variant="body2" sx={{ mt: 1 }}>
//               選択されたファイル: {newImageFile.name}
//             </Typography>
//           )}
//         </DialogContent>
//         <DialogActions>
//           <Button
//             onClick={() => {
//               setIsAddImageDialogOpen(false);
//               setNewImageFile(null);
//               setNewImageURL('');
//             }}
//           >
//             キャンセル
//           </Button>
//           <Button onClick={handleAddImage} color="primary" variant="contained">
//             追加
//           </Button>
//         </DialogActions>
//       </Dialog>

//       {/* 画像削除確認ダイアログ */}
//       <Dialog
//         open={openConfirmDialog}
//         onClose={() => setOpenConfirmDialog(false)}
//         aria-labelledby="alert-dialog-title"
//         aria-describedby="alert-dialog-description"
//       >
//         <DialogTitle id="alert-dialog-title">{'確認'}</DialogTitle>
//         <DialogContent>
//           <DialogContentText id="alert-dialog-description">
//             選択された画像が商品データから削除されます。よろしいですか？
//           </DialogContentText>
//         </DialogContent>
//         <DialogActions>
//           <Button onClick={() => setOpenConfirmDialog(false)} color="primary">
//             キャンセル
//           </Button>
//           <Button onClick={handleDelete} color="primary" autoFocus>
//             実行
//           </Button>
//         </DialogActions>
//       </Dialog>

//       {/* 商品削除確認ダイアログ */}
//       <Dialog
//         open={openProductDeleteConfirmDialog}
//         onClose={() => setOpenProductDeleteConfirmDialog(false)}
//       >
//         <DialogTitle>商品を削除</DialogTitle>
//         <DialogContent>
//           <DialogContentText>
//             この商品を削除してもよろしいですか？
//           </DialogContentText>
//         </DialogContent>
//         <DialogActions>
//           <Button onClick={() => setOpenProductDeleteConfirmDialog(false)}>
//             キャンセル
//           </Button>
//           <Button
//             onClick={() => {
//               confirmDeleteProduct(productToDelete);
//               setOpenProductDeleteConfirmDialog(false);
//             }}
//             color="error"
//           >
//             削除
//           </Button>
//         </DialogActions>
//       </Dialog>
//     </>
//   );
// }

// export default ImageOperations;




// // ImageOperations.js

// import React, { useState, useCallback, useEffect, useRef } from 'react';
// import {
//   Button,
//   Typography,
//   Box,
//   Dialog,
//   DialogActions,
//   DialogContent,
//   DialogTitle,
//   Paper,
//   Grid,
//   IconButton,
//   DialogContentText,
//   TextField,
//   MenuItem,
//   Select,
//   FormControl,
//   Pagination,
// } from '@mui/material';
// import { useTheme } from '@mui/material/styles';
// import CloseIcon from '@mui/icons-material/Close';
// import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
// import { DragDropContext, Droppable, Draggable } from '@hello-pangea/dnd';
// import {
//   getStorage,
//   ref as storageRef,
//   uploadBytes,
//   getDownloadURL,
// } from 'firebase/storage';
// import { v4 as uuidv4 } from 'uuid';

// function ImageOperations({
//   data,
//   setData,
//   startRow,
//   endRow,
//   calculateMaxEndRow,
//   isProcessing,
//   setIsProcessing,
//   setError,
//   showSnackbar,
//   openPreviewDialog,
//   setOpenPreviewDialog,
// }) {
//   const [previewImages, setPreviewImages] = useState([]);
//   const [displayedPreviewImages, setDisplayedPreviewImages] = useState([]);
//   const [selectedImages, setSelectedImages] = useState(new Set());
//   const [selectedProductForEdit, setSelectedProductForEdit] = useState(null);
//   const [isAddImageDialogOpen, setIsAddImageDialogOpen] = useState(false);
//   const [newImageFile, setNewImageFile] = useState(null);
//   const [newImageURL, setNewImageURL] = useState('');
//   const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
//   const [openProductDeleteConfirmDialog, setOpenProductDeleteConfirmDialog] = useState(false);
//   const [productToDelete, setProductToDelete] = useState(null);

//   const [itemsPerPage, setItemsPerPage] = useState(50); // 1ページあたりの表示件数
//   const [currentPage, setCurrentPage] = useState(1); // 現在のページ番号

//   const theme = useTheme();

//   // スクロール可能なコンテナのrefを追加
//   const scrollableContainerRef = useRef(null);
//   const [showScrollTopButton, setShowScrollTopButton] = useState(false); // スクロールトップボタンの表示制御

//   const handleOpenPreviewDialog = useCallback(() => {
//     try {
//       if (!startRow || !endRow || isNaN(startRow) || isNaN(endRow)) {
//         throw new Error('Start RowとEnd Rowは有効な数字である必要があります。');
//       }

//       const picUrlKey = Object.keys(data[0]).find(
//         (key) => key.toLowerCase() === 'picurl'
//       );
//       const titleKey = Object.keys(data[0]).find(
//         (key) => key.toLowerCase() === 'title'
//       );

//       if (!picUrlKey) {
//         throw new Error('PicURLカラムが見つかりません。');
//       }

//       const actualStartRow = parseInt(startRow) - 1; // インデックスは0から開始
//       const maxEndRow = calculateMaxEndRow();
//       const actualEndRow = Math.min(parseInt(endRow) - 1, maxEndRow - 1);

//       if (actualStartRow < 0 || actualEndRow < actualStartRow) {
//         throw new Error('Start RowとEnd Rowが有効ではありません。');
//       }

//       let previewItems = [];

//       // すべての画像を取得
//       for (let i = actualStartRow; i <= actualEndRow; i++) {
//         const item = data[i];
//         const picUrl = item[picUrlKey];
//         const imageUrls = picUrl ? picUrl.split('|') : [];
//         const title = item[titleKey] || `商品 ${i + 1}`;
//         const images = imageUrls.map((url, idx) => ({
//           url,
//           imageIndex: idx + 1,
//         }));
//         if (images.length > 0) {
//           previewItems.push({
//             title,
//             rowIndex: i,
//             images,
//           });
//         }
//       }

//       setPreviewImages(previewItems);
//       setSelectedImages(new Set()); // すべて未選択
//       setCurrentPage(1); // ページをリセット
//     } catch (err) {
//       const errorMessage = `プレビューの準備中にエラーが発生しました: ${err.message}`;
//       setError(errorMessage);
//       console.error(errorMessage, err);
//     }
//   }, [data, startRow, endRow, calculateMaxEndRow, setError]);

//   // openPreviewDialogがtrueになったときにプレビューを準備
//   useEffect(() => {
//     if (openPreviewDialog) {
//       handleOpenPreviewDialog();
//     } else {
//       // ダイアログが閉じられたときにプレビュー画像をクリア
//       setPreviewImages([]);
//       setSelectedImages(new Set());
//     }
//   }, [openPreviewDialog, handleOpenPreviewDialog]);

//   // ページネーションの処理
//   useEffect(() => {
//     if (itemsPerPage === 'ALL') {
//       setDisplayedPreviewImages(previewImages);
//     } else {
//       const startIndex = (currentPage - 1) * itemsPerPage;
//       const endIndex = startIndex + itemsPerPage;
//       setDisplayedPreviewImages(previewImages.slice(startIndex, endIndex));
//     }
//   }, [previewImages, currentPage, itemsPerPage]);

//   // currentPageが変更されたときにスクロール位置をトップに戻す
//   useEffect(() => {
//     if (scrollableContainerRef.current) {
//       scrollableContainerRef.current.scrollTop = 0;
//     }
//   }, [currentPage]);

//   // スクロール位置に応じてスクロールトップボタンの表示を制御
//   useEffect(() => {
//     const handleScroll = () => {
//       if (scrollableContainerRef.current) {
//         const scrollTop = scrollableContainerRef.current.scrollTop;
//         setShowScrollTopButton(scrollTop > 200);
//       }
//     };

//     const container = scrollableContainerRef.current;
//     if (container) {
//       container.addEventListener('scroll', handleScroll);
//       return () => container.removeEventListener('scroll', handleScroll);
//     }
//   }, [scrollableContainerRef]);

//   const totalPages = itemsPerPage === 'ALL' ? 1 : Math.ceil(previewImages.length / itemsPerPage);

//   const handleItemsPerPageChange = (event) => {
//     const value = event.target.value === 'ALL' ? 'ALL' : parseInt(event.target.value);
//     setItemsPerPage(value);
//     setCurrentPage(1); // ページをリセット
//   };

//   const handlePageChange = (event, value) => {
//     setCurrentPage(value);
//   };

//   const toggleImageSelection = (url) => {
//     setSelectedImages((prevSelected) => {
//       const newSelected = new Set(prevSelected);
//       if (newSelected.has(url)) {
//         newSelected.delete(url);
//       } else {
//         newSelected.add(url);
//       }
//       return newSelected;
//     });
//   };

//   const handleDelete = useCallback(async () => {
//     setIsProcessing(true);
//     setError('');

//     try {
//       if (!startRow || !endRow || isNaN(startRow) || isNaN(endRow)) {
//         throw new Error('Start RowとEnd Rowは有効な数字である必要があります。');
//       }

//       const picUrlKey = Object.keys(data[0]).find(
//         (key) => key.toLowerCase() === 'picurl'
//       );

//       if (!picUrlKey) {
//         throw new Error('PicURLカラムが見つかりません。');
//       }

//       const actualStartRow = parseInt(startRow) - 1; // インデックスは0から開始
//       const maxEndRow = calculateMaxEndRow();
//       const actualEndRow = Math.min(parseInt(endRow) - 1, maxEndRow - 1);

//       if (actualStartRow < 0 || actualEndRow < actualStartRow) {
//         throw new Error('Start RowとEnd Rowが有効ではありません。');
//       }

//       // データの更新
//       const updatedData = [...data];
//       for (let i = actualStartRow; i <= actualEndRow; i++) {
//         const item = { ...updatedData[i] };
//         const originalImages = item[picUrlKey]
//           ? item[picUrlKey].split('|')
//           : [];
//         const newImages = originalImages.filter(
//           (url) => !selectedImages.has(url)
//         );
//         item[picUrlKey] = newImages.join('|');
//         updatedData[i] = item;
//       }

//       setData(updatedData);

//       // プレビュー画像を更新
//       const updatedPreviewImages = previewImages.map((product) => {
//         const item = updatedData[product.rowIndex];
//         const picUrl = item[picUrlKey];
//         const imageUrls = picUrl ? picUrl.split('|') : [];
//         const images = imageUrls.map((url, idx) => ({
//           url,
//           imageIndex: idx + 1,
//         }));
//         return {
//           ...product,
//           images,
//         };
//       });
//       setPreviewImages(updatedPreviewImages);

//       const finalMessage = `画像の削除が完了しました。`;
//       showSnackbar(finalMessage);
//     } catch (err) {
//       const errorMessage = `画像の削除に失敗しました: ${err.message}`;
//       setError(errorMessage);
//       showSnackbar(errorMessage);
//       console.error(errorMessage, err);
//     } finally {
//       setIsProcessing(false);
//       setOpenConfirmDialog(false);
//       setOpenPreviewDialog(false);
//     }
//   }, [
//     data,
//     startRow,
//     endRow,
//     selectedImages,
//     calculateMaxEndRow,
//     setData,
//     previewImages,
//     setIsProcessing,
//     setError,
//     showSnackbar,
//     setOpenPreviewDialog,
//   ]);

//   const handleAddImageClick = (product) => {
//     setSelectedProductForEdit(product);
//     setIsAddImageDialogOpen(true);
//   };

//   const handleImageFileChange = (e) => {
//     if (e.target.files && e.target.files[0]) {
//       setNewImageFile(e.target.files[0]);
//       setNewImageURL('');
//     }
//   };

//   const handleAddImage = async () => {
//     const storage = getStorage();

//     try {
//       let imageUrl = newImageURL;

//       if (newImageFile) {
//         // Firebase Storage に画像をアップロード
//         const fileExtension = newImageFile.name.split('.').pop();
//         const storageReference = storageRef(
//           storage,
//           `images/${uuidv4()}.${fileExtension}`
//         );
//         await uploadBytes(storageReference, newImageFile);
//         imageUrl = await getDownloadURL(storageReference);
//       }

//       if (!imageUrl) {
//         alert('画像のURLを入力するか、画像ファイルをアップロードしてください。');
//         return;
//       }

//       // データの更新
//       const picUrlKey = Object.keys(data[0]).find(
//         (key) => key.toLowerCase() === 'picurl'
//       );
//       const updatedData = [...data];
//       const item = { ...updatedData[selectedProductForEdit.rowIndex] };
//       const imageUrls = item[picUrlKey] ? item[picUrlKey].split('|') : [];
//       imageUrls.push(imageUrl);
//       item[picUrlKey] = imageUrls.join('|');
//       updatedData[selectedProductForEdit.rowIndex] = item;
//       setData(updatedData);

//       // previewImagesを更新
//       const updatedPreviewImages = previewImages.map((product) => {
//         if (product.rowIndex === selectedProductForEdit.rowIndex) {
//           const images = [
//             ...product.images,
//             { url: imageUrl, imageIndex: product.images.length + 1 },
//           ];
//           return {
//             ...product,
//             images,
//           };
//         }
//         return product;
//       });
//       setPreviewImages(updatedPreviewImages);

//       setIsAddImageDialogOpen(false);
//       setNewImageFile(null);
//       setNewImageURL('');
//     } catch (error) {
//       console.error('画像の追加中にエラーが発生しました:', error);
//       alert('画像の追加に失敗しました。再度お試しください。');
//     }
//   };

//   // 商品削除の関数
//   const handleDeleteProduct = (rowIndex) => {
//     setProductToDelete(rowIndex);
//     setOpenProductDeleteConfirmDialog(true);
//   };

//   // 削除を確定する関数
//   const confirmDeleteProduct = (rowIndex) => {
//     // データから商品を削除
//     const updatedData = data.filter((_, index) => index !== rowIndex);
//     setData(updatedData);

//     // プレビュー画像から商品を削除
//     const updatedPreviewImages = previewImages.filter(
//       (product) => product.rowIndex !== rowIndex
//     );

//     // インデックスを再計算
//     const recalculatedPreviewImages = updatedPreviewImages.map((product) => {
//       return {
//         ...product,
//         rowIndex:
//           product.rowIndex > rowIndex ? product.rowIndex - 1 : product.rowIndex,
//       };
//     });

//     setPreviewImages(recalculatedPreviewImages);
//   };

//   // ドラッグ終了時のハンドラー
//   const handleDragEnd = (result, product) => {
//     if (!result.destination) {
//       return;
//     }

//     const { source, destination } = result;

//     const productIndex = previewImages.findIndex(
//       (p) => p.rowIndex === product.rowIndex
//     );
//     if (productIndex === -1) {
//       return;
//     }

//     const images = Array.from(previewImages[productIndex].images);
//     const [movedImage] = images.splice(source.index, 1);
//     images.splice(destination.index, 0, movedImage);

//     // previewImagesを更新
//     const updatedPreviewImages = [...previewImages];
//     updatedPreviewImages[productIndex] = {
//       ...previewImages[productIndex],
//       images,
//     };
//     setPreviewImages(updatedPreviewImages);

//     // dataを更新
//     const picUrlKey = Object.keys(data[0]).find(
//       (key) => key.toLowerCase() === 'picurl'
//     );
//     const updatedData = [...data];
//     const item = { ...updatedData[product.rowIndex] };
//     item[picUrlKey] = images.map((img) => img.url).join('|');
//     updatedData[product.rowIndex] = item;
//     setData(updatedData);
//   };

//   return (
//     <>
//       {/* プレビューダイアログ */}
//       <Dialog
//         open={openPreviewDialog}
//         onClose={() => setOpenPreviewDialog(false)}
//         maxWidth="xl"
//         fullWidth
//       >
//         <DialogTitle>
//           画像編集・削除
//           <IconButton
//             aria-label="close"
//             onClick={() => setOpenPreviewDialog(false)}
//             sx={{
//               position: 'absolute',
//               right: 8,
//               top: 8,
//               color: (theme) => theme.palette.grey[500],
//             }}
//           >
//             <CloseIcon />
//           </IconButton>
//         </DialogTitle>
//         <DialogContent>
//           {/* 表示件数の選択 */}
//           <Box sx={{ mb: 2, display: 'flex', alignItems: 'center' }}>
//             <Typography variant="body2" sx={{ mr: 2 }}>
//               表示件数:
//             </Typography>
//             <FormControl variant="outlined" size="small">
//               <Select
//                 value={itemsPerPage}
//                 onChange={handleItemsPerPageChange}
//                 sx={{ minWidth: 120 }}
//               >
//                 <MenuItem value={50}>50</MenuItem>
//                 <MenuItem value={100}>100</MenuItem>
//                 <MenuItem value={150}>150</MenuItem>
//                 <MenuItem value={200}>200</MenuItem>
//                 <MenuItem value="ALL">ALL</MenuItem>
//               </Select>
//             </FormControl>
//           </Box>
//           <Typography variant="body2" sx={{ mb: 2 }}>
//             削除したい画像の「×」をクリックしてください。画像をドラッグ＆ドロップで並び替えることもできます。
//           </Typography>
//           <Box
//             ref={scrollableContainerRef} // ここでrefを設定
//             sx={{ maxHeight: '70vh', overflowY: 'auto', position: 'relative' }}
//           >
//             {displayedPreviewImages.map((product, index) => (
//               <Box key={index} sx={{ mb: 4 }}>
//                 <Typography variant="h6">{product.title}</Typography>

//                 {/* 商品を削除するボタンを追加 */}
//                 <Button
//                   variant="outlined"
//                   color="error"
//                   onClick={() => handleDeleteProduct(product.rowIndex)}
//                   sx={{ mb: 2 }}
//                 >
//                   商品を削除
//                 </Button>

//                 <DragDropContext
//                   onDragEnd={(result) => handleDragEnd(result, product)}
//                 >
//                   <Droppable
//                     droppableId={`droppable-${product.rowIndex}`}
//                     direction="horizontal"
//                   >
//                     {(provided) => (
//                       <Grid
//                         container
//                         spacing={2}
//                         ref={provided.innerRef}
//                         {...provided.droppableProps}
//                       >
//                         {product.images.map((image, idx) => (
//                           <Draggable
//                             key={image.url}
//                             draggableId={image.url}
//                             index={idx}
//                           >
//                             {(provided) => (
//                               <Grid
//                                 item
//                                 xs={3}
//                                 sm={2}
//                                 md={1.5}
//                                 lg={1}
//                                 ref={provided.innerRef}
//                                 {...provided.draggableProps}
//                                 {...provided.dragHandleProps}
//                               >
//                                 <Paper
//                                   elevation={1}
//                                   sx={{
//                                     p: 1,
//                                     position: 'relative',
//                                     opacity: selectedImages.has(image.url)
//                                       ? 0.4
//                                       : 1,
//                                   }}
//                                 >
//                                   <IconButton
//                                     aria-label="delete"
//                                     onClick={() =>
//                                       toggleImageSelection(image.url)
//                                     }
//                                     sx={{
//                                       position: 'absolute',
//                                       top: 0,
//                                       right: 0,
//                                       color: (theme) =>
//                                         theme.palette.error.main,
//                                     }}
//                                   >
//                                     <CloseIcon />
//                                   </IconButton>
//                                   <img
//                                     src={image.url}
//                                     alt={`Row ${product.rowIndex + 1} Image ${
//                                       image.imageIndex
//                                     }`}
//                                     style={{ width: '100%', height: 'auto' }}
//                                     loading="lazy"
//                                   />
//                                   <Typography variant="caption">
//                                     画像 {idx + 1}
//                                   </Typography>
//                                 </Paper>
//                               </Grid>
//                             )}
//                           </Draggable>
//                         ))}

//                         {provided.placeholder}

//                         {/* プラスアイコンのDraggableアイテム */}
//                         <Draggable
//                           key={`add-image-${product.rowIndex}`}
//                           draggableId={`add-image-${product.rowIndex}`}
//                           index={product.images.length}
//                           isDragDisabled={true} // ドラッグ不可に設定
//                         >
//                           {(provided) => (
//                             <Grid
//                               item
//                               xs={3}
//                               sm={2}
//                               md={1.5}
//                               lg={1}
//                               ref={provided.innerRef}
//                               {...provided.draggableProps}
//                             >
//                               <Paper
//                                 elevation={1}
//                                 sx={{
//                                   p: 1,
//                                   display: 'flex',
//                                   alignItems: 'center',
//                                   justifyContent: 'center',
//                                   height: '100%',
//                                   border: '2px dashed #1976d2',
//                                   color: '#1976d2',
//                                   cursor: 'pointer',
//                                 }}
//                                 onClick={() => handleAddImageClick(product)}
//                               >
//                                 <Typography variant="h4">+</Typography>
//                               </Paper>
//                             </Grid>
//                           )}
//                         </Draggable>
//                       </Grid>
//                     )}
//                   </Droppable>
//                 </DragDropContext>
//               </Box>
//             ))}
//           </Box>
//           {/* スクロールトップボタン */}
//           {showScrollTopButton && (
//             <IconButton
//               onClick={() => {
//                 if (scrollableContainerRef.current) {
//                   scrollableContainerRef.current.scrollTo({ top: 0, behavior: 'smooth' });
//                 }
//               }}
//               sx={{
//                 position: 'fixed',
//                 bottom: 100,
//                 right: 40,
//                 zIndex: 1000,
//                 backgroundColor: theme.palette.background.paper,
//                 '&:hover': {
//                   backgroundColor: theme.palette.grey[300],
//                 },
//               }}
//             >
//               <KeyboardArrowUpIcon />
//             </IconButton>
//           )}
//           {/* ページネーション */}
//           {totalPages > 1 && (
//             <Box sx={{ mt: 2, display: 'flex', justifyContent: 'center' }}>
//               <Pagination
//                 count={totalPages}
//                 page={currentPage}
//                 onChange={handlePageChange}
//                 color="primary"
//               />
//             </Box>
//           )}
//         </DialogContent>
//         <DialogActions>
//           <Button onClick={() => setOpenPreviewDialog(false)}>閉じる</Button>
//           <Button
//             onClick={() => {
//               setOpenConfirmDialog(true);
//             }}
//             color="primary"
//             variant="contained"
//           >
//             削除実行
//           </Button>
//         </DialogActions>
//       </Dialog>

//       {/* 画像追加ダイアログ */}
//       <Dialog
//         open={isAddImageDialogOpen}
//         onClose={() => setIsAddImageDialogOpen(false)}
//       >
//         <DialogTitle>画像を追加</DialogTitle>
//         <DialogContent>
//           <Typography variant="body2" sx={{ mb: 2 }}>
//             画像のURLを入力するか、画像ファイルをアップロードしてください。
//           </Typography>
//           <TextField
//             label="画像URL"
//             value={newImageURL}
//             onChange={(e) => setNewImageURL(e.target.value)}
//             fullWidth
//             sx={{ mb: 2 }}
//           />
//           <Typography variant="body2" sx={{ mb: 1 }}>
//             または
//           </Typography>
//           <Button variant="contained" component="label">
//             画像を選択
//             <input
//               type="file"
//               accept="image/*"
//               hidden
//               onChange={handleImageFileChange}
//             />
//           </Button>
//           {newImageFile && (
//             <Typography variant="body2" sx={{ mt: 1 }}>
//               選択されたファイル: {newImageFile.name}
//             </Typography>
//           )}
//         </DialogContent>
//         <DialogActions>
//           <Button
//             onClick={() => {
//               setIsAddImageDialogOpen(false);
//               setNewImageFile(null);
//               setNewImageURL('');
//             }}
//           >
//             キャンセル
//           </Button>
//           <Button onClick={handleAddImage} color="primary" variant="contained">
//             追加
//           </Button>
//         </DialogActions>
//       </Dialog>

//       {/* 画像削除確認ダイアログ */}
//       <Dialog
//         open={openConfirmDialog}
//         onClose={() => setOpenConfirmDialog(false)}
//         aria-labelledby="alert-dialog-title"
//         aria-describedby="alert-dialog-description"
//       >
//         <DialogTitle id="alert-dialog-title">{'確認'}</DialogTitle>
//         <DialogContent>
//           <DialogContentText id="alert-dialog-description">
//             選択された画像が商品データから削除されます。よろしいですか？
//           </DialogContentText>
//         </DialogContent>
//         <DialogActions>
//           <Button onClick={() => setOpenConfirmDialog(false)} color="primary">
//             キャンセル
//           </Button>
//           <Button onClick={handleDelete} color="primary" autoFocus>
//             実行
//           </Button>
//         </DialogActions>
//       </Dialog>

//       {/* 商品削除確認ダイアログ */}
//       <Dialog
//         open={openProductDeleteConfirmDialog}
//         onClose={() => setOpenProductDeleteConfirmDialog(false)}
//       >
//         <DialogTitle>商品を削除</DialogTitle>
//         <DialogContent>
//           <DialogContentText>
//             この商品を削除してもよろしいですか？
//           </DialogContentText>
//         </DialogContent>
//         <DialogActions>
//           <Button onClick={() => setOpenProductDeleteConfirmDialog(false)}>
//             キャンセル
//           </Button>
//           <Button
//             onClick={() => {
//               confirmDeleteProduct(productToDelete);
//               setOpenProductDeleteConfirmDialog(false);
//             }}
//             color="error"
//           >
//             削除
//           </Button>
//         </DialogActions>
//       </Dialog>
//     </>
//   );
// }

// export default ImageOperations;

//--------------------------------------------------------------------------------------------




// // ImageOperations.js

// import React, { useState, useCallback, useEffect } from 'react';
// import {
//   Button,
//   Typography,
//   Box,
//   Dialog,
//   DialogActions,
//   DialogContent,
//   DialogTitle,
//   Paper,
//   Grid,
//   IconButton,
//   DialogContentText,
//   TextField,
//   MenuItem,
//   Select,
//   FormControl,
//   InputLabel,
//   Pagination,
// } from '@mui/material';
// import { useTheme } from '@mui/material/styles';
// import CloseIcon from '@mui/icons-material/Close';
// import { DragDropContext, Droppable, Draggable } from '@hello-pangea/dnd';
// import {
//   getStorage,
//   ref as storageRef,
//   uploadBytes,
//   getDownloadURL,
// } from 'firebase/storage';
// import { v4 as uuidv4 } from 'uuid';

// function ImageOperations({
//   data,
//   setData,
//   startRow,
//   endRow,
//   calculateMaxEndRow,
//   isProcessing,
//   setIsProcessing,
//   setError,
//   showSnackbar,
//   openPreviewDialog,
//   setOpenPreviewDialog,
// }) {
//   const [previewImages, setPreviewImages] = useState([]);
//   const [displayedPreviewImages, setDisplayedPreviewImages] = useState([]);
//   const [selectedImages, setSelectedImages] = useState(new Set());
//   const [selectedProductForEdit, setSelectedProductForEdit] = useState(null);
//   const [isAddImageDialogOpen, setIsAddImageDialogOpen] = useState(false);
//   const [newImageFile, setNewImageFile] = useState(null);
//   const [newImageURL, setNewImageURL] = useState('');
//   const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
//   const [openProductDeleteConfirmDialog, setOpenProductDeleteConfirmDialog] = useState(false);
//   const [productToDelete, setProductToDelete] = useState(null);

//   const [itemsPerPage, setItemsPerPage] = useState(50); // 1ページあたりの表示件数
//   const [currentPage, setCurrentPage] = useState(1); // 現在のページ番号

//   const theme = useTheme();

//   const handleOpenPreviewDialog = useCallback(() => {
//     try {
//       if (!startRow || !endRow || isNaN(startRow) || isNaN(endRow)) {
//         throw new Error('Start RowとEnd Rowは有効な数字である必要があります。');
//       }

//       const picUrlKey = Object.keys(data[0]).find(
//         (key) => key.toLowerCase() === 'picurl'
//       );
//       const titleKey = Object.keys(data[0]).find(
//         (key) => key.toLowerCase() === 'title'
//       );

//       if (!picUrlKey) {
//         throw new Error('PicURLカラムが見つかりません。');
//       }

//       const actualStartRow = parseInt(startRow) - 1; // インデックスは0から開始
//       const maxEndRow = calculateMaxEndRow();
//       const actualEndRow = Math.min(parseInt(endRow) - 1, maxEndRow - 1);

//       if (actualStartRow < 0 || actualEndRow < actualStartRow) {
//         throw new Error('Start RowとEnd Rowが有効ではありません。');
//       }

//       let previewItems = [];

//       // すべての画像を取得
//       for (let i = actualStartRow; i <= actualEndRow; i++) {
//         const item = data[i];
//         const picUrl = item[picUrlKey];
//         const imageUrls = picUrl ? picUrl.split('|') : [];
//         const title = item[titleKey] || `商品 ${i + 1}`;
//         const images = imageUrls.map((url, idx) => ({
//           url,
//           imageIndex: idx + 1,
//         }));
//         if (images.length > 0) {
//           previewItems.push({
//             title,
//             rowIndex: i,
//             images,
//           });
//         }
//       }

//       setPreviewImages(previewItems);
//       setSelectedImages(new Set()); // すべて未選択
//       setCurrentPage(1); // ページをリセット
//     } catch (err) {
//       const errorMessage = `プレビューの準備中にエラーが発生しました: ${err.message}`;
//       setError(errorMessage);
//       console.error(errorMessage, err);
//     }
//   }, [data, startRow, endRow, calculateMaxEndRow, setError]);

//   // openPreviewDialogがtrueになったときにプレビューを準備
//   useEffect(() => {
//     if (openPreviewDialog) {
//       handleOpenPreviewDialog();
//     } else {
//       // ダイアログが閉じられたときにプレビュー画像をクリア
//       setPreviewImages([]);
//       setSelectedImages(new Set());
//     }
//   }, [openPreviewDialog, handleOpenPreviewDialog]);

//   // ページネーションの処理
//   useEffect(() => {
//     if (itemsPerPage === 'ALL') {
//       setDisplayedPreviewImages(previewImages);
//     } else {
//       const startIndex = (currentPage - 1) * itemsPerPage;
//       const endIndex = startIndex + itemsPerPage;
//       setDisplayedPreviewImages(previewImages.slice(startIndex, endIndex));
//     }
//   }, [previewImages, currentPage, itemsPerPage]);

//   const totalPages = itemsPerPage === 'ALL' ? 1 : Math.ceil(previewImages.length / itemsPerPage);

//   const handleItemsPerPageChange = (event) => {
//     const value = event.target.value === 'ALL' ? 'ALL' : parseInt(event.target.value);
//     setItemsPerPage(value);
//     setCurrentPage(1); // ページをリセット
//   };

//   const handlePageChange = (event, value) => {
//     setCurrentPage(value);
//   };

//   const toggleImageSelection = (url) => {
//     setSelectedImages((prevSelected) => {
//       const newSelected = new Set(prevSelected);
//       if (newSelected.has(url)) {
//         newSelected.delete(url);
//       } else {
//         newSelected.add(url);
//       }
//       return newSelected;
//     });
//   };

//   const handleDelete = useCallback(async () => {
//     setIsProcessing(true);
//     setError('');

//     try {
//       if (!startRow || !endRow || isNaN(startRow) || isNaN(endRow)) {
//         throw new Error('Start RowとEnd Rowは有効な数字である必要があります。');
//       }

//       const picUrlKey = Object.keys(data[0]).find(
//         (key) => key.toLowerCase() === 'picurl'
//       );

//       if (!picUrlKey) {
//         throw new Error('PicURLカラムが見つかりません。');
//       }

//       const actualStartRow = parseInt(startRow) - 1; // インデックスは0から開始
//       const maxEndRow = calculateMaxEndRow();
//       const actualEndRow = Math.min(parseInt(endRow) - 1, maxEndRow - 1);

//       if (actualStartRow < 0 || actualEndRow < actualStartRow) {
//         throw new Error('Start RowとEnd Rowが有効ではありません。');
//       }

//       // データの更新
//       const updatedData = [...data];
//       for (let i = actualStartRow; i <= actualEndRow; i++) {
//         const item = { ...updatedData[i] };
//         const originalImages = item[picUrlKey]
//           ? item[picUrlKey].split('|')
//           : [];
//         const newImages = originalImages.filter(
//           (url) => !selectedImages.has(url)
//         );
//         item[picUrlKey] = newImages.join('|');
//         updatedData[i] = item;
//       }

//       setData(updatedData);

//       // プレビュー画像を更新
//       const updatedPreviewImages = previewImages.map((product) => {
//         const item = updatedData[product.rowIndex];
//         const picUrl = item[picUrlKey];
//         const imageUrls = picUrl ? picUrl.split('|') : [];
//         const images = imageUrls.map((url, idx) => ({
//           url,
//           imageIndex: idx + 1,
//         }));
//         return {
//           ...product,
//           images,
//         };
//       });
//       setPreviewImages(updatedPreviewImages);

//       const finalMessage = `画像の削除が完了しました。`;
//       showSnackbar(finalMessage);
//     } catch (err) {
//       const errorMessage = `画像の削除に失敗しました: ${err.message}`;
//       setError(errorMessage);
//       showSnackbar(errorMessage);
//       console.error(errorMessage, err);
//     } finally {
//       setIsProcessing(false);
//       setOpenConfirmDialog(false);
//       setOpenPreviewDialog(false);
//     }
//   }, [
//     data,
//     startRow,
//     endRow,
//     selectedImages,
//     calculateMaxEndRow,
//     setData,
//     previewImages,
//     setIsProcessing,
//     setError,
//     showSnackbar,
//     setOpenPreviewDialog,
//   ]);

//   const handleAddImageClick = (product) => {
//     setSelectedProductForEdit(product);
//     setIsAddImageDialogOpen(true);
//   };

//   const handleImageFileChange = (e) => {
//     if (e.target.files && e.target.files[0]) {
//       setNewImageFile(e.target.files[0]);
//       setNewImageURL('');
//     }
//   };

//   const handleAddImage = async () => {
//     const storage = getStorage();

//     try {
//       let imageUrl = newImageURL;

//       if (newImageFile) {
//         // Firebase Storage に画像をアップロード
//         const fileExtension = newImageFile.name.split('.').pop();
//         const storageReference = storageRef(
//           storage,
//           `images/${uuidv4()}.${fileExtension}`
//         );
//         await uploadBytes(storageReference, newImageFile);
//         imageUrl = await getDownloadURL(storageReference);
//       }

//       if (!imageUrl) {
//         alert('画像のURLを入力するか、画像ファイルをアップロードしてください。');
//         return;
//       }

//       // データの更新
//       const picUrlKey = Object.keys(data[0]).find(
//         (key) => key.toLowerCase() === 'picurl'
//       );
//       const updatedData = [...data];
//       const item = { ...updatedData[selectedProductForEdit.rowIndex] };
//       const imageUrls = item[picUrlKey] ? item[picUrlKey].split('|') : [];
//       imageUrls.push(imageUrl);
//       item[picUrlKey] = imageUrls.join('|');
//       updatedData[selectedProductForEdit.rowIndex] = item;
//       setData(updatedData);

//       // previewImagesを更新
//       const updatedPreviewImages = previewImages.map((product) => {
//         if (product.rowIndex === selectedProductForEdit.rowIndex) {
//           const images = [
//             ...product.images,
//             { url: imageUrl, imageIndex: product.images.length + 1 },
//           ];
//           return {
//             ...product,
//             images,
//           };
//         }
//         return product;
//       });
//       setPreviewImages(updatedPreviewImages);

//       setIsAddImageDialogOpen(false);
//       setNewImageFile(null);
//       setNewImageURL('');
//     } catch (error) {
//       console.error('画像の追加中にエラーが発生しました:', error);
//       alert('画像の追加に失敗しました。再度お試しください。');
//     }
//   };

//   // 商品削除の関数
//   const handleDeleteProduct = (rowIndex) => {
//     setProductToDelete(rowIndex);
//     setOpenProductDeleteConfirmDialog(true);
//   };

//   // 削除を確定する関数
//   const confirmDeleteProduct = (rowIndex) => {
//     // データから商品を削除
//     const updatedData = data.filter((_, index) => index !== rowIndex);
//     setData(updatedData);

//     // プレビュー画像から商品を削除
//     const updatedPreviewImages = previewImages.filter(
//       (product) => product.rowIndex !== rowIndex
//     );

//     // インデックスを再計算
//     const recalculatedPreviewImages = updatedPreviewImages.map((product) => {
//       return {
//         ...product,
//         rowIndex:
//           product.rowIndex > rowIndex ? product.rowIndex - 1 : product.rowIndex,
//       };
//     });

//     setPreviewImages(recalculatedPreviewImages);
//   };

//   // ドラッグ終了時のハンドラー
//   const handleDragEnd = (result, product) => {
//     if (!result.destination) {
//       return;
//     }

//     const { source, destination } = result;

//     const productIndex = previewImages.findIndex(
//       (p) => p.rowIndex === product.rowIndex
//     );
//     if (productIndex === -1) {
//       return;
//     }

//     const images = Array.from(previewImages[productIndex].images);
//     const [movedImage] = images.splice(source.index, 1);
//     images.splice(destination.index, 0, movedImage);

//     // previewImagesを更新
//     const updatedPreviewImages = [...previewImages];
//     updatedPreviewImages[productIndex] = {
//       ...previewImages[productIndex],
//       images,
//     };
//     setPreviewImages(updatedPreviewImages);

//     // dataを更新
//     const picUrlKey = Object.keys(data[0]).find(
//       (key) => key.toLowerCase() === 'picurl'
//     );
//     const updatedData = [...data];
//     const item = { ...updatedData[product.rowIndex] };
//     item[picUrlKey] = images.map((img) => img.url).join('|');
//     updatedData[product.rowIndex] = item;
//     setData(updatedData);
//   };

//   return (
//     <>
//       {/* プレビューダイアログ */}
//       <Dialog
//         open={openPreviewDialog}
//         onClose={() => setOpenPreviewDialog(false)}
//         maxWidth="xl"
//         fullWidth
//       >
//         <DialogTitle>
//           画像編集・削除
//           <IconButton
//             aria-label="close"
//             onClick={() => setOpenPreviewDialog(false)}
//             sx={{
//               position: 'absolute',
//               right: 8,
//               top: 8,
//               color: (theme) => theme.palette.grey[500],
//             }}
//           >
//             <CloseIcon />
//           </IconButton>
//         </DialogTitle>
//         <DialogContent>
//           {/* 表示件数の選択 */}
//           <Box sx={{ mb: 2, display: 'flex', alignItems: 'center' }}>
//             <Typography variant="body2" sx={{ mr: 2 }}>
//               表示件数:
//             </Typography>
//             <FormControl variant="outlined" size="small">
//               <Select
//                 value={itemsPerPage}
//                 onChange={handleItemsPerPageChange}
//                 sx={{ minWidth: 120 }}
//               >
//                 <MenuItem value={50}>50</MenuItem>
//                 <MenuItem value={100}>100</MenuItem>
//                 <MenuItem value={150}>150</MenuItem>
//                 <MenuItem value={200}>200</MenuItem>
//                 <MenuItem value="ALL">ALL</MenuItem>
//               </Select>
//             </FormControl>
//           </Box>
//           <Typography variant="body2" sx={{ mb: 2 }}>
//             削除したい画像の「×」をクリックしてください。画像をドラッグ＆ドロップで並び替えることもできます。
//           </Typography>
//           <Box sx={{ maxHeight: '70vh', overflowY: 'auto' }}>
//             {displayedPreviewImages.map((product, index) => (
//               <Box key={index} sx={{ mb: 4 }}>
//                 <Typography variant="h6">{product.title}</Typography>

//                 {/* 商品を削除するボタンを追加 */}
//                 <Button
//                   variant="outlined"
//                   color="error"
//                   onClick={() => handleDeleteProduct(product.rowIndex)}
//                   sx={{ mb: 2 }}
//                 >
//                   商品を削除
//                 </Button>

//                 <DragDropContext
//                   onDragEnd={(result) => handleDragEnd(result, product)}
//                 >
//                   <Droppable
//                     droppableId={`droppable-${product.rowIndex}`}
//                     direction="horizontal"
//                   >
//                     {(provided) => (
//                       <Grid
//                         container
//                         spacing={2}
//                         ref={provided.innerRef}
//                         {...provided.droppableProps}
//                       >
//                         {product.images.map((image, idx) => (
//                           <Draggable
//                             key={image.url}
//                             draggableId={image.url}
//                             index={idx}
//                           >
//                             {(provided) => (
//                               <Grid
//                                 item
//                                 xs={3}
//                                 sm={2}
//                                 md={1.5}
//                                 lg={1}
//                                 ref={provided.innerRef}
//                                 {...provided.draggableProps}
//                                 {...provided.dragHandleProps}
//                               >
//                                 <Paper
//                                   elevation={1}
//                                   sx={{
//                                     p: 1,
//                                     position: 'relative',
//                                     opacity: selectedImages.has(image.url)
//                                       ? 0.4
//                                       : 1,
//                                   }}
//                                 >
//                                   <IconButton
//                                     aria-label="delete"
//                                     onClick={() =>
//                                       toggleImageSelection(image.url)
//                                     }
//                                     sx={{
//                                       position: 'absolute',
//                                       top: 0,
//                                       right: 0,
//                                       color: (theme) =>
//                                         theme.palette.error.main,
//                                     }}
//                                   >
//                                     <CloseIcon />
//                                   </IconButton>
//                                   <img
//                                     src={image.url}
//                                     alt={`Row ${product.rowIndex + 1} Image ${
//                                       image.imageIndex
//                                     }`}
//                                     style={{ width: '100%', height: 'auto' }}
//                                     loading="lazy"
//                                   />
//                                   <Typography variant="caption">
//                                     画像 {idx + 1}
//                                   </Typography>
//                                 </Paper>
//                               </Grid>
//                             )}
//                           </Draggable>
//                         ))}

//                         {provided.placeholder}

//                         {/* プラスアイコンのDraggableアイテム */}
//                         <Draggable
//                           key={`add-image-${product.rowIndex}`}
//                           draggableId={`add-image-${product.rowIndex}`}
//                           index={product.images.length}
//                           isDragDisabled={true} // ドラッグ不可に設定
//                         >
//                           {(provided) => (
//                             <Grid
//                               item
//                               xs={3}
//                               sm={2}
//                               md={1.5}
//                               lg={1}
//                               ref={provided.innerRef}
//                               {...provided.draggableProps}
//                             >
//                               <Paper
//                                 elevation={1}
//                                 sx={{
//                                   p: 1,
//                                   display: 'flex',
//                                   alignItems: 'center',
//                                   justifyContent: 'center',
//                                   height: '100%',
//                                   border: '2px dashed #1976d2',
//                                   color: '#1976d2',
//                                   cursor: 'pointer',
//                                 }}
//                                 onClick={() => handleAddImageClick(product)}
//                               >
//                                 <Typography variant="h4">+</Typography>
//                               </Paper>
//                             </Grid>
//                           )}
//                         </Draggable>
//                       </Grid>
//                     )}
//                   </Droppable>
//                 </DragDropContext>
//               </Box>
//             ))}
//           </Box>
//           {/* ページネーション */}
//           {totalPages > 1 && (
//             <Box sx={{ mt: 2, display: 'flex', justifyContent: 'center' }}>
//               <Pagination
//                 count={totalPages}
//                 page={currentPage}
//                 onChange={handlePageChange}
//                 color="primary"
//               />
//             </Box>
//           )}
//         </DialogContent>
//         <DialogActions>
//           <Button onClick={() => setOpenPreviewDialog(false)}>閉じる</Button>
//           <Button
//             onClick={() => {
//               setOpenConfirmDialog(true);
//             }}
//             color="primary"
//             variant="contained"
//           >
//             削除実行
//           </Button>
//         </DialogActions>
//       </Dialog>

//       {/* 画像追加ダイアログ */}
//       <Dialog
//         open={isAddImageDialogOpen}
//         onClose={() => setIsAddImageDialogOpen(false)}
//       >
//         <DialogTitle>画像を追加</DialogTitle>
//         <DialogContent>
//           <Typography variant="body2" sx={{ mb: 2 }}>
//             画像のURLを入力するか、画像ファイルをアップロードしてください。
//           </Typography>
//           <TextField
//             label="画像URL"
//             value={newImageURL}
//             onChange={(e) => setNewImageURL(e.target.value)}
//             fullWidth
//             sx={{ mb: 2 }}
//           />
//           <Typography variant="body2" sx={{ mb: 1 }}>
//             または
//           </Typography>
//           <Button variant="contained" component="label">
//             画像を選択
//             <input
//               type="file"
//               accept="image/*"
//               hidden
//               onChange={handleImageFileChange}
//             />
//           </Button>
//           {newImageFile && (
//             <Typography variant="body2" sx={{ mt: 1 }}>
//               選択されたファイル: {newImageFile.name}
//             </Typography>
//           )}
//         </DialogContent>
//         <DialogActions>
//           <Button
//             onClick={() => {
//               setIsAddImageDialogOpen(false);
//               setNewImageFile(null);
//               setNewImageURL('');
//             }}
//           >
//             キャンセル
//           </Button>
//           <Button onClick={handleAddImage} color="primary" variant="contained">
//             追加
//           </Button>
//         </DialogActions>
//       </Dialog>

//       {/* 画像削除確認ダイアログ */}
//       <Dialog
//         open={openConfirmDialog}
//         onClose={() => setOpenConfirmDialog(false)}
//         aria-labelledby="alert-dialog-title"
//         aria-describedby="alert-dialog-description"
//       >
//         <DialogTitle id="alert-dialog-title">{'確認'}</DialogTitle>
//         <DialogContent>
//           <DialogContentText id="alert-dialog-description">
//             選択された画像が商品データから削除されます。よろしいですか？
//           </DialogContentText>
//         </DialogContent>
//         <DialogActions>
//           <Button onClick={() => setOpenConfirmDialog(false)} color="primary">
//             キャンセル
//           </Button>
//           <Button onClick={handleDelete} color="primary" autoFocus>
//             実行
//           </Button>
//         </DialogActions>
//       </Dialog>

//       {/* 商品削除確認ダイアログ */}
//       <Dialog
//         open={openProductDeleteConfirmDialog}
//         onClose={() => setOpenProductDeleteConfirmDialog(false)}
//       >
//         <DialogTitle>商品を削除</DialogTitle>
//         <DialogContent>
//           <DialogContentText>
//             この商品を削除してもよろしいですか？
//           </DialogContentText>
//         </DialogContent>
//         <DialogActions>
//           <Button onClick={() => setOpenProductDeleteConfirmDialog(false)}>
//             キャンセル
//           </Button>
//           <Button
//             onClick={() => {
//               confirmDeleteProduct(productToDelete);
//               setOpenProductDeleteConfirmDialog(false);
//             }}
//             color="error"
//           >
//             削除
//           </Button>
//         </DialogActions>
//       </Dialog>
//     </>
//   );
// }

// export default ImageOperations;





// // ImageOperations.js

// import React, { useState, useCallback, useEffect } from 'react';
// import {
//   Button,
//   Typography,
//   Box,
//   Dialog,
//   DialogActions,
//   DialogContent,
//   DialogTitle,
//   Paper,
//   Grid,
//   IconButton,
//   DialogContentText,
//   TextField,
// } from '@mui/material';
// import { useTheme } from '@mui/material/styles';
// import CloseIcon from '@mui/icons-material/Close';
// import { DragDropContext, Droppable, Draggable } from '@hello-pangea/dnd';
// import {
//   getStorage,
//   ref as storageRef,
//   uploadBytes,
//   getDownloadURL,
// } from 'firebase/storage';
// import { v4 as uuidv4 } from 'uuid';

// function ImageOperations({
//   data,
//   setData,
//   startRow,
//   endRow,
//   calculateMaxEndRow,
//   isProcessing,
//   setIsProcessing,
//   setError,
//   showSnackbar,
//   openPreviewDialog,
//   setOpenPreviewDialog,
// }) {
//   const [previewImages, setPreviewImages] = useState([]);
//   const [selectedImages, setSelectedImages] = useState(new Set());
//   const [selectedProductForEdit, setSelectedProductForEdit] = useState(null);
//   const [isAddImageDialogOpen, setIsAddImageDialogOpen] = useState(false);
//   const [newImageFile, setNewImageFile] = useState(null);
//   const [newImageURL, setNewImageURL] = useState('');
//   const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
//   const [openProductDeleteConfirmDialog, setOpenProductDeleteConfirmDialog] = useState(false);
//   const [productToDelete, setProductToDelete] = useState(null);

//   const theme = useTheme();

//   const handleOpenPreviewDialog = useCallback(() => {
//     try {
//       if (!startRow || !endRow || isNaN(startRow) || isNaN(endRow)) {
//         throw new Error('Start RowとEnd Rowは有効な数字である必要があります。');
//       }

//       const picUrlKey = Object.keys(data[0]).find(
//         (key) => key.toLowerCase() === 'picurl'
//       );
//       const titleKey = Object.keys(data[0]).find(
//         (key) => key.toLowerCase() === 'title'
//       );

//       if (!picUrlKey) {
//         throw new Error('PicURLカラムが見つかりません。');
//       }

//       const actualStartRow = parseInt(startRow) - 1; // インデックスは0から開始
//       const maxEndRow = calculateMaxEndRow();
//       const actualEndRow = Math.min(parseInt(endRow) - 1, maxEndRow - 1);

//       if (actualStartRow < 0 || actualEndRow < actualStartRow) {
//         throw new Error('Start RowとEnd Rowが有効ではありません。');
//       }

//       let previewItems = [];

//       // すべての画像を取得
//       for (let i = actualStartRow; i <= actualEndRow; i++) {
//         const item = data[i];
//         const picUrl = item[picUrlKey];
//         const imageUrls = picUrl ? picUrl.split('|') : [];
//         const title = item[titleKey] || `商品 ${i + 1}`;
//         const images = imageUrls.map((url, idx) => ({
//           url,
//           imageIndex: idx + 1,
//         }));
//         if (images.length > 0) {
//           previewItems.push({
//             title,
//             rowIndex: i,
//             images,
//           });
//         }
//       }

//       setPreviewImages(previewItems);
//       setSelectedImages(new Set()); // すべて未選択
//     } catch (err) {
//       const errorMessage = `プレビューの準備中にエラーが発生しました: ${err.message}`;
//       setError(errorMessage);
//       console.error(errorMessage, err);
//     }
//   }, [data, startRow, endRow, calculateMaxEndRow, setError]);

//   // openPreviewDialogがtrueになったときにプレビューを準備
//   useEffect(() => {
//     if (openPreviewDialog) {
//       handleOpenPreviewDialog();
//     } else {
//       // ダイアログが閉じられたときにプレビュー画像をクリア
//       setPreviewImages([]);
//       setSelectedImages(new Set());
//     }
//   }, [openPreviewDialog, handleOpenPreviewDialog]);

//   const toggleImageSelection = (url) => {
//     setSelectedImages((prevSelected) => {
//       const newSelected = new Set(prevSelected);
//       if (newSelected.has(url)) {
//         newSelected.delete(url);
//       } else {
//         newSelected.add(url);
//       }
//       return newSelected;
//     });
//   };

//   const handleDelete = useCallback(async () => {
//     setIsProcessing(true);
//     setError('');

//     try {
//       if (!startRow || !endRow || isNaN(startRow) || isNaN(endRow)) {
//         throw new Error('Start RowとEnd Rowは有効な数字である必要があります。');
//       }

//       const picUrlKey = Object.keys(data[0]).find(
//         (key) => key.toLowerCase() === 'picurl'
//       );

//       if (!picUrlKey) {
//         throw new Error('PicURLカラムが見つかりません。');
//       }

//       const actualStartRow = parseInt(startRow) - 1; // インデックスは0から開始
//       const maxEndRow = calculateMaxEndRow();
//       const actualEndRow = Math.min(parseInt(endRow) - 1, maxEndRow - 1);

//       if (actualStartRow < 0 || actualEndRow < actualStartRow) {
//         throw new Error('Start RowとEnd Rowが有効ではありません。');
//       }

//       // データの更新
//       const updatedData = [...data];
//       for (let i = actualStartRow; i <= actualEndRow; i++) {
//         const item = { ...updatedData[i] };
//         const originalImages = item[picUrlKey]
//           ? item[picUrlKey].split('|')
//           : [];
//         const newImages = originalImages.filter(
//           (url) => !selectedImages.has(url)
//         );
//         item[picUrlKey] = newImages.join('|');
//         updatedData[i] = item;
//       }

//       setData(updatedData);

//       // プレビュー画像を更新
//       const updatedPreviewImages = previewImages.map((product) => {
//         const item = updatedData[product.rowIndex];
//         const picUrl = item[picUrlKey];
//         const imageUrls = picUrl ? picUrl.split('|') : [];
//         const images = imageUrls.map((url, idx) => ({
//           url,
//           imageIndex: idx + 1,
//         }));
//         return {
//           ...product,
//           images,
//         };
//       });
//       setPreviewImages(updatedPreviewImages);

//       const finalMessage = `画像の削除が完了しました。`;
//       showSnackbar(finalMessage);
//     } catch (err) {
//       const errorMessage = `画像の削除に失敗しました: ${err.message}`;
//       setError(errorMessage);
//       showSnackbar(errorMessage);
//       console.error(errorMessage, err);
//     } finally {
//       setIsProcessing(false);
//       setOpenConfirmDialog(false);
//       setOpenPreviewDialog(false);
//     }
//   }, [
//     data,
//     startRow,
//     endRow,
//     selectedImages,
//     calculateMaxEndRow,
//     setData,
//     previewImages,
//     setIsProcessing,
//     setError,
//     showSnackbar,
//     setOpenPreviewDialog,
//   ]);

//   const handleAddImageClick = (product) => {
//     setSelectedProductForEdit(product);
//     setIsAddImageDialogOpen(true);
//   };

//   const handleImageFileChange = (e) => {
//     if (e.target.files && e.target.files[0]) {
//       setNewImageFile(e.target.files[0]);
//       setNewImageURL('');
//     }
//   };

//   const handleAddImage = async () => {
//     const storage = getStorage();

//     try {
//       let imageUrl = newImageURL;

//       if (newImageFile) {
//         // Firebase Storage に画像をアップロード
//         const fileExtension = newImageFile.name.split('.').pop();
//         const storageReference = storageRef(
//           storage,
//           `images/${uuidv4()}.${fileExtension}`
//         );
//         await uploadBytes(storageReference, newImageFile);
//         imageUrl = await getDownloadURL(storageReference);
//       }

//       if (!imageUrl) {
//         alert('画像のURLを入力するか、画像ファイルをアップロードしてください。');
//         return;
//       }

//       // データの更新
//       const picUrlKey = Object.keys(data[0]).find(
//         (key) => key.toLowerCase() === 'picurl'
//       );
//       const updatedData = [...data];
//       const item = { ...updatedData[selectedProductForEdit.rowIndex] };
//       const imageUrls = item[picUrlKey] ? item[picUrlKey].split('|') : [];
//       imageUrls.push(imageUrl);
//       item[picUrlKey] = imageUrls.join('|');
//       updatedData[selectedProductForEdit.rowIndex] = item;
//       setData(updatedData);

//       // previewImagesを更新
//       const updatedPreviewImages = previewImages.map((product) => {
//         if (product.rowIndex === selectedProductForEdit.rowIndex) {
//           const images = [
//             ...product.images,
//             { url: imageUrl, imageIndex: product.images.length + 1 },
//           ];
//           return {
//             ...product,
//             images,
//           };
//         }
//         return product;
//       });
//       setPreviewImages(updatedPreviewImages);

//       setIsAddImageDialogOpen(false);
//       setNewImageFile(null);
//       setNewImageURL('');
//     } catch (error) {
//       console.error('画像の追加中にエラーが発生しました:', error);
//       alert('画像の追加に失敗しました。再度お試しください。');
//     }
//   };

//   // 商品削除の関数
//   const handleDeleteProduct = (rowIndex) => {
//     setProductToDelete(rowIndex);
//     setOpenProductDeleteConfirmDialog(true);
//   };

//   // 削除を確定する関数
//   const confirmDeleteProduct = (rowIndex) => {
//     // データから商品を削除
//     const updatedData = data.filter((_, index) => index !== rowIndex);
//     setData(updatedData);

//     // プレビュー画像から商品を削除
//     const updatedPreviewImages = previewImages.filter(
//       (product) => product.rowIndex !== rowIndex
//     );

//     // インデックスを再計算
//     const recalculatedPreviewImages = updatedPreviewImages.map((product) => {
//       return {
//         ...product,
//         rowIndex:
//           product.rowIndex > rowIndex ? product.rowIndex - 1 : product.rowIndex,
//       };
//     });

//     setPreviewImages(recalculatedPreviewImages);
//   };

//   // ドラッグ終了時のハンドラー
//   const handleDragEnd = (result, product) => {
//     if (!result.destination) {
//       return;
//     }

//     const { source, destination } = result;

//     const productIndex = previewImages.findIndex(
//       (p) => p.rowIndex === product.rowIndex
//     );
//     if (productIndex === -1) {
//       return;
//     }

//     const images = Array.from(previewImages[productIndex].images);
//     const [movedImage] = images.splice(source.index, 1);
//     images.splice(destination.index, 0, movedImage);

//     // previewImagesを更新
//     const updatedPreviewImages = [...previewImages];
//     updatedPreviewImages[productIndex] = {
//       ...previewImages[productIndex],
//       images,
//     };
//     setPreviewImages(updatedPreviewImages);

//     // dataを更新
//     const picUrlKey = Object.keys(data[0]).find(
//       (key) => key.toLowerCase() === 'picurl'
//     );
//     const updatedData = [...data];
//     const item = { ...updatedData[product.rowIndex] };
//     item[picUrlKey] = images.map((img) => img.url).join('|');
//     updatedData[product.rowIndex] = item;
//     setData(updatedData);
//   };

//   return (
//     <>
//       {/* プレビューダイアログ */}
//       <Dialog
//         open={openPreviewDialog}
//         onClose={() => setOpenPreviewDialog(false)}
//         maxWidth="xl"
//         fullWidth
//       >
//         <DialogTitle>
//           画像編集・削除
//           <IconButton
//             aria-label="close"
//             onClick={() => setOpenPreviewDialog(false)}
//             sx={{
//               position: 'absolute',
//               right: 8,
//               top: 8,
//               color: (theme) => theme.palette.grey[500],
//             }}
//           >
//             <CloseIcon />
//           </IconButton>
//         </DialogTitle>
//         <DialogContent>
//           <Typography variant="body2" sx={{ mb: 2 }}>
//             削除したい画像の「×」をクリックしてください。画像をドラッグ＆ドロップで並び替えることもできます。
//           </Typography>
//           <Box sx={{ maxHeight: '70vh', overflowY: 'auto' }}>
//             {previewImages.map((product, index) => (
//               <Box key={index} sx={{ mb: 4 }}>
//                 <Typography variant="h6">{product.title}</Typography>

//                 {/* 商品を削除するボタンを追加 */}
//                 <Button
//                   variant="outlined"
//                   color="error"
//                   onClick={() => handleDeleteProduct(product.rowIndex)}
//                   sx={{ mb: 2 }}
//                 >
//                   商品を削除
//                 </Button>

//                 <DragDropContext
//                   onDragEnd={(result) => handleDragEnd(result, product)}
//                 >
//                   <Droppable
//                     droppableId={`droppable-${product.rowIndex}`}
//                     direction="horizontal"
//                   >
//                     {(provided) => (
//                       <Grid
//                         container
//                         spacing={2}
//                         ref={provided.innerRef}
//                         {...provided.droppableProps}
//                       >
//                         {product.images.map((image, idx) => (
//                           <Draggable
//                             key={image.url}
//                             draggableId={image.url}
//                             index={idx}
//                           >
//                             {(provided) => (
//                               <Grid
//                                 item
//                                 xs={3}
//                                 sm={2}
//                                 md={1.5}
//                                 lg={1}
//                                 ref={provided.innerRef}
//                                 {...provided.draggableProps}
//                                 {...provided.dragHandleProps}
//                               >
//                                 <Paper
//                                   elevation={1}
//                                   sx={{
//                                     p: 1,
//                                     position: 'relative',
//                                     opacity: selectedImages.has(image.url)
//                                       ? 0.4
//                                       : 1,
//                                   }}
//                                 >
//                                   <IconButton
//                                     aria-label="delete"
//                                     onClick={() =>
//                                       toggleImageSelection(image.url)
//                                     }
//                                     sx={{
//                                       position: 'absolute',
//                                       top: 0,
//                                       right: 0,
//                                       color: (theme) =>
//                                         theme.palette.error.main,
//                                     }}
//                                   >
//                                     <CloseIcon />
//                                   </IconButton>
//                                   <img
//                                     src={image.url}
//                                     alt={`Row ${product.rowIndex + 1} Image ${
//                                       image.imageIndex
//                                     }`}
//                                     style={{ width: '100%', height: 'auto' }}
//                                     loading="lazy"
//                                   />
//                                   <Typography variant="caption">
//                                     画像 {idx + 1}
//                                   </Typography>
//                                 </Paper>
//                               </Grid>
//                             )}
//                           </Draggable>
//                         ))}

//                         {provided.placeholder}

//                         {/* プラスアイコンのDraggableアイテム */}
//                         <Draggable
//                           key={`add-image-${product.rowIndex}`}
//                           draggableId={`add-image-${product.rowIndex}`}
//                           index={product.images.length}
//                           isDragDisabled={true} // ドラッグ不可に設定
//                         >
//                           {(provided) => (
//                             <Grid
//                               item
//                               xs={3}
//                               sm={2}
//                               md={1.5}
//                               lg={1}
//                               ref={provided.innerRef}
//                               {...provided.draggableProps}
//                             >
//                               <Paper
//                                 elevation={1}
//                                 sx={{
//                                   p: 1,
//                                   display: 'flex',
//                                   alignItems: 'center',
//                                   justifyContent: 'center',
//                                   height: '100%',
//                                   border: '2px dashed #1976d2',
//                                   color: '#1976d2',
//                                   cursor: 'pointer',
//                                 }}
//                                 onClick={() => handleAddImageClick(product)}
//                               >
//                                 <Typography variant="h4">+</Typography>
//                               </Paper>
//                             </Grid>
//                           )}
//                         </Draggable>
//                       </Grid>
//                     )}
//                   </Droppable>
//                 </DragDropContext>
//               </Box>
//             ))}
//           </Box>
//         </DialogContent>
//         <DialogActions>
//           <Button onClick={() => setOpenPreviewDialog(false)}>閉じる</Button>
//           <Button
//             onClick={() => {
//               setOpenConfirmDialog(true);
//             }}
//             color="primary"
//             variant="contained"
//           >
//             削除実行
//           </Button>
//         </DialogActions>
//       </Dialog>

//       {/* 画像追加ダイアログ */}
//       <Dialog
//         open={isAddImageDialogOpen}
//         onClose={() => setIsAddImageDialogOpen(false)}
//       >
//         <DialogTitle>画像を追加</DialogTitle>
//         <DialogContent>
//           <Typography variant="body2" sx={{ mb: 2 }}>
//             画像のURLを入力するか、画像ファイルをアップロードしてください。
//           </Typography>
//           <TextField
//             label="画像URL"
//             value={newImageURL}
//             onChange={(e) => setNewImageURL(e.target.value)}
//             fullWidth
//             sx={{ mb: 2 }}
//           />
//           <Typography variant="body2" sx={{ mb: 1 }}>
//             または
//           </Typography>
//           <Button variant="contained" component="label">
//             画像を選択
//             <input
//               type="file"
//               accept="image/*"
//               hidden
//               onChange={handleImageFileChange}
//             />
//           </Button>
//           {newImageFile && (
//             <Typography variant="body2" sx={{ mt: 1 }}>
//               選択されたファイル: {newImageFile.name}
//             </Typography>
//           )}
//         </DialogContent>
//         <DialogActions>
//           <Button
//             onClick={() => {
//               setIsAddImageDialogOpen(false);
//               setNewImageFile(null);
//               setNewImageURL('');
//             }}
//           >
//             キャンセル
//           </Button>
//           <Button onClick={handleAddImage} color="primary" variant="contained">
//             追加
//           </Button>
//         </DialogActions>
//       </Dialog>

//       {/* 画像削除確認ダイアログ */}
//       <Dialog
//         open={openConfirmDialog}
//         onClose={() => setOpenConfirmDialog(false)}
//         aria-labelledby="alert-dialog-title"
//         aria-describedby="alert-dialog-description"
//       >
//         <DialogTitle id="alert-dialog-title">{'確認'}</DialogTitle>
//         <DialogContent>
//           <DialogContentText id="alert-dialog-description">
//             選択された画像が商品データから削除されます。よろしいですか？
//           </DialogContentText>
//         </DialogContent>
//         <DialogActions>
//           <Button onClick={() => setOpenConfirmDialog(false)} color="primary">
//             キャンセル
//           </Button>
//           <Button onClick={handleDelete} color="primary" autoFocus>
//             実行
//           </Button>
//         </DialogActions>
//       </Dialog>

//       {/* 商品削除確認ダイアログ */}
//       <Dialog
//         open={openProductDeleteConfirmDialog}
//         onClose={() => setOpenProductDeleteConfirmDialog(false)}
//       >
//         <DialogTitle>商品を削除</DialogTitle>
//         <DialogContent>
//           <DialogContentText>
//             この商品を削除してもよろしいですか？
//           </DialogContentText>
//         </DialogContent>
//         <DialogActions>
//           <Button onClick={() => setOpenProductDeleteConfirmDialog(false)}>
//             キャンセル
//           </Button>
//           <Button
//             onClick={() => {
//               confirmDeleteProduct(productToDelete);
//               setOpenProductDeleteConfirmDialog(false);
//             }}
//             color="error"
//           >
//             削除
//           </Button>
//         </DialogActions>
//       </Dialog>
//     </>
//   );
// }

// export default ImageOperations;

