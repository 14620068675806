import React from 'react';
import { Typography, Box } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';

function LegalDisclosure() {
  return (
    <Box sx={{ p: 2 }}>
      <Typography variant="h4" gutterBottom>
        特定商取引法に基づく表記
      </Typography>

      <Typography variant="h6" gutterBottom>
        1. 販売業者
      </Typography>
      <Typography variant="body1" gutterBottom>
        株式会社Awiiin
      </Typography>

      <Typography variant="h6" gutterBottom>
        2. 運営統括責任者
      </Typography>
      <Typography variant="body1" gutterBottom>
        菊池 駿
      </Typography>

      <Typography variant="h6" gutterBottom>
        3. 所在地
      </Typography>
      <Typography variant="body1" gutterBottom>
        〒340-0831<br />
        埼玉県八潮市南後谷828-10
      </Typography>

      <Typography variant="h6" gutterBottom>
        4. 連絡先
      </Typography>
      <Typography variant="body1" gutterBottom>
        電話番号: 048-919-2850<br />
        メールアドレス: info@awiiin.com<br />
        営業時間: 平日 10:00〜18:00<br />
        土日祝日 休業
      </Typography>

      <Typography variant="h6" gutterBottom>
        5. 商品代金以外に必要な料金
      </Typography>
      <Typography variant="body1" gutterBottom>
        消費税: 商品代金に加えて消費税を申し受けます。<br />
        送料・配送手数料: デジタル商品に該当するため、送料は発生しません。
      </Typography>

      <Typography variant="h6" gutterBottom>
        6. 支払い方法
      </Typography>
      <Typography variant="body1" gutterBottom>
        オンライン決済サービス
      </Typography>

      <Typography variant="h6" gutterBottom>
        7. 支払い時期
      </Typography>
      <Typography variant="body1" gutterBottom>
        お客様がサービスを申し込み、支払い方法を選択した時点で支払いが確定します。サブスクリプション料金は選択されたプランに基づき、定期的に自動で請求されます。
      </Typography>

      <Typography variant="h6" gutterBottom>
        8. 商品引渡時期
      </Typography>
      <Typography variant="body1" gutterBottom>
        サブスクリプションの購入が完了した時点で、即時にサービスの利用が可能となります。サービスの提供はオンライン上で行われるため、物理的な引渡しはありません。
      </Typography>

      <Typography variant="h6" gutterBottom>
        9. サブスクリプション契約の内容
      </Typography>
      <Typography variant="body1" gutterBottom>
        <strong>契約期間:</strong> サブスクリプションは月額、年額など、選択されたプランの期間に基づき自動更新されます。<br />
        <strong>自動更新:</strong> サブスクリプションは、契約期間終了後、自動的に同一条件で更新されます。自動更新を希望しない場合は、契約期間終了前に解約手続きを行ってください。<br />
        <strong>解約方法:</strong> サブスクリプションの解約は、弊社サポートへお問い合わせいただくことにより行うことができます。解約手続きが完了すると、次回の請求から料金が発生しなくなります。<br />
        <strong>返金について:</strong> サービスの性質上、解約後の返金は原則として行いません。ただし、技術的な問題やサービス提供に重大な欠陥があった場合は、個別に対応いたします。返金をご希望の場合は、サポートまでご連絡ください。
      </Typography>

      <Typography variant="h6" gutterBottom>
        10. 返品・交換について
      </Typography>
      <Typography variant="body1" gutterBottom>
        <strong>返品・交換の条件:</strong><br />
        ・サービスに技術的な欠陥がある場合、サービス開始後7日以内にご連絡ください。<br />
        ・お客様都合による解約・返金は原則としてお受けできません。ただし、特別な事情がある場合は個別に対応いたします。<br />
        <strong>返品・交換の手続き:</strong><br />
        ・解約や返金をご希望の場合は、まず弊社サポートまでご連絡ください。必要な手続き方法をご案内いたします。
      </Typography>

      <Typography variant="h6" gutterBottom>
        11. 事業者の責任範囲
      </Typography>
      <Typography variant="body1" gutterBottom>
        当社は、サービスの提供において最善を尽くしますが、予期せぬトラブルや不可抗力によるサービス停止等に関して、当社の責任はサービス料金の範囲内に限られます。
      </Typography>

      <Typography variant="h6" gutterBottom>
        12. 利用規約およびプライバシーポリシー
      </Typography>
      <Typography variant="body1" gutterBottom>
        詳細な利用規約およびプライバシーポリシーについては、以下のリンクをご参照ください。<br />
        <RouterLink to="/terms">利用規約</RouterLink><br />
        <RouterLink to="/privacy">プライバシーポリシー</RouterLink>
      </Typography>

      <Typography variant="h6" gutterBottom>
        13. 特定商取引法に基づく表記の変更について
      </Typography>
      <Typography variant="body1" gutterBottom>
        本表記は予告なく変更されることがあります。最新の情報は当サイトの「特定商取引法に基づく表記」ページにてご確認ください。
      </Typography>
    </Box>
  );
}

export default LegalDisclosure;