// // ImageAnalysis.js

// import axios from 'axios';
// import { getAuth } from 'firebase/auth';

// /**
//  * 画像を解析して説明文を生成する関数
//  * @param {string} imageUrl - 画像のURL
//  * @returns {Promise<string>} - 生成された説明文
//  */
// const ImageAnalysis = async (imageUrl) => {
//   try {
//     // Firebase認証から現在のユーザーを取得
//     const auth = getAuth();
//     const user = auth.currentUser;
//     if (!user) {
//       throw new Error('ユーザーが認証されていません');
//     }

//     // Firebase IDトークンを取得
//     const idToken = await user.getIdToken();

//     // Cloud FunctionsのエンドポイントURLを設定
//     const functionsUrl = 'https://us-central1-ebayprice-405908.cloudfunctions.net/api/imageAnalysis';

//     // Cloud Functionにリクエストを送信
//     const response = await axios.post(
//       functionsUrl,
//       { imageUrl },
//       {
//         headers: {
//           Authorization: `Bearer ${idToken}`,
//           'Content-Type': 'application/json',
//         },
//       }
//     );

//     // 解析結果を取得
//     const description = response.data.description;
//     return description;
//   } catch (error) {
//     console.error('画像解析中にエラーが発生しました:', error.response?.data || error.message);
//     throw error;
//   }
// };

// export default ImageAnalysis;





// import axios from 'axios';

// /**
//  * 画像を解析して説明文を生成する関数
//  * @param {string} imageUrl - 画像の公開 URL
//  * @param {string} apiKey - OpenAI APIキー
//  * @returns {Promise<string>} - 生成された説明文
//  */
// const ImageAnalysis = async (imageUrl, apiKey) => {
//   try {
//     // OpenAI APIにリクエスト
//     const apiResponse = await axios.post(
//       'https://api.openai.com/v1/chat/completions',
//       {
//         model: 'gpt-4o', // ご使用のモデル名に変更してください
//         messages: [
//           {
//             role: 'user',
//             content: `この画像の内容を日本語で詳しく説明してください。箇条書きで商品の特徴をわかりやすく出力してください。\n画像URL: ${imageUrl}`
//           }
//         ],
//         max_tokens: 1000
//       },
//       {
//         headers: {
//           'Content-Type': 'application/json',
//           'Authorization': `Bearer ${apiKey}`,
//         },
//       }
//     );

//     const description = apiResponse.data.choices[0].message.content.trim();
//     return description;
//   } catch (error) {
//     console.error('画像解析中にエラーが発生しました:', error);
//     if (error.response) {
//       console.error('エラー内容:', error.response.data);
//     }
//     throw error;
//   }
// };

// export default ImageAnalysis;






// // ImageAnalysis.js

// import axios from 'axios';
// import { getStorage, ref, getDownloadURL } from "firebase/storage";

// /**
//  * 画像を解析して説明文を生成する関数
//  * @param {string} userId - ユーザーID
//  * @param {string} imageId - 画像ID
//  * @param {string} apiKey - OpenAI APIキー
//  * @returns {Promise<string>} - 生成された説明文
//  */
// const ImageAnalysis = async (userId, imageId, apiKey) => {
//   try {
//     const storage = getStorage();
//     const imageRef = ref(storage, `users/${userId}/images/${imageId}.png`);
    
//     // 画像の公開URLを取得
//     const imageUrl = await getDownloadURL(imageRef);
    
//     // OpenAI APIにリクエスト
//     const apiResponse = await axios.post(
//       'https://api.openai.com/v1/chat/completions',
//       {
//         model: 'gpt-4o-mini', // ご指定のモデル名
//         messages: [
//           {
//             role: 'user',
//             content: `この画像の内容を日本語で詳しく説明してください。箇条書きで商品の特徴をわかりやすく出力してください。\n画像URL: ${imageUrl}`
//           }
//         ],
//         max_tokens: 1000
//       },
//       {
//         headers: {
//           'Content-Type': 'application/json',
//           'Authorization': `Bearer ${apiKey}`,
//         },
//       }
//     );

//     const description = apiResponse.data.choices[0].message.content.trim();
//     return description;
//   } catch (error) {
//     console.error('画像解析中にエラーが発生しました:', error);
//     let errorMessage = '画像解析中にエラーが発生しました。';
//     if (error.response) {
//       console.error('エラー内容:', error.response.data);
//       errorMessage += ` エラー内容: ${JSON.stringify(error.response.data)}`;
//     } else {
//       errorMessage += ` エラー詳細: ${error.message}`;
//     }
//     throw new Error(errorMessage);
//   }
// };

// export default ImageAnalysis;




// // ImageAnalysis.js

// import axios from 'axios';

// // 同時に処理するリクエストの最大数を設定
// const MAX_CONCURRENT_REQUESTS = 5;

// // リクエスト間の待機時間（ミリ秒）
// const REQUEST_INTERVAL = 1000;

// // リクエストのキュー
// let requestQueue = [];

// // 現在のアクティブなリクエスト数
// let activeRequests = 0;

// // リクエストを処理する関数
// const processQueue = () => {
//   while (activeRequests < MAX_CONCURRENT_REQUESTS && requestQueue.length > 0) {
//     const { imageUrl, apiKey, resolve, reject } = requestQueue.shift();
//     activeRequests++;

//     ImageAnalysis(imageUrl, apiKey)
//       .then((result) => {
//         resolve(result);
//       })
//       .catch((error) => {
//         if (error.response && error.response.status === 429) {
//           // レート制限に達した場合、再度キューに追加し、待機時間を設ける
//           console.warn('レート制限に達しました。5秒待機して再試行します。');
//           setTimeout(() => {
//             requestQueue.push({ imageUrl, apiKey, resolve, reject });
//             activeRequests--;
//             processQueue();
//           }, 5000); // 5秒待機
//         } else {
//           reject(error);
//           activeRequests--;
//         }
//       })
//       .finally(() => {
//         activeRequests--;
//         // 次のリクエストを処理
//         if (requestQueue.length > 0) {
//           setTimeout(processQueue, REQUEST_INTERVAL);
//         }
//       });
//   }
// };

// // リクエストをキューに追加する関数
// export const enqueueImageAnalysisRequest = (imageUrl, apiKey) => {
//   return new Promise((resolve, reject) => {
//     requestQueue.push({ imageUrl, apiKey, resolve, reject });
//     processQueue();
//   });
// };

// // 画像解析を行うメインの関数
// const ImageAnalysis = async (imageUrl, apiKey) => {
//   try {
//     // OpenAI APIにリクエストを送信
//     const apiResponse = await axios.post(
//       'https://api.openai.com/v1/chat/completions',
//       {
//         model: 'gpt-4o-mini', // または 'gpt-4' を使用
//         messages: [
//           {
//             role: 'user',
//             content: `以下の画像の内容を日本語で詳しく説明してください。原則箇条書きで商品の特徴をわかりやすく出力してください。\n画像URL: ${imageUrl}`,
//           },
//         ],
//         max_tokens: 500,
//       },
//       {
//         headers: {
//           'Content-Type': 'application/json',
//           Authorization: `Bearer ${apiKey}`,
//         },
//       }
//     );

//     const description = apiResponse.data.choices[0].message.content.trim();
//     return description;
//   } catch (error) {
//     if (error.response) {
//       if (error.response.status === 429) {
//         // レート制限エラー
//         console.error('レート制限に達しました。再試行します。');
//       } else {
//         console.error('APIエラー:', error.response.data);
//       }
//     } else if (error.request) {
//       console.error('リクエストエラー:', error.request);
//     } else {
//       console.error('エラー:', error.message);
//     }
//     if (error.response) {
//       console.error('ステータスコード:', error.response.status);
//       console.error('レスポンスデータ:', error.response.data);
//     } else if (error.request) {
//       console.error('リクエストが送信されましたが、レスポンスがありませんでした:', error.request);
//     } else {
//       console.error('エラーを生成した設定で問題が発生しました:', error.message);
//     }
//     console.error('エラーコンフィグ:', error.config);
//     throw error;
//   }
// };

// export default ImageAnalysis;






//ーーーーーーーーーー一番安定版ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー


import axios from 'axios';

/**
 * 画像を解析して説明文を生成する関数
 * @param {string} imageUrl - 画像のURL
 * @param {string} apiKey - OpenAI APIキー
 * @returns {Promise<string>} - 生成された説明文
 */
const ImageAnalysis = async (imageUrl, apiKey) => {
  try {
    // OpenAI APIにリクエスト
    const apiResponse = await axios.post(
      'https://api.openai.com/v1/chat/completions',
      {
        model: 'gpt-4o-mini', // 正しいモデル名
        messages: [
          {
            role: 'user',
            content: [
              {
                type: 'text',
                text: 'この画像の内容を日本語で詳しく説明してください。原則箇条書きで商品の特徴をわかりやすく出力してください。'
              },
              {
                type: 'image_url',
                image_url: {
                  url: imageUrl,
                }
              }
            ]
          }
        ],
        max_tokens: 500
      },
      {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${apiKey}`,
        },
      }
    );

    const description = apiResponse.data.choices[0].message.content.trim();
    return description;
  } catch (error) {
    if (error.response) {
      console.error('ステータスコード:', error.response.status);
      console.error('レスポンスデータ:', error.response.data);
    } else if (error.request) {
      console.error('リクエストが送信されましたが、レスポンスがありませんでした:', error.request);
    } else {
      console.error('エラーを生成した設定で問題が発生しました:', error.message);
    }
    console.error('エラーコンフィグ:', error.config);
    throw error;
  }  
};

export default ImageAnalysis;

