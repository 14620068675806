// ItemSpecificsApplier.js

import React, { useState, useEffect } from 'react';
import {
  Button,
  Typography,
  Box,
  CircularProgress,
  Snackbar,
  Alert,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  List,
  ListItem,
  ListItemText,
  Checkbox,
  FormControlLabel,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  RadioGroup,
  Radio,
} from '@mui/material';
import UpdateIcon from '@mui/icons-material/Update';
import SettingsIcon from '@mui/icons-material/Settings';
import { getFirestore, doc, getDoc } from 'firebase/firestore';

const ItemSpecificsApplier = ({ data, setData, userId }) => {
  const [loading, setLoading] = useState(false);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'info' });
  const [categories, setCategories] = useState({});
  const [openSettingsDialog, setOpenSettingsDialog] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState('');
  const [selectedColumns, setSelectedColumns] = useState([]);
  const [caseSensitive, setCaseSensitive] = useState(false);
  const [partialMatch, setPartialMatch] = useState(true);
  const [previewData, setPreviewData] = useState([]);
  const [previewOpen, setPreviewOpen] = useState(false);
  const [headers, setHeaders] = useState([]);
  const [updatedData, setUpdatedData] = useState([]);
  const [matchSource, setMatchSource] = useState('title'); // マッチングの基準

  const db = getFirestore();

  // Firestoreから設定を読み込む
  const loadSettings = async () => {
    if (!userId) {
      setSnackbar({ open: true, message: 'ユーザー情報がありません。', severity: 'error' });
      return;
    }

    try {
      const docRef = doc(db, 'itemSpecificsSettings', userId);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        const userSettings = docSnap.data();
        setCategories(userSettings.categories || {});
        setSnackbar({ open: true, message: '設定を読み込みました。', severity: 'success' });
      } else {
        setCategories({});
        setSnackbar({ open: true, message: '設定が見つかりません。', severity: 'info' });
      }
    } catch (error) {
      console.error('Failed to load item specifics settings:', error);
      setSnackbar({ open: true, message: '設定の読み込みに失敗しました。', severity: 'error' });
    }
  };

  // 初回マウント時に設定を読み込む
  useEffect(() => {
    loadSettings();
  }, [userId]);

  // ヘッダーの取得
  useEffect(() => {
    if (data && data.length > 0) {
      setHeaders(Object.keys(data[0]));
    }
  }, [data]);

  // 設定を再読み込みする関数
  const handleReloadSettings = () => {
    loadSettings();
  };

  // DescriptionからAI生成部分を抽出する関数を追加
  const extractAIGeneratedContent = (description) => {
    if (!description) return '';
    const regex = /<div id="ai-generated-content">(.*?)<\/div>/is;
    const match = description.match(regex);
    if (match && match[1]) {
      // マッチした部分のHTMLタグを除去してテキストのみを取得
      const tempDiv = document.createElement('div');
      tempDiv.innerHTML = match[1];
      return tempDiv.textContent || tempDiv.innerText || '';
    }
    return '';
  };

  // データにItem Specificsを適用し、プレビューを表示する
  const handleUpdateData = () => {
    if (!selectedCategory) {
      setSnackbar({ open: true, message: '適用するカテゴリーを選択してください。', severity: 'warning' });
      return;
    }

    if (selectedColumns.length === 0) {
      setSnackbar({ open: true, message: '適用するカラムを選択してください。', severity: 'warning' });
      return;
    }

    setLoading(true);

    try {
      const titleKey = headers.find((header) => header.toLowerCase() === 'title');
      const descriptionKey = headers.find((header) => header.toLowerCase() === 'description');

      if (!titleKey && (matchSource === 'title' || matchSource === 'both')) {
        throw new Error('「Title」カラムが見つかりません。');
      }

      if (!descriptionKey && (matchSource === 'description' || matchSource === 'both')) {
        throw new Error('「Description」カラムが見つかりません。');
      }

      const newData = data.map((item) => {
        const title = item[titleKey] || '';
        let description = item[descriptionKey] || '';
        let sourceText = '';

        // マッチングソースに応じてテキストを設定
        if (matchSource === 'title') {
          sourceText = title;
        } else if (matchSource === 'description') {
          // DescriptionからAI生成部分を抽出
          const aiContent = extractAIGeneratedContent(description);
          sourceText = aiContent;
        } else if (matchSource === 'both') {
          // DescriptionからAI生成部分を抽出
          const aiContent = extractAIGeneratedContent(description);
          sourceText = `${title} ${aiContent}`;
        }

        const newItem = { ...item };

        selectedColumns.forEach((column) => {
          const conversionRules = categories[selectedCategory]?.columns[column]?.conversionRules || [];
          let valueSet = false;

          // 変換ルールの適用
          for (const rule of conversionRules) {
            const { keyword, value } = rule;
            if (!keyword || !value) continue;

            const flags = caseSensitive ? 'g' : 'gi';

            // 正規表現の特殊文字をエスケープする関数
            function escapeRegExp(string) {
              return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
            }

            // キーワードをエスケープしてワードバウンダリを追加
            const escapedKeyword = escapeRegExp(keyword);
            const regex = new RegExp(`\\b${escapedKeyword}\\b`, flags);

            const matchCondition = partialMatch
              ? regex.test(sourceText)
              : caseSensitive
              ? sourceText === keyword
              : sourceText.toLowerCase() === keyword.toLowerCase();

            if (matchCondition) {
              newItem[column] = value;
              valueSet = true;
              break;
            }
          }

          // 変換ルールで値が設定されなかった場合、値のリストを適用
          if (!valueSet) {
            const values = categories[selectedCategory]?.columns[column]?.values || [];
            for (const value of values) {
              if (typeof value !== 'string') continue;

              const flags = caseSensitive ? 'g' : 'gi';

              // 正規表現の特殊文字をエスケープする関数
              function escapeRegExp(string) {
                return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
              }

              const escapedValue = escapeRegExp(value);
              const regex = new RegExp(`\\b${escapedValue}\\b`, flags);

              const matchCondition = partialMatch
                ? regex.test(sourceText)
                : caseSensitive
                ? sourceText === value
                : sourceText.toLowerCase() === value.toLowerCase();

              if (matchCondition) {
                newItem[column] = value;
                break;
              }
            }
          }
        });

        return newItem;
      });

      setUpdatedData(newData);

      // プレビュー用データを作成（上位10件のみ）
      const previewItems = newData.slice(0, 10).map((item, index) => {
        const originalItem = data[index];
        const previewItem = { Title: item[titleKey] || '' };

        if (matchSource !== 'title') {
          previewItem.Description = item[descriptionKey] || '';
          // AI生成部分のみを抽出してプレビューに表示
          previewItem['AI Generated Content'] = extractAIGeneratedContent(item[descriptionKey]);
        }

        selectedColumns.forEach((column) => {
          previewItem[`${column} (Before)`] = originalItem[column] || '';
          previewItem[`${column} (After)`] = item[column] || '';
        });

        return previewItem;
      });

      setPreviewData(previewItems);
      setPreviewOpen(true);
    } catch (error) {
      console.error('Error updating data:', error);
      setSnackbar({ open: true, message: `データの更新中にエラーが発生しました: ${error.message}`, severity: 'error' });
    } finally {
      setLoading(false);
    }
  };

  // プレビューを確認し、データを更新する
  const confirmUpdate = () => {
    if (updatedData.length === 0) {
      setSnackbar({ open: true, message: '更新データが存在しません。', severity: 'warning' });
      return;
    }

    setData(updatedData);
    setSnackbar({ open: true, message: 'データが正常に更新されました。', severity: 'success' });
    setPreviewOpen(false);
  };

  // 設定ダイアログを開く
  const handleOpenSettingsDialog = () => {
    setOpenSettingsDialog(true);
  };

  // 設定ダイアログを閉じる
  const handleCloseSettingsDialog = () => {
    setOpenSettingsDialog(false);
  };

  // カラムの選択/解除を行う
  const handleColumnToggle = (column) => {
    setSelectedColumns((prev) =>
      prev.includes(column) ? prev.filter((c) => c !== column) : [...prev, column]
    );
  };

  // 大文字小文字区別の切り替え
  const handleCaseSensitiveToggle = (event) => {
    setCaseSensitive(event.target.checked);
  };

  // 部分一致の切り替え
  const handlePartialMatchToggle = (event) => {
    setPartialMatch(event.target.checked);
  };

  // 設定ダイアログ内で選択されたカテゴリーのカラムを取得する
  const getColumnsForSelectedCategory = () => {
    if (selectedCategory && categories[selectedCategory]) {
      return Object.keys(categories[selectedCategory].columns || {});
    }
    return [];
  };

  // マッチングソースの変更
  const handleMatchSourceChange = (event) => {
    setMatchSource(event.target.value);
  };

  return (
    <Box sx={{ p: 2, mt: 2 }}>
      <Typography variant="h5" gutterBottom>
        Item Specifics適用
      </Typography>
      <Typography variant="body1" paragraph>
        設定したItem Specificsをタイトルや商品説明に基づいて適用し、該当するカラムを更新します。
      </Typography>
      <Box
        sx={{
          mt: 2,
          display: 'flex',
          flexDirection: { xs: 'column', sm: 'row' },
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Box>
          <Button
            startIcon={<UpdateIcon />}
            onClick={handleUpdateData}
            variant="contained"
            color="primary"
            disabled={loading}
            sx={{ mr: 2, mb: { xs: 2, sm: 0 } }}
          >
            {loading ? <CircularProgress size={24} /> : 'データを更新'}
          </Button>
          <Button
            startIcon={<SettingsIcon />}
            onClick={handleOpenSettingsDialog}
            variant="outlined"
            color="secondary"
            sx={{ mr: 2 }}
          >
            適用設定
          </Button>
          <Button variant="text" color="info" onClick={handleReloadSettings}>
            データ再読み込み
          </Button>
        </Box>
      </Box>

      {/* 設定ダイアログ */}
      <Dialog open={openSettingsDialog} onClose={handleCloseSettingsDialog} maxWidth="sm" fullWidth>
        <DialogTitle>Item Specifics設定</DialogTitle>
        <DialogContent>
          {/* カテゴリー選択 */}
          <FormControl fullWidth variant="outlined" size="small" sx={{ mt: 2, mb: 2 }}>
            <InputLabel>適用するカテゴリー</InputLabel>
            <Select
              value={selectedCategory}
              onChange={(e) => {
                setSelectedCategory(e.target.value);
                setSelectedColumns([]); // カテゴリー変更時に選択カラムをリセット
              }}
              label="適用するカテゴリー"
            >
              {Object.keys(categories).map((category) => (
                <MenuItem key={category} value={category}>
                  {category}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          {/* カラムの選択 */}
          {selectedCategory && (
            <>
              <Typography variant="subtitle1" gutterBottom>
                適用するカラムを選択してください。
              </Typography>
              <List>
                {getColumnsForSelectedCategory().map((column) => (
                  <ListItem key={column} button onClick={() => handleColumnToggle(column)}>
                    <Checkbox
                      edge="start"
                      checked={selectedColumns.includes(column)}
                      tabIndex={-1}
                      disableRipple
                    />
                    <ListItemText primary={column} />
                  </ListItem>
                ))}
              </List>
            </>
          )}

          {/* マッチングソースの選択 */}
          <Box sx={{ mt: 2 }}>
            <Typography variant="subtitle1" gutterBottom>
              マッチングの基準を選択してください。
            </Typography>
            <FormControl component="fieldset">
              <RadioGroup value={matchSource} onChange={handleMatchSourceChange}>
                <FormControlLabel value="title" control={<Radio />} label="タイトルのみ" />
                <FormControlLabel value="description" control={<Radio />} label="商品説明のみ (AI生成部分のみ)" />
                <FormControlLabel value="both" control={<Radio />} label="タイトルと商品説明の両方 (AI生成部分のみ)" />
              </RadioGroup>
            </FormControl>
          </Box>

          {/* オプション */}
          <Box sx={{ mt: 2 }}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={caseSensitive}
                  onChange={handleCaseSensitiveToggle}
                  name="caseSensitive"
                />
              }
              label="大文字小文字を区別する"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={partialMatch}
                  onChange={handlePartialMatchToggle}
                  name="partialMatch"
                />
              }
              label="部分一致を許可する"
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseSettingsDialog} color="primary">
            閉じる
          </Button>
        </DialogActions>
      </Dialog>

      {/* プレビューダイアログ */}
      <Dialog open={previewOpen} onClose={() => setPreviewOpen(false)} maxWidth="lg" fullWidth>
        <DialogTitle>更新プレビュー</DialogTitle>
        <DialogContent>
          <Typography variant="body2" paragraph>
            以下の内容で更新されます。確認してください。
          </Typography>
          <Box sx={{ maxHeight: 400, overflow: 'auto' }}>
            <table style={{ width: '100%', borderCollapse: 'collapse' }}>
              <thead>
                <tr>
                  {previewData[0] &&
                    Object.keys(previewData[0]).map((header, index) => (
                      <th
                        key={index}
                        style={{
                          border: '1px solid #ccc',
                          padding: '8px',
                          backgroundColor: '#f5f5f5',
                          position: 'sticky',
                          top: 0,
                        }}
                      >
                        {header}
                      </th>
                    ))}
                </tr>
              </thead>
              <tbody>
                {previewData.map((row, rowIndex) => (
                  <tr key={rowIndex}>
                    {Object.values(row).map((cell, cellIndex) => (
                      <td key={cellIndex} style={{ border: '1px solid #ccc', padding: '8px' }}>
                        {cell}
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
          </Box>
          {updatedData.length > 10 && (
            <Typography variant="body2" sx={{ mt: 2 }}>
              (表示は上位10件のみです。実際の更新はすべてのデータに適用されます。)
            </Typography>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setPreviewOpen(false)} color="secondary">
            キャンセル
          </Button>
          <Button onClick={confirmUpdate} color="primary" variant="contained">
            更新を確定
          </Button>
        </DialogActions>
      </Dialog>

      {/* スナックバー */}
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      >
        <Alert
          onClose={() => setSnackbar({ ...snackbar, open: false })}
          severity={snackbar.severity}
          variant="filled"
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default ItemSpecificsApplier;





// // ItemSpecificsApplier.js

// import React, { useState, useEffect } from 'react';
// import {
//   Button,
//   Typography,
//   Box,
//   CircularProgress,
//   Snackbar,
//   Alert,
//   Dialog,
//   DialogActions,
//   DialogContent,
//   DialogTitle,
//   List,
//   ListItem,
//   ListItemText,
//   Checkbox,
//   FormControlLabel,
//   FormControl,
//   InputLabel,
//   Select,
//   MenuItem,
//   RadioGroup,
//   Radio,
// } from '@mui/material';
// import UpdateIcon from '@mui/icons-material/Update';
// import SettingsIcon from '@mui/icons-material/Settings';
// import { getFirestore, doc, getDoc } from 'firebase/firestore';

// const ItemSpecificsApplier = ({ data, setData, userId }) => {
//   const [loading, setLoading] = useState(false);
//   const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'info' });
//   const [categories, setCategories] = useState({});
//   const [openSettingsDialog, setOpenSettingsDialog] = useState(false);
//   const [selectedCategory, setSelectedCategory] = useState('');
//   const [selectedColumns, setSelectedColumns] = useState([]);
//   const [caseSensitive, setCaseSensitive] = useState(false);
//   const [partialMatch, setPartialMatch] = useState(true);
//   const [previewData, setPreviewData] = useState([]);
//   const [previewOpen, setPreviewOpen] = useState(false);
//   const [headers, setHeaders] = useState([]);
//   const [updatedData, setUpdatedData] = useState([]);
//   const [matchSource, setMatchSource] = useState('title'); // マッチングの基準

//   const db = getFirestore();

//   // Firestoreから設定を読み込む
//   const loadSettings = async () => {
//     if (!userId) {
//       setSnackbar({ open: true, message: 'ユーザー情報がありません。', severity: 'error' });
//       return;
//     }

//     try {
//       const docRef = doc(db, 'itemSpecificsSettings', userId);
//       const docSnap = await getDoc(docRef);

//       if (docSnap.exists()) {
//         const userSettings = docSnap.data();
//         setCategories(userSettings.categories || {});
//         setSnackbar({ open: true, message: '設定を読み込みました。', severity: 'success' });
//       } else {
//         setCategories({});
//         setSnackbar({ open: true, message: '設定が見つかりません。', severity: 'info' });
//       }
//     } catch (error) {
//       console.error('Failed to load item specifics settings:', error);
//       setSnackbar({ open: true, message: '設定の読み込みに失敗しました。', severity: 'error' });
//     }
//   };

//   // 初回マウント時に設定を読み込む
//   useEffect(() => {
//     loadSettings();
//   }, [userId]);

//   // ヘッダーの取得
//   useEffect(() => {
//     if (data && data.length > 0) {
//       setHeaders(Object.keys(data[0]));
//     }
//   }, [data]);

//   // 設定を再読み込みする関数
//   const handleReloadSettings = () => {
//     loadSettings();
//   };

//   // DescriptionからAI生成部分を抽出する関数を追加
//   const extractAIGeneratedContent = (description) => {
//     if (!description) return '';
//     const regex = /<div id="ai-generated-content">(.*?)<\/div>/is;
//     const match = description.match(regex);
//     if (match && match[1]) {
//       // マッチした部分のHTMLタグを除去してテキストのみを取得
//       const tempDiv = document.createElement('div');
//       tempDiv.innerHTML = match[1];
//       return tempDiv.textContent || tempDiv.innerText || '';
//     }
//     return '';
//   };

//   // データにItem Specificsを適用し、プレビューを表示する
//   const handleUpdateData = () => {
//     if (!selectedCategory) {
//       setSnackbar({ open: true, message: '適用するカテゴリーを選択してください。', severity: 'warning' });
//       return;
//     }

//     if (selectedColumns.length === 0) {
//       setSnackbar({ open: true, message: '適用するカラムを選択してください。', severity: 'warning' });
//       return;
//     }

//     setLoading(true);

//     try {
//       const titleKey = headers.find((header) => header.toLowerCase() === 'title');
//       const descriptionKey = headers.find((header) => header.toLowerCase() === 'description');

//       if (!titleKey && (matchSource === 'title' || matchSource === 'both')) {
//         throw new Error('「Title」カラムが見つかりません。');
//       }

//       if (!descriptionKey && (matchSource === 'description' || matchSource === 'both')) {
//         throw new Error('「Description」カラムが見つかりません。');
//       }

//       const newData = data.map((item) => {
//         const title = item[titleKey] || '';
//         let description = item[descriptionKey] || '';
//         let sourceText = '';

//         // マッチングソースに応じてテキストを設定
//         if (matchSource === 'title') {
//           sourceText = title;
//         } else if (matchSource === 'description') {
//           // DescriptionからAI生成部分を抽出
//           const aiContent = extractAIGeneratedContent(description);
//           sourceText = aiContent;
//         } else if (matchSource === 'both') {
//           // DescriptionからAI生成部分を抽出
//           const aiContent = extractAIGeneratedContent(description);
//           sourceText = `${title} ${aiContent}`;
//         }

//         const newItem = { ...item };

//         selectedColumns.forEach((column) => {
//           const conversionRules = categories[selectedCategory]?.columns[column]?.conversionRules || [];
//           let valueSet = false;

//           // 変換ルールの適用
//           for (const rule of conversionRules) {
//             const { keyword, value } = rule;
//             if (!keyword || !value) continue;

//             const flags = caseSensitive ? 'g' : 'gi';
//             const regex = new RegExp(keyword, flags);

//             const matchCondition = partialMatch
//               ? regex.test(sourceText)
//               : caseSensitive
//               ? sourceText === keyword
//               : sourceText.toLowerCase() === keyword.toLowerCase();

//             if (matchCondition) {
//               newItem[column] = value;
//               valueSet = true;
//               break;
//             }
//           }

//           // 変換ルールで値が設定されなかった場合、値のリストを適用
//           if (!valueSet) {
//             const values = categories[selectedCategory]?.columns[column]?.values || [];
//             for (const value of values) {
//               if (typeof value !== 'string') continue;

//               const flags = caseSensitive ? 'g' : 'gi';
//               const regex = new RegExp(value, flags);

//               const matchCondition = partialMatch
//                 ? regex.test(sourceText)
//                 : caseSensitive
//                 ? sourceText === value
//                 : sourceText.toLowerCase() === value.toLowerCase();

//               if (matchCondition) {
//                 newItem[column] = value;
//                 break;
//               }
//             }
//           }
//         });

//         return newItem;
//       });

//       setUpdatedData(newData);

//       // プレビュー用データを作成（上位10件のみ）
//       const previewItems = newData.slice(0, 10).map((item, index) => {
//         const originalItem = data[index];
//         const previewItem = { Title: item[titleKey] || '' };

//         if (matchSource !== 'title') {
//           previewItem.Description = item[descriptionKey] || '';
//           // AI生成部分のみを抽出してプレビューに表示
//           previewItem['AI Generated Content'] = extractAIGeneratedContent(item[descriptionKey]);
//         }

//         selectedColumns.forEach((column) => {
//           previewItem[`${column} (Before)`] = originalItem[column] || '';
//           previewItem[`${column} (After)`] = item[column] || '';
//         });

//         return previewItem;
//       });

//       setPreviewData(previewItems);
//       setPreviewOpen(true);
//     } catch (error) {
//       console.error('Error updating data:', error);
//       setSnackbar({ open: true, message: `データの更新中にエラーが発生しました: ${error.message}`, severity: 'error' });
//     } finally {
//       setLoading(false);
//     }
//   };

//   // プレビューを確認し、データを更新する
//   const confirmUpdate = () => {
//     if (updatedData.length === 0) {
//       setSnackbar({ open: true, message: '更新データが存在しません。', severity: 'warning' });
//       return;
//     }

//     setData(updatedData);
//     setSnackbar({ open: true, message: 'データが正常に更新されました。', severity: 'success' });
//     setPreviewOpen(false);
//   };

//   // 設定ダイアログを開く
//   const handleOpenSettingsDialog = () => {
//     setOpenSettingsDialog(true);
//   };

//   // 設定ダイアログを閉じる
//   const handleCloseSettingsDialog = () => {
//     setOpenSettingsDialog(false);
//   };

//   // カラムの選択/解除を行う
//   const handleColumnToggle = (column) => {
//     setSelectedColumns((prev) =>
//       prev.includes(column) ? prev.filter((c) => c !== column) : [...prev, column]
//     );
//   };

//   // 大文字小文字区別の切り替え
//   const handleCaseSensitiveToggle = (event) => {
//     setCaseSensitive(event.target.checked);
//   };

//   // 部分一致の切り替え
//   const handlePartialMatchToggle = (event) => {
//     setPartialMatch(event.target.checked);
//   };

//   // 設定ダイアログ内で選択されたカテゴリーのカラムを取得する
//   const getColumnsForSelectedCategory = () => {
//     if (selectedCategory && categories[selectedCategory]) {
//       return Object.keys(categories[selectedCategory].columns || {});
//     }
//     return [];
//   };

//   // マッチングソースの変更
//   const handleMatchSourceChange = (event) => {
//     setMatchSource(event.target.value);
//   };

//   return (
//     <Box sx={{ p: 2, mt: 2 }}>
//       <Typography variant="h5" gutterBottom>
//         Item Specifics適用
//       </Typography>
//       <Typography variant="body1" paragraph>
//         設定したItem Specificsをタイトルや商品説明に基づいて適用し、該当するカラムを更新します。
//       </Typography>
//       <Box
//         sx={{
//           mt: 2,
//           display: 'flex',
//           flexDirection: { xs: 'column', sm: 'row' },
//           justifyContent: 'space-between',
//           alignItems: 'center',
//         }}
//       >
//         <Box>
//           <Button
//             startIcon={<UpdateIcon />}
//             onClick={handleUpdateData}
//             variant="contained"
//             color="primary"
//             disabled={loading}
//             sx={{ mr: 2, mb: { xs: 2, sm: 0 } }}
//           >
//             {loading ? <CircularProgress size={24} /> : 'データを更新'}
//           </Button>
//           <Button
//             startIcon={<SettingsIcon />}
//             onClick={handleOpenSettingsDialog}
//             variant="outlined"
//             color="secondary"
//             sx={{ mr: 2 }}
//           >
//             適用設定
//           </Button>
//           <Button variant="text" color="info" onClick={handleReloadSettings}>
//             データ再読み込み
//           </Button>
//         </Box>
//       </Box>

//       {/* 設定ダイアログ */}
//       <Dialog open={openSettingsDialog} onClose={handleCloseSettingsDialog} maxWidth="sm" fullWidth>
//         <DialogTitle>Item Specifics設定</DialogTitle>
//         <DialogContent>
//           {/* カテゴリー選択 */}
//           <FormControl fullWidth variant="outlined" size="small" sx={{ mt: 2, mb: 2 }}>
//             <InputLabel>適用するカテゴリー</InputLabel>
//             <Select
//               value={selectedCategory}
//               onChange={(e) => {
//                 setSelectedCategory(e.target.value);
//                 setSelectedColumns([]); // カテゴリー変更時に選択カラムをリセット
//               }}
//               label="適用するカテゴリー"
//             >
//               {Object.keys(categories).map((category) => (
//                 <MenuItem key={category} value={category}>
//                   {category}
//                 </MenuItem>
//               ))}
//             </Select>
//           </FormControl>

//           {/* カラムの選択 */}
//           {selectedCategory && (
//             <>
//               <Typography variant="subtitle1" gutterBottom>
//                 適用するカラムを選択してください。
//               </Typography>
//               <List>
//                 {getColumnsForSelectedCategory().map((column) => (
//                   <ListItem key={column} button onClick={() => handleColumnToggle(column)}>
//                     <Checkbox
//                       edge="start"
//                       checked={selectedColumns.includes(column)}
//                       tabIndex={-1}
//                       disableRipple
//                     />
//                     <ListItemText primary={column} />
//                   </ListItem>
//                 ))}
//               </List>
//             </>
//           )}

//           {/* マッチングソースの選択 */}
//           <Box sx={{ mt: 2 }}>
//             <Typography variant="subtitle1" gutterBottom>
//               マッチングの基準を選択してください。
//             </Typography>
//             <FormControl component="fieldset">
//               <RadioGroup value={matchSource} onChange={handleMatchSourceChange}>
//                 <FormControlLabel value="title" control={<Radio />} label="タイトルのみ" />
//                 <FormControlLabel value="description" control={<Radio />} label="商品説明のみ (AI生成部分のみ)" />
//                 <FormControlLabel value="both" control={<Radio />} label="タイトルと商品説明の両方 (AI生成部分のみ)" />
//               </RadioGroup>
//             </FormControl>
//           </Box>

//           {/* オプション */}
//           <Box sx={{ mt: 2 }}>
//             <FormControlLabel
//               control={
//                 <Checkbox
//                   checked={caseSensitive}
//                   onChange={handleCaseSensitiveToggle}
//                   name="caseSensitive"
//                 />
//               }
//               label="大文字小文字を区別する"
//             />
//             <FormControlLabel
//               control={
//                 <Checkbox
//                   checked={partialMatch}
//                   onChange={handlePartialMatchToggle}
//                   name="partialMatch"
//                 />
//               }
//               label="部分一致を許可する"
//             />
//           </Box>
//         </DialogContent>
//         <DialogActions>
//           <Button onClick={handleCloseSettingsDialog} color="primary">
//             閉じる
//           </Button>
//         </DialogActions>
//       </Dialog>

//       {/* プレビューダイアログ */}
//       <Dialog open={previewOpen} onClose={() => setPreviewOpen(false)} maxWidth="lg" fullWidth>
//         <DialogTitle>更新プレビュー</DialogTitle>
//         <DialogContent>
//           <Typography variant="body2" paragraph>
//             以下の内容で更新されます。確認してください。
//           </Typography>
//           <Box sx={{ maxHeight: 400, overflow: 'auto' }}>
//             <table style={{ width: '100%', borderCollapse: 'collapse' }}>
//               <thead>
//                 <tr>
//                   {previewData[0] &&
//                     Object.keys(previewData[0]).map((header, index) => (
//                       <th
//                         key={index}
//                         style={{
//                           border: '1px solid #ccc',
//                           padding: '8px',
//                           backgroundColor: '#f5f5f5',
//                           position: 'sticky',
//                           top: 0,
//                         }}
//                       >
//                         {header}
//                       </th>
//                     ))}
//                 </tr>
//               </thead>
//               <tbody>
//                 {previewData.map((row, rowIndex) => (
//                   <tr key={rowIndex}>
//                     {Object.values(row).map((cell, cellIndex) => (
//                       <td key={cellIndex} style={{ border: '1px solid #ccc', padding: '8px' }}>
//                         {cell}
//                       </td>
//                     ))}
//                   </tr>
//                 ))}
//               </tbody>
//             </table>
//           </Box>
//           {updatedData.length > 10 && (
//             <Typography variant="body2" sx={{ mt: 2 }}>
//               (表示は上位10件のみです。実際の更新はすべてのデータに適用されます。)
//             </Typography>
//           )}
//         </DialogContent>
//         <DialogActions>
//           <Button onClick={() => setPreviewOpen(false)} color="secondary">
//             キャンセル
//           </Button>
//           <Button onClick={confirmUpdate} color="primary" variant="contained">
//             更新を確定
//           </Button>
//         </DialogActions>
//       </Dialog>

//       {/* スナックバー */}
//       <Snackbar
//         open={snackbar.open}
//         autoHideDuration={6000}
//         onClose={() => setSnackbar({ ...snackbar, open: false })}
//         anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
//       >
//         <Alert
//           onClose={() => setSnackbar({ ...snackbar, open: false })}
//           severity={snackbar.severity}
//           variant="filled"
//         >
//           {snackbar.message}
//         </Alert>
//       </Snackbar>
//     </Box>
//   );
// };

// export default ItemSpecificsApplier;





// // ItemSpecificsApplier.js

// import React, { useState, useEffect } from 'react';
// import {
//   Button,
//   Typography,
//   Box,
//   CircularProgress,
//   Snackbar,
//   Alert,
//   Dialog,
//   DialogActions,
//   DialogContent,
//   DialogTitle,
//   List,
//   ListItem,
//   ListItemText,
//   Checkbox,
//   FormControlLabel,
//   FormControl,
//   InputLabel,
//   Select,
//   MenuItem,
//   RadioGroup,
//   Radio,
// } from '@mui/material';
// import UpdateIcon from '@mui/icons-material/Update';
// import SettingsIcon from '@mui/icons-material/Settings';
// import { getFirestore, doc, getDoc } from 'firebase/firestore';

// const ItemSpecificsApplier = ({ data, setData, userId }) => {
//   const [loading, setLoading] = useState(false);
//   const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'info' });
//   const [categories, setCategories] = useState({});
//   const [openSettingsDialog, setOpenSettingsDialog] = useState(false);
//   const [selectedCategory, setSelectedCategory] = useState('');
//   const [selectedColumns, setSelectedColumns] = useState([]);
//   const [caseSensitive, setCaseSensitive] = useState(false);
//   const [partialMatch, setPartialMatch] = useState(true);
//   const [previewData, setPreviewData] = useState([]);
//   const [previewOpen, setPreviewOpen] = useState(false);
//   const [headers, setHeaders] = useState([]);
//   const [updatedData, setUpdatedData] = useState([]);
//   const [matchSource, setMatchSource] = useState('title'); // 追加: マッチングの基準

//   const db = getFirestore();

//   // Firestoreから設定を読み込む
//   const loadSettings = async () => {
//     if (!userId) {
//       setSnackbar({ open: true, message: 'ユーザー情報がありません。', severity: 'error' });
//       return;
//     }

//     try {
//       const docRef = doc(db, 'itemSpecificsSettings', userId);
//       const docSnap = await getDoc(docRef);

//       if (docSnap.exists()) {
//         const userSettings = docSnap.data();
//         setCategories(userSettings.categories || {});
//         setSnackbar({ open: true, message: '設定を読み込みました。', severity: 'success' });
//       } else {
//         setCategories({});
//         setSnackbar({ open: true, message: '設定が見つかりません。', severity: 'info' });
//       }
//     } catch (error) {
//       console.error('Failed to load item specifics settings:', error);
//       setSnackbar({ open: true, message: '設定の読み込みに失敗しました。', severity: 'error' });
//     }
//   };

//   // 初回マウント時に設定を読み込む
//   useEffect(() => {
//     loadSettings();
//   }, [userId]);

//   // ヘッダーの取得
//   useEffect(() => {
//     if (data && data.length > 0) {
//       setHeaders(Object.keys(data[0]));
//     }
//   }, [data]);

//   // 設定を再読み込みする関数
//   const handleReloadSettings = () => {
//     loadSettings();
//   };

//   // データにItem Specificsを適用し、プレビューを表示する
//   const handleUpdateData = () => {
//     if (!selectedCategory) {
//       setSnackbar({ open: true, message: '適用するカテゴリーを選択してください。', severity: 'warning' });
//       return;
//     }

//     if (selectedColumns.length === 0) {
//       setSnackbar({ open: true, message: '適用するカラムを選択してください。', severity: 'warning' });
//       return;
//     }

//     setLoading(true);

//     try {
//       const titleKey = headers.find((header) => header.toLowerCase() === 'title');
//       const descriptionKey = headers.find((header) => header.toLowerCase() === 'description');

//       if (!titleKey && (matchSource === 'title' || matchSource === 'both')) {
//         throw new Error('「Title」カラムが見つかりません。');
//       }

//       if (!descriptionKey && (matchSource === 'description' || matchSource === 'both')) {
//         throw new Error('「Description」カラムが見つかりません。');
//       }

//       const newData = data.map((item) => {
//         const title = item[titleKey] || '';
//         const description = item[descriptionKey] || '';
//         let sourceText = '';

//         // マッチングソースに応じてテキストを設定
//         if (matchSource === 'title') {
//           sourceText = title;
//         } else if (matchSource === 'description') {
//           sourceText = description;
//         } else if (matchSource === 'both') {
//           sourceText = `${title} ${description}`;
//         }

//         const newItem = { ...item };

//         selectedColumns.forEach((column) => {
//           const conversionRules = categories[selectedCategory]?.columns[column]?.conversionRules || [];
//           let valueSet = false;

//           // 変換ルールの適用
//           for (const rule of conversionRules) {
//             const { keyword, value } = rule;
//             if (!keyword || !value) continue;

//             const flags = caseSensitive ? 'g' : 'gi';
//             const regex = new RegExp(keyword, flags);

//             const matchCondition = partialMatch
//               ? regex.test(sourceText)
//               : caseSensitive
//               ? sourceText === keyword
//               : sourceText.toLowerCase() === keyword.toLowerCase();

//             if (matchCondition) {
//               newItem[column] = value;
//               valueSet = true;
//               break;
//             }
//           }

//           // 変換ルールで値が設定されなかった場合、値のリストを適用
//           if (!valueSet) {
//             const values = categories[selectedCategory]?.columns[column]?.values || [];
//             for (const value of values) {
//               if (typeof value !== 'string') continue;

//               const flags = caseSensitive ? 'g' : 'gi';
//               const regex = new RegExp(value, flags);

//               const matchCondition = partialMatch
//                 ? regex.test(sourceText)
//                 : caseSensitive
//                 ? sourceText === value
//                 : sourceText.toLowerCase() === value.toLowerCase();

//               if (matchCondition) {
//                 newItem[column] = value;
//                 break;
//               }
//             }
//           }
//         });

//         return newItem;
//       });

//       setUpdatedData(newData);

//       // プレビュー用データを作成（上位10件のみ）
//       const previewItems = newData.slice(0, 10).map((item, index) => {
//         const originalItem = data[index];
//         const previewItem = { Title: item[titleKey] || '' };

//         if (matchSource !== 'title') {
//           previewItem.Description = item[descriptionKey] || '';
//         }

//         selectedColumns.forEach((column) => {
//           previewItem[`${column} (Before)`] = originalItem[column] || '';
//           previewItem[`${column} (After)`] = item[column] || '';
//         });

//         return previewItem;
//       });

//       setPreviewData(previewItems);
//       setPreviewOpen(true);
//     } catch (error) {
//       console.error('Error updating data:', error);
//       setSnackbar({ open: true, message: `データの更新中にエラーが発生しました: ${error.message}`, severity: 'error' });
//     } finally {
//       setLoading(false);
//     }
//   };

//   // プレビューを確認し、データを更新する
//   const confirmUpdate = () => {
//     if (updatedData.length === 0) {
//       setSnackbar({ open: true, message: '更新データが存在しません。', severity: 'warning' });
//       return;
//     }

//     setData(updatedData);
//     setSnackbar({ open: true, message: 'データが正常に更新されました。', severity: 'success' });
//     setPreviewOpen(false);
//   };

//   // 設定ダイアログを開く
//   const handleOpenSettingsDialog = () => {
//     setOpenSettingsDialog(true);
//   };

//   // 設定ダイアログを閉じる
//   const handleCloseSettingsDialog = () => {
//     setOpenSettingsDialog(false);
//   };

//   // カラムの選択/解除を行う
//   const handleColumnToggle = (column) => {
//     setSelectedColumns((prev) =>
//       prev.includes(column) ? prev.filter((c) => c !== column) : [...prev, column]
//     );
//   };

//   // 大文字小文字区別の切り替え
//   const handleCaseSensitiveToggle = (event) => {
//     setCaseSensitive(event.target.checked);
//   };

//   // 部分一致の切り替え
//   const handlePartialMatchToggle = (event) => {
//     setPartialMatch(event.target.checked);
//   };

//   // 設定ダイアログ内で選択されたカテゴリーのカラムを取得する
//   const getColumnsForSelectedCategory = () => {
//     if (selectedCategory && categories[selectedCategory]) {
//       return Object.keys(categories[selectedCategory].columns || {});
//     }
//     return [];
//   };

//   // マッチングソースの変更
//   const handleMatchSourceChange = (event) => {
//     setMatchSource(event.target.value);
//   };

//   return (
//     <Box sx={{ p: 2, mt: 2 }}>
//       <Typography variant="h5" gutterBottom>
//         Item Specifics適用
//       </Typography>
//       <Typography variant="body1" paragraph>
//         設定したItem Specificsをタイトルや商品説明に基づいて適用し、該当するカラムを更新します。
//       </Typography>
//       <Box
//         sx={{
//           mt: 2,
//           display: 'flex',
//           flexDirection: { xs: 'column', sm: 'row' },
//           justifyContent: 'space-between',
//           alignItems: 'center',
//         }}
//       >
//         <Box>
//           <Button
//             startIcon={<UpdateIcon />}
//             onClick={handleUpdateData}
//             variant="contained"
//             color="primary"
//             disabled={loading}
//             sx={{ mr: 2, mb: { xs: 2, sm: 0 } }}
//           >
//             {loading ? <CircularProgress size={24} /> : 'データを更新'}
//           </Button>
//           <Button
//             startIcon={<SettingsIcon />}
//             onClick={handleOpenSettingsDialog}
//             variant="outlined"
//             color="secondary"
//             sx={{ mr: 2 }}
//           >
//             適用設定
//           </Button>
//           <Button variant="text" color="info" onClick={handleReloadSettings}>
//             データ再読み込み
//           </Button>
//         </Box>
//       </Box>

//       {/* 設定ダイアログ */}
//       <Dialog open={openSettingsDialog} onClose={handleCloseSettingsDialog} maxWidth="sm" fullWidth>
//         <DialogTitle>Item Specifics設定</DialogTitle>
//         <DialogContent>
//           {/* カテゴリー選択 */}
//           <FormControl fullWidth variant="outlined" size="small" sx={{ mt: 2, mb: 2 }}>
//             <InputLabel>適用するカテゴリー</InputLabel>
//             <Select
//               value={selectedCategory}
//               onChange={(e) => {
//                 setSelectedCategory(e.target.value);
//                 setSelectedColumns([]); // カテゴリー変更時に選択カラムをリセット
//               }}
//               label="適用するカテゴリー"
//             >
//               {Object.keys(categories).map((category) => (
//                 <MenuItem key={category} value={category}>
//                   {category}
//                 </MenuItem>
//               ))}
//             </Select>
//           </FormControl>

//           {/* カラムの選択 */}
//           {selectedCategory && (
//             <>
//               <Typography variant="subtitle1" gutterBottom>
//                 適用するカラムを選択してください。
//               </Typography>
//               <List>
//                 {getColumnsForSelectedCategory().map((column) => (
//                   <ListItem key={column} button onClick={() => handleColumnToggle(column)}>
//                     <Checkbox
//                       edge="start"
//                       checked={selectedColumns.includes(column)}
//                       tabIndex={-1}
//                       disableRipple
//                     />
//                     <ListItemText primary={column} />
//                   </ListItem>
//                 ))}
//               </List>
//             </>
//           )}

//           {/* マッチングソースの選択 */}
//           <Box sx={{ mt: 2 }}>
//             <Typography variant="subtitle1" gutterBottom>
//               マッチングの基準を選択してください。
//             </Typography>
//             <FormControl component="fieldset">
//               <RadioGroup value={matchSource} onChange={handleMatchSourceChange}>
//                 <FormControlLabel value="title" control={<Radio />} label="タイトルのみ" />
//                 <FormControlLabel value="description" control={<Radio />} label="商品説明のみ" />
//                 <FormControlLabel value="both" control={<Radio />} label="タイトルと商品説明の両方" />
//               </RadioGroup>
//             </FormControl>
//           </Box>

//           {/* オプション */}
//           <Box sx={{ mt: 2 }}>
//             <FormControlLabel
//               control={
//                 <Checkbox
//                   checked={caseSensitive}
//                   onChange={handleCaseSensitiveToggle}
//                   name="caseSensitive"
//                 />
//               }
//               label="大文字小文字を区別する"
//             />
//             <FormControlLabel
//               control={
//                 <Checkbox
//                   checked={partialMatch}
//                   onChange={handlePartialMatchToggle}
//                   name="partialMatch"
//                 />
//               }
//               label="部分一致を許可する"
//             />
//           </Box>
//         </DialogContent>
//         <DialogActions>
//           <Button onClick={handleCloseSettingsDialog} color="primary">
//             閉じる
//           </Button>
//         </DialogActions>
//       </Dialog>

//       {/* プレビューダイアログ */}
//       <Dialog open={previewOpen} onClose={() => setPreviewOpen(false)} maxWidth="lg" fullWidth>
//         <DialogTitle>更新プレビュー</DialogTitle>
//         <DialogContent>
//           <Typography variant="body2" paragraph>
//             以下の内容で更新されます。確認してください。
//           </Typography>
//           <Box sx={{ maxHeight: 400, overflow: 'auto' }}>
//             <table style={{ width: '100%', borderCollapse: 'collapse' }}>
//               <thead>
//                 <tr>
//                   {previewData[0] &&
//                     Object.keys(previewData[0]).map((header, index) => (
//                       <th
//                         key={index}
//                         style={{
//                           border: '1px solid #ccc',
//                           padding: '8px',
//                           backgroundColor: '#f5f5f5',
//                           position: 'sticky',
//                           top: 0,
//                         }}
//                       >
//                         {header}
//                       </th>
//                     ))}
//                 </tr>
//               </thead>
//               <tbody>
//                 {previewData.map((row, rowIndex) => (
//                   <tr key={rowIndex}>
//                     {Object.values(row).map((cell, cellIndex) => (
//                       <td key={cellIndex} style={{ border: '1px solid #ccc', padding: '8px' }}>
//                         {cell}
//                       </td>
//                     ))}
//                   </tr>
//                 ))}
//               </tbody>
//             </table>
//           </Box>
//           {updatedData.length > 10 && (
//             <Typography variant="body2" sx={{ mt: 2 }}>
//               (表示は上位10件のみです。実際の更新はすべてのデータに適用されます。)
//             </Typography>
//           )}
//         </DialogContent>
//         <DialogActions>
//           <Button onClick={() => setPreviewOpen(false)} color="secondary">
//             キャンセル
//           </Button>
//           <Button onClick={confirmUpdate} color="primary" variant="contained">
//             更新を確定
//           </Button>
//         </DialogActions>
//       </Dialog>

//       {/* スナックバー */}
//       <Snackbar
//         open={snackbar.open}
//         autoHideDuration={6000}
//         onClose={() => setSnackbar({ ...snackbar, open: false })}
//         anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
//       >
//         <Alert
//           onClose={() => setSnackbar({ ...snackbar, open: false })}
//           severity={snackbar.severity}
//           variant="filled"
//         >
//           {snackbar.message}
//         </Alert>
//       </Snackbar>
//     </Box>
//   );
// };

// export default ItemSpecificsApplier;




// // ItemSpecificsApplier.js

// import React, { useState, useEffect } from 'react';
// import {
//   Button,
//   Typography,
//   Box,
//   CircularProgress,
//   Snackbar,
//   Alert,
//   Dialog,
//   DialogActions,
//   DialogContent,
//   DialogTitle,
//   List,
//   ListItem,
//   ListItemText,
//   Checkbox,
//   FormControlLabel,
//   FormControl,
//   InputLabel,
//   Select,
//   MenuItem,
// } from '@mui/material';
// import UpdateIcon from '@mui/icons-material/Update';
// import SettingsIcon from '@mui/icons-material/Settings';
// import { getFirestore, doc, getDoc } from 'firebase/firestore';

// const ItemSpecificsApplier = ({ data, setData, userId }) => {
//   const [loading, setLoading] = useState(false);
//   const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'info' });
//   const [categories, setCategories] = useState({});
//   const [openSettingsDialog, setOpenSettingsDialog] = useState(false);
//   const [selectedCategory, setSelectedCategory] = useState('');
//   const [selectedColumns, setSelectedColumns] = useState([]);
//   const [caseSensitive, setCaseSensitive] = useState(false);
//   const [partialMatch, setPartialMatch] = useState(true);
//   const [previewData, setPreviewData] = useState([]);
//   const [previewOpen, setPreviewOpen] = useState(false);
//   const [headers, setHeaders] = useState([]);
//   const [updatedData, setUpdatedData] = useState([]);

//   const db = getFirestore();

//   // Firestoreから設定を読み込む
//   const loadSettings = async () => {
//     if (!userId) {
//       setSnackbar({ open: true, message: 'ユーザー情報がありません。', severity: 'error' });
//       return;
//     }

//     try {
//       const docRef = doc(db, 'itemSpecificsSettings', userId);
//       const docSnap = await getDoc(docRef);

//       if (docSnap.exists()) {
//         const userSettings = docSnap.data();
//         setCategories(userSettings.categories || {});
//         setSnackbar({ open: true, message: '設定を読み込みました。', severity: 'success' });
//       } else {
//         setCategories({});
//         setSnackbar({ open: true, message: '設定が見つかりません。', severity: 'info' });
//       }
//     } catch (error) {
//       console.error('Failed to load item specifics settings:', error);
//       setSnackbar({ open: true, message: '設定の読み込みに失敗しました。', severity: 'error' });
//     }
//   };

//   // 初回マウント時に設定を読み込む
//   useEffect(() => {
//     loadSettings();
//   }, [userId]);

//   // ヘッダーの取得
//   useEffect(() => {
//     if (data && data.length > 0) {
//       setHeaders(Object.keys(data[0]));
//     }
//   }, [data]);

//   // 設定を再読み込みする関数
//   const handleReloadSettings = () => {
//     loadSettings();
//   };

//   // データにItem Specificsを適用し、プレビューを表示する
//   const handleUpdateData = () => {
//     if (!selectedCategory) {
//       setSnackbar({ open: true, message: '適用するカテゴリーを選択してください。', severity: 'warning' });
//       return;
//     }

//     if (selectedColumns.length === 0) {
//       setSnackbar({ open: true, message: '適用するカラムを選択してください。', severity: 'warning' });
//       return;
//     }

//     setLoading(true);

//     try {
//       const titleKey = headers.find((header) => header.toLowerCase() === 'title');
//       if (!titleKey) {
//         throw new Error('「Title」カラムが見つかりません。');
//       }

//       const newData = data.map((item) => {
//         const title = item[titleKey] || '';
//         const newItem = { ...item };

//         selectedColumns.forEach((column) => {
//           const conversionRules = categories[selectedCategory]?.columns[column]?.conversionRules || [];
//           let valueSet = false;

//           // 変換ルールの適用
//           for (const rule of conversionRules) {
//             const { keyword, value } = rule;
//             if (!keyword || !value) continue;

//             const flags = caseSensitive ? 'g' : 'gi';
//             const regex = new RegExp(keyword, flags);

//             const matchCondition = partialMatch
//               ? regex.test(title)
//               : caseSensitive
//               ? title === keyword
//               : title.toLowerCase() === keyword.toLowerCase();

//             if (matchCondition) {
//               newItem[column] = value;
//               valueSet = true;
//               break;
//             }
//           }

//           // 変換ルールで値が設定されなかった場合、値のリストを適用
//           if (!valueSet) {
//             const values = categories[selectedCategory]?.columns[column]?.values || [];
//             for (const value of values) {
//               if (typeof value !== 'string') continue;

//               const flags = caseSensitive ? 'g' : 'gi';
//               const regex = new RegExp(value, flags);

//               const matchCondition = partialMatch
//                 ? regex.test(title)
//                 : caseSensitive
//                 ? title === value
//                 : title.toLowerCase() === value.toLowerCase();

//               if (matchCondition) {
//                 newItem[column] = value;
//                 break;
//               }
//             }
//           }
//         });

//         return newItem;
//       });

//       setUpdatedData(newData);

//       // プレビュー用データを作成（上位10件のみ）
//       const previewItems = newData.slice(0, 10).map((item, index) => {
//         const originalItem = data[index];
//         const previewItem = { Title: item[titleKey] || '' };

//         selectedColumns.forEach((column) => {
//           previewItem[`${column} (Before)`] = originalItem[column] || '';
//           previewItem[`${column} (After)`] = item[column] || '';
//         });

//         return previewItem;
//       });

//       setPreviewData(previewItems);
//       setPreviewOpen(true);
//     } catch (error) {
//       console.error('Error updating data:', error);
//       setSnackbar({ open: true, message: `データの更新中にエラーが発生しました: ${error.message}`, severity: 'error' });
//     } finally {
//       setLoading(false);
//     }
//   };

//   // プレビューを確認し、データを更新する
//   const confirmUpdate = () => {
//     if (updatedData.length === 0) {
//       setSnackbar({ open: true, message: '更新データが存在しません。', severity: 'warning' });
//       return;
//     }

//     setData(updatedData);
//     setSnackbar({ open: true, message: 'データが正常に更新されました。', severity: 'success' });
//     setPreviewOpen(false);
//   };

//   // 設定ダイアログを開く
//   const handleOpenSettingsDialog = () => {
//     setOpenSettingsDialog(true);
//   };

//   // 設定ダイアログを閉じる
//   const handleCloseSettingsDialog = () => {
//     setOpenSettingsDialog(false);
//   };

//   // カラムの選択/解除を行う
//   const handleColumnToggle = (column) => {
//     setSelectedColumns((prev) =>
//       prev.includes(column) ? prev.filter((c) => c !== column) : [...prev, column]
//     );
//   };

//   // 大文字小文字区別の切り替え
//   const handleCaseSensitiveToggle = (event) => {
//     setCaseSensitive(event.target.checked);
//   };

//   // 部分一致の切り替え
//   const handlePartialMatchToggle = (event) => {
//     setPartialMatch(event.target.checked);
//   };

//   // 設定ダイアログ内で選択されたカテゴリーのカラムを取得する
//   const getColumnsForSelectedCategory = () => {
//     if (selectedCategory && categories[selectedCategory]) {
//       return Object.keys(categories[selectedCategory].columns || {});
//     }
//     return [];
//   };

//   return (
//     <Box sx={{ p: 2, mt: 2 }}>
//       <Typography variant="h5" gutterBottom>
//         Item Specifics適用
//       </Typography>
//       <Typography variant="body1" paragraph>
//         設定したItem Specificsをタイトルに基づいて適用し、該当するカラムを更新します。
//       </Typography>
//       <Box
//         sx={{
//           mt: 2,
//           display: 'flex',
//           flexDirection: { xs: 'column', sm: 'row' },
//           justifyContent: 'space-between',
//           alignItems: 'center',
//         }}
//       >
//         <Box>
//           <Button
//             startIcon={<UpdateIcon />}
//             onClick={handleUpdateData}
//             variant="contained"
//             color="primary"
//             disabled={loading}
//             sx={{ mr: 2, mb: { xs: 2, sm: 0 } }}
//           >
//             {loading ? <CircularProgress size={24} /> : 'データを更新'}
//           </Button>
//           <Button
//             startIcon={<SettingsIcon />}
//             onClick={handleOpenSettingsDialog}
//             variant="outlined"
//             color="secondary"
//             sx={{ mr: 2 }}
//           >
//             適用カテゴリー選択
//           </Button>
//           <Button variant="text" color="info" onClick={handleReloadSettings}>
//             データ再読み込み
//           </Button>
//         </Box>
//       </Box>

//       {/* 設定ダイアログ */}
//       <Dialog open={openSettingsDialog} onClose={handleCloseSettingsDialog} maxWidth="sm" fullWidth>
//         <DialogTitle>Item Specifics設定</DialogTitle>
//         <DialogContent>
//           {/* カテゴリー選択 */}
//           <FormControl fullWidth variant="outlined" size="small" sx={{ mt: 2, mb: 2 }}>
//             <InputLabel>適用するカテゴリー</InputLabel>
//             <Select
//               value={selectedCategory}
//               onChange={(e) => {
//                 setSelectedCategory(e.target.value);
//                 setSelectedColumns([]); // カテゴリー変更時に選択カラムをリセット
//               }}
//               label="適用するカテゴリー"
//             >
//               {Object.keys(categories).map((category) => (
//                 <MenuItem key={category} value={category}>
//                   {category}
//                 </MenuItem>
//               ))}
//             </Select>
//           </FormControl>

//           {/* カラムの選択 */}
//           {selectedCategory && (
//             <>
//               <Typography variant="subtitle1" gutterBottom>
//                 適用するカラムを選択してください。
//               </Typography>
//               <List>
//                 {getColumnsForSelectedCategory().map((column) => (
//                   <ListItem key={column} button onClick={() => handleColumnToggle(column)}>
//                     <Checkbox
//                       edge="start"
//                       checked={selectedColumns.includes(column)}
//                       tabIndex={-1}
//                       disableRipple
//                     />
//                     <ListItemText primary={column} />
//                   </ListItem>
//                 ))}
//               </List>
//             </>
//           )}

//           {/* オプション */}
//           <Box sx={{ mt: 2 }}>
//             <FormControlLabel
//               control={
//                 <Checkbox
//                   checked={caseSensitive}
//                   onChange={handleCaseSensitiveToggle}
//                   name="caseSensitive"
//                 />
//               }
//               label="大文字小文字を区別する"
//             />
//             <FormControlLabel
//               control={
//                 <Checkbox
//                   checked={partialMatch}
//                   onChange={handlePartialMatchToggle}
//                   name="partialMatch"
//                 />
//               }
//               label="部分一致を許可する"
//             />
//           </Box>
//         </DialogContent>
//         <DialogActions>
//           <Button onClick={handleCloseSettingsDialog} color="primary">
//             閉じる
//           </Button>
//         </DialogActions>
//       </Dialog>

//       {/* プレビューダイアログ */}
//       <Dialog open={previewOpen} onClose={() => setPreviewOpen(false)} maxWidth="lg" fullWidth>
//         <DialogTitle>更新プレビュー</DialogTitle>
//         <DialogContent>
//           <Typography variant="body2" paragraph>
//             以下の内容で更新されます。確認してください。
//           </Typography>
//           <Box sx={{ maxHeight: 400, overflow: 'auto' }}>
//             <table style={{ width: '100%', borderCollapse: 'collapse' }}>
//               <thead>
//                 <tr>
//                   {previewData[0] &&
//                     Object.keys(previewData[0]).map((header, index) => (
//                       <th
//                         key={index}
//                         style={{
//                           border: '1px solid #ccc',
//                           padding: '8px',
//                           backgroundColor: '#f5f5f5',
//                           position: 'sticky',
//                           top: 0,
//                         }}
//                       >
//                         {header}
//                       </th>
//                     ))}
//                 </tr>
//               </thead>
//               <tbody>
//                 {previewData.map((row, rowIndex) => (
//                   <tr key={rowIndex}>
//                     {Object.values(row).map((cell, cellIndex) => (
//                       <td key={cellIndex} style={{ border: '1px solid #ccc', padding: '8px' }}>
//                         {cell}
//                       </td>
//                     ))}
//                   </tr>
//                 ))}
//               </tbody>
//             </table>
//           </Box>
//           {updatedData.length > 10 && (
//             <Typography variant="body2" sx={{ mt: 2 }}>
//               (表示は上位10件のみです。実際の更新はすべてのデータに適用されます。)
//             </Typography>
//           )}
//         </DialogContent>
//         <DialogActions>
//           <Button onClick={() => setPreviewOpen(false)} color="secondary">
//             キャンセル
//           </Button>
//           <Button onClick={confirmUpdate} color="primary" variant="contained">
//             更新を確定
//           </Button>
//         </DialogActions>
//       </Dialog>

//       {/* スナックバー */}
//       <Snackbar
//         open={snackbar.open}
//         autoHideDuration={6000}
//         onClose={() => setSnackbar({ ...snackbar, open: false })}
//         anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
//       >
//         <Alert
//           onClose={() => setSnackbar({ ...snackbar, open: false })}
//           severity={snackbar.severity}
//           variant="filled"
//         >
//           {snackbar.message}
//         </Alert>
//       </Snackbar>
//     </Box>
//   );
// };

// export default ItemSpecificsApplier;