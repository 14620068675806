// ResetConfirmationDialog.js

import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
} from '@mui/material';

const ResetConfirmationDialog = ({ open, handleClose, handleConfirm, selectedCount }) => {
  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>初期値にリセット</DialogTitle>
      <DialogContent>
        <Typography variant="body1">
          選択した{selectedCount}件の商品を初期値に戻します。よろしいですか？
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>キャンセル</Button>
        <Button onClick={handleConfirm} color="primary">
          リセット
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ResetConfirmationDialog;
