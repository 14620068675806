// ImageUploader.js

import React, { useState } from 'react';
import {
  Box,
  Button,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  LinearProgress,
  Grid,
  IconButton,
  CircularProgress,
  Pagination,
} from '@mui/material';
import { v4 as uuidv4 } from 'uuid';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import { DragDropContext, Droppable, Draggable } from '@hello-pangea/dnd';
import { getStorage, ref as storageRef, uploadBytes, getDownloadURL } from 'firebase/storage';
import { doc, getDoc, updateDoc, setDoc } from 'firebase/firestore';
import imageCompression from 'browser-image-compression';
import { db } from './firebaseConfig';
import LazyLoad from 'react-lazyload';

const MAX_CONCURRENT_UPLOADS = 10; // 一度にアップロードする最大の画像数

const ImageUploader = ({
  user,
  showSnackbar,
  defaultValues,
  columns,
  setColumns,
  data,
  setData,
  startRow,
  setStartRow,
  endRow,
  setEndRow,
}) => {
  // 画像関連のステート
  const [uploadedImages, setUploadedImages] = useState([]);
  const [isUploading, setIsUploading] = useState(false);
  const [groupedImages, setGroupedImages] = useState([]);
  const [isGrouping, setIsGrouping] = useState(false);

  const [isImageUploadDialogOpen, setIsImageUploadDialogOpen] = useState(false);
  const [imageFiles, setImageFiles] = useState([]);

  const [uploadProgress, setUploadProgress] = useState(0);
  const [compressionProgress, setCompressionProgress] = useState(0);
  const [isCompressing, setIsCompressing] = useState(false);

  // アップロードモードを管理するステート
  const [uploadMode, setUploadMode] = useState('new'); // 'new' または 'add'

  // ページネーション用のステート
  const [currentPage, setCurrentPage] = useState(1);
  const imagesPerPage = 30; // 1ページあたりの画像数

  // ページネーション用のインデックス計算
  const indexOfLastImage = currentPage * imagesPerPage;
  const indexOfFirstImage = indexOfLastImage - imagesPerPage;
  const currentImages = uploadedImages.slice(indexOfFirstImage, indexOfLastImage);

  // 最新のアップロード画像を取得
  const fetchLatestUploadedImages = async () => {
    if (!user) {
      alert('ユーザーが認証されていません。再度ログインしてください。');
      return;
    }

    setIsUploading(true);

    try {
      const userDocRef = doc(db, 'users', user.uid);
      const userDocSnap = await getDoc(userDocRef);

      if (userDocSnap.exists()) {
        const data = userDocSnap.data();
        const images = data.uploadedImages || [];
        setUploadedImages(images);
        showSnackbar('最新の画像を取得しました。', 'success');
      } else {
        showSnackbar('アップロードされた画像が見つかりません。', 'warning');
      }
    } catch (error) {
      console.error('画像の取得に失敗しました:', error);
      alert('画像の取得に失敗しました。再度お試しください。');
    } finally {
      setIsUploading(false);
    }
  };

  // 新規出品を開始
  const handleStartNewListing = () => {
    setUploadMode('new');
    setUploadedImages([]); // 既存の画像をクリア
    setIsImageUploadDialogOpen(true);
  };

  // 追加画像をアップロード
  const handleAddImages = () => {
    setUploadMode('add');
    setIsImageUploadDialogOpen(true);
  };

  // 画像ファイルの選択
  const handleImageFileChange = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const files = Array.from(e.target.files);
      setImageFiles(files);
    }
  };

  // 画像をFirebase Storageにアップロード
  const handleImageUpload = async () => {
    if (!imageFiles || imageFiles.length === 0) {
      alert('画像ファイルを選択してください。');
      return;
    }

    // ユーザーが認証されているか確認
    if (!user) {
      alert('ユーザーが認証されていません。再度ログインしてください。');
      return;
    }

    setIsCompressing(true);
    setCompressionProgress(0);

    try {
      // 画像の圧縮処理
      const compressedFiles = [];
      for (let i = 0; i < imageFiles.length; i++) {
        const file = imageFiles[i];
        try {
          const options = {
            maxSizeMB: 0.5, // 最大ファイルサイズを0.5MBに設定
            maxWidthOrHeight: 1600, // 最大の横幅または高さを1600pxに設定
            useWebWorker: true,
          };
          const compressedFile = await imageCompression(file, options);
          compressedFiles.push(compressedFile);
        } catch (error) {
          console.error('画像の圧縮に失敗しました:', error);
          compressedFiles.push(file); // 圧縮に失敗した場合は元のファイルを使用
        }
        // 圧縮進捗を更新
        setCompressionProgress(((i + 1) / imageFiles.length) * 100);
      }

      setIsCompressing(false);
      setIsUploading(true);
      setUploadProgress(0);

      const storage = getStorage();
      const uploadedUrls = [];
      let uploadQueue = [...compressedFiles];

      while (uploadQueue.length > 0) {
        const currentBatch = uploadQueue.slice(0, MAX_CONCURRENT_UPLOADS);
        uploadQueue = uploadQueue.slice(MAX_CONCURRENT_UPLOADS);

        const uploadPromises = currentBatch.map(async (file) => {
          try {
            const fileExtension = file.name.split('.').pop();
            const storageReference = storageRef(
              storage,
              `users/${user.uid}/images/${uuidv4()}.${fileExtension}`
            );
            const snapshot = await uploadBytes(storageReference, file);
            const downloadURL = await getDownloadURL(snapshot.ref);
            return {
              id: uuidv4(),
              url: downloadURL,
            };
          } catch (error) {
            console.error('画像のアップロードに失敗しました:', error);
            throw error;
          }
        });

        const batchResults = await Promise.all(uploadPromises);
        uploadedUrls.push(...batchResults);

        // アップロード進捗を更新
        setUploadProgress((prevProgress) => prevProgress + batchResults.length);
      }

      // 新規出品の場合は既存の画像をリセット
      let newUploadedImages;
      if (uploadMode === 'new') {
        newUploadedImages = [...uploadedUrls];
        setUploadedImages(newUploadedImages);
      } else {
        // 追加アップロードの場合は既存の画像に追加
        newUploadedImages = [...uploadedImages, ...uploadedUrls];
        setUploadedImages(newUploadedImages);
      }

      // Firestore に保存
      const userDocRef = doc(db, 'users', user.uid);
      await setDoc(userDocRef, { uploadedImages: newUploadedImages }, { merge: true });

      setIsImageUploadDialogOpen(false);
      setImageFiles([]);
      setUploadProgress(0);
    } catch (error) {
      console.error('画像のアップロードに失敗しました:', error);
      alert('画像のアップロードに失敗しました。再度お試しください。');
    } finally {
      setIsCompressing(false);
      setIsUploading(false);
    }
  };

  // 画像の削除
  const handleDeleteUploadedImage = async (id) => {
    // ローカルの状態を更新
    const newUploadedImages = uploadedImages.filter((img) => img.id !== id);
    setUploadedImages(newUploadedImages);

    // Firestore を更新
    try {
      const userDocRef = doc(db, 'users', user.uid);
      await updateDoc(userDocRef, { uploadedImages: newUploadedImages });
    } catch (error) {
      console.error('画像の削除時にエラーが発生しました:', error);
      alert('画像の削除に失敗しました。再度お試しください。');
    }
  };

  // 区切りを追加
  const addSeparator = async (index) => {
    const globalIndex = index + indexOfFirstImage;
    const newUploadedImages = [...uploadedImages];
    const separator = { id: uuidv4(), type: 'separator' };
    newUploadedImages.splice(globalIndex + 1, 0, separator);
    setUploadedImages(newUploadedImages);

    // Firestore を更新
    try {
      const userDocRef = doc(db, 'users', user.uid);
      await updateDoc(userDocRef, { uploadedImages: newUploadedImages });
    } catch (error) {
      console.error('区切りの追加時にエラーが発生しました:', error);
      alert('区切りの追加に失敗しました。再度お試しください。');
    }
  };

  // 区切りを削除
  const removeSeparator = async (id) => {
    const newUploadedImages = uploadedImages.filter((img) => img.id !== id);
    setUploadedImages(newUploadedImages);

    // Firestore を更新
    try {
      const userDocRef = doc(db, 'users', user.uid);
      await updateDoc(userDocRef, { uploadedImages: newUploadedImages });
    } catch (error) {
      console.error('区切りの削除時にエラーが発生しました:', error);
      alert('区切りの削除に失敗しました。再度お試しください。');
    }
  };

  // 画像をグループ化して商品を作成
  const handleGroupImages = () => {
    setIsGrouping(true);

    // 画像を区切りごとにグループ化する処理
    const products = [];
    let currentProductImages = [];

    for (const img of uploadedImages) {
      if (img.type === 'separator') {
        if (currentProductImages.length > 0) {
          products.push([...currentProductImages]);
          currentProductImages = [];
        }
      } else {
        currentProductImages.push(img);
      }
    }

    if (currentProductImages.length > 0) {
      products.push([...currentProductImages]);
    }

    setGroupedImages(products);
    setIsGrouping(false);

    // データを更新
    if (columns.length > 0) {
      const newData = products.map((productImages) => ({
        // 各カラムにデフォルト値を設定
        ...columns.reduce((obj, col) => {
          if (col === 'PicURL') {
            // PicURLカラムには画像URLを設定
            obj[col] = productImages.map((img) => img.url).join('|');
          } else if (defaultValues.hasOwnProperty(col)) {
            // デフォルト値が指定されているカラム
            obj[col] = defaultValues[col];
          } else {
            // それ以外のカラムには 'NA' を設定
            obj[col] = 'NA';
          }
          return obj;
        }, {}),
      }));
      setData(newData);

      // startRowとendRowを更新
      setStartRow(1);
      setEndRow(newData.length);
    }
  };

  // 画像のドラッグ＆ドロップによる並び替え
  const onDragEnd = async (result) => {
    if (!result.destination) {
      return;
    }

    const startIndex = indexOfFirstImage;
    const endIndex = indexOfLastImage;

    const currentImagesCopy = Array.from(currentImages);
    const [removed] = currentImagesCopy.splice(result.source.index, 1);
    currentImagesCopy.splice(result.destination.index, 0, removed);

    // 全体のuploadedImagesを更新
    const newUploadedImages = Array.from(uploadedImages);
    newUploadedImages.splice(startIndex, currentImagesCopy.length, ...currentImagesCopy);

    setUploadedImages(newUploadedImages);

    // Firestore を更新
    try {
      const userDocRef = doc(db, 'users', user.uid);
      await updateDoc(userDocRef, { uploadedImages: newUploadedImages });
    } catch (error) {
      console.error('画像の並び替え時にエラーが発生しました:', error);
      alert('画像の並び替えに失敗しました。再度お試しください。');
    }
  };

  return (
    <Box>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={4}>
          <Button variant="contained" color="primary" onClick={handleStartNewListing}>
            新規出品をする
          </Button>
        </Grid>
        {uploadedImages.length > 0 && (
          <Grid item xs={12} sm={6} md={4}>
            <Button variant="contained" color="primary" onClick={handleAddImages}>
              追加画像をアップロード
            </Button>
          </Grid>
        )}
        <Grid item xs={12} sm={6} md={4}>
          <Button variant="contained" color="primary" onClick={fetchLatestUploadedImages}>
            最新のアップロード画像を取得
          </Button>
        </Grid>
      </Grid>

      <Dialog open={isImageUploadDialogOpen} onClose={() => setIsImageUploadDialogOpen(false)}>
        <DialogTitle>
          {uploadMode === 'new' ? '新規出品の画像をアップロード' : '追加画像をアップロード'}
        </DialogTitle>
        <DialogContent>
          <input type="file" accept="image/*" multiple onChange={handleImageFileChange} />
          {imageFiles.length > 0 && (
            <Typography variant="body2" sx={{ mt: 2 }}>
              選択された画像: {imageFiles.length} 枚
            </Typography>
          )}
          {(isCompressing || isUploading) && (
            <Box sx={{ mt: 2 }}>
              {isCompressing && (
                <>
                  <Typography variant="body2">画像を圧縮しています...</Typography>
                  <LinearProgress variant="determinate" value={compressionProgress} />
                </>
              )}
              {isUploading && (
                <>
                  <Typography variant="body2">
                    画像をアップロードしています... ({uploadProgress} / {imageFiles.length})
                  </Typography>
                  <LinearProgress
                    variant="determinate"
                    value={(uploadProgress / imageFiles.length) * 100}
                  />
                </>
              )}
            </Box>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setIsImageUploadDialogOpen(false);
              setImageFiles([]);
            }}
            disabled={isCompressing || isUploading}
          >
            キャンセル
          </Button>
          <Button
            onClick={handleImageUpload}
            disabled={isCompressing || isUploading || imageFiles.length === 0}
          >
            アップロード
          </Button>
        </DialogActions>
      </Dialog>

      {uploadedImages.length > 0 && (
        <>
          <Typography variant="h6" sx={{ mt: 4 }}>
            アップロードされた画像
          </Typography>
          <Typography variant="body2" sx={{ mb: 2 }}>
            画像をドラッグ＆ドロップで並び替え、区切りを挿入して商品を分けることができます。
          </Typography>
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="uploadedImages" direction="horizontal">
              {(provided) => (
                <Box
                  sx={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    p: 1,
                    border: '1px solid #ccc',
                    borderRadius: '4px',
                  }}
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                >
                  {currentImages.map((img, index) => (
                    <Draggable key={img.id} draggableId={img.id} index={index}>
                      {(provided) => (
                        <Box
                          sx={{
                            position: 'relative',
                            m: 0.5,
                            width: '160px',
                            height: '160px',
                            overflow: 'hidden', // 追加: 画像が枠をはみ出さないようにする
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            backgroundColor: img.type === 'separator' ? '#f0f0f0' : 'transparent',
                            border: img.type === 'separator' ? '2px dashed #ccc' : '1px solid #ccc',
                            borderRadius: '4px',
                          }}
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                        >
                          {img.type === 'separator' ? (
                            <>
                              <Typography variant="body2">区切り</Typography>
                              <IconButton
                                size="small"
                                sx={{
                                  position: 'absolute',
                                  top: '4px',
                                  right: '4px',
                                  color: 'red',
                                  backgroundColor: 'white',
                                  '&:hover': {
                                    backgroundColor: 'white',
                                  },
                                }}
                                onClick={() => removeSeparator(img.id)}
                              >
                                <DeleteIcon fontSize="small" />
                              </IconButton>
                            </>
                          ) : (
                            <>
                              <LazyLoad height={160} offset={100}>
                                <img
                                  src={img.url}
                                  alt={`Uploaded ${index}`}
                                  style={{
                                    width: '100%',
                                    height: '100%',
                                    objectFit: 'cover',
                                  }}
                                />
                              </LazyLoad>
                              <IconButton
                                size="small"
                                sx={{
                                  position: 'absolute',
                                  top: '4px',
                                  right: '4px',
                                  color: 'red',
                                  backgroundColor: 'white',
                                  '&:hover': {
                                    backgroundColor: 'white',
                                  },
                                }}
                                onClick={() => handleDeleteUploadedImage(img.id)}
                              >
                                <DeleteIcon fontSize="small" />
                              </IconButton>
                              {/* 区切りを挿入するボタン */}
                              <IconButton
                                size="small"
                                sx={{
                                  position: 'absolute',
                                  bottom: '4px',
                                  right: '4px',
                                  backgroundColor: 'white',
                                  '&:hover': {
                                    backgroundColor: 'white',
                                  },
                                }}
                                onClick={() => addSeparator(index)}
                              >
                                <AddIcon fontSize="small" />
                              </IconButton>
                            </>
                          )}
                        </Box>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </Box>
              )}
            </Droppable>
          </DragDropContext>

          {/* ページネーションコントロール */}
          <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
            <Pagination
              count={Math.ceil(uploadedImages.length / imagesPerPage)}
              page={currentPage}
              onChange={(event, value) => setCurrentPage(value)}
              color="primary"
            />
          </Box>

          <Grid container spacing={2} sx={{ mt: 2 }}>
            <Grid item xs={12} sm={6} md={4}>
              <Button
                variant="contained"
                color="secondary"
                onClick={handleGroupImages}
                disabled={isGrouping}
              >
                画像を商品に割り当てる
              </Button>
            </Grid>
          </Grid>
        </>
      )}

      {isGrouping && (
        <Box sx={{ mt: 2 }}>
          <Typography variant="body2">商品情報を更新しています...</Typography>
          <LinearProgress />
        </Box>
      )}
    </Box>
  );
};

export default ImageUploader;






// // ImageUploader.js

// import React, { useState } from 'react';
// import {
//   Box,
//   Button,
//   Typography,
//   Dialog,
//   DialogTitle,
//   DialogContent,
//   DialogActions,
//   LinearProgress,
//   Grid,
//   IconButton,
//   CircularProgress,
//   Pagination,
// } from '@mui/material';
// import { v4 as uuidv4 } from 'uuid';
// import DeleteIcon from '@mui/icons-material/Delete';
// import AddIcon from '@mui/icons-material/Add';
// import { DragDropContext, Droppable, Draggable } from '@hello-pangea/dnd';
// import { getStorage, ref as storageRef, uploadBytes, getDownloadURL } from 'firebase/storage';
// import { doc, getDoc, updateDoc, setDoc } from 'firebase/firestore'; // 追加
// import imageCompression from 'browser-image-compression';
// import { db } from './firebaseConfig';
// import LazyLoad from 'react-lazyload';

// const MAX_CONCURRENT_UPLOADS = 10; // 一度にアップロードする最大の画像数

// const ImageUploader = ({
//   user,
//   showSnackbar,
//   defaultValues,
//   columns,
//   setColumns,
//   data,
//   setData,
//   startRow,
//   setStartRow,
//   endRow,
//   setEndRow,
// }) => {
//   // 画像関連のステート
//   const [uploadedImages, setUploadedImages] = useState([]);
//   const [isUploading, setIsUploading] = useState(false);
//   const [groupedImages, setGroupedImages] = useState([]);
//   const [isGrouping, setIsGrouping] = useState(false);

//   const [isImageUploadDialogOpen, setIsImageUploadDialogOpen] = useState(false);
//   const [imageFiles, setImageFiles] = useState([]);

//   const [uploadProgress, setUploadProgress] = useState(0);
//   const [compressionProgress, setCompressionProgress] = useState(0);
//   const [isCompressing, setIsCompressing] = useState(false);

//   // アップロードモードを管理するステート
//   const [uploadMode, setUploadMode] = useState('new'); // 'new' または 'add'

//   // ページネーション用のステート
//   const [currentPage, setCurrentPage] = useState(1);
//   const imagesPerPage = 30; // 1ページあたりの画像数

//   // ページネーション用のインデックス計算
//   const indexOfLastImage = currentPage * imagesPerPage;
//   const indexOfFirstImage = indexOfLastImage - imagesPerPage;
//   const currentImages = uploadedImages.slice(indexOfFirstImage, indexOfLastImage);

//   // 最新のアップロード画像を取得
//   const fetchLatestUploadedImages = async () => {
//     if (!user) {
//       alert('ユーザーが認証されていません。再度ログインしてください。');
//       return;
//     }

//     setIsUploading(true);

//     try {
//       const userDocRef = doc(db, 'users', user.uid);
//       const userDocSnap = await getDoc(userDocRef);

//       if (userDocSnap.exists()) {
//         const data = userDocSnap.data();
//         const images = data.uploadedImages || [];
//         setUploadedImages(images);
//         showSnackbar('最新の画像を取得しました。', 'success');
//       } else {
//         showSnackbar('アップロードされた画像が見つかりません。', 'warning');
//       }
//     } catch (error) {
//       console.error('画像の取得に失敗しました:', error);
//       alert('画像の取得に失敗しました。再度お試しください。');
//     } finally {
//       setIsUploading(false);
//     }
//   };

//   // 新規出品を開始
//   const handleStartNewListing = () => {
//     setUploadMode('new');
//     setUploadedImages([]); // 既存の画像をクリア
//     setIsImageUploadDialogOpen(true);
//   };

//   // 追加画像をアップロード
//   const handleAddImages = () => {
//     setUploadMode('add');
//     setIsImageUploadDialogOpen(true);
//   };

//   // 画像ファイルの選択
//   const handleImageFileChange = (e) => {
//     if (e.target.files && e.target.files.length > 0) {
//       const files = Array.from(e.target.files);
//       setImageFiles(files);
//     }
//   };

//   // 画像をFirebase Storageにアップロード
//   const handleImageUpload = async () => {
//     if (!imageFiles || imageFiles.length === 0) {
//       alert('画像ファイルを選択してください。');
//       return;
//     }

//     // ユーザーが認証されているか確認
//     if (!user) {
//       alert('ユーザーが認証されていません。再度ログインしてください。');
//       return;
//     }

//     setIsCompressing(true);
//     setCompressionProgress(0);

//     try {
//       // 画像の圧縮処理
//       const compressedFiles = [];
//       for (let i = 0; i < imageFiles.length; i++) {
//         const file = imageFiles[i];
//         try {
//           const options = {
//             maxSizeMB: 0.5, // 最大ファイルサイズを0.5MBに設定
//             maxWidthOrHeight: 1600, // 最大の横幅または高さを1600pxに設定
//             useWebWorker: true,
//           };
//           const compressedFile = await imageCompression(file, options);
//           compressedFiles.push(compressedFile);
//         } catch (error) {
//           console.error('画像の圧縮に失敗しました:', error);
//           compressedFiles.push(file); // 圧縮に失敗した場合は元のファイルを使用
//         }
//         // 圧縮進捗を更新
//         setCompressionProgress(((i + 1) / imageFiles.length) * 100);
//       }

//       setIsCompressing(false);
//       setIsUploading(true);
//       setUploadProgress(0);

//       const storage = getStorage();
//       const uploadedUrls = [];
//       let uploadQueue = [...compressedFiles];

//       while (uploadQueue.length > 0) {
//         const currentBatch = uploadQueue.slice(0, MAX_CONCURRENT_UPLOADS);
//         uploadQueue = uploadQueue.slice(MAX_CONCURRENT_UPLOADS);

//         const uploadPromises = currentBatch.map(async (file) => {
//           try {
//             const fileExtension = file.name.split('.').pop();
//             const storageReference = storageRef(
//               storage,
//               `users/${user.uid}/images/${uuidv4()}.${fileExtension}`
//             );
//             const snapshot = await uploadBytes(storageReference, file);
//             const downloadURL = await getDownloadURL(snapshot.ref);
//             return {
//               id: uuidv4(),
//               url: downloadURL,
//             };
//           } catch (error) {
//             console.error('画像のアップロードに失敗しました:', error);
//             throw error;
//           }
//         });

//         const batchResults = await Promise.all(uploadPromises);
//         uploadedUrls.push(...batchResults);

//         // アップロード進捗を更新
//         setUploadProgress((prevProgress) => prevProgress + batchResults.length);
//       }

//       // 新規出品の場合は既存の画像をリセット
//       let newUploadedImages;
//       if (uploadMode === 'new') {
//         newUploadedImages = [...uploadedUrls];
//         setUploadedImages(newUploadedImages);
//       } else {
//         // 追加アップロードの場合は既存の画像に追加
//         newUploadedImages = [...uploadedImages, ...uploadedUrls];
//         setUploadedImages(newUploadedImages);
//       }

//       // Firestore に保存
//       const userDocRef = doc(db, 'users', user.uid);
//       await setDoc(userDocRef, { uploadedImages: newUploadedImages }, { merge: true });

//       setIsImageUploadDialogOpen(false);
//       setImageFiles([]);
//       setUploadProgress(0);
//     } catch (error) {
//       console.error('画像のアップロードに失敗しました:', error);
//       alert('画像のアップロードに失敗しました。再度お試しください。');
//     } finally {
//       setIsCompressing(false);
//       setIsUploading(false);
//     }
//   };

//   // 画像の削除
//   const handleDeleteUploadedImage = async (id) => {
//     // ローカルの状態を更新
//     const newUploadedImages = uploadedImages.filter((img) => img.id !== id);
//     setUploadedImages(newUploadedImages);

//     // Firestore を更新
//     try {
//       const userDocRef = doc(db, 'users', user.uid);
//       await updateDoc(userDocRef, { uploadedImages: newUploadedImages });
//     } catch (error) {
//       console.error('画像の削除時にエラーが発生しました:', error);
//       alert('画像の削除に失敗しました。再度お試しください。');
//     }
//   };

//   // 区切りを追加
//   const addSeparator = async (index) => {
//     const globalIndex = index + indexOfFirstImage;
//     const newUploadedImages = [...uploadedImages];
//     const separator = { id: uuidv4(), type: 'separator' };
//     newUploadedImages.splice(globalIndex + 1, 0, separator);
//     setUploadedImages(newUploadedImages);

//     // Firestore を更新
//     try {
//       const userDocRef = doc(db, 'users', user.uid);
//       await updateDoc(userDocRef, { uploadedImages: newUploadedImages });
//     } catch (error) {
//       console.error('区切りの追加時にエラーが発生しました:', error);
//       alert('区切りの追加に失敗しました。再度お試しください。');
//     }
//   };

//   // 区切りを削除
//   const removeSeparator = async (id) => {
//     const newUploadedImages = uploadedImages.filter((img) => img.id !== id);
//     setUploadedImages(newUploadedImages);

//     // Firestore を更新
//     try {
//       const userDocRef = doc(db, 'users', user.uid);
//       await updateDoc(userDocRef, { uploadedImages: newUploadedImages });
//     } catch (error) {
//       console.error('区切りの削除時にエラーが発生しました:', error);
//       alert('区切りの削除に失敗しました。再度お試しください。');
//     }
//   };

//   // 画像をグループ化して商品を作成
//   const handleGroupImages = () => {
//     setIsGrouping(true);

//     // 画像を区切りごとにグループ化する処理
//     const products = [];
//     let currentProductImages = [];

//     for (const img of uploadedImages) {
//       if (img.type === 'separator') {
//         if (currentProductImages.length > 0) {
//           products.push([...currentProductImages]);
//           currentProductImages = [];
//         }
//       } else {
//         currentProductImages.push(img);
//       }
//     }

//     if (currentProductImages.length > 0) {
//       products.push([...currentProductImages]);
//     }

//     setGroupedImages(products);
//     setIsGrouping(false);

//     // データを更新
//     if (columns.length > 0) {
//       const newData = products.map((productImages) => ({
//         // 各カラムにデフォルト値を設定
//         ...columns.reduce((obj, col) => {
//           if (col === 'PicURL') {
//             // PicURLカラムには画像URLを設定
//             obj[col] = productImages.map((img) => img.url).join('|');
//           } else if (defaultValues.hasOwnProperty(col)) {
//             // デフォルト値が指定されているカラム
//             obj[col] = defaultValues[col];
//           } else {
//             // それ以外のカラムには 'NA' を設定
//             obj[col] = 'NA';
//           }
//           return obj;
//         }, {}),
//       }));
//       setData(newData);

//       // startRowとendRowを更新
//       setStartRow(1);
//       setEndRow(newData.length);
//     }
//   };

//   // 画像のドラッグ＆ドロップによる並び替え
//   const onDragEnd = async (result) => {
//     if (!result.destination) {
//       return;
//     }

//     const startIndex = indexOfFirstImage;
//     const endIndex = indexOfLastImage;

//     const currentImagesCopy = Array.from(currentImages);
//     const [removed] = currentImagesCopy.splice(result.source.index, 1);
//     currentImagesCopy.splice(result.destination.index, 0, removed);

//     // 全体のuploadedImagesを更新
//     const newUploadedImages = Array.from(uploadedImages);
//     newUploadedImages.splice(startIndex, currentImagesCopy.length, ...currentImagesCopy);

//     setUploadedImages(newUploadedImages);

//     // Firestore を更新
//     try {
//       const userDocRef = doc(db, 'users', user.uid);
//       await updateDoc(userDocRef, { uploadedImages: newUploadedImages });
//     } catch (error) {
//       console.error('画像の並び替え時にエラーが発生しました:', error);
//       alert('画像の並び替えに失敗しました。再度お試しください。');
//     }
//   };

//   return (
//     <Box>
//       <Grid container spacing={2}>
//         <Grid item xs={12} sm={6} md={4}>
//           <Button variant="contained" color="primary" onClick={handleStartNewListing}>
//             新規出品をする
//           </Button>
//         </Grid>
//         {uploadedImages.length > 0 && (
//           <Grid item xs={12} sm={6} md={4}>
//             <Button variant="contained" color="primary" onClick={handleAddImages}>
//               追加画像をアップロード
//             </Button>
//           </Grid>
//         )}
//         <Grid item xs={12} sm={6} md={4}>
//           <Button variant="contained" color="primary" onClick={fetchLatestUploadedImages}>
//             最新のアップロード画像を取得
//           </Button>
//         </Grid>
//       </Grid>

//       <Dialog open={isImageUploadDialogOpen} onClose={() => setIsImageUploadDialogOpen(false)}>
//         <DialogTitle>
//           {uploadMode === 'new' ? '新規出品の画像をアップロード' : '追加画像をアップロード'}
//         </DialogTitle>
//         <DialogContent>
//           <input type="file" accept="image/*" multiple onChange={handleImageFileChange} />
//           {imageFiles.length > 0 && (
//             <Typography variant="body2" sx={{ mt: 2 }}>
//               選択された画像: {imageFiles.length} 枚
//             </Typography>
//           )}
//           {(isCompressing || isUploading) && (
//             <Box sx={{ mt: 2 }}>
//               {isCompressing && (
//                 <>
//                   <Typography variant="body2">画像を圧縮しています...</Typography>
//                   <LinearProgress variant="determinate" value={compressionProgress} />
//                 </>
//               )}
//               {isUploading && (
//                 <>
//                   <Typography variant="body2">
//                     画像をアップロードしています... ({uploadProgress} / {imageFiles.length})
//                   </Typography>
//                   <LinearProgress
//                     variant="determinate"
//                     value={(uploadProgress / imageFiles.length) * 100}
//                   />
//                 </>
//               )}
//             </Box>
//           )}
//         </DialogContent>
//         <DialogActions>
//           <Button
//             onClick={() => {
//               setIsImageUploadDialogOpen(false);
//               setImageFiles([]);
//             }}
//             disabled={isCompressing || isUploading}
//           >
//             キャンセル
//           </Button>
//           <Button
//             onClick={handleImageUpload}
//             disabled={isCompressing || isUploading || imageFiles.length === 0}
//           >
//             アップロード
//           </Button>
//         </DialogActions>
//       </Dialog>

//       {uploadedImages.length > 0 && (
//         <>
//           <Typography variant="h6" sx={{ mt: 4 }}>
//             アップロードされた画像
//           </Typography>
//           <Typography variant="body2" sx={{ mb: 2 }}>
//             画像をドラッグ＆ドロップで並び替え、区切りを挿入して商品を分けることができます。
//           </Typography>
//           <DragDropContext onDragEnd={onDragEnd}>
//             <Droppable droppableId="uploadedImages" direction="horizontal">
//               {(provided) => (
//                 <Box
//                   sx={{
//                     display: 'flex',
//                     flexWrap: 'wrap',
//                     p: 1,
//                     border: '1px solid #ccc',
//                     borderRadius: '4px',
//                   }}
//                   {...provided.droppableProps}
//                   ref={provided.innerRef}
//                 >
//                   {currentImages.map((img, index) => (
//                     <Draggable key={img.id} draggableId={img.id} index={index}>
//                       {(provided) => (
//                         <Box
//                           sx={{
//                             position: 'relative',
//                             m: 0.5,
//                             width: '160px',
//                             height: '160px',
//                             display: 'flex',
//                             alignItems: 'center',
//                             justifyContent: 'center',
//                             backgroundColor: img.type === 'separator' ? '#f0f0f0' : 'transparent',
//                             border: img.type === 'separator' ? '2px dashed #ccc' : 'none',
//                           }}
//                           ref={provided.innerRef}
//                           {...provided.draggableProps}
//                           {...provided.dragHandleProps}
//                         >
//                           {img.type === 'separator' ? (
//                             <>
//                               <Typography variant="body2">区切り</Typography>
//                               <IconButton
//                                 size="small"
//                                 sx={{
//                                   position: 'absolute',
//                                   top: '4px',
//                                   right: '4px',
//                                   color: 'red',
//                                   backgroundColor: 'white',
//                                   '&:hover': {
//                                     backgroundColor: 'white',
//                                   },
//                                 }}
//                                 onClick={() => removeSeparator(img.id)}
//                               >
//                                 <DeleteIcon fontSize="small" />
//                               </IconButton>
//                             </>
//                           ) : (
//                             <>
//                               <LazyLoad height={120} offset={100}>
//                                 <img
//                                   src={img.url}
//                                   alt={`Uploaded ${index}`}
//                                   style={{ width: '100%', height: '100%', objectFit: 'cover' }}
//                                 />
//                               </LazyLoad>
//                               <IconButton
//                                 size="small"
//                                 sx={{
//                                   position: 'absolute',
//                                   top: '4px',
//                                   right: '4px',
//                                   color: 'red',
//                                   backgroundColor: 'white',
//                                   '&:hover': {
//                                     backgroundColor: 'white',
//                                   },
//                                 }}
//                                 onClick={() => handleDeleteUploadedImage(img.id)}
//                               >
//                                 <DeleteIcon fontSize="small" />
//                               </IconButton>
//                               {/* 区切りを挿入するボタン */}
//                               {img.type !== 'separator' && (
//                                 <IconButton
//                                   size="small"
//                                   sx={{
//                                     position: 'absolute',
//                                     bottom: '4px',
//                                     right: '4px',
//                                     backgroundColor: 'white',
//                                     '&:hover': {
//                                       backgroundColor: 'white',
//                                     },
//                                   }}
//                                   onClick={() => addSeparator(index)}
//                                 >
//                                   <AddIcon fontSize="small" />
//                                 </IconButton>
//                               )}
//                             </>
//                           )}
//                         </Box>
//                       )}
//                     </Draggable>
//                   ))}
//                   {provided.placeholder}
//                 </Box>
//               )}
//             </Droppable>
//           </DragDropContext>

//           {/* ページネーションコントロール */}
//           <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
//             <Pagination
//               count={Math.ceil(uploadedImages.length / imagesPerPage)}
//               page={currentPage}
//               onChange={(event, value) => setCurrentPage(value)}
//               color="primary"
//             />
//           </Box>

//           <Grid container spacing={2} sx={{ mt: 2 }}>
//             <Grid item xs={12} sm={6} md={4}>
//               <Button
//                 variant="contained"
//                 color="secondary"
//                 onClick={handleGroupImages}
//                 disabled={isGrouping}
//               >
//                 画像を商品に割り当てる
//               </Button>
//             </Grid>
//           </Grid>
//         </>
//       )}

//       {isGrouping && (
//         <Box sx={{ mt: 2 }}>
//           <Typography variant="body2">商品情報を更新しています...</Typography>
//           <LinearProgress />
//         </Box>
//       )}
//     </Box>
//   );
// };

// export default ImageUploader;





// // ImageUploader.js

// import React, { useState } from 'react';
// import {
//   Box,
//   Button,
//   Typography,
//   Dialog,
//   DialogTitle,
//   DialogContent,
//   DialogActions,
//   LinearProgress,
//   Grid,
//   IconButton,
//   CircularProgress,
//   Pagination,
// } from '@mui/material';
// import { v4 as uuidv4 } from 'uuid';
// import DeleteIcon from '@mui/icons-material/Delete';
// import AddIcon from '@mui/icons-material/Add';
// import { DragDropContext, Droppable, Draggable } from '@hello-pangea/dnd';
// import { getStorage, ref as storageRef, uploadBytes, getDownloadURL } from 'firebase/storage';
// import { doc, getDoc } from 'firebase/firestore';
// import imageCompression from 'browser-image-compression';
// import { db } from './firebaseConfig';
// import LazyLoad from 'react-lazyload';

// const MAX_CONCURRENT_UPLOADS = 10; // 一度にアップロードする最大の画像数

// const ImageUploader = ({
//   user,
//   showSnackbar,
//   defaultValues,
//   columns,
//   setColumns,
//   data,
//   setData,
//   startRow,
//   setStartRow,
//   endRow,
//   setEndRow,
// }) => {
//   // 画像関連のステート
//   const [uploadedImages, setUploadedImages] = useState([]);
//   const [isUploading, setIsUploading] = useState(false);
//   const [groupedImages, setGroupedImages] = useState([]);
//   const [isGrouping, setIsGrouping] = useState(false);

//   const [isImageUploadDialogOpen, setIsImageUploadDialogOpen] = useState(false);
//   const [imageFiles, setImageFiles] = useState([]);

//   const [uploadProgress, setUploadProgress] = useState(0);
//   const [compressionProgress, setCompressionProgress] = useState(0);
//   const [isCompressing, setIsCompressing] = useState(false);

//   // アップロードモードを管理するステート
//   const [uploadMode, setUploadMode] = useState('new'); // 'new' または 'add'

//   // ページネーション用のステート
//   const [currentPage, setCurrentPage] = useState(1);
//   const imagesPerPage = 30; // 1ページあたりの画像数

//   // ページネーション用のインデックス計算
//   const indexOfLastImage = currentPage * imagesPerPage;
//   const indexOfFirstImage = indexOfLastImage - imagesPerPage;
//   const currentImages = uploadedImages.slice(indexOfFirstImage, indexOfLastImage);

//   // 最新のアップロード画像を取得
//   const fetchLatestUploadedImages = async () => {
//     if (!user) {
//       alert('ユーザーが認証されていません。再度ログインしてください。');
//       return;
//     }

//     setIsUploading(true);

//     try {
//       const userDocRef = doc(db, 'users', user.uid);
//       const userDocSnap = await getDoc(userDocRef);

//       if (userDocSnap.exists()) {
//         const data = userDocSnap.data();
//         const images = data.uploadedImages || [];
//         setUploadedImages(images);
//         showSnackbar('最新の画像を取得しました。', 'success');
//       } else {
//         showSnackbar('アップロードされた画像が見つかりません。', 'warning');
//       }
//     } catch (error) {
//       console.error('画像の取得に失敗しました:', error);
//       alert('画像の取得に失敗しました。再度お試しください。');
//     } finally {
//       setIsUploading(false);
//     }
//   };

//   // 新規出品を開始
//   const handleStartNewListing = () => {
//     setUploadMode('new');
//     setUploadedImages([]); // 既存の画像をクリア
//     setIsImageUploadDialogOpen(true);
//   };

//   // 追加画像をアップロード
//   const handleAddImages = () => {
//     setUploadMode('add');
//     setIsImageUploadDialogOpen(true);
//   };

//   // 画像ファイルの選択
//   const handleImageFileChange = (e) => {
//     if (e.target.files && e.target.files.length > 0) {
//       const files = Array.from(e.target.files);
//       setImageFiles(files);
//     }
//   };

//   // 画像をFirebase Storageにアップロード
//   const handleImageUpload = async () => {
//     if (!imageFiles || imageFiles.length === 0) {
//       alert('画像ファイルを選択してください。');
//       return;
//     }

//     // ユーザーが認証されているか確認
//     if (!user) {
//       alert('ユーザーが認証されていません。再度ログインしてください。');
//       return;
//     }

//     setIsCompressing(true);
//     setCompressionProgress(0);

//     try {
//       // 画像の圧縮処理
//       const compressedFiles = [];
//       for (let i = 0; i < imageFiles.length; i++) {
//         const file = imageFiles[i];
//         try {
//           const options = {
//             maxSizeMB: 0.5, // 最大ファイルサイズを0.5MBに設定
//             maxWidthOrHeight: 1600, // 最大の横幅または高さを1600pxに設定
//             useWebWorker: true,
//           };
//           const compressedFile = await imageCompression(file, options);
//           compressedFiles.push(compressedFile);
//         } catch (error) {
//           console.error('画像の圧縮に失敗しました:', error);
//           compressedFiles.push(file); // 圧縮に失敗した場合は元のファイルを使用
//         }
//         // 圧縮進捗を更新
//         setCompressionProgress(((i + 1) / imageFiles.length) * 100);
//       }

//       setIsCompressing(false);
//       setIsUploading(true);
//       setUploadProgress(0);

//       const storage = getStorage();
//       const uploadedUrls = [];
//       let uploadQueue = [...compressedFiles];

//       while (uploadQueue.length > 0) {
//         const currentBatch = uploadQueue.slice(0, MAX_CONCURRENT_UPLOADS);
//         uploadQueue = uploadQueue.slice(MAX_CONCURRENT_UPLOADS);

//         const uploadPromises = currentBatch.map(async (file) => {
//           try {
//             const fileExtension = file.name.split('.').pop();
//             const storageReference = storageRef(
//               storage,
//               `users/${user.uid}/images/${uuidv4()}.${fileExtension}`
//             );
//             const snapshot = await uploadBytes(storageReference, file);
//             const downloadURL = await getDownloadURL(snapshot.ref);
//             return {
//               id: uuidv4(),
//               url: downloadURL,
//             };
//           } catch (error) {
//             console.error('画像のアップロードに失敗しました:', error);
//             throw error;
//           }
//         });

//         const batchResults = await Promise.all(uploadPromises);
//         uploadedUrls.push(...batchResults);

//         // アップロード進捗を更新
//         setUploadProgress((prevProgress) => prevProgress + batchResults.length);
//       }

//       // 新規出品の場合は既存の画像をリセット
//       if (uploadMode === 'new') {
//         setUploadedImages([...uploadedUrls]);
//       } else {
//         // 追加アップロードの場合は既存の画像に追加
//         setUploadedImages((prev) => [...prev, ...uploadedUrls]);
//       }

//       setIsImageUploadDialogOpen(false);
//       setImageFiles([]);
//       setUploadProgress(0);
//     } catch (error) {
//       console.error('画像のアップロードに失敗しました:', error);
//       alert('画像のアップロードに失敗しました。再度お試しください。');
//     } finally {
//       setIsCompressing(false);
//       setIsUploading(false);
//     }
//   };

//   // 画像の削除
//   const handleDeleteUploadedImage = (id) => {
//     // ローカルの状態を更新
//     setUploadedImages((prev) => prev.filter((img) => img.id !== id));
//   };

//   // 区切りを追加
//   const addSeparator = (index) => {
//     const globalIndex = index + indexOfFirstImage;
//     const newUploadedImages = [...uploadedImages];
//     const separator = { id: uuidv4(), type: 'separator' };
//     newUploadedImages.splice(globalIndex + 1, 0, separator);
//     setUploadedImages(newUploadedImages);
//   };

//   // 区切りを削除
//   const removeSeparator = (id) => {
//     const newUploadedImages = uploadedImages.filter((img) => img.id !== id);
//     setUploadedImages(newUploadedImages);
//   };

//   // 画像をグループ化して商品を作成
//   const handleGroupImages = () => {
//     setIsGrouping(true);

//     // 画像を区切りごとにグループ化する処理
//     const products = [];
//     let currentProductImages = [];

//     for (const img of uploadedImages) {
//       if (img.type === 'separator') {
//         if (currentProductImages.length > 0) {
//           products.push([...currentProductImages]);
//           currentProductImages = [];
//         }
//       } else {
//         currentProductImages.push(img);
//       }
//     }

//     if (currentProductImages.length > 0) {
//       products.push([...currentProductImages]);
//     }

//     setGroupedImages(products);
//     setIsGrouping(false);

//     // データを更新
//     if (columns.length > 0) {
//       const newData = products.map((productImages) => ({
//         // 各カラムにデフォルト値を設定
//         ...columns.reduce((obj, col) => {
//           if (col === 'PicURL') {
//             // PicURLカラムには画像URLを設定
//             obj[col] = productImages.map((img) => img.url).join('|');
//           } else if (defaultValues.hasOwnProperty(col)) {
//             // デフォルト値が指定されているカラム
//             obj[col] = defaultValues[col];
//           } else {
//             // それ以外のカラムには 'NA' を設定
//             obj[col] = 'NA';
//           }
//           return obj;
//         }, {}),
//       }));
//       setData(newData);

//       // startRowとendRowを更新
//       setStartRow(1);
//       setEndRow(newData.length);
//     }
//   };

//   // 画像のドラッグ＆ドロップによる並び替え
//   const onDragEnd = (result) => {
//     if (!result.destination) {
//       return;
//     }

//     const startIndex = indexOfFirstImage;
//     const endIndex = indexOfLastImage;

//     const currentImagesCopy = Array.from(currentImages);
//     const [removed] = currentImagesCopy.splice(result.source.index, 1);
//     currentImagesCopy.splice(result.destination.index, 0, removed);

//     // 全体のuploadedImagesを更新
//     const newUploadedImages = Array.from(uploadedImages);
//     newUploadedImages.splice(startIndex, currentImagesCopy.length, ...currentImagesCopy);

//     setUploadedImages(newUploadedImages);
//   };

//   return (
//     <Box>
//       <Grid container spacing={2}>
//         <Grid item xs={12} sm={6} md={4}>
//           <Button variant="contained" color="primary" onClick={handleStartNewListing}>
//             新規出品をする
//           </Button>
//         </Grid>
//         {uploadedImages.length > 0 && (
//           <Grid item xs={12} sm={6} md={4}>
//             <Button variant="contained" color="primary" onClick={handleAddImages}>
//               追加画像をアップロード
//             </Button>
//           </Grid>
//         )}
//         <Grid item xs={12} sm={6} md={4}>
//           <Button variant="contained" color="primary" onClick={fetchLatestUploadedImages}>
//             最新のアップロード画像を取得
//           </Button>
//         </Grid>
//       </Grid>

//       <Dialog open={isImageUploadDialogOpen} onClose={() => setIsImageUploadDialogOpen(false)}>
//         <DialogTitle>
//           {uploadMode === 'new' ? '新規出品の画像をアップロード' : '追加画像をアップロード'}
//         </DialogTitle>
//         <DialogContent>
//           <input type="file" accept="image/*" multiple onChange={handleImageFileChange} />
//           {imageFiles.length > 0 && (
//             <Typography variant="body2" sx={{ mt: 2 }}>
//               選択された画像: {imageFiles.length} 枚
//             </Typography>
//           )}
//           {(isCompressing || isUploading) && (
//             <Box sx={{ mt: 2 }}>
//               {isCompressing && (
//                 <>
//                   <Typography variant="body2">画像を圧縮しています...</Typography>
//                   <LinearProgress variant="determinate" value={compressionProgress} />
//                 </>
//               )}
//               {isUploading && (
//                 <>
//                   <Typography variant="body2">
//                     画像をアップロードしています... ({uploadProgress} / {imageFiles.length})
//                   </Typography>
//                   <LinearProgress
//                     variant="determinate"
//                     value={(uploadProgress / imageFiles.length) * 100}
//                   />
//                 </>
//               )}
//             </Box>
//           )}
//         </DialogContent>
//         <DialogActions>
//           <Button
//             onClick={() => {
//               setIsImageUploadDialogOpen(false);
//               setImageFiles([]);
//             }}
//             disabled={isCompressing || isUploading}
//           >
//             キャンセル
//           </Button>
//           <Button
//             onClick={handleImageUpload}
//             disabled={isCompressing || isUploading || imageFiles.length === 0}
//           >
//             アップロード
//           </Button>
//         </DialogActions>
//       </Dialog>

//       {uploadedImages.length > 0 && (
//         <>
//           <Typography variant="h6" sx={{ mt: 4 }}>
//             アップロードされた画像
//           </Typography>
//           <Typography variant="body2" sx={{ mb: 2 }}>
//             画像をドラッグ＆ドロップで並び替え、区切りを挿入して商品を分けることができます。
//           </Typography>
//           <DragDropContext onDragEnd={onDragEnd}>
//             <Droppable droppableId="uploadedImages" direction="horizontal">
//               {(provided) => (
//                 <Box
//                   sx={{
//                     display: 'flex',
//                     flexWrap: 'wrap',
//                     p: 1,
//                     border: '1px solid #ccc',
//                     borderRadius: '4px',
//                   }}
//                   {...provided.droppableProps}
//                   ref={provided.innerRef}
//                 >
//                   {currentImages.map((img, index) => (
//                     <Draggable key={img.id} draggableId={img.id} index={index}>
//                       {(provided) => (
//                         <Box
//                           sx={{
//                             position: 'relative',
//                             m: 0.5,
//                             width: '160px',
//                             height: '160px',
//                             display: 'flex',
//                             alignItems: 'center',
//                             justifyContent: 'center',
//                             backgroundColor: img.type === 'separator' ? '#f0f0f0' : 'transparent',
//                             border: img.type === 'separator' ? '2px dashed #ccc' : 'none',
//                           }}
//                           ref={provided.innerRef}
//                           {...provided.draggableProps}
//                           {...provided.dragHandleProps}
//                         >
//                           {img.type === 'separator' ? (
//                             <>
//                               <Typography variant="body2">区切り</Typography>
//                               <IconButton
//                                 size="small"
//                                 sx={{
//                                   position: 'absolute',
//                                   top: '4px',
//                                   right: '4px',
//                                   color: 'red',
//                                   backgroundColor: 'white',
//                                   '&:hover': {
//                                     backgroundColor: 'white',
//                                   },
//                                 }}
//                                 onClick={() => removeSeparator(img.id)}
//                               >
//                                 <DeleteIcon fontSize="small" />
//                               </IconButton>
//                             </>
//                           ) : (
//                             <>
//                               <LazyLoad height={120} offset={100}>
//                                 <img
//                                   src={img.url}
//                                   alt={`Uploaded ${index}`}
//                                   style={{ width: '100%', height: '100%', objectFit: 'cover' }}
//                                 />
//                               </LazyLoad>
//                               <IconButton
//                                 size="small"
//                                 sx={{
//                                   position: 'absolute',
//                                   top: '4px',
//                                   right: '4px',
//                                   color: 'red',
//                                   backgroundColor: 'white',
//                                   '&:hover': {
//                                     backgroundColor: 'white',
//                                   },
//                                 }}
//                                 onClick={() => handleDeleteUploadedImage(img.id)}
//                               >
//                                 <DeleteIcon fontSize="small" />
//                               </IconButton>
//                               {/* 区切りを挿入するボタン */}
//                               {img.type !== 'separator' && (
//                                 <IconButton
//                                   size="small"
//                                   sx={{
//                                     position: 'absolute',
//                                     bottom: '4px',
//                                     right: '4px',
//                                     backgroundColor: 'white',
//                                     '&:hover': {
//                                       backgroundColor: 'white',
//                                     },
//                                   }}
//                                   onClick={() => addSeparator(index)}
//                                 >
//                                   <AddIcon fontSize="small" />
//                                 </IconButton>
//                               )}
//                             </>
//                           )}
//                         </Box>
//                       )}
//                     </Draggable>
//                   ))}
//                   {provided.placeholder}
//                 </Box>
//               )}
//             </Droppable>
//           </DragDropContext>

//           {/* ページネーションコントロール */}
//           <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
//             <Pagination
//               count={Math.ceil(uploadedImages.length / imagesPerPage)}
//               page={currentPage}
//               onChange={(event, value) => setCurrentPage(value)}
//               color="primary"
//             />
//           </Box>

//           <Grid container spacing={2} sx={{ mt: 2 }}>
//             <Grid item xs={12} sm={6} md={4}>
//               <Button
//                 variant="contained"
//                 color="secondary"
//                 onClick={handleGroupImages}
//                 disabled={isGrouping}
//               >
//                 画像を商品に割り当てる
//               </Button>
//             </Grid>
//           </Grid>
//         </>
//       )}

//       {isGrouping && (
//         <Box sx={{ mt: 2 }}>
//           <Typography variant="body2">商品情報を更新しています...</Typography>
//           <LinearProgress />
//         </Box>
//       )}
//     </Box>
//   );
// };

// export default ImageUploader;





// // ImageUploader.js

// import React, { useState } from 'react';
// import {
//   Box,
//   Button,
//   Typography,
//   Dialog,
//   DialogTitle,
//   DialogContent,
//   DialogActions,
//   LinearProgress,
//   Grid,
//   IconButton,
//   CircularProgress,
// } from '@mui/material';
// import { v4 as uuidv4 } from 'uuid';
// import DeleteIcon from '@mui/icons-material/Delete';
// import AddIcon from '@mui/icons-material/Add';
// import { DragDropContext, Droppable, Draggable } from '@hello-pangea/dnd';
// import { getStorage, ref as storageRef, uploadBytes, getDownloadURL } from 'firebase/storage';
// import { doc, getDoc } from 'firebase/firestore';
// import imageCompression from 'browser-image-compression';
// import { db } from './firebaseConfig';

// const MAX_CONCURRENT_UPLOADS = 10; // 一度にアップロードする最大の画像数

// const ImageUploader = ({
//   user,
//   showSnackbar,
//   defaultValues,
//   columns,
//   setColumns,
//   data,
//   setData,
//   startRow,
//   setStartRow,
//   endRow,
//   setEndRow,
// }) => {
//   // 画像関連のステート
//   const [uploadedImages, setUploadedImages] = useState([]);
//   const [isUploading, setIsUploading] = useState(false);
//   const [groupedImages, setGroupedImages] = useState([]);
//   const [isGrouping, setIsGrouping] = useState(false);

//   const [isImageUploadDialogOpen, setIsImageUploadDialogOpen] = useState(false);
//   const [imageFiles, setImageFiles] = useState([]);

//   const [uploadProgress, setUploadProgress] = useState(0);
//   const [compressionProgress, setCompressionProgress] = useState(0);
//   const [isCompressing, setIsCompressing] = useState(false);

//   // アップロードモードを管理するステート
//   const [uploadMode, setUploadMode] = useState('new'); // 'new' または 'add'

//   // 新規出品を開始
//   const handleStartNewListing = () => {
//     setUploadMode('new');
//     setUploadedImages([]); // 既存の画像をクリア
//     setIsImageUploadDialogOpen(true);
//   };

//   // 追加画像をアップロード
//   const handleAddImages = () => {
//     setUploadMode('add');
//     setIsImageUploadDialogOpen(true);
//   };

//   // 最新のアップロード画像を取得
//   const fetchLatestUploadedImages = async () => {
//     if (!user) {
//       alert('ユーザーが認証されていません。再度ログインしてください。');
//       return;
//     }

//     setIsUploading(true);

//     try {
//       const userDocRef = doc(db, 'users', user.uid);
//       const userDocSnap = await getDoc(userDocRef);

//       if (userDocSnap.exists()) {
//         const data = userDocSnap.data();
//         const images = data.uploadedImages || [];
//         setUploadedImages(images);
//         showSnackbar('最新の画像を取得しました。', 'success');
//       } else {
//         showSnackbar('アップロードされた画像が見つかりません。', 'warning');
//       }
//     } catch (error) {
//       console.error('画像の取得に失敗しました:', error);
//       alert('画像の取得に失敗しました。再度お試しください。');
//     } finally {
//       setIsUploading(false);
//     }
//   };

//   // 画像ファイルの選択
//   const handleImageFileChange = (e) => {
//     if (e.target.files && e.target.files.length > 0) {
//       const files = Array.from(e.target.files);
//       setImageFiles(files);
//     }
//   };

//   // 画像をFirebase Storageにアップロード
//   const handleImageUpload = async () => {
//     if (!imageFiles || imageFiles.length === 0) {
//       alert('画像ファイルを選択してください。');
//       return;
//     }

//     // ユーザーが認証されているか確認
//     if (!user) {
//       alert('ユーザーが認証されていません。再度ログインしてください。');
//       return;
//     }

//     setIsCompressing(true);
//     setCompressionProgress(0);

//     try {
//       // 画像の圧縮処理
//       const compressedFiles = [];
//       for (let i = 0; i < imageFiles.length; i++) {
//         const file = imageFiles[i];
//         try {
//           const options = {
//             maxSizeMB: 0.5, // 最大ファイルサイズを0.5MBに設定
//             maxWidthOrHeight: 1920, // 最大の横幅または高さを1920pxに設定
//             useWebWorker: true,
//           };
//           const compressedFile = await imageCompression(file, options);
//           compressedFiles.push(compressedFile);
//         } catch (error) {
//           console.error('画像の圧縮に失敗しました:', error);
//           compressedFiles.push(file); // 圧縮に失敗した場合は元のファイルを使用
//         }
//         // 圧縮進捗を更新
//         setCompressionProgress(((i + 1) / imageFiles.length) * 100);
//       }

//       setIsCompressing(false);
//       setIsUploading(true);
//       setUploadProgress(0);

//       const storage = getStorage();
//       const uploadedUrls = [];
//       let uploadQueue = [...compressedFiles];

//       while (uploadQueue.length > 0) {
//         const currentBatch = uploadQueue.slice(0, MAX_CONCURRENT_UPLOADS);
//         uploadQueue = uploadQueue.slice(MAX_CONCURRENT_UPLOADS);

//         const uploadPromises = currentBatch.map(async (file) => {
//           try {
//             const fileExtension = file.name.split('.').pop();
//             const storageReference = storageRef(
//               storage,
//               `users/${user.uid}/images/${uuidv4()}.${fileExtension}`
//             );
//             const snapshot = await uploadBytes(storageReference, file);
//             const downloadURL = await getDownloadURL(snapshot.ref);
//             return {
//               id: uuidv4(),
//               url: downloadURL,
//             };
//           } catch (error) {
//             console.error('画像のアップロードに失敗しました:', error);
//             throw error;
//           }
//         });

//         const batchResults = await Promise.all(uploadPromises);
//         uploadedUrls.push(...batchResults);

//         // アップロード進捗を更新
//         setUploadProgress((prevProgress) => prevProgress + batchResults.length);
//       }

//       // 新規出品の場合は既存の画像をリセット
//       if (uploadMode === 'new') {
//         setUploadedImages([...uploadedUrls]);
//       } else {
//         // 追加アップロードの場合は既存の画像に追加
//         setUploadedImages((prev) => [...prev, ...uploadedUrls]);
//       }

//       setIsImageUploadDialogOpen(false);
//       setImageFiles([]);
//       setUploadProgress(0);
//     } catch (error) {
//       console.error('画像のアップロードに失敗しました:', error);
//       alert('画像のアップロードに失敗しました。再度お試しください。');
//     } finally {
//       setIsCompressing(false);
//       setIsUploading(false);
//     }
//   };

//   // 画像の削除
//   const handleDeleteUploadedImage = (id) => {
//     // ローカルの状態を更新
//     setUploadedImages((prev) => prev.filter((img) => img.id !== id));
//   };

//   // 区切りを追加
//   const addSeparator = (index) => {
//     const newUploadedImages = [...uploadedImages];
//     const separator = { id: uuidv4(), type: 'separator' };
//     newUploadedImages.splice(index + 1, 0, separator);
//     setUploadedImages(newUploadedImages);
//   };

//   // 区切りを削除
//   const removeSeparator = (id) => {
//     const newUploadedImages = uploadedImages.filter((img) => img.id !== id);
//     setUploadedImages(newUploadedImages);
//   };

//   // 画像をグループ化して商品を作成
//   const handleGroupImages = () => {
//     setIsGrouping(true);

//     // 画像を区切りごとにグループ化する処理
//     const products = [];
//     let currentProductImages = [];

//     for (const img of uploadedImages) {
//       if (img.type === 'separator') {
//         if (currentProductImages.length > 0) {
//           products.push([...currentProductImages]);
//           currentProductImages = [];
//         }
//       } else {
//         currentProductImages.push(img);
//       }
//     }

//     if (currentProductImages.length > 0) {
//       products.push([...currentProductImages]);
//     }

//     setGroupedImages(products);
//     setIsGrouping(false);

//     // データを更新
//     if (columns.length > 0) {
//       const newData = products.map((productImages) => ({
//         // 各カラムにデフォルト値を設定
//         ...columns.reduce((obj, col) => {
//           if (col === 'PicURL') {
//             // PicURLカラムには画像URLを設定
//             obj[col] = productImages.map((img) => img.url).join('|');
//           } else if (defaultValues.hasOwnProperty(col)) {
//             // デフォルト値が指定されているカラム
//             obj[col] = defaultValues[col];
//           } else {
//             // それ以外のカラムには 'NA' を設定
//             obj[col] = 'NA';
//           }
//           return obj;
//         }, {}),
//       }));
//       setData(newData);

//       // startRowとendRowを更新
//       setStartRow(1);
//       setEndRow(newData.length);
//     }
//   };

//   // 画像のドラッグ＆ドロップによる並び替え
//   const onDragEnd = (result) => {
//     if (!result.destination) {
//       return;
//     }

//     const reorderedImages = Array.from(uploadedImages);
//     const [removed] = reorderedImages.splice(result.source.index, 1);
//     reorderedImages.splice(result.destination.index, 0, removed);

//     setUploadedImages(reorderedImages);
//   };

//   return (
//     <Box>
//       <Grid container spacing={2}>
//         <Grid item xs={12} sm={6} md={4}>
//           <Button variant="contained" color="primary" onClick={handleStartNewListing}>
//             新規出品をする
//           </Button>
//         </Grid>
//         {uploadedImages.length > 0 && (
//           <Grid item xs={12} sm={6} md={4}>
//             <Button variant="contained" color="primary" onClick={handleAddImages}>
//               追加画像をアップロード
//             </Button>
//           </Grid>
//         )}
//         <Grid item xs={12} sm={6} md={4}>
//           <Button variant="contained" color="primary" onClick={fetchLatestUploadedImages}>
//             最新のアップロード画像を取得
//           </Button>
//         </Grid>
//       </Grid>

//       <Dialog open={isImageUploadDialogOpen} onClose={() => setIsImageUploadDialogOpen(false)}>
//         <DialogTitle>
//           {uploadMode === 'new' ? '新規出品の画像をアップロード' : '追加画像をアップロード'}
//         </DialogTitle>
//         <DialogContent>
//           <input type="file" accept="image/*" multiple onChange={handleImageFileChange} />
//           {imageFiles.length > 0 && (
//             <Typography variant="body2" sx={{ mt: 2 }}>
//               選択された画像: {imageFiles.length} 枚
//             </Typography>
//           )}
//           {(isCompressing || isUploading) && (
//             <Box sx={{ mt: 2 }}>
//               {isCompressing && (
//                 <>
//                   <Typography variant="body2">画像を圧縮しています...</Typography>
//                   <LinearProgress variant="determinate" value={compressionProgress} />
//                 </>
//               )}
//               {isUploading && (
//                 <>
//                   <Typography variant="body2">
//                     画像をアップロードしています... ({uploadProgress} / {imageFiles.length})
//                   </Typography>
//                   <LinearProgress
//                     variant="determinate"
//                     value={(uploadProgress / imageFiles.length) * 100}
//                   />
//                 </>
//               )}
//             </Box>
//           )}
//         </DialogContent>
//         <DialogActions>
//           <Button
//             onClick={() => {
//               setIsImageUploadDialogOpen(false);
//               setImageFiles([]);
//             }}
//             disabled={isCompressing || isUploading}
//           >
//             キャンセル
//           </Button>
//           <Button
//             onClick={handleImageUpload}
//             disabled={isCompressing || isUploading || imageFiles.length === 0}
//           >
//             アップロード
//           </Button>
//         </DialogActions>
//       </Dialog>

//       {uploadedImages.length > 0 && (
//         <>
//           <Typography variant="h6" sx={{ mt: 4 }}>
//             アップロードされた画像
//           </Typography>
//           <Typography variant="body2" sx={{ mb: 2 }}>
//             画像をドラッグ＆ドロップで並び替え、区切りを挿入して商品を分けることができます。
//           </Typography>
//           <DragDropContext onDragEnd={onDragEnd}>
//             <Droppable droppableId="uploadedImages" direction="horizontal">
//               {(provided) => (
//                 <Box
//                   sx={{
//                     display: 'flex',
//                     flexWrap: 'wrap',
//                     p: 1,
//                     border: '1px solid #ccc',
//                     borderRadius: '4px',
//                   }}
//                   {...provided.droppableProps}
//                   ref={provided.innerRef}
//                 >
//                   {uploadedImages.map((img, index) => (
//                     <Draggable key={img.id} draggableId={img.id} index={index}>
//                       {(provided) => (
//                         <Box
//                           sx={{
//                             position: 'relative',
//                             m: 0.5,
//                             width: '120px',
//                             height: '120px',
//                             display: 'flex',
//                             alignItems: 'center',
//                             justifyContent: 'center',
//                             backgroundColor: img.type === 'separator' ? '#f0f0f0' : 'transparent',
//                             border: img.type === 'separator' ? '2px dashed #ccc' : 'none',
//                           }}
//                           ref={provided.innerRef}
//                           {...provided.draggableProps}
//                           {...provided.dragHandleProps}
//                         >
//                           {img.type === 'separator' ? (
//                             <>
//                               <Typography variant="body2">区切り</Typography>
//                               <IconButton
//                                 size="small"
//                                 sx={{
//                                   position: 'absolute',
//                                   top: '4px',
//                                   right: '4px',
//                                   color: 'red',
//                                   backgroundColor: 'white',
//                                   '&:hover': {
//                                     backgroundColor: 'white',
//                                   },
//                                 }}
//                                 onClick={() => removeSeparator(img.id)}
//                               >
//                                 <DeleteIcon fontSize="small" />
//                               </IconButton>
//                             </>
//                           ) : (
//                             <>
//                               <img
//                                 src={img.url}
//                                 alt={`Uploaded ${index}`}
//                                 style={{ width: '100%', height: '100%', objectFit: 'cover' }}
//                               />
//                               <IconButton
//                                 size="small"
//                                 sx={{
//                                   position: 'absolute',
//                                   top: '4px',
//                                   right: '4px',
//                                   color: 'red',
//                                   backgroundColor: 'white',
//                                   '&:hover': {
//                                     backgroundColor: 'white',
//                                   },
//                                 }}
//                                 onClick={() => handleDeleteUploadedImage(img.id)}
//                               >
//                                 <DeleteIcon fontSize="small" />
//                               </IconButton>
//                               {/* 区切りを挿入するボタン */}
//                               {img.type !== 'separator' && (
//                                 <IconButton
//                                   size="small"
//                                   sx={{
//                                     position: 'absolute',
//                                     bottom: '4px',
//                                     right: '4px',
//                                     backgroundColor: 'white',
//                                     '&:hover': {
//                                       backgroundColor: 'white',
//                                     },
//                                   }}
//                                   onClick={() => addSeparator(index)}
//                                 >
//                                   <AddIcon fontSize="small" />
//                                 </IconButton>
//                               )}
//                             </>
//                           )}
//                         </Box>
//                       )}
//                     </Draggable>
//                   ))}
//                   {provided.placeholder}
//                 </Box>
//               )}
//             </Droppable>
//           </DragDropContext>

//           <Grid container spacing={2} sx={{ mt: 2 }}>
//             <Grid item xs={12} sm={6} md={4}>
//               <Button
//                 variant="contained"
//                 color="secondary"
//                 onClick={handleGroupImages}
//                 disabled={isGrouping}
//               >
//                 画像を商品に割り当てる
//               </Button>
//             </Grid>
//           </Grid>
//         </>
//       )}

//       {isGrouping && (
//         <Box sx={{ mt: 2 }}>
//           <Typography variant="body2">商品情報を更新しています...</Typography>
//           <LinearProgress />
//         </Box>
//       )}
//     </Box>
//   );
// };

// export default ImageUploader;