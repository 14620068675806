// TitleGenerationComponent.js

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
  Button,
  Typography,
  TextField,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Grid,
  Snackbar,
  Alert,
  Box,
  LinearProgress,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  FormControlLabel,
  Switch,
  Chip,
  IconButton,
  Pagination,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@mui/material';
import {
  Add,
  Delete,
  FormatColorReset as FormatColorResetIcon,
  Edit as EditIcon,
  MoneyOff as MoneyOffIcon,
} from '@mui/icons-material';
import PQueue from 'p-queue';
import CustomPromptDialog from './CustomPromptDialog';
import {
  loadCacheFromLocalStorage,
  saveCacheToLocalStorage,
  getCachedTitle,
  setCachedTitle,
  clearTitleCache,
} from './titleCache';
import { db } from './firebaseConfig'; // Firestoreのインポート
import { doc, setDoc, getDoc } from 'firebase/firestore'; // Firestoreの関数をインポート
import LowCostTitleGenerator, {
  generateProductTitles as generateLowCostTitles,
} from './LowCostTitleGenerator';
import ProductFilter from './ProductFilter'; // フィルターコンポーネントをインポート

// 定数の定義
const ITEMS_PER_PAGE_OPTIONS = [10, 25, 50, 'All'];

// 正規表現の特殊文字をエスケープする関数
function escapeRegExp(string) {
  return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
}

// コンポーネントの定義
const TitleGenerationComponent = ({ data, setData, apiKey, user }) => {
  // 状態管理のフック
  const [open, setOpen] = useState(false);
  const [products, setProducts] = useState([]);
  const [displayedProducts, setDisplayedProducts] = useState([]);
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [loading, setLoading] = useState(false);
  const [lowCostLoading, setLowCostLoading] = useState(false); // 低コストAI生成用のローディング状態
  const [progress, setProgress] = useState(0);
  const [error, setError] = useState('');
  const [message, setMessage] = useState('');
  const [customPromptDialogOpen, setCustomPromptDialogOpen] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('info');
  const [deleteStrings, setDeleteStrings] = useState([]);
  const [replacePairs, setReplacePairs] = useState([]);
  const [prependText, setPrependText] = useState('');
  const [appendText, setAppendText] = useState('');
  const [limitTitleLength, setLimitTitleLength] = useState(false);
  const [categoryName, setCategoryName] = useState('');
  const [customCategories, setCustomCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState('');
  const [focusedProductId, setFocusedProductId] = useState(null);

  // 画像解析の説明を使用するかどうかの状態
  const [useImageDescription, setUseImageDescription] = useState(false);

  // キャッシュの読み込みとFirestoreからの設定取得
  useEffect(() => {
    loadCacheFromLocalStorage();
    if (user) {
      loadCategoriesFromFirestore(user.uid);
    }
  }, [user]);

  // カスタムカテゴリーをFirestoreから読み込む
  const loadCategoriesFromFirestore = async (uid) => {
    try {
      const docRef = doc(db, 'userTitleSettings', uid);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        const data = docSnap.data();
        setCustomCategories(data.customCategories || []);
        setSnackbarMessage('カスタム設定をロードしました');
        setSnackbarSeverity('success');
        setSnackbarOpen(true);
      } else {
        console.log('No custom categories found in Firestore.');
      }
    } catch (error) {
      console.error('Error loading categories from Firestore:', error);
      setSnackbarMessage('カスタム設定のロードに失敗しました');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    }
  };

  // カスタムカテゴリーをFirestoreに保存
  const saveCategoriesToFirestore = async (uid, categories) => {
    try {
      const docRef = doc(db, 'userTitleSettings', uid);
      await setDoc(docRef, { customCategories: categories }, { merge: true });
      setSnackbarMessage('カスタム設定を保存しました');
      setSnackbarSeverity('success');
      setSnackbarOpen(true);
    } catch (error) {
      console.error('Error saving categories to Firestore:', error);
      setSnackbarMessage('カスタム設定の保存に失敗しました');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    }
  };

  // p-queueの設定
  const CONCURRENCY = 2;
  const INTERVAL = 1000;
  const INTERVAL_CAP = 5;
  const queue = new PQueue({
    concurrency: CONCURRENCY,
    interval: INTERVAL,
    intervalCap: INTERVAL_CAP,
  });

  useEffect(() => {
    if (open) {
      initializeProducts();
    }
  }, [open, useImageDescription]); // useImageDescriptionを依存関係に追加

  // productsの長さやitemsPerPageが変わったときにtotalPagesを更新
  useEffect(() => {
    const total = filteredProducts.length;
    const itemsPerPageNum = itemsPerPage === 'All' ? filteredProducts.length : parseInt(itemsPerPage, 10);
    const newTotalPages = Math.ceil(total / itemsPerPageNum);
    setTotalPages(newTotalPages);

    // 現在のページが総ページ数を超えていれば調整
    if (currentPage > newTotalPages) {
      setCurrentPage(newTotalPages);
    }
  }, [filteredProducts.length, itemsPerPage]);

  // currentPageやfilteredProductsが変わったときにdisplayedProductsを更新
  useEffect(() => {
    updateDisplayedProducts(currentPage, filteredProducts);
  }, [filteredProducts, currentPage, itemsPerPage]);

  // フィルタリングとソートを適用
  useEffect(() => {
    setFilteredProducts(products);
  }, [products]);

  const updateDisplayedProducts = (page, productsToDisplay) => {
    const itemsPerPageNum = itemsPerPage === 'All' ? productsToDisplay.length : parseInt(itemsPerPage, 10);
    const startIndex = (page - 1) * itemsPerPageNum;
    const endIndex = startIndex + itemsPerPageNum;
    setDisplayedProducts(productsToDisplay.slice(startIndex, endIndex));
  };

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
    updateDisplayedProducts(value, filteredProducts);
  };

  // データから商品リストを初期化
  const initializeProducts = () => {
    if (!data || data.length === 0) {
      setError('データがありません。CSVファイルをインポートしてください。');
      setSnackbarMessage('データがありません');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
      return;
    }

    const headers = Object.keys(data[0]);
    const titleIndex = headers.findIndex((header) => header.toLowerCase() === 'title');
    const jpDescIndex = headers.findIndex((header) => header.toLowerCase() === 'jp_desc');
    const jpImageDescIndex = headers.findIndex((header) => header.toLowerCase() === 'jp_image_description');

    if (titleIndex === -1) {
      setError('Titleカラムが見つかりません');
      return;
    }

    const productData = data.map((row, index) => {
      let jpDescValue = '';
      if (useImageDescription) {
        jpDescValue = row['jp_image_description'] || '';
      } else {
        jpDescValue = row['jp_desc'] || '';
      }

      return {
        id: index,
        title: row['Title'] || row['title'] || '',
        jpDesc: jpDescValue,
        generatedTitle: '',
        selected: false,
        editableJpDesc: jpDescValue,
      };
    });

    setProducts(productData);
    setCurrentPage(1); // ページを初期化
  };

  // 商品の選択ハンドラー
  const handleSelectProduct = (productId) => {
    const updatedProducts = products.map((product) =>
      product.id === productId ? { ...product, selected: !product.selected } : product
    );
    setProducts(updatedProducts);
  };

  // すべての商品を選択/解除
  const handleSelectAll = () => {
    const allSelected = displayedProducts.every((product) => product.selected);
    const updatedProducts = products.map((product) => {
      if (displayedProducts.find((p) => p.id === product.id)) {
        return { ...product, selected: !allSelected };
      }
      return product;
    });
    setProducts(updatedProducts);
  };

  // 生成されたタイトルの編集ハンドラー
  const handleGeneratedTitleChange = (event, productId) => {
    const updatedProducts = products.map((product) =>
      product.id === productId ? { ...product, generatedTitle: event.target.value } : product
    );
    setProducts(updatedProducts);
  };

  // AIによるタイトル生成（デフォルトプロンプト）
  const generateProductTitles = async () => {
    if (!apiKey) {
      setError('OpenAI APIキーが設定されていません');
      setSnackbarMessage('OpenAI APIキーが必要です');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
      return;
    }

    const selectedProductsData = products.filter((product) => product.selected);
    if (selectedProductsData.length === 0) {
      setError('少なくとも一つの商品を選択してください');
      setSnackbarMessage('商品が選択されていません');
      setSnackbarSeverity('warning');
      setSnackbarOpen(true);
      return;
    }

    setLoading(true);
    setError('');
    setMessage('');
    setProgress(0);

    try {
      const total = selectedProductsData.length;
      let completed = 0;

      const updatedProducts = [...products];

      const generateTitleTasks = selectedProductsData.map((product) => async () => {
        const index = products.findIndex((p) => p.id === product.id);
        try {
          const generatedTitle = await generateSingleTitle(
            product.title,
            product.editableJpDesc,
            apiKey
          );
          updatedProducts[index].generatedTitle = generatedTitle;
          completed++;
          setProgress(Math.round((completed / total) * 100));
        } catch (err) {
          console.error(`商品ID ${product.id} のタイトル生成エラー:`, err);
          updatedProducts[index].generatedTitle = '';
          setError(`タイトルの生成に失敗しました: ${err.message}`);
        }
      });

      await queue.addAll(generateTitleTasks);

      setProducts(updatedProducts);
      setMessage('タイトルの生成が完了しました');
      setSnackbarMessage('タイトルの生成が完了しました');
      setSnackbarSeverity('success');
      setSnackbarOpen(true);
    } catch (err) {
      console.error('タイトル生成エラー:', err);
      setError(`タイトルの生成に失敗しました: ${err.message}`);
      setSnackbarMessage(`エラー: ${err.message}`);
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    } finally {
      setLoading(false);
      setProgress(0);
    }
  };

  // 低コストAIでタイトル生成
  const handleLowCostAIGeneration = async () => {
    if (!apiKey) {
      setError('OpenAI APIキーが設定されていません');
      setSnackbarMessage('OpenAI APIキーが必要です');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
      return;
    }

    const selectedProductsData = products.filter((product) => product.selected);
    if (selectedProductsData.length === 0) {
      setError('少なくとも一つの商品を選択してください');
      setSnackbarMessage('商品が選択されていません');
      setSnackbarSeverity('warning');
      setSnackbarOpen(true);
      return;
    }

    setLowCostLoading(true);
    setError('');
    setMessage('');
    setProgress(0);

    try {
      await generateLowCostTitles({
        products,
        setProducts,
        apiKey,
        setSnackbarMessage,
        setSnackbarSeverity,
        setSnackbarOpen,
        setError,
        setMessage,
        setProgress,
        queue,
      });
      setSnackbarMessage('低コストAIでのタイトル生成が完了しました');
      setSnackbarSeverity('success');
      setSnackbarOpen(true);
    } catch (error) {
      console.error('低コストAI生成エラー:', error);
      setError(`低コストAI生成に失敗しました: ${error.message}`);
      setSnackbarMessage(`エラー: ${error.message}`);
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    } finally {
      setLowCostLoading(false);
      setProgress(0);
    }
  };

  // 単一の商品タイトルを生成
  async function generateSingleTitle(originalTitle, jpDesc, apiKey) {
    const cacheKey = originalTitle + jpDesc.slice(0, 150);
    const cachedTitle = getCachedTitle(cacheKey);
    if (cachedTitle) {
      return cachedTitle;
    }

    try {
      const url = 'https://api.openai.com/v1/chat/completions';
      const limitedJpDesc = jpDesc.slice(0, 150);
      const requestData = {
        model: 'gpt-4o-2024-08-06',
        messages: [
          {
            role: 'system',
            content: `You are an AI assistant specializing in eBay product title optimization. Based on the given original title and Japanese product information, create an effective English title within 80 characters.
Order of title composition:
1. brand name (if applicable)
2. model name or product name/character name (if applicable)
3. product type (if applicable)
4. key features (color, material, size) (if applicable)
5. distinctive elements or uniqueness (if applicable)
6. condition (new/used) (if applicable)
7. important keywords (if applicable)
NOTES:
- Add or optimize new information while retaining important information from the original title
- Avoid unnecessary adjectives and words such as “eBay
- Use abbreviations and common names appropriately
- Think from the searcher's perspective and include search terms that buyers are likely to use
- If information is unclear or ambiguous, omit it.
- Use only reliable information and do not include guesswork or uncertain information.
- Do not extract from Japanese descriptions that are a list of words.
- Titles should be generated in English and should not exceed 80 characters.`,
          },
          {
            role: 'user',
            content: `元のタイトル：「${originalTitle}」
日本語の商品説明：「${limitedJpDesc}」
この情報を基に、最適化された新しいeBayの商品タイトルを生成してください。また日本語の商品説明が空の場合は元のタイトルから生成してください`,
          },
        ],
        max_tokens: 100,
      };

      const response = await axios.post(url, requestData, {
        headers: { Authorization: `Bearer ${apiKey}` },
      });

      const generatedTitle = response.data.choices[0].message.content.trim();
      setCachedTitle(cacheKey, generatedTitle);
      return generatedTitle;
    } catch (error) {
      throw error;
    }
  }

  // タイトルカスタマイズの適用
  const customizeTitles = () => {
    const updatedProducts = products.map((product) => {
      if (product.selected && product.generatedTitle) {
        let newTitle = product.generatedTitle;

        // 複数の削除文字列を処理
        deleteStrings.forEach((deleteStr) => {
          if (deleteStr) {
            const escapedDeleteStr = escapeRegExp(deleteStr);
            const regex = new RegExp(escapedDeleteStr, 'g');
            newTitle = newTitle.replace(regex, '');
          }
        });

        // 複数の置換ペアを処理
        replacePairs.forEach((pair) => {
          const { find, replace } = pair;
          if (find) {
            const escapedFind = escapeRegExp(find);
            const regex = new RegExp(escapedFind, 'g');
            newTitle = newTitle.replace(regex, replace);
          }
        });

        // 先頭と末尾の追加文字列を処理
        if (prependText) {
          newTitle = `${prependText} ${newTitle}`;
        }
        if (appendText) {
          newTitle = `${newTitle} ${appendText}`;
        }

        // タイトルを80文字に制限
        if (limitTitleLength) {
          newTitle = newTitle.slice(0, 79);
        }

        return { ...product, generatedTitle: newTitle };
      }
      return product;
    });
    setProducts(updatedProducts);
  };

  // 生成されたタイトルをデータに反映
  const applyUpdatedTitles = () => {
    const updatedProducts = products.map((product) => {
      if (product.selected && product.generatedTitle) {
        const updatedProduct = { ...data[product.id], Title: product.generatedTitle };
        return updatedProduct;
      }
      return data[product.id];
    });

    setData(updatedProducts);
    setSnackbarMessage('生成されたタイトルをデータに反映しました');
    setSnackbarSeverity('success');
    setSnackbarOpen(true);
    setOpen(false);
  };

  // カテゴリーの変更ハンドラー
  const handleCategoryChange = (event) => {
    const selectedName = event.target.value;
    setSelectedCategory(selectedName);

    if (selectedName) {
      const category = customCategories.find((cat) => cat.name === selectedName);
      if (category) {
        setDeleteStrings(category.deleteStrings || []);
        setReplacePairs(category.replacePairs || []);
        setPrependText(category.prependText || '');
        setAppendText(category.appendText || '');
        setLimitTitleLength(category.limitTitleLength || false);
        setCategoryName(category.name);
      }
    } else {
      // 新しいカテゴリーを作成する場合、初期状態にリセット
      resetCustomizationSettings();
    }
  };

  // カスタマイズ設定を初期状態にリセット
  const resetCustomizationSettings = () => {
    setDeleteStrings([]);
    setReplacePairs([]);
    setPrependText('');
    setAppendText('');
    setLimitTitleLength(false);
    setCategoryName('');
    setSelectedCategory('');
  };

  // 新しいカテゴリーを作成
  const handleCreateNewCategory = () => {
    // カテゴリー選択をクリアし、初期状態にリセット
    resetCustomizationSettings();
  };

  // カテゴリーを保存または更新する
  const saveOrUpdateCategory = () => {
    if (!categoryName.trim()) {
      setSnackbarMessage('カテゴリー名を入力してください');
      setSnackbarSeverity('warning');
      setSnackbarOpen(true);
      return;
    }

    const newCategory = {
      name: categoryName.trim(),
      deleteStrings,
      replacePairs,
      prependText,
      appendText,
      limitTitleLength,
    };

    let updatedCategories;
    if (selectedCategory) {
      // 既存のカテゴリーを更新
      updatedCategories = customCategories.map((cat) =>
        cat.name === selectedCategory ? newCategory : cat
      );
      setSnackbarMessage('カテゴリーを更新しました');
    } else {
      // 新しいカテゴリーを追加
      updatedCategories = [...customCategories, newCategory];
      setSnackbarMessage('新しいカテゴリーを保存しました');
    }

    setCustomCategories(updatedCategories);
    if (user) {
      saveCategoriesToFirestore(user.uid, updatedCategories);
    }
    setSnackbarSeverity('success');
    setSnackbarOpen(true);
    setSelectedCategory(newCategory.name);
  };

  // カテゴリーを削除する
  const deleteCategory = () => {
    if (!selectedCategory) return;

    const updatedCategories = customCategories.filter((cat) => cat.name !== selectedCategory);
    setCustomCategories(updatedCategories);
    if (user) {
      saveCategoriesToFirestore(user.uid, updatedCategories);
    }
    resetCustomizationSettings();
    setSnackbarMessage('カテゴリーを削除しました');
    setSnackbarSeverity('info');
    setSnackbarOpen(true);
  };

  // キャッシュをクリア
  const handleClearTitleCache = () => {
    clearTitleCache();
    setSnackbarMessage('キャッシュをクリアしました');
    setSnackbarSeverity('info');
    setSnackbarOpen(true);
  };

  // 削除文字列のチップ関連の関数
  const handleDeleteStringKeyPress = (event) => {
    if (event.key === 'Enter' && event.target.value.trim() !== '') {
      setDeleteStrings([...deleteStrings, event.target.value.trim()]);
      event.target.value = '';
    }
  };

  const handleDeleteChip = (chipToDelete) => {
    setDeleteStrings((chips) => chips.filter((chip) => chip !== chipToDelete));
  };

  // 置換ペア関連の関数
  const handleAddReplacePair = () => {
    setReplacePairs([...replacePairs, { find: '', replace: '' }]);
  };

  const handleReplacePairChange = (index, field, value) => {
    const newReplacePairs = [...replacePairs];
    newReplacePairs[index][field] = value;
    setReplacePairs(newReplacePairs);
  };

  const handleRemoveReplacePair = (index) => {
    const newReplacePairs = replacePairs.filter((_, i) => i !== index);
    setReplacePairs(newReplacePairs);
  };

  // 商品説明の編集
  const handleEditJpDesc = (productId, newDesc) => {
    const updatedProducts = products.map((product) =>
      product.id === productId ? { ...product, editableJpDesc: newDesc } : product
    );
    setProducts(updatedProducts);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  // 生成されたタイトルを更新するための関数
  const updateProducts = (updatedProducts) => {
    const newProducts = products.map((product) => {
      const updatedProduct = updatedProducts.find((p) => p.id === product.id);
      return updatedProduct ? updatedProduct : product;
    });
    setProducts(newProducts);
  };

  // itemsPerPageが変更されたときにcurrentPageを1にリセット
  const handleItemsPerPageChange = (e) => {
    setItemsPerPage(e.target.value);
    setCurrentPage(1);
  };

  return (
    <Paper
      elevation={3}
      sx={{
        p: 4,
        mt: 1,
        mb: 1,
        maxWidth: '100%',
        margin: '0 auto',
        overflow: 'hidden',
      }}
    >
      <Box sx={{ maxWidth: '1600px', margin: '0 auto' }}>
        {/* ヘッダーセクション */}
        <Grid container spacing={3} alignItems="center">
          <Grid item xs={12} md={6}>
            <Typography variant="h5" gutterBottom>
              AIを活用したタイトル生成
            </Typography>
            <Typography variant="body1" paragraph>
              日本語の商品説明から英語の商品タイトルを自動生成します。
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography variant="subtitle1" gutterBottom>
              主な機能：
            </Typography>
            <Grid container spacing={1}>
              {[
                '日本語の商品説明から英語の商品タイトルを生成',
                'タイトルの一括編集とカスタマイズ',
              ].map((feature, index) => (
                <Grid item xs={6} key={index}>
                  <Typography variant="body2">• {feature}</Typography>
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Grid>

        {/* アクションボタンセクション */}
        <Box sx={{ mt: 3, display: 'flex', gap: '10px', justifyContent: 'center' }}>
          <Button variant="contained" color="primary" onClick={handleOpen} startIcon={<Add />}>
            タイトルをAIで生成する
          </Button>
          <Button
            variant="outlined"
            color="primary"
            onClick={handleClearTitleCache}
            startIcon={<FormatColorResetIcon />}
          >
            キャッシュをクリア
          </Button>
        </Box>

        {/* ダイアログセクション */}
        <Dialog open={open} onClose={handleClose} fullScreen>
          <DialogTitle>AIでタイトルを生成する</DialogTitle>
          <DialogContent>
            {/* レイアウトを左右に分割 */}
            <Grid container spacing={2}>
              {/* 左側のセクション */}
              <Grid
                item
                xs={12}
                md={2}
                sx={{
                  maxHeight: 'calc(100vh - 32px)',
                  overflowY: 'auto',
                }}
              >
                {/* カテゴリー選択とカスタマイズ */}
                <Typography variant="h6">タイトルカスタマイズ</Typography>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <FormControl fullWidth margin="normal">
                      <InputLabel id="category-select-label">カテゴリーを選択</InputLabel>
                      <Select
                        labelId="category-select-label"
                        value={selectedCategory}
                        onChange={handleCategoryChange}
                      >
                        {customCategories.map((cat) => (
                          <MenuItem key={cat.name} value={cat.name}>
                            {cat.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      label="カテゴリー名"
                      fullWidth
                      margin="normal"
                      value={categoryName}
                      onChange={(e) => setCategoryName(e.target.value)}
                      placeholder="新しいカテゴリー名を入力"
                    />
                    <Button
                      variant="outlined"
                      color="primary"
                      onClick={handleCreateNewCategory}
                      sx={{ mt: 1 }}
                    >
                      新しいカテゴリーを作成
                    </Button>
                  </Grid>

                  {/* トグルスイッチを追加 */}
                  <Grid item xs={12} style={{ marginTop: '20px' }}>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={useImageDescription}
                          onChange={(e) => setUseImageDescription(e.target.checked)}
                          name="useImageDescription"
                          color="primary"
                        />
                      }
                      label="画像解析の説明を使用"
                    />
                  </Grid>

                  {/* 削除する文字列 */}
                  <Grid item xs={12}>
                    <Typography variant="subtitle1">削除する文字列</Typography>
                    <TextField
                      placeholder="削除したい文字列を入力し、Enterキーを押してください"
                      onKeyPress={handleDeleteStringKeyPress}
                      fullWidth
                    />
                    <Box sx={{ mt: 1, display: 'flex', flexWrap: 'wrap', gap: 1 }}>
                      {deleteStrings.map((string, index) => (
                        <Chip key={index} label={string} onDelete={() => handleDeleteChip(string)} />
                      ))}
                    </Box>
                  </Grid>
                  {/* 置換ペア */}
                  <Grid item xs={12}>
                    <Typography variant="subtitle1">置換ペア</Typography>
                    {replacePairs.map((pair, index) => (
                      <Box
                        key={index}
                        sx={{ display: 'flex', gap: 1, alignItems: 'center', mt: 1 }}
                      >
                        <TextField
                          label="置換する文字列"
                          value={pair.find}
                          onChange={(e) => handleReplacePairChange(index, 'find', e.target.value)}
                        />
                        <TextField
                          label="置換後の文字列"
                          value={pair.replace}
                          onChange={(e) => handleReplacePairChange(index, 'replace', e.target.value)}
                        />
                        <IconButton color="secondary" onClick={() => handleRemoveReplacePair(index)}>
                          <Delete />
                        </IconButton>
                      </Box>
                    ))}
                    <Button
                      variant="outlined"
                      startIcon={<Add />}
                      onClick={handleAddReplacePair}
                      sx={{ mt: 2 }}
                    >
                      置換ペアを追加
                    </Button>
                  </Grid>
                  {/* 先頭・末尾に追加する文字列 */}
                  <Grid item xs={12}>
                    <TextField
                      label="先頭に追加する文字列"
                      fullWidth
                      margin="normal"
                      value={prependText}
                      onChange={(e) => setPrependText(e.target.value)}
                      helperText="タイトルの先頭に追加します"
                    />
                    <TextField
                      label="末尾に追加する文字列"
                      fullWidth
                      margin="normal"
                      value={appendText}
                      onChange={(e) => setAppendText(e.target.value)}
                      helperText="タイトルの末尾に追加します"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={limitTitleLength}
                          onChange={(e) => setLimitTitleLength(e.target.checked)}
                        />
                      }
                      label="80文字に制限する"
                    />
                  </Grid>
                  {/* カテゴリーの保存・更新・削除ボタン */}
                  <Grid item xs={12}>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={saveOrUpdateCategory}
                      disabled={!categoryName.trim()}
                      fullWidth
                    >
                      {selectedCategory ? 'カテゴリーを更新' : 'カテゴリーを保存'}
                    </Button>
                    {selectedCategory && (
                      <Button
                        variant="outlined"
                        color="secondary"
                        onClick={deleteCategory}
                        fullWidth
                        sx={{ mt: 1 }}
                      >
                        カテゴリーを削除
                      </Button>
                    )}
                  </Grid>
                  {/* タイトルカスタマイズの適用ボタン */}
                  <Grid item xs={12}>
                    <Button
                      variant="contained"
                      color="secondary"
                      onClick={customizeTitles}
                      fullWidth
                      sx={{ mt: 2 }}
                    >
                      タイトルカスタマイズを適用
                    </Button>
                  </Grid>
                  {/* 表示件数選択コンポーネントを追加 */}
                  <Grid item xs={12}>
                    <FormControl variant="outlined" size="small" fullWidth sx={{ mt: 2 }}>
                      <InputLabel id="items-per-page-label">表示件数</InputLabel>
                      <Select
                        labelId="items-per-page-label"
                        value={itemsPerPage}
                        onChange={handleItemsPerPageChange}
                        label="表示件数"
                      >
                        {ITEMS_PER_PAGE_OPTIONS.map((option, index) => (
                          <MenuItem key={index} value={option}>
                            {option === 'All' ? '全て' : `${option}件ずつ`}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>

                {/* フィルターとソートのセクションを追加 */}
                <ProductFilter
                  products={products}
                  setFilteredProducts={setFilteredProducts}
                />
              </Grid>

              {/* 右側のセクション */}
              <Grid
                item
                xs={12}
                md={10}
                sx={{
                  maxHeight: 'calc(100vh - 32px)',
                  overflowY: 'auto',
                }}
              >
                {/* アクションボタン */}
                <Grid container spacing={2} style={{ marginBottom: '20px' }}>
                  <Grid item>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={generateProductTitles}
                      disabled={loading || !apiKey}
                    >
                      高精度AI生成をする
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleLowCostAIGeneration}
                      startIcon={<MoneyOffIcon />}
                      disabled={lowCostLoading || !apiKey}
                    >
                      低コストAI生成をする
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button
                      variant="contained"
                      color="secondary"
                      onClick={applyUpdatedTitles}
                      disabled={loading || lowCostLoading}
                    >
                      タイトルをデータに反映する
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button
                      variant="contained"
                      color="success"
                      onClick={() => setCustomPromptDialogOpen(true)}
                      startIcon={<EditIcon />}
                      disabled={loading || lowCostLoading}
                    >
                      カスタムプロンプトで生成
                    </Button>
                  </Grid>
                </Grid>

                {/* 進捗状況表示 */}
                {(loading || lowCostLoading) && (
                  <Box sx={{ width: '100%', mb: 2 }}>
                    <LinearProgress variant="determinate" value={progress} />
                    <Typography variant="body2" color="text.secondary" align="center">
                      {`${Math.round(progress)}%`}
                    </Typography>
                  </Box>
                )}

                {/* エラーメッセージ */}
                {error && (
                  <Typography color="error" style={{ marginTop: '20px' }}>
                    {error}
                  </Typography>
                )}
                {/* 成功メッセージ */}
                {message && (
                  <Typography color="primary" style={{ marginTop: '20px' }}>
                    {message}
                  </Typography>
                )}

                {/* 商品テーブル */}
                <TableContainer component={Paper}>
                  <Table stickyHeader aria-label="products-table">
                    <TableHead>
                      <TableRow>
                        <TableCell padding="checkbox">
                          <Checkbox
                            checked={displayedProducts.every((product) => product.selected)}
                            onChange={handleSelectAll}
                            indeterminate={
                              displayedProducts.some((product) => product.selected) &&
                              !displayedProducts.every((product) => product.selected)
                            }
                          />
                        </TableCell>
                        <TableCell>商品タイトル</TableCell>
                        <TableCell>日本語の商品説明</TableCell>
                        <TableCell>生成されたタイトル</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {displayedProducts.map((product) => (
                        <TableRow key={product.id} hover>
                          <TableCell padding="checkbox">
                            <Checkbox
                              checked={product.selected}
                              onChange={() => handleSelectProduct(product.id)}
                              onClick={(e) => e.stopPropagation()}
                            />
                          </TableCell>
                          <TableCell
                            sx={{
                              fontSize: '0.8rem',
                              whiteSpace: 'pre-wrap',
                              wordWrap: 'break-word',
                              maxWidth: '300px',
                              padding: '4px',
                            }}
                          >
                            {product.title}
                          </TableCell>
                          <TableCell
                            sx={{
                              padding: '4px',
                            }}
                          >
                            <TextField
                              multiline
                              fullWidth
                              variant="outlined"
                              value={product.editableJpDesc}
                              onChange={(e) => handleEditJpDesc(product.id, e.target.value)}
                              onFocus={() => setFocusedProductId(product.id)}
                              onBlur={() => setFocusedProductId(null)}
                              InputProps={{
                                sx: {
                                  textarea: {
                                    maxHeight: focusedProductId === product.id ? 'none' : '8em',
                                    overflow: 'auto',
                                    transition: 'max-height 0.3s ease',
                                  },
                                },
                              }}
                              inputProps={{
                                style: {
                                  fontSize: '0.8rem',
                                  padding: '2px',
                                  lineHeight: '1.2',
                                },
                              }}
                            />
                          </TableCell>
                          <TableCell
                            sx={{
                              padding: '4px',
                            }}
                          >
                            <TextField
                              multiline
                              fullWidth
                              variant="outlined"
                              value={product.generatedTitle}
                              onChange={(event) => handleGeneratedTitleChange(event, product.id)}
                              inputProps={{
                                style: {
                                  fontSize: '0.8rem',
                                  padding: '2px',
                                  lineHeight: '1.2',
                                },
                              }}
                              sx={{
                                margin: 0,
                              }}
                            />
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>

                {/* ページネーション */}
                {itemsPerPage !== 'All' && (
                  <Pagination
                    count={totalPages}
                    page={currentPage}
                    onChange={handlePageChange}
                    color="primary"
                    style={{ marginTop: '20px', display: 'flex', justifyContent: 'center' }}
                  />
                )}
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>閉じる</Button>
          </DialogActions>
        </Dialog>

        {/* カスタムプロンプトダイアログ */}
        <CustomPromptDialog
          open={customPromptDialogOpen}
          onClose={() => setCustomPromptDialogOpen(false)}
          apiKey={apiKey}
          selectedProducts={products.filter((product) => product.selected)}
          updateProducts={updateProducts}
        />

        {/* スナックバー通知 */}
        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          open={snackbarOpen}
          autoHideDuration={6000}
          onClose={() => setSnackbarOpen(false)}
        >
          <Alert
            onClose={() => setSnackbarOpen(false)}
            severity={snackbarSeverity}
            sx={{ width: '100%' }}
          >
            {snackbarMessage}
          </Alert>
        </Snackbar>
      </Box>
    </Paper>
  );
};

export default TitleGenerationComponent;





// // TitleGenerationComponent.js

// import React, { useState, useEffect } from 'react';
// import axios from 'axios';
// import {
//   Button,
//   Typography,
//   TextField,
//   Checkbox,
//   Table,
//   TableBody,
//   TableCell,
//   TableContainer,
//   TableHead,
//   TableRow,
//   Paper,
//   Grid,
//   Snackbar,
//   Alert,
//   Box,
//   LinearProgress,
//   FormControl,
//   Select,
//   MenuItem,
//   InputLabel,
//   FormControlLabel,
//   Switch,
//   Chip,
//   IconButton,
//   Pagination,
//   Dialog,
//   DialogTitle,
//   DialogContent,
//   DialogActions,
// } from '@mui/material';
// import {
//   Add,
//   Delete,
//   FormatColorReset as FormatColorResetIcon,
//   Edit as EditIcon,
//   MoneyOff as MoneyOffIcon,
// } from '@mui/icons-material';
// import PQueue from 'p-queue';
// import CustomPromptDialog from './CustomPromptDialog';
// import {
//   loadCacheFromLocalStorage,
//   saveCacheToLocalStorage,
//   getCachedTitle,
//   setCachedTitle,
//   clearTitleCache,
// } from './titleCache';
// import { db } from './firebaseConfig'; // Firestoreのインポート
// import { doc, setDoc, getDoc } from 'firebase/firestore'; // Firestoreの関数をインポート
// import LowCostTitleGenerator, { generateProductTitles as generateLowCostTitles } from './LowCostTitleGenerator';

// // 定数の定義
// const ITEMS_PER_PAGE_OPTIONS = [10, 25, 50, 'All'];

// // コンポーネントの定義
// const TitleGenerationComponent = ({ data, setData, apiKey, user }) => {
//   // 状態管理のフック
//   const [open, setOpen] = useState(false);
//   const [products, setProducts] = useState([]);
//   const [displayedProducts, setDisplayedProducts] = useState([]);
//   const [currentPage, setCurrentPage] = useState(1);
//   const [totalPages, setTotalPages] = useState(1);
//   const [itemsPerPage, setItemsPerPage] = useState(10);
//   const [loading, setLoading] = useState(false);
//   const [lowCostLoading, setLowCostLoading] = useState(false); // 低コストAI生成用のローディング状態
//   const [progress, setProgress] = useState(0);
//   const [error, setError] = useState('');
//   const [message, setMessage] = useState('');
//   const [customPromptDialogOpen, setCustomPromptDialogOpen] = useState(false);
//   const [snackbarOpen, setSnackbarOpen] = useState(false);
//   const [snackbarMessage, setSnackbarMessage] = useState('');
//   const [snackbarSeverity, setSnackbarSeverity] = useState('info');
//   const [deleteStrings, setDeleteStrings] = useState([]);
//   const [replacePairs, setReplacePairs] = useState([]);
//   const [prependText, setPrependText] = useState('');
//   const [appendText, setAppendText] = useState('');
//   const [limitTitleLength, setLimitTitleLength] = useState(false);
//   const [categoryName, setCategoryName] = useState('');
//   const [customCategories, setCustomCategories] = useState([]);
//   const [selectedCategory, setSelectedCategory] = useState('');
//   const [focusedProductId, setFocusedProductId] = useState(null);

//   // 画像解析の説明を使用するかどうかの状態
//   const [useImageDescription, setUseImageDescription] = useState(false);

//   // キャッシュの読み込みとFirestoreからの設定取得
//   useEffect(() => {
//     loadCacheFromLocalStorage();
//     if (user) {
//       loadCategoriesFromFirestore(user.uid);
//     }
//   }, [user]);

//   // カスタムカテゴリーをFirestoreから読み込む
//   const loadCategoriesFromFirestore = async (uid) => {
//     try {
//       const docRef = doc(db, 'userTitleSettings', uid);
//       const docSnap = await getDoc(docRef);
//       if (docSnap.exists()) {
//         const data = docSnap.data();
//         setCustomCategories(data.customCategories || []);
//         setSnackbarMessage('カスタム設定をロードしました');
//         setSnackbarSeverity('success');
//         setSnackbarOpen(true);
//       } else {
//         console.log('No custom categories found in Firestore.');
//       }
//     } catch (error) {
//       console.error('Error loading categories from Firestore:', error);
//       setSnackbarMessage('カスタム設定のロードに失敗しました');
//       setSnackbarSeverity('error');
//       setSnackbarOpen(true);
//     }
//   };

//   // カスタムカテゴリーをFirestoreに保存
//   const saveCategoriesToFirestore = async (uid, categories) => {
//     try {
//       const docRef = doc(db, 'userTitleSettings', uid);
//       await setDoc(docRef, { customCategories: categories }, { merge: true });
//       setSnackbarMessage('カスタム設定を保存しました');
//       setSnackbarSeverity('success');
//       setSnackbarOpen(true);
//     } catch (error) {
//       console.error('Error saving categories to Firestore:', error);
//       setSnackbarMessage('カスタム設定の保存に失敗しました');
//       setSnackbarSeverity('error');
//       setSnackbarOpen(true);
//     }
//   };

//   // p-queueの設定
//   const CONCURRENCY = 2;
//   const INTERVAL = 1000;
//   const INTERVAL_CAP = 5;
//   const queue = new PQueue({
//     concurrency: CONCURRENCY,
//     interval: INTERVAL,
//     intervalCap: INTERVAL_CAP,
//   });

//   useEffect(() => {
//     if (open) {
//       initializeProducts();
//     }
//   }, [open, useImageDescription]); // useImageDescriptionを依存関係に追加

//   // productsの長さやitemsPerPageが変わったときにtotalPagesを更新
//   useEffect(() => {
//     const total = products.length;
//     const itemsPerPageNum = itemsPerPage === 'All' ? products.length : parseInt(itemsPerPage, 10);
//     const newTotalPages = Math.ceil(total / itemsPerPageNum);
//     setTotalPages(newTotalPages);

//     // 現在のページが総ページ数を超えていれば調整
//     if (currentPage > newTotalPages) {
//       setCurrentPage(newTotalPages);
//     }
//   }, [products.length, itemsPerPage]);

//   // currentPageやproductsが変わったときにdisplayedProductsを更新
//   useEffect(() => {
//     updateDisplayedProducts(currentPage);
//   }, [products, currentPage, itemsPerPage]);

//   const updateDisplayedProducts = (page) => {
//     const itemsPerPageNum = itemsPerPage === 'All' ? products.length : parseInt(itemsPerPage, 10);
//     const startIndex = (page - 1) * itemsPerPageNum;
//     const endIndex = startIndex + itemsPerPageNum;
//     setDisplayedProducts(products.slice(startIndex, endIndex));
//   };

//   const handlePageChange = (event, value) => {
//     setCurrentPage(value);
//     updateDisplayedProducts(value);
//   };

//   // データから商品リストを初期化
//   const initializeProducts = () => {
//     if (!data || data.length === 0) {
//       setError('データがありません。CSVファイルをインポートしてください。');
//       setSnackbarMessage('データがありません');
//       setSnackbarSeverity('error');
//       setSnackbarOpen(true);
//       return;
//     }

//     const headers = Object.keys(data[0]);
//     const titleIndex = headers.findIndex((header) => header.toLowerCase() === 'title');
//     const jpDescIndex = headers.findIndex((header) => header.toLowerCase() === 'jp_desc');
//     const jpImageDescIndex = headers.findIndex((header) => header.toLowerCase() === 'jp_image_description');

//     if (titleIndex === -1) {
//       setError('Titleカラムが見つかりません');
//       return;
//     }

//     const productData = data.map((row, index) => {
//       let jpDescValue = '';
//       if (useImageDescription) {
//         jpDescValue = row['jp_image_description'] || '';
//       } else {
//         jpDescValue = row['jp_desc'] || '';
//       }

//       return {
//         id: index,
//         title: row['Title'] || row['title'] || '',
//         jpDesc: jpDescValue,
//         generatedTitle: '',
//         selected: false,
//         editableJpDesc: jpDescValue,
//       };
//     });

//     setProducts(productData);
//     setCurrentPage(1); // ページを初期化
//   };

//   // 商品の選択ハンドラー
//   const handleSelectProduct = (productId) => {
//     const updatedProducts = products.map((product) =>
//       product.id === productId ? { ...product, selected: !product.selected } : product
//     );
//     setProducts(updatedProducts);
//   };

//   // すべての商品を選択/解除
//   const handleSelectAll = () => {
//     const allSelected = products.every((product) => product.selected);
//     const updatedProducts = products.map((product) => ({
//       ...product,
//       selected: !allSelected,
//     }));
//     setProducts(updatedProducts);
//   };

//   // 生成されたタイトルの編集ハンドラー
//   const handleGeneratedTitleChange = (event, productId) => {
//     const updatedProducts = products.map((product) =>
//       product.id === productId ? { ...product, generatedTitle: event.target.value } : product
//     );
//     setProducts(updatedProducts);
//   };

//   // AIによるタイトル生成（デフォルトプロンプト）
//   const generateProductTitles = async () => {
//     if (!apiKey) {
//       setError('OpenAI APIキーが設定されていません');
//       setSnackbarMessage('OpenAI APIキーが必要です');
//       setSnackbarSeverity('error');
//       setSnackbarOpen(true);
//       return;
//     }

//     const selectedProductsData = products.filter((product) => product.selected);
//     if (selectedProductsData.length === 0) {
//       setError('少なくとも一つの商品を選択してください');
//       setSnackbarMessage('商品が選択されていません');
//       setSnackbarSeverity('warning');
//       setSnackbarOpen(true);
//       return;
//     }

//     setLoading(true);
//     setError('');
//     setMessage('');
//     setProgress(0);

//     try {
//       const total = selectedProductsData.length;
//       let completed = 0;

//       const updatedProducts = [...products];

//       const generateTitleTasks = selectedProductsData.map((product) => async () => {
//         const index = products.findIndex((p) => p.id === product.id);
//         try {
//           const generatedTitle = await generateSingleTitle(
//             product.title,
//             product.editableJpDesc,
//             apiKey
//           );
//           updatedProducts[index].generatedTitle = generatedTitle;
//           completed++;
//           setProgress(Math.round((completed / total) * 100));
//         } catch (err) {
//           console.error(`商品ID ${product.id} のタイトル生成エラー:`, err);
//           updatedProducts[index].generatedTitle = '';
//           setError(`タイトルの生成に失敗しました: ${err.message}`);
//         }
//       });

//       await queue.addAll(generateTitleTasks);

//       setProducts(updatedProducts);
//       setMessage('タイトルの生成が完了しました');
//       setSnackbarMessage('タイトルの生成が完了しました');
//       setSnackbarSeverity('success');
//       setSnackbarOpen(true);
//     } catch (err) {
//       console.error('タイトル生成エラー:', err);
//       setError(`タイトルの生成に失敗しました: ${err.message}`);
//       setSnackbarMessage(`エラー: ${err.message}`);
//       setSnackbarSeverity('error');
//       setSnackbarOpen(true);
//     } finally {
//       setLoading(false);
//       setProgress(0);
//     }
//   };

//   // 低コストAIでタイトル生成
//   const handleLowCostAIGeneration = async () => {
//     if (!apiKey) {
//       setError('OpenAI APIキーが設定されていません');
//       setSnackbarMessage('OpenAI APIキーが必要です');
//       setSnackbarSeverity('error');
//       setSnackbarOpen(true);
//       return;
//     }

//     const selectedProductsData = products.filter((product) => product.selected);
//     if (selectedProductsData.length === 0) {
//       setError('少なくとも一つの商品を選択してください');
//       setSnackbarMessage('商品が選択されていません');
//       setSnackbarSeverity('warning');
//       setSnackbarOpen(true);
//       return;
//     }

//     setLowCostLoading(true);
//     setError('');
//     setMessage('');
//     setProgress(0);

//     try {
//       await generateLowCostTitles({
//         products,
//         setProducts,
//         apiKey,
//         setSnackbarMessage,
//         setSnackbarSeverity,
//         setSnackbarOpen,
//         setError,
//         setMessage,
//         setProgress,
//         queue,
//       });
//       setSnackbarMessage('低コストAIでのタイトル生成が完了しました');
//       setSnackbarSeverity('success');
//       setSnackbarOpen(true);
//     } catch (error) {
//       console.error('低コストAI生成エラー:', error);
//       setError(`低コストAI生成に失敗しました: ${error.message}`);
//       setSnackbarMessage(`エラー: ${error.message}`);
//       setSnackbarSeverity('error');
//       setSnackbarOpen(true);
//     } finally {
//       setLowCostLoading(false);
//       setProgress(0);
//     }
//   };

//   // 単一の商品タイトルを生成
//   async function generateSingleTitle(originalTitle, jpDesc, apiKey) {
//     const cacheKey = originalTitle + jpDesc.slice(0, 150);
//     const cachedTitle = getCachedTitle(cacheKey);
//     if (cachedTitle) {
//       return cachedTitle;
//     }

//     try {
//       const url = 'https://api.openai.com/v1/chat/completions';
//       const limitedJpDesc = jpDesc.slice(0, 150);
//       const requestData = {
//         model: 'gpt-4o-2024-08-06',
//         messages: [
//           {
//             role: 'system',
//             content: `You are an AI assistant specializing in eBay product title optimization. Based on the given original title and Japanese product information, create an effective English title within 80 characters.
// Order of title composition:
// 1. brand name (if applicable)
// 2. model name or product name/character name (if applicable)
// 3. product type (if applicable)
// 4. key features (color, material, size) (if applicable)
// 5. distinctive elements or uniqueness (if applicable)
// 6. condition (new/used) (if applicable)
// 7. important keywords (if applicable)
// NOTES:
// - Add or optimize new information while retaining important information from the original title
// - Avoid unnecessary adjectives and words such as “eBay
// - Use abbreviations and common names appropriately
// - Think from the searcher's perspective and include search terms that buyers are likely to use
// - If information is unclear or ambiguous, omit it.
// - Use only reliable information and do not include guesswork or uncertain information.
// - Do not extract from Japanese descriptions that are a list of words.
// - Titles should be generated in English and should not exceed 80 characters.`,
//           },
//           {
//             role: 'user',
//             content: `元のタイトル：「${originalTitle}」
// 日本語の商品説明：「${limitedJpDesc}」
// この情報を基に、最適化された新しいeBayの商品タイトルを生成してください。また日本語の商品説明が空の場合は元のタイトルから生成してください`,
//           },
//         ],
//         max_tokens: 100,
//       };

//       const response = await axios.post(url, requestData, {
//         headers: { Authorization: `Bearer ${apiKey}` },
//       });

//       const generatedTitle = response.data.choices[0].message.content.trim();
//       setCachedTitle(cacheKey, generatedTitle);
//       return generatedTitle;
//     } catch (error) {
//       throw error;
//     }
//   }

//   // 生成されたタイトルをデータに反映
//   const applyUpdatedTitles = () => {
//     const updatedProducts = products.map((product) => {
//       if (product.selected && product.generatedTitle) {
//         const updatedProduct = { ...data[product.id], Title: product.generatedTitle };
//         return updatedProduct;
//       }
//       return data[product.id];
//     });

//     setData(updatedProducts);
//     setSnackbarMessage('生成されたタイトルをデータに反映しました');
//     setSnackbarSeverity('success');
//     setSnackbarOpen(true);
//     setOpen(false);
//   };

//   // カテゴリーの変更ハンドラー
//   const handleCategoryChange = (event) => {
//     const selectedName = event.target.value;
//     setSelectedCategory(selectedName);

//     if (selectedName) {
//       const category = customCategories.find((cat) => cat.name === selectedName);
//       if (category) {
//         setDeleteStrings(category.deleteStrings || []);
//         setReplacePairs(category.replacePairs || []);
//         setPrependText(category.prependText || '');
//         setAppendText(category.appendText || '');
//         setLimitTitleLength(category.limitTitleLength || false);
//         setCategoryName(category.name);
//       }
//     } else {
//       // 新しいカテゴリーを作成する場合、初期状態にリセット
//       resetCustomizationSettings();
//     }
//   };

//   // カスタマイズ設定を初期状態にリセット
//   const resetCustomizationSettings = () => {
//     setDeleteStrings([]);
//     setReplacePairs([]);
//     setPrependText('');
//     setAppendText('');
//     setLimitTitleLength(false);
//     setCategoryName('');
//     setSelectedCategory('');
//   };

//   // 新しいカテゴリーを作成
//   const handleCreateNewCategory = () => {
//     // カテゴリー選択をクリアし、初期状態にリセット
//     resetCustomizationSettings();
//   };

//   // カテゴリーを保存または更新する
//   const saveOrUpdateCategory = () => {
//     if (!categoryName.trim()) {
//       setSnackbarMessage('カテゴリー名を入力してください');
//       setSnackbarSeverity('warning');
//       setSnackbarOpen(true);
//       return;
//     }

//     const newCategory = {
//       name: categoryName.trim(),
//       deleteStrings,
//       replacePairs,
//       prependText,
//       appendText,
//       limitTitleLength,
//     };

//     let updatedCategories;
//     if (selectedCategory) {
//       // 既存のカテゴリーを更新
//       updatedCategories = customCategories.map((cat) =>
//         cat.name === selectedCategory ? newCategory : cat
//       );
//       setSnackbarMessage('カテゴリーを更新しました');
//     } else {
//       // 新しいカテゴリーを追加
//       updatedCategories = [...customCategories, newCategory];
//       setSnackbarMessage('新しいカテゴリーを保存しました');
//     }

//     setCustomCategories(updatedCategories);
//     if (user) {
//       saveCategoriesToFirestore(user.uid, updatedCategories);
//     }
//     setSnackbarSeverity('success');
//     setSnackbarOpen(true);
//     setSelectedCategory(newCategory.name);
//   };

//   // カテゴリーを削除する
//   const deleteCategory = () => {
//     if (!selectedCategory) return;

//     const updatedCategories = customCategories.filter((cat) => cat.name !== selectedCategory);
//     setCustomCategories(updatedCategories);
//     if (user) {
//       saveCategoriesToFirestore(user.uid, updatedCategories);
//     }
//     resetCustomizationSettings();
//     setSnackbarMessage('カテゴリーを削除しました');
//     setSnackbarSeverity('info');
//     setSnackbarOpen(true);
//   };

//   // タイトルカスタマイズの適用
//   const customizeTitles = () => {
//     const updatedProducts = products.map((product) => {
//       if (product.selected && product.generatedTitle) {
//         let newTitle = product.generatedTitle;

//         // 複数の削除文字列を処理
//         deleteStrings.forEach((deleteStr) => {
//           if (deleteStr) {
//             const regex = new RegExp(deleteStr, 'g');
//             newTitle = newTitle.replace(regex, '');
//           }
//         });

//         // 複数の置換ペアを処理
//         replacePairs.forEach((pair) => {
//           const { find, replace } = pair;
//           if (find) {
//             const regex = new RegExp(find, 'g');
//             newTitle = newTitle.replace(regex, replace);
//           }
//         });

//         // 先頭と末尾の追加文字列を処理
//         if (prependText) {
//           newTitle = `${prependText} ${newTitle}`;
//         }
//         if (appendText) {
//           newTitle = `${newTitle} ${appendText}`;
//         }

//         // タイトルを80文字に制限
//         if (limitTitleLength) {
//           newTitle = newTitle.slice(0, 79);
//         }

//         return { ...product, generatedTitle: newTitle };
//       }
//       return product;
//     });
//     setProducts(updatedProducts);
//   };

//   // キャッシュをクリア
//   const handleClearTitleCache = () => {
//     clearTitleCache();
//     setSnackbarMessage('キャッシュをクリアしました');
//     setSnackbarSeverity('info');
//     setSnackbarOpen(true);
//   };

//   // 削除文字列のチップ関連の関数
//   const handleDeleteStringKeyPress = (event) => {
//     if (event.key === 'Enter' && event.target.value.trim() !== '') {
//       setDeleteStrings([...deleteStrings, event.target.value.trim()]);
//       event.target.value = '';
//     }
//   };

//   const handleDeleteChip = (chipToDelete) => {
//     setDeleteStrings((chips) => chips.filter((chip) => chip !== chipToDelete));
//   };

//   // 置換ペア関連の関数
//   const handleAddReplacePair = () => {
//     setReplacePairs([...replacePairs, { find: '', replace: '' }]);
//   };

//   const handleReplacePairChange = (index, field, value) => {
//     const newReplacePairs = [...replacePairs];
//     newReplacePairs[index][field] = value;
//     setReplacePairs(newReplacePairs);
//   };

//   const handleRemoveReplacePair = (index) => {
//     const newReplacePairs = replacePairs.filter((_, i) => i !== index);
//     setReplacePairs(newReplacePairs);
//   };

//   // 商品説明の編集
//   const handleEditJpDesc = (productId, newDesc) => {
//     const updatedProducts = products.map((product) =>
//       product.id === productId ? { ...product, editableJpDesc: newDesc } : product
//     );
//     setProducts(updatedProducts);
//   };

//   const handleOpen = () => {
//     setOpen(true);
//   };

//   const handleClose = () => {
//     setOpen(false);
//   };

//   // 生成されたタイトルを更新するための関数
//   const updateProducts = (updatedProducts) => {
//     const newProducts = products.map((product) => {
//       const updatedProduct = updatedProducts.find((p) => p.id === product.id);
//       return updatedProduct ? updatedProduct : product;
//     });
//     setProducts(newProducts);
//   };

//   // itemsPerPageが変更されたときにcurrentPageを1にリセット
//   const handleItemsPerPageChange = (e) => {
//     setItemsPerPage(e.target.value);
//     setCurrentPage(1);
//   };

//   return (
//     <Paper
//       elevation={3}
//       sx={{
//         p: 4,
//         mt: 1,
//         mb: 1,
//         maxWidth: '100%',
//         margin: '0 auto',
//         overflow: 'hidden',
//       }}
//     >
//       <Box sx={{ maxWidth: '1600px', margin: '0 auto' }}>
//         {/* ヘッダーセクション */}
//         <Grid container spacing={3} alignItems="center">
//           <Grid item xs={12} md={6}>
//             <Typography variant="h5" gutterBottom>
//               AIを活用したタイトル生成
//             </Typography>
//             <Typography variant="body1" paragraph>
//               日本語の商品説明から英語の商品タイトルを自動生成します。
//             </Typography>
//           </Grid>
//           <Grid item xs={12} md={6}>
//             <Typography variant="subtitle1" gutterBottom>
//               主な機能：
//             </Typography>
//             <Grid container spacing={1}>
//               {[
//                 '日本語の商品説明から英語の商品タイトルを生成',
//                 'タイトルの一括編集とカスタマイズ',
//               ].map((feature, index) => (
//                 <Grid item xs={6} key={index}>
//                   <Typography variant="body2">• {feature}</Typography>
//                 </Grid>
//               ))}
//             </Grid>
//           </Grid>
//         </Grid>

//         {/* アクションボタンセクション */}
//         <Box sx={{ mt: 3, display: 'flex', gap: '10px', justifyContent: 'center' }}>
//           <Button variant="contained" color="primary" onClick={handleOpen} startIcon={<Add />}>
//             タイトルをAIで生成する
//           </Button>
//           <Button
//             variant="outlined"
//             color="primary"
//             onClick={handleClearTitleCache}
//             startIcon={<FormatColorResetIcon />}
//           >
//             キャッシュをクリア
//           </Button>
//         </Box>

//         {/* ダイアログセクション */}
//         <Dialog open={open} onClose={handleClose} fullScreen>
//           <DialogTitle>AIでタイトルを生成する</DialogTitle>
//           <DialogContent>
//             {/* レイアウトを左右に分割 */}
//             <Grid container spacing={2}>
//               {/* 左側のセクション */}
//               <Grid
//                 item
//                 xs={12}
//                 md={2}
//                 sx={{
//                   maxHeight: 'calc(100vh - 32px)',
//                   overflowY: 'auto',
//                 }}
//               >
//                 {/* カテゴリー選択とカスタマイズ */}
//                 <Typography variant="h6">タイトルカスタマイズ</Typography>
//                 <Grid container spacing={2}>
//                   <Grid item xs={12}>
//                     <FormControl fullWidth margin="normal">
//                       <InputLabel id="category-select-label">カテゴリーを選択</InputLabel>
//                       <Select
//                         labelId="category-select-label"
//                         value={selectedCategory}
//                         onChange={handleCategoryChange}
//                       >
//                         {customCategories.map((cat) => (
//                           <MenuItem key={cat.name} value={cat.name}>
//                             {cat.name}
//                           </MenuItem>
//                         ))}
//                       </Select>
//                     </FormControl>
//                   </Grid>
//                   <Grid item xs={12}>
//                     <TextField
//                       label="カテゴリー名"
//                       fullWidth
//                       margin="normal"
//                       value={categoryName}
//                       onChange={(e) => setCategoryName(e.target.value)}
//                       placeholder="新しいカテゴリー名を入力"
//                     />
//                     <Button
//                       variant="outlined"
//                       color="primary"
//                       onClick={handleCreateNewCategory}
//                       sx={{ mt: 1 }}
//                     >
//                       新しいカテゴリーを作成
//                     </Button>
//                   </Grid>

//                   {/* トグルスイッチを追加 */}
//                   <Grid item xs={12} style={{ marginTop: '20px' }}>
//                     <FormControlLabel
//                       control={
//                         <Switch
//                           checked={useImageDescription}
//                           onChange={(e) => setUseImageDescription(e.target.checked)}
//                           name="useImageDescription"
//                           color="primary"
//                         />
//                       }
//                       label="画像解析の説明を使用"
//                     />
//                   </Grid>

//                   {/* 削除する文字列 */}
//                   <Grid item xs={12}>
//                     <Typography variant="subtitle1">削除する文字列</Typography>
//                     <TextField
//                       placeholder="削除したい文字列を入力し、Enterキーを押してください"
//                       onKeyPress={handleDeleteStringKeyPress}
//                       fullWidth
//                     />
//                     <Box sx={{ mt: 1, display: 'flex', flexWrap: 'wrap', gap: 1 }}>
//                       {deleteStrings.map((string, index) => (
//                         <Chip key={index} label={string} onDelete={() => handleDeleteChip(string)} />
//                       ))}
//                     </Box>
//                   </Grid>
//                   {/* 置換ペア */}
//                   <Grid item xs={12}>
//                     <Typography variant="subtitle1">置換ペア</Typography>
//                     {replacePairs.map((pair, index) => (
//                       <Box
//                         key={index}
//                         sx={{ display: 'flex', gap: 1, alignItems: 'center', mt: 1 }}
//                       >
//                         <TextField
//                           label="置換する文字列"
//                           value={pair.find}
//                           onChange={(e) => handleReplacePairChange(index, 'find', e.target.value)}
//                         />
//                         <TextField
//                           label="置換後の文字列"
//                           value={pair.replace}
//                           onChange={(e) => handleReplacePairChange(index, 'replace', e.target.value)}
//                         />
//                         <IconButton color="secondary" onClick={() => handleRemoveReplacePair(index)}>
//                           <Delete />
//                         </IconButton>
//                       </Box>
//                     ))}
//                     <Button
//                       variant="outlined"
//                       startIcon={<Add />}
//                       onClick={handleAddReplacePair}
//                       sx={{ mt: 2 }}
//                     >
//                       置換ペアを追加
//                     </Button>
//                   </Grid>
//                   {/* 先頭・末尾に追加する文字列 */}
//                   <Grid item xs={12}>
//                     <TextField
//                       label="先頭に追加する文字列"
//                       fullWidth
//                       margin="normal"
//                       value={prependText}
//                       onChange={(e) => setPrependText(e.target.value)}
//                       helperText="タイトルの先頭に追加します"
//                     />
//                     <TextField
//                       label="末尾に追加する文字列"
//                       fullWidth
//                       margin="normal"
//                       value={appendText}
//                       onChange={(e) => setAppendText(e.target.value)}
//                       helperText="タイトルの末尾に追加します"
//                     />
//                   </Grid>
//                   <Grid item xs={12}>
//                     <FormControlLabel
//                       control={
//                         <Switch
//                           checked={limitTitleLength}
//                           onChange={(e) => setLimitTitleLength(e.target.checked)}
//                         />
//                       }
//                       label="80文字に制限する"
//                     />
//                   </Grid>
//                   {/* カテゴリーの保存・更新・削除ボタン */}
//                   <Grid item xs={12}>
//                     <Button
//                       variant="contained"
//                       color="primary"
//                       onClick={saveOrUpdateCategory}
//                       disabled={!categoryName.trim()}
//                       fullWidth
//                     >
//                       {selectedCategory ? 'カテゴリーを更新' : 'カテゴリーを保存'}
//                     </Button>
//                     {selectedCategory && (
//                       <Button
//                         variant="outlined"
//                         color="secondary"
//                         onClick={deleteCategory}
//                         fullWidth
//                         sx={{ mt: 1 }}
//                       >
//                         カテゴリーを削除
//                       </Button>
//                     )}
//                   </Grid>
//                   {/* タイトルカスタマイズの適用ボタン */}
//                   <Grid item xs={12}>
//                     <Button
//                       variant="contained"
//                       color="secondary"
//                       onClick={customizeTitles}
//                       fullWidth
//                       sx={{ mt: 2 }}
//                     >
//                       タイトルカスタマイズを適用
//                     </Button>
//                   </Grid>
//                   {/* 表示件数選択コンポーネントを追加 */}
//                   <Grid item xs={12}>
//                     <FormControl variant="outlined" size="small" fullWidth sx={{ mt: 2 }}>
//                       <InputLabel id="items-per-page-label">表示件数</InputLabel>
//                       <Select
//                         labelId="items-per-page-label"
//                         value={itemsPerPage}
//                         onChange={handleItemsPerPageChange}
//                         label="表示件数"
//                       >
//                         {ITEMS_PER_PAGE_OPTIONS.map((option, index) => (
//                           <MenuItem key={index} value={option}>
//                             {option === 'All' ? '全て' : `${option}件ずつ`}
//                           </MenuItem>
//                         ))}
//                       </Select>
//                     </FormControl>
//                   </Grid>
//                 </Grid>
//               </Grid>

//               {/* 右側のセクション */}
//               <Grid
//                 item
//                 xs={12}
//                 md={10}
//                 sx={{
//                   maxHeight: 'calc(100vh - 32px)',
//                   overflowY: 'auto',
//                 }}
//               >
//                 {/* アクションボタン */}
//                 <Grid container spacing={2} style={{ marginBottom: '20px' }}>
//                   <Grid item>
//                     <Button
//                       variant="contained"
//                       color="primary"
//                       onClick={generateProductTitles}
//                       disabled={loading || !apiKey}
//                     >
//                       高精度AI生成をする
//                     </Button>
//                   </Grid>
//                   <Grid item>
//                     <Button
//                       variant="contained"
//                       color="primary"
//                       onClick={handleLowCostAIGeneration}
//                       startIcon={<MoneyOffIcon />}
//                       disabled={lowCostLoading || !apiKey}
//                     >
//                       低コストAI生成をする
//                     </Button>
//                   </Grid>
//                   <Grid item>
//                     <Button
//                       variant="contained"
//                       color="secondary"
//                       onClick={applyUpdatedTitles}
//                       disabled={loading || lowCostLoading}
//                     >
//                       タイトルをデータに反映する
//                     </Button>
//                   </Grid>
//                   <Grid item>
//                     <Button
//                       variant="contained"
//                       color="success"
//                       onClick={() => setCustomPromptDialogOpen(true)}
//                       startIcon={<EditIcon />}
//                       disabled={loading || lowCostLoading}
//                     >
//                       カスタムプロンプトで生成
//                     </Button>
//                   </Grid>
//                 </Grid>

//                 {/* 進捗状況表示 */}
//                 {(loading || lowCostLoading) && (
//                   <Box sx={{ width: '100%', mb: 2 }}>
//                     <LinearProgress variant="determinate" value={progress} />
//                     <Typography variant="body2" color="text.secondary" align="center">
//                       {`${Math.round(progress)}%`}
//                     </Typography>
//                   </Box>
//                 )}

//                 {/* エラーメッセージ */}
//                 {error && (
//                   <Typography color="error" style={{ marginTop: '20px' }}>
//                     {error}
//                   </Typography>
//                 )}
//                 {/* 成功メッセージ */}
//                 {message && (
//                   <Typography color="primary" style={{ marginTop: '20px' }}>
//                     {message}
//                   </Typography>
//                 )}

//                 {/* 商品テーブル */}
//                 <TableContainer component={Paper}>
//                   <Table stickyHeader aria-label="products-table">
//                     <TableHead>
//                       <TableRow>
//                         <TableCell padding="checkbox">
//                           <Checkbox
//                             checked={products.every((product) => product.selected)}
//                             onChange={handleSelectAll}
//                             indeterminate={
//                               products.some((product) => product.selected) &&
//                               !products.every((product) => product.selected)
//                             }
//                           />
//                         </TableCell>
//                         <TableCell>商品タイトル</TableCell>
//                         <TableCell>日本語の商品説明</TableCell>
//                         <TableCell>生成されたタイトル</TableCell>
//                       </TableRow>
//                     </TableHead>
//                     <TableBody>
//                       {displayedProducts.map((product) => (
//                         <TableRow key={product.id} hover>
//                           <TableCell padding="checkbox">
//                             <Checkbox
//                               checked={product.selected}
//                               onChange={() => handleSelectProduct(product.id)}
//                               onClick={(e) => e.stopPropagation()}
//                             />
//                           </TableCell>
//                           <TableCell
//                             sx={{
//                               fontSize: '0.8rem',
//                               whiteSpace: 'pre-wrap',
//                               wordWrap: 'break-word',
//                               maxWidth: '300px',
//                               padding: '4px',
//                             }}
//                           >
//                             {product.title}
//                           </TableCell>
//                           <TableCell
//                             sx={{
//                               padding: '4px',
//                             }}
//                           >
//                             <TextField
//                               multiline
//                               fullWidth
//                               variant="outlined"
//                               value={product.editableJpDesc}
//                               onChange={(e) => handleEditJpDesc(product.id, e.target.value)}
//                               onFocus={() => setFocusedProductId(product.id)}
//                               onBlur={() => setFocusedProductId(null)}
//                               InputProps={{
//                                 sx: {
//                                   textarea: {
//                                     maxHeight: focusedProductId === product.id ? 'none' : '8em',
//                                     overflow: 'auto',
//                                     transition: 'max-height 0.3s ease',
//                                   },
//                                 },
//                               }}
//                               inputProps={{
//                                 style: {
//                                   fontSize: '0.8rem',
//                                   padding: '2px',
//                                   lineHeight: '1.2',
//                                 },
//                               }}
//                             />
//                           </TableCell>
//                           <TableCell
//                             sx={{
//                               padding: '4px',
//                             }}
//                           >
//                             <TextField
//                               multiline
//                               fullWidth
//                               variant="outlined"
//                               value={product.generatedTitle}
//                               onChange={(event) => handleGeneratedTitleChange(event, product.id)}
//                               inputProps={{
//                                 style: {
//                                   fontSize: '0.8rem',
//                                   padding: '2px',
//                                   lineHeight: '1.2',
//                                 },
//                               }}
//                               sx={{
//                                 margin: 0,
//                               }}
//                             />
//                           </TableCell>
//                         </TableRow>
//                       ))}
//                     </TableBody>
//                   </Table>
//                 </TableContainer>

//                 {/* ページネーション */}
//                 {itemsPerPage !== 'All' && (
//                   <Pagination
//                     count={totalPages}
//                     page={currentPage}
//                     onChange={handlePageChange}
//                     color="primary"
//                     style={{ marginTop: '20px', display: 'flex', justifyContent: 'center' }}
//                   />
//                 )}
//               </Grid>
//             </Grid>
//           </DialogContent>
//           <DialogActions>
//             <Button onClick={handleClose}>閉じる</Button>
//           </DialogActions>
//         </Dialog>

//         {/* カスタムプロンプトダイアログ */}
//         <CustomPromptDialog
//           open={customPromptDialogOpen}
//           onClose={() => setCustomPromptDialogOpen(false)}
//           apiKey={apiKey}
//           selectedProducts={products.filter((product) => product.selected)}
//           updateProducts={updateProducts}
//         />

//         {/* スナックバー通知 */}
//         <Snackbar
//           anchorOrigin={{
//             vertical: 'bottom',
//             horizontal: 'left',
//           }}
//           open={snackbarOpen}
//           autoHideDuration={6000}
//           onClose={() => setSnackbarOpen(false)}
//         >
//           <Alert
//             onClose={() => setSnackbarOpen(false)}
//             severity={snackbarSeverity}
//             sx={{ width: '100%' }}
//           >
//             {snackbarMessage}
//           </Alert>
//         </Snackbar>
//       </Box>
//     </Paper>
//   );
// };

// export default TitleGenerationComponent;






// // TitleGenerationComponent.js

// import React, { useState, useEffect } from 'react';
// import axios from 'axios';
// import {
//   Button,
//   Typography,
//   TextField,
//   Checkbox,
//   Table,
//   TableBody,
//   TableCell,
//   TableContainer,
//   TableHead,
//   TableRow,
//   Paper,
//   Grid,
//   Snackbar,
//   Alert,
//   Box,
//   LinearProgress,
//   FormControl,
//   Select,
//   MenuItem,
//   InputLabel,
//   FormControlLabel,
//   Switch,
//   Chip,
//   IconButton,
//   Pagination,
//   Dialog,
//   DialogTitle,
//   DialogContent,
//   DialogActions,
// } from '@mui/material';
// import {
//   Add,
//   Delete,
//   FormatColorReset as FormatColorResetIcon,
//   Edit as EditIcon,
//   MoneyOff as MoneyOffIcon,
// } from '@mui/icons-material';
// import PQueue from 'p-queue';
// import CustomPromptDialog from './CustomPromptDialog';
// import {
//   loadCacheFromLocalStorage,
//   saveCacheToLocalStorage,
//   getCachedTitle,
//   setCachedTitle,
//   clearTitleCache,
// } from './titleCache';
// import { db } from './firebaseConfig'; // Firestoreのインポート
// import { doc, setDoc, getDoc } from 'firebase/firestore'; // Firestoreの関数をインポート
// import LowCostTitleGenerator, { generateProductTitles as generateLowCostTitles } from './LowCostTitleGenerator';

// // 定数の定義
// const ITEMS_PER_PAGE_OPTIONS = [10, 25, 50, 'All'];

// // コンポーネントの定義
// const TitleGenerationComponent = ({ data, setData, apiKey, user }) => {
//   // 状態管理のフック
//   const [open, setOpen] = useState(false);
//   const [products, setProducts] = useState([]);
//   const [displayedProducts, setDisplayedProducts] = useState([]);
//   const [currentPage, setCurrentPage] = useState(1);
//   const [totalPages, setTotalPages] = useState(1);
//   const [itemsPerPage, setItemsPerPage] = useState(10);
//   const [loading, setLoading] = useState(false);
//   const [lowCostLoading, setLowCostLoading] = useState(false); // 低コストAI生成用のローディング状態
//   const [progress, setProgress] = useState(0);
//   const [error, setError] = useState('');
//   const [message, setMessage] = useState('');
//   const [customPromptDialogOpen, setCustomPromptDialogOpen] = useState(false);
//   const [snackbarOpen, setSnackbarOpen] = useState(false);
//   const [snackbarMessage, setSnackbarMessage] = useState('');
//   const [snackbarSeverity, setSnackbarSeverity] = useState('info');
//   const [deleteStrings, setDeleteStrings] = useState([]);
//   const [replacePairs, setReplacePairs] = useState([]);
//   const [prependText, setPrependText] = useState('');
//   const [appendText, setAppendText] = useState('');
//   const [limitTitleLength, setLimitTitleLength] = useState(false);
//   const [categoryName, setCategoryName] = useState('');
//   const [customCategories, setCustomCategories] = useState([]);
//   const [selectedCategory, setSelectedCategory] = useState('');
//   const [focusedProductId, setFocusedProductId] = useState(null);

//   // 画像解析の説明を使用するかどうかの状態
//   const [useImageDescription, setUseImageDescription] = useState(false);

//   // キャッシュの読み込みとFirestoreからの設定取得
//   useEffect(() => {
//     loadCacheFromLocalStorage();
//     if (user) {
//       loadCategoriesFromFirestore(user.uid);
//     }
//   }, [user]);

//   // カスタムカテゴリーをFirestoreから読み込む
//   const loadCategoriesFromFirestore = async (uid) => {
//     try {
//       const docRef = doc(db, 'userTitleSettings', uid);
//       const docSnap = await getDoc(docRef);
//       if (docSnap.exists()) {
//         const data = docSnap.data();
//         setCustomCategories(data.customCategories || []);
//         setSnackbarMessage('カスタム設定をロードしました');
//         setSnackbarSeverity('success');
//         setSnackbarOpen(true);
//       } else {
//         console.log('No custom categories found in Firestore.');
//       }
//     } catch (error) {
//       console.error('Error loading categories from Firestore:', error);
//       setSnackbarMessage('カスタム設定のロードに失敗しました');
//       setSnackbarSeverity('error');
//       setSnackbarOpen(true);
//     }
//   };

//   // カスタムカテゴリーをFirestoreに保存
//   const saveCategoriesToFirestore = async (uid, categories) => {
//     try {
//       const docRef = doc(db, 'userTitleSettings', uid);
//       await setDoc(docRef, { customCategories: categories }, { merge: true });
//       setSnackbarMessage('カスタム設定を保存しました');
//       setSnackbarSeverity('success');
//       setSnackbarOpen(true);
//     } catch (error) {
//       console.error('Error saving categories to Firestore:', error);
//       setSnackbarMessage('カスタム設定の保存に失敗しました');
//       setSnackbarSeverity('error');
//       setSnackbarOpen(true);
//     }
//   };

//   // p-queueの設定
//   const CONCURRENCY = 2;
//   const INTERVAL = 1000;
//   const INTERVAL_CAP = 5;
//   const queue = new PQueue({
//     concurrency: CONCURRENCY,
//     interval: INTERVAL,
//     intervalCap: INTERVAL_CAP,
//   });

//   useEffect(() => {
//     if (open) {
//       initializeProducts();
//     }
//   }, [open, useImageDescription]); // useImageDescriptionを依存関係に追加

//   useEffect(() => {
//     const total = products.length;
//     if (itemsPerPage === 'All') {
//       setTotalPages(1);
//       setDisplayedProducts(products);
//     } else {
//       setTotalPages(Math.ceil(total / itemsPerPage));
//       updateDisplayedProducts(1);
//     }
//     setCurrentPage(1);
//   }, [products, itemsPerPage]);

//   const updateDisplayedProducts = (page) => {
//     if (itemsPerPage === 'All') {
//       setDisplayedProducts(products);
//       return;
//     }
//     const startIndex = (page - 1) * itemsPerPage;
//     const endIndex = startIndex + itemsPerPage;
//     setDisplayedProducts(products.slice(startIndex, endIndex));
//   };

//   const handlePageChange = (event, value) => {
//     setCurrentPage(value);
//     updateDisplayedProducts(value);
//   };

//   // データから商品リストを初期化
//   const initializeProducts = () => {
//     if (!data || data.length === 0) {
//       setError('データがありません。CSVファイルをインポートしてください。');
//       setSnackbarMessage('データがありません');
//       setSnackbarSeverity('error');
//       setSnackbarOpen(true);
//       return;
//     }

//     const headers = Object.keys(data[0]);
//     const titleIndex = headers.findIndex((header) => header.toLowerCase() === 'title');
//     const jpDescIndex = headers.findIndex((header) => header.toLowerCase() === 'jp_desc');
//     const jpImageDescIndex = headers.findIndex((header) => header.toLowerCase() === 'jp_image_description');

//     if (titleIndex === -1) {
//       setError('Titleカラムが見つかりません');
//       return;
//     }

//     const productData = data.map((row, index) => {
//       let jpDescValue = '';
//       if (useImageDescription) {
//         jpDescValue = row['jp_image_description'] || '';
//       } else {
//         jpDescValue = row['jp_desc'] || '';
//       }

//       return {
//         id: index,
//         title: row['Title'] || row['title'] || '',
//         jpDesc: jpDescValue,
//         generatedTitle: '',
//         selected: false,
//         editableJpDesc: jpDescValue,
//       };
//     });

//     setProducts(productData);
//   };

//   // 商品の選択ハンドラー
//   const handleSelectProduct = (productId) => {
//     const updatedProducts = products.map((product) =>
//       product.id === productId ? { ...product, selected: !product.selected } : product
//     );
//     setProducts(updatedProducts);
//     updateDisplayedProducts(currentPage);
//   };

//   // すべての商品を選択/解除
//   const handleSelectAll = () => {
//     const allSelected = products.every((product) => product.selected);
//     const updatedProducts = products.map((product) => ({
//       ...product,
//       selected: !allSelected,
//     }));
//     setProducts(updatedProducts);
//     updateDisplayedProducts(currentPage);
//   };

//   // 生成されたタイトルの編集ハンドラー
//   const handleGeneratedTitleChange = (event, productId) => {
//     const updatedProducts = products.map((product) =>
//       product.id === productId ? { ...product, generatedTitle: event.target.value } : product
//     );
//     setProducts(updatedProducts);
//     updateDisplayedProducts(currentPage);
//   };

//   // AIによるタイトル生成（デフォルトプロンプト）
//   const generateProductTitles = async () => {
//     if (!apiKey) {
//       setError('OpenAI APIキーが設定されていません');
//       setSnackbarMessage('OpenAI APIキーが必要です');
//       setSnackbarSeverity('error');
//       setSnackbarOpen(true);
//       return;
//     }

//     const selectedProductsData = products.filter((product) => product.selected);
//     if (selectedProductsData.length === 0) {
//       setError('少なくとも一つの商品を選択してください');
//       setSnackbarMessage('商品が選択されていません');
//       setSnackbarSeverity('warning');
//       setSnackbarOpen(true);
//       return;
//     }

//     setLoading(true);
//     setError('');
//     setMessage('');
//     setProgress(0);

//     try {
//       const total = selectedProductsData.length;
//       let completed = 0;

//       const updatedProducts = [...products];

//       const generateTitleTasks = selectedProductsData.map((product) => async () => {
//         const index = products.findIndex((p) => p.id === product.id);
//         try {
//           const generatedTitle = await generateSingleTitle(
//             product.title,
//             product.editableJpDesc,
//             apiKey
//           );
//           updatedProducts[index].generatedTitle = generatedTitle;
//           completed++;
//           setProgress(Math.round((completed / total) * 100));
//         } catch (err) {
//           console.error(`商品ID ${product.id} のタイトル生成エラー:`, err);
//           updatedProducts[index].generatedTitle = '';
//           setError(`タイトルの生成に失敗しました: ${err.message}`);
//         }
//       });

//       await queue.addAll(generateTitleTasks);

//       setProducts(updatedProducts);
//       setMessage('タイトルの生成が完了しました');
//       setSnackbarMessage('タイトルの生成が完了しました');
//       setSnackbarSeverity('success');
//       setSnackbarOpen(true);
//       updateDisplayedProducts(currentPage);
//     } catch (err) {
//       console.error('タイトル生成エラー:', err);
//       setError(`タイトルの生成に失敗しました: ${err.message}`);
//       setSnackbarMessage(`エラー: ${err.message}`);
//       setSnackbarSeverity('error');
//       setSnackbarOpen(true);
//     } finally {
//       setLoading(false);
//       setProgress(0);
//     }
//   };

//   // 低コストAIでタイトル生成
//   const handleLowCostAIGeneration = async () => {
//     if (!apiKey) {
//       setError('OpenAI APIキーが設定されていません');
//       setSnackbarMessage('OpenAI APIキーが必要です');
//       setSnackbarSeverity('error');
//       setSnackbarOpen(true);
//       return;
//     }

//     const selectedProductsData = products.filter((product) => product.selected);
//     if (selectedProductsData.length === 0) {
//       setError('少なくとも一つの商品を選択してください');
//       setSnackbarMessage('商品が選択されていません');
//       setSnackbarSeverity('warning');
//       setSnackbarOpen(true);
//       return;
//     }

//     setLowCostLoading(true);
//     setError('');
//     setMessage('');
//     setProgress(0);

//     try {
//       await generateLowCostTitles({
//         products,
//         setProducts,
//         apiKey,
//         setSnackbarMessage,
//         setSnackbarSeverity,
//         setSnackbarOpen,
//         setError,
//         setMessage,
//         setProgress,
//         queue,
//       });
//       setSnackbarMessage('低コストAIでのタイトル生成が完了しました');
//       setSnackbarSeverity('success');
//       setSnackbarOpen(true);
//       updateDisplayedProducts(currentPage);
//     } catch (error) {
//       console.error('低コストAI生成エラー:', error);
//       setError(`低コストAI生成に失敗しました: ${error.message}`);
//       setSnackbarMessage(`エラー: ${error.message}`);
//       setSnackbarSeverity('error');
//       setSnackbarOpen(true);
//     } finally {
//       setLowCostLoading(false);
//       setProgress(0);
//     }
//   };

//   // 単一の商品タイトルを生成
//   async function generateSingleTitle(originalTitle, jpDesc, apiKey) {
//     const cacheKey = originalTitle + jpDesc.slice(0, 150);
//     const cachedTitle = getCachedTitle(cacheKey);
//     if (cachedTitle) {
//       return cachedTitle;
//     }

//     try {
//       const url = 'https://api.openai.com/v1/chat/completions';
//       const limitedJpDesc = jpDesc.slice(0, 150);
//       const requestData = {
//         model: 'gpt-4o-2024-08-06',
//         messages: [
//           {
//             role: 'system',
//             content: `You are an AI assistant specializing in eBay product title optimization. Based on the given original title and Japanese product information, create an effective English title within 80 characters.
// Order of title composition:
// 1. brand name (if applicable)
// 2. model name or product name/character name (if applicable)
// 3. product type (if applicable)
// 4. key features (color, material, size) (if applicable)
// 5. distinctive elements or uniqueness (if applicable)
// 6. condition (new/used) (if applicable)
// 7. important keywords (if applicable)
// NOTES:
// - Add or optimize new information while retaining important information from the original title
// - Avoid unnecessary adjectives and words such as “eBay
// - Use abbreviations and common names appropriately
// - Think from the searcher's perspective and include search terms that buyers are likely to use
// - If information is unclear or ambiguous, omit it.
// - Use only reliable information and do not include guesswork or uncertain information.
// - Do not extract from Japanese descriptions that are a list of words.
// - Titles should be generated in English and should not exceed 80 characters.`,
//           },
//           {
//             role: 'user',
//             content: `元のタイトル：「${originalTitle}」
// 日本語の商品説明：「${limitedJpDesc}」
// この情報を基に、最適化された新しいeBayの商品タイトルを生成してください。また日本語の商品説明が空の場合は元のタイトルから生成してください`,
//           },
//         ],
//         max_tokens: 100,
//       };

//       const response = await axios.post(url, requestData, {
//         headers: { Authorization: `Bearer ${apiKey}` },
//       });

//       const generatedTitle = response.data.choices[0].message.content.trim();
//       setCachedTitle(cacheKey, generatedTitle);
//       return generatedTitle;
//     } catch (error) {
//       throw error;
//     }
//   }

//   // 生成されたタイトルをデータに反映
//   const applyUpdatedTitles = () => {
//     const updatedProducts = products.map((product) => {
//       if (product.selected && product.generatedTitle) {
//         const updatedProduct = { ...data[product.id], Title: product.generatedTitle };
//         return updatedProduct;
//       }
//       return data[product.id];
//     });

//     setData(updatedProducts);
//     setSnackbarMessage('生成されたタイトルをデータに反映しました');
//     setSnackbarSeverity('success');
//     setSnackbarOpen(true);
//     setOpen(false);
//   };

//   // カテゴリーの変更ハンドラー
//   const handleCategoryChange = (event) => {
//     const selectedName = event.target.value;
//     setSelectedCategory(selectedName);

//     if (selectedName) {
//       const category = customCategories.find((cat) => cat.name === selectedName);
//       if (category) {
//         setDeleteStrings(category.deleteStrings || []);
//         setReplacePairs(category.replacePairs || []);
//         setPrependText(category.prependText || '');
//         setAppendText(category.appendText || '');
//         setLimitTitleLength(category.limitTitleLength || false);
//         setCategoryName(category.name);
//       }
//     } else {
//       // 新しいカテゴリーを作成する場合、初期状態にリセット
//       resetCustomizationSettings();
//     }
//   };

//   // カスタマイズ設定を初期状態にリセット
//   const resetCustomizationSettings = () => {
//     setDeleteStrings([]);
//     setReplacePairs([]);
//     setPrependText('');
//     setAppendText('');
//     setLimitTitleLength(false);
//     setCategoryName('');
//     setSelectedCategory('');
//   };

//   // 新しいカテゴリーを作成
//   const handleCreateNewCategory = () => {
//     // カテゴリー選択をクリアし、初期状態にリセット
//     resetCustomizationSettings();
//   };

//   // カテゴリーを保存または更新する
//   const saveOrUpdateCategory = () => {
//     if (!categoryName.trim()) {
//       setSnackbarMessage('カテゴリー名を入力してください');
//       setSnackbarSeverity('warning');
//       setSnackbarOpen(true);
//       return;
//     }

//     const newCategory = {
//       name: categoryName.trim(),
//       deleteStrings,
//       replacePairs,
//       prependText,
//       appendText,
//       limitTitleLength,
//     };

//     let updatedCategories;
//     if (selectedCategory) {
//       // 既存のカテゴリーを更新
//       updatedCategories = customCategories.map((cat) =>
//         cat.name === selectedCategory ? newCategory : cat
//       );
//       setSnackbarMessage('カテゴリーを更新しました');
//     } else {
//       // 新しいカテゴリーを追加
//       updatedCategories = [...customCategories, newCategory];
//       setSnackbarMessage('新しいカテゴリーを保存しました');
//     }

//     setCustomCategories(updatedCategories);
//     if (user) {
//       saveCategoriesToFirestore(user.uid, updatedCategories);
//     }
//     setSnackbarSeverity('success');
//     setSnackbarOpen(true);
//     setSelectedCategory(newCategory.name);
//   };

//   // カテゴリーを削除する
//   const deleteCategory = () => {
//     if (!selectedCategory) return;

//     const updatedCategories = customCategories.filter((cat) => cat.name !== selectedCategory);
//     setCustomCategories(updatedCategories);
//     if (user) {
//       saveCategoriesToFirestore(user.uid, updatedCategories);
//     }
//     resetCustomizationSettings();
//     setSnackbarMessage('カテゴリーを削除しました');
//     setSnackbarSeverity('info');
//     setSnackbarOpen(true);
//   };

//   // タイトルカスタマイズの適用
//   const customizeTitles = () => {
//     const updatedProducts = products.map((product) => {
//       if (product.selected && product.generatedTitle) {
//         let newTitle = product.generatedTitle;

//         // 複数の削除文字列を処理
//         deleteStrings.forEach((deleteStr) => {
//           if (deleteStr) {
//             const regex = new RegExp(deleteStr, 'g');
//             newTitle = newTitle.replace(regex, '');
//           }
//         });

//         // 複数の置換ペアを処理
//         replacePairs.forEach((pair) => {
//           const { find, replace } = pair;
//           if (find) {
//             const regex = new RegExp(find, 'g');
//             newTitle = newTitle.replace(regex, replace);
//           }
//         });

//         // 先頭と末尾の追加文字列を処理
//         if (prependText) {
//           newTitle = `${prependText} ${newTitle}`;
//         }
//         if (appendText) {
//           newTitle = `${newTitle} ${appendText}`;
//         }

//         // タイトルを80文字に制限
//         if (limitTitleLength) {
//           newTitle = newTitle.slice(0, 79);
//         }

//         return { ...product, generatedTitle: newTitle };
//       }
//       return product;
//     });
//     setProducts(updatedProducts);
//     updateDisplayedProducts(currentPage);

//     setSnackbarMessage('タイトルをカスタマイズしました');
//     setSnackbarSeverity('success');
//     setSnackbarOpen(true);
//   };

//   // キャッシュをクリア
//   const handleClearTitleCache = () => {
//     clearTitleCache();
//     setSnackbarMessage('キャッシュをクリアしました');
//     setSnackbarSeverity('info');
//     setSnackbarOpen(true);
//   };

//   // 削除文字列のチップ関連の関数
//   const handleDeleteStringKeyPress = (event) => {
//     if (event.key === 'Enter' && event.target.value.trim() !== '') {
//       setDeleteStrings([...deleteStrings, event.target.value.trim()]);
//       event.target.value = '';
//     }
//   };

//   const handleDeleteChip = (chipToDelete) => {
//     setDeleteStrings((chips) => chips.filter((chip) => chip !== chipToDelete));
//   };

//   // 置換ペア関連の関数
//   const handleAddReplacePair = () => {
//     setReplacePairs([...replacePairs, { find: '', replace: '' }]);
//   };

//   const handleReplacePairChange = (index, field, value) => {
//     const newReplacePairs = [...replacePairs];
//     newReplacePairs[index][field] = value;
//     setReplacePairs(newReplacePairs);
//   };

//   const handleRemoveReplacePair = (index) => {
//     const newReplacePairs = replacePairs.filter((_, i) => i !== index);
//     setReplacePairs(newReplacePairs);
//   };

//   // 商品説明の編集
//   const handleEditJpDesc = (productId, newDesc) => {
//     const updatedProducts = products.map((product) =>
//       product.id === productId ? { ...product, editableJpDesc: newDesc } : product
//     );
//     setProducts(updatedProducts);
//     updateDisplayedProducts(currentPage);
//   };

//   const handleOpen = () => {
//     setOpen(true);
//   };

//   const handleClose = () => {
//     setOpen(false);
//   };

//   // 生成されたタイトルを更新するための関数
//   const updateProducts = (updatedProducts) => {
//     const newProducts = products.map((product) => {
//       const updatedProduct = updatedProducts.find((p) => p.id === product.id);
//       return updatedProduct ? updatedProduct : product;
//     });
//     setProducts(newProducts);
//     updateDisplayedProducts(currentPage);
//   };

//   return (
//     <Paper
//       elevation={3}
//       sx={{
//         p: 4,
//         mt: 1,
//         mb: 1,
//         maxWidth: '100%',
//         margin: '0 auto',
//         overflow: 'hidden',
//       }}
//     >
//       <Box sx={{ maxWidth: '1600px', margin: '0 auto' }}>
//         {/* ヘッダーセクション */}
//         <Grid container spacing={3} alignItems="center">
//           <Grid item xs={12} md={6}>
//             <Typography variant="h5" gutterBottom>
//               AIを活用したタイトル生成
//             </Typography>
//             <Typography variant="body1" paragraph>
//               日本語の商品説明から英語の商品タイトルを自動生成します。
//             </Typography>
//           </Grid>
//           <Grid item xs={12} md={6}>
//             <Typography variant="subtitle1" gutterBottom>
//               主な機能：
//             </Typography>
//             <Grid container spacing={1}>
//               {[
//                 '日本語の商品説明から英語の商品タイトルを生成',
//                 'タイトルの一括編集とカスタマイズ',
//               ].map((feature, index) => (
//                 <Grid item xs={6} key={index}>
//                   <Typography variant="body2">• {feature}</Typography>
//                 </Grid>
//               ))}
//             </Grid>
//           </Grid>
//         </Grid>

//         {/* アクションボタンセクション */}
//         <Box sx={{ mt: 3, display: 'flex', gap: '10px', justifyContent: 'center' }}>
//           <Button variant="contained" color="primary" onClick={handleOpen} startIcon={<Add />}>
//             タイトルをAIで生成する
//           </Button>
//           <Button
//             variant="outlined"
//             color="primary"
//             onClick={handleClearTitleCache}
//             startIcon={<FormatColorResetIcon />}
//           >
//             キャッシュをクリア
//           </Button>
//         </Box>

//         {/* ダイアログセクション */}
//         <Dialog open={open} onClose={handleClose} fullScreen>
//           <DialogTitle>AIでタイトルを生成する</DialogTitle>
//           <DialogContent>
//             {/* レイアウトを左右に分割 */}
//             <Grid container spacing={2}>
//               {/* 左側のセクション */}
//               <Grid
//                 item
//                 xs={12}
//                 md={2}
//                 sx={{
//                   maxHeight: 'calc(100vh - 32px)',
//                   overflowY: 'auto',
//                 }}
//               >
//                 {/* カテゴリー選択とカスタマイズ */}
//                 <Typography variant="h6">タイトルカスタマイズ</Typography>
//                 <Grid container spacing={2}>
//                   <Grid item xs={12}>
//                     <FormControl fullWidth margin="normal">
//                       <InputLabel id="category-select-label">カテゴリーを選択</InputLabel>
//                       <Select
//                         labelId="category-select-label"
//                         value={selectedCategory}
//                         onChange={handleCategoryChange}
//                       >
//                         {customCategories.map((cat) => (
//                           <MenuItem key={cat.name} value={cat.name}>
//                             {cat.name}
//                           </MenuItem>
//                         ))}
//                       </Select>
//                     </FormControl>
//                   </Grid>
//                   <Grid item xs={12}>
//                     <TextField
//                       label="カテゴリー名"
//                       fullWidth
//                       margin="normal"
//                       value={categoryName}
//                       onChange={(e) => setCategoryName(e.target.value)}
//                       placeholder="新しいカテゴリー名を入力"
//                     />
//                     <Button
//                       variant="outlined"
//                       color="primary"
//                       onClick={handleCreateNewCategory}
//                       sx={{ mt: 1 }}
//                     >
//                       新しいカテゴリーを作成
//                     </Button>
//                   </Grid>

//                   {/* トグルスイッチを追加 */}
//                   <Grid item xs={12} style={{ marginTop: '20px' }}>
//                     <FormControlLabel
//                       control={
//                         <Switch
//                           checked={useImageDescription}
//                           onChange={(e) => setUseImageDescription(e.target.checked)}
//                           name="useImageDescription"
//                           color="primary"
//                         />
//                       }
//                       label="画像解析の説明を使用"
//                     />
//                   </Grid>

//                   {/* 削除する文字列 */}
//                   <Grid item xs={12}>
//                     <Typography variant="subtitle1">削除する文字列</Typography>
//                     <TextField
//                       placeholder="削除したい文字列を入力し、Enterキーを押してください"
//                       onKeyPress={handleDeleteStringKeyPress}
//                       fullWidth
//                     />
//                     <Box sx={{ mt: 1, display: 'flex', flexWrap: 'wrap', gap: 1 }}>
//                       {deleteStrings.map((string, index) => (
//                         <Chip key={index} label={string} onDelete={() => handleDeleteChip(string)} />
//                       ))}
//                     </Box>
//                   </Grid>
//                   {/* 置換ペア */}
//                   <Grid item xs={12}>
//                     <Typography variant="subtitle1">置換ペア</Typography>
//                     {replacePairs.map((pair, index) => (
//                       <Box
//                         key={index}
//                         sx={{ display: 'flex', gap: 1, alignItems: 'center', mt: 1 }}
//                       >
//                         <TextField
//                           label="置換する文字列"
//                           value={pair.find}
//                           onChange={(e) => handleReplacePairChange(index, 'find', e.target.value)}
//                         />
//                         <TextField
//                           label="置換後の文字列"
//                           value={pair.replace}
//                           onChange={(e) => handleReplacePairChange(index, 'replace', e.target.value)}
//                         />
//                         <IconButton color="secondary" onClick={() => handleRemoveReplacePair(index)}>
//                           <Delete />
//                         </IconButton>
//                       </Box>
//                     ))}
//                     <Button
//                       variant="outlined"
//                       startIcon={<Add />}
//                       onClick={handleAddReplacePair}
//                       sx={{ mt: 2 }}
//                     >
//                       置換ペアを追加
//                     </Button>
//                   </Grid>
//                   {/* 先頭・末尾に追加する文字列 */}
//                   <Grid item xs={12}>
//                     <TextField
//                       label="先頭に追加する文字列"
//                       fullWidth
//                       margin="normal"
//                       value={prependText}
//                       onChange={(e) => setPrependText(e.target.value)}
//                       helperText="タイトルの先頭に追加します"
//                     />
//                     <TextField
//                       label="末尾に追加する文字列"
//                       fullWidth
//                       margin="normal"
//                       value={appendText}
//                       onChange={(e) => setAppendText(e.target.value)}
//                       helperText="タイトルの末尾に追加します"
//                     />
//                   </Grid>
//                   <Grid item xs={12}>
//                     <FormControlLabel
//                       control={
//                         <Switch
//                           checked={limitTitleLength}
//                           onChange={(e) => setLimitTitleLength(e.target.checked)}
//                         />
//                       }
//                       label="80文字に制限する"
//                     />
//                   </Grid>
//                   {/* カテゴリーの保存・更新・削除ボタン */}
//                   <Grid item xs={12}>
//                     <Button
//                       variant="contained"
//                       color="primary"
//                       onClick={saveOrUpdateCategory}
//                       disabled={!categoryName.trim()}
//                       fullWidth
//                     >
//                       {selectedCategory ? 'カテゴリーを更新' : 'カテゴリーを保存'}
//                     </Button>
//                     {selectedCategory && (
//                       <Button
//                         variant="outlined"
//                         color="secondary"
//                         onClick={deleteCategory}
//                         fullWidth
//                         sx={{ mt: 1 }}
//                       >
//                         カテゴリーを削除
//                       </Button>
//                     )}
//                   </Grid>
//                   {/* タイトルカスタマイズの適用ボタン */}
//                   <Grid item xs={12}>
//                     <Button
//                       variant="contained"
//                       color="secondary"
//                       onClick={customizeTitles}
//                       fullWidth
//                       sx={{ mt: 2 }}
//                     >
//                       タイトルカスタマイズを適用
//                     </Button>
//                   </Grid>
//                   {/* 表示件数選択コンポーネントを追加 */}
//                   <Grid item xs={12}>
//                     <FormControl variant="outlined" size="small" fullWidth sx={{ mt: 2 }}>
//                       <InputLabel id="items-per-page-label">表示件数</InputLabel>
//                       <Select
//                         labelId="items-per-page-label"
//                         value={itemsPerPage}
//                         onChange={(e) => {
//                           setItemsPerPage(e.target.value);
//                           setCurrentPage(1);
//                         }}
//                         label="表示件数"
//                       >
//                         {ITEMS_PER_PAGE_OPTIONS.map((option, index) => (
//                           <MenuItem key={index} value={option}>
//                             {option === 'All' ? '全て' : `${option}件ずつ`}
//                           </MenuItem>
//                         ))}
//                       </Select>
//                     </FormControl>
//                   </Grid>
//                 </Grid>
//               </Grid>

//               {/* 右側のセクション */}
//               <Grid
//                 item
//                 xs={12}
//                 md={10}
//                 sx={{
//                   maxHeight: 'calc(100vh - 32px)',
//                   overflowY: 'auto',
//                 }}
//               >
//                 {/* アクションボタン */}
//                 <Grid container spacing={2} style={{ marginBottom: '20px' }}>
//                   <Grid item>
//                     <Button
//                       variant="contained"
//                       color="primary"
//                       onClick={generateProductTitles}
//                       disabled={loading || !apiKey}
//                     >
//                       高精度AI生成をする
//                     </Button>
//                   </Grid>
//                   <Grid item>
//                     <Button
//                       variant="contained"
//                       color="primary"
//                       onClick={handleLowCostAIGeneration}
//                       startIcon={<MoneyOffIcon />}
//                       disabled={lowCostLoading || !apiKey}
//                     >
//                       低コストAI生成をする
//                     </Button>
//                   </Grid>
//                   <Grid item>
//                     <Button
//                       variant="contained"
//                       color="secondary"
//                       onClick={applyUpdatedTitles}
//                       disabled={loading || lowCostLoading}
//                     >
//                       タイトルをデータに反映する
//                     </Button>
//                   </Grid>
//                   <Grid item>
//                     <Button
//                       variant="contained"
//                       color="success"
//                       onClick={() => setCustomPromptDialogOpen(true)}
//                       startIcon={<EditIcon />}
//                       disabled={loading || lowCostLoading}
//                     >
//                       カスタムプロンプトで生成
//                     </Button>
//                   </Grid>
//                 </Grid>

//                 {/* 進捗状況表示 */}
//                 {(loading || lowCostLoading) && (
//                   <Box sx={{ width: '100%', mb: 2 }}>
//                     <LinearProgress variant="determinate" value={progress} />
//                     <Typography variant="body2" color="text.secondary" align="center">
//                       {`${Math.round(progress)}%`}
//                     </Typography>
//                   </Box>
//                 )}

//                 {/* エラーメッセージ */}
//                 {error && (
//                   <Typography color="error" style={{ marginTop: '20px' }}>
//                     {error}
//                   </Typography>
//                 )}
//                 {/* 成功メッセージ */}
//                 {message && (
//                   <Typography color="primary" style={{ marginTop: '20px' }}>
//                     {message}
//                   </Typography>
//                 )}

//                 {/* 商品テーブル */}
//                 <TableContainer component={Paper}>
//                   <Table stickyHeader aria-label="products-table">
//                     <TableHead>
//                       <TableRow>
//                         <TableCell padding="checkbox">
//                           <Checkbox
//                             checked={products.every((product) => product.selected)}
//                             onChange={handleSelectAll}
//                             indeterminate={
//                               products.some((product) => product.selected) &&
//                               !products.every((product) => product.selected)
//                             }
//                           />
//                         </TableCell>
//                         <TableCell>商品タイトル</TableCell>
//                         <TableCell>日本語の商品説明</TableCell>
//                         <TableCell>生成されたタイトル</TableCell>
//                       </TableRow>
//                     </TableHead>
//                     <TableBody>
//                       {displayedProducts.map((product) => (
//                         <TableRow key={product.id} hover>
//                           <TableCell padding="checkbox">
//                             <Checkbox
//                               checked={product.selected}
//                               onChange={() => handleSelectProduct(product.id)}
//                               onClick={(e) => e.stopPropagation()}
//                             />
//                           </TableCell>
//                           <TableCell
//                             sx={{
//                               fontSize: '0.8rem',
//                               whiteSpace: 'pre-wrap',
//                               wordWrap: 'break-word',
//                               maxWidth: '300px',
//                               padding: '4px',
//                             }}
//                           >
//                             {product.title}
//                           </TableCell>
//                           <TableCell
//                             sx={{
//                               padding: '4px',
//                             }}
//                           >
//                             <TextField
//                               multiline
//                               fullWidth
//                               variant="outlined"
//                               value={product.editableJpDesc}
//                               onChange={(e) => handleEditJpDesc(product.id, e.target.value)}
//                               onFocus={() => setFocusedProductId(product.id)}
//                               onBlur={() => setFocusedProductId(null)}
//                               InputProps={{
//                                 sx: {
//                                   textarea: {
//                                     maxHeight: focusedProductId === product.id ? 'none' : '8em',
//                                     overflow: 'auto',
//                                     transition: 'max-height 0.3s ease',
//                                   },
//                                 },
//                               }}
//                               inputProps={{
//                                 style: {
//                                   fontSize: '0.8rem',
//                                   padding: '2px',
//                                   lineHeight: '1.2',
//                                 },
//                               }}
//                             />
//                           </TableCell>
//                           <TableCell
//                             sx={{
//                               padding: '4px',
//                             }}
//                           >
//                             <TextField
//                               multiline
//                               fullWidth
//                               variant="outlined"
//                               value={product.generatedTitle}
//                               onChange={(event) => handleGeneratedTitleChange(event, product.id)}
//                               inputProps={{
//                                 style: {
//                                   fontSize: '0.8rem',
//                                   padding: '2px',
//                                   lineHeight: '1.2',
//                                 },
//                               }}
//                               sx={{
//                                 margin: 0,
//                               }}
//                             />
//                           </TableCell>
//                         </TableRow>
//                       ))}
//                     </TableBody>
//                   </Table>
//                 </TableContainer>

//                 {/* ページネーション */}
//                 {itemsPerPage !== 'All' && (
//                   <Pagination
//                     count={totalPages}
//                     page={currentPage}
//                     onChange={handlePageChange}
//                     color="primary"
//                     style={{ marginTop: '20px', display: 'flex', justifyContent: 'center' }}
//                   />
//                 )}
//               </Grid>
//             </Grid>
//           </DialogContent>
//           <DialogActions>
//             <Button onClick={handleClose}>閉じる</Button>
//           </DialogActions>
//         </Dialog>

//         {/* カスタムプロンプトダイアログ */}
//         <CustomPromptDialog
//           open={customPromptDialogOpen}
//           onClose={() => setCustomPromptDialogOpen(false)}
//           apiKey={apiKey}
//           selectedProducts={products.filter((product) => product.selected)}
//           updateProducts={updateProducts}
//         />

//         {/* スナックバー通知 */}
//         <Snackbar
//           anchorOrigin={{
//             vertical: 'bottom',
//             horizontal: 'left',
//           }}
//           open={snackbarOpen}
//           autoHideDuration={6000}
//           onClose={() => setSnackbarOpen(false)}
//         >
//           <Alert
//             onClose={() => setSnackbarOpen(false)}
//             severity={snackbarSeverity}
//             sx={{ width: '100%' }}
//           >
//             {snackbarMessage}
//           </Alert>
//         </Snackbar>
//       </Box>
//     </Paper>
//   );
// };

// export default TitleGenerationComponent;






// // TitleGenerationComponent.js

// import React, { useState, useEffect } from 'react';
// import axios from 'axios';
// import {
//   Button,
//   Typography,
//   TextField,
//   Checkbox,
//   Table,
//   TableBody,
//   TableCell,
//   TableContainer,
//   TableHead,
//   TableRow,
//   Paper,
//   Grid,
//   Snackbar,
//   Alert,
//   Box,
//   LinearProgress,
//   FormControl,
//   Select,
//   MenuItem,
//   InputLabel,
//   FormControlLabel,
//   Switch,
//   Chip,
//   IconButton,
//   Pagination,
//   Dialog,
//   DialogTitle,
//   DialogContent,
//   DialogActions,
// } from '@mui/material';
// import {
//   Add,
//   Delete,
//   FormatColorReset as FormatColorResetIcon,
//   Edit as EditIcon,
//   MoneyOff as MoneyOffIcon,
// } from '@mui/icons-material';
// import PQueue from 'p-queue';
// import CustomPromptDialog from './CustomPromptDialog';
// import {
//   loadCacheFromLocalStorage,
//   saveCacheToLocalStorage,
//   getCachedTitle,
//   setCachedTitle,
//   clearTitleCache,
// } from './titleCache';
// import { db } from './firebaseConfig'; // Firestoreのインポート
// import { doc, setDoc, getDoc } from 'firebase/firestore'; // Firestoreの関数をインポート
// import LowCostTitleGenerator, { generateProductTitles as generateLowCostTitles } from './LowCostTitleGenerator';

// // 定数の定義
// const ITEMS_PER_PAGE_OPTIONS = [10, 25, 50, 'All'];

// // コンポーネントの定義
// const TitleGenerationComponent = ({ data, setData, apiKey, user }) => {
//   // 状態管理のフック
//   const [open, setOpen] = useState(false);
//   const [products, setProducts] = useState([]);
//   const [displayedProducts, setDisplayedProducts] = useState([]);
//   const [currentPage, setCurrentPage] = useState(1);
//   const [totalPages, setTotalPages] = useState(1);
//   const [itemsPerPage, setItemsPerPage] = useState(10);
//   const [loading, setLoading] = useState(false);
//   const [lowCostLoading, setLowCostLoading] = useState(false); // 低コストAI生成用のローディング状態
//   const [progress, setProgress] = useState(0);
//   const [error, setError] = useState('');
//   const [message, setMessage] = useState('');
//   const [customPromptDialogOpen, setCustomPromptDialogOpen] = useState(false);
//   const [snackbarOpen, setSnackbarOpen] = useState(false);
//   const [snackbarMessage, setSnackbarMessage] = useState('');
//   const [snackbarSeverity, setSnackbarSeverity] = useState('info');
//   const [deleteStrings, setDeleteStrings] = useState([]);
//   const [replacePairs, setReplacePairs] = useState([]);
//   const [prependText, setPrependText] = useState('');
//   const [appendText, setAppendText] = useState('');
//   const [limitTitleLength, setLimitTitleLength] = useState(false);
//   const [categoryName, setCategoryName] = useState('');
//   const [customCategories, setCustomCategories] = useState([]);
//   const [selectedCategory, setSelectedCategory] = useState('');
//   const [focusedProductId, setFocusedProductId] = useState(null);

//   // キャッシュの読み込みとFirestoreからの設定取得
//   useEffect(() => {
//     loadCacheFromLocalStorage();
//     if (user) {
//       loadCategoriesFromFirestore(user.uid);
//     }
//   }, [user]);

//   // カスタムカテゴリーをFirestoreから読み込む
//   const loadCategoriesFromFirestore = async (uid) => {
//     try {
//       const docRef = doc(db, 'userTitleSettings', uid);
//       const docSnap = await getDoc(docRef);
//       if (docSnap.exists()) {
//         const data = docSnap.data();
//         setCustomCategories(data.customCategories || []);
//         setSnackbarMessage('カスタム設定をロードしました');
//         setSnackbarSeverity('success');
//         setSnackbarOpen(true);
//       } else {
//         console.log('No custom categories found in Firestore.');
//       }
//     } catch (error) {
//       console.error('Error loading categories from Firestore:', error);
//       setSnackbarMessage('カスタム設定のロードに失敗しました');
//       setSnackbarSeverity('error');
//       setSnackbarOpen(true);
//     }
//   };

//   // カスタムカテゴリーをFirestoreに保存
//   const saveCategoriesToFirestore = async (uid, categories) => {
//     try {
//       const docRef = doc(db, 'userTitleSettings', uid);
//       await setDoc(docRef, { customCategories: categories }, { merge: true });
//       setSnackbarMessage('カスタム設定を保存しました');
//       setSnackbarSeverity('success');
//       setSnackbarOpen(true);
//     } catch (error) {
//       console.error('Error saving categories to Firestore:', error);
//       setSnackbarMessage('カスタム設定の保存に失敗しました');
//       setSnackbarSeverity('error');
//       setSnackbarOpen(true);
//     }
//   };

//   // p-queueの設定
//   const CONCURRENCY = 2;
//   const INTERVAL = 1000;
//   const INTERVAL_CAP = 5;
//   const queue = new PQueue({
//     concurrency: CONCURRENCY,
//     interval: INTERVAL,
//     intervalCap: INTERVAL_CAP,
//   });

//   useEffect(() => {
//     if (open) {
//       initializeProducts();
//     }
//   }, [open]);

//   useEffect(() => {
//     const total = products.length;
//     if (itemsPerPage === 'All') {
//       setTotalPages(1);
//       setDisplayedProducts(products);
//     } else {
//       setTotalPages(Math.ceil(total / itemsPerPage));
//       updateDisplayedProducts(1);
//     }
//     setCurrentPage(1);
//   }, [products, itemsPerPage]);

//   const updateDisplayedProducts = (page) => {
//     if (itemsPerPage === 'All') {
//       setDisplayedProducts(products);
//       return;
//     }
//     const startIndex = (page - 1) * itemsPerPage;
//     const endIndex = startIndex + itemsPerPage;
//     setDisplayedProducts(products.slice(startIndex, endIndex));
//   };

//   const handlePageChange = (event, value) => {
//     setCurrentPage(value);
//     updateDisplayedProducts(value);
//   };

//   // データから商品リストを初期化
//   const initializeProducts = () => {
//     if (!data || data.length === 0) {
//       setError('データがありません。CSVファイルをインポートしてください。');
//       setSnackbarMessage('データがありません');
//       setSnackbarSeverity('error');
//       setSnackbarOpen(true);
//       return;
//     }

//     const headers = Object.keys(data[0]);
//     const titleIndex = headers.findIndex((header) => header.toLowerCase() === 'title');
//     const jpDescIndex = headers.findIndex((header) => header.toLowerCase() === 'jp_desc');

//     if (titleIndex === -1) {
//       setError('Titleカラムが見つかりません');
//       return;
//     }

//     const productData = data.map((row, index) => ({
//       id: index,
//       title: row['Title'] || row['title'] || '',
//       jpDesc: row['JP_Desc'] || row['jp_desc'] || '',
//       generatedTitle: '',
//       selected: false,
//       editableJpDesc: row['JP_Desc'] || row['jp_desc'] || '',
//     }));

//     setProducts(productData);
//   };

//   // 商品の選択ハンドラー
//   const handleSelectProduct = (productId) => {
//     const updatedProducts = products.map((product) =>
//       product.id === productId ? { ...product, selected: !product.selected } : product
//     );
//     setProducts(updatedProducts);
//     updateDisplayedProducts(currentPage);
//   };

//   // すべての商品を選択/解除
//   const handleSelectAll = () => {
//     const allSelected = products.every((product) => product.selected);
//     const updatedProducts = products.map((product) => ({
//       ...product,
//       selected: !allSelected,
//     }));
//     setProducts(updatedProducts);
//     updateDisplayedProducts(currentPage);
//   };

//   // 生成されたタイトルの編集ハンドラー
//   const handleGeneratedTitleChange = (event, productId) => {
//     const updatedProducts = products.map((product) =>
//       product.id === productId ? { ...product, generatedTitle: event.target.value } : product
//     );
//     setProducts(updatedProducts);
//     updateDisplayedProducts(currentPage);
//   };

//   // AIによるタイトル生成（デフォルトプロンプト）
//   const generateProductTitles = async () => {
//     if (!apiKey) {
//       setError('OpenAI APIキーが設定されていません');
//       setSnackbarMessage('OpenAI APIキーが必要です');
//       setSnackbarSeverity('error');
//       setSnackbarOpen(true);
//       return;
//     }

//     const selectedProductsData = products.filter((product) => product.selected);
//     if (selectedProductsData.length === 0) {
//       setError('少なくとも一つの商品を選択してください');
//       setSnackbarMessage('商品が選択されていません');
//       setSnackbarSeverity('warning');
//       setSnackbarOpen(true);
//       return;
//     }

//     setLoading(true);
//     setError('');
//     setMessage('');
//     setProgress(0);

//     try {
//       const total = selectedProductsData.length;
//       let completed = 0;

//       const updatedProducts = [...products];

//       const generateTitleTasks = selectedProductsData.map((product) => async () => {
//         const index = products.findIndex((p) => p.id === product.id);
//         try {
//           const generatedTitle = await generateSingleTitle(
//             product.title,
//             product.editableJpDesc,
//             apiKey
//           );
//           updatedProducts[index].generatedTitle = generatedTitle;
//           completed++;
//           setProgress(Math.round((completed / total) * 100));
//         } catch (err) {
//           console.error(`商品ID ${product.id} のタイトル生成エラー:`, err);
//           updatedProducts[index].generatedTitle = '';
//           setError(`タイトルの生成に失敗しました: ${err.message}`);
//         }
//       });

//       await queue.addAll(generateTitleTasks);

//       setProducts(updatedProducts);
//       setMessage('タイトルの生成が完了しました');
//       setSnackbarMessage('タイトルの生成が完了しました');
//       setSnackbarSeverity('success');
//       setSnackbarOpen(true);
//       updateDisplayedProducts(currentPage);
//     } catch (err) {
//       console.error('タイトル生成エラー:', err);
//       setError(`タイトルの生成に失敗しました: ${err.message}`);
//       setSnackbarMessage(`エラー: ${err.message}`);
//       setSnackbarSeverity('error');
//       setSnackbarOpen(true);
//     } finally {
//       setLoading(false);
//       setProgress(0);
//     }
//   };

//   // 低コストAIでタイトル生成
//   const handleLowCostAIGeneration = async () => {
//     if (!apiKey) {
//       setError('OpenAI APIキーが設定されていません');
//       setSnackbarMessage('OpenAI APIキーが必要です');
//       setSnackbarSeverity('error');
//       setSnackbarOpen(true);
//       return;
//     }

//     const selectedProductsData = products.filter((product) => product.selected);
//     if (selectedProductsData.length === 0) {
//       setError('少なくとも一つの商品を選択してください');
//       setSnackbarMessage('商品が選択されていません');
//       setSnackbarSeverity('warning');
//       setSnackbarOpen(true);
//       return;
//     }

//     setLowCostLoading(true);
//     setError('');
//     setMessage('');
//     setProgress(0);

//     try {
//       await generateLowCostTitles({
//         products,
//         setProducts,
//         apiKey,
//         setSnackbarMessage,
//         setSnackbarSeverity,
//         setSnackbarOpen,
//         setError,
//         setMessage,
//         setProgress,
//         queue,
//       });
//       setSnackbarMessage('低コストAIでのタイトル生成が完了しました');
//       setSnackbarSeverity('success');
//       setSnackbarOpen(true);
//       updateDisplayedProducts(currentPage);
//     } catch (error) {
//       console.error('低コストAI生成エラー:', error);
//       setError(`低コストAI生成に失敗しました: ${error.message}`);
//       setSnackbarMessage(`エラー: ${error.message}`);
//       setSnackbarSeverity('error');
//       setSnackbarOpen(true);
//     } finally {
//       setLowCostLoading(false);
//       setProgress(0);
//     }
//   };

//   // 単一の商品タイトルを生成
//   async function generateSingleTitle(originalTitle, jpDesc, apiKey) {
//     const cacheKey = originalTitle + jpDesc.slice(0, 150);
//     const cachedTitle = getCachedTitle(cacheKey);
//     if (cachedTitle) {
//       return cachedTitle;
//     }

//     try {
//       const url = 'https://api.openai.com/v1/chat/completions';
//       const limitedJpDesc = jpDesc.slice(0, 150);
//       const requestData = {
//         model: 'gpt-4o-2024-08-06',
//         messages: [
//           {
//             role: 'system',
//             content: `You are an AI assistant specializing in eBay product title optimization. Based on the given original title and Japanese product information, create an effective English title within 80 characters.
// Order of title composition:
// 1. brand name (if applicable)
// 2. model name or product name/character name (if applicable)
// 3. product type (if applicable)
// 4. key features (color, material, size) (if applicable)
// 5. distinctive elements or uniqueness (if applicable)
// 6. condition (new/used) (if applicable)
// 7. important keywords (if applicable)
// NOTES:
// - Add or optimize new information while retaining important information from the original title
// - Avoid unnecessary adjectives and words such as “eBay
// - Use abbreviations and common names appropriately
// - Think from the searcher's perspective and include search terms that buyers are likely to use
// - If information is unclear or ambiguous, omit it.
// - Use only reliable information and do not include guesswork or uncertain information.
// - Do not extract from Japanese descriptions that are a list of words.
// - Titles should be generated in English and should not exceed 80 characters.`,
//           },
//           {
//             role: 'user',
//             content: `元のタイトル：「${originalTitle}」
// 日本語の商品説明：「${limitedJpDesc}」
// この情報を基に、最適化された新しいeBayの商品タイトルを生成してください。また日本語の商品説明が空の場合は元のタイトルから生成してください`,
//           },
//         ],
//         max_tokens: 100,
//       };

//       const response = await axios.post(url, requestData, {
//         headers: { Authorization: `Bearer ${apiKey}` },
//       });

//       const generatedTitle = response.data.choices[0].message.content.trim();
//       setCachedTitle(cacheKey, generatedTitle);
//       return generatedTitle;
//     } catch (error) {
//       throw error;
//     }
//   }

//   // 生成されたタイトルをデータに反映
//   const applyUpdatedTitles = () => {
//     const updatedProducts = products.map((product) => {
//       if (product.selected && product.generatedTitle) {
//         const updatedProduct = { ...data[product.id], Title: product.generatedTitle };
//         return updatedProduct;
//       }
//       return data[product.id];
//     });

//     setData(updatedProducts);
//     setSnackbarMessage('生成されたタイトルをデータに反映しました');
//     setSnackbarSeverity('success');
//     setSnackbarOpen(true);
//     setOpen(false);
//   };

//   // カテゴリーの変更ハンドラー
//   const handleCategoryChange = (event) => {
//     const selectedName = event.target.value;
//     setSelectedCategory(selectedName);

//     if (selectedName) {
//       const category = customCategories.find((cat) => cat.name === selectedName);
//       if (category) {
//         setDeleteStrings(category.deleteStrings || []);
//         setReplacePairs(category.replacePairs || []);
//         setPrependText(category.prependText || '');
//         setAppendText(category.appendText || '');
//         setLimitTitleLength(category.limitTitleLength || false);
//         setCategoryName(category.name);
//       }
//     } else {
//       // 新しいカテゴリーを作成する場合、初期状態にリセット
//       resetCustomizationSettings();
//     }
//   };

//   // カスタマイズ設定を初期状態にリセット
//   const resetCustomizationSettings = () => {
//     setDeleteStrings([]);
//     setReplacePairs([]);
//     setPrependText('');
//     setAppendText('');
//     setLimitTitleLength(false);
//     setCategoryName('');
//     setSelectedCategory('');
//   };

//   // 新しいカテゴリーを作成
//   const handleCreateNewCategory = () => {
//     // カテゴリー選択をクリアし、初期状態にリセット
//     resetCustomizationSettings();
//   };

//   // カテゴリーを保存または更新する
//   const saveOrUpdateCategory = () => {
//     if (!categoryName.trim()) {
//       setSnackbarMessage('カテゴリー名を入力してください');
//       setSnackbarSeverity('warning');
//       setSnackbarOpen(true);
//       return;
//     }

//     const newCategory = {
//       name: categoryName.trim(),
//       deleteStrings,
//       replacePairs,
//       prependText,
//       appendText,
//       limitTitleLength,
//     };

//     let updatedCategories;
//     if (selectedCategory) {
//       // 既存のカテゴリーを更新
//       updatedCategories = customCategories.map((cat) =>
//         cat.name === selectedCategory ? newCategory : cat
//       );
//       setSnackbarMessage('カテゴリーを更新しました');
//     } else {
//       // 新しいカテゴリーを追加
//       updatedCategories = [...customCategories, newCategory];
//       setSnackbarMessage('新しいカテゴリーを保存しました');
//     }

//     setCustomCategories(updatedCategories);
//     if (user) {
//       saveCategoriesToFirestore(user.uid, updatedCategories);
//     }
//     setSnackbarSeverity('success');
//     setSnackbarOpen(true);
//     setSelectedCategory(newCategory.name);
//   };

//   // カテゴリーを削除する
//   const deleteCategory = () => {
//     if (!selectedCategory) return;

//     const updatedCategories = customCategories.filter((cat) => cat.name !== selectedCategory);
//     setCustomCategories(updatedCategories);
//     if (user) {
//       saveCategoriesToFirestore(user.uid, updatedCategories);
//     }
//     resetCustomizationSettings();
//     setSnackbarMessage('カテゴリーを削除しました');
//     setSnackbarSeverity('info');
//     setSnackbarOpen(true);
//   };

//   // タイトルカスタマイズの適用
//   const customizeTitles = () => {
//     const updatedProducts = products.map((product) => {
//       if (product.selected && product.generatedTitle) {
//         let newTitle = product.generatedTitle;

//         // 複数の削除文字列を処理
//         deleteStrings.forEach((deleteStr) => {
//           if (deleteStr) {
//             const regex = new RegExp(deleteStr, 'g');
//             newTitle = newTitle.replace(regex, '');
//           }
//         });

//         // 複数の置換ペアを処理
//         replacePairs.forEach((pair) => {
//           const { find, replace } = pair;
//           if (find) {
//             const regex = new RegExp(find, 'g');
//             newTitle = newTitle.replace(regex, replace);
//           }
//         });

//         // 先頭と末尾の追加文字列を処理
//         if (prependText) {
//           newTitle = `${prependText} ${newTitle}`;
//         }
//         if (appendText) {
//           newTitle = `${newTitle} ${appendText}`;
//         }

//         // タイトルを80文字に制限
//         if (limitTitleLength) {
//           newTitle = newTitle.slice(0, 79);
//         }

//         return { ...product, generatedTitle: newTitle };
//       }
//       return product;
//     });
//     setProducts(updatedProducts);
//     updateDisplayedProducts(currentPage);

//     setSnackbarMessage('タイトルをカスタマイズしました');
//     setSnackbarSeverity('success');
//     setSnackbarOpen(true);
//   };

//   // キャッシュをクリア
//   const handleClearTitleCache = () => {
//     clearTitleCache();
//     setSnackbarMessage('キャッシュをクリアしました');
//     setSnackbarSeverity('info');
//     setSnackbarOpen(true);
//   };

//   // 削除文字列のチップ関連の関数
//   const handleDeleteStringKeyPress = (event) => {
//     if (event.key === 'Enter' && event.target.value.trim() !== '') {
//       setDeleteStrings([...deleteStrings, event.target.value.trim()]);
//       event.target.value = '';
//     }
//   };

//   const handleDeleteChip = (chipToDelete) => {
//     setDeleteStrings((chips) => chips.filter((chip) => chip !== chipToDelete));
//   };

//   // 置換ペア関連の関数
//   const handleAddReplacePair = () => {
//     setReplacePairs([...replacePairs, { find: '', replace: '' }]);
//   };

//   const handleReplacePairChange = (index, field, value) => {
//     const newReplacePairs = [...replacePairs];
//     newReplacePairs[index][field] = value;
//     setReplacePairs(newReplacePairs);
//   };

//   const handleRemoveReplacePair = (index) => {
//     const newReplacePairs = replacePairs.filter((_, i) => i !== index);
//     setReplacePairs(newReplacePairs);
//   };

//   // 商品説明の編集
//   const handleEditJpDesc = (productId, newDesc) => {
//     const updatedProducts = products.map((product) =>
//       product.id === productId ? { ...product, editableJpDesc: newDesc } : product
//     );
//     setProducts(updatedProducts);
//     updateDisplayedProducts(currentPage);
//   };

//   const handleOpen = () => {
//     setOpen(true);
//   };

//   const handleClose = () => {
//     setOpen(false);
//   };

//   // 生成されたタイトルを更新するための関数
//   const updateProducts = (updatedProducts) => {
//     const newProducts = products.map((product) => {
//       const updatedProduct = updatedProducts.find((p) => p.id === product.id);
//       return updatedProduct ? updatedProduct : product;
//     });
//     setProducts(newProducts);
//     updateDisplayedProducts(currentPage);
//   };

//   return (
//     <Paper
//       elevation={3}
//       sx={{
//         p: 4,
//         mt: 1,
//         mb: 1,
//         maxWidth: '100%',
//         margin: '0 auto',
//         overflow: 'hidden',
//       }}
//     >
//       <Box sx={{ maxWidth: '1600px', margin: '0 auto' }}>
//         {/* ヘッダーセクション */}
//         <Grid container spacing={3} alignItems="center">
//           <Grid item xs={12} md={6}>
//             <Typography variant="h5" gutterBottom>
//               AIを活用したタイトル生成
//             </Typography>
//             <Typography variant="body1" paragraph>
//               日本語の商品説明から英語の商品タイトルを自動生成します。
//             </Typography>
//           </Grid>
//           <Grid item xs={12} md={6}>
//             <Typography variant="subtitle1" gutterBottom>
//               主な機能：
//             </Typography>
//             <Grid container spacing={1}>
//               {[
//                 '日本語の商品説明から英語の商品タイトルを生成',
//                 'タイトルの一括編集とカスタマイズ',
//               ].map((feature, index) => (
//                 <Grid item xs={6} key={index}>
//                   <Typography variant="body2">• {feature}</Typography>
//                 </Grid>
//               ))}
//             </Grid>
//           </Grid>
//         </Grid>

//         {/* アクションボタンセクション */}
//         <Box sx={{ mt: 3, display: 'flex', gap: '10px', justifyContent: 'center' }}>
//           <Button variant="contained" color="primary" onClick={handleOpen} startIcon={<Add />}>
//             タイトルをAIで生成する
//           </Button>
//           <Button
//             variant="outlined"
//             color="primary"
//             onClick={handleClearTitleCache}
//             startIcon={<FormatColorResetIcon />}
//           >
//             キャッシュをクリア
//           </Button>
//         </Box>

//         {/* ダイアログセクション */}
//         <Dialog open={open} onClose={handleClose} fullScreen>
//           <DialogTitle>AIでタイトルを生成する</DialogTitle>
//           <DialogContent>
//             {/* レイアウトを左右に分割 */}
//             <Grid container spacing={2}>
//               {/* 左側のセクション */}
//               <Grid
//                 item
//                 xs={12}
//                 md={2}
//                 sx={{
//                   maxHeight: 'calc(100vh - 32px)',
//                   overflowY: 'auto',
//                 }}
//               >
//                 {/* カテゴリー選択とカスタマイズ */}
//                 <Typography variant="h6">タイトルカスタマイズ</Typography>
//                 <Grid container spacing={2}>
//                   <Grid item xs={12}>
//                     <FormControl fullWidth margin="normal">
//                       <InputLabel id="category-select-label">カテゴリーを選択</InputLabel>
//                       <Select
//                         labelId="category-select-label"
//                         value={selectedCategory}
//                         onChange={handleCategoryChange}
//                       >
//                         {customCategories.map((cat) => (
//                           <MenuItem key={cat.name} value={cat.name}>
//                             {cat.name}
//                           </MenuItem>
//                         ))}
//                       </Select>
//                     </FormControl>
//                   </Grid>
//                   <Grid item xs={12}>
//                     <TextField
//                       label="カテゴリー名"
//                       fullWidth
//                       margin="normal"
//                       value={categoryName}
//                       onChange={(e) => setCategoryName(e.target.value)}
//                       placeholder="新しいカテゴリー名を入力"
//                     />
//                     <Button
//                       variant="outlined"
//                       color="primary"
//                       onClick={handleCreateNewCategory}
//                       sx={{ mt: 1 }}
//                     >
//                       新しいカテゴリーを作成
//                     </Button>
//                   </Grid>
//                   {/* 削除する文字列 */}
//                   <Grid item xs={12}>
//                     <Typography variant="subtitle1">削除する文字列</Typography>
//                     <TextField
//                       placeholder="削除したい文字列を入力し、Enterキーを押してください"
//                       onKeyPress={handleDeleteStringKeyPress}
//                       fullWidth
//                     />
//                     <Box sx={{ mt: 1, display: 'flex', flexWrap: 'wrap', gap: 1 }}>
//                       {deleteStrings.map((string, index) => (
//                         <Chip key={index} label={string} onDelete={() => handleDeleteChip(string)} />
//                       ))}
//                     </Box>
//                   </Grid>
//                   {/* 置換ペア */}
//                   <Grid item xs={12}>
//                     <Typography variant="subtitle1">置換ペア</Typography>
//                     {replacePairs.map((pair, index) => (
//                       <Box
//                         key={index}
//                         sx={{ display: 'flex', gap: 1, alignItems: 'center', mt: 1 }}
//                       >
//                         <TextField
//                           label="置換する文字列"
//                           value={pair.find}
//                           onChange={(e) => handleReplacePairChange(index, 'find', e.target.value)}
//                         />
//                         <TextField
//                           label="置換後の文字列"
//                           value={pair.replace}
//                           onChange={(e) => handleReplacePairChange(index, 'replace', e.target.value)}
//                         />
//                         <IconButton color="secondary" onClick={() => handleRemoveReplacePair(index)}>
//                           <Delete />
//                         </IconButton>
//                       </Box>
//                     ))}
//                     <Button
//                       variant="outlined"
//                       startIcon={<Add />}
//                       onClick={handleAddReplacePair}
//                       sx={{ mt: 2 }}
//                     >
//                       置換ペアを追加
//                     </Button>
//                   </Grid>
//                   {/* 先頭・末尾に追加する文字列 */}
//                   <Grid item xs={12}>
//                     <TextField
//                       label="先頭に追加する文字列"
//                       fullWidth
//                       margin="normal"
//                       value={prependText}
//                       onChange={(e) => setPrependText(e.target.value)}
//                       helperText="タイトルの先頭に追加します"
//                     />
//                     <TextField
//                       label="末尾に追加する文字列"
//                       fullWidth
//                       margin="normal"
//                       value={appendText}
//                       onChange={(e) => setAppendText(e.target.value)}
//                       helperText="タイトルの末尾に追加します"
//                     />
//                   </Grid>
//                   <Grid item xs={12}>
//                     <FormControlLabel
//                       control={
//                         <Switch
//                           checked={limitTitleLength}
//                           onChange={(e) => setLimitTitleLength(e.target.checked)}
//                         />
//                       }
//                       label="80文字に制限する"
//                     />
//                   </Grid>
//                   {/* カテゴリーの保存・更新・削除ボタン */}
//                   <Grid item xs={12}>
//                     <Button
//                       variant="contained"
//                       color="primary"
//                       onClick={saveOrUpdateCategory}
//                       disabled={!categoryName.trim()}
//                       fullWidth
//                     >
//                       {selectedCategory ? 'カテゴリーを更新' : 'カテゴリーを保存'}
//                     </Button>
//                     {selectedCategory && (
//                       <Button
//                         variant="outlined"
//                         color="secondary"
//                         onClick={deleteCategory}
//                         fullWidth
//                         sx={{ mt: 1 }}
//                       >
//                         カテゴリーを削除
//                       </Button>
//                     )}
//                   </Grid>
//                   {/* タイトルカスタマイズの適用ボタン */}
//                   <Grid item xs={12}>
//                     <Button
//                       variant="contained"
//                       color="secondary"
//                       onClick={customizeTitles}
//                       fullWidth
//                       sx={{ mt: 2 }}
//                     >
//                       タイトルカスタマイズを適用
//                     </Button>
//                   </Grid>
//                   {/* 表示件数選択コンポーネントを追加 */}
//                   <Grid item xs={12}>
//                     <FormControl variant="outlined" size="small" fullWidth sx={{ mt: 2 }}>
//                       <InputLabel id="items-per-page-label">表示件数</InputLabel>
//                       <Select
//                         labelId="items-per-page-label"
//                         value={itemsPerPage}
//                         onChange={(e) => {
//                           setItemsPerPage(e.target.value);
//                           setCurrentPage(1);
//                         }}
//                         label="表示件数"
//                       >
//                         {ITEMS_PER_PAGE_OPTIONS.map((option, index) => (
//                           <MenuItem key={index} value={option}>
//                             {option === 'All' ? '全て' : `${option}件ずつ`}
//                           </MenuItem>
//                         ))}
//                       </Select>
//                     </FormControl>
//                   </Grid>
//                 </Grid>
//               </Grid>

//               {/* 右側のセクション */}
//               <Grid
//                 item
//                 xs={12}
//                 md={10}
//                 sx={{
//                   maxHeight: 'calc(100vh - 32px)',
//                   overflowY: 'auto',
//                 }}
//               >
//                 {/* アクションボタン */}
//                 <Grid container spacing={2} style={{ marginBottom: '20px' }}>
//                   <Grid item>
//                     <Button
//                       variant="contained"
//                       color="primary"
//                       onClick={generateProductTitles}
//                       disabled={loading || !apiKey}
//                     >
//                       高精度AI生成をする
//                     </Button>
//                   </Grid>
//                   <Grid item>
//                     <Button
//                       variant="contained"
//                       color="primary"
//                       onClick={handleLowCostAIGeneration}
//                       startIcon={<MoneyOffIcon />}
//                       disabled={lowCostLoading || !apiKey}
//                     >
//                       低コストAI生成をする
//                     </Button>
//                   </Grid>
//                   <Grid item>
//                     <Button
//                       variant="contained"
//                       color="secondary"
//                       onClick={applyUpdatedTitles}
//                       disabled={loading || lowCostLoading}
//                     >
//                       タイトルをデータに反映する
//                     </Button>
//                   </Grid>
//                   <Grid item>
//                     <Button
//                       variant="contained"
//                       color="success"
//                       onClick={() => setCustomPromptDialogOpen(true)}
//                       startIcon={<EditIcon />}
//                       disabled={loading || lowCostLoading}
//                     >
//                       カスタムプロンプトで生成
//                     </Button>
//                   </Grid>
//                 </Grid>

//                 {/* 進捗状況表示 */}
//                 {(loading || lowCostLoading) && (
//                   <Box sx={{ width: '100%', mb: 2 }}>
//                     <LinearProgress variant="determinate" value={progress} />
//                     <Typography variant="body2" color="text.secondary" align="center">
//                       {`${Math.round(progress)}%`}
//                     </Typography>
//                   </Box>
//                 )}

//                 {/* エラーメッセージ */}
//                 {error && (
//                   <Typography color="error" style={{ marginTop: '20px' }}>
//                     {error}
//                   </Typography>
//                 )}
//                 {/* 成功メッセージ */}
//                 {message && (
//                   <Typography color="primary" style={{ marginTop: '20px' }}>
//                     {message}
//                   </Typography>
//                 )}

//                 {/* 商品テーブル */}
//                 <TableContainer component={Paper}>
//                   <Table stickyHeader aria-label="products-table">
//                     <TableHead>
//                       <TableRow>
//                         <TableCell padding="checkbox">
//                           <Checkbox
//                             checked={products.every((product) => product.selected)}
//                             onChange={handleSelectAll}
//                             indeterminate={
//                               products.some((product) => product.selected) &&
//                               !products.every((product) => product.selected)
//                             }
//                           />
//                         </TableCell>
//                         <TableCell>商品タイトル</TableCell>
//                         <TableCell>日本語の商品説明</TableCell>
//                         <TableCell>生成されたタイトル</TableCell>
//                       </TableRow>
//                     </TableHead>
//                     <TableBody>
//                       {displayedProducts.map((product) => (
//                         <TableRow key={product.id} hover>
//                           <TableCell padding="checkbox">
//                             <Checkbox
//                               checked={product.selected}
//                               onChange={() => handleSelectProduct(product.id)}
//                               onClick={(e) => e.stopPropagation()}
//                             />
//                           </TableCell>
//                           <TableCell
//                             sx={{
//                               fontSize: '0.8rem',
//                               whiteSpace: 'pre-wrap',
//                               wordWrap: 'break-word',
//                               maxWidth: '300px',
//                               padding: '4px',
//                             }}
//                           >
//                             {product.title}
//                           </TableCell>
//                           <TableCell
//                             sx={{
//                               padding: '4px',
//                             }}
//                           >
//                             <TextField
//                               multiline
//                               fullWidth
//                               variant="outlined"
//                               value={product.editableJpDesc}
//                               onChange={(e) => handleEditJpDesc(product.id, e.target.value)}
//                               onFocus={() => setFocusedProductId(product.id)}
//                               onBlur={() => setFocusedProductId(null)}
//                               InputProps={{
//                                 sx: {
//                                   textarea: {
//                                     maxHeight: focusedProductId === product.id ? 'none' : '8em',
//                                     overflow: 'auto',
//                                     transition: 'max-height 0.3s ease',
//                                   },
//                                 },
//                               }}
//                               inputProps={{
//                                 style: {
//                                   fontSize: '0.8rem',
//                                   padding: '2px',
//                                   lineHeight: '1.2',
//                                 },
//                               }}
//                             />
//                           </TableCell>
//                           <TableCell
//                             sx={{
//                               padding: '4px',
//                             }}
//                           >
//                             <TextField
//                               multiline
//                               fullWidth
//                               variant="outlined"
//                               value={product.generatedTitle}
//                               onChange={(event) => handleGeneratedTitleChange(event, product.id)}
//                               inputProps={{
//                                 style: {
//                                   fontSize: '0.8rem',
//                                   padding: '2px',
//                                   lineHeight: '1.2',
//                                 },
//                               }}
//                               sx={{
//                                 margin: 0,
//                               }}
//                             />
//                           </TableCell>
//                         </TableRow>
//                       ))}
//                     </TableBody>
//                   </Table>
//                 </TableContainer>

//                 {/* ページネーション */}
//                 {itemsPerPage !== 'All' && (
//                   <Pagination
//                     count={totalPages}
//                     page={currentPage}
//                     onChange={handlePageChange}
//                     color="primary"
//                     style={{ marginTop: '20px', display: 'flex', justifyContent: 'center' }}
//                   />
//                 )}
//               </Grid>
//             </Grid>
//           </DialogContent>
//           <DialogActions>
//             <Button onClick={handleClose}>閉じる</Button>
//           </DialogActions>
//         </Dialog>

//         {/* カスタムプロンプトダイアログ */}
//         <CustomPromptDialog
//           open={customPromptDialogOpen}
//           onClose={() => setCustomPromptDialogOpen(false)}
//           apiKey={apiKey}
//           selectedProducts={products.filter((product) => product.selected)}
//           updateProducts={updateProducts}
//         />

//         {/* スナックバー通知 */}
//         <Snackbar
//           anchorOrigin={{
//             vertical: 'bottom',
//             horizontal: 'left',
//           }}
//           open={snackbarOpen}
//           autoHideDuration={6000}
//           onClose={() => setSnackbarOpen(false)}
//         >
//           <Alert
//             onClose={() => setSnackbarOpen(false)}
//             severity={snackbarSeverity}
//             sx={{ width: '100%' }}
//           >
//             {snackbarMessage}
//           </Alert>
//         </Snackbar>
//       </Box>
//     </Paper>
//   );
// };

// export default TitleGenerationComponent;





// // TitleGenerationComponent.js

// import React, { useState, useEffect } from 'react';
// import axios from 'axios';
// import {
//   Button,
//   Typography,
//   TextField,
//   Checkbox,
//   Table,
//   TableBody,
//   TableCell,
//   TableContainer,
//   TableHead,
//   TableRow,
//   Paper,
//   Grid,
//   Snackbar,
//   Alert,
//   Box,
//   LinearProgress,
//   FormControl,
//   Select,
//   MenuItem,
//   InputLabel,
//   FormControlLabel,
//   Switch,
//   Chip,
//   IconButton,
//   Pagination,
//   Dialog,
//   DialogTitle,
//   DialogContent,
//   DialogActions,
// } from '@mui/material';
// import {
//   Add,
//   Delete,
//   FormatColorReset as FormatColorResetIcon,
//   Edit as EditIcon,
// } from '@mui/icons-material';
// import PQueue from 'p-queue';
// import CustomPromptDialog from './CustomPromptDialog';
// import {
//   loadCacheFromLocalStorage,
//   saveCacheToLocalStorage,
//   getCachedTitle,
//   setCachedTitle,
//   clearTitleCache,
// } from './titleCache';
// import { db } from './firebaseConfig'; // Firestoreのインポート
// import { doc, setDoc, getDoc } from 'firebase/firestore'; // Firestoreの関数をインポート

// // 定数の定義
// const ITEMS_PER_PAGE_OPTIONS = [10, 25, 50, 'All'];

// // コンポーネントの定義
// const TitleGenerationComponent = ({ data, setData, apiKey, user }) => {
//   // 状態管理のフック
//   const [open, setOpen] = useState(false);
//   const [products, setProducts] = useState([]);
//   const [displayedProducts, setDisplayedProducts] = useState([]);
//   const [currentPage, setCurrentPage] = useState(1);
//   const [totalPages, setTotalPages] = useState(1);
//   const [itemsPerPage, setItemsPerPage] = useState(10);
//   const [loading, setLoading] = useState(false);
//   const [progress, setProgress] = useState(0);
//   const [error, setError] = useState('');
//   const [message, setMessage] = useState('');
//   const [customPromptDialogOpen, setCustomPromptDialogOpen] = useState(false);
//   const [snackbarOpen, setSnackbarOpen] = useState(false);
//   const [snackbarMessage, setSnackbarMessage] = useState('');
//   const [snackbarSeverity, setSnackbarSeverity] = useState('info');
//   const [deleteStrings, setDeleteStrings] = useState([]);
//   const [replacePairs, setReplacePairs] = useState([]);
//   const [prependText, setPrependText] = useState('');
//   const [appendText, setAppendText] = useState('');
//   const [limitTitleLength, setLimitTitleLength] = useState(false);
//   const [categoryName, setCategoryName] = useState('');
//   const [customCategories, setCustomCategories] = useState([]);
//   const [selectedCategory, setSelectedCategory] = useState('');
//   const [focusedProductId, setFocusedProductId] = useState(null);

//   // キャッシュの読み込みとFirestoreからの設定取得
//   useEffect(() => {
//     loadCacheFromLocalStorage();
//     if (user) {
//       loadCategoriesFromFirestore(user.uid);
//     }
//   }, [user]);

//   // カスタムカテゴリーをFirestoreから読み込む
//   const loadCategoriesFromFirestore = async (uid) => {
//     try {
//       const docRef = doc(db, 'userTitleSettings', uid);
//       const docSnap = await getDoc(docRef);
//       if (docSnap.exists()) {
//         const data = docSnap.data();
//         setCustomCategories(data.customCategories || []);
//         setSnackbarMessage('カスタム設定をロードしました');
//         setSnackbarSeverity('success');
//         setSnackbarOpen(true);
//       } else {
//         console.log('No custom categories found in Firestore.');
//       }
//     } catch (error) {
//       console.error('Error loading categories from Firestore:', error);
//       setSnackbarMessage('カスタム設定のロードに失敗しました');
//       setSnackbarSeverity('error');
//       setSnackbarOpen(true);
//     }
//   };

//   // カスタムカテゴリーをFirestoreに保存
//   const saveCategoriesToFirestore = async (uid, categories) => {
//     try {
//       const docRef = doc(db, 'userTitleSettings', uid);
//       await setDoc(docRef, { customCategories: categories }, { merge: true });
//       setSnackbarMessage('カスタム設定を保存しました');
//       setSnackbarSeverity('success');
//       setSnackbarOpen(true);
//     } catch (error) {
//       console.error('Error saving categories to Firestore:', error);
//       setSnackbarMessage('カスタム設定の保存に失敗しました');
//       setSnackbarSeverity('error');
//       setSnackbarOpen(true);
//     }
//   };

//   // p-queueの設定
//   const CONCURRENCY = 2;
//   const INTERVAL = 1000;
//   const INTERVAL_CAP = 5;
//   const queue = new PQueue({
//     concurrency: CONCURRENCY,
//     interval: INTERVAL,
//     intervalCap: INTERVAL_CAP,
//   });

//   useEffect(() => {
//     if (open) {
//       initializeProducts();
//     }
//   }, [open]);

//   useEffect(() => {
//     const total = products.length;
//     if (itemsPerPage === 'All') {
//       setTotalPages(1);
//       setDisplayedProducts(products);
//     } else {
//       setTotalPages(Math.ceil(total / itemsPerPage));
//       updateDisplayedProducts(1);
//     }
//     setCurrentPage(1);
//   }, [products, itemsPerPage]);

//   const updateDisplayedProducts = (page) => {
//     if (itemsPerPage === 'All') {
//       setDisplayedProducts(products);
//       return;
//     }
//     const startIndex = (page - 1) * itemsPerPage;
//     const endIndex = startIndex + itemsPerPage;
//     setDisplayedProducts(products.slice(startIndex, endIndex));
//   };

//   const handlePageChange = (event, value) => {
//     setCurrentPage(value);
//     updateDisplayedProducts(value);
//   };

//   // データから商品リストを初期化
//   const initializeProducts = () => {
//     if (!data || data.length === 0) {
//       setError('データがありません。CSVファイルをインポートしてください。');
//       setSnackbarMessage('データがありません');
//       setSnackbarSeverity('error');
//       setSnackbarOpen(true);
//       return;
//     }

//     const headers = Object.keys(data[0]);
//     const titleIndex = headers.findIndex((header) => header.toLowerCase() === 'title');
//     const jpDescIndex = headers.findIndex((header) => header.toLowerCase() === 'jp_desc');

//     if (titleIndex === -1) {
//       setError('Titleカラムが見つかりません');
//       return;
//     }

//     const productData = data.map((row, index) => ({
//       id: index,
//       title: row['Title'] || row['title'] || '',
//       jpDesc: row['JP_Desc'] || row['jp_desc'] || '',
//       generatedTitle: '',
//       selected: false,
//       editableJpDesc: row['JP_Desc'] || row['jp_desc'] || '',
//     }));

//     setProducts(productData);
//   };

//   // 商品の選択ハンドラー
//   const handleSelectProduct = (productId) => {
//     const updatedProducts = products.map((product) =>
//       product.id === productId ? { ...product, selected: !product.selected } : product
//     );
//     setProducts(updatedProducts);
//     updateDisplayedProducts(currentPage);
//   };

//   // すべての商品を選択/解除
//   const handleSelectAll = () => {
//     const allSelected = products.every((product) => product.selected);
//     const updatedProducts = products.map((product) => ({
//       ...product,
//       selected: !allSelected,
//     }));
//     setProducts(updatedProducts);
//     updateDisplayedProducts(currentPage);
//   };

//   // 生成されたタイトルの編集ハンドラー
//   const handleGeneratedTitleChange = (event, productId) => {
//     const updatedProducts = products.map((product) =>
//       product.id === productId ? { ...product, generatedTitle: event.target.value } : product
//     );
//     setProducts(updatedProducts);
//     updateDisplayedProducts(currentPage);
//   };

//   // AIによるタイトル生成（デフォルトプロンプト）
//   const generateProductTitles = async () => {
//     if (!apiKey) {
//       setError('OpenAI APIキーが設定されていません');
//       setSnackbarMessage('OpenAI APIキーが必要です');
//       setSnackbarSeverity('error');
//       setSnackbarOpen(true);
//       return;
//     }

//     const selectedProductsData = products.filter((product) => product.selected);
//     if (selectedProductsData.length === 0) {
//       setError('少なくとも一つの商品を選択してください');
//       setSnackbarMessage('商品が選択されていません');
//       setSnackbarSeverity('warning');
//       setSnackbarOpen(true);
//       return;
//     }

//     setLoading(true);
//     setError('');
//     setMessage('');
//     setProgress(0);

//     try {
//       const total = selectedProductsData.length;
//       let completed = 0;

//       const updatedProducts = [...products];

//       const generateTitleTasks = selectedProductsData.map((product) => async () => {
//         const index = products.findIndex((p) => p.id === product.id);
//         try {
//           const generatedTitle = await generateSingleTitle(
//             product.title,
//             product.editableJpDesc,
//             apiKey
//           );
//           updatedProducts[index].generatedTitle = generatedTitle;
//           completed++;
//           setProgress(Math.round((completed / total) * 100));
//         } catch (err) {
//           console.error(`商品ID ${product.id} のタイトル生成エラー:`, err);
//           updatedProducts[index].generatedTitle = '';
//           setError(`タイトルの生成に失敗しました: ${err.message}`);
//         }
//       });

//       await queue.addAll(generateTitleTasks);

//       setProducts(updatedProducts);
//       setMessage('タイトルの生成が完了しました');
//       setSnackbarMessage('タイトルの生成が完了しました');
//       setSnackbarSeverity('success');
//       setSnackbarOpen(true);
//       updateDisplayedProducts(currentPage);
//     } catch (err) {
//       console.error('タイトル生成エラー:', err);
//       setError(`タイトルの生成に失敗しました: ${err.message}`);
//       setSnackbarMessage(`エラー: ${err.message}`);
//       setSnackbarSeverity('error');
//       setSnackbarOpen(true);
//     } finally {
//       setLoading(false);
//       setProgress(0);
//     }
//   };

//   // 単一の商品タイトルを生成
//   async function generateSingleTitle(originalTitle, jpDesc, apiKey) {
//     const cacheKey = originalTitle + jpDesc.slice(0, 150);
//     const cachedTitle = getCachedTitle(cacheKey);
//     if (cachedTitle) {
//       return cachedTitle;
//     }

//     try {
//       const url = 'https://api.openai.com/v1/chat/completions';
//       const limitedJpDesc = jpDesc.slice(0, 150);
//       const requestData = {
//         model: 'gpt-4o-2024-08-06',
//         messages: [
//           {
//             role: 'system',
//             content: `You are an AI assistant specializing in eBay product title optimization. Based on the given original title and Japanese product information, create an effective English title within 80 characters.
// Order of title composition:
// 1. brand name (if applicable)
// 2. model name or product name/character name (if applicable)
// 3. product type (if applicable)
// 4. key features (color, material, size) (if applicable)
// 5. distinctive elements or uniqueness (if applicable)
// 6. condition (new/used) (if applicable)
// 7. important keywords (if applicable)
// NOTES:
// - Add or optimize new information while retaining important information from the original title
// - Avoid unnecessary adjectives and words such as “eBay
// - Use abbreviations and common names appropriately
// - Think from the searcher's perspective and include search terms that buyers are likely to use
// - If information is unclear or ambiguous, omit it.
// - Use only reliable information and do not include guesswork or uncertain information.
// - Do not extract from Japanese descriptions that are a list of words.
// - Titles should be generated in English and should not exceed 80 characters.`,
//           },
//           {
//             role: 'user',
//             content: `元のタイトル：「${originalTitle}」
// 日本語の商品説明：「${limitedJpDesc}」
// この情報を基に、最適化された新しいeBayの商品タイトルを生成してください。また日本語の商品説明が空の場合は元のタイトルから生成してください`,
//           },
//         ],
//         max_tokens: 100,
//       };

//       const response = await axios.post(url, requestData, {
//         headers: { Authorization: `Bearer ${apiKey}` },
//       });

//       const generatedTitle = response.data.choices[0].message.content.trim();
//       setCachedTitle(cacheKey, generatedTitle);
//       return generatedTitle;
//     } catch (error) {
//       throw error;
//     }
//   }

//   // 生成されたタイトルをデータに反映
//   const applyUpdatedTitles = () => {
//     const updatedProducts = products.map((product) => {
//       if (product.selected && product.generatedTitle) {
//         const updatedProduct = { ...data[product.id], Title: product.generatedTitle };
//         return updatedProduct;
//       }
//       return data[product.id];
//     });

//     setData(updatedProducts);
//     setSnackbarMessage('生成されたタイトルをデータに反映しました');
//     setSnackbarSeverity('success');
//     setSnackbarOpen(true);
//     setOpen(false);
//   };

//   // カテゴリーの変更ハンドラー
//   const handleCategoryChange = (event) => {
//     const selectedName = event.target.value;
//     setSelectedCategory(selectedName);

//     if (selectedName) {
//       const category = customCategories.find((cat) => cat.name === selectedName);
//       if (category) {
//         setDeleteStrings(category.deleteStrings || []);
//         setReplacePairs(category.replacePairs || []);
//         setPrependText(category.prependText || '');
//         setAppendText(category.appendText || '');
//         setLimitTitleLength(category.limitTitleLength || false);
//         setCategoryName(category.name);
//       }
//     } else {
//       // 新しいカテゴリーを作成する場合、初期状態にリセット
//       resetCustomizationSettings();
//     }
//   };

//   // カスタマイズ設定を初期状態にリセット
//   const resetCustomizationSettings = () => {
//     setDeleteStrings([]);
//     setReplacePairs([]);
//     setPrependText('');
//     setAppendText('');
//     setLimitTitleLength(false);
//     setCategoryName('');
//     setSelectedCategory('');
//   };

//   // 新しいカテゴリーを作成
//   const handleCreateNewCategory = () => {
//     // カテゴリー選択をクリアし、初期状態にリセット
//     resetCustomizationSettings();
//   };

//   // カテゴリーを保存または更新する
//   const saveOrUpdateCategory = () => {
//     if (!categoryName.trim()) {
//       setSnackbarMessage('カテゴリー名を入力してください');
//       setSnackbarSeverity('warning');
//       setSnackbarOpen(true);
//       return;
//     }

//     const newCategory = {
//       name: categoryName.trim(),
//       deleteStrings,
//       replacePairs,
//       prependText,
//       appendText,
//       limitTitleLength,
//     };

//     let updatedCategories;
//     if (selectedCategory) {
//       // 既存のカテゴリーを更新
//       updatedCategories = customCategories.map((cat) =>
//         cat.name === selectedCategory ? newCategory : cat
//       );
//       setSnackbarMessage('カテゴリーを更新しました');
//     } else {
//       // 新しいカテゴリーを追加
//       updatedCategories = [...customCategories, newCategory];
//       setSnackbarMessage('新しいカテゴリーを保存しました');
//     }

//     setCustomCategories(updatedCategories);
//     if (user) {
//       saveCategoriesToFirestore(user.uid, updatedCategories);
//     }
//     setSnackbarSeverity('success');
//     setSnackbarOpen(true);
//     setSelectedCategory(newCategory.name);
//   };

//   // カテゴリーを削除する
//   const deleteCategory = () => {
//     if (!selectedCategory) return;

//     const updatedCategories = customCategories.filter((cat) => cat.name !== selectedCategory);
//     setCustomCategories(updatedCategories);
//     if (user) {
//       saveCategoriesToFirestore(user.uid, updatedCategories);
//     }
//     resetCustomizationSettings();
//     setSnackbarMessage('カテゴリーを削除しました');
//     setSnackbarSeverity('info');
//     setSnackbarOpen(true);
//   };

//   // タイトルカスタマイズの適用
//   const customizeTitles = () => {
//     const updatedProducts = products.map((product) => {
//       if (product.selected && product.generatedTitle) {
//         let newTitle = product.generatedTitle;

//         // 複数の削除文字列を処理
//         deleteStrings.forEach((deleteStr) => {
//           if (deleteStr) {
//             const regex = new RegExp(deleteStr, 'g');
//             newTitle = newTitle.replace(regex, '');
//           }
//         });

//         // 複数の置換ペアを処理
//         replacePairs.forEach((pair) => {
//           const { find, replace } = pair;
//           if (find) {
//             const regex = new RegExp(find, 'g');
//             newTitle = newTitle.replace(regex, replace);
//           }
//         });

//         // 先頭と末尾の追加文字列を処理
//         if (prependText) {
//           newTitle = `${prependText} ${newTitle}`;
//         }
//         if (appendText) {
//           newTitle = `${newTitle} ${appendText}`;
//         }

//         // タイトルを80文字に制限
//         if (limitTitleLength) {
//           newTitle = newTitle.slice(0, 80);
//         }

//         return { ...product, generatedTitle: newTitle };
//       }
//       return product;
//     });
//     setProducts(updatedProducts);
//     updateDisplayedProducts(currentPage);

//     setSnackbarMessage('タイトルをカスタマイズしました');
//     setSnackbarSeverity('success');
//     setSnackbarOpen(true);
//   };

//   // キャッシュをクリア
//   const handleClearTitleCache = () => {
//     clearTitleCache();
//     setSnackbarMessage('キャッシュをクリアしました');
//     setSnackbarSeverity('info');
//     setSnackbarOpen(true);
//   };

//   // 削除文字列のチップ関連の関数
//   const handleDeleteStringKeyPress = (event) => {
//     if (event.key === 'Enter' && event.target.value.trim() !== '') {
//       setDeleteStrings([...deleteStrings, event.target.value.trim()]);
//       event.target.value = '';
//     }
//   };

//   const handleDeleteChip = (chipToDelete) => {
//     setDeleteStrings((chips) => chips.filter((chip) => chip !== chipToDelete));
//   };

//   // 置換ペア関連の関数
//   const handleAddReplacePair = () => {
//     setReplacePairs([...replacePairs, { find: '', replace: '' }]);
//   };

//   const handleReplacePairChange = (index, field, value) => {
//     const newReplacePairs = [...replacePairs];
//     newReplacePairs[index][field] = value;
//     setReplacePairs(newReplacePairs);
//   };

//   const handleRemoveReplacePair = (index) => {
//     const newReplacePairs = replacePairs.filter((_, i) => i !== index);
//     setReplacePairs(newReplacePairs);
//   };

//   // 商品説明の編集
//   const handleEditJpDesc = (productId, newDesc) => {
//     const updatedProducts = products.map((product) =>
//       product.id === productId ? { ...product, editableJpDesc: newDesc } : product
//     );
//     setProducts(updatedProducts);
//     updateDisplayedProducts(currentPage);
//   };

//   const handleOpen = () => {
//     setOpen(true);
//   };

//   const handleClose = () => {
//     setOpen(false);
//   };

//   // 生成されたタイトルを更新するための関数
//   const updateProducts = (updatedProducts) => {
//     const newProducts = products.map((product) => {
//       const updatedProduct = updatedProducts.find((p) => p.id === product.id);
//       return updatedProduct ? updatedProduct : product;
//     });
//     setProducts(newProducts);
//     updateDisplayedProducts(currentPage);
//   };

//   return (
//     <Paper
//       elevation={3}
//       sx={{
//         p: 4,
//         mt: 1,
//         mb: 1,
//         maxWidth: '100%',
//         margin: '0 auto',
//         overflow: 'hidden',
//       }}
//     >
//       <Box sx={{ maxWidth: '1600px', margin: '0 auto' }}>
//         {/* ヘッダーセクション */}
//         <Grid container spacing={3} alignItems="center">
//           <Grid item xs={12} md={6}>
//             <Typography variant="h5" gutterBottom>
//               AIを活用したタイトル生成
//             </Typography>
//             <Typography variant="body1" paragraph>
//               日本語の商品説明から英語の商品タイトルを自動生成します。
              
//             </Typography>
//           </Grid>
//           <Grid item xs={12} md={6}>
//             <Typography variant="subtitle1" gutterBottom>
//               主な機能：
//             </Typography>
//             <Grid container spacing={1}>
//               {[
//                 '日本語の商品説明から英語の商品タイトルを生成',
//                 'タイトルの一括編集とカスタマイズ',
//               ].map((feature, index) => (
//                 <Grid item xs={6} key={index}>
//                   <Typography variant="body2">• {feature}</Typography>
//                 </Grid>
//               ))}
//             </Grid>
//           </Grid>
//         </Grid>

//         {/* アクションボタンセクション */}
//         <Box sx={{ mt: 3, display: 'flex', gap: '10px', justifyContent: 'center' }}>
//           <Button variant="contained" color="primary" onClick={handleOpen} startIcon={<Add />}>
//             タイトルをAIで生成する
//           </Button>
//           <Button
//             variant="outlined"
//             color="primary"
//             onClick={handleClearTitleCache}
//             startIcon={<FormatColorResetIcon />}
//           >
//             キャッシュをクリア
//           </Button>
//         </Box>

//         {/* ダイアログセクション */}
//         <Dialog open={open} onClose={handleClose} fullScreen>
//           <DialogTitle>AIでタイトルを生成する</DialogTitle>
//           <DialogContent>
//             {/* レイアウトを左右に分割 */}
//             <Grid container spacing={2}>
//               {/* 左側のセクション */}
//               <Grid
//                 item
//                 xs={12}
//                 md={2}
//                 sx={{
//                   maxHeight: 'calc(100vh - 32px)',
//                   overflowY: 'auto',
//                 }}
//               >
//                 {/* カテゴリー選択とカスタマイズ */}
//                 <Typography variant="h6">タイトルカスタマイズ</Typography>
//                 <Grid container spacing={2}>
//                   <Grid item xs={12}>
//                     <FormControl fullWidth margin="normal">
//                       <InputLabel id="category-select-label">カテゴリーを選択</InputLabel>
//                       <Select
//                         labelId="category-select-label"
//                         value={selectedCategory}
//                         onChange={handleCategoryChange}
//                       >
//                         {customCategories.map((cat) => (
//                           <MenuItem key={cat.name} value={cat.name}>
//                             {cat.name}
//                           </MenuItem>
//                         ))}
//                       </Select>
//                     </FormControl>
//                   </Grid>
//                   <Grid item xs={12}>
//                     <TextField
//                       label="カテゴリー名"
//                       fullWidth
//                       margin="normal"
//                       value={categoryName}
//                       onChange={(e) => setCategoryName(e.target.value)}
//                       placeholder="新しいカテゴリー名を入力"
//                     />
//                     <Button
//                       variant="outlined"
//                       color="primary"
//                       onClick={handleCreateNewCategory}
//                       sx={{ mt: 1 }}
//                     >
//                       新しいカテゴリーを作成
//                     </Button>
//                   </Grid>
//                   {/* 削除する文字列 */}
//                   <Grid item xs={12}>
//                     <Typography variant="subtitle1">削除する文字列</Typography>
//                     <TextField
//                       placeholder="削除したい文字列を入力し、Enterキーを押してください"
//                       onKeyPress={handleDeleteStringKeyPress}
//                       fullWidth
//                     />
//                     <Box sx={{ mt: 1, display: 'flex', flexWrap: 'wrap', gap: 1 }}>
//                       {deleteStrings.map((string, index) => (
//                         <Chip key={index} label={string} onDelete={() => handleDeleteChip(string)} />
//                       ))}
//                     </Box>
//                   </Grid>
//                   {/* 置換ペア */}
//                   <Grid item xs={12}>
//                     <Typography variant="subtitle1">置換ペア</Typography>
//                     {replacePairs.map((pair, index) => (
//                       <Box
//                         key={index}
//                         sx={{ display: 'flex', gap: 1, alignItems: 'center', mt: 1 }}
//                       >
//                         <TextField
//                           label="置換する文字列"
//                           value={pair.find}
//                           onChange={(e) => handleReplacePairChange(index, 'find', e.target.value)}
//                         />
//                         <TextField
//                           label="置換後の文字列"
//                           value={pair.replace}
//                           onChange={(e) => handleReplacePairChange(index, 'replace', e.target.value)}
//                         />
//                         <IconButton color="secondary" onClick={() => handleRemoveReplacePair(index)}>
//                           <Delete />
//                         </IconButton>
//                       </Box>
//                     ))}
//                     <Button
//                       variant="outlined"
//                       startIcon={<Add />}
//                       onClick={handleAddReplacePair}
//                       sx={{ mt: 2 }}
//                     >
//                       置換ペアを追加
//                     </Button>
//                   </Grid>
//                   {/* 先頭・末尾に追加する文字列 */}
//                   <Grid item xs={12}>
//                     <TextField
//                       label="先頭に追加する文字列"
//                       fullWidth
//                       margin="normal"
//                       value={prependText}
//                       onChange={(e) => setPrependText(e.target.value)}
//                       helperText="タイトルの先頭に追加します"
//                     />
//                     <TextField
//                       label="末尾に追加する文字列"
//                       fullWidth
//                       margin="normal"
//                       value={appendText}
//                       onChange={(e) => setAppendText(e.target.value)}
//                       helperText="タイトルの末尾に追加します"
//                     />
//                   </Grid>
//                   <Grid item xs={12}>
//                     <FormControlLabel
//                       control={
//                         <Switch
//                           checked={limitTitleLength}
//                           onChange={(e) => setLimitTitleLength(e.target.checked)}
//                         />
//                       }
//                       label="80文字に制限する"
//                     />
//                   </Grid>
//                   {/* カテゴリーの保存・更新・削除ボタン */}
//                   <Grid item xs={12}>
//                     <Button
//                       variant="contained"
//                       color="primary"
//                       onClick={saveOrUpdateCategory}
//                       disabled={!categoryName.trim()}
//                       fullWidth
//                     >
//                       {selectedCategory ? 'カテゴリーを更新' : 'カテゴリーを保存'}
//                     </Button>
//                     {selectedCategory && (
//                       <Button
//                         variant="outlined"
//                         color="secondary"
//                         onClick={deleteCategory}
//                         fullWidth
//                         sx={{ mt: 1 }}
//                       >
//                         カテゴリーを削除
//                       </Button>
//                     )}
//                   </Grid>
//                   {/* タイトルカスタマイズの適用ボタン */}
//                   <Grid item xs={12}>
//                     <Button
//                       variant="contained"
//                       color="secondary"
//                       onClick={customizeTitles}
//                       fullWidth
//                       sx={{ mt: 2 }}
//                     >
//                       タイトルカスタマイズを適用
//                     </Button>
//                   </Grid>
//                   {/* 表示件数選択コンポーネントを追加 */}
//                   <Grid item xs={12}>
//                     <FormControl variant="outlined" size="small" fullWidth sx={{ mt: 2 }}>
//                       <InputLabel id="items-per-page-label">表示件数</InputLabel>
//                       <Select
//                         labelId="items-per-page-label"
//                         value={itemsPerPage}
//                         onChange={(e) => {
//                           setItemsPerPage(e.target.value);
//                           setCurrentPage(1);
//                         }}
//                         label="表示件数"
//                       >
//                         {ITEMS_PER_PAGE_OPTIONS.map((option, index) => (
//                           <MenuItem key={index} value={option}>
//                             {option === 'All' ? '全て' : `${option}件ずつ`}
//                           </MenuItem>
//                         ))}
//                       </Select>
//                     </FormControl>
//                   </Grid>
//                 </Grid>
//               </Grid>

//               {/* 右側のセクション */}
//               <Grid
//                 item
//                 xs={12}
//                 md={10}
//                 sx={{
//                   maxHeight: 'calc(100vh - 32px)',
//                   overflowY: 'auto',
//                 }}
//               >
//                 {/* アクションボタン */}
//                 <Grid container spacing={2} style={{ marginBottom: '20px' }}>
//                   <Grid item>
//                     <Button
//                       variant="contained"
//                       color="primary"
//                       onClick={generateProductTitles}
//                       disabled={loading || !apiKey}
//                     >
//                       AIでタイトルを生成する
//                     </Button>
//                   </Grid>
//                   <Grid item>
//                     <Button
//                       variant="contained"
//                       color="secondary"
//                       onClick={applyUpdatedTitles}
//                       disabled={loading}
//                     >
//                       タイトルをデータに反映する
//                     </Button>
//                   </Grid>
//                   <Grid item>
//                     <Button
//                       variant="contained"
//                       color="success"
//                       onClick={() => setCustomPromptDialogOpen(true)}
//                       startIcon={<EditIcon />}
//                       disabled={loading}
//                     >
//                       カスタムプロンプトで生成
//                     </Button>
//                   </Grid>
//                 </Grid>

//                 {/* 進捗状況表示 */}
//                 {loading && (
//                   <Box sx={{ width: '100%', mb: 2 }}>
//                     <LinearProgress variant="determinate" value={progress} />
//                     <Typography variant="body2" color="text.secondary" align="center">
//                       {`${Math.round(progress)}%`}
//                     </Typography>
//                   </Box>
//                 )}

//                 {/* エラーメッセージ */}
//                 {error && (
//                   <Typography color="error" style={{ marginTop: '20px' }}>
//                     {error}
//                   </Typography>
//                 )}
//                 {/* 成功メッセージ */}
//                 {message && (
//                   <Typography color="primary" style={{ marginTop: '20px' }}>
//                     {message}
//                   </Typography>
//                 )}

//                 {/* 商品テーブル */}
//                 <TableContainer component={Paper}>
//                   <Table stickyHeader aria-label="products-table">
//                     <TableHead>
//                       <TableRow>
//                         <TableCell padding="checkbox">
//                           <Checkbox
//                             checked={products.every((product) => product.selected)}
//                             onChange={handleSelectAll}
//                             indeterminate={
//                               products.some((product) => product.selected) &&
//                               !products.every((product) => product.selected)
//                             }
//                           />
//                         </TableCell>
//                         <TableCell>商品タイトル</TableCell>
//                         <TableCell>日本語の商品説明</TableCell>
//                         <TableCell>生成されたタイトル</TableCell>
//                       </TableRow>
//                     </TableHead>
//                     <TableBody>
//                       {displayedProducts.map((product) => (
//                         <TableRow key={product.id} hover>
//                           <TableCell padding="checkbox">
//                             <Checkbox
//                               checked={product.selected}
//                               onChange={() => handleSelectProduct(product.id)}
//                               onClick={(e) => e.stopPropagation()}
//                             />
//                           </TableCell>
//                           <TableCell
//                             sx={{
//                               fontSize: '0.8rem',
//                               whiteSpace: 'pre-wrap',
//                               wordWrap: 'break-word',
//                               maxWidth: '300px',
//                               padding: '4px',
//                             }}
//                           >
//                             {product.title}
//                           </TableCell>
//                           <TableCell
//                             sx={{
//                               padding: '4px',
//                             }}
//                           >
//                             <TextField
//                               multiline
//                               fullWidth
//                               variant="outlined"
//                               value={product.editableJpDesc}
//                               onChange={(e) => handleEditJpDesc(product.id, e.target.value)}
//                               onFocus={() => setFocusedProductId(product.id)}
//                               onBlur={() => setFocusedProductId(null)}
//                               InputProps={{
//                                 sx: {
//                                   textarea: {
//                                     maxHeight: focusedProductId === product.id ? 'none' : '8em',
//                                     overflow: 'auto',
//                                     transition: 'max-height 0.3s ease',
//                                   },
//                                 },
//                               }}
//                               inputProps={{
//                                 style: {
//                                   fontSize: '0.8rem',
//                                   padding: '2px',
//                                   lineHeight: '1.2',
//                                 },
//                               }}
//                             />
//                           </TableCell>
//                           <TableCell
//                             sx={{
//                               padding: '4px',
//                             }}
//                           >
//                             <TextField
//                               multiline
//                               fullWidth
//                               variant="outlined"
//                               value={product.generatedTitle}
//                               onChange={(event) => handleGeneratedTitleChange(event, product.id)}
//                               inputProps={{
//                                 style: {
//                                   fontSize: '0.8rem',
//                                   padding: '2px',
//                                   lineHeight: '1.2',
//                                 },
//                               }}
//                               sx={{
//                                 margin: 0,
//                               }}
//                             />
//                           </TableCell>
//                         </TableRow>
//                       ))}
//                     </TableBody>
//                   </Table>
//                 </TableContainer>

//                 {/* ページネーション */}
//                 {itemsPerPage !== 'All' && (
//                   <Pagination
//                     count={totalPages}
//                     page={currentPage}
//                     onChange={handlePageChange}
//                     color="primary"
//                     style={{ marginTop: '20px', display: 'flex', justifyContent: 'center' }}
//                   />
//                 )}
//               </Grid>
//             </Grid>
//           </DialogContent>
//           <DialogActions>
//             <Button onClick={handleClose}>閉じる</Button>
//           </DialogActions>
//         </Dialog>

//         {/* カスタムプロンプトダイアログ */}
//         <CustomPromptDialog
//           open={customPromptDialogOpen}
//           onClose={() => setCustomPromptDialogOpen(false)}
//           apiKey={apiKey}
//           selectedProducts={products.filter((product) => product.selected)}
//           updateProducts={updateProducts}
//         />

//         {/* スナックバー通知 */}
//         <Snackbar
//           anchorOrigin={{
//             vertical: 'bottom',
//             horizontal: 'left',
//           }}
//           open={snackbarOpen}
//           autoHideDuration={6000}
//           onClose={() => setSnackbarOpen(false)}
//         >
//           <Alert
//             onClose={() => setSnackbarOpen(false)}
//             severity={snackbarSeverity}
//             sx={{ width: '100%' }}
//           >
//             {snackbarMessage}
//           </Alert>
//         </Snackbar>
//       </Box>
//     </Paper>
//   );
// };

// export default TitleGenerationComponent;