// titleCache.js

// キャッシュの管理
let titleCache = {};

// キャッシュをローカルストレージに保存
export function saveCacheToLocalStorage() {
  localStorage.setItem('titleGenerationCache', JSON.stringify(titleCache));
}

// キャッシュをローカルストレージから読み込み
export function loadCacheFromLocalStorage() {
  const savedCache = localStorage.getItem('titleGenerationCache');
  if (savedCache) {
    titleCache = JSON.parse(savedCache);
  }
}

// キャッシュからタイトルを取得
export function getCachedTitle(cacheKey) {
  return titleCache[cacheKey];
}

// キャッシュにタイトルを保存
export function setCachedTitle(cacheKey, generatedTitle) {
  titleCache[cacheKey] = generatedTitle;
  saveCacheToLocalStorage();
}

// キャッシュをクリア
export function clearTitleCache() {
  titleCache = {};
  saveCacheToLocalStorage();
}
