// App.js

import React, { useState, useEffect } from 'react';
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
  useNavigate,
  Link as RouterLink,
} from 'react-router-dom';
import {
  signOut,
  onAuthStateChanged,
  getAuth,
} from 'firebase/auth';
import {
  getFunctions,
} from 'firebase/functions';
import {
  getFirestore,
  collection,
  query,
  where,
  onSnapshot,
  orderBy,
  limit,
  doc,
  setDoc,
  getDoc,
  updateDoc,
} from 'firebase/firestore';
import { auth, db, app } from './firebaseConfig';
import {
  Box,
  Button,
  Typography,
  Grid,
  Paper,
  AppBar,
  Toolbar,
  IconButton,
  Drawer,
  Dialog,
  DialogTitle,
  DialogContent,
  Snackbar,
  Alert,
  Link,
  CircularProgress,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  DialogActions,
  Badge,
  Menu,
  MenuItem,
} from '@mui/material';
import {
  PhotoCamera as PhotoCameraIcon,
  Link as LinkIcon,
  Menu as MenuIcon,
  Help as HelpIcon,
  CloudUpload as CloudUploadIcon,
  Settings as SettingsIcon,
  AttachMoney as AttachMoneyIcon,
  Launch as LaunchIcon,
  Save as SaveIcon,
  VpnKey as VpnKeyIcon,
  Notifications as NotificationsIcon,
} from '@mui/icons-material';
import Papa from 'papaparse';
import CryptoJS from 'crypto-js';
import EditableSpreadsheet from './EditableSpreadsheet';
import ColumnNameBasedTemplateManager from './ColumnNameBasedTemplateManager';
import InteractiveManual from './InteractiveManual';
import TitleGenerationComponent from './TitleGenerationComponent';
import ColumnDeletion from './ColumnDeletion';
import APIKeyInput from './APIKeyInput';
import ImageDownloader from './ImageDownloader';
import ImageUrlReplacer from './ImageUrlReplacer';
import CustomColumnSettings from './CustomColumnSettings';
import PriceCalculationSettings from './PriceCalculationSettings';
import OpenAIDescriptionGenerator from './OpenAIDescriptionGenerator';
import ItemSpecificsSettings from './ItemSpecificsSettings';
import ItemSpecificsApplier from './ItemSpecificsApplier';
import TermsOfService from './TermsOfService';
import PrivacyPolicy from './PrivacyPolicy';
import LegalDisclosure from './LegalDisclosure';
import Login from './Login';
import AdminNotificationSender from './AdminNotificationSender';
import BulkListingFromScratch from './BulkListingFromScratch'; // 新しいコンポーネントをインポート
import { clearTitleCache } from './titleCache';
import { clearDescriptionCache } from './descriptionCache';

import './App.css';

// Firebase Storage関連のインポート
import { getStorage, ref, uploadString, getBlob } from 'firebase/storage';

// 通知コンポーネント
function Notifications({ user }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [notifications, setNotifications] = useState([]);

  useEffect(() => {
    if (!user) return;

    // ユーザーごとの通知を取得するクエリを作成
    const q = query(
      collection(db, 'notifications'),
      where('userId', '==', user.uid),
      orderBy('timestamp', 'desc'),
      limit(20)
    );

    // リアルタイムリスナーを設定
    const unsubscribe = onSnapshot(
      q,
      (querySnapshot) => {
        const notificationsData = [];
        querySnapshot.forEach((doc) => {
          notificationsData.push({ id: doc.id, ...doc.data() });
        });
        setNotifications(notificationsData);
      },
      (error) => {
        console.error('通知の取得中にエラーが発生しました:', error);
        // 必要に応じてエラーメッセージを表示
      }
    );

    // クリーンアップ関数を返す
    return () => unsubscribe();
  }, [user]);

  // メニューを開く処理
  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  // メニューを閉じる処理
  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  // 通知をクリックしたときに既読にする
  const handleNotificationClick = (notificationId) => {
    const notificationRef = doc(db, 'notifications', notificationId);
    updateDoc(notificationRef, { read: true })
      .catch((error) => {
        console.error('通知の更新中にエラーが発生しました:', error);
      });
    handleMenuClose();
  };

  // 未読の通知数を計算
  const unreadCount = notifications.filter(n => !n.read).length;

  return (
    <div>
      <IconButton color="inherit" onClick={handleMenuOpen}>
        <Badge badgeContent={unreadCount} color="secondary">
          <NotificationsIcon />
        </Badge>
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
      >
        {notifications.length === 0 ? (
          <MenuItem onClick={handleMenuClose}>新しいお知らせはありません</MenuItem>
        ) : (
          notifications.map((notification) => (
            <MenuItem key={notification.id} onClick={() => handleNotificationClick(notification.id)}>
              {notification.message}
            </MenuItem>
          ))
        )}
      </Menu>
    </div>
  );
}

function AppContent({ setIsAuthenticated }) {
  const [user, setUser] = useState(null);
  const [userIsAdmin, setUserIsAdmin] = useState(false);
  const [csvData, setCsvData] = useState([]);
  const [columns, setColumns] = useState([]);
  const [csvFileName, setCsvFileName] = useState('');
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [isHelpOpen, setIsHelpOpen] = useState(false);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'info' });
  const [apiKey, setApiKey] = useState('');
  const [isApiKeyDialogOpen, setIsApiKeyDialogOpen] = useState(false);
  const [customSettings, setCustomSettings] = useState(null);
  const [isCustomSettingsOpen, setIsCustomSettingsOpen] = useState(false);
  const [isItemSpecificsSettingsOpen, setIsItemSpecificsSettingsOpen] = useState(false);
  const [itemSpecificsSettings, setItemSpecificsSettings] = useState([]);
  const navigate = useNavigate();

  const functions = getFunctions(app);
  const auth = getAuth();

  // ユーザー固有の秘密鍵を生成
  const generateEncryptionKey = (uid) => {
    const salt = 'your-fixed-salt-value'; // 固定の塩値（セキュリティ向上のため、環境変数で管理することを推奨）
    return CryptoJS.PBKDF2(uid, salt, { keySize: 256 / 32 }).toString();
  };

  // サインアウト処理
  const handleSignOut = async () => {
    try {
      await signOut(auth);
      // ローカルストレージをクリア
      localStorage.clear();
      setIsAuthenticated(false);
      navigate('/login');
    } catch (error) {
      console.error('サインアウト中にエラーが発生しました:', error);
      setSnackbar({ open: true, message: 'サインアウト中にエラーが発生しました。', severity: 'error' });
    }
  };

  // 認証状態の監視と初期データの取得
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (currentUser) => {
      setUser(currentUser);
      if (!currentUser) {
        setIsAuthenticated(false);
        navigate('/login');
      } else {
        // 管理者権限の確認
        const idTokenResult = await currentUser.getIdTokenResult();
        setUserIsAdmin(!!idTokenResult.claims.admin);

        // FirestoreからAPIキーを取得
        try {
          const docRef = doc(db, 'userApiKeys', currentUser.uid);
          const docSnap = await getDoc(docRef);
          if (docSnap.exists()) {
            const encryptedKey = docSnap.data().apiKey;
            const encryptionKey = generateEncryptionKey(currentUser.uid);
            const bytes = CryptoJS.AES.decrypt(encryptedKey, encryptionKey);
            const decryptedKey = bytes.toString(CryptoJS.enc.Utf8);
            if (decryptedKey) {
              setApiKey(decryptedKey);
            } else {
              console.error('APIキーの復号に失敗しました。');
            }
          }
        } catch (error) {
          console.error('APIキーの取得エラー:', error);
        }

        // ローカルストレージからCSVデータとファイル名を取得
        const storedCsvData = localStorage.getItem('csvData');
        const storedColumns = localStorage.getItem('csvColumns');
        const storedFileName = localStorage.getItem('csvFileName');

        if (storedCsvData && storedColumns) {
          const data = JSON.parse(storedCsvData);
          const cols = JSON.parse(storedColumns);
          setCsvData(data);
          setColumns(cols);
          setCsvFileName(storedFileName || '');
          setSnackbar({ open: true, message: 'ローカルストレージから編集データを読み込みました。', severity: 'success' });
        } else {
          // Firebase Storageからデータを取得
          try {
            // Firestoreからメタデータを取得（ファイル名など）
            const docRef = doc(db, 'userData', currentUser.uid);
            const docSnap = await getDoc(docRef);
            let storedFileName = '';
            if (docSnap.exists()) {
              storedFileName = docSnap.data().csvFileName || '';
              setCsvFileName(storedFileName);
            }

            const storage = getStorage(app);
            const storageRef = ref(storage, `userData/${currentUser.uid}/csvData.json`);

            // getBlob を使用してデータを取得
            const blob = await getBlob(storageRef);
            const textData = await blob.text();
            const jsonData = JSON.parse(textData);

            setCsvData(jsonData);
            setColumns(Object.keys(jsonData[0] || {}));
            setSnackbar({ open: true, message: 'Firebase Storageから編集データを読み込みました。', severity: 'success' });

            // ローカルストレージにデータを保存
            localStorage.setItem('csvData', JSON.stringify(jsonData));
            localStorage.setItem('csvColumns', JSON.stringify(Object.keys(jsonData[0] || {})));
            localStorage.setItem('csvFileName', storedFileName);

          } catch (error) {
            console.error('Firebase Storageからデータを取得中にエラーが発生しました:', error);
            setSnackbar({ open: true, message: 'Firebase Storageからデータを取得できませんでした。', severity: 'error' });
          }
        }
      }
    });
    return () => unsubscribe();
  }, [navigate, setIsAuthenticated, auth]);

  // CSVファイルのインポート処理
  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      setCsvFileName(file.name); // ファイル名を保存
      Papa.parse(file, {
        header: true,
        skipEmptyLines: true,
        complete: (results) => {
          const data = results.data;
          setCsvData(data);
          setColumns(Object.keys(data[0] || {}));
          setSnackbar({ open: true, message: 'CSVファイルがインポートされました', severity: 'success' });

          // ローカルストレージにデータとファイル名を保存
          localStorage.setItem('csvData', JSON.stringify(data));
          localStorage.setItem('csvColumns', JSON.stringify(Object.keys(data[0] || {})));
          localStorage.setItem('csvFileName', file.name);

          // AIタイトル生成のキャッシュをクリア
          clearTitleCache();
          
          // AI商品説明生成のキャッシュをクリア
          clearDescriptionCache();
          
        },

        error: (error) => {
          console.error('CSVファイルの解析中にエラーが発生しました:', error);
          setSnackbar({ open: true, message: 'CSVファイルの解析中にエラーが発生しました', severity: 'error' });
        },
      });
    }
  };

  // Firebase Storageにデータを保存
  const handleSaveEditedData = async () => {
    if (csvData.length > 0 && user) {
      try {
        const storage = getStorage(app);
        const storageRef = ref(storage, `userData/${user.uid}/csvData.json`);
        const jsonData = JSON.stringify(csvData);

        await uploadString(storageRef, jsonData);
        setSnackbar({ open: true, message: '編集データがFirebase Storageに保存されました。', severity: 'success' });

        // Firestoreにもメタデータを保存（ファイル名やタイムスタンプなど）
        const docRef = doc(db, 'userData', user.uid);
        await setDoc(docRef, {
          csvFileName,
          timestamp: new Date(),
        }, { merge: true });

      } catch (error) {
        console.error('Firebase Storageへのデータ保存中にエラーが発生しました:', error);
        setSnackbar({ open: true, message: 'データの保存に失敗しました。', severity: 'error' });
      }
    } else {
      setSnackbar({ open: true, message: '保存するデータがありません。', severity: 'warning' });
    }
  };

  const toggleDrawer = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setIsDrawerOpen(open);
  };

  const handleOpenCustomSettings = () => {
    setIsCustomSettingsOpen(true);
    setIsDrawerOpen(false);
  };

  const handleCloseCustomSettings = () => {
    setIsCustomSettingsOpen(false);
  };

  const handleSaveCustomSettings = (settings) => {
    setCustomSettings(settings);
    setIsCustomSettingsOpen(false);
    localStorage.setItem('customPriceSettings', JSON.stringify(settings));
    setSnackbar({ open: true, message: '設定が保存されました', severity: 'success' });
  };

  const handleItemSpecificsSettingsOpen = () => {
    setIsItemSpecificsSettingsOpen(true);
    setIsDrawerOpen(false);
  };

  const handleItemSpecificsSettingsClose = () => {
    setIsItemSpecificsSettingsOpen(false);
  };

  const handleItemSpecificsSettingsSave = (settings) => {
    setItemSpecificsSettings(settings);
    handleItemSpecificsSettingsClose();
    setSnackbar({ open: true, message: 'Item Specifics設定が保存されました', severity: 'success' });
  };

  const handleApiKeySet = async (key) => {
    setApiKey(key);
    if (user) {
      try {
        const encryptionKey = generateEncryptionKey(user.uid);
        const encryptedKey = CryptoJS.AES.encrypt(key, encryptionKey).toString();
        // Firestoreに保存
        await setDoc(doc(db, 'userApiKeys', user.uid), { apiKey: encryptedKey }, { merge: true });
        setSnackbar({ open: true, message: 'APIキーが保存されました。', severity: 'success' });
      } catch (error) {
        console.error('APIキーの保存エラー:', error);
        setSnackbar({ open: true, message: `APIキーの保存に失敗しました: ${error.message}`, severity: 'error' });
      }
    } else {
      setSnackbar({ open: true, message: 'ユーザーが認証されていません。', severity: 'error' });
    }
  };

  // ハンバーガーメニューの項目
  const menuItems = [
    {
      text: 'APIキー管理',
      icon: <VpnKeyIcon />,
      action: () => {
        setIsApiKeyDialogOpen(true);
        setIsDrawerOpen(false);
      },
    },
    {
      text: '価格計算設定',
      icon: <SettingsIcon />,
      action: handleOpenCustomSettings,
    },
    {
      text: 'Item Specifics設定',
      icon: <SettingsIcon />,
      action: handleItemSpecificsSettingsOpen,
    },
    {
      text: 'マニュアル',
      icon: <HelpIcon />,
      action: () => {
        window.open('https://ebay-marketing-tool.com/ebay-spread-manual-price/', '_blank', 'noopener,noreferrer');
      },
      renderItem: (item) => (
        <>
          <ListItemIcon>{item.icon}</ListItemIcon>
          <ListItemText primary={item.text} />
          <Box component="span" sx={{ ml: 1 }}>
            <LaunchIcon fontSize="small" />
          </Box>
        </>
      ),
    },
  ];

  return (
    <Box sx={{ flexGrow: 1 }}>
      {/* アプリバー */}
      <AppBar position="static">
        <Toolbar>
          {/* ハンバーガーメニュー */}
          <IconButton size="large" edge="start" color="inherit" aria-label="menu" sx={{ mr: 2 }} onClick={toggleDrawer(true)}>
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" component="div">
            Specifics-IN ver 2.4.5
          </Typography>
          <Box sx={{ flexGrow: 1 }} />
          {/* ヘッダーバーに新しいリンクを追加 */}
          <Button color="inherit" component={RouterLink} to="/bulk-listing">
            0から一括出品
          </Button>
          {/* 管理者の場合、管理者ページへのリンクを表示 */}
          {userIsAdmin && (
            <Button color="inherit" component={RouterLink} to="/admin">
              管理者ページ
            </Button>
          )}
          {/* 通知アイコンを追加 */}
          <Notifications user={user} />
          {/* 既存のボタン */}
          <Button
            color="inherit"
            href="https://www.ebay.com/sh/reports/uploads"
            target="_blank"
            rel="noopener noreferrer"
            startIcon={<CloudUploadIcon />}
          >
            eBayアップロード
          </Button>
          <Button
            color="inherit"
            href="https://app.photoroom.com/batch"
            target="_blank"
            rel="noopener noreferrer"
            startIcon={<PhotoCameraIcon />}
          >
            Photoroom
          </Button>
          <Button
            color="inherit"
            href="https://postimages.org/#google_vignette"
            target="_blank"
            rel="noopener noreferrer"
            startIcon={<LinkIcon />}
          >
            画像URL変換
          </Button>
          <Button color="inherit" onClick={handleSignOut}>
            ログアウト
          </Button>
        </Toolbar>
      </AppBar>

      {/* ドロワーメニュー */}
      <Drawer
        anchor="left"
        open={isDrawerOpen}
        onClose={toggleDrawer(false)}
        sx={{
          '& .MuiDrawer-paper': {
            width: '200px',
            boxSizing: 'border-box',
          },
        }}
      >
        <List sx={{ width: '100%' }}>
          {menuItems.map((item, index) => (
            <ListItem
              button
              key={index}
              onClick={item.action}
              sx={{
                py: 2,
                '&:hover': {
                  backgroundColor: 'rgba(0, 0, 0, 0.04)',
                },
              }}
            >
              {item.renderItem ? (
                item.renderItem(item)
              ) : (
                <>
                  <ListItemIcon sx={{ minWidth: '56px' }}>{item.icon}</ListItemIcon>
                  <ListItemText
                    primary={item.text}
                    primaryTypographyProps={{
                      fontSize: '1.1rem',
                      fontWeight: 'medium',
                    }}
                  />
                </>
              )}
            </ListItem>
          ))}
        </List>
      </Drawer>

      {/* メインコンテンツ */}
      <Routes>
        <Route
          path="/bulk-listing"
          element={<BulkListingFromScratch user={user} />}
        />
        <Route
          path="/"
          element={
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Paper elevation={3} sx={{ p: 2 }}>
                  {/* CSVファイルのアップロードと編集データの保存 */}
                  <Paper elevation={3} sx={{ p: 2, mb: 2 }}>
                    <Grid container spacing={2} alignItems="center">
                      {/* CSVファイルのインポート */}
                      <Grid item xs={12} sm={8} md={9}>
                        <Typography variant="h6" gutterBottom>
                          CSVファイルのインポート
                        </Typography>
                        <input
                          accept=".csv"
                          style={{ display: 'none' }}
                          id="contained-button-file"
                          type="file"
                          onChange={handleFileUpload}
                        />
                        <label htmlFor="contained-button-file">
                          <Button
                            variant="contained"
                            component="span"
                            startIcon={<CloudUploadIcon />}
                            sx={{
                              backgroundColor: '#1976d2',
                              '&:hover': {
                                backgroundColor: '#1565c0',
                              },
                            }}
                          >
                            CSVファイルをインポート
                          </Button>
                        </label>
                        {csvFileName && (
                          <Typography variant="body1" sx={{ mt: 2 }}>
                            現在のCSVファイル: {csvFileName}
                          </Typography>
                        )}
                      </Grid>
                      {/* 編集データの保存ボタン */}
                      <Grid item xs={12} sm={4} md={3} sx={{ textAlign: 'right' }}>
                        <Button
                          variant="contained"
                          color="primary"
                          startIcon={<SaveIcon />}
                          onClick={handleSaveEditedData}
                          sx={{
                            mt: { xs: 2, sm: 0 },
                            backgroundColor: '#1976d2',
                            '&:hover': {
                              backgroundColor: '#1565c0',
                            },
                          }}
                        >
                          編集データを保存
                        </Button>
                      </Grid>
                    </Grid>
                  </Paper>

                  {/* インポートされたCSVデータの表示と編集 */}
                  {csvData.length > 0 && (
                    <Grid container spacing={4}>
                      {/* ColumnNameBasedTemplateManagerコンポーネント */}
                      <Grid item xs={12}>
                        <ColumnNameBasedTemplateManager
                          data={csvData}
                          setData={setCsvData}
                          columns={columns}
                          setColumns={setColumns}
                        />
                      </Grid>

                      {/* その他のコンポーネント */}
                      <Grid container spacing={4} sx={{ display: 'flex', p: 2 }}>
                        {/* ColumnDeletionコンポーネント */}
                        <Grid item xs={12} md={4}>
                          <Paper elevation={3} sx={{ flexGrow: 1, p: 2, m: 1 }}>
                            <ColumnDeletion
                              data={csvData}
                              setData={setCsvData}
                              columns={columns}
                              setColumns={setColumns}
                            />
                          </Paper>
                        </Grid>

                        {/* PriceCalculationSettingsコンポーネント */}
                        <Grid item xs={12} md={4}>
                          <Paper elevation={3} sx={{ flexGrow: 1, p: 2, m: 1 }}>
                            <PriceCalculationSettings
                              customSettings={customSettings}
                              data={csvData}
                              setData={setCsvData}
                            />
                          </Paper>
                        </Grid>

                        {/* ItemSpecificsApplierコンポーネント */}
                        <Grid item xs={12} md={4}>
                          <Paper elevation={3} sx={{ flexGrow: 1, p: 2, m: 1 }}>
                            <ItemSpecificsApplier data={csvData} setData={setCsvData} userId={user ? user.uid : null} />
                          </Paper>
                        </Grid>
                      </Grid>

                      {/* 画像関連のコンポーネント */}
                      <Grid container spacing={4} sx={{ display: 'flex', p: 2 }}>
                        <Grid item xs={12} md={6}>
                          <Paper elevation={3} sx={{ p: 3, mb: 0, ml: 1, mt: 0 }}>
                            <ImageDownloader data={csvData} setData={setCsvData} apiKey={apiKey} />
                          </Paper>
                        </Grid>

                        <Grid item xs={12} md={6}>
                          <Paper elevation={3} sx={{ p: 3, mb: 0 }}>
                            <ImageUrlReplacer data={csvData} setData={setCsvData} />
                          </Paper>
                        </Grid>
                      </Grid>

                      {/* AI機能のセクション */}
                      <Grid item xs={12}>
                        <AppBar position="static" color="primary" sx={{ mt: 0 }}>
                          <Toolbar>
                            <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                              AI機能 ※API利用
                            </Typography>
                          </Toolbar>
                        </AppBar>
                      </Grid>

                      {/* タイトル生成と商品説明生成のコンポーネント */}
                      <Grid container spacing={4} sx={{ display: 'flex', p: 2 }}>
                        <Grid item xs={12} md={6}>
                          <TitleGenerationComponent data={csvData} setData={setCsvData} apiKey={apiKey} user={user} />
                        </Grid>

                        <Grid item xs={12} md={6}>
                          <OpenAIDescriptionGenerator data={csvData} setData={setCsvData} apiKey={apiKey} user={user} />
                        </Grid>
                      </Grid>

                      {/* 編集可能なスプレッドシート */}
                      <Grid item xs={12}>
                        <EditableSpreadsheet
                          data={csvData}
                          setData={setCsvData}
                          columns={columns}
                          setColumns={setColumns}
                        />
                      </Grid>
                    </Grid>
                  )}
                </Paper>
              </Grid>
            </Grid>
          }
        />
      </Routes>

      {/* ヘルプのドロワー */}
      <Drawer anchor="right" open={isHelpOpen} onClose={() => setIsHelpOpen(false)}>
        <Box sx={{ width: 300, p: 2 }}>
          <InteractiveManual />
        </Box>
      </Drawer>

      {/* APIキー管理ダイアログ */}
      <Dialog open={isApiKeyDialogOpen} onClose={() => setIsApiKeyDialogOpen(false)}>
        <DialogTitle>APIキー管理</DialogTitle>
        <DialogContent>
          <APIKeyInput
            user={user}
            apiKey={apiKey}
            setApiKey={setApiKey}
            onApiKeySet={handleApiKeySet}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsApiKeyDialogOpen(false)}>閉じる</Button>
        </DialogActions>
      </Dialog>

      {/* 設定のダイアログ */}
      <Dialog open={isCustomSettingsOpen} onClose={handleCloseCustomSettings} fullWidth maxWidth="md">
        <DialogTitle>価格計算設定</DialogTitle>
        <DialogContent>
          <CustomColumnSettings
            onSave={handleSaveCustomSettings}
            initialSettings={customSettings}
            userId={user ? user.uid : null}
          />
        </DialogContent>
      </Dialog>

      <Dialog
        open={isItemSpecificsSettingsOpen}
        onClose={handleItemSpecificsSettingsClose}
        fullWidth
        maxWidth="md"
        fullScreen
      >
        <DialogTitle>Item Specificsの管理画面</DialogTitle>
        <DialogContent>
          <ItemSpecificsSettings
            onSave={handleItemSpecificsSettingsSave}
            onClose={handleItemSpecificsSettingsClose}
            headers={columns}
            userId={user ? user.uid : null}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleItemSpecificsSettingsClose}>閉じる</Button>
        </DialogActions>
      </Dialog>

      {/* Snackbarによる通知 */}
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
      >
        <Alert
          onClose={() => setSnackbar({ ...snackbar, open: false })}
          severity={snackbar.severity}
          sx={{ width: '100%' }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>

      {/* フッターのリンク */}
      <Box sx={{ mt: 4, textAlign: 'center' }}>
        <Typography variant="body2">
          <Link component={RouterLink} to="/terms">
            利用規約
          </Link>
          {' | '}
          <Link component={RouterLink} to="/privacy">
            プライバシーポリシー
          </Link>
          {' | '}
          <Link component={RouterLink} to="/legal-disclosure">
            特定商取引法に基づく表記
          </Link>
        </Typography>
      </Box>
    </Box>
  );
}

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  // 認証状態の監視
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setIsAuthenticated(!!user);
      setIsLoading(false);
    });
    return () => unsubscribe();
  }, []);

  if (isLoading) {
    return (
      <div style={{ textAlign: 'center', marginTop: '20%' }}>
        <CircularProgress />
      </div>
    );
  }

  return (
    <Router>
      <Routes>
        <Route
          path="/login"
          element={
            isAuthenticated ? <Navigate to="/" replace /> : <Login setIsAuthenticated={setIsAuthenticated} />
          }
        />
        <Route path="/terms" element={<TermsOfService />} />
        <Route path="/privacy" element={<PrivacyPolicy />} />
        <Route path="/legal-disclosure" element={<LegalDisclosure />} />
        <Route
          path="/admin"
          element={
            isAuthenticated ? (
              <AdminNotificationSender />
            ) : (
              <Navigate to="/login" replace />
            )
          }
        />
        <Route
          path="/bulk-listing"
          element={
            isAuthenticated ? (
              <BulkListingFromScratch user={auth.currentUser} />
            ) : (
              <Navigate to="/login" replace />
            )
          }
        />
        <Route
          path="/*"
          element={
            isAuthenticated ? (
              <AppContent setIsAuthenticated={setIsAuthenticated} />
            ) : (
              <Navigate to="/login" replace />
            )
          }
        />
        {/* 存在しないルートへの対応 */}
        <Route path="*" element={<Navigate to="/login" replace />} />
      </Routes>
    </Router>
  );
}

export default App;





// // App.js

// import React, { useState, useEffect } from 'react';
// import {
//   BrowserRouter as Router,
//   Route,
//   Routes,
//   Navigate,
//   useNavigate,
//   Link as RouterLink,
// } from 'react-router-dom';
// import {
//   signOut,
//   onAuthStateChanged,
//   getAuth,
// } from 'firebase/auth';
// import {
//   getFunctions,
//   httpsCallable,
// } from 'firebase/functions';
// import {
//   getFirestore,
//   collection,
//   query,
//   where,
//   onSnapshot,
//   orderBy,
//   limit,
//   doc,
//   setDoc,
//   getDoc,
//   updateDoc,
// } from 'firebase/firestore';
// import { auth, db, app } from './firebaseConfig';
// import {
//   Box,
//   Button,
//   Typography,
//   Grid,
//   Paper,
//   AppBar,
//   Toolbar,
//   IconButton,
//   Drawer,
//   Dialog,
//   DialogTitle,
//   DialogContent,
//   Snackbar,
//   Alert,
//   Link,
//   CircularProgress,
//   List,
//   ListItem,
//   ListItemIcon,
//   ListItemText,
//   DialogActions,
//   Badge,
//   Menu,
//   MenuItem,
// } from '@mui/material';
// import {
//   PhotoCamera as PhotoCameraIcon,
//   Link as LinkIcon,
//   Menu as MenuIcon,
//   Help as HelpIcon,
//   CloudUpload as CloudUploadIcon,
//   Settings as SettingsIcon,
//   AttachMoney as AttachMoneyIcon,
//   Launch as LaunchIcon,
//   Save as SaveIcon,
//   VpnKey as VpnKeyIcon,
//   Notifications as NotificationsIcon,
// } from '@mui/icons-material';
// import Papa from 'papaparse';
// import CryptoJS from 'crypto-js';
// import EditableSpreadsheet from './EditableSpreadsheet';
// import ColumnNameBasedTemplateManager from './ColumnNameBasedTemplateManager';
// import InteractiveManual from './InteractiveManual';
// import TitleGenerationComponent from './TitleGenerationComponent';
// import ColumnDeletion from './ColumnDeletion';
// import APIKeyInput from './APIKeyInput';
// import ImageDownloader from './ImageDownloader';
// import ImageUrlReplacer from './ImageUrlReplacer';
// import CustomColumnSettings from './CustomColumnSettings';
// import PriceCalculationSettings from './PriceCalculationSettings';
// import OpenAIDescriptionGenerator from './OpenAIDescriptionGenerator';
// import ItemSpecificsSettings from './ItemSpecificsSettings';
// import ItemSpecificsApplier from './ItemSpecificsApplier';
// import TermsOfService from './TermsOfService';
// import PrivacyPolicy from './PrivacyPolicy';
// import LegalDisclosure from './LegalDisclosure';
// import Login from './Login';
// import AdminNotificationSender from './AdminNotificationSender';
// import './App.css';

// // Firebase Storage関連のインポート
// import { getStorage, ref, uploadString, getBlob } from 'firebase/storage';

// // 通知コンポーネント
// function Notifications({ user }) {
//   const [anchorEl, setAnchorEl] = useState(null);
//   const [notifications, setNotifications] = useState([]);

//   useEffect(() => {
//     if (!user) return;

//     // ユーザーごとの通知を取得するクエリを作成
//     const q = query(
//       collection(db, 'notifications'),
//       where('userId', '==', user.uid),
//       orderBy('timestamp', 'desc'),
//       limit(20)
//     );

//     // リアルタイムリスナーを設定
//     const unsubscribe = onSnapshot(
//       q,
//       (querySnapshot) => {
//         const notificationsData = [];
//         querySnapshot.forEach((doc) => {
//           notificationsData.push({ id: doc.id, ...doc.data() });
//         });
//         setNotifications(notificationsData);
//       },
//       (error) => {
//         console.error('通知の取得中にエラーが発生しました:', error);
//         // 必要に応じてエラーメッセージを表示
//       }
//     );

//     // クリーンアップ関数を返す
//     return () => unsubscribe();
//   }, [user]);

//   // メニューを開く処理
//   const handleMenuOpen = (event) => {
//     setAnchorEl(event.currentTarget);
//   };

//   // メニューを閉じる処理
//   const handleMenuClose = () => {
//     setAnchorEl(null);
//   };

//   // 通知をクリックしたときに既読にする
//   const handleNotificationClick = (notificationId) => {
//     const notificationRef = doc(db, 'notifications', notificationId);
//     updateDoc(notificationRef, { read: true })
//       .catch((error) => {
//         console.error('通知の更新中にエラーが発生しました:', error);
//       });
//     handleMenuClose();
//   };

//   // 未読の通知数を計算
//   const unreadCount = notifications.filter(n => !n.read).length;

//   return (
//     <div>
//       <IconButton color="inherit" onClick={handleMenuOpen}>
//         <Badge badgeContent={unreadCount} color="secondary">
//           <NotificationsIcon />
//         </Badge>
//       </IconButton>
//       <Menu
//         anchorEl={anchorEl}
//         open={Boolean(anchorEl)}
//         onClose={handleMenuClose}
//       >
//         {notifications.length === 0 ? (
//           <MenuItem onClick={handleMenuClose}>新しいお知らせはありません</MenuItem>
//         ) : (
//           notifications.map((notification) => (
//             <MenuItem key={notification.id} onClick={() => handleNotificationClick(notification.id)}>
//               {notification.message}
//             </MenuItem>
//           ))
//         )}
//       </Menu>
//     </div>
//   );
// }

// function AppContent({ setIsAuthenticated }) {
//   const [user, setUser] = useState(null);
//   const [userIsAdmin, setUserIsAdmin] = useState(false);
//   const [csvData, setCsvData] = useState([]);
//   const [columns, setColumns] = useState([]);
//   const [csvFileName, setCsvFileName] = useState('');
//   const [isDrawerOpen, setIsDrawerOpen] = useState(false);
//   const [isHelpOpen, setIsHelpOpen] = useState(false);
//   const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'info' });
//   const [apiKey, setApiKey] = useState('');
//   const [isApiKeyDialogOpen, setIsApiKeyDialogOpen] = useState(false);
//   const [customSettings, setCustomSettings] = useState(null);
//   const [isCustomSettingsOpen, setIsCustomSettingsOpen] = useState(false);
//   const [isItemSpecificsSettingsOpen, setIsItemSpecificsSettingsOpen] = useState(false);
//   const [itemSpecificsSettings, setItemSpecificsSettings] = useState([]);
//   const navigate = useNavigate();

//   const functions = getFunctions(app);
//   const auth = getAuth();

//   // ユーザー固有の秘密鍵を生成
//   const generateEncryptionKey = (uid) => {
//     const salt = 'your-fixed-salt-value'; // 固定の塩値（セキュリティ向上のため、環境変数で管理することを推奨）
//     return CryptoJS.PBKDF2(uid, salt, { keySize: 256 / 32 }).toString();
//   };

//   // サインアウト処理
//   const handleSignOut = async () => {
//     try {
//       await signOut(auth);
//       // ローカルストレージをクリア
//       localStorage.clear();
//       setIsAuthenticated(false);
//       navigate('/login');
//     } catch (error) {
//       console.error('サインアウト中にエラーが発生しました:', error);
//       setSnackbar({ open: true, message: 'サインアウト中にエラーが発生しました。', severity: 'error' });
//     }
//   };

//   // 認証状態の監視と初期データの取得
//   useEffect(() => {
//     const unsubscribe = onAuthStateChanged(auth, async (currentUser) => {
//       setUser(currentUser);
//       if (!currentUser) {
//         setIsAuthenticated(false);
//         navigate('/login');
//       } else {
//         // 管理者権限の確認
//         const idTokenResult = await currentUser.getIdTokenResult();
//         setUserIsAdmin(!!idTokenResult.claims.admin);

//         // FirestoreからAPIキーを取得
//         try {
//           const docRef = doc(db, 'userApiKeys', currentUser.uid);
//           const docSnap = await getDoc(docRef);
//           if (docSnap.exists()) {
//             const encryptedKey = docSnap.data().apiKey;
//             const encryptionKey = generateEncryptionKey(currentUser.uid);
//             const bytes = CryptoJS.AES.decrypt(encryptedKey, encryptionKey);
//             const decryptedKey = bytes.toString(CryptoJS.enc.Utf8);
//             if (decryptedKey) {
//               setApiKey(decryptedKey);
//             } else {
//               console.error('APIキーの復号に失敗しました。');
//             }
//           }
//         } catch (error) {
//           console.error('APIキーの取得エラー:', error);
//         }

//         // ローカルストレージからCSVデータとファイル名を取得
//         const storedCsvData = localStorage.getItem('csvData');
//         const storedColumns = localStorage.getItem('csvColumns');
//         const storedFileName = localStorage.getItem('csvFileName');

//         if (storedCsvData && storedColumns) {
//           const data = JSON.parse(storedCsvData);
//           const cols = JSON.parse(storedColumns);
//           setCsvData(data);
//           setColumns(cols);
//           setCsvFileName(storedFileName || '');
//           setSnackbar({ open: true, message: 'ローカルストレージから編集データを読み込みました。', severity: 'success' });
//         } else {
//           // Firebase Storageからデータを取得
//           try {
//             // Firestoreからメタデータを取得（ファイル名など）
//             const docRef = doc(db, 'userData', currentUser.uid);
//             const docSnap = await getDoc(docRef);
//             let storedFileName = '';
//             if (docSnap.exists()) {
//               storedFileName = docSnap.data().csvFileName || '';
//               setCsvFileName(storedFileName);
//             }

//             const storage = getStorage(app);
//             const storageRef = ref(storage, `userData/${currentUser.uid}/csvData.json`);

//             // getBlob を使用してデータを取得
//             const blob = await getBlob(storageRef);
//             const textData = await blob.text();
//             const jsonData = JSON.parse(textData);

//             setCsvData(jsonData);
//             setColumns(Object.keys(jsonData[0] || {}));
//             setSnackbar({ open: true, message: 'Firebase Storageから編集データを読み込みました。', severity: 'success' });

//             // ローカルストレージにデータを保存
//             localStorage.setItem('csvData', JSON.stringify(jsonData));
//             localStorage.setItem('csvColumns', JSON.stringify(Object.keys(jsonData[0] || {})));
//             localStorage.setItem('csvFileName', storedFileName);

//           } catch (error) {
//             console.error('Firebase Storageからデータを取得中にエラーが発生しました:', error);
//             setSnackbar({ open: true, message: 'Firebase Storageからデータを取得できませんでした。', severity: 'error' });
//           }
//         }
//       }
//     });
//     return () => unsubscribe();
//   }, [navigate, setIsAuthenticated, auth]);

//   // CSVファイルのインポート処理
//   const handleFileUpload = (event) => {
//     const file = event.target.files[0];
//     if (file) {
//       setCsvFileName(file.name); // ファイル名を保存
//       Papa.parse(file, {
//         header: true,
//         skipEmptyLines: true,
//         complete: (results) => {
//           const data = results.data;
//           setCsvData(data);
//           setColumns(Object.keys(data[0] || {}));
//           setSnackbar({ open: true, message: 'CSVファイルがインポートされました', severity: 'success' });

//           // ローカルストレージにデータとファイル名を保存
//           localStorage.setItem('csvData', JSON.stringify(data));
//           localStorage.setItem('csvColumns', JSON.stringify(Object.keys(data[0] || {})));
//           localStorage.setItem('csvFileName', file.name);
//         },
//         error: (error) => {
//           console.error('CSVファイルの解析中にエラーが発生しました:', error);
//           setSnackbar({ open: true, message: 'CSVファイルの解析中にエラーが発生しました', severity: 'error' });
//         },
//       });
//     }
//   };

//   // Firebase Storageにデータを保存
//   const handleSaveEditedData = async () => {
//     if (csvData.length > 0 && user) {
//       try {
//         const storage = getStorage(app);
//         const storageRef = ref(storage, `userData/${user.uid}/csvData.json`);
//         const jsonData = JSON.stringify(csvData);

//         await uploadString(storageRef, jsonData);
//         setSnackbar({ open: true, message: '編集データがFirebase Storageに保存されました。', severity: 'success' });

//         // Firestoreにもメタデータを保存（ファイル名やタイムスタンプなど）
//         const docRef = doc(db, 'userData', user.uid);
//         await setDoc(docRef, {
//           csvFileName,
//           timestamp: new Date(),
//         }, { merge: true });

//       } catch (error) {
//         console.error('Firebase Storageへのデータ保存中にエラーが発生しました:', error);
//         setSnackbar({ open: true, message: 'データの保存に失敗しました。', severity: 'error' });
//       }
//     } else {
//       setSnackbar({ open: true, message: '保存するデータがありません。', severity: 'warning' });
//     }
//   };

//   const toggleDrawer = (open) => (event) => {
//     if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
//       return;
//     }
//     setIsDrawerOpen(open);
//   };

//   const handleOpenCustomSettings = () => {
//     setIsCustomSettingsOpen(true);
//     setIsDrawerOpen(false);
//   };

//   const handleCloseCustomSettings = () => {
//     setIsCustomSettingsOpen(false);
//   };

//   const handleSaveCustomSettings = (settings) => {
//     setCustomSettings(settings);
//     setIsCustomSettingsOpen(false);
//     localStorage.setItem('customPriceSettings', JSON.stringify(settings));
//     setSnackbar({ open: true, message: '設定が保存されました', severity: 'success' });
//   };

//   const handleItemSpecificsSettingsOpen = () => {
//     setIsItemSpecificsSettingsOpen(true);
//     setIsDrawerOpen(false);
//   };

//   const handleItemSpecificsSettingsClose = () => {
//     setIsItemSpecificsSettingsOpen(false);
//   };

//   const handleItemSpecificsSettingsSave = (settings) => {
//     setItemSpecificsSettings(settings);
//     handleItemSpecificsSettingsClose();
//     setSnackbar({ open: true, message: 'Item Specifics設定が保存されました', severity: 'success' });
//   };

//   const handleApiKeySet = async (key) => {
//     setApiKey(key);
//     if (user) {
//       try {
//         const encryptionKey = generateEncryptionKey(user.uid);
//         const encryptedKey = CryptoJS.AES.encrypt(key, encryptionKey).toString();
//         // Firestoreに保存
//         await setDoc(doc(db, 'userApiKeys', user.uid), { apiKey: encryptedKey }, { merge: true });
//         setSnackbar({ open: true, message: 'APIキーが保存されました。', severity: 'success' });
//       } catch (error) {
//         console.error('APIキーの保存エラー:', error);
//         setSnackbar({ open: true, message: `APIキーの保存に失敗しました: ${error.message}`, severity: 'error' });
//       }
//     } else {
//       setSnackbar({ open: true, message: 'ユーザーが認証されていません。', severity: 'error' });
//     }
//   };

//   // ハンバーガーメニューの項目
//   const menuItems = [
//     {
//       text: 'APIキー管理',
//       icon: <VpnKeyIcon />,
//       action: () => {
//         setIsApiKeyDialogOpen(true);
//         setIsDrawerOpen(false);
//       },
//     },
//     {
//       text: '価格計算設定',
//       icon: <SettingsIcon />,
//       action: handleOpenCustomSettings,
//     },
//     {
//       text: 'Item Specifics設定',
//       icon: <SettingsIcon />,
//       action: handleItemSpecificsSettingsOpen,
//     },
//     {
//       text: 'マニュアル',
//       icon: <AttachMoneyIcon />,
//       action: () => {
//         window.open('https://ebay-marketing-tool.com/ebay-spread-manual-price/', '_blank', 'noopener,noreferrer');
//       },
//       renderItem: (item) => (
//         <>
//           <ListItemIcon>{item.icon}</ListItemIcon>
//           <ListItemText primary={item.text} />
//           <Box component="span" sx={{ ml: 1 }}>
//             <LaunchIcon fontSize="small" />
//           </Box>
//         </>
//       ),
//     },
//   ];

//   return (
//     <Box sx={{ flexGrow: 1 }}>
//       {/* アプリバー */}
//       <AppBar position="static">
//         <Toolbar>
//           <IconButton size="large" edge="start" color="inherit" aria-label="menu" sx={{ mr: 2 }} onClick={toggleDrawer(true)}>
//             <MenuIcon />
//           </IconButton>
//           <Typography variant="h6" component="div">
//             Specifics-IN ver 2.4.0
//           </Typography>
//           <Box sx={{ flexGrow: 1 }} />
//           {/* 管理者の場合、管理者ページへのリンクを表示 */}
//           {userIsAdmin && (
//             <Button color="inherit" component={RouterLink} to="/admin">
//               管理者ページ
//             </Button>
//           )}
//           {/* 通知アイコンを追加 */}
//           <Notifications user={user} />
//           {/* 既存のボタン */}
//           <Button
//             color="inherit"
//             href="https://www.ebay.com/sh/reports/uploads"
//             target="_blank"
//             rel="noopener noreferrer"
//             startIcon={<CloudUploadIcon />}
//           >
//             eBayアップロード
//           </Button>
//           <Button
//             color="inherit"
//             href="https://app.photoroom.com/batch"
//             target="_blank"
//             rel="noopener noreferrer"
//             startIcon={<PhotoCameraIcon />}
//           >
//             Photoroom
//           </Button>
//           <Button
//             color="inherit"
//             href="https://postimages.org/#google_vignette"
//             target="_blank"
//             rel="noopener noreferrer"
//             startIcon={<LinkIcon />}
//           >
//             画像URL変換
//           </Button>
//           <Button color="inherit" onClick={handleSignOut}>
//             ログアウト
//           </Button>
//         </Toolbar>
//       </AppBar>

//       {/* ドロワーメニュー */}
//       <Drawer
//         anchor="left"
//         open={isDrawerOpen}
//         onClose={toggleDrawer(false)}
//         sx={{
//           '& .MuiDrawer-paper': {
//             width: '200px',
//             boxSizing: 'border-box',
//           },
//         }}
//       >
//         <List sx={{ width: '100%' }}>
//           {menuItems.map((item, index) => (
//             <ListItem
//               button
//               key={index}
//               onClick={item.action}
//               sx={{
//                 py: 2,
//                 '&:hover': {
//                   backgroundColor: 'rgba(0, 0, 0, 0.04)',
//                 },
//               }}
//             >
//               {item.renderItem ? (
//                 item.renderItem(item)
//               ) : (
//                 <>
//                   <ListItemIcon sx={{ minWidth: '56px' }}>{item.icon}</ListItemIcon>
//                   <ListItemText
//                     primary={item.text}
//                     primaryTypographyProps={{
//                       fontSize: '1.1rem',
//                       fontWeight: 'medium',
//                     }}
//                   />
//                 </>
//               )}
//             </ListItem>
//           ))}
//         </List>
//       </Drawer>

//       {/* メインコンテンツ */}
//       <Grid container spacing={2}>
//         <Grid item xs={12}>
//           <Paper elevation={3} sx={{ p: 2 }}>
//             {/* CSVファイルのアップロードと編集データの保存 */}
//             <Paper elevation={3} sx={{ p: 2, mb: 2 }}>
//               <Grid container spacing={2} alignItems="center">
//                 {/* CSVファイルのインポート */}
//                 <Grid item xs={12} sm={8} md={9}>
//                   <Typography variant="h6" gutterBottom>
//                     CSVファイルのインポート
//                   </Typography>
//                   <input
//                     accept=".csv"
//                     style={{ display: 'none' }}
//                     id="contained-button-file"
//                     type="file"
//                     onChange={handleFileUpload}
//                   />
//                   <label htmlFor="contained-button-file">
//                     <Button
//                       variant="contained"
//                       component="span"
//                       startIcon={<CloudUploadIcon />}
//                       sx={{
//                         backgroundColor: '#1976d2',
//                         '&:hover': {
//                           backgroundColor: '#1565c0',
//                         },
//                       }}
//                     >
//                       CSVファイルをインポート
//                     </Button>
//                   </label>
//                   {csvFileName && (
//                     <Typography variant="body1" sx={{ mt: 2 }}>
//                       現在のCSVファイル: {csvFileName}
//                     </Typography>
//                   )}
//                 </Grid>
//                 {/* 編集データの保存ボタン */}
//                 <Grid item xs={12} sm={4} md={3} sx={{ textAlign: 'right' }}>
//                   <Button
//                     variant="contained"
//                     color="primary"
//                     startIcon={<SaveIcon />}
//                     onClick={handleSaveEditedData}
//                     sx={{
//                       mt: { xs: 2, sm: 0 },
//                       backgroundColor: '#1976d2',
//                       '&:hover': {
//                         backgroundColor: '#1565c0',
//                       },
//                     }}
//                   >
//                     編集データを保存
//                   </Button>
//                 </Grid>
//               </Grid>
//             </Paper>

//             {/* インポートされたCSVデータの表示と編集 */}
//             {csvData.length > 0 && (
//               <Grid container spacing={4}>
//                 {/* ColumnNameBasedTemplateManagerコンポーネント */}
//                 <Grid item xs={12}>
//                   <ColumnNameBasedTemplateManager
//                     data={csvData}
//                     setData={setCsvData}
//                     columns={columns}
//                     setColumns={setColumns}
//                   />
//                 </Grid>

//                 {/* その他のコンポーネント */}
//                 <Grid container spacing={4} sx={{ display: 'flex', p: 2 }}>
//                   {/* ColumnDeletionコンポーネント */}
//                   <Grid item xs={12} md={4}>
//                     <Paper elevation={3} sx={{ flexGrow: 1, p: 2, m: 1 }}>
//                       <ColumnDeletion
//                         data={csvData}
//                         setData={setCsvData}
//                         columns={columns}
//                         setColumns={setColumns}
//                       />
//                     </Paper>
//                   </Grid>

//                   {/* PriceCalculationSettingsコンポーネント */}
//                   <Grid item xs={12} md={4}>
//                     <Paper elevation={3} sx={{ flexGrow: 1, p: 2, m: 1 }}>
//                       <PriceCalculationSettings
//                         customSettings={customSettings}
//                         data={csvData}
//                         setData={setCsvData}
//                       />
//                     </Paper>
//                   </Grid>

//                   {/* ItemSpecificsApplierコンポーネント */}
//                   <Grid item xs={12} md={4}>
//                     <Paper elevation={3} sx={{ flexGrow: 1, p: 2, m: 1 }}>
//                       <ItemSpecificsApplier data={csvData} setData={setCsvData} userId={user ? user.uid : null} />
//                     </Paper>
//                   </Grid>
//                 </Grid>

//                 {/* 画像関連のコンポーネント */}
//                 <Grid container spacing={4} sx={{ display: 'flex', p: 2 }}>
//                   <Grid item xs={12} md={6}>
//                     <Paper elevation={3} sx={{ p: 3, mb: 0, ml: 1, mt: 0 }}>
//                       <ImageDownloader data={csvData} setData={setCsvData} apiKey={apiKey} />
//                     </Paper>
//                   </Grid>

//                   <Grid item xs={12} md={6}>
//                     <Paper elevation={3} sx={{ p: 3, mb: 0 }}>
//                       <ImageUrlReplacer data={csvData} setData={setCsvData} />
//                     </Paper>
//                   </Grid>
//                 </Grid>

//                 {/* AI機能のセクション */}
//                 <Grid item xs={12}>
//                   <AppBar position="static" color="primary" sx={{ mt: 0 }}>
//                     <Toolbar>
//                       <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
//                         AI機能 ※API利用
//                       </Typography>
//                     </Toolbar>
//                   </AppBar>
//                 </Grid>

//                 {/* タイトル生成と商品説明生成のコンポーネント */}
//                 <Grid container spacing={4} sx={{ display: 'flex', p: 2 }}>
//                   <Grid item xs={12} md={6}>
//                     <TitleGenerationComponent data={csvData} setData={setCsvData} apiKey={apiKey} user={user} />
//                   </Grid>

//                   <Grid item xs={12} md={6}>
//                     <OpenAIDescriptionGenerator data={csvData} setData={setCsvData} apiKey={apiKey} user={user} />
//                   </Grid>
//                 </Grid>

//                 {/* 編集可能なスプレッドシート */}
//                 <Grid item xs={12}>
//                   <EditableSpreadsheet
//                     data={csvData}
//                     setData={setCsvData}
//                     columns={columns}
//                     setColumns={setColumns}
//                   />
//                 </Grid>
//               </Grid>
//             )}
//           </Paper>
//         </Grid>
//       </Grid>

//       {/* ヘルプのドロワー */}
//       <Drawer anchor="right" open={isHelpOpen} onClose={() => setIsHelpOpen(false)}>
//         <Box sx={{ width: 300, p: 2 }}>
//           <InteractiveManual />
//         </Box>
//       </Drawer>

//       {/* APIキー管理ダイアログ */}
//       <Dialog open={isApiKeyDialogOpen} onClose={() => setIsApiKeyDialogOpen(false)}>
//         <DialogTitle>APIキー管理</DialogTitle>
//         <DialogContent>
//           <APIKeyInput
//             user={user}
//             apiKey={apiKey}
//             setApiKey={setApiKey}
//             onApiKeySet={handleApiKeySet}
//           />
//         </DialogContent>
//         <DialogActions>
//           <Button onClick={() => setIsApiKeyDialogOpen(false)}>閉じる</Button>
//         </DialogActions>
//       </Dialog>

//       {/* 設定のダイアログ */}
//       <Dialog open={isCustomSettingsOpen} onClose={handleCloseCustomSettings} fullWidth maxWidth="md">
//         <DialogTitle>価格計算設定</DialogTitle>
//         <DialogContent>
//           <CustomColumnSettings
//             onSave={handleSaveCustomSettings}
//             initialSettings={customSettings}
//             userId={user ? user.uid : null}
//           />
//         </DialogContent>
//       </Dialog>

//       <Dialog
//         open={isItemSpecificsSettingsOpen}
//         onClose={handleItemSpecificsSettingsClose}
//         fullWidth
//         maxWidth="md"
//         fullScreen
//       >
//         <DialogTitle>Item Specificsの管理画面</DialogTitle>
//         <DialogContent>
//           <ItemSpecificsSettings
//             onSave={handleItemSpecificsSettingsSave}
//             onClose={handleItemSpecificsSettingsClose}
//             headers={columns}
//             userId={user ? user.uid : null}
//           />
//         </DialogContent>
//         <DialogActions>
//           <Button onClick={handleItemSpecificsSettingsClose}>閉じる</Button>
//         </DialogActions>
//       </Dialog>

//       {/* Snackbarによる通知 */}
//       <Snackbar
//         open={snackbar.open}
//         autoHideDuration={6000}
//         onClose={() => setSnackbar({ ...snackbar, open: false })}
//       >
//         <Alert
//           onClose={() => setSnackbar({ ...snackbar, open: false })}
//           severity={snackbar.severity}
//           sx={{ width: '100%' }}
//         >
//           {snackbar.message}
//         </Alert>
//       </Snackbar>

//       {/* フッターのリンク */}
//       <Box sx={{ mt: 4, textAlign: 'center' }}>
//         <Typography variant="body2">
//           <Link component={RouterLink} to="/terms">
//             利用規約
//           </Link>
//           {' | '}
//           <Link component={RouterLink} to="/privacy">
//             プライバシーポリシー
//           </Link>
//           {' | '}
//           <Link component={RouterLink} to="/legal-disclosure">
//             特定商取引法に基づく表記
//           </Link>
//         </Typography>
//       </Box>
//     </Box>
//   );
// }

// function App() {
//   const [isAuthenticated, setIsAuthenticated] = useState(false);
//   const [isLoading, setIsLoading] = useState(true);

//   // 認証状態の監視
//   useEffect(() => {
//     const unsubscribe = onAuthStateChanged(auth, (user) => {
//       setIsAuthenticated(!!user);
//       setIsLoading(false);
//     });
//     return () => unsubscribe();
//   }, []);

//   if (isLoading) {
//     return (
//       <div style={{ textAlign: 'center', marginTop: '20%' }}>
//         <CircularProgress />
//       </div>
//     );
//   }

//   return (
//     <Router>
//       <Routes>
//         <Route
//           path="/login"
//           element={
//             isAuthenticated ? <Navigate to="/" replace /> : <Login setIsAuthenticated={setIsAuthenticated} />
//           }
//         />
//         <Route path="/terms" element={<TermsOfService />} />
//         <Route path="/privacy" element={<PrivacyPolicy />} />
//         <Route path="/legal-disclosure" element={<LegalDisclosure />} />
//         <Route
//           path="/admin"
//           element={
//             isAuthenticated ? (
//               <AdminNotificationSender />
//             ) : (
//               <Navigate to="/login" replace />
//             )
//           }
//         />
//         <Route
//           path="/"
//           element={
//             isAuthenticated ? (
//               <AppContent setIsAuthenticated={setIsAuthenticated} />
//             ) : (
//               <Navigate to="/login" replace />
//             )
//           }
//         />
//         {/* 存在しないルートへの対応 */}
//         <Route path="*" element={<Navigate to="/login" replace />} />
//       </Routes>
//     </Router>
//   );
// }

// export default App;






// // App.js

// import React, { useState, useEffect } from 'react';
// import {
//   BrowserRouter as Router,
//   Route,
//   Routes,
//   Navigate,
//   useNavigate,
//   Link as RouterLink,
// } from 'react-router-dom';
// import {
//   signOut,
//   onAuthStateChanged,
//   getAuth,
// } from 'firebase/auth';
// import {
//   getFunctions,
//   httpsCallable,
// } from 'firebase/functions';
// import {
//   getFirestore,
//   collection,
//   query,
//   where,
//   onSnapshot,
//   orderBy,
//   limit,
//   doc,
//   setDoc,
//   getDoc,
//   updateDoc,
// } from 'firebase/firestore';
// import { auth, db, app } from './firebaseConfig';
// import {
//   Box,
//   Button,
//   Typography,
//   Grid,
//   Paper,
//   AppBar,
//   Toolbar,
//   IconButton,
//   Drawer,
//   Dialog,
//   DialogTitle,
//   DialogContent,
//   Snackbar,
//   Alert,
//   Link,
//   CircularProgress,
//   List,
//   ListItem,
//   ListItemIcon,
//   ListItemText,
//   DialogActions,
//   Badge,
//   Menu,
//   MenuItem,
// } from '@mui/material';
// import {
//   PhotoCamera as PhotoCameraIcon,
//   Link as LinkIcon,
//   Menu as MenuIcon,
//   Help as HelpIcon,
//   CloudUpload as CloudUploadIcon,
//   Settings as SettingsIcon,
//   AttachMoney as AttachMoneyIcon,
//   Launch as LaunchIcon,
//   Save as SaveIcon,
//   VpnKey as VpnKeyIcon,
//   Notifications as NotificationsIcon,
// } from '@mui/icons-material';
// import Papa from 'papaparse';
// import CryptoJS from 'crypto-js';
// import EditableSpreadsheet from './EditableSpreadsheet';
// import ColumnNameBasedTemplateManager from './ColumnNameBasedTemplateManager';
// import InteractiveManual from './InteractiveManual';
// import TitleGenerationComponent from './TitleGenerationComponent';
// import ColumnDeletion from './ColumnDeletion';
// import APIKeyInput from './APIKeyInput';
// import ImageDownloader from './ImageDownloader';
// import ImageUrlReplacer from './ImageUrlReplacer';
// import CustomColumnSettings from './CustomColumnSettings';
// import PriceCalculationSettings from './PriceCalculationSettings';
// import OpenAIDescriptionGenerator from './OpenAIDescriptionGenerator';
// import ItemSpecificsSettings from './ItemSpecificsSettings';
// import ItemSpecificsApplier from './ItemSpecificsApplier';
// import TermsOfService from './TermsOfService';
// import PrivacyPolicy from './PrivacyPolicy';
// import LegalDisclosure from './LegalDisclosure';
// import Login from './Login';
// import AdminNotificationSender from './AdminNotificationSender';
// import './App.css';

// // Firebase Storage関連のインポート
// import { getStorage, ref, uploadString, getBlob } from 'firebase/storage';

// // 通知コンポーネント
// function Notifications({ user }) {
//   const [anchorEl, setAnchorEl] = useState(null);
//   const [notifications, setNotifications] = useState([]);

//   useEffect(() => {
//     if (!user) return;

//     // ユーザーごとの通知を取得するクエリを作成
//     const q = query(
//       collection(db, 'notifications'),
//       where('userId', '==', user.uid),
//       orderBy('timestamp', 'desc'),
//       limit(20)
//     );

//     // リアルタイムリスナーを設定
//     const unsubscribe = onSnapshot(
//       q,
//       (querySnapshot) => {
//         const notificationsData = [];
//         querySnapshot.forEach((doc) => {
//           notificationsData.push({ id: doc.id, ...doc.data() });
//         });
//         setNotifications(notificationsData);
//       },
//       (error) => {
//         console.error('通知の取得中にエラーが発生しました:', error);
//         // 必要に応じてエラーメッセージを表示
//       }
//     );

//     // クリーンアップ関数を返す
//     return () => unsubscribe();
//   }, [user]);

//   // メニューを開く処理
//   const handleMenuOpen = (event) => {
//     setAnchorEl(event.currentTarget);
//   };

//   // メニューを閉じる処理
//   const handleMenuClose = () => {
//     setAnchorEl(null);
//   };

//   // 通知をクリックしたときに既読にする
//   const handleNotificationClick = (notificationId) => {
//     const notificationRef = doc(db, 'notifications', notificationId);
//     updateDoc(notificationRef, { read: true })
//       .catch((error) => {
//         console.error('通知の更新中にエラーが発生しました:', error);
//       });
//     handleMenuClose();
//   };

//   // 未読の通知数を計算
//   const unreadCount = notifications.filter(n => !n.read).length;

//   return (
//     <div>
//       <IconButton color="inherit" onClick={handleMenuOpen}>
//         <Badge badgeContent={unreadCount} color="secondary">
//           <NotificationsIcon />
//         </Badge>
//       </IconButton>
//       <Menu
//         anchorEl={anchorEl}
//         open={Boolean(anchorEl)}
//         onClose={handleMenuClose}
//       >
//         {notifications.length === 0 ? (
//           <MenuItem onClick={handleMenuClose}>新しいお知らせはありません</MenuItem>
//         ) : (
//           notifications.map((notification) => (
//             <MenuItem key={notification.id} onClick={() => handleNotificationClick(notification.id)}>
//               {notification.message}
//             </MenuItem>
//           ))
//         )}
//       </Menu>
//     </div>
//   );
// }

// function AppContent({ setIsAuthenticated }) {
//   const [user, setUser] = useState(null);
//   const [userIsAdmin, setUserIsAdmin] = useState(false);
//   const [csvData, setCsvData] = useState([]);
//   const [columns, setColumns] = useState([]);
//   const [csvFileName, setCsvFileName] = useState('');
//   const [isDrawerOpen, setIsDrawerOpen] = useState(false);
//   const [isHelpOpen, setIsHelpOpen] = useState(false);
//   const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'info' });
//   const [apiKey, setApiKey] = useState('');
//   const [isApiKeyDialogOpen, setIsApiKeyDialogOpen] = useState(false);
//   const [customSettings, setCustomSettings] = useState(null);
//   const [isCustomSettingsOpen, setIsCustomSettingsOpen] = useState(false);
//   const [isItemSpecificsSettingsOpen, setIsItemSpecificsSettingsOpen] = useState(false);
//   const [itemSpecificsSettings, setItemSpecificsSettings] = useState([]);
//   const navigate = useNavigate();

//   const functions = getFunctions(app);
//   const auth = getAuth();

//   // ユーザー固有の秘密鍵を生成
//   const generateEncryptionKey = (uid) => {
//     const salt = 'your-fixed-salt-value'; // 固定の塩値（セキュリティ向上のため、環境変数で管理することを推奨）
//     return CryptoJS.PBKDF2(uid, salt, { keySize: 256 / 32 }).toString();
//   };

//   // サインアウト処理
//   const handleSignOut = async () => {
//     try {
//       await signOut(auth);
//       // ローカルストレージをクリア
//       localStorage.clear();
//       setIsAuthenticated(false);
//       navigate('/login');
//     } catch (error) {
//       console.error('サインアウト中にエラーが発生しました:', error);
//       setSnackbar({ open: true, message: 'サインアウト中にエラーが発生しました。', severity: 'error' });
//     }
//   };

//   // 認証状態の監視と初期データの取得
//   useEffect(() => {
//     const unsubscribe = onAuthStateChanged(auth, async (currentUser) => {
//       setUser(currentUser);
//       if (!currentUser) {
//         setIsAuthenticated(false);
//         navigate('/login');
//       } else {
//         // 管理者権限の確認
//         const idTokenResult = await currentUser.getIdTokenResult();
//         setUserIsAdmin(!!idTokenResult.claims.admin);

//         // FirestoreからAPIキーを取得
//         try {
//           const docRef = doc(db, 'userApiKeys', currentUser.uid);
//           const docSnap = await getDoc(docRef);
//           if (docSnap.exists()) {
//             const encryptedKey = docSnap.data().apiKey;
//             const encryptionKey = generateEncryptionKey(currentUser.uid);
//             const bytes = CryptoJS.AES.decrypt(encryptedKey, encryptionKey);
//             const decryptedKey = bytes.toString(CryptoJS.enc.Utf8);
//             if (decryptedKey) {
//               setApiKey(decryptedKey);
//             } else {
//               console.error('APIキーの復号に失敗しました。');
//             }
//           }
//         } catch (error) {
//           console.error('APIキーの取得エラー:', error);
//         }

//         // ローカルストレージからCSVデータとファイル名を取得
//         const storedCsvData = localStorage.getItem('csvData');
//         const storedColumns = localStorage.getItem('csvColumns');
//         const storedFileName = localStorage.getItem('csvFileName');

//         if (storedCsvData && storedColumns) {
//           const data = JSON.parse(storedCsvData);
//           const cols = JSON.parse(storedColumns);
//           setCsvData(data);
//           setColumns(cols);
//           setCsvFileName(storedFileName || '');
//           setSnackbar({ open: true, message: 'ローカルストレージから編集データを読み込みました。', severity: 'success' });
//         } else {
//           // Firebase Storageからデータを取得
//           try {
//             // Firestoreからメタデータを取得（ファイル名など）
//             const docRef = doc(db, 'userData', currentUser.uid);
//             const docSnap = await getDoc(docRef);
//             let storedFileName = '';
//             if (docSnap.exists()) {
//               storedFileName = docSnap.data().csvFileName || '';
//               setCsvFileName(storedFileName);
//             }

//             const storage = getStorage(app);
//             const storageRef = ref(storage, `userData/${currentUser.uid}/csvData.json`);

//             // getBlob を使用してデータを取得
//             const blob = await getBlob(storageRef);
//             const textData = await blob.text();
//             const jsonData = JSON.parse(textData);

//             setCsvData(jsonData);
//             setColumns(Object.keys(jsonData[0] || {}));
//             setSnackbar({ open: true, message: 'Firebase Storageから編集データを読み込みました。', severity: 'success' });

//             // ローカルストレージにデータを保存
//             localStorage.setItem('csvData', JSON.stringify(jsonData));
//             localStorage.setItem('csvColumns', JSON.stringify(Object.keys(jsonData[0] || {})));
//             localStorage.setItem('csvFileName', storedFileName);

//           } catch (error) {
//             console.error('Firebase Storageからデータを取得中にエラーが発生しました:', error);
//             setSnackbar({ open: true, message: 'Firebase Storageからデータを取得できませんでした。', severity: 'error' });
//           }
//         }
//       }
//     });
//     return () => unsubscribe();
//   }, [navigate, setIsAuthenticated, auth]);

//   // CSVファイルのインポート処理
//   const handleFileUpload = (event) => {
//     const file = event.target.files[0];
//     if (file) {
//       setCsvFileName(file.name); // ファイル名を保存
//       Papa.parse(file, {
//         header: true,
//         skipEmptyLines: true,
//         complete: (results) => {
//           const data = results.data;
//           setCsvData(data);
//           setColumns(Object.keys(data[0] || {}));
//           setSnackbar({ open: true, message: 'CSVファイルがインポートされました', severity: 'success' });

//           // ローカルストレージにデータとファイル名を保存
//           localStorage.setItem('csvData', JSON.stringify(data));
//           localStorage.setItem('csvColumns', JSON.stringify(Object.keys(data[0] || {})));
//           localStorage.setItem('csvFileName', file.name);
//         },
//         error: (error) => {
//           console.error('CSVファイルの解析中にエラーが発生しました:', error);
//           setSnackbar({ open: true, message: 'CSVファイルの解析中にエラーが発生しました', severity: 'error' });
//         },
//       });
//     }
//   };

//   // Firebase Storageにデータを保存
//   const handleSaveEditedData = async () => {
//     if (csvData.length > 0 && user) {
//       try {
//         const storage = getStorage(app);
//         const storageRef = ref(storage, `userData/${user.uid}/csvData.json`);
//         const jsonData = JSON.stringify(csvData);

//         await uploadString(storageRef, jsonData);
//         setSnackbar({ open: true, message: '編集データがFirebase Storageに保存されました。', severity: 'success' });

//         // Firestoreにもメタデータを保存（ファイル名やタイムスタンプなど）
//         const docRef = doc(db, 'userData', user.uid);
//         await setDoc(docRef, {
//           csvFileName,
//           timestamp: new Date(),
//         }, { merge: true });

//       } catch (error) {
//         console.error('Firebase Storageへのデータ保存中にエラーが発生しました:', error);
//         setSnackbar({ open: true, message: 'データの保存に失敗しました。', severity: 'error' });
//       }
//     } else {
//       setSnackbar({ open: true, message: '保存するデータがありません。', severity: 'warning' });
//     }
//   };

//   const toggleDrawer = (open) => (event) => {
//     if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
//       return;
//     }
//     setIsDrawerOpen(open);
//   };

//   const handleOpenCustomSettings = () => {
//     setIsCustomSettingsOpen(true);
//     setIsDrawerOpen(false);
//   };

//   const handleCloseCustomSettings = () => {
//     setIsCustomSettingsOpen(false);
//   };

//   const handleSaveCustomSettings = (settings) => {
//     setCustomSettings(settings);
//     setIsCustomSettingsOpen(false);
//     localStorage.setItem('customPriceSettings', JSON.stringify(settings));
//     setSnackbar({ open: true, message: '設定が保存されました', severity: 'success' });
//   };

//   const handleItemSpecificsSettingsOpen = () => {
//     setIsItemSpecificsSettingsOpen(true);
//     setIsDrawerOpen(false);
//   };

//   const handleItemSpecificsSettingsClose = () => {
//     setIsItemSpecificsSettingsOpen(false);
//   };

//   const handleItemSpecificsSettingsSave = (settings) => {
//     setItemSpecificsSettings(settings);
//     handleItemSpecificsSettingsClose();
//     setSnackbar({ open: true, message: 'Item Specifics設定が保存されました', severity: 'success' });
//   };

//   const handleApiKeySet = async (key) => {
//     setApiKey(key);
//     if (user) {
//       try {
//         const encryptionKey = generateEncryptionKey(user.uid);
//         const encryptedKey = CryptoJS.AES.encrypt(key, encryptionKey).toString();
//         // Firestoreに保存
//         await setDoc(doc(db, 'userApiKeys', user.uid), { apiKey: encryptedKey }, { merge: true });
//         setSnackbar({ open: true, message: 'APIキーが保存されました。', severity: 'success' });
//       } catch (error) {
//         console.error('APIキーの保存エラー:', error);
//         setSnackbar({ open: true, message: `APIキーの保存に失敗しました: ${error.message}`, severity: 'error' });
//       }
//     } else {
//       setSnackbar({ open: true, message: 'ユーザーが認証されていません。', severity: 'error' });
//     }
//   };

//   // ハンバーガーメニューの項目
//   const menuItems = [
//     {
//       text: 'APIキー管理',
//       icon: <VpnKeyIcon />,
//       action: () => {
//         setIsApiKeyDialogOpen(true);
//         setIsDrawerOpen(false);
//       },
//     },
//     {
//       text: '価格計算設定',
//       icon: <SettingsIcon />,
//       action: handleOpenCustomSettings,
//     },
//     {
//       text: 'Item Specifics設定',
//       icon: <SettingsIcon />,
//       action: handleItemSpecificsSettingsOpen,
//     },
//     {
//       text: 'マニュアル',
//       icon: <AttachMoneyIcon />,
//       action: () => {
//         window.open('https://ebay-marketing-tool.com/ebay-spread-manual-price/', '_blank', 'noopener,noreferrer');
//       },
//       renderItem: (item) => (
//         <>
//           <ListItemIcon>{item.icon}</ListItemIcon>
//           <ListItemText primary={item.text} />
//           <Box component="span" sx={{ ml: 1 }}>
//             <LaunchIcon fontSize="small" />
//           </Box>
//         </>
//       ),
//     },
//   ];

//   return (
//     <Box sx={{ flexGrow: 1 }}>
//       {/* アプリバー */}
//       <AppBar position="static">
//         <Toolbar>
//           <IconButton size="large" edge="start" color="inherit" aria-label="menu" sx={{ mr: 2 }} onClick={toggleDrawer(true)}>
//             <MenuIcon />
//           </IconButton>
//           <Typography variant="h6" component="div">
//             Specifics-IN ver 2.3.0
//           </Typography>
//           <Box sx={{ flexGrow: 1 }} />
//           {/* 管理者の場合、管理者ページへのリンクを表示 */}
//           {userIsAdmin && (
//             <Button color="inherit" component={RouterLink} to="/admin">
//               管理者ページ
//             </Button>
//           )}
//           {/* 通知アイコンを追加 */}
//           <Notifications user={user} />
//           {/* 既存のボタン */}
//           <Button
//             color="inherit"
//             href="https://www.ebay.com/sh/reports/uploads"
//             target="_blank"
//             rel="noopener noreferrer"
//             startIcon={<CloudUploadIcon />}
//           >
//             eBayアップロード
//           </Button>
//           <Button
//             color="inherit"
//             href="https://app.photoroom.com/batch"
//             target="_blank"
//             rel="noopener noreferrer"
//             startIcon={<PhotoCameraIcon />}
//           >
//             Photoroom
//           </Button>
//           <Button
//             color="inherit"
//             href="https://postimages.org/#google_vignette"
//             target="_blank"
//             rel="noopener noreferrer"
//             startIcon={<LinkIcon />}
//           >
//             画像URL変換
//           </Button>
//           <Button color="inherit" onClick={handleSignOut}>
//             ログアウト
//           </Button>
//           {/* <IconButton color="inherit" onClick={() => setIsHelpOpen(!isHelpOpen)} aria-label="ヘルプを表示/非表示">
//             <HelpIcon />
//           </IconButton> */}
//         </Toolbar>
//       </AppBar>

//       {/* ドロワーメニュー */}
//       <Drawer
//         anchor="left"
//         open={isDrawerOpen}
//         onClose={toggleDrawer(false)}
//         sx={{
//           '& .MuiDrawer-paper': {
//             width: '200px',
//             boxSizing: 'border-box',
//           },
//         }}
//       >
//         <List sx={{ width: '100%' }}>
//           {menuItems.map((item, index) => (
//             <ListItem
//               button
//               key={index}
//               onClick={item.action}
//               sx={{
//                 py: 2,
//                 '&:hover': {
//                   backgroundColor: 'rgba(0, 0, 0, 0.04)',
//                 },
//               }}
//             >
//               {item.renderItem ? (
//                 item.renderItem(item)
//               ) : (
//                 <>
//                   <ListItemIcon sx={{ minWidth: '56px' }}>{item.icon}</ListItemIcon>
//                   <ListItemText
//                     primary={item.text}
//                     primaryTypographyProps={{
//                       fontSize: '1.1rem',
//                       fontWeight: 'medium',
//                     }}
//                   />
//                 </>
//               )}
//             </ListItem>
//           ))}
//         </List>
//       </Drawer>

//       {/* メインコンテンツ */}
//       <Grid container spacing={2}>
//         <Grid item xs={12}>
//           <Paper elevation={3} sx={{ p: 2 }}>
//             {/* CSVファイルのアップロードと編集データの保存 */}
//             <Paper elevation={3} sx={{ p: 2, mb: 2 }}>
//               <Grid container spacing={2} alignItems="center">
//                 {/* CSVファイルのインポート */}
//                 <Grid item xs={12} sm={8} md={9}>
//                   <Typography variant="h6" gutterBottom>
//                     CSVファイルのインポート
//                   </Typography>
//                   <input
//                     accept=".csv"
//                     style={{ display: 'none' }}
//                     id="contained-button-file"
//                     type="file"
//                     onChange={handleFileUpload}
//                   />
//                   <label htmlFor="contained-button-file">
//                     <Button
//                       variant="contained"
//                       component="span"
//                       startIcon={<CloudUploadIcon />}
//                       sx={{
//                         backgroundColor: '#1976d2',
//                         '&:hover': {
//                           backgroundColor: '#1565c0',
//                         },
//                       }}
//                     >
//                       CSVファイルをインポート
//                     </Button>
//                   </label>
//                   {csvFileName && (
//                     <Typography variant="body1" sx={{ mt: 2 }}>
//                       現在のCSVファイル: {csvFileName}
//                     </Typography>
//                   )}
//                 </Grid>
//                 {/* 編集データの保存ボタン */}
//                 <Grid item xs={12} sm={4} md={3} sx={{ textAlign: 'right' }}>
//                   <Button
//                     variant="contained"
//                     color="primary"
//                     startIcon={<SaveIcon />}
//                     onClick={handleSaveEditedData}
//                     sx={{
//                       mt: { xs: 2, sm: 0 },
//                       backgroundColor: '#1976d2',
//                       '&:hover': {
//                         backgroundColor: '#1565c0',
//                       },
//                     }}
//                   >
//                     編集データを保存
//                   </Button>
//                 </Grid>
//               </Grid>
//             </Paper>

//             {/* インポートされたCSVデータの表示と編集 */}
//             {csvData.length > 0 && (
//               <Grid container spacing={4}>
//                 {/* ColumnNameBasedTemplateManagerコンポーネント */}
//                 <Grid item xs={12}>
//                   <ColumnNameBasedTemplateManager
//                     data={csvData}
//                     setData={setCsvData}
//                     columns={columns}
//                     setColumns={setColumns}
//                   />
//                 </Grid>

//                 {/* その他のコンポーネント */}
//                 <Grid container spacing={4} sx={{ display: 'flex', p: 2 }}>
//                   {/* ColumnDeletionコンポーネント */}
//                   <Grid item xs={12} md={4}>
//                     <Paper elevation={3} sx={{ flexGrow: 1, p: 2, m: 1 }}>
//                       <ColumnDeletion
//                         data={csvData}
//                         setData={setCsvData}
//                         columns={columns}
//                         setColumns={setColumns}
//                       />
//                     </Paper>
//                   </Grid>

//                   {/* PriceCalculationSettingsコンポーネント */}
//                   <Grid item xs={12} md={4}>
//                     <Paper elevation={3} sx={{ flexGrow: 1, p: 2, m: 1 }}>
//                       <PriceCalculationSettings
//                         customSettings={customSettings}
//                         data={csvData}
//                         setData={setCsvData}
//                       />
//                     </Paper>
//                   </Grid>

//                   {/* ItemSpecificsApplierコンポーネント */}
//                   <Grid item xs={12} md={4}>
//                     <Paper elevation={3} sx={{ flexGrow: 1, p: 2, m: 1 }}>
//                       <ItemSpecificsApplier data={csvData} setData={setCsvData} userId={user ? user.uid : null} />
//                     </Paper>
//                   </Grid>
//                 </Grid>

//                 {/* 画像関連のコンポーネント */}
//                 <Grid container spacing={4} sx={{ display: 'flex', p: 2 }}>
//                   <Grid item xs={12} md={6}>
//                     <Paper elevation={3} sx={{ p: 3, mb: 0, ml: 1, mt: 0 }}>
//                       <ImageDownloader data={csvData} setData={setCsvData} />
//                     </Paper>
//                   </Grid>

//                   <Grid item xs={12} md={6}>
//                     <Paper elevation={3} sx={{ p: 3, mb: 0 }}>
//                       <ImageUrlReplacer data={csvData} setData={setCsvData} />
//                     </Paper>
//                   </Grid>
//                 </Grid>

//                 {/* AI機能のセクション */}
//                 <Grid item xs={12}>
//                   <AppBar position="static" color="primary" sx={{ mt: 0 }}>
//                     <Toolbar>
//                       <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
//                         AI機能 ※API利用
//                       </Typography>
//                     </Toolbar>
//                   </AppBar>
//                 </Grid>

//                 {/* タイトル生成と商品説明生成のコンポーネント */}
//                 <Grid container spacing={4} sx={{ display: 'flex', p: 2 }}>
//                   <Grid item xs={12} md={6}>
//                     <TitleGenerationComponent data={csvData} setData={setCsvData} apiKey={apiKey} user={user} />
//                   </Grid>

//                   <Grid item xs={12} md={6}>
//                     <OpenAIDescriptionGenerator data={csvData} setData={setCsvData} apiKey={apiKey} user={user}  />
//                   </Grid>
//                 </Grid>

//                 {/* 編集可能なスプレッドシート */}
//                 <Grid item xs={12}>
//                   <EditableSpreadsheet
//                     data={csvData}
//                     setData={setCsvData}
//                     columns={columns}
//                     setColumns={setColumns}
//                   />
//                 </Grid>
//               </Grid>
//             )}
//           </Paper>
//         </Grid>
//       </Grid>

//       {/* ヘルプのドロワー */}
//       <Drawer anchor="right" open={isHelpOpen} onClose={() => setIsHelpOpen(false)}>
//         <Box sx={{ width: 300, p: 2 }}>
//           <InteractiveManual />
//         </Box>
//       </Drawer>

//       {/* APIキー管理ダイアログ */}
//       <Dialog open={isApiKeyDialogOpen} onClose={() => setIsApiKeyDialogOpen(false)}>
//         <DialogTitle>APIキー管理</DialogTitle>
//         <DialogContent>
//           <APIKeyInput
//             user={user}
//             apiKey={apiKey}
//             setApiKey={setApiKey}
//             onApiKeySet={handleApiKeySet}
//           />
//         </DialogContent>
//         <DialogActions>
//           <Button onClick={() => setIsApiKeyDialogOpen(false)}>閉じる</Button>
//         </DialogActions>
//       </Dialog>

//       {/* 設定のダイアログ */}
//       <Dialog open={isCustomSettingsOpen} onClose={handleCloseCustomSettings} fullWidth maxWidth="md">
//         <DialogTitle>価格計算設定</DialogTitle>
//         <DialogContent>
//           <CustomColumnSettings
//             onSave={handleSaveCustomSettings}
//             initialSettings={customSettings}
//             userId={user ? user.uid : null}
//           />
//         </DialogContent>
//       </Dialog>

//       <Dialog
//         open={isItemSpecificsSettingsOpen}
//         onClose={handleItemSpecificsSettingsClose}
//         fullWidth
//         maxWidth="md"
//         fullScreen // ここを追加
//       >
//         <DialogTitle>Item Specificsの管理画面</DialogTitle>
//         <DialogContent>
//           <ItemSpecificsSettings
//             onSave={handleItemSpecificsSettingsSave}
//             onClose={handleItemSpecificsSettingsClose}
//             headers={columns}
//             userId={user ? user.uid : null}
//           />
//         </DialogContent>
//         <DialogActions>
//           <Button onClick={handleItemSpecificsSettingsClose}>閉じる</Button>
//         </DialogActions>
//       </Dialog>

//       {/* Snackbarによる通知 */}
//       <Snackbar
//         open={snackbar.open}
//         autoHideDuration={6000}
//         onClose={() => setSnackbar({ ...snackbar, open: false })}
//       >
//         <Alert
//           onClose={() => setSnackbar({ ...snackbar, open: false })}
//           severity={snackbar.severity}
//           sx={{ width: '100%' }}
//         >
//           {snackbar.message}
//         </Alert>
//       </Snackbar>

//       {/* フッターのリンク */}
//       <Box sx={{ mt: 4, textAlign: 'center' }}>
//         <Typography variant="body2">
//           <Link component={RouterLink} to="/terms">
//             利用規約
//           </Link>
//           {' | '}
//           <Link component={RouterLink} to="/privacy">
//             プライバシーポリシー
//           </Link>
//           {' | '}
//           <Link component={RouterLink} to="/legal-disclosure">
//             特定商取引法に基づく表記
//           </Link>
//         </Typography>
//       </Box>
//     </Box>
//   );
// }

// function App() {
//   const [isAuthenticated, setIsAuthenticated] = useState(false);
//   const [isLoading, setIsLoading] = useState(true);

//   // 認証状態の監視
//   useEffect(() => {
//     const unsubscribe = onAuthStateChanged(auth, (user) => {
//       setIsAuthenticated(!!user);
//       setIsLoading(false);
//     });
//     return () => unsubscribe();
//   }, []);

//   if (isLoading) {
//     return (
//       <div style={{ textAlign: 'center', marginTop: '20%' }}>
//         <CircularProgress />
//       </div>
//     );
//   }

//   return (
//     <Router>
//       <Routes>
//         <Route
//           path="/login"
//           element={
//             isAuthenticated ? <Navigate to="/" replace /> : <Login setIsAuthenticated={setIsAuthenticated} />
//           }
//         />
//         <Route path="/terms" element={<TermsOfService />} />
//         <Route path="/privacy" element={<PrivacyPolicy />} />
//         <Route path="/legal-disclosure" element={<LegalDisclosure />} />
//         <Route
//           path="/admin"
//           element={
//             isAuthenticated ? (
//               <AdminNotificationSender />
//             ) : (
//               <Navigate to="/login" replace />
//             )
//           }
//         />
//         <Route
//           path="/"
//           element={
//             isAuthenticated ? (
//               <AppContent setIsAuthenticated={setIsAuthenticated} />
//             ) : (
//               <Navigate to="/login" replace />
//             )
//           }
//         />
//         {/* 存在しないルートへの対応 */}
//         <Route path="*" element={<Navigate to="/login" replace />} />
//       </Routes>
//     </Router>
//   );
// }

// export default App;