// BulkColumnEdit.js

import React, { useState, useEffect } from 'react';
import Select from 'react-select/creatable';
import Modal from 'react-modal';
import Papa from 'papaparse';
import {
  Box,
  Button,
  TextField,
  Typography,
  Grid,
  Paper,
  Snackbar,
  Alert,
} from '@mui/material';
import PropTypes from 'prop-types';

// モーダルのアクセシビリティ設定
Modal.setAppElement('#root');

function BulkColumnEdit({ data = [], setData, columns = [], setColumns }) {
  // ステート変数の定義
  const [selectedColumn, setSelectedColumn] = useState('');
  const [newValue, setNewValue] = useState('');
  const [csvData, setCsvData] = useState(null);
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: 'info',
  });

  // 以下、削除ワード関連のステートを追加
  const [error, setError] = useState(null);
  const [deleteWords, setDeleteWords] = useState([]); // 削除ワードのステート
  const [isConfirmOpen, setIsConfirmOpen] = useState(false);
  const [rowsToDelete, setRowsToDelete] = useState([]);
  const [deletionLog, setDeletionLog] = useState([]);
  const [titleIndex, setTitleIndex] = useState(-1);
  const [jpTitleIndex, setJpTitleIndex] = useState(-1);
  const [isDeletionLogOpen, setIsDeletionLogOpen] = useState(false);
  const [isManageModalOpen, setIsManageModalOpen] = useState(false); // 管理モーダルのステート
  const [importText, setImportText] = useState(''); // 一括インポート用のローカルステート

  // テンプレート関連のステート
  const [templates, setTemplates] = useState([]); // テンプレートのリスト
  const [selectedTemplateName, setSelectedTemplateName] = useState(''); // 選択されたテンプレート名
  const [newTemplateName, setNewTemplateName] = useState(''); // 新しいテンプレート名

  // Effect hooks
  useEffect(() => {
    if (data && data.length > 0) {
      const headers = Object.keys(data[0]);
      setTitleIndex(headers.findIndex((header) => header.toLowerCase() === 'title'));
      setJpTitleIndex(headers.findIndex((header) => header.toLowerCase() === 'jp_title'));
    }
  }, [data]);

  // テンプレートをローカルストレージから読み込む
  useEffect(() => {
    const storedTemplates = localStorage.getItem('deleteWordTemplates');
    if (storedTemplates) {
      const templatesArray = JSON.parse(storedTemplates);
      setTemplates(templatesArray);
    }
  }, []);

  // テンプレートの変更をローカルストレージに保存
  useEffect(() => {
    localStorage.setItem('deleteWordTemplates', JSON.stringify(templates));
  }, [templates]);

  // 削除ワードをローカルストレージから読み込む
  useEffect(() => {
    const storedWords = localStorage.getItem('deleteWords');
    if (storedWords) {
      const wordsArray = JSON.parse(storedWords);
      const formattedWords = wordsArray.map((word) => ({ value: word, label: word }));
      setDeleteWords(formattedWords);
    }
  }, []);

  // 削除ワードの変更をローカルストレージに保存
  useEffect(() => {
    const wordsArray = deleteWords.map((word) => word.value);
    localStorage.setItem('deleteWords', JSON.stringify(wordsArray));
  }, [deleteWords]);

  // 選択されたテンプレート名をローカルストレージから読み込む
  useEffect(() => {
    const storedSelectedTemplateName = localStorage.getItem('selectedTemplateName');
    if (storedSelectedTemplateName) {
      setSelectedTemplateName(storedSelectedTemplateName);
    }
  }, []);

  // 選択されたテンプレート名をローカルストレージに保存
  useEffect(() => {
    localStorage.setItem('selectedTemplateName', selectedTemplateName);
  }, [selectedTemplateName]);

  // テンプレートまたはテンプレートのリストが更新されたときに、選択されたテンプレートを適用
  useEffect(() => {
    if (selectedTemplateName && templates.length > 0) {
      const template = templates.find((t) => t.name === selectedTemplateName);
      if (template) {
        // テンプレートを適用
        const formattedWords = template.words.map((word) => ({
          value: word,
          label: word,
        }));
        setDeleteWords(formattedWords);
      } else {
        // テンプレートが見つからない場合、選択を解除
        setSelectedTemplateName('');
      }
    }
  }, [selectedTemplateName, templates]);

  // 削除ワード機能の関数

  const splitTitleIntoWords = (title) => {
    if (!title) return [];
    return title.split(/\s+/).filter(Boolean);
  };

  const handleDeleteWordsChange = (newWords) => {
    setDeleteWords(newWords || []);
  };

  const handleDeleteRowsByWord = () => {
    if (deleteWords.length === 0) {
      setError('削除したいワードを入力してください。');
      return;
    }

    if (data.length === 0) {
      setError('データがありません。');
      return;
    }

    try {
      if (titleIndex === -1 && jpTitleIndex === -1) {
        setError('"Title" カラムまたは "jp_title" カラムが見つかりません。');
        return;
      }

      const words = deleteWords.map((word) => word.value).filter((w) => w);

      if (words.length === 0) {
        setError('有効な削除ワードがありません。');
        return;
      }

      const wordsLower = words.map((word) => word.toLowerCase());

      const rowsToDeleteTemp = [];
      const deletionLogTemp = [];

      for (let i = 0; i < data.length; i++) {
        const row = data[i];
        const titleCell =
          titleIndex !== -1 ? row[columns[titleIndex]] || '' : '';
        const jpTitleCell =
          jpTitleIndex !== -1 ? row[columns[jpTitleIndex]] || '' : '';

        // タイトルを空白で分割して単語の配列を取得
        const titleWords = splitTitleIntoWords(titleCell);
        const jpTitleWords = splitTitleIntoWords(jpTitleCell);

        const titleWordsLower = titleWords.map((word) => word.toLowerCase());
        const jpTitleWordsLower = jpTitleWords.map((word) => word.toLowerCase());

        // 削除ワードとタイトル内の単語を比較
        const titleMatches = wordsLower.some((word) =>
          titleWordsLower.includes(word)
        );
        const jpTitleMatches = wordsLower.some((word) =>
          jpTitleWordsLower.includes(word)
        );

        if (titleMatches || jpTitleMatches) {
          rowsToDeleteTemp.push(i);
          deletionLogTemp.push({
            rowIndex: i,
            rowData: row,
          });
        }
      }

      if (rowsToDeleteTemp.length === 0) {
        setError('指定したワードを含む行は見つかりませんでした。');
        return;
      }

      rowsToDeleteTemp.sort((a, b) => b - a);

      setRowsToDelete(rowsToDeleteTemp);
      setDeletionLog((prevLog) => [...prevLog, ...deletionLogTemp]);
      setIsConfirmOpen(true);
      setError(null);
    } catch (err) {
      console.error('Error preparing rows for deletion:', err);
      setError('行の削除準備中にエラーが発生しました');
    }
  };

  const confirmDeleteRows = () => {
    try {
      let newData = [...data];
      for (let rowIndex of rowsToDelete) {
        newData.splice(rowIndex, 1);
      }
      setData(newData);
      setIsConfirmOpen(false);
      setRowsToDelete([]);
      setError(null);
    } catch (err) {
      console.error('Error deleting rows:', err);
      setError('行の削除に失敗しました');
      setIsConfirmOpen(false);
    }
  };

  const cancelDeleteRows = () => {
    setIsConfirmOpen(false);
    setRowsToDelete([]);
  };

  const handleRestoreRow = (log) => {
    try {
      const newData = [...data];
      newData.push(log.rowData);
      setData(newData);

      setDeletionLog((prevLog) => prevLog.filter((item) => item !== log));

      setError(null);
    } catch (err) {
      console.error('Error restoring row:', err);
      setError('行の復元に失敗しました');
    }
  };

  // ワード管理用モーダルのハンドラー
  const openManageModal = () => {
    setIsManageModalOpen(true);
  };

  const closeManageModal = () => {
    setIsManageModalOpen(false);
  };

  // モーダル内でのワード管理（追加・削除）
  const handleManageAddWord = (newWord) => {
    if (newWord && !deleteWords.some((word) => word.value === newWord)) {
      setDeleteWords([...deleteWords, { value: newWord, label: newWord }]);
    }
  };

  const handleManageDeleteWord = (wordToDelete) => {
    setDeleteWords(
      deleteWords.filter((word) => word.value !== wordToDelete.value)
    );
  };

  // 一括インポート機能のハンドラー
  const handleImportWords = () => {
    if (importText.trim() === '') return;

    // カンマ、全角カンマ、改行で分割
    const importedWords = importText
      .split(/[,、\n]+/)
      .map((w) => w.trim())
      .filter((w) => w);
    const uniqueImportedWords = Array.from(new Set(importedWords));
    const formattedImportedWords = uniqueImportedWords.map((w) => ({
      value: w,
      label: w,
    }));

    setDeleteWords((prevWords) => {
      const updatedWords = [...prevWords];
      formattedImportedWords.forEach((w) => {
        if (!updatedWords.some((word) => word.value === w.value)) {
          updatedWords.push(w);
        }
      });
      return updatedWords;
    });

    setImportText(''); // テキストエリアをクリア
  };

  // テンプレートを保存
  const saveTemplate = () => {
    if (!newTemplateName.trim()) {
      setError('テンプレート名を入力してください。');
      return;
    }

    // 同じ名前のテンプレートが存在するか確認
    const existingTemplate = templates.find(
      (t) => t.name === newTemplateName.trim()
    );
    if (existingTemplate) {
      setError('同じ名前のテンプレートが既に存在します。別の名前を使用してください。');
      return;
    }

    // 削除ワードを文字列の配列に変換
    const wordsArray = deleteWords.map((word) => word.value);

    const newTemplate = {
      name: newTemplateName.trim(),
      words: wordsArray,
    };

    setTemplates([...templates, newTemplate]);
    setNewTemplateName('');
    setError(null);
  };

  // テンプレートを適用
  const applyTemplate = (template) => {
    // 削除ワードを { value, label } の形式に変換
    const formattedWords = template.words.map((word) => ({
      value: word,
      label: word,
    }));
    setDeleteWords(formattedWords);
    setSelectedTemplateName(template.name);
  };

  // テンプレートを削除
  const deleteTemplate = (templateToDelete) => {
    setTemplates(templates.filter((t) => t.name !== templateToDelete.name));
    if (selectedTemplateName === templateToDelete.name) {
      setSelectedTemplateName('');
      setDeleteWords([]);
    }
  };

  // モーダルのスタイル設定
  const confirmDialogStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      transform: 'translate(-50%, -50%)',
      width: '50%',
      maxHeight: '70%',
      overflow: 'auto',
    },
    overlay: {
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
    },
  };

  const deletionLogModalStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      transform: 'translate(-50%, -50%)',
      width: '80%',
      maxHeight: '80%',
      overflow: 'auto',
    },
    overlay: {
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
    },
  };

  // カラム一括編集のハンドラー
  const handleBulkEdit = () => {
    if (!selectedColumn || !newValue) {
      setSnackbar({
        open: true,
        message: '列名と新しい値を入力してください',
        severity: 'error',
      });
      return;
    }

    const updatedData = data.map((row) => ({
      ...row,
      [selectedColumn]: newValue,
    }));

    setData(updatedData);
    setSnackbar({
      open: true,
      message: `列 "${selectedColumn}" が更新されました`,
      severity: 'success',
    });
  };

  // CSVダウンロードのハンドラー
  const handleCsvDownload = () => {
    if (!data || data.length === 0) {
      setSnackbar({
        open: true,
        message: 'ダウンロードするデータがありません',
        severity: 'error',
      });
      return;
    }

    const csv = Papa.unparse(data);
    const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });

    // 現在の日付を取得
    const currentDate = new Date().toISOString().split('T')[0]; // YYYY-MM-DD形式

    // 最初の商品タイトルを取得し、先頭10文字を抜き出す
    const firstTitle = data[0]['Title'] || data[0]['title'] || 'Untitled';
    const truncatedTitle = firstTitle.substring(0, 10);

    // ファイル名に使用できない文字を置換する関数
    const sanitizeFileName = (name) => name.replace(/[\\/:*?"<>|]/g, '_');

    // サニタイズしたタイトルを取得
    const safeTitle = sanitizeFileName(truncatedTitle);

    // ファイル名を生成
    const fileName = `${safeTitle}_${currentDate}.csv`;

    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

    setSnackbar({
      open: true,
      message: `CSVのダウンロードが完了しました。ファイル名: ${fileName}`,
      severity: 'success',
    });
  };

  // レンダリング部分
  return (
    <Paper elevation={3} sx={{ p: 2, mt: 0 }}>
      <Grid container spacing={2}>
        {/* カラム一括編集 */}
        <Grid item xs={12} md={4}>
          <Typography variant="subtitle1">カラム一括編集</Typography>
          <TextField
            select
            label="列名を選択"
            value={selectedColumn}
            onChange={(e) => setSelectedColumn(e.target.value)}
            variant="outlined"
            margin="normal"
            fullWidth
            SelectProps={{
              native: true,
            }}
          >
            <option value="" disabled>
              列名を選択してください
            </option>
            {Array.isArray(columns) &&
              columns.map((column, index) => (
                <option key={index} value={column}>
                  {column}
                </option>
              ))}
          </TextField>
          <TextField
            type="text"
            value={newValue}
            onChange={(e) => setNewValue(e.target.value)}
            label="新しい値"
            variant="outlined"
            margin="normal"
            fullWidth
          />
          <Button
            onClick={handleBulkEdit}
            variant="contained"
            color="primary"
            fullWidth
          >
            アップデート
          </Button>
        </Grid>

        {/* 削除ワード入力と管理ボタン */}
        <Grid item xs={12} md={4}>
          <Typography variant="subtitle1">削除ワードによる行の削除</Typography>
          <Select
            isMulti
            isClearable
            onChange={handleDeleteWordsChange}
            options={deleteWords}
            value={deleteWords}
            placeholder="削除したいワードを入力"
            components={{
              DropdownIndicator: null,
            }}
            styles={{
              multiValue: (styles) => ({
                ...styles,
                backgroundColor: '#e0e0e0',
              }),
              multiValueLabel: (styles) => ({
                ...styles,
                color: '#333',
              }),
              multiValueRemove: (styles) => ({
                ...styles,
                color: '#666',
                ':hover': {
                  backgroundColor: '#ccc',
                  color: '#000',
                },
              }),
            }}
          />
          <Button
            onClick={openManageModal}
            variant="outlined"
            color="primary"
            fullWidth
            sx={{ mt: 1 }}
          >
            ワードを管理
          </Button>
          <Button
            onClick={handleDeleteRowsByWord}
            variant="contained"
            color="secondary"
            fullWidth
            sx={{ mt: 1 }}
          >
            指定ワードを含む行を削除
          </Button>
          <Button
            onClick={() => setIsDeletionLogOpen(true)}
            variant="outlined"
            color="secondary"
            fullWidth
            sx={{ mt: 1 }}
          >
            削除された商品を見る
          </Button>
        </Grid>

        {/* CSVダウンロード */}
        <Grid item xs={12} md={4}>
          <Typography variant="subtitle1">CSVダウンロード</Typography>
          <Button
            onClick={handleCsvDownload}
            variant="contained"
            color="primary"
            fullWidth
            sx={{ mt: 4 }}
          >
            CSVをダウンロード
          </Button>
        </Grid>
      </Grid>

      {/* エラーメッセージ表示 */}
      {error && (
        <Typography color="error" variant="body1" sx={{ mt: 2 }}>
          {error}
        </Typography>
      )}

      {/* 確認ダイアログ */}
      <Modal
        isOpen={isConfirmOpen}
        onRequestClose={cancelDeleteRows}
        contentLabel="削除確認"
        style={confirmDialogStyles}
      >
        <div className="confirm-dialog-content">
          <p>以下の {rowsToDelete.length} 行を削除します。よろしいですか？</p>
          <ul>
            {rowsToDelete.map((rowIndex, idx) => (
              <li key={idx}>
                行 {rowIndex + 1}:{' '}
                {titleIndex !== -1 &&
                  `Title="${data[rowIndex][columns[titleIndex]]}"`}{' '}
                {jpTitleIndex !== -1 &&
                  `jp_title="${data[rowIndex][columns[jpTitleIndex]]}"`}
              </li>
            ))}
          </ul>
          <Button onClick={confirmDeleteRows} variant="contained" color="secondary">
            削除する
          </Button>
          <Button
            onClick={cancelDeleteRows}
            variant="outlined"
            color="primary"
            sx={{ ml: 2 }}
          >
            キャンセル
          </Button>
        </div>
      </Modal>

      {/* 削除ログモーダル */}
      <Modal
        isOpen={isDeletionLogOpen}
        onRequestClose={() => setIsDeletionLogOpen(false)}
        contentLabel="削除された商品"
        style={deletionLogModalStyles}
      >
        <div className="deletion-log-content">
          <h3>削除された商品</h3>
          <Button
            onClick={() => setIsDeletionLogOpen(false)}
            variant="outlined"
            color="primary"
            sx={{ mb: 2 }}
          >
            閉じる
          </Button>
          {deletionLog.length > 0 ? (
            <table className="deleted-items-table">
              <thead>
                <tr>
                  <th>行番号</th>
                  {titleIndex !== -1 && <th>Title</th>}
                  {jpTitleIndex !== -1 && <th>jp_title</th>}
                  <th>操作</th>
                </tr>
              </thead>
              <tbody>
                {deletionLog.map((log, index) => (
                  <tr key={index}>
                    <td>{log.rowIndex + 1}</td>
                    {titleIndex !== -1 && (
                      <td>{log.rowData[columns[titleIndex]]}</td>
                    )}
                    {jpTitleIndex !== -1 && (
                      <td>{log.rowData[columns[jpTitleIndex]]}</td>
                    )}
                    <td>
                      <Button
                        onClick={() => handleRestoreRow(log)}
                        variant="contained"
                        color="primary"
                      >
                        復元
                      </Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <p>削除された商品はありません。</p>
          )}
          <p>復元された商品はデータの末尾に追加されます。</p>
        </div>
      </Modal>

      {/* ワード管理モーダル */}
      <Modal
        isOpen={isManageModalOpen}
        onRequestClose={closeManageModal}
        contentLabel="削除ワード管理"
        style={{
          content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            transform: 'translate(-50%, -50%)',
            width: '60%',
            maxHeight: '80%',
            overflow: 'auto',
          },
          overlay: {
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
          },
        }}
      >
        <h2>削除ワードを管理</h2>
        {/* テンプレートの選択 */}
        <div className="template-selection">
          <h3>テンプレートを選択</h3>
          <ul className="template-list">
            {templates.map((template, index) => (
              <li key={index}>
                <span
                  onClick={() => applyTemplate(template)}
                  style={{
                    cursor: 'pointer',
                    color: selectedTemplateName === template.name ? 'blue' : 'black',
                  }}
                >
                  {template.name}
                </span>
                <button onClick={() => deleteTemplate(template)}>削除</button>
              </li>
            ))}
          </ul>
          <div className="new-template">
            <input
              type="text"
              placeholder="新しいテンプレート名"
              value={newTemplateName}
              onChange={(e) => setNewTemplateName(e.target.value)}
            />
            <button onClick={saveTemplate}>テンプレートを保存</button>
          </div>
        </div>

        <div className="manage-words-input">
          <input
            type="text"
            placeholder="新しいワードを入力"
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                e.preventDefault();
                const newWord = e.target.value.trim();
                if (newWord) {
                  handleManageAddWord(newWord);
                  e.target.value = '';
                }
              }
            }}
          />
          <button
            onClick={() => {
              const input = document.querySelector('.manage-words-input input');
              const newWord = input.value.trim();
              if (newWord) {
                handleManageAddWord(newWord);
                input.value = '';
              }
            }}
          >
            追加
          </button>
        </div>
        {/* 一括インポート機能 */}
        <div className="manage-words-import">
          <textarea
            placeholder="一括でワードを入力（カンマ、改行で区切る）"
            rows="4"
            value={importText}
            onChange={(e) => setImportText(e.target.value)}
          />
          <button onClick={handleImportWords}>一括インポート</button>
        </div>
        <ul className="manage-words-list">
          {deleteWords.map((word, index) => (
            <li key={index}>
              {word.label}
              <button onClick={() => handleManageDeleteWord(word)}>削除</button>
            </li>
          ))}
        </ul>
        <button onClick={closeManageModal} className="close-modal-button">
          閉じる
        </button>
      </Modal>

      {/* Snackbarによる通知 */}
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
      >
        <Alert
          onClose={() => setSnackbar({ ...snackbar, open: false })}
          severity={snackbar.severity}
          sx={{ width: '100%' }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Paper>
  );
}

BulkColumnEdit.propTypes = {
  data: PropTypes.array.isRequired,
  setData: PropTypes.func.isRequired,
  columns: PropTypes.array.isRequired,
  setColumns: PropTypes.func.isRequired,
};

export default BulkColumnEdit;






// // BulkColumnEdit.js

// import React, { useState, useEffect } from 'react';
// import Select from 'react-select/creatable';
// import Modal from 'react-modal';
// import Papa from 'papaparse';
// import {
//   Box,
//   Button,
//   TextField,
//   Typography,
//   Grid,
//   Paper,
//   Snackbar,
//   Alert,
// } from '@mui/material';
// import PropTypes from 'prop-types';

// // モーダルのアクセシビリティ設定
// Modal.setAppElement('#root');

// function BulkColumnEdit({ data = [], setData, columns = [], setColumns }) {
//   // ステート変数の定義
//   const [selectedColumn, setSelectedColumn] = useState('');
//   const [newValue, setNewValue] = useState('');
//   const [csvData, setCsvData] = useState(null);
//   const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'info' });

//   // 以下、削除ワード関連のステートを追加
//   const [error, setError] = useState(null);
//   const [deleteWords, setDeleteWords] = useState([]); // 削除ワードのステート
//   const [isConfirmOpen, setIsConfirmOpen] = useState(false);
//   const [rowsToDelete, setRowsToDelete] = useState([]);
//   const [deletionLog, setDeletionLog] = useState([]);
//   const [titleIndex, setTitleIndex] = useState(-1);
//   const [jpTitleIndex, setJpTitleIndex] = useState(-1);
//   const [isDeletionLogOpen, setIsDeletionLogOpen] = useState(false);
//   const [isManageModalOpen, setIsManageModalOpen] = useState(false); // 管理モーダルのステート
//   const [importText, setImportText] = useState(''); // 一括インポート用のローカルステート

//   // テンプレート関連のステート
//   const [templates, setTemplates] = useState([]); // テンプレートのリスト
//   const [selectedTemplateName, setSelectedTemplateName] = useState(''); // 選択されたテンプレート名
//   const [newTemplateName, setNewTemplateName] = useState(''); // 新しいテンプレート名

//   // Effect hooks
//   useEffect(() => {
//     if (data && data.length > 0) {
//       const headers = Object.keys(data[0]);
//       setTitleIndex(headers.findIndex((header) => header.toLowerCase() === 'title'));
//       setJpTitleIndex(headers.findIndex((header) => header.toLowerCase() === 'jp_title'));
//     }
//   }, [data]);

//   // テンプレートをローカルストレージから読み込む
//   useEffect(() => {
//     const storedTemplates = localStorage.getItem('deleteWordTemplates');
//     if (storedTemplates) {
//       const templatesArray = JSON.parse(storedTemplates);
//       setTemplates(templatesArray);
//     }
//   }, []);

//   // テンプレートの変更をローカルストレージに保存
//   useEffect(() => {
//     localStorage.setItem('deleteWordTemplates', JSON.stringify(templates));
//   }, [templates]);

//   // 削除ワードをローカルストレージから読み込む
//   useEffect(() => {
//     const storedWords = localStorage.getItem('deleteWords');
//     if (storedWords) {
//       const wordsArray = JSON.parse(storedWords);
//       const formattedWords = wordsArray.map((word) => ({ value: word, label: word }));
//       setDeleteWords(formattedWords);
//     }
//   }, []);

//   // 削除ワードの変更をローカルストレージに保存
//   useEffect(() => {
//     const wordsArray = deleteWords.map((word) => word.value);
//     localStorage.setItem('deleteWords', JSON.stringify(wordsArray));
//   }, [deleteWords]);

//   // 選択されたテンプレート名をローカルストレージから読み込む
//   useEffect(() => {
//     const storedSelectedTemplateName = localStorage.getItem('selectedTemplateName');
//     if (storedSelectedTemplateName) {
//       setSelectedTemplateName(storedSelectedTemplateName);
//     }
//   }, []);

//   // 選択されたテンプレート名をローカルストレージに保存
//   useEffect(() => {
//     localStorage.setItem('selectedTemplateName', selectedTemplateName);
//   }, [selectedTemplateName]);

//   // テンプレートまたはテンプレートのリストが更新されたときに、選択されたテンプレートを適用
//   useEffect(() => {
//     if (selectedTemplateName && templates.length > 0) {
//       const template = templates.find((t) => t.name === selectedTemplateName);
//       if (template) {
//         // テンプレートを適用
//         const formattedWords = template.words.map((word) => ({ value: word, label: word }));
//         setDeleteWords(formattedWords);
//       } else {
//         // テンプレートが見つからない場合、選択を解除
//         setSelectedTemplateName('');
//       }
//     }
//   }, [selectedTemplateName, templates]);

//   // 以下、削除ワード機能の関数を追加

//   const handleDeleteWordsChange = (newWords) => {
//     setDeleteWords(newWords || []);
//   };

//   const handleDeleteRowsByWord = () => {
//     if (deleteWords.length === 0) {
//       setError('削除したいワードを入力してください。');
//       return;
//     }

//     if (data.length === 0) {
//       setError('データがありません。');
//       return;
//     }

//     try {
//       if (titleIndex === -1 && jpTitleIndex === -1) {
//         setError('"Title" カラムまたは "jp_title" カラムが見つかりません。');
//         return;
//       }

//       const words = deleteWords.map((word) => word.value).filter((w) => w);

//       if (words.length === 0) {
//         setError('有効な削除ワードがありません。');
//         return;
//       }

//       // 正規表現を使用して完全一致を判定（大文字小文字を無視）
//       const regexes = words.map((word) => new RegExp(`\\b${escapeRegExp(word)}\\b`, 'i'));

//       const rowsToDeleteTemp = [];
//       const deletionLogTemp = [];

//       for (let i = 0; i < data.length; i++) {
//         const row = data[i];
//         const titleCell = titleIndex !== -1 ? row[columns[titleIndex]] : '';
//         const jpTitleCell = jpTitleIndex !== -1 ? row[columns[jpTitleIndex]] : '';

//         const titleMatches = regexes.some((regex) => regex.test(titleCell));
//         const jpTitleMatches = regexes.some((regex) => regex.test(jpTitleCell));

//         if (titleMatches || jpTitleMatches) {
//           rowsToDeleteTemp.push(i);
//           deletionLogTemp.push({
//             rowIndex: i,
//             rowData: row,
//           });
//         }
//       }

//       if (rowsToDeleteTemp.length === 0) {
//         setError('指定したワードを含む行は見つかりませんでした。');
//         return;
//       }

//       rowsToDeleteTemp.sort((a, b) => b - a);

//       setRowsToDelete(rowsToDeleteTemp);
//       setDeletionLog((prevLog) => [...prevLog, ...deletionLogTemp]);
//       setIsConfirmOpen(true);
//       setError(null);
//     } catch (err) {
//       console.error('Error preparing rows for deletion:', err);
//       setError('行の削除準備中にエラーが発生しました');
//     }
//   };

//   // 正規表現で特殊文字をエスケープする関数
//   const escapeRegExp = (string) => {
//     return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& はマッチした文字列全体
//   };

//   const confirmDeleteRows = () => {
//     try {
//       let newData = [...data];
//       for (let rowIndex of rowsToDelete) {
//         newData.splice(rowIndex, 1);
//       }
//       setData(newData);
//       setIsConfirmOpen(false);
//       setRowsToDelete([]);
//       setError(null);
//     } catch (err) {
//       console.error('Error deleting rows:', err);
//       setError('行の削除に失敗しました');
//       setIsConfirmOpen(false);
//     }
//   };

//   const cancelDeleteRows = () => {
//     setIsConfirmOpen(false);
//     setRowsToDelete([]);
//   };

//   const handleRestoreRow = (log) => {
//     try {
//       const newData = [...data];
//       newData.push(log.rowData);
//       setData(newData);

//       setDeletionLog((prevLog) => prevLog.filter((item) => item !== log));

//       setError(null);
//     } catch (err) {
//       console.error('Error restoring row:', err);
//       setError('行の復元に失敗しました');
//     }
//   };

//   // ワード管理用モーダルのハンドラー
//   const openManageModal = () => {
//     setIsManageModalOpen(true);
//   };

//   const closeManageModal = () => {
//     setIsManageModalOpen(false);
//   };

//   // モーダル内でのワード管理（追加・削除）
//   const handleManageAddWord = (newWord) => {
//     if (newWord && !deleteWords.some((word) => word.value === newWord)) {
//       setDeleteWords([...deleteWords, { value: newWord, label: newWord }]);
//     }
//   };

//   const handleManageDeleteWord = (wordToDelete) => {
//     setDeleteWords(deleteWords.filter((word) => word.value !== wordToDelete.value));
//   };

//   // 一括インポート機能のハンドラー
//   const handleImportWords = () => {
//     if (importText.trim() === '') return;

//     // カンマ、全角カンマ、改行で分割
//     const importedWords = importText
//       .split(/[,、\n]+/)
//       .map((w) => w.trim())
//       .filter((w) => w);
//     const uniqueImportedWords = Array.from(new Set(importedWords));
//     const formattedImportedWords = uniqueImportedWords.map((w) => ({ value: w, label: w }));

//     setDeleteWords((prevWords) => {
//       const updatedWords = [...prevWords];
//       formattedImportedWords.forEach((w) => {
//         if (!updatedWords.some((word) => word.value === w.value)) {
//           updatedWords.push(w);
//         }
//       });
//       return updatedWords;
//     });

//     setImportText(''); // テキストエリアをクリア
//   };

//   // テンプレートを保存
//   const saveTemplate = () => {
//     if (!newTemplateName.trim()) {
//       setError('テンプレート名を入力してください。');
//       return;
//     }

//     // 同じ名前のテンプレートが存在するか確認
//     const existingTemplate = templates.find((t) => t.name === newTemplateName.trim());
//     if (existingTemplate) {
//       setError('同じ名前のテンプレートが既に存在します。別の名前を使用してください。');
//       return;
//     }

//     // 削除ワードを文字列の配列に変換
//     const wordsArray = deleteWords.map((word) => word.value);

//     const newTemplate = {
//       name: newTemplateName.trim(),
//       words: wordsArray,
//     };

//     setTemplates([...templates, newTemplate]);
//     setNewTemplateName('');
//     setError(null);
//   };

//   // テンプレートを適用
//   const applyTemplate = (template) => {
//     // 削除ワードを { value, label } の形式に変換
//     const formattedWords = template.words.map((word) => ({ value: word, label: word }));
//     setDeleteWords(formattedWords);
//     setSelectedTemplateName(template.name);
//   };

//   // テンプレートを削除
//   const deleteTemplate = (templateToDelete) => {
//     setTemplates(templates.filter((t) => t.name !== templateToDelete.name));
//     if (selectedTemplateName === templateToDelete.name) {
//       setSelectedTemplateName('');
//       setDeleteWords([]);
//     }
//   };

//   // モーダルのスタイル設定
//   const confirmDialogStyles = {
//     content: {
//       top: '50%',
//       left: '50%',
//       right: 'auto',
//       bottom: 'auto',
//       transform: 'translate(-50%, -50%)',
//       width: '50%',
//       maxHeight: '70%',
//       overflow: 'auto',
//     },
//     overlay: {
//       backgroundColor: 'rgba(0, 0, 0, 0.5)',
//     },
//   };

//   const deletionLogModalStyles = {
//     content: {
//       top: '50%',
//       left: '50%',
//       right: 'auto',
//       bottom: 'auto',
//       transform: 'translate(-50%, -50%)',
//       width: '80%',
//       maxHeight: '80%',
//       overflow: 'auto',
//     },
//     overlay: {
//       backgroundColor: 'rgba(0, 0, 0, 0.5)',
//     },
//   };

//   // 以下、元々の関数（カラム一括編集、CSVダウンロード）をそのまま残します

//   // カラム一括編集のハンドラー
//   const handleBulkEdit = () => {
//     if (!selectedColumn || !newValue) {
//       setSnackbar({ open: true, message: '列名と新しい値を入力してください', severity: 'error' });
//       return;
//     }

//     const updatedData = data.map((row) => ({
//       ...row,
//       [selectedColumn]: newValue,
//     }));

//     setData(updatedData);
//     setSnackbar({
//       open: true,
//       message: `列 "${selectedColumn}" が更新されました`,
//       severity: 'success',
//     });
//   };

//   // // CSVダウンロードのハンドラー
//   // const handleCsvDownload = () => {
//   //   if (!data || data.length === 0) {
//   //     setSnackbar({ open: true, message: 'ダウンロードするデータがありません', severity: 'error' });
//   //     return;
//   //   }

//   //   const csv = Papa.unparse(data);
//   //   const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });

//   //   // 現在の日付を取得
//   //   const currentDate = new Date().toISOString().split('T')[0]; // YYYY-MM-DD形式

//   //   // ファイル名を生成
//   //   const fileName = `export_${currentDate}.csv`;

//   //   const link = document.createElement('a');
//   //   link.href = URL.createObjectURL(blob);
//   //   link.setAttribute('download', fileName);
//   //   document.body.appendChild(link);
//   //   link.click();
//   //   document.body.removeChild(link);

//   //   setSnackbar({
//   //     open: true,
//   //     message: `CSVのダウンロードが完了しました。ファイル名: ${fileName}`,
//   //     severity: 'success',
//   //   });
//   // };

//   const handleCsvDownload = () => {
//     if (!data || data.length === 0) {
//       setSnackbar({ open: true, message: 'ダウンロードするデータがありません', severity: 'error' });
//       return;
//     }
  
//     const csv = Papa.unparse(data);
//     const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
  
//     // 現在の日付を取得
//     const currentDate = new Date().toISOString().split('T')[0]; // YYYY-MM-DD形式
  
//     // 最初の商品タイトルを取得し、先頭10文字を抜き出す
//     const firstTitle = data[0]['Title'] || data[0]['title'] || 'Untitled';
//     const truncatedTitle = firstTitle.substring(0, 10);
  
//     // ファイル名に使用できない文字を置換する関数
//     const sanitizeFileName = (name) => name.replace(/[\\/:*?"<>|]/g, '_');
  
//     // サニタイズしたタイトルを取得
//     const safeTitle = sanitizeFileName(truncatedTitle);
  
//     // ファイル名を生成
//     const fileName = `${safeTitle}_${currentDate}.csv`;
  
//     const link = document.createElement('a');
//     link.href = URL.createObjectURL(blob);
//     link.setAttribute('download', fileName);
//     document.body.appendChild(link);
//     link.click();
//     document.body.removeChild(link);
  
//     setSnackbar({
//       open: true,
//       message: `CSVのダウンロードが完了しました。ファイル名: ${fileName}`,
//       severity: 'success',
//     });
//   };
  

//   // レンダリング部分
//   return (
//     <Paper elevation={3} sx={{ p: 2, mt: 0 }}>
//       <Grid container spacing={2}>
//         {/* カラム一括編集 */}
//         <Grid item xs={12} md={4}>
//           <Typography variant="subtitle1">カラム一括編集</Typography>
//           <TextField
//             select
//             label="列名を選択"
//             value={selectedColumn}
//             onChange={(e) => setSelectedColumn(e.target.value)}
//             variant="outlined"
//             margin="normal"
//             fullWidth
//             SelectProps={{
//               native: true,
//             }}
//           >
//             <option value="" disabled>
//               列名を選択してください
//             </option>
//             {Array.isArray(columns) && columns.map((column, index) => (
//               <option key={index} value={column}>
//                 {column}
//               </option>
//             ))}
//           </TextField>
//           <TextField
//             type="text"
//             value={newValue}
//             onChange={(e) => setNewValue(e.target.value)}
//             label="新しい値"
//             variant="outlined"
//             margin="normal"
//             fullWidth
//           />
//           <Button
//             onClick={handleBulkEdit}
//             variant="contained"
//             color="primary"
//             fullWidth
//           >
//             アップデート
//           </Button>
//         </Grid>

//         {/* 削除ワード入力と管理ボタン */}
//         <Grid item xs={12} md={4}>
//           <Typography variant="subtitle1">削除ワードによる行の削除</Typography>
//           <Select
//             isMulti
//             isClearable
//             onChange={handleDeleteWordsChange}
//             options={deleteWords}
//             value={deleteWords}
//             placeholder="削除したいワードを入力"
//             components={{
//               DropdownIndicator: null,
//             }}
//             styles={{
//               multiValue: (styles) => ({
//                 ...styles,
//                 backgroundColor: '#e0e0e0',
//               }),
//               multiValueLabel: (styles) => ({
//                 ...styles,
//                 color: '#333',
//               }),
//               multiValueRemove: (styles) => ({
//                 ...styles,
//                 color: '#666',
//                 ':hover': {
//                   backgroundColor: '#ccc',
//                   color: '#000',
//                 },
//               }),
//             }}
//           />
//           <Button
//             onClick={openManageModal}
//             variant="outlined"
//             color="primary"
//             fullWidth
//             sx={{ mt: 1 }}
//           >
//             ワードを管理
//           </Button>
//           <Button
//             onClick={handleDeleteRowsByWord}
//             variant="contained"
//             color="secondary"
//             fullWidth
//             sx={{ mt: 1 }}
//           >
//             指定ワードを含む行を削除
//           </Button>
//           <Button
//             onClick={() => setIsDeletionLogOpen(true)}
//             variant="outlined"
//             color="secondary"
//             fullWidth
//             sx={{ mt: 1 }}
//           >
//             削除された商品を見る
//           </Button>
//         </Grid>

//         {/* CSVダウンロード */}
//         <Grid item xs={12} md={4}>
//           <Typography variant="subtitle1">CSVダウンロード</Typography>
//           <Button
//             onClick={handleCsvDownload}
//             variant="contained"
//             color="primary"
//             fullWidth
//             sx={{ mt: 4 }}
//           >
//             CSVをダウンロード
//           </Button>
//         </Grid>
//       </Grid>

//       {/* エラーメッセージ表示 */}
//       {error && (
//         <Typography color="error" variant="body1" sx={{ mt: 2 }}>
//           {error}
//         </Typography>
//       )}

//       {/* 確認ダイアログ */}
//       <Modal
//         isOpen={isConfirmOpen}
//         onRequestClose={cancelDeleteRows}
//         contentLabel="削除確認"
//         style={confirmDialogStyles}
//       >
//         <div className="confirm-dialog-content">
//           <p>以下の {rowsToDelete.length} 行を削除します。よろしいですか？</p>
//           <ul>
//             {rowsToDelete.map((rowIndex, idx) => (
//               <li key={idx}>
//                 行 {rowIndex + 1}:{' '}
//                 {titleIndex !== -1 && `Title="${data[rowIndex][columns[titleIndex]]}"`}{' '}
//                 {jpTitleIndex !== -1 && `jp_title="${data[rowIndex][columns[jpTitleIndex]]}"`}
//               </li>
//             ))}
//           </ul>
//           <Button onClick={confirmDeleteRows} variant="contained" color="secondary">
//             削除する
//           </Button>
//           <Button onClick={cancelDeleteRows} variant="outlined" color="primary" sx={{ ml: 2 }}>
//             キャンセル
//           </Button>
//         </div>
//       </Modal>

//       {/* 削除ログモーダル */}
//       <Modal
//         isOpen={isDeletionLogOpen}
//         onRequestClose={() => setIsDeletionLogOpen(false)}
//         contentLabel="削除された商品"
//         style={deletionLogModalStyles}
//       >
//         <div className="deletion-log-content">
//           <h3>削除された商品</h3>
//           <Button
//             onClick={() => setIsDeletionLogOpen(false)}
//             variant="outlined"
//             color="primary"
//             sx={{ mb: 2 }}
//           >
//             閉じる
//           </Button>
//           {deletionLog.length > 0 ? (
//             <table className="deleted-items-table">
//               <thead>
//                 <tr>
//                   <th>行番号</th>
//                   {titleIndex !== -1 && <th>Title</th>}
//                   {jpTitleIndex !== -1 && <th>jp_title</th>}
//                   <th>操作</th>
//                 </tr>
//               </thead>
//               <tbody>
//                 {deletionLog.map((log, index) => (
//                   <tr key={index}>
//                     <td>{log.rowIndex + 1}</td>
//                     {titleIndex !== -1 && <td>{log.rowData[columns[titleIndex]]}</td>}
//                     {jpTitleIndex !== -1 && <td>{log.rowData[columns[jpTitleIndex]]}</td>}
//                     <td>
//                       <Button
//                         onClick={() => handleRestoreRow(log)}
//                         variant="contained"
//                         color="primary"
//                       >
//                         復元
//                       </Button>
//                     </td>
//                   </tr>
//                 ))}
//               </tbody>
//             </table>
//           ) : (
//             <p>削除された商品はありません。</p>
//           )}
//           <p>復元された商品はデータの末尾に追加されます。</p>
//         </div>
//       </Modal>

//       {/* ワード管理モーダル */}
//       <Modal
//         isOpen={isManageModalOpen}
//         onRequestClose={closeManageModal}
//         contentLabel="削除ワード管理"
//         style={{
//           content: {
//             top: '50%',
//             left: '50%',
//             right: 'auto',
//             bottom: 'auto',
//             transform: 'translate(-50%, -50%)',
//             width: '60%',
//             maxHeight: '80%',
//             overflow: 'auto',
//           },
//           overlay: {
//             backgroundColor: 'rgba(0, 0, 0, 0.5)',
//           },
//         }}
//       >
//         <h2>削除ワードを管理</h2>
//         {/* テンプレートの選択 */}
//         <div className="template-selection">
//           <h3>テンプレートを選択</h3>
//           <ul className="template-list">
//             {templates.map((template, index) => (
//               <li key={index}>
//                 <span
//                   onClick={() => applyTemplate(template)}
//                   style={{
//                     cursor: 'pointer',
//                     color: selectedTemplateName === template.name ? 'blue' : 'black',
//                   }}
//                 >
//                   {template.name}
//                 </span>
//                 <button onClick={() => deleteTemplate(template)}>削除</button>
//               </li>
//             ))}
//           </ul>
//           <div className="new-template">
//             <input
//               type="text"
//               placeholder="新しいテンプレート名"
//               value={newTemplateName}
//               onChange={(e) => setNewTemplateName(e.target.value)}
//             />
//             <button onClick={saveTemplate}>テンプレートを保存</button>
//           </div>
//         </div>

//         <div className="manage-words-input">
//           <input
//             type="text"
//             placeholder="新しいワードを入力"
//             onKeyDown={(e) => {
//               if (e.key === 'Enter') {
//                 e.preventDefault();
//                 const newWord = e.target.value.trim();
//                 if (newWord) {
//                   handleManageAddWord(newWord);
//                   e.target.value = '';
//                 }
//               }
//             }}
//           />
//           <button
//             onClick={() => {
//               const input = document.querySelector('.manage-words-input input');
//               const newWord = input.value.trim();
//               if (newWord) {
//                 handleManageAddWord(newWord);
//                 input.value = '';
//               }
//             }}
//           >
//             追加
//           </button>
//         </div>
//         {/* 一括インポート機能 */}
//         <div className="manage-words-import">
//           <textarea
//             placeholder="一括でワードを入力（カンマ、改行で区切る）"
//             rows="4"
//             value={importText}
//             onChange={(e) => setImportText(e.target.value)}
//           />
//           <button onClick={handleImportWords}>一括インポート</button>
//         </div>
//         <ul className="manage-words-list">
//           {deleteWords.map((word, index) => (
//             <li key={index}>
//               {word.label}
//               <button onClick={() => handleManageDeleteWord(word)}>削除</button>
//             </li>
//           ))}
//         </ul>
//         <button onClick={closeManageModal} className="close-modal-button">
//           閉じる
//         </button>
//       </Modal>

//       {/* Snackbarによる通知 */}
//       <Snackbar
//         open={snackbar.open}
//         autoHideDuration={6000}
//         onClose={() => setSnackbar({ ...snackbar, open: false })}
//       >
//         <Alert
//           onClose={() => setSnackbar({ ...snackbar, open: false })}
//           severity={snackbar.severity}
//           sx={{ width: '100%' }}
//         >
//           {snackbar.message}
//         </Alert>
//       </Snackbar>
//     </Paper>
//   );
// }

// BulkColumnEdit.propTypes = {
//   data: PropTypes.array.isRequired,
//   setData: PropTypes.func.isRequired,
//   columns: PropTypes.array.isRequired,
//   setColumns: PropTypes.func.isRequired,
// };

// export default BulkColumnEdit;