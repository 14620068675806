// ItemSpecificsGenerator.js

import axios from 'axios';

const BATCH_SIZE = 5;
const INITIAL_RETRY_DELAY = 1000;
const MAX_RETRIES = 5;

// 項目名の英訳マッピングを定義します（必要に応じて拡張）
const specificationKeyTranslations = {
  'メーカー': 'Manufacturer',
  'ブランド': 'Brand',
  '型番': 'Model Number',
  'サイズ': 'Size',
  'パッケージサイズ': 'Package Size',
  '色': 'Color',
  '素材': 'Material',
  '状態': 'Condition',
  '商品カテゴリー': 'Product Type',
  '作品名': 'Series',
  'キャラクター': 'Character',
  '数量': 'Quantity',
  // 他の項目名も必要に応じて追加してください
};

export const generateItemSpecificsFeatures = async ({
  products,
  setProducts,
  selectedProducts,
  apiKey,
  setSnackbarMessage,
  setSnackbarSeverity,
  setSnackbarOpen,
  setError,
  setMessage,
  setProgress,
  queue,
  selectedTemplate,
  generateFormattedDescription,
  sanitizeDescription,
}) => {
  let processedCount = 0;

  const selectedProductsData = products.filter(
    (product) => selectedProducts.includes(product.id) && !product.isUpdated
  );

  if (selectedProductsData.length === 0) {
    setMessage('選択された商品はすでに更新されています');
    setSnackbarMessage('選択された商品はすでに更新されています');
    setSnackbarSeverity('info');
    setSnackbarOpen(true);
    return;
  }

  for (let i = 0; i < selectedProductsData.length; i += BATCH_SIZE) {
    const batch = selectedProductsData.slice(i, i + BATCH_SIZE);
    try {
      const updatedBatch = await processBatch(batch);
      setProducts((prevProducts) => {
        const newProducts = [...prevProducts];
        updatedBatch.forEach((updatedProduct) => {
          const index = newProducts.findIndex((p) => p.id === updatedProduct.id);
          if (index !== -1) {
            newProducts[index] = updatedProduct;
          }
        });
        return newProducts;
      });
      processedCount += batch.length;
      setProgress((processedCount / selectedProductsData.length) * 100);
    } catch (err) {
      console.error('バッチ処理エラー:', err);
      setError(`バッチ処理に失敗しました: ${err.message}`);
      setSnackbarMessage(`エラー: ${err.message}`);
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
      break;
    }
  }

  setMessage('Item Specifics の生成が完了しました');
  setSnackbarMessage('Item Specifics の生成が完了しました');
  setSnackbarSeverity('success');
  setSnackbarOpen(true);

  // 内部関数：バッチ処理
  async function processBatch(batch) {
    return await Promise.all(
      batch.map(async (product) => {
        let generatedFeatures;

        // "C:~"で始まるカラムのキーを取得し、英語に翻訳
        const specificationKeys = Object.keys(product.specifications).map((key) => {
          const keyWithoutPrefix = key.replace(/^C:/, '');
          return specificationKeyTranslations[keyWithoutPrefix] || keyWithoutPrefix;
        });

        // プロンプト用に商品説明を準備
        let promptDescription = product.editableJpDesc || product.jpDesc;

        // 日本語説明を簡素化する
        promptDescription = await simplifyJapaneseDescriptionWithRetry(promptDescription);

        // 商品説明から情報を抽出するようにAIに指示
        generatedFeatures = await generateFeaturesWithRetry(promptDescription, specificationKeys);

        const sanitizedDescription = sanitizeDescription(product.originalDescription);

        const updatedDescription = generateFormattedDescription(
          product.title,
          generatedFeatures,
          sanitizedDescription,
          product.specifications,
          selectedTemplate
        );

        return {
          ...product,
          generatedFeatures,
          editableGeneratedFeatures: generatedFeatures,
          updatedDescription,
          isUpdated: true,
        };
      })
    );
  }

  // 内部関数：リトライ付きの日本語説明簡素化
  async function simplifyJapaneseDescriptionWithRetry(description, retryCount = 0) {
    try {
      return await queue.add(() => simplifyJapaneseDescription(description));
    } catch (error) {
      if (error.response && error.response.status === 429 && retryCount < MAX_RETRIES) {
        const retryAfter = error.response.headers['retry-after'];
        const delay = retryAfter
          ? parseInt(retryAfter) * 1000
          : INITIAL_RETRY_DELAY * Math.pow(2, retryCount);
        const jitter = Math.random() * 1000;
        await new Promise((resolve) => setTimeout(resolve, delay + jitter));
        return simplifyJapaneseDescriptionWithRetry(description, retryCount + 1);
      }
      throw error;
    }
  }

  // 内部関数：日本語説明を簡素化する
  async function simplifyJapaneseDescription(description, maxTokens = 500) {
    const prompt = `
以下の日本語の商品説明を読み、重要な情報のみを残して簡潔な説明文にしてください。余分な情報や不要な表現、繰り返しを削除し、わかりやすくまとめてください。

商品説明：
${description}

注意事項：
- 出力は日本語でお願いします。
- 重要な情報のみを残し、簡潔にまとめてください。
- 不要な宣伝文句や重複する内容は削除してください。
- 追加情報は不要です。本当に製品情報だけの日本語をまず生成してください。
- 商品が複数ある場合はラベリングしてわかりやすくしてください。
`;

    const response = await axios.post(
      'https://api.openai.com/v1/chat/completions',
      {
        model: 'gpt-4o-mini', // 低コストモデルを使用
        messages: [
          {
            role: 'user',
            content: prompt,
          },
        ],
        max_tokens: maxTokens,
        temperature: 0.1,
      },
      {
        headers: {
          Authorization: `Bearer ${apiKey}`,
          'Content-Type': 'application/json',
        },
      }
    );

    return response.data.choices[0].message.content.trim();
  }

  // 内部関数：リトライ付きの特徴生成
  async function generateFeaturesWithRetry(description, specificationKeys, retryCount = 0) {
    try {
      return await queue.add(() => generateFeatures(description, specificationKeys));
    } catch (error) {
      if (error.response && error.response.status === 429 && retryCount < MAX_RETRIES) {
        const retryAfter = error.response.headers['retry-after'];
        const delay = retryAfter
          ? parseInt(retryAfter) * 1000
          : INITIAL_RETRY_DELAY * Math.pow(2, retryCount);
        const jitter = Math.random() * 1000;
        await new Promise((resolve) => setTimeout(resolve, delay + jitter));
        return generateFeaturesWithRetry(description, specificationKeys, retryCount + 1);
      }
      throw error;
    }
  }

  // 内部関数：特徴生成
  async function generateFeatures(description, specificationKeys, maxTokens = 500) {
    const prompt = `
以下の日本語の商品説明を読み、指定された項目について可能な限り情報を抽出し、それ以外の関連情報も可能な限り抽出してください。それぞれの値を**英語に翻訳**してください。

商品説明：
${description}

指定された項目：
${specificationKeys.join(', ')}

注意事項：
- 存在しない情報を生成しないでください。
- 情報がない場合はその項目は省略してください。
- **抽出した値はすべて英語に翻訳してください。特に固有名詞（作品名やキャラクター名、メーカー名など）も可能な限り公式の英語表記にしてください。**
- 出力は以下の形式でお願いします：
Item Name1: Value1
Item Name2: Value2
...
- **出力は英語のみで行ってください。日本語は使用しないでください。**
`;

    const response = await axios.post(
      'https://api.openai.com/v1/chat/completions',
      {
        model: 'gpt-4o-mini', // 低コストモデルを使用
        messages: [
          {
            role: 'system',
            content: `You are an assistant that extracts specified information from product descriptions and also extracts any other relevant information. Translate all extracted values into English. Read the user's provided product description and extract as much information as possible.

- Do not generate information that does not exist.
- If information is not available, ignore that item.
- **Please translate all extracted values into English. For proper nouns (e.g., product names, character names, manufacturer names), use official English names wherever possible.**
- Output should be in English only, in the format "Item Name: Value".
- **Do not include any Japanese in the output.**
`,
          },
          {
            role: 'user',
            content: prompt,
          },
        ],
        max_tokens: maxTokens,
        temperature: 0.1,
      },
      {
        headers: {
          Authorization: `Bearer ${apiKey}`,
          'Content-Type': 'application/json',
        },
      }
    );

    return response.data.choices[0].message.content.trim();
  }
};





// // ItemSpecificsGenerator.js

// import axios from 'axios';

// const BATCH_SIZE = 5;
// const INITIAL_RETRY_DELAY = 1000;
// const MAX_RETRIES = 5;

// // 項目名の英訳マッピングを定義します（必要に応じて拡張）
// const specificationKeyTranslations = {
//   'メーカー': 'Manufacturer',
//   'ブランド': 'Brand',
//   '型番': 'Model Number',
//   'サイズ': 'Size',
//   'パッケージサイズ': 'Package Size',
//   '色': 'Color',
//   '素材': 'Material',
//   '状態': 'Condition',
//   '商品カテゴリー': 'Product Type',
//   '作品名': 'Series',
//   'キャラクター': 'Character',
//   '数量': 'Quantity',
//   // 他の項目名も必要に応じて追加してください
// };

// export const generateItemSpecificsFeatures = async ({
//   products,
//   setProducts,
//   selectedProducts,
//   apiKey,
//   setSnackbarMessage,
//   setSnackbarSeverity,
//   setSnackbarOpen,
//   setError,
//   setMessage,
//   setProgress,
//   queue,
//   selectedTemplate,
//   generateFormattedDescription,
//   sanitizeDescription,
// }) => {
//   let processedCount = 0;

//   const selectedProductsData = products.filter(
//     (product) => selectedProducts.includes(product.id) && !product.isUpdated
//   );

//   if (selectedProductsData.length === 0) {
//     setMessage('選択された商品はすでに更新されています');
//     setSnackbarMessage('選択された商品はすでに更新されています');
//     setSnackbarSeverity('info');
//     setSnackbarOpen(true);
//     return;
//   }

//   for (let i = 0; i < selectedProductsData.length; i += BATCH_SIZE) {
//     const batch = selectedProductsData.slice(i, i + BATCH_SIZE);
//     try {
//       const updatedBatch = await processBatch(batch);
//       setProducts((prevProducts) => {
//         const newProducts = [...prevProducts];
//         updatedBatch.forEach((updatedProduct) => {
//           const index = newProducts.findIndex((p) => p.id === updatedProduct.id);
//           if (index !== -1) {
//             newProducts[index] = updatedProduct;
//           }
//         });
//         return newProducts;
//       });
//       processedCount += batch.length;
//       setProgress((processedCount / selectedProductsData.length) * 100);
//     } catch (err) {
//       console.error('バッチ処理エラー:', err);
//       setError(`バッチ処理に失敗しました: ${err.message}`);
//       setSnackbarMessage(`エラー: ${err.message}`);
//       setSnackbarSeverity('error');
//       setSnackbarOpen(true);
//       break;
//     }
//   }

//   setMessage('Item Specifics の生成が完了しました');
//   setSnackbarMessage('Item Specifics の生成が完了しました');
//   setSnackbarSeverity('success');
//   setSnackbarOpen(true);

//   // 内部関数：バッチ処理
//   async function processBatch(batch) {
//     return await Promise.all(
//       batch.map(async (product) => {
//         let generatedFeatures;

//         // "C:~"で始まるカラムのキーを取得し、英語に翻訳
//         const specificationKeys = Object.keys(product.specifications).map((key) => {
//           const keyWithoutPrefix = key.replace(/^C:/, '');
//           return specificationKeyTranslations[keyWithoutPrefix] || keyWithoutPrefix;
//         });

//         // プロンプト用に商品説明を準備
//         const promptDescription = product.editableJpDesc || product.jpDesc;

//         // 商品説明から情報を抽出するようにAIに指示
//         generatedFeatures = await generateFeaturesWithRetry(promptDescription, specificationKeys);

//         const sanitizedDescription = sanitizeDescription(product.originalDescription);

//         const updatedDescription = generateFormattedDescription(
//           product.title,
//           generatedFeatures,
//           sanitizedDescription,
//           product.specifications,
//           selectedTemplate
//         );

//         return {
//           ...product,
//           generatedFeatures,
//           editableGeneratedFeatures: generatedFeatures,
//           updatedDescription,
//           isUpdated: true,
//         };
//       })
//     );
//   }

//   // 内部関数：リトライ付きの特徴生成
//   async function generateFeaturesWithRetry(description, specificationKeys, retryCount = 0) {
//     try {
//       return await queue.add(() => generateFeatures(description, specificationKeys));
//     } catch (error) {
//       if (error.response && error.response.status === 429 && retryCount < MAX_RETRIES) {
//         const retryAfter = error.response.headers['retry-after'];
//         const delay = retryAfter
//           ? parseInt(retryAfter) * 1000
//           : INITIAL_RETRY_DELAY * Math.pow(2, retryCount);
//         const jitter = Math.random() * 1000;
//         await new Promise((resolve) => setTimeout(resolve, delay + jitter));
//         return generateFeaturesWithRetry(description, specificationKeys, retryCount + 1);
//       }
//       throw error;
//     }
//   }

//   // 内部関数：特徴生成
//   async function generateFeatures(description, specificationKeys, maxTokens = 500) {
//     const prompt = `
// 以下の日本語の商品説明を読み、指定された項目について可能な限り情報を抽出し、それ以外の関連情報も可能な限り抽出してください。それぞれの値を**英語に翻訳**してください。

// 商品説明：
// ${description}

// 指定された項目：
// ${specificationKeys.join(', ')}

// 注意事項：
// - 存在しない情報を生成しないでください。
// - 情報がない場合はその項目は省略してください。
// - **抽出した値はすべて英語に翻訳してください。特に固有名詞（作品名やキャラクター名、メーカー名など）も可能な限り公式の英語表記にしてください。**
// - 出力は以下の形式でお願いします：
// Item Name1: Value1
// Item Name2: Value2
// ...
// - **出力は英語のみで行ってください。日本語は使用しないでください。**
// `;

//     const response = await axios.post(
//       'https://api.openai.com/v1/chat/completions',
//       {
//         model: 'gpt-4o-2024-08-06',
//         messages: [
//           {
//             role: 'system',
//             content: `You are an assistant that extracts specified information from product descriptions and also extracts any other relevant information. Translate all extracted values into English. Read the user's provided product description and extract as much information as possible.

// - Do not generate information that does not exist.
// - If information is not available, ignore that item.
// - **Please translate all extracted values into English. For proper nouns (e.g., product names, character names, manufacturer names), use official English names wherever possible.**
// - Output should be in English only, in the format "Item Name: Value".
// - **Do not include any Japanese in the output.**
// `,
//           },
//           {
//             role: 'user',
//             content: prompt,
//           },
//         ],
//         max_tokens: maxTokens,
//         temperature: 0.1,
//       },
//       {
//         headers: {
//           Authorization: `Bearer ${apiKey}`,
//           'Content-Type': 'application/json',
//         },
//       }
//     );

//     return response.data.choices[0].message.content.trim();
//   }
// };





// // ItemSpecificsGenerator.js

// import axios from 'axios';

// const BATCH_SIZE = 5;
// const INITIAL_RETRY_DELAY = 1000;
// const MAX_RETRIES = 5;

// // 項目名の英訳マッピングを定義します（必要に応じて拡張）
// const specificationKeyTranslations = {
//   'メーカー': 'Manufacturer',
//   'ブランド': 'Brand',
//   '型番': 'Model Number',
//   'サイズ': 'Size',
//   'パッケージサイズ': 'Package Size',
//   '色': 'Color',
//   '素材': 'Material',
//   '状態': 'Condition',
//   '商品カテゴリー': 'Product Type',
//   '作品名': 'Series',
//   'キャラクター': 'Character',
//   '数量': 'Quantity',
//   // 他の項目名も必要に応じて追加してください
// };

// export const generateItemSpecificsFeatures = async ({
//   products,
//   setProducts,
//   selectedProducts,
//   apiKey,
//   setSnackbarMessage,
//   setSnackbarSeverity,
//   setSnackbarOpen,
//   setError,
//   setMessage,
//   setProgress,
//   queue,
//   selectedTemplate,
//   generateFormattedDescription,
//   sanitizeDescription,
// }) => {
//   let processedCount = 0;

//   const selectedProductsData = products.filter(
//     (product) => selectedProducts.includes(product.id) && !product.isUpdated
//   );

//   if (selectedProductsData.length === 0) {
//     setMessage('選択された商品はすでに更新されています');
//     setSnackbarMessage('選択された商品はすでに更新されています');
//     setSnackbarSeverity('info');
//     setSnackbarOpen(true);
//     return;
//   }

//   for (let i = 0; i < selectedProductsData.length; i += BATCH_SIZE) {
//     const batch = selectedProductsData.slice(i, i + BATCH_SIZE);
//     try {
//       const updatedBatch = await processBatch(batch);
//       setProducts((prevProducts) => {
//         const newProducts = [...prevProducts];
//         updatedBatch.forEach((updatedProduct) => {
//           const index = newProducts.findIndex((p) => p.id === updatedProduct.id);
//           if (index !== -1) {
//             newProducts[index] = updatedProduct;
//           }
//         });
//         return newProducts;
//       });
//       processedCount += batch.length;
//       setProgress((processedCount / selectedProductsData.length) * 100);
//     } catch (err) {
//       console.error('バッチ処理エラー:', err);
//       setError(`バッチ処理に失敗しました: ${err.message}`);
//       setSnackbarMessage(`エラー: ${err.message}`);
//       setSnackbarSeverity('error');
//       setSnackbarOpen(true);
//       break;
//     }
//   }

//   setMessage('Item Specifics の生成が完了しました');
//   setSnackbarMessage('Item Specifics の生成が完了しました');
//   setSnackbarSeverity('success');
//   setSnackbarOpen(true);

//   // 内部関数：バッチ処理
//   async function processBatch(batch) {
//     return await Promise.all(
//       batch.map(async (product) => {
//         let generatedFeatures;

//         // プロンプト用に商品説明を準備
//         const promptDescription = product.editableJpDesc || product.jpDesc;

//         // 商品説明から情報を抽出するようにAIに指示
//         generatedFeatures = await generateFeaturesWithRetry(promptDescription);

//         const sanitizedDescription = sanitizeDescription(product.originalDescription);

//         const updatedDescription = generateFormattedDescription(
//           product.title,
//           generatedFeatures,
//           sanitizedDescription,
//           product.specifications,
//           selectedTemplate
//         );

//         return {
//           ...product,
//           generatedFeatures,
//           editableGeneratedFeatures: generatedFeatures,
//           updatedDescription,
//           isUpdated: true,
//         };
//       })
//     );
//   }

//   // 内部関数：リトライ付きの特徴生成
//   async function generateFeaturesWithRetry(description, retryCount = 0) {
//     try {
//       return await queue.add(() => generateFeatures(description));
//     } catch (error) {
//       if (error.response && error.response.status === 429 && retryCount < MAX_RETRIES) {
//         const retryAfter = error.response.headers['retry-after'];
//         const delay = retryAfter
//           ? parseInt(retryAfter) * 1000
//           : INITIAL_RETRY_DELAY * Math.pow(2, retryCount);
//         const jitter = Math.random() * 1000;
//         await new Promise((resolve) => setTimeout(resolve, delay + jitter));
//         return generateFeaturesWithRetry(description, retryCount + 1);
//       }
//       throw error;
//     }
//   }

//   // 内部関数：特徴生成
//   async function generateFeatures(description, maxTokens = 500) {
//     const prompt = `
// 以下の日本語の商品説明を読み、可能な限り多くの関連情報を抽出し、それぞれの値を**英語に翻訳**してください。

// 商品説明：
// ${description}

// 注意事項：
// - 存在しない情報を生成しないでください。
// - **抽出した値はすべて英語に翻訳してください。特に固有名詞（作品名やキャラクター名、メーカー名など）も可能な限り公式の英語表記にしてください。**
// - 出力は以下の形式でお願いします：
// Item Name1: Value1
// Item Name2: Value2
// ...
// - **出力は英語のみで行ってください。日本語は使用しないでください。**
// `;

//     const response = await axios.post(
//       'https://api.openai.com/v1/chat/completions',
//       {
//         model: 'gpt-4o-2024-08-06',
//         messages: [
//           {
//             role: 'system',
//             content: `You are an assistant that extracts as much relevant information as possible from product descriptions and translates the extracted values into English. Read the user's provided product description and extract as much information as possible.

// - Do not generate information that does not exist.
// - **Please translate all extracted values into English. For proper nouns (e.g., product names, character names, manufacturer names), use official English names wherever possible.**
// - Output should be in English only, in the format "Item Name: Value".
// - **Do not include any Japanese in the output.**
// `,
//           },
//           {
//             role: 'user',
//             content: prompt,
//           },
//         ],
//         max_tokens: maxTokens,
//         temperature: 0.1,
//       },
//       {
//         headers: {
//           Authorization: `Bearer ${apiKey}`,
//           'Content-Type': 'application/json',
//         },
//       }
//     );

//     return response.data.choices[0].message.content.trim();
//   }
// };
