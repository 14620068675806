// BulkListingFromScratch.js

import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  Typography,
  Paper,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  LinearProgress,
  Snackbar,
  Alert,
  IconButton,
  AppBar,
  Toolbar,
  Grid,
  CssBaseline,
  Divider,
  List,
  ListItem,
  ListItemText,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import MenuIcon from '@mui/icons-material/Menu';
import HomeIcon from '@mui/icons-material/Home';
import { styled } from '@mui/material/styles';
import Drawer from '@mui/material/Drawer';
import EditableSpreadsheet from './EditableSpreadsheet';
import { doc, getDoc, updateDoc, setDoc, arrayUnion } from 'firebase/firestore';
import { db } from './firebaseConfig';
import { getAuth } from 'firebase/auth';
import CryptoJS from 'crypto-js';
import ColumnNameBasedTemplateManager from './ColumnNameBasedTemplateManager';
import APIKeyInput from './APIKeyInput';
import TitleGenerationComponent from './TitleGenerationComponent';
import OpenAIDescriptionGenerator from './OpenAIDescriptionGenerator';
import ItemSpecificsManager from './ItemSpecificsManager';
import UserTemplateManager from './UserTemplateManager';
import { categoryTemplates } from './CategoryTemplates';
import PriceManager from './PriceManager';
import ImageOperations from './ImageOperations';
import ImageUploader from './ImageUploader'; // 新しいコンポーネントをインポート
import { useNavigate } from 'react-router-dom';
import { parse } from 'papaparse'; // CSVパース用のライブラリ

const drawerWidth = 250;

// スタイリングされたMainコンポーネントを定義
const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    maxWidth: '100%',
    overflowX: 'auto',
    transition: theme.transitions.create(['margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${drawerWidth}px`,
    ...(open && {
      transition: theme.transitions.create(['margin'], {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    }),
  }),
);

function BulkListingFromScratch() {
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);

  const [activeSection, setActiveSection] = useState('main');
  const [selectedTemplate, setSelectedTemplate] = useState('');
  const [columns, setColumns] = useState([]);
  const [data, setData] = useState([]);

  // デフォルト値を設定したいカラムとその値を定義
  const defaultValues = {
    'Action(CC=Cp1252)': 'Add',
    'ConditionID': '3000',
    'C:Brand': 'No Brand',
    'PayPalAccepted': '1',
    'PayPalEmailAddress': 'payAddress',
    'Country': 'JP',
    'Apply Profile Domestic': '0.0',
    'Apply Profile International': '0.0',
    'BuyerRequirements:LinkedPayPalAccount': '0.0',
    'Duration': 'GTC',
    'Format': 'FixedPriceItem',
    'Quantity': '1',
    'Currency': 'USD',
    'SiteID': 'US',
    'C:Country': 'Japan',
    'BestOfferEnabled': '0',
    // 必要に応じて他のデフォルト値を追加
  };

  // ImageOperations用のステートと関数
  const [startRow, setStartRow] = useState(1);
  const [endRow, setEndRow] = useState(1);
  const [isProcessing, setIsProcessing] = useState(false);
  const [error, setError] = useState('');
  const [openPreviewDialog, setOpenPreviewDialog] = useState(false);
  const [previewDialogMode, setPreviewDialogMode] = useState('edit');
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: 'info',
  });

  // OpenAIのAPIキーを管理するステート
  const [apiKey, setApiKey] = useState(null); // 初期値をnullに変更

  const [user, setUser] = useState(null);

  const navigate = useNavigate();

  // CSVファイルアップロード用のステート
  const [isCSVUploadDialogOpen, setIsCSVUploadDialogOpen] = useState(false);
  const [csvFile, setCsvFile] = useState(null);

  // ユーザーテンプレート管理用のステート
  const [userTemplates, setUserTemplates] = useState([]);
  // カテゴリーテンプレート管理用のステート
  const [userCategoryTemplates, setUserCategoryTemplates] = useState([]);

  // ユーザー情報を取得
  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = auth.onAuthStateChanged((firebaseUser) => {
      setUser(firebaseUser);
    });
    return () => unsubscribe();
  }, []);

  // ユーザー固有の秘密鍵を生成
  const generateEncryptionKey = (uid) => {
    const salt = 'your-fixed-salt-value';
    return CryptoJS.PBKDF2(uid, salt, { keySize: 256 / 32 }).toString();
  };

  // Snackbarを閉じる
  const handleSnackbarClose = () => {
    setSnackbar({
      ...snackbar,
      open: false,
    });
  };

  // エラーメッセージを表示
  const showSnackbar = (message, severity = 'info') => {
    setSnackbar({
      open: true,
      message,
      severity,
    });
  };

  // FirebaseからAPIキーを取得
  useEffect(() => {
    const fetchApiKey = async () => {
      if (user) {
        try {
          const docRef = doc(db, 'userApiKeys', user.uid);
          const docSnap = await getDoc(docRef);
          if (docSnap.exists()) {
            const data = docSnap.data();
            // APIキーを復号化
            const encryptionKey = generateEncryptionKey(user.uid);
            const decryptedBytes = CryptoJS.AES.decrypt(data.apiKey, encryptionKey);
            const decryptedKey = decryptedBytes.toString(CryptoJS.enc.Utf8);

            if (decryptedKey) {
              setApiKey(decryptedKey);
            } else {
              console.error('APIキーの復号化に失敗しました。');
              setApiKey(null);
              showSnackbar('APIキーの復号化に失敗しました。再度設定してください。', 'error');
            }
          } else {
            console.error('APIキーが設定されていません。');
            setApiKey(null);
            showSnackbar('APIキーが設定されていません。APIキーを入力してください。', 'warning');
          }
        } catch (error) {
          console.error('APIキーの取得に失敗しました:', error);
          setApiKey(null);
          showSnackbar('APIキーの取得に失敗しました。', 'error');
        }
      }
    };

    fetchApiKey();
  }, [user]);

  // ユーザーテンプレートを取得
  useEffect(() => {
    const fetchUserTemplates = async () => {
      if (user) {
        try {
          const userTemplatesRef = doc(db, 'userTemplates', user.uid);
          const userTemplatesSnap = await getDoc(userTemplatesRef);
          if (userTemplatesSnap.exists()) {
            const data = userTemplatesSnap.data();
            setUserTemplates(data.templates || []);
          }
        } catch (error) {
          console.error('テンプレートの取得に失敗しました:', error);
        }
      }
    };

    fetchUserTemplates();
  }, [user]);

  // ユーザーカテゴリーテンプレートを取得
  useEffect(() => {
    const fetchUserCategoryTemplates = async () => {
      if (user) {
        try {
          const userCategoryTemplatesRef = doc(db, 'userCategoryTemplates', user.uid);
          const userCategoryTemplatesSnap = await getDoc(userCategoryTemplatesRef);
          if (userCategoryTemplatesSnap.exists()) {
            const data = userCategoryTemplatesSnap.data();
            setUserCategoryTemplates(data.templates || []);
          }
        } catch (error) {
          console.error('カテゴリーテンプレートの取得に失敗しました:', error);
        }
      }
    };

    fetchUserCategoryTemplates();
  }, [user]);

  const handleTemplateChange = (event) => {
    const templateName = event.target.value;
    setSelectedTemplate(templateName);

    if (templateName.startsWith('user-category-')) {
      const userTemplateName = templateName.replace('user-category-', '');
      const selectedUserCategoryTemplate = userCategoryTemplates.find(
        (template) => template.name === userTemplateName
      );
      setColumns(selectedUserCategoryTemplate.columns || []);
    } else {
      const selectedColumns = categoryTemplates[templateName] || [];
      setColumns(selectedColumns);
    }

    // 初期データをクリア
    setData([]);
    // startRowとendRowをリセット
    setStartRow(1);
    setEndRow(1);
  };

  // サイドバーのセクション切り替え
  const handleSectionChange = (section) => {
    setActiveSection(section);
  };

  // ホーム画面に戻る処理
  const handleGoHome = () => {
    navigate('/'); // ホーム画面へのパスに変更してください
  };

  // CSVアップロードダイアログを開く
  const openCSVUploadDialog = () => {
    setIsCSVUploadDialogOpen(true);
  };

  // CSVファイルが選択されたときの処理
  const handleCSVFileChange = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      setCsvFile(e.target.files[0]);
    }
  };

  // CSVファイルをアップロードしてカテゴリーテンプレートを作成
  const handleCSVUpload = () => {
    if (!csvFile) {
      alert('CSVファイルを選択してください。');
      return;
    }

    parse(csvFile, {
      header: true,
      skipEmptyLines: true,
      complete: async (results) => {
        const columnsFromCSV = results.meta.fields;
        // テンプレート名をユーザーに入力してもらう
        const templateName = prompt('テンプレート名を入力してください:');
        if (templateName && columnsFromCSV) {
          // ユーザーのカテゴリーテンプレートとして保存
          await saveUserCategoryTemplate(templateName, columnsFromCSV);
          setIsCSVUploadDialogOpen(false);
          setCsvFile(null);
          showSnackbar('テンプレートが保存されました。', 'success');
        } else {
          alert('テンプレート名が無効です。');
        }
      },
      error: (error) => {
        console.error('CSVのパースに失敗しました:', error);
        alert('CSVファイルの読み込みに失敗しました。');
      },
    });
  };

  // ユーザーカテゴリーテンプレートを保存
  const saveUserCategoryTemplate = async (templateName, columns) => {
    if (!user) {
      alert('ユーザーが認証されていません。再度ログインしてください。');
      return;
    }

    try {
      const userCategoryTemplatesRef = doc(db, 'userCategoryTemplates', user.uid);
      const userCategoryTemplatesSnap = await getDoc(userCategoryTemplatesRef);

      const newTemplate = { name: templateName, columns };

      if (userCategoryTemplatesSnap.exists()) {
        const data = userCategoryTemplatesSnap.data();
        const existingTemplates = data.templates || [];
        // 同名のテンプレートが存在するか確認
        const duplicate = existingTemplates.find((t) => t.name === templateName);
        if (duplicate) {
          alert('同じ名前のテンプレートが既に存在します。別の名前を使用してください。');
          return;
        }
        // 既存のテンプレートに追加
        await updateDoc(userCategoryTemplatesRef, {
          templates: arrayUnion(newTemplate),
        });
        setUserCategoryTemplates([...existingTemplates, newTemplate]);
      } else {
        // 新しいドキュメントを作成
        await setDoc(userCategoryTemplatesRef, {
          templates: [newTemplate],
        });
        setUserCategoryTemplates([newTemplate]);
      }
    } catch (error) {
      console.error('カテゴリーテンプレートの保存に失敗しました:', error);
      alert('カテゴリーテンプレートの保存に失敗しました。');
    }
  };

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      {/* AppBar */}
      <AppBar
        position="fixed"
        sx={{
          zIndex: (theme) => theme.zIndex.drawer + 1,
          transition: (theme) =>
            theme.transitions.create(['width', 'margin'], {
              easing: theme.transitions.easing.sharp,
              duration: theme.transitions.duration.leavingScreen,
            }),
          ...(isSidebarOpen && {
            marginLeft: drawerWidth,
            width: `calc(100% - ${drawerWidth}px)`,
          }),
        }}
      >
        <Toolbar>
          {/* ホーム画面へのリンクを追加 */}
          <IconButton
            color="inherit"
            aria-label="go to home"
            onClick={handleGoHome}
            edge="start"
            sx={{
              marginRight: 2,
            }}
          >
            <HomeIcon />
          </IconButton>

          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={() => setIsSidebarOpen(!isSidebarOpen)}
            edge="start"
            sx={{
              marginRight: 2,
            }}
          >
            {isSidebarOpen ? <CloseIcon /> : <MenuIcon />}
          </IconButton>
          <Typography variant="h6" noWrap component="div">
            0から一括出品
          </Typography>
        </Toolbar>
      </AppBar>

      {/* サイドバー */}
      <Drawer
        variant="persistent"
        anchor="left"
        open={isSidebarOpen}
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            boxSizing: 'border-box',
          },
        }}
      >
        <Toolbar />
        <Box sx={{ overflow: 'auto' }}>
          <List>
            <ListItem button onClick={() => handleSectionChange('main')}>
              <ListItemText primary="メイン" />
            </ListItem>
            <ListItem button onClick={() => handleSectionChange('priceManager')}>
              <ListItemText primary="価格管理" />
            </ListItem>
            <ListItem button onClick={() => handleSectionChange('itemSpecifics')}>
              <ListItemText primary="Item Specifics管理" />
            </ListItem>
            <ListItem button onClick={() => handleSectionChange('templateManager')}>
              <ListItemText primary="テンプレート管理" />
            </ListItem>
            <ListItem button onClick={() => handleSectionChange('titleGeneration')}>
              <ListItemText primary="AIタイトル生成" />
            </ListItem>
            <ListItem button onClick={() => handleSectionChange('descriptionGeneration')}>
              <ListItemText primary="AI商品説明生成" />
            </ListItem>
            <ListItem button onClick={() => handleSectionChange('apiKeyInput')}>
              <ListItemText primary="APIキー入力" />
            </ListItem>
            <ListItem button onClick={() => handleSectionChange('userTemplateManager')}>
              <ListItemText primary="ユーザーテンプレート管理" />
            </ListItem>
          </List>
        </Box>
      </Drawer>

      {/* メインコンテンツ */}
      <Main open={isSidebarOpen}>
        <Toolbar />
        {/* APIキーが未設定の場合に警告を表示 */}
        {!apiKey && (
          <Alert severity="warning" sx={{ mb: 2 }}>
            OpenAI APIキーが設定されていません。左のサイドバーから「APIキー入力」を選択し、APIキーを入力してください。
          </Alert>
        )}
        {activeSection === 'main' && (
          <Box>
            <FormControl fullWidth sx={{ mb: 2 }}>
              <InputLabel id="template-select-label">カテゴリーテンプレートを選択</InputLabel>
              <Select
                labelId="template-select-label"
                value={selectedTemplate}
                label="カテゴリーテンプレートを選択"
                onChange={handleTemplateChange}
              >
                <MenuItem value="">
                  <em>テンプレートを選択してください</em>
                </MenuItem>
                {/* 既存のテンプレート */}
                {Object.keys(categoryTemplates).map((templateName) => (
                  <MenuItem key={templateName} value={templateName}>
                    {templateName}
                  </MenuItem>
                ))}
                {/* ユーザーのカテゴリーテンプレート */}
                {userCategoryTemplates.map((template) => (
                  <MenuItem key={template.name} value={`user-category-${template.name}`}>
                    {template.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <Grid container spacing={2} sx={{ mb: 2 }}>
              <Grid item xs={12} sm={6} md={4}>
                <Button variant="contained" color="primary" onClick={openCSVUploadDialog}>
                  CSVファイルからテンプレートを作成
                </Button>
              </Grid>
            </Grid>

            {/* CSVアップロードダイアログ */}
            <Dialog open={isCSVUploadDialogOpen} onClose={() => setIsCSVUploadDialogOpen(false)}>
              <DialogTitle>CSVファイルをアップロード</DialogTitle>
              <DialogContent>
                <input type="file" accept=".csv" onChange={handleCSVFileChange} />
              </DialogContent>
              <DialogActions>
                <Button onClick={() => setIsCSVUploadDialogOpen(false)}>キャンセル</Button>
                <Button onClick={handleCSVUpload} disabled={!csvFile}>
                  アップロード
                </Button>
              </DialogActions>
            </Dialog>

            {selectedTemplate && (
              <>
                {/* ImageUploaderコンポーネントを追加 */}
                <ImageUploader
                  user={user}
                  showSnackbar={showSnackbar}
                  defaultValues={defaultValues}
                  columns={columns}
                  setColumns={setColumns}
                  data={data}
                  setData={setData}
                  startRow={startRow}
                  setStartRow={setStartRow}
                  endRow={endRow}
                  setEndRow={setEndRow}
                />

                {/* ImageOperationsコンポーネントを追加 */}
                <Box sx={{ mt: 4 }}>
                  <ImageOperations
                    data={data}
                    setData={setData}
                    startRow={startRow}
                    endRow={endRow}
                    calculateMaxEndRow={() => data.length}
                    isProcessing={isProcessing}
                    setIsProcessing={setIsProcessing}
                    setError={setError}
                    showSnackbar={showSnackbar}
                    openPreviewDialog={openPreviewDialog}
                    setOpenPreviewDialog={setOpenPreviewDialog}
                    apiKey={apiKey} // OpenAIのAPIキー
                    previewDialogMode={previewDialogMode}
                    setPreviewDialogMode={setPreviewDialogMode}
                  />
                </Box>
              </>
            )}

            {data.length > 0 && (
              <>
                {/* EditableSpreadsheetを常に表示 */}
                <Paper elevation={3} sx={{ p: 2, mt: 4 }}>
                  <EditableSpreadsheet
                    data={data}
                    setData={setData}
                    columns={columns}
                    setColumns={setColumns}
                  />
                </Paper>

                {/* Snackbar */}
                <Snackbar
                  open={snackbar.open}
                  autoHideDuration={6000}
                  onClose={handleSnackbarClose}
                >
                  <Alert
                    onClose={handleSnackbarClose}
                    severity={snackbar.severity}
                    sx={{ width: '100%' }}
                  >
                    {snackbar.message}
                  </Alert>
                </Snackbar>
              </>
            )}
          </Box>
        )}

        {activeSection === 'priceManager' && (
          <Box>
            <PriceManager data={data} setData={setData} />
          </Box>
        )}

        {activeSection === 'itemSpecifics' && (
          <Box>
            <ItemSpecificsManager
              userId={user ? user.uid : null}
              headers={columns}
              data={data}
              setData={setData}
            />
          </Box>
        )}

        {activeSection === 'templateManager' && (
          <Box>
            <ColumnNameBasedTemplateManager
              data={data}
              setData={setData}
              columns={columns}
              setColumns={setColumns}
            />
          </Box>
        )}

        {activeSection === 'titleGeneration' && (
          <Box>
            <TitleGenerationComponent data={data} setData={setData} apiKey={apiKey} user={user} />
          </Box>
        )}

        {activeSection === 'descriptionGeneration' && (
          <Box>
            <OpenAIDescriptionGenerator data={data} setData={setData} apiKey={apiKey} user={user} />
          </Box>
        )}

        {activeSection === 'apiKeyInput' && (
          <Box>
            <APIKeyInput user={user} apiKey={apiKey} setApiKey={setApiKey} />
          </Box>
        )}

        {activeSection === 'userTemplateManager' && (
          <Box>
            <UserTemplateManager
              userTemplates={userTemplates}
              setUserTemplates={setUserTemplates}
              user={user}
            />
          </Box>
        )}
      </Main>
    </Box>
  );
}

export default BulkListingFromScratch;







// // BulkListingFromScratch.js

// import React, { useState, useEffect } from 'react';
// import {
//   Box,
//   Button,
//   Typography,
//   Paper,
//   FormControl,
//   InputLabel,
//   Select,
//   MenuItem,
//   Dialog,
//   DialogTitle,
//   DialogContent,
//   DialogActions,
//   LinearProgress,
//   Snackbar,
//   Alert,
//   IconButton,
//   AppBar,
//   Toolbar,
//   Grid,
//   CssBaseline,
//   Divider,
//   List,
//   ListItem,
//   ListItemText,
//   TextField,
// } from '@mui/material';
// import CloseIcon from '@mui/icons-material/Close';
// import MenuIcon from '@mui/icons-material/Menu';
// import HomeIcon from '@mui/icons-material/Home';
// import { styled } from '@mui/material/styles';
// import Drawer from '@mui/material/Drawer';
// import EditableSpreadsheet from './EditableSpreadsheet';
// import { getStorage, ref as storageRef, uploadBytes, getDownloadURL } from 'firebase/storage';
// import { v4 as uuidv4 } from 'uuid';
// import DeleteIcon from '@mui/icons-material/Delete';
// import AddIcon from '@mui/icons-material/Add';
// import { DragDropContext, Droppable, Draggable } from '@hello-pangea/dnd';
// import { db } from './firebaseConfig';
// import { doc, getDoc, setDoc, updateDoc, arrayUnion } from 'firebase/firestore';
// import CryptoJS from 'crypto-js';
// import ColumnNameBasedTemplateManager from './ColumnNameBasedTemplateManager';
// import APIKeyInput from './APIKeyInput';
// import TitleGenerationComponent from './TitleGenerationComponent';
// import OpenAIDescriptionGenerator from './OpenAIDescriptionGenerator';
// import ItemSpecificsManager from './ItemSpecificsManager';
// import UserTemplateManager from './UserTemplateManager';
// import { getAuth } from 'firebase/auth';
// import { categoryTemplates } from './CategoryTemplates';
// import PriceManager from './PriceManager';
// import ImageOperations from './ImageOperations';
// import { useNavigate, Link as RouterLink } from 'react-router-dom';
// import { parse } from 'papaparse'; // CSVパース用のライブラリ

// const drawerWidth = 250;

// // スタイリングされたMainコンポーネントを定義
// const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
//   ({ theme, open }) => ({
//     flexGrow: 1,
//     padding: theme.spacing(3),
//     maxWidth: '100%',
//     overflowX: 'auto',
//     transition: theme.transitions.create(['margin'], {
//       easing: theme.transitions.easing.sharp,
//       duration: theme.transitions.duration.leavingScreen,
//     }),
//     marginLeft: `-${drawerWidth}px`,
//     ...(open && {
//       transition: theme.transitions.create(['margin'], {
//         easing: theme.transitions.easing.easeOut,
//         duration: theme.transitions.duration.enteringScreen,
//       }),
//       marginLeft: 0,
//     }),
//   }),
// );

// function BulkListingFromScratch() {
//   const [isSidebarOpen, setIsSidebarOpen] = useState(true);

//   const [activeSection, setActiveSection] = useState('main');
//   const [selectedTemplate, setSelectedTemplate] = useState('');
//   const [columns, setColumns] = useState([]);
//   const [data, setData] = useState([]);

//   // デフォルト値を設定したいカラムとその値を定義
//   const defaultValues = {
//     'Action(CC=Cp1252)': 'Add',
//     'ConditionID': '3000',
//     'C:Brand': 'No Brand',
//     'PayPalAccepted': '1',
//     'Country': 'JP',
//     'Apply Profile Domestic': '0.0',
//     'Apply Profile International': '0.0',
//     'BuyerRequirements:LinkedPayPalAccount': '0.0',
//     'Duration': 'GTC',
//     'Format': 'FixedPriceItem',
//     'Quantity': '1',
//     'Currency': 'USD',
//     'SiteID': 'US',
//     'C:Country': 'Japan',
//     'BestOfferEnabled': '0',
//     // 必要に応じて他のデフォルト値を追加
//   };

//   // 画像関連のステート
//   const [uploadedImages, setUploadedImages] = useState([]);
//   const [isUploading, setIsUploading] = useState(false);
//   const [groupedImages, setGroupedImages] = useState([]);
//   const [isGrouping, setIsGrouping] = useState(false);

//   const [isImageUploadDialogOpen, setIsImageUploadDialogOpen] = useState(false);
//   const [imageFiles, setImageFiles] = useState([]);

//   // ImageOperations用のステートと関数
//   const [startRow, setStartRow] = useState(1);
//   const [endRow, setEndRow] = useState(1);
//   const [isProcessing, setIsProcessing] = useState(false);
//   const [error, setError] = useState('');
//   const [openPreviewDialog, setOpenPreviewDialog] = useState(false);
//   const [previewDialogMode, setPreviewDialogMode] = useState('edit');
//   const [snackbar, setSnackbar] = useState({
//     open: false,
//     message: '',
//     severity: 'info',
//   });

//   // OpenAIのAPIキーを管理するステート
//   const [apiKey, setApiKey] = useState(null); // 初期値をnullに変更

//   const [user, setUser] = useState(null);

//   const navigate = useNavigate();

//   // CSVファイルアップロード用のステート
//   const [isCSVUploadDialogOpen, setIsCSVUploadDialogOpen] = useState(false);
//   const [csvFile, setCsvFile] = useState(null);

//   // ユーザーテンプレート管理用のステート
//   const [userTemplates, setUserTemplates] = useState([]);
//   // カテゴリーテンプレート管理用のステート
//   const [userCategoryTemplates, setUserCategoryTemplates] = useState([]);

//   // ユーザー情報を取得
//   useEffect(() => {
//     const auth = getAuth();
//     const unsubscribe = auth.onAuthStateChanged((firebaseUser) => {
//       setUser(firebaseUser);
//     });
//     return () => unsubscribe();
//   }, []);

//   // ユーザー固有の秘密鍵を生成
//   const generateEncryptionKey = (uid) => {
//     const salt = 'your-fixed-salt-value';
//     return CryptoJS.PBKDF2(uid, salt, { keySize: 256 / 32 }).toString();
//   };

//   // Snackbarを閉じる
//   const handleSnackbarClose = () => {
//     setSnackbar({
//       ...snackbar,
//       open: false,
//     });
//   };

//   // エラーメッセージを表示
//   const showSnackbar = (message, severity = 'info') => {
//     setSnackbar({
//       open: true,
//       message,
//       severity,
//     });
//   };

//   // FirebaseからAPIキーを取得
//   useEffect(() => {
//     const fetchApiKey = async () => {
//       if (user) {
//         try {
//           const docRef = doc(db, 'userApiKeys', user.uid);
//           const docSnap = await getDoc(docRef);
//           if (docSnap.exists()) {
//             const data = docSnap.data();
//             // APIキーを復号化
//             const encryptionKey = generateEncryptionKey(user.uid);
//             const decryptedBytes = CryptoJS.AES.decrypt(data.apiKey, encryptionKey);
//             const decryptedKey = decryptedBytes.toString(CryptoJS.enc.Utf8);

//             if (decryptedKey) {
//               setApiKey(decryptedKey);
//             } else {
//               console.error('APIキーの復号化に失敗しました。');
//               setApiKey(null);
//               showSnackbar('APIキーの復号化に失敗しました。再度設定してください。', 'error');
//             }
//           } else {
//             console.error('APIキーが設定されていません。');
//             setApiKey(null);
//             showSnackbar('APIキーが設定されていません。APIキーを入力してください。', 'warning');
//           }
//         } catch (error) {
//           console.error('APIキーの取得に失敗しました:', error);
//           setApiKey(null);
//           showSnackbar('APIキーの取得に失敗しました。', 'error');
//         }
//       }
//     };

//     fetchApiKey();
//   }, [user]);

//   // ユーザーテンプレートを取得
//   useEffect(() => {
//     const fetchUserTemplates = async () => {
//       if (user) {
//         try {
//           const userTemplatesRef = doc(db, 'userTemplates', user.uid);
//           const userTemplatesSnap = await getDoc(userTemplatesRef);
//           if (userTemplatesSnap.exists()) {
//             const data = userTemplatesSnap.data();
//             setUserTemplates(data.templates || []);
//           }
//         } catch (error) {
//           console.error('テンプレートの取得に失敗しました:', error);
//         }
//       }
//     };

//     fetchUserTemplates();
//   }, [user]);

//   // ユーザーカテゴリーテンプレートを取得
//   useEffect(() => {
//     const fetchUserCategoryTemplates = async () => {
//       if (user) {
//         try {
//           const userCategoryTemplatesRef = doc(db, 'userCategoryTemplates', user.uid);
//           const userCategoryTemplatesSnap = await getDoc(userCategoryTemplatesRef);
//           if (userCategoryTemplatesSnap.exists()) {
//             const data = userCategoryTemplatesSnap.data();
//             setUserCategoryTemplates(data.templates || []);
//           }
//         } catch (error) {
//           console.error('カテゴリーテンプレートの取得に失敗しました:', error);
//         }
//       }
//     };

//     fetchUserCategoryTemplates();
//   }, [user]);

//   const handleTemplateChange = (event) => {
//     const templateName = event.target.value;
//     setSelectedTemplate(templateName);

//     if (templateName.startsWith('user-category-')) {
//       const userTemplateName = templateName.replace('user-category-', '');
//       const selectedUserCategoryTemplate = userCategoryTemplates.find(
//         (template) => template.name === userTemplateName
//       );
//       setColumns(selectedUserCategoryTemplate.columns || []);
//     } else {
//       const selectedColumns = categoryTemplates[templateName] || [];
//       setColumns(selectedColumns);
//     }

//     // 初期データをクリア
//     setData([]);
//     setGroupedImages([]);
//     setUploadedImages([]);
//   };

//   // 画像アップロードダイアログを開く
//   const openImageUploadDialog = () => {
//     setIsImageUploadDialogOpen(true);
//   };

//   // 画像ファイルの選択
//   const handleImageFileChange = (e) => {
//     if (e.target.files && e.target.files.length > 0) {
//       setImageFiles(Array.from(e.target.files));
//     }
//   };

//   // 画像をFirebase Storageにアップロード
//   const handleImageUpload = async () => {
//     if (!imageFiles || imageFiles.length === 0) {
//       alert('画像ファイルを選択してください。');
//       return;
//     }

//     // ユーザーが認証されているか確認
//     if (!user) {
//       alert('ユーザーが認証されていません。再度ログインしてください。');
//       return;
//     }

//     setIsUploading(true);

//     try {
//       const storage = getStorage();
//       const uploadedUrls = [];

//       for (const file of imageFiles) {
//         const fileExtension = file.name.split('.').pop();
//         const storageReference = storageRef(
//           storage,
//           `users/${user.uid}/images/${uuidv4()}.${fileExtension}`
//         );
//         await uploadBytes(storageReference, file);
//         const downloadURL = await getDownloadURL(storageReference);
//         uploadedUrls.push({
//           id: uuidv4(),
//           url: downloadURL,
//         });
//       }

//       // ローカルの状態を更新
//       setUploadedImages((prev) => [...prev, ...uploadedUrls]);

//       // Firestoreに保存
//       const userDocRef = doc(db, 'users', user.uid);

//       // ユーザードキュメントが存在するか確認
//       const userDocSnap = await getDoc(userDocRef);
//       if (userDocSnap.exists()) {
//         // ドキュメントが存在する場合は更新
//         await updateDoc(userDocRef, {
//           uploadedImages: arrayUnion(...uploadedUrls),
//         });
//       } else {
//         // ドキュメントが存在しない場合は作成
//         await setDoc(userDocRef, {
//           uploadedImages: uploadedUrls,
//         });
//       }

//       setIsImageUploadDialogOpen(false);
//       setImageFiles([]);
//     } catch (error) {
//       console.error('画像のアップロードに失敗しました:', error);
//       alert('画像のアップロードに失敗しました。再度お試しください。');
//     } finally {
//       setIsUploading(false);
//     }
//   };

//   // 画像をFirestoreから取得
//   const fetchLatestUploadedImages = async () => {
//     if (!user) {
//       alert('ユーザーが認証されていません。再度ログインしてください。');
//       return;
//     }

//     setIsUploading(true);

//     try {
//       const userDocRef = doc(db, 'users', user.uid);
//       const userDocSnap = await getDoc(userDocRef);

//       if (userDocSnap.exists()) {
//         const data = userDocSnap.data();
//         const images = data.uploadedImages || [];
//         setUploadedImages(images);
//         showSnackbar('最新の画像を取得しました。', 'success');
//       } else {
//         showSnackbar('アップロードされた画像が見つかりません。', 'warning');
//       }
//     } catch (error) {
//       console.error('画像の取得に失敗しました:', error);
//       alert('画像の取得に失敗しました。再度お試しください。');
//     } finally {
//       setIsUploading(false);
//     }
//   };

//   // 画像の削除
//   const handleDeleteUploadedImage = async (id) => {
//     // ローカルの状態を更新
//     setUploadedImages((prev) => prev.filter((img) => img.id !== id));

//     // Firestoreからも削除
//     try {
//       const userDocRef = doc(db, 'users', user.uid);
//       const userDocSnap = await getDoc(userDocRef);
//       if (userDocSnap.exists()) {
//         const data = userDocSnap.data();
//         const updatedImages = data.uploadedImages.filter((img) => img.id !== id);
//         await updateDoc(userDocRef, {
//           uploadedImages: updatedImages,
//         });
//       }
//     } catch (error) {
//       console.error('Firestoreからの画像削除に失敗しました:', error);
//     }
//   };

//   // 区切りを追加
//   const addSeparator = async (index) => {
//     const newUploadedImages = [...uploadedImages];
//     const separator = { id: uuidv4(), type: 'separator' };
//     newUploadedImages.splice(index + 1, 0, separator);
//     setUploadedImages(newUploadedImages);

//     // Firestoreを更新
//     await updateFirestoreImages(newUploadedImages);
//   };

//   // 区切りを削除
//   const removeSeparator = async (id) => {
//     const newUploadedImages = uploadedImages.filter((img) => img.id !== id);
//     setUploadedImages(newUploadedImages);

//     // Firestoreを更新
//     await updateFirestoreImages(newUploadedImages);
//   };

//   // Firestoreの画像リストを更新
//   const updateFirestoreImages = async (images) => {
//     try {
//       const userDocRef = doc(db, 'users', user.uid);
//       await updateDoc(userDocRef, {
//         uploadedImages: images,
//       });
//     } catch (error) {
//       console.error('Firestoreの更新に失敗しました:', error);
//     }
//   };

//   // 画像をグループ化して商品を作成
//   const handleGroupImages = () => {
//     setIsGrouping(true);

//     // 画像を区切りごとにグループ化する処理
//     const products = [];
//     let currentProductImages = [];

//     for (const img of uploadedImages) {
//       if (img.type === 'separator') {
//         if (currentProductImages.length > 0) {
//           products.push([...currentProductImages]);
//           currentProductImages = [];
//         }
//       } else {
//         currentProductImages.push(img);
//       }
//     }

//     if (currentProductImages.length > 0) {
//       products.push([...currentProductImages]);
//     }

//     setGroupedImages(products);
//     setIsGrouping(false);

//     // データを更新
//     if (selectedTemplate) {
//       const newData = products.map((productImages) => ({
//         // 各カラムにデフォルト値を設定
//         ...columns.reduce((obj, col) => {
//           if (col === 'PicURL') {
//             // PicURLカラムには画像URLを設定
//             obj[col] = productImages.map((img) => img.url).join('|');
//           } else if (defaultValues.hasOwnProperty(col)) {
//             // デフォルト値が指定されているカラム
//             obj[col] = defaultValues[col];
//           } else {
//             // それ以外のカラムには 'NA' を設定
//             obj[col] = 'NA';
//           }
//           return obj;
//         }, {}),
//       }));
//       setData(newData);

//       // startRowとendRowを更新
//       setStartRow(1);
//       setEndRow(newData.length);
//     }
//   };

//   // 画像のドラッグ＆ドロップによる並び替え
//   const onDragEnd = async (result) => {
//     if (!result.destination) {
//       return;
//     }

//     const reorderedImages = Array.from(uploadedImages);
//     const [removed] = reorderedImages.splice(result.source.index, 1);
//     reorderedImages.splice(result.destination.index, 0, removed);

//     setUploadedImages(reorderedImages);

//     // Firestoreを更新
//     await updateFirestoreImages(reorderedImages);
//   };

//   // 最大のendRowを計算
//   const calculateMaxEndRow = () => {
//     return data.length;
//   };

//   // ページロード時に画像を自動取得（オプション）
//   useEffect(() => {
//     if (user) {
//       fetchLatestUploadedImages();
//     }
//   }, [user]);

//   // サイドバーのセクション切り替え
//   const handleSectionChange = (section) => {
//     setActiveSection(section);
//   };

//   // ホーム画面に戻る処理
//   const handleGoHome = () => {
//     navigate('/'); // ホーム画面へのパスに変更してください
//   };

//   // CSVアップロードダイアログを開く
//   const openCSVUploadDialog = () => {
//     setIsCSVUploadDialogOpen(true);
//   };

//   // CSVファイルが選択されたときの処理
//   const handleCSVFileChange = (e) => {
//     if (e.target.files && e.target.files.length > 0) {
//       setCsvFile(e.target.files[0]);
//     }
//   };

//   // CSVファイルをアップロードしてカテゴリーテンプレートを作成
//   const handleCSVUpload = () => {
//     if (!csvFile) {
//       alert('CSVファイルを選択してください。');
//       return;
//     }

//     parse(csvFile, {
//       header: true,
//       skipEmptyLines: true,
//       complete: async (results) => {
//         const columnsFromCSV = results.meta.fields;
//         // テンプレート名をユーザーに入力してもらう
//         const templateName = prompt('テンプレート名を入力してください:');
//         if (templateName && columnsFromCSV) {
//           // ユーザーのカテゴリーテンプレートとして保存
//           await saveUserCategoryTemplate(templateName, columnsFromCSV);
//           setIsCSVUploadDialogOpen(false);
//           setCsvFile(null);
//           showSnackbar('テンプレートが保存されました。', 'success');
//         } else {
//           alert('テンプレート名が無効です。');
//         }
//       },
//       error: (error) => {
//         console.error('CSVのパースに失敗しました:', error);
//         alert('CSVファイルの読み込みに失敗しました。');
//       },
//     });
//   };

//   // ユーザーカテゴリーテンプレートを保存
//   const saveUserCategoryTemplate = async (templateName, columns) => {
//     if (!user) {
//       alert('ユーザーが認証されていません。再度ログインしてください。');
//       return;
//     }

//     try {
//       const userCategoryTemplatesRef = doc(db, 'userCategoryTemplates', user.uid);
//       const userCategoryTemplatesSnap = await getDoc(userCategoryTemplatesRef);

//       const newTemplate = { name: templateName, columns };

//       if (userCategoryTemplatesSnap.exists()) {
//         const data = userCategoryTemplatesSnap.data();
//         const existingTemplates = data.templates || [];
//         // 同名のテンプレートが存在するか確認
//         const duplicate = existingTemplates.find((t) => t.name === templateName);
//         if (duplicate) {
//           alert('同じ名前のテンプレートが既に存在します。別の名前を使用してください。');
//           return;
//         }
//         // 既存のテンプレートに追加
//         await updateDoc(userCategoryTemplatesRef, {
//           templates: arrayUnion(newTemplate),
//         });
//         setUserCategoryTemplates([...existingTemplates, newTemplate]);
//       } else {
//         // 新しいドキュメントを作成
//         await setDoc(userCategoryTemplatesRef, {
//           templates: [newTemplate],
//         });
//         setUserCategoryTemplates([newTemplate]);
//       }
//     } catch (error) {
//       console.error('カテゴリーテンプレートの保存に失敗しました:', error);
//       alert('カテゴリーテンプレートの保存に失敗しました。');
//     }
//   };

//   return (
//     <Box sx={{ display: 'flex' }}>
//       <CssBaseline />
//       {/* AppBar */}
//       <AppBar
//         position="fixed"
//         sx={{
//           zIndex: (theme) => theme.zIndex.drawer + 1,
//           transition: (theme) =>
//             theme.transitions.create(['width', 'margin'], {
//               easing: theme.transitions.easing.sharp,
//               duration: theme.transitions.duration.leavingScreen,
//             }),
//           ...(isSidebarOpen && {
//             marginLeft: drawerWidth,
//             width: `calc(100% - ${drawerWidth}px)`,
//           }),
//         }}
//       >
//         <Toolbar>
//           {/* ホーム画面へのリンクを追加 */}
//           <IconButton
//             color="inherit"
//             aria-label="go to home"
//             onClick={handleGoHome}
//             edge="start"
//             sx={{
//               marginRight: 2,
//             }}
//           >
//             <HomeIcon />
//           </IconButton>

//           <IconButton
//             color="inherit"
//             aria-label="open drawer"
//             onClick={() => setIsSidebarOpen(!isSidebarOpen)}
//             edge="start"
//             sx={{
//               marginRight: 2,
//             }}
//           >
//             {isSidebarOpen ? <CloseIcon /> : <MenuIcon />}
//           </IconButton>
//           <Typography variant="h6" noWrap component="div">
//             0から一括出品
//           </Typography>
//         </Toolbar>
//       </AppBar>

//       {/* サイドバー */}
//       <Drawer
//         variant="persistent"
//         anchor="left"
//         open={isSidebarOpen}
//         sx={{
//           width: drawerWidth,
//           flexShrink: 0,
//           '& .MuiDrawer-paper': {
//             width: drawerWidth,
//             boxSizing: 'border-box',
//           },
//         }}
//       >
//         <Toolbar />
//         <Box sx={{ overflow: 'auto' }}>
//           <List>
//             <ListItem button onClick={() => handleSectionChange('main')}>
//               <ListItemText primary="メイン" />
//             </ListItem>
//             <ListItem button onClick={() => handleSectionChange('priceManager')}>
//               <ListItemText primary="価格管理" />
//             </ListItem>
//             <ListItem button onClick={() => handleSectionChange('itemSpecifics')}>
//               <ListItemText primary="Item Specifics管理" />
//             </ListItem>
//             <ListItem button onClick={() => handleSectionChange('templateManager')}>
//               <ListItemText primary="テンプレート管理" />
//             </ListItem>
//             <ListItem button onClick={() => handleSectionChange('titleGeneration')}>
//               <ListItemText primary="AIタイトル生成" />
//             </ListItem>
//             <ListItem button onClick={() => handleSectionChange('descriptionGeneration')}>
//               <ListItemText primary="AI商品説明生成" />
//             </ListItem>
//             <ListItem button onClick={() => handleSectionChange('apiKeyInput')}>
//               <ListItemText primary="APIキー入力" />
//             </ListItem>
//             <ListItem button onClick={() => handleSectionChange('userTemplateManager')}>
//               <ListItemText primary="ユーザーテンプレート管理" />
//             </ListItem>
//           </List>
//         </Box>
//       </Drawer>

//       {/* メインコンテンツ */}
//       <Main open={isSidebarOpen}>
//         <Toolbar />
//         {/* APIキーが未設定の場合に警告を表示 */}
//         {!apiKey && (
//           <Alert severity="warning" sx={{ mb: 2 }}>
//             OpenAI APIキーが設定されていません。左のサイドバーから「APIキー入力」を選択し、APIキーを入力してください。
//           </Alert>
//         )}
//         {activeSection === 'main' && (
//           <Box>
//             <FormControl fullWidth sx={{ mb: 2 }}>
//               <InputLabel id="template-select-label">カテゴリーテンプレートを選択</InputLabel>
//               <Select
//                 labelId="template-select-label"
//                 value={selectedTemplate}
//                 label="カテゴリーテンプレートを選択"
//                 onChange={handleTemplateChange}
//               >
//                 <MenuItem value="">
//                   <em>テンプレートを選択してください</em>
//                 </MenuItem>
//                 {/* 既存のテンプレート */}
//                 {Object.keys(categoryTemplates).map((templateName) => (
//                   <MenuItem key={templateName} value={templateName}>
//                     {templateName}
//                   </MenuItem>
//                 ))}
//                 {/* ユーザーのカテゴリーテンプレート */}
//                 {userCategoryTemplates.map((template) => (
//                   <MenuItem key={template.name} value={`user-category-${template.name}`}>
//                     {template.name}
//                   </MenuItem>
//                 ))}
//               </Select>
//             </FormControl>

//             <Grid container spacing={2} sx={{ mb: 2 }}>
//               <Grid item xs={12} sm={6} md={4}>
//                 <Button
//                   variant="contained"
//                   color="primary"
//                   onClick={openCSVUploadDialog}
//                 >
//                   CSVファイルからテンプレートを作成
//                 </Button>
//               </Grid>
//             </Grid>

//             {/* CSVアップロードダイアログ */}
//             <Dialog open={isCSVUploadDialogOpen} onClose={() => setIsCSVUploadDialogOpen(false)}>
//               <DialogTitle>CSVファイルをアップロード</DialogTitle>
//               <DialogContent>
//                 <input
//                   type="file"
//                   accept=".csv"
//                   onChange={handleCSVFileChange}
//                 />
//               </DialogContent>
//               <DialogActions>
//                 <Button onClick={() => setIsCSVUploadDialogOpen(false)}>キャンセル</Button>
//                 <Button onClick={handleCSVUpload} disabled={!csvFile}>
//                   アップロード
//                 </Button>
//               </DialogActions>
//             </Dialog>

//             {selectedTemplate && (
//               <>
//                 <Grid container spacing={2}>
//                   <Grid item xs={12} sm={6} md={4}>
//                     <Button
//                       variant="contained"
//                       color="primary"
//                       onClick={openImageUploadDialog}
//                     >
//                       画像をアップロード
//                     </Button>
//                   </Grid>
//                   <Grid item xs={12} sm={6} md={4}>
//                     <Button
//                       variant="contained"
//                       color="primary"
//                       onClick={fetchLatestUploadedImages}
//                     >
//                       最新のアップロード画像を取得
//                     </Button>
//                   </Grid>
//                 </Grid>

//                 <Dialog open={isImageUploadDialogOpen} onClose={() => setIsImageUploadDialogOpen(false)}>
//                   <DialogTitle>画像をアップロード</DialogTitle>
//                   <DialogContent>
//                     <input
//                       type="file"
//                       accept="image/*"
//                       multiple
//                       onChange={handleImageFileChange}
//                     />
//                   </DialogContent>
//                   <DialogActions>
//                     <Button onClick={() => setIsImageUploadDialogOpen(false)}>キャンセル</Button>
//                     <Button onClick={handleImageUpload} disabled={isUploading}>
//                       アップロード
//                     </Button>
//                   </DialogActions>
//                 </Dialog>

//                 {isUploading && (
//                   <LinearProgress sx={{ mt: 2, mb: 2 }} />
//                 )}

//                 {uploadedImages.length > 0 && (
//                   <>
//                     <Typography variant="h6" sx={{ mt: 4 }}>
//                       アップロードされた画像
//                     </Typography>
//                     <Typography variant="body2" sx={{ mb: 2 }}>
//                       画像をドラッグ＆ドロップで並び替え、区切りを挿入して商品を分けることができます。
//                     </Typography>
//                     <DragDropContext onDragEnd={onDragEnd}>
//                       <Droppable droppableId="uploadedImages" direction="horizontal">
//                         {(provided) => (
//                           <Box
//                             sx={{
//                               display: 'flex',
//                               flexWrap: 'wrap',
//                               p: 1,
//                               border: '1px solid #ccc',
//                               borderRadius: '4px',
//                             }}
//                             {...provided.droppableProps}
//                             ref={provided.innerRef}
//                           >
//                             {uploadedImages.map((img, index) => (
//                               <Draggable key={img.id} draggableId={img.id} index={index}>
//                                 {(provided) => (
//                                   <Box
//                                     sx={{
//                                       position: 'relative',
//                                       m: 0.5,
//                                       width: '120px',
//                                       height: '120px',
//                                       display: 'flex',
//                                       alignItems: 'center',
//                                       justifyContent: 'center',
//                                       backgroundColor: img.type === 'separator' ? '#f0f0f0' : 'transparent',
//                                       border: img.type === 'separator' ? '2px dashed #ccc' : 'none',
//                                     }}
//                                     ref={provided.innerRef}
//                                     {...provided.draggableProps}
//                                     {...provided.dragHandleProps}
//                                   >
//                                     {img.type === 'separator' ? (
//                                       <>
//                                         <Typography variant="body2">区切り</Typography>
//                                         <IconButton
//                                           size="small"
//                                           sx={{
//                                             position: 'absolute',
//                                             top: '4px',
//                                             right: '4px',
//                                             color: 'red',
//                                             backgroundColor: 'white',
//                                             '&:hover': {
//                                               backgroundColor: 'white',
//                                             },
//                                           }}
//                                           onClick={() => removeSeparator(img.id)}
//                                         >
//                                           <DeleteIcon fontSize="small" />
//                                         </IconButton>
//                                       </>
//                                     ) : (
//                                       <>
//                                         <img
//                                           src={img.url}
//                                           alt={`Uploaded ${index}`}
//                                           style={{ width: '100%', height: '100%', objectFit: 'cover' }}
//                                         />
//                                         <IconButton
//                                           size="small"
//                                           sx={{
//                                             position: 'absolute',
//                                             top: '4px',
//                                             right: '4px',
//                                             color: 'red',
//                                             backgroundColor: 'white',
//                                             '&:hover': {
//                                               backgroundColor: 'white',
//                                             },
//                                           }}
//                                           onClick={() => handleDeleteUploadedImage(img.id)}
//                                         >
//                                           <DeleteIcon fontSize="small" />
//                                         </IconButton>
//                                         {/* 区切りを挿入するボタン */}
//                                         {img.type !== 'separator' && (
//                                           <IconButton
//                                             size="small"
//                                             sx={{
//                                               position: 'absolute',
//                                               bottom: '4px',
//                                               right: '4px',
//                                               backgroundColor: 'white',
//                                               '&:hover': {
//                                                 backgroundColor: 'white',
//                                               },
//                                             }}
//                                             onClick={() => addSeparator(index)}
//                                           >
//                                             <AddIcon fontSize="small" />
//                                           </IconButton>
//                                         )}
//                                       </>
//                                     )}
//                                   </Box>
//                                 )}
//                               </Draggable>
//                             ))}
//                             {provided.placeholder}
//                           </Box>
//                         )}
//                       </Droppable>
//                     </DragDropContext>

//                     <Grid container spacing={2} sx={{ mt: 2 }}>
//                       <Grid item xs={12} sm={6} md={4}>
//                         <Button
//                           variant="contained"
//                           color="secondary"
//                           onClick={handleGroupImages}
//                           disabled={isGrouping}
//                         >
//                           画像を商品に割り当てる
//                         </Button>
//                       </Grid>
//                     </Grid>
//                   </>
//                 )}

//                 {/* ImageOperationsコンポーネントを追加 */}
//                 <Box sx={{ mt: 4 }}>
//                   <ImageOperations
//                     data={data}
//                     setData={setData}
//                     startRow={startRow}
//                     endRow={endRow}
//                     calculateMaxEndRow={calculateMaxEndRow}
//                     isProcessing={isProcessing}
//                     setIsProcessing={setIsProcessing}
//                     setError={setError}
//                     showSnackbar={showSnackbar}
//                     openPreviewDialog={openPreviewDialog}
//                     setOpenPreviewDialog={setOpenPreviewDialog}
//                     apiKey={apiKey} // OpenAIのAPIキー
//                     previewDialogMode={previewDialogMode}
//                     setPreviewDialogMode={setPreviewDialogMode}
//                   />
//                 </Box>
//               </>
//             )}

//             {isGrouping && (
//               <LinearProgress sx={{ mt: 2, mb: 2 }} />
//             )}

//             {data.length > 0 && (
//               <>
//                 {/* EditableSpreadsheetを常に表示 */}
//                 <Paper elevation={3} sx={{ p: 2, mt: 4 }}>
//                   <EditableSpreadsheet
//                     data={data}
//                     setData={setData}
//                     columns={columns}
//                     setColumns={setColumns}
//                   />
//                 </Paper>

//                 {/* Snackbar */}
//                 <Snackbar
//                   open={snackbar.open}
//                   autoHideDuration={6000}
//                   onClose={handleSnackbarClose}
//                 >
//                   <Alert
//                     onClose={handleSnackbarClose}
//                     severity={snackbar.severity}
//                     sx={{ width: '100%' }}
//                   >
//                     {snackbar.message}
//                   </Alert>
//                 </Snackbar>
//               </>
//             )}
//           </Box>
//         )}

//         {activeSection === 'priceManager' && (
//           <Box>
//             <PriceManager data={data} setData={setData} />
//           </Box>
//         )}

//         {activeSection === 'itemSpecifics' && (
//           <Box>
//             <ItemSpecificsManager
//               userId={user ? user.uid : null}
//               headers={columns}
//               data={data}
//               setData={setData}
//             />
//           </Box>
//         )}

//         {activeSection === 'templateManager' && (
//           <Box>
//             <ColumnNameBasedTemplateManager
//               data={data}
//               setData={setData}
//               columns={columns}
//               setColumns={setColumns}
//             />
//           </Box>
//         )}

//         {activeSection === 'titleGeneration' && (
//           <Box>
//             <TitleGenerationComponent
//               data={data}
//               setData={setData}
//               apiKey={apiKey}
//               user={user}
//             />
//           </Box>
//         )}

//         {activeSection === 'descriptionGeneration' && (
//           <Box>
//             <OpenAIDescriptionGenerator
//               data={data}
//               setData={setData}
//               apiKey={apiKey}
//               user={user}
//             />
//           </Box>
//         )}

//         {activeSection === 'apiKeyInput' && (
//           <Box>
//             <APIKeyInput user={user} apiKey={apiKey} setApiKey={setApiKey} />
//           </Box>
//         )}

//         {activeSection === 'userTemplateManager' && (
//           <Box>
//             <UserTemplateManager
//               userTemplates={userTemplates}
//               setUserTemplates={setUserTemplates}
//               user={user}
//             />
//           </Box>
//         )}
//       </Main>
//     </Box>
//   );
// }

// export default BulkListingFromScratch;





// // BulkListingFromScratch.js

// import React, { useState, useEffect } from 'react';
// import {
//   Box,
//   Button,
//   Typography,
//   Paper,
//   FormControl,
//   InputLabel,
//   Select,
//   MenuItem,
//   Dialog,
//   DialogTitle,
//   DialogContent,
//   DialogActions,
//   LinearProgress,
//   Snackbar,
//   Alert,
//   IconButton,
//   AppBar,
//   Toolbar,
//   Grid,
//   CssBaseline,
//   Divider,
//   List,
//   ListItem,
//   ListItemText,
//   TextField,
// } from '@mui/material';
// import CloseIcon from '@mui/icons-material/Close';
// import MenuIcon from '@mui/icons-material/Menu';
// import HomeIcon from '@mui/icons-material/Home';
// import { styled } from '@mui/material/styles';
// import Drawer from '@mui/material/Drawer';
// import EditableSpreadsheet from './EditableSpreadsheet';
// import { getStorage, ref as storageRef, uploadBytes, getDownloadURL } from 'firebase/storage';
// import { v4 as uuidv4 } from 'uuid';
// import DeleteIcon from '@mui/icons-material/Delete';
// import AddIcon from '@mui/icons-material/Add';
// import { DragDropContext, Droppable, Draggable } from '@hello-pangea/dnd';
// import { db } from './firebaseConfig';
// import { doc, getDoc, setDoc, updateDoc, arrayUnion } from 'firebase/firestore';
// import CryptoJS from 'crypto-js';
// import ColumnNameBasedTemplateManager from './ColumnNameBasedTemplateManager';
// import APIKeyInput from './APIKeyInput';
// import TitleGenerationComponent from './TitleGenerationComponent';
// import OpenAIDescriptionGenerator from './OpenAIDescriptionGenerator';
// import ItemSpecificsManager from './ItemSpecificsManager';
// import UserTemplateManager from './UserTemplateManager';
// import { getAuth } from 'firebase/auth';
// import { categoryTemplates } from './CategoryTemplates';
// import PriceManager from './PriceManager';
// import ImageOperations from './ImageOperations';
// import { useNavigate, Link as RouterLink } from 'react-router-dom';
// import { parse } from 'papaparse'; // CSVパース用のライブラリ

// const drawerWidth = 250;

// // スタイリングされたMainコンポーネントを定義
// const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
//   ({ theme, open }) => ({
//     flexGrow: 1,
//     padding: theme.spacing(3),
//     maxWidth: '100%',
//     overflowX: 'auto',
//     transition: theme.transitions.create(['margin'], {
//       easing: theme.transitions.easing.sharp,
//       duration: theme.transitions.duration.leavingScreen,
//     }),
//     marginLeft: `-${drawerWidth}px`,
//     ...(open && {
//       transition: theme.transitions.create(['margin'], {
//         easing: theme.transitions.easing.easeOut,
//         duration: theme.transitions.duration.enteringScreen,
//       }),
//       marginLeft: 0,
//     }),
//   }),
// );

// function BulkListingFromScratch() {
//   const [isSidebarOpen, setIsSidebarOpen] = useState(true);

//   const [activeSection, setActiveSection] = useState('main');
//   const [selectedTemplate, setSelectedTemplate] = useState('');
//   const [columns, setColumns] = useState([]);
//   const [data, setData] = useState([]);

//   // デフォルト値を設定したいカラムとその値を定義
//   const defaultValues = {
//     'Action(CC=Cp1252)': 'Add',
//     'ConditionID': '3000',
//     'C:Brand': 'No Brand',
//     'PayPalAccepted': '1',
//     'Country': 'JP',
//     'Apply Profile Domestic': '0.0',
//     'Apply Profile International': '0.0',
//     'BuyerRequirements:LinkedPayPalAccount': '0.0',
//     'Duration': 'GTC',
//     'Format': 'FixedPriceItem',
//     'Quantity': '1',
//     'Currency': 'USD',
//     'SiteID': 'US',
//     'C:Country': 'Japan',
//     'BestOfferEnabled': '0',
//     // 必要に応じて他のデフォルト値を追加
//   };

//   // 画像関連のステート
//   const [uploadedImages, setUploadedImages] = useState([]);
//   const [isUploading, setIsUploading] = useState(false);
//   const [groupedImages, setGroupedImages] = useState([]);
//   const [isGrouping, setIsGrouping] = useState(false);

//   const [isImageUploadDialogOpen, setIsImageUploadDialogOpen] = useState(false);
//   const [imageFiles, setImageFiles] = useState([]);

//   // ImageOperations用のステートと関数
//   const [startRow, setStartRow] = useState(1);
//   const [endRow, setEndRow] = useState(1);
//   const [isProcessing, setIsProcessing] = useState(false);
//   const [error, setError] = useState('');
//   const [openPreviewDialog, setOpenPreviewDialog] = useState(false);
//   const [previewDialogMode, setPreviewDialogMode] = useState('edit');
//   const [snackbar, setSnackbar] = useState({
//     open: false,
//     message: '',
//     severity: 'info',
//   });

//   // OpenAIのAPIキーを管理するステート
//   const [apiKey, setApiKey] = useState(null); // 初期値をnullに変更

//   const [user, setUser] = useState(null);

//   const navigate = useNavigate();

//   // CSVファイルアップロード用のステート
//   const [isCSVUploadDialogOpen, setIsCSVUploadDialogOpen] = useState(false);
//   const [csvFile, setCsvFile] = useState(null);

//   // ユーザーテンプレート管理用のステート
//   const [userTemplates, setUserTemplates] = useState([]);

//   // ユーザー情報を取得
//   useEffect(() => {
//     const auth = getAuth();
//     const unsubscribe = auth.onAuthStateChanged((firebaseUser) => {
//       setUser(firebaseUser);
//     });
//     return () => unsubscribe();
//   }, []);

//   // ユーザー固有の秘密鍵を生成
//   const generateEncryptionKey = (uid) => {
//     const salt = 'your-fixed-salt-value';
//     return CryptoJS.PBKDF2(uid, salt, { keySize: 256 / 32 }).toString();
//   };

//   // Snackbarを閉じる
//   const handleSnackbarClose = () => {
//     setSnackbar({
//       ...snackbar,
//       open: false,
//     });
//   };

//   // エラーメッセージを表示
//   const showSnackbar = (message, severity = 'info') => {
//     setSnackbar({
//       open: true,
//       message,
//       severity,
//     });
//   };

//   // FirebaseからAPIキーを取得
//   useEffect(() => {
//     const fetchApiKey = async () => {
//       if (user) {
//         try {
//           const docRef = doc(db, 'userApiKeys', user.uid);
//           const docSnap = await getDoc(docRef);
//           if (docSnap.exists()) {
//             const data = docSnap.data();
//             // APIキーを復号化
//             const encryptionKey = generateEncryptionKey(user.uid);
//             const decryptedBytes = CryptoJS.AES.decrypt(data.apiKey, encryptionKey);
//             const decryptedKey = decryptedBytes.toString(CryptoJS.enc.Utf8);

//             if (decryptedKey) {
//               setApiKey(decryptedKey);
//             } else {
//               console.error('APIキーの復号化に失敗しました。');
//               setApiKey(null);
//               showSnackbar('APIキーの復号化に失敗しました。再度設定してください。', 'error');
//             }
//           } else {
//             console.error('APIキーが設定されていません。');
//             setApiKey(null);
//             showSnackbar('APIキーが設定されていません。APIキーを入力してください。', 'warning');
//           }
//         } catch (error) {
//           console.error('APIキーの取得に失敗しました:', error);
//           setApiKey(null);
//           showSnackbar('APIキーの取得に失敗しました。', 'error');
//         }
//       }
//     };

//     fetchApiKey();
//   }, [user]);

//   // ユーザーテンプレートを取得
//   useEffect(() => {
//     const fetchUserTemplates = async () => {
//       if (user) {
//         try {
//           const userTemplatesRef = doc(db, 'userTemplates', user.uid);
//           const userTemplatesSnap = await getDoc(userTemplatesRef);
//           if (userTemplatesSnap.exists()) {
//             const data = userTemplatesSnap.data();
//             setUserTemplates(data.templates || []);
//           }
//         } catch (error) {
//           console.error('テンプレートの取得に失敗しました:', error);
//         }
//       }
//     };

//     fetchUserTemplates();
//   }, [user]);

//   const handleTemplateChange = (event) => {
//     const templateName = event.target.value;
//     setSelectedTemplate(templateName);

//     if (templateName.startsWith('user-')) {
//       const userTemplateName = templateName.replace('user-', '');
//       const selectedUserTemplate = userTemplates.find(
//         (template) => template.name === userTemplateName
//       );
//       setColumns(selectedUserTemplate.columns || []);
//     } else {
//       const selectedColumns = categoryTemplates[templateName] || [];
//       setColumns(selectedColumns);
//     }

//     // 初期データをクリア
//     setData([]);
//     setGroupedImages([]);
//     setUploadedImages([]);
//   };

//   // 画像アップロードダイアログを開く
//   const openImageUploadDialog = () => {
//     setIsImageUploadDialogOpen(true);
//   };

//   // 画像ファイルの選択
//   const handleImageFileChange = (e) => {
//     if (e.target.files && e.target.files.length > 0) {
//       setImageFiles(Array.from(e.target.files));
//     }
//   };

//   // 画像をFirebase Storageにアップロード
//   const handleImageUpload = async () => {
//     if (!imageFiles || imageFiles.length === 0) {
//       alert('画像ファイルを選択してください。');
//       return;
//     }

//     // ユーザーが認証されているか確認
//     if (!user) {
//       alert('ユーザーが認証されていません。再度ログインしてください。');
//       return;
//     }

//     setIsUploading(true);

//     try {
//       const storage = getStorage();
//       const uploadedUrls = [];

//       for (const file of imageFiles) {
//         const fileExtension = file.name.split('.').pop();
//         const storageReference = storageRef(
//           storage,
//           `users/${user.uid}/images/${uuidv4()}.${fileExtension}`
//         );
//         await uploadBytes(storageReference, file);
//         const downloadURL = await getDownloadURL(storageReference);
//         uploadedUrls.push({
//           id: uuidv4(),
//           url: downloadURL,
//         });
//       }

//       // ローカルの状態を更新
//       setUploadedImages((prev) => [...prev, ...uploadedUrls]);

//       // Firestoreに保存
//       const userDocRef = doc(db, 'users', user.uid);

//       // ユーザードキュメントが存在するか確認
//       const userDocSnap = await getDoc(userDocRef);
//       if (userDocSnap.exists()) {
//         // ドキュメントが存在する場合は更新
//         await updateDoc(userDocRef, {
//           uploadedImages: arrayUnion(...uploadedUrls),
//         });
//       } else {
//         // ドキュメントが存在しない場合は作成
//         await setDoc(userDocRef, {
//           uploadedImages: uploadedUrls,
//         });
//       }

//       setIsImageUploadDialogOpen(false);
//       setImageFiles([]);
//     } catch (error) {
//       console.error('画像のアップロードに失敗しました:', error);
//       alert('画像のアップロードに失敗しました。再度お試しください。');
//     } finally {
//       setIsUploading(false);
//     }
//   };

//   // 画像をFirestoreから取得
//   const fetchLatestUploadedImages = async () => {
//     if (!user) {
//       alert('ユーザーが認証されていません。再度ログインしてください。');
//       return;
//     }

//     setIsUploading(true);

//     try {
//       const userDocRef = doc(db, 'users', user.uid);
//       const userDocSnap = await getDoc(userDocRef);

//       if (userDocSnap.exists()) {
//         const data = userDocSnap.data();
//         const images = data.uploadedImages || [];
//         setUploadedImages(images);
//         showSnackbar('最新の画像を取得しました。', 'success');
//       } else {
//         showSnackbar('アップロードされた画像が見つかりません。', 'warning');
//       }
//     } catch (error) {
//       console.error('画像の取得に失敗しました:', error);
//       alert('画像の取得に失敗しました。再度お試しください。');
//     } finally {
//       setIsUploading(false);
//     }
//   };

//   // 画像の削除
//   const handleDeleteUploadedImage = async (id) => {
//     // ローカルの状態を更新
//     setUploadedImages((prev) => prev.filter((img) => img.id !== id));

//     // Firestoreからも削除
//     try {
//       const userDocRef = doc(db, 'users', user.uid);
//       const userDocSnap = await getDoc(userDocRef);
//       if (userDocSnap.exists()) {
//         const data = userDocSnap.data();
//         const updatedImages = data.uploadedImages.filter((img) => img.id !== id);
//         await updateDoc(userDocRef, {
//           uploadedImages: updatedImages,
//         });
//       }
//     } catch (error) {
//       console.error('Firestoreからの画像削除に失敗しました:', error);
//     }
//   };

//   // 区切りを追加
//   const addSeparator = async (index) => {
//     const newUploadedImages = [...uploadedImages];
//     const separator = { id: uuidv4(), type: 'separator' };
//     newUploadedImages.splice(index + 1, 0, separator);
//     setUploadedImages(newUploadedImages);

//     // Firestoreを更新
//     await updateFirestoreImages(newUploadedImages);
//   };

//   // 区切りを削除
//   const removeSeparator = async (id) => {
//     const newUploadedImages = uploadedImages.filter((img) => img.id !== id);
//     setUploadedImages(newUploadedImages);

//     // Firestoreを更新
//     await updateFirestoreImages(newUploadedImages);
//   };

//   // Firestoreの画像リストを更新
//   const updateFirestoreImages = async (images) => {
//     try {
//       const userDocRef = doc(db, 'users', user.uid);
//       await updateDoc(userDocRef, {
//         uploadedImages: images,
//       });
//     } catch (error) {
//       console.error('Firestoreの更新に失敗しました:', error);
//     }
//   };

//   // 画像をグループ化して商品を作成
//   const handleGroupImages = () => {
//     setIsGrouping(true);

//     // 画像を区切りごとにグループ化する処理
//     const products = [];
//     let currentProductImages = [];

//     for (const img of uploadedImages) {
//       if (img.type === 'separator') {
//         if (currentProductImages.length > 0) {
//           products.push([...currentProductImages]);
//           currentProductImages = [];
//         }
//       } else {
//         currentProductImages.push(img);
//       }
//     }

//     if (currentProductImages.length > 0) {
//       products.push([...currentProductImages]);
//     }

//     setGroupedImages(products);
//     setIsGrouping(false);

//     // データを更新
//     if (selectedTemplate) {
//       const newData = products.map((productImages) => ({
//         // 各カラムにデフォルト値を設定
//         ...columns.reduce((obj, col) => {
//           if (col === 'PicURL') {
//             // PicURLカラムには画像URLを設定
//             obj[col] = productImages.map((img) => img.url).join('|');
//           } else if (defaultValues.hasOwnProperty(col)) {
//             // デフォルト値が指定されているカラム
//             obj[col] = defaultValues[col];
//           } else {
//             // それ以外のカラムには 'NA' を設定
//             obj[col] = 'NA';
//           }
//           return obj;
//         }, {}),
//       }));
//       setData(newData);

//       // startRowとendRowを更新
//       setStartRow(1);
//       setEndRow(newData.length);
//     }
//   };

//   // 画像のドラッグ＆ドロップによる並び替え
//   const onDragEnd = async (result) => {
//     if (!result.destination) {
//       return;
//     }

//     const reorderedImages = Array.from(uploadedImages);
//     const [removed] = reorderedImages.splice(result.source.index, 1);
//     reorderedImages.splice(result.destination.index, 0, removed);

//     setUploadedImages(reorderedImages);

//     // Firestoreを更新
//     await updateFirestoreImages(reorderedImages);
//   };

//   // 最大のendRowを計算
//   const calculateMaxEndRow = () => {
//     return data.length;
//   };

//   // ページロード時に画像を自動取得（オプション）
//   useEffect(() => {
//     if (user) {
//       fetchLatestUploadedImages();
//     }
//   }, [user]);

//   // サイドバーのセクション切り替え
//   const handleSectionChange = (section) => {
//     setActiveSection(section);
//   };

//   // ホーム画面に戻る処理
//   const handleGoHome = () => {
//     navigate('/'); // ホーム画面へのパスに変更してください
//   };

//   // CSVアップロードダイアログを開く
//   const openCSVUploadDialog = () => {
//     setIsCSVUploadDialogOpen(true);
//   };

//   // CSVファイルが選択されたときの処理
//   const handleCSVFileChange = (e) => {
//     if (e.target.files && e.target.files.length > 0) {
//       setCsvFile(e.target.files[0]);
//     }
//   };

//   // CSVファイルをアップロードしてテンプレートを作成
//   const handleCSVUpload = () => {
//     if (!csvFile) {
//       alert('CSVファイルを選択してください。');
//       return;
//     }

//     parse(csvFile, {
//       header: true,
//       skipEmptyLines: true,
//       complete: async (results) => {
//         const columnsFromCSV = results.meta.fields;
//         // テンプレート名をユーザーに入力してもらう
//         const templateName = prompt('テンプレート名を入力してください:');
//         if (templateName && columnsFromCSV) {
//           // ユーザーのテンプレートとして保存
//           await saveUserTemplate(templateName, columnsFromCSV);
//           setIsCSVUploadDialogOpen(false);
//           setCsvFile(null);
//           showSnackbar('テンプレートが保存されました。', 'success');
//         } else {
//           alert('テンプレート名が無効です。');
//         }
//       },
//       error: (error) => {
//         console.error('CSVのパースに失敗しました:', error);
//         alert('CSVファイルの読み込みに失敗しました。');
//       },
//     });
//   };

//   // ユーザーテンプレートを保存
//   const saveUserTemplate = async (templateName, columns) => {
//     if (!user) {
//       alert('ユーザーが認証されていません。再度ログインしてください。');
//       return;
//     }

//     try {
//       const userTemplatesRef = doc(db, 'userTemplates', user.uid);
//       const userTemplatesSnap = await getDoc(userTemplatesRef);

//       const newTemplate = { name: templateName, columns };

//       if (userTemplatesSnap.exists()) {
//         const data = userTemplatesSnap.data();
//         const existingTemplates = data.templates || [];
//         // 同名のテンプレートが存在するか確認
//         const duplicate = existingTemplates.find((t) => t.name === templateName);
//         if (duplicate) {
//           alert('同じ名前のテンプレートが既に存在します。別の名前を使用してください。');
//           return;
//         }
//         // 既存のテンプレートに追加
//         await updateDoc(userTemplatesRef, {
//           templates: arrayUnion(newTemplate),
//         });
//         setUserTemplates([...existingTemplates, newTemplate]);
//       } else {
//         // 新しいドキュメントを作成
//         await setDoc(userTemplatesRef, {
//           templates: [newTemplate],
//         });
//         setUserTemplates([newTemplate]);
//       }
//     } catch (error) {
//       console.error('テンプレートの保存に失敗しました:', error);
//       alert('テンプレートの保存に失敗しました。');
//     }
//   };

//   return (
//     <Box sx={{ display: 'flex' }}>
//       <CssBaseline />
//       {/* AppBar */}
//       <AppBar
//         position="fixed"
//         sx={{
//           zIndex: (theme) => theme.zIndex.drawer + 1,
//           transition: (theme) =>
//             theme.transitions.create(['width', 'margin'], {
//               easing: theme.transitions.easing.sharp,
//               duration: theme.transitions.duration.leavingScreen,
//             }),
//           ...(isSidebarOpen && {
//             marginLeft: drawerWidth,
//             width: `calc(100% - ${drawerWidth}px)`,
//           }),
//         }}
//       >
//         <Toolbar>
//           {/* ホーム画面へのリンクを追加 */}
//           <IconButton
//             color="inherit"
//             aria-label="go to home"
//             onClick={handleGoHome}
//             edge="start"
//             sx={{
//               marginRight: 2,
//             }}
//           >
//             <HomeIcon />
//           </IconButton>

//           <IconButton
//             color="inherit"
//             aria-label="open drawer"
//             onClick={() => setIsSidebarOpen(!isSidebarOpen)}
//             edge="start"
//             sx={{
//               marginRight: 2,
//             }}
//           >
//             {isSidebarOpen ? <CloseIcon /> : <MenuIcon />}
//           </IconButton>
//           <Typography variant="h6" noWrap component="div">
//             0から一括出品
//           </Typography>
//         </Toolbar>
//       </AppBar>

//       {/* サイドバー */}
//       <Drawer
//         variant="persistent"
//         anchor="left"
//         open={isSidebarOpen}
//         sx={{
//           width: drawerWidth,
//           flexShrink: 0,
//           '& .MuiDrawer-paper': {
//             width: drawerWidth,
//             boxSizing: 'border-box',
//           },
//         }}
//       >
//         <Toolbar />
//         <Box sx={{ overflow: 'auto' }}>
//           <List>
//             <ListItem button onClick={() => handleSectionChange('main')}>
//               <ListItemText primary="メイン" />
//             </ListItem>
//             <ListItem button onClick={() => handleSectionChange('priceManager')}>
//               <ListItemText primary="価格管理" />
//             </ListItem>
//             <ListItem button onClick={() => handleSectionChange('itemSpecifics')}>
//               <ListItemText primary="Item Specifics管理" />
//             </ListItem>
//             <ListItem button onClick={() => handleSectionChange('templateManager')}>
//               <ListItemText primary="テンプレート管理" />
//             </ListItem>
//             <ListItem button onClick={() => handleSectionChange('titleGeneration')}>
//               <ListItemText primary="AIタイトル生成" />
//             </ListItem>
//             <ListItem button onClick={() => handleSectionChange('descriptionGeneration')}>
//               <ListItemText primary="AI商品説明生成" />
//             </ListItem>
//             <ListItem button onClick={() => handleSectionChange('apiKeyInput')}>
//               <ListItemText primary="APIキー入力" />
//             </ListItem>
//             <ListItem button onClick={() => handleSectionChange('userTemplateManager')}>
//               <ListItemText primary="ユーザーテンプレート管理" />
//             </ListItem>
//           </List>
//         </Box>
//       </Drawer>

//       {/* メインコンテンツ */}
//       <Main open={isSidebarOpen}>
//         <Toolbar />
//         {/* APIキーが未設定の場合に警告を表示 */}
//         {!apiKey && (
//           <Alert severity="warning" sx={{ mb: 2 }}>
//             OpenAI APIキーが設定されていません。左のサイドバーから「APIキー入力」を選択し、APIキーを入力してください。
//           </Alert>
//         )}
//         {activeSection === 'main' && (
//           <Box>
//             <FormControl fullWidth sx={{ mb: 2 }}>
//               <InputLabel id="template-select-label">カテゴリーテンプレートを選択</InputLabel>
//               <Select
//                 labelId="template-select-label"
//                 value={selectedTemplate}
//                 label="カテゴリーテンプレートを選択"
//                 onChange={handleTemplateChange}
//               >
//                 <MenuItem value="">
//                   <em>テンプレートを選択してください</em>
//                 </MenuItem>
//                 {/* 既存のテンプレート */}
//                 {Object.keys(categoryTemplates).map((templateName) => (
//                   <MenuItem key={templateName} value={templateName}>
//                     {templateName}
//                   </MenuItem>
//                 ))}
//                 {/* ユーザーのテンプレート */}
//                 {userTemplates.map((template) => (
//                   <MenuItem key={template.name} value={`user-${template.name}`}>
//                     {template.name}
//                   </MenuItem>
//                 ))}
//               </Select>
//             </FormControl>

//             <Grid container spacing={2} sx={{ mb: 2 }}>
//               <Grid item xs={12} sm={6} md={4}>
//                 <Button
//                   variant="contained"
//                   color="primary"
//                   onClick={openCSVUploadDialog}
//                 >
//                   CSVファイルからテンプレートを作成
//                 </Button>
//               </Grid>
//             </Grid>

//             {/* CSVアップロードダイアログ */}
//             <Dialog open={isCSVUploadDialogOpen} onClose={() => setIsCSVUploadDialogOpen(false)}>
//               <DialogTitle>CSVファイルをアップロード</DialogTitle>
//               <DialogContent>
//                 <input
//                   type="file"
//                   accept=".csv"
//                   onChange={handleCSVFileChange}
//                 />
//               </DialogContent>
//               <DialogActions>
//                 <Button onClick={() => setIsCSVUploadDialogOpen(false)}>キャンセル</Button>
//                 <Button onClick={handleCSVUpload} disabled={!csvFile}>
//                   アップロード
//                 </Button>
//               </DialogActions>
//             </Dialog>

//             {selectedTemplate && (
//               <>
//                 <Grid container spacing={2}>
//                   <Grid item xs={12} sm={6} md={4}>
//                     <Button
//                       variant="contained"
//                       color="primary"
//                       onClick={openImageUploadDialog}
//                     >
//                       画像をアップロード
//                     </Button>
//                   </Grid>
//                   <Grid item xs={12} sm={6} md={4}>
//                     <Button
//                       variant="contained"
//                       color="primary"
//                       onClick={fetchLatestUploadedImages}
//                     >
//                       最新のアップロード画像を取得
//                     </Button>
//                   </Grid>
//                 </Grid>

//                 <Dialog open={isImageUploadDialogOpen} onClose={() => setIsImageUploadDialogOpen(false)}>
//                   <DialogTitle>画像をアップロード</DialogTitle>
//                   <DialogContent>
//                     <input
//                       type="file"
//                       accept="image/*"
//                       multiple
//                       onChange={handleImageFileChange}
//                     />
//                   </DialogContent>
//                   <DialogActions>
//                     <Button onClick={() => setIsImageUploadDialogOpen(false)}>キャンセル</Button>
//                     <Button onClick={handleImageUpload} disabled={isUploading}>
//                       アップロード
//                     </Button>
//                   </DialogActions>
//                 </Dialog>

//                 {isUploading && (
//                   <LinearProgress sx={{ mt: 2, mb: 2 }} />
//                 )}

//                 {uploadedImages.length > 0 && (
//                   <>
//                     <Typography variant="h6" sx={{ mt: 4 }}>
//                       アップロードされた画像
//                     </Typography>
//                     <Typography variant="body2" sx={{ mb: 2 }}>
//                       画像をドラッグ＆ドロップで並び替え、区切りを挿入して商品を分けることができます。
//                     </Typography>
//                     <DragDropContext onDragEnd={onDragEnd}>
//                       <Droppable droppableId="uploadedImages" direction="horizontal">
//                         {(provided) => (
//                           <Box
//                             sx={{
//                               display: 'flex',
//                               flexWrap: 'wrap',
//                               p: 1,
//                               border: '1px solid #ccc',
//                               borderRadius: '4px',
//                             }}
//                             {...provided.droppableProps}
//                             ref={provided.innerRef}
//                           >
//                             {uploadedImages.map((img, index) => (
//                               <Draggable key={img.id} draggableId={img.id} index={index}>
//                                 {(provided) => (
//                                   <Box
//                                     sx={{
//                                       position: 'relative',
//                                       m: 0.5,
//                                       width: '120px',
//                                       height: '120px',
//                                       display: 'flex',
//                                       alignItems: 'center',
//                                       justifyContent: 'center',
//                                       backgroundColor: img.type === 'separator' ? '#f0f0f0' : 'transparent',
//                                       border: img.type === 'separator' ? '2px dashed #ccc' : 'none',
//                                     }}
//                                     ref={provided.innerRef}
//                                     {...provided.draggableProps}
//                                     {...provided.dragHandleProps}
//                                   >
//                                     {img.type === 'separator' ? (
//                                       <>
//                                         <Typography variant="body2">区切り</Typography>
//                                         <IconButton
//                                           size="small"
//                                           sx={{
//                                             position: 'absolute',
//                                             top: '4px',
//                                             right: '4px',
//                                             color: 'red',
//                                             backgroundColor: 'white',
//                                             '&:hover': {
//                                               backgroundColor: 'white',
//                                             },
//                                           }}
//                                           onClick={() => removeSeparator(img.id)}
//                                         >
//                                           <DeleteIcon fontSize="small" />
//                                         </IconButton>
//                                       </>
//                                     ) : (
//                                       <>
//                                         <img
//                                           src={img.url}
//                                           alt={`Uploaded ${index}`}
//                                           style={{ width: '100%', height: '100%', objectFit: 'cover' }}
//                                         />
//                                         <IconButton
//                                           size="small"
//                                           sx={{
//                                             position: 'absolute',
//                                             top: '4px',
//                                             right: '4px',
//                                             color: 'red',
//                                             backgroundColor: 'white',
//                                             '&:hover': {
//                                               backgroundColor: 'white',
//                                             },
//                                           }}
//                                           onClick={() => handleDeleteUploadedImage(img.id)}
//                                         >
//                                           <DeleteIcon fontSize="small" />
//                                         </IconButton>
//                                         {/* 区切りを挿入するボタン */}
//                                         {img.type !== 'separator' && (
//                                           <IconButton
//                                             size="small"
//                                             sx={{
//                                               position: 'absolute',
//                                               bottom: '4px',
//                                               right: '4px',
//                                               backgroundColor: 'white',
//                                               '&:hover': {
//                                                 backgroundColor: 'white',
//                                               },
//                                             }}
//                                             onClick={() => addSeparator(index)}
//                                           >
//                                             <AddIcon fontSize="small" />
//                                           </IconButton>
//                                         )}
//                                       </>
//                                     )}
//                                   </Box>
//                                 )}
//                               </Draggable>
//                             ))}
//                             {provided.placeholder}
//                           </Box>
//                         )}
//                       </Droppable>
//                     </DragDropContext>

//                     <Grid container spacing={2} sx={{ mt: 2 }}>
//                       <Grid item xs={12} sm={6} md={4}>
//                         <Button
//                           variant="contained"
//                           color="secondary"
//                           onClick={handleGroupImages}
//                           disabled={isGrouping}
//                         >
//                           画像を商品に割り当てる
//                         </Button>
//                       </Grid>
//                     </Grid>
//                   </>
//                 )}

//                 {/* ImageOperationsコンポーネントを追加 */}
//                 <Box sx={{ mt: 4 }}>
//                   <ImageOperations
//                     data={data}
//                     setData={setData}
//                     startRow={startRow}
//                     endRow={endRow}
//                     calculateMaxEndRow={calculateMaxEndRow}
//                     isProcessing={isProcessing}
//                     setIsProcessing={setIsProcessing}
//                     setError={setError}
//                     showSnackbar={showSnackbar}
//                     openPreviewDialog={openPreviewDialog}
//                     setOpenPreviewDialog={setOpenPreviewDialog}
//                     apiKey={apiKey} // OpenAIのAPIキー
//                     previewDialogMode={previewDialogMode}
//                     setPreviewDialogMode={setPreviewDialogMode}
//                   />
//                 </Box>
//               </>
//             )}

//             {isGrouping && (
//               <LinearProgress sx={{ mt: 2, mb: 2 }} />
//             )}

//             {data.length > 0 && (
//               <>
//                 {/* EditableSpreadsheetを常に表示 */}
//                 <Paper elevation={3} sx={{ p: 2, mt: 4 }}>
//                   <EditableSpreadsheet
//                     data={data}
//                     setData={setData}
//                     columns={columns}
//                     setColumns={setColumns}
//                   />
//                 </Paper>

//                 {/* Snackbar */}
//                 <Snackbar
//                   open={snackbar.open}
//                   autoHideDuration={6000}
//                   onClose={handleSnackbarClose}
//                 >
//                   <Alert
//                     onClose={handleSnackbarClose}
//                     severity={snackbar.severity}
//                     sx={{ width: '100%' }}
//                   >
//                     {snackbar.message}
//                   </Alert>
//                 </Snackbar>
//               </>
//             )}
//           </Box>
//         )}

//         {activeSection === 'priceManager' && (
//           <Box>
//             <PriceManager data={data} setData={setData} />
//           </Box>
//         )}

//         {activeSection === 'itemSpecifics' && (
//           <Box>
//             <ItemSpecificsManager
//               userId={user ? user.uid : null}
//               headers={columns}
//               data={data}
//               setData={setData}
//             />
//           </Box>
//         )}

//         {activeSection === 'templateManager' && (
//           <Box>
//             <ColumnNameBasedTemplateManager
//               data={data}
//               setData={setData}
//               columns={columns}
//               setColumns={setColumns}
//             />
//           </Box>
//         )}

//         {activeSection === 'titleGeneration' && (
//           <Box>
//             <TitleGenerationComponent
//               data={data}
//               setData={setData}
//               apiKey={apiKey}
//               user={user}
//             />
//           </Box>
//         )}

//         {activeSection === 'descriptionGeneration' && (
//           <Box>
//             <OpenAIDescriptionGenerator
//               data={data}
//               setData={setData}
//               apiKey={apiKey}
//               user={user}
//             />
//           </Box>
//         )}

//         {activeSection === 'apiKeyInput' && (
//           <Box>
//             <APIKeyInput user={user} apiKey={apiKey} setApiKey={setApiKey} />
//           </Box>
//         )}

//         {activeSection === 'userTemplateManager' && (
//           <Box>
//             <UserTemplateManager
//               userTemplates={userTemplates}
//               setUserTemplates={setUserTemplates}
//               user={user}
//             />
//           </Box>
//         )}
//       </Main>
//     </Box>
//   );
// }

// export default BulkListingFromScratch;
