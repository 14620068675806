// LowCostTitleGenerator.js

import axios from 'axios';
import PQueue from 'p-queue';

const BATCH_SIZE = 5;
const INITIAL_RETRY_DELAY = 1000;
const MAX_RETRIES = 5;

// generateProductTitles 関数をコンポーネントの外側で定義し、名前付きエクスポート
export const generateProductTitles = async ({
  products,
  setProducts,
  apiKey,
  setSnackbarMessage,
  setSnackbarSeverity,
  setSnackbarOpen,
  setError,
  setMessage,
  setProgress,
  queue,
}) => {
  let processedCount = 0;

  const selectedProductsData = products.filter((product) => product.selected && !product.generatedTitle);

  if (selectedProductsData.length === 0) {
    setMessage('選択された商品はすでに更新されています');
    setSnackbarMessage('選択された商品はすでに更新されています');
    setSnackbarSeverity('info');
    setSnackbarOpen(true);
    return;
  }

  for (let i = 0; i < selectedProductsData.length; i += BATCH_SIZE) {
    const batch = selectedProductsData.slice(i, i + BATCH_SIZE);
    try {
      const updatedBatch = await processBatch(batch);
      setProducts((prevProducts) => {
        const newProducts = [...prevProducts];
        updatedBatch.forEach((updatedProduct) => {
          const index = newProducts.findIndex((p) => p.id === updatedProduct.id);
          if (index !== -1) {
            newProducts[index] = updatedProduct;
          }
        });
        return newProducts;
      });
      processedCount += batch.length;
      setProgress((processedCount / selectedProductsData.length) * 100);
    } catch (err) {
      console.error('バッチ処理エラー:', err);
      setError(`バッチ処理に失敗しました: ${err.message}`);
      setSnackbarMessage(`エラー: ${err.message}`);
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
      throw err; // エラーが発生した場合は処理を中断
    }
  }

  setMessage('低コストAIでのタイトル生成が完了しました');
  setSnackbarMessage('低コストAIでのタイトル生成が完了しました');
  setSnackbarSeverity('success');
  setSnackbarOpen(true);

  // 内部関数: バッチ処理
  async function processBatch(batch) {
    return await Promise.all(
      batch.map(async (product) => {
        let generatedTitle;

        // APIコールを行い、タイトルを生成
        generatedTitle = await generateTitleWithRetry(product.title, product.editableJpDesc);

        return {
          ...product,
          generatedTitle,
        };
      })
    );
  }

  // 内部関数: リトライ付きのタイトル生成
  async function generateTitleWithRetry(originalTitle, jpDesc, retryCount = 0) {
    try {
      return await queue.add(() => generateTitle(originalTitle, jpDesc));
    } catch (error) {
      if (error.response && error.response.status === 429 && retryCount < MAX_RETRIES) {
        const retryAfter = error.response.headers['retry-after'];
        const delay = retryAfter
          ? parseInt(retryAfter) * 1000
          : INITIAL_RETRY_DELAY * Math.pow(2, retryCount);
        const jitter = Math.random() * 1000;
        await new Promise((resolve) => setTimeout(resolve, delay + jitter));
        return generateTitleWithRetry(originalTitle, jpDesc, retryCount + 1);
      }
      throw error;
    }
  }

  // 内部関数: タイトル生成
  async function generateTitle(originalTitle, jpDesc) {
    try {
      const url = 'https://api.openai.com/v1/chat/completions';
      const limitedJpDesc = jpDesc.slice(0, 150);
      const requestData = {
        model: 'gpt-4o-mini', // 低コストのモデルを使用
        messages: [
          {
            role: 'system',
            content: `You are an AI assistant specializing in eBay product title optimization. Based on the given original title and Japanese product information, create an effective English title within 80 characters.
Order of title composition:
1. brand name (if applicable)
2. model name or product name/character name (if applicable)
3. product type (if applicable)
4. key features (color, material, size) (if applicable)
5. distinctive elements or uniqueness (if applicable)
6. condition (new/used) (if applicable)
7. important keywords (if applicable)
NOTES:
- Add or optimize new information while retaining important information from the original title
- Avoid unnecessary adjectives and words such as “eBay
- Use abbreviations and common names appropriately
- Think from the searcher's perspective and include search terms that buyers are likely to use
- If information is unclear or ambiguous, omit it.
- Use only reliable information and do not include guesswork or uncertain information.
- Do not extract from Japanese descriptions that are a list of words.
- Titles should be generated in English and should not exceed 80 characters.`,
          },
          {
            role: 'user',
            content: `元のタイトル：「${originalTitle}」
日本語の商品説明：「${limitedJpDesc}」
この情報を基に、最適化された新しいeBayの商品タイトルを生成してください。また日本語の商品説明が空の場合は元のタイトルから生成してください`,
          },
        ],
        max_tokens: 100,
      };

      const response = await axios.post(url, requestData, {
        headers: { Authorization: `Bearer ${apiKey}` },
      });

      const generatedTitle = response.data.choices[0].message.content.trim();
      return generatedTitle;
    } catch (error) {
      throw error;
    }
  }
};

export default {};
