// OptionsPanel.js

import React, { useState } from 'react';
import {
  Typography,
  Grid,
  FormControlLabel,
  Checkbox,
  TextField,
  Button,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  Collapse,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { presetFilters } from './filters';

const OptionsPanel = ({
  // フィルタリングオプションのプロップス
  selectedFilters,
  handleFilterChange,
  customFilterInput,
  setCustomFilterInput,
  customFilters,
  handleAddCustomFilter,
  handleDeleteCustomFilter,
  // テキスト変換のプロップス
  sentencesToDelete,
  setSentencesToDelete,
  wordsToReplace,
  setWordsToReplace,
}) => {
  // 各フィルターの詳細表示用のステート
  const [openFilterDetails, setOpenFilterDetails] = useState({});

  const toggleFilterDetails = (label) => {
    setOpenFilterDetails((prev) => ({
      ...prev,
      [label]: !prev[label],
    }));
  };

  return (
    <Grid container spacing={2}>
      {/* フィルタリングオプション */}
      <Grid item xs={12}>
        <Typography variant="h6">フィルタリングオプション</Typography>
        {presetFilters.map((filter, index) => (
          <div key={index}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={selectedFilters.includes(filter.label)}
                  onChange={() => handleFilterChange(filter.label)}
                />
              }
              label={
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  {filter.label}
                  <IconButton
                    size="small"
                    onClick={(e) => {
                      e.stopPropagation();
                      toggleFilterDetails(filter.label);
                    }}
                  >
                    {openFilterDetails[filter.label] ? (
                      <ExpandLessIcon fontSize="small" />
                    ) : (
                      <ExpandMoreIcon fontSize="small" />
                    )}
                  </IconButton>
                </div>
              }
            />
            <Collapse in={openFilterDetails[filter.label]} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                {filter.patterns.map((pattern, idx) => (
                  <ListItem key={idx} dense>
                    <ListItemText primary={`• ${pattern}`} />
                  </ListItem>
                ))}
              </List>
            </Collapse>
          </div>
        ))}
      </Grid>
      {/* カスタムフィルターの入力フィールド */}
      <Grid item xs={12}>
        <TextField
          label="カスタムフィルターを追加"
          type="text"
          fullWidth
          value={customFilterInput}
          onChange={(e) => setCustomFilterInput(e.target.value)}
          variant="outlined"
        />
        <Button
          variant="contained"
          color="primary"
          onClick={handleAddCustomFilter}
          disabled={!customFilterInput.trim()}
          sx={{ mt: 1 }}
        >
          フィルターを追加
        </Button>
      </Grid>
      {/* カスタムフィルター一覧 */}
      {customFilters.length > 0 && (
        <Grid item xs={12}>
          <Typography variant="subtitle1">カスタムフィルター一覧</Typography>
          <List>
            {customFilters.map((filter, index) => (
              <ListItem key={index} dense>
                <ListItemText primary={filter} />
                <ListItemSecondaryAction>
                  <IconButton edge="end" onClick={() => handleDeleteCustomFilter(index)}>
                    <DeleteIcon />
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>
            ))}
          </List>
        </Grid>
      )}
      {/* テキスト変換 */}
      <Grid item xs={12}>
        <Typography variant="h6" sx={{ mt: 2 }}>
          生成された文章のカスタマイズ
        </Typography>
      </Grid>
      {/* 指定した文章の削除 */}
      <Grid item xs={12}>
        <Typography variant="subtitle1">削除したい文章（1行に1つ）</Typography>
        <TextField
          multiline
          rows={4}
          fullWidth
          variant="outlined"
          placeholder="削除したい文章を入力..."
          value={sentencesToDelete.join('\n')}
          onChange={(e) => setSentencesToDelete(e.target.value.split('\n'))}
        />
      </Grid>
      {/* 指定したワードの置換 */}
      <Grid item xs={12}>
        <Typography variant="subtitle1">置換したいワード</Typography>
        {wordsToReplace.map((item, index) => (
          <Grid container spacing={1} key={index} alignItems="center">
            <Grid item xs={5}>
              <TextField
                fullWidth
                variant="outlined"
                placeholder="置換前"
                value={item.from}
                onChange={(e) => {
                  const newWordsToReplace = [...wordsToReplace];
                  newWordsToReplace[index].from = e.target.value;
                  setWordsToReplace(newWordsToReplace);
                }}
              />
            </Grid>
            <Grid item xs={5}>
              <TextField
                fullWidth
                variant="outlined"
                placeholder="置換後"
                value={item.to}
                onChange={(e) => {
                  const newWordsToReplace = [...wordsToReplace];
                  newWordsToReplace[index].to = e.target.value;
                  setWordsToReplace(newWordsToReplace);
                }}
              />
            </Grid>
            <Grid item xs={2}>
              <IconButton
                onClick={() => {
                  const newWordsToReplace = [...wordsToReplace];
                  newWordsToReplace.splice(index, 1);
                  setWordsToReplace(newWordsToReplace);
                }}
              >
                <DeleteIcon />
              </IconButton>
            </Grid>
          </Grid>
        ))}
        <Button
          variant="outlined"
          startIcon={<AddIcon />}
          onClick={() => setWordsToReplace([...wordsToReplace, { from: '', to: '' }])}
          sx={{ mt: 1 }}
        >
          置換ペアを追加
        </Button>
      </Grid>
    </Grid>
  );
};

export default OptionsPanel;
