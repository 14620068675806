// DescriptionCustomPrompt.js

import React, { useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Grid,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  LinearProgress,
  Typography,
} from '@mui/material';
import axios from 'axios';

const BATCH_SIZE = 5;
const INITIAL_RETRY_DELAY = 1000;
const MAX_RETRIES = 5;

const DescriptionCustomPrompt = ({
  open,
  handleClose,
  products,
  setProducts,
  selectedProducts,
  apiKey,
  setSnackbarMessage,
  setSnackbarSeverity,
  setSnackbarOpen,
  loading,
  setLoading,
  setError,
  setMessage,
  setProgress,
  queue,
  selectedTemplate,
  designTemplates,
  applyCustomTransformations,
  sanitizeDescription,
}) => {
  const [customPrompt, setCustomPrompt] = useState('');
  const [customOptions, setCustomOptions] = useState({
    maxTokens: 200,
    temperature: 0.1,
    top_p: 1,
    frequency_penalty: 0,
    presence_penalty: 0,
    model: 'gpt-4',
    language: '英語',
    style: '最適化された',
    additionalInstructions: '',
    systemMessageTemplate: `You are eBay's AI assistant specializing in product description optimization.`,
    userMessageTemplate: `以下の日本語の商品説明を分析し、eBayの商品説明として{{style}}{{language}}の説明を生成してください。出力は{{maxTokens}}トークン以内に収めてください：「{{jpDesc}}」`,
    maxJpDescLength: 200,
  });

  const [cache, setCache] = useState({});

  const handleGenerateWithCustomPrompt = async () => {
    if (!apiKey) {
      setError('OpenAI APIキーが設定されていません');
      setSnackbarMessage('OpenAI APIキーが必要です');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
      return;
    }

    if (selectedProducts.length === 0) {
      setError('少なくとも一つの商品を選択してください');
      setSnackbarMessage('商品が選択されていません');
      setSnackbarSeverity('warning');
      setSnackbarOpen(true);
      return;
    }

    if (!customPrompt.trim()) {
      setError('カスタムプロンプトが入力されていません');
      setSnackbarMessage('カスタムプロンプトを入力してください');
      setSnackbarSeverity('warning');
      setSnackbarOpen(true);
      return;
    }

    setLoading(true);
    setError('');
    setMessage('');
    setProgress(0);

    const selectedProductsData = products.filter((product) =>
      selectedProducts.includes(product.id)
    );
    let processedCount = 0;

    try {
      for (let i = 0; i < selectedProductsData.length; i += BATCH_SIZE) {
        const batch = selectedProductsData.slice(i, i + BATCH_SIZE);
        const updatedBatch = await processBatchWithCustomPrompt(
          batch,
          customPrompt,
          customOptions
        );
        setProducts((prevProducts) => {
          const newProducts = [...prevProducts];
          updatedBatch.forEach((updatedProduct) => {
            const index = newProducts.findIndex((p) => p.id === updatedProduct.id);
            if (index !== -1) {
              newProducts[index] = updatedProduct;
            }
          });
          return newProducts;
        });
        processedCount += batch.length;
        setProgress((processedCount / selectedProductsData.length) * 100);
      }

      setMessage('カスタムプロンプトによる商品説明の生成が完了しました');
      setSnackbarMessage('カスタムプロンプトによる商品説明の生成が完了しました');
      setSnackbarSeverity('success');
      setSnackbarOpen(true);
      handleClose();
    } catch (err) {
      console.error('カスタムプロンプトバッチ処理エラー:', err);
      setError(`カスタムプロンプトバッチ処理に失敗しました: ${err.message}`);
      setSnackbarMessage(`エラー: ${err.message}`);
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    } finally {
      setLoading(false);
    }
  };

  const processBatchWithCustomPrompt = async (batch, prompt, options) => {
    return await Promise.all(
      batch.map(async (product) => {
        let generatedFeatures;
        const jpDescToUse = product.editableJpDesc || product.jpDesc;
        if (cache[jpDescToUse]) {
          generatedFeatures = cache[jpDescToUse];
        } else {
          generatedFeatures = await generateFeaturesWithRetryCustomPrompt(
            jpDescToUse,
            prompt,
            options
          );
          setCache((prevCache) => ({ ...prevCache, [jpDescToUse]: generatedFeatures }));
        }

        const transformedFeatures = applyCustomTransformations(generatedFeatures);

        const sanitizedDescription = sanitizeDescription(product.description);

        const updatedDescription = generateFormattedDescription(
          product.title,
          transformedFeatures,
          sanitizedDescription,
          product.editableFields
        );

        return {
          ...product,
          generatedFeatures,
          editableGeneratedFeatures: transformedFeatures,
          updatedDescription,
        };
      })
    );
  };

  const generateFeaturesWithRetryCustomPrompt = async (
    jpDesc,
    prompt,
    options,
    retryCount = 0
  ) => {
    try {
      return await queue.add(() => generateFeaturesCustomPrompt(jpDesc, prompt, options));
    } catch (error) {
      if (error.response && error.response.status === 429 && retryCount < MAX_RETRIES) {
        const retryAfter = error.response.headers['retry-after'];
        const delay = retryAfter
          ? parseInt(retryAfter) * 1000
          : INITIAL_RETRY_DELAY * Math.pow(2, retryCount);
        const jitter = Math.random() * 1000;
        await new Promise((resolve) => setTimeout(resolve, delay + jitter));
        return generateFeaturesWithRetryCustomPrompt(
          jpDesc,
          prompt,
          options,
          retryCount + 1
        );
      }
      throw error;
    }
  };

  const generateFeaturesCustomPrompt = async (jpDesc, prompt, options) => {
    const {
      maxTokens = 200,
      temperature = 0.1,
      top_p = 1,
      frequency_penalty = 0,
      presence_penalty = 0,
      model = 'gpt-4',
      language = '英語',
      style = '最適化された',
      additionalInstructions = '',
      systemMessageTemplate = `You are eBay's AI assistant specializing in product description optimization.`,
      userMessageTemplate = `以下の日本語の商品説明を分析し、eBayの商品説明として{{style}}{{language}}の説明を生成してください。出力は{{maxTokens}}トークン以内に収めてください：「{{jpDesc}}」`,
      maxJpDescLength = 200,
    } = options;

    const systemMessage = `${systemMessageTemplate} ${prompt} ${additionalInstructions}`;

    let truncatedJpDesc = jpDesc;
    if (maxJpDescLength > 0) {
      truncatedJpDesc = jpDesc.substring(0, maxJpDescLength);
    }

    const userMessage = userMessageTemplate
      .replace('{{style}}', style)
      .replace('{{language}}', language)
      .replace('{{maxTokens}}', maxTokens)
      .replace('{{jpDesc}}', truncatedJpDesc);

    const response = await axios.post(
      'https://api.openai.com/v1/chat/completions',
      {
        model: model,
        messages: [
          {
            role: 'system',
            content: systemMessage,
          },
          {
            role: 'user',
            content: userMessage,
          },
        ],
        max_tokens: maxTokens,
        temperature: temperature,
        top_p: top_p,
        frequency_penalty: frequency_penalty,
        presence_penalty: presence_penalty,
      },
      {
        headers: {
          Authorization: `Bearer ${apiKey}`,
          'Content-Type': 'application/json',
        },
      }
    );

    return response.data.choices[0].message.content.trim();
  };

  const generateFormattedDescription = (title, features, description, specifications) => {
    const template = designTemplates[selectedTemplate];
    const html = template.generateHTML(title, features, description, specifications);

    // 生成されたHTMLをクリーンアップ
    const cleanedHtml = cleanHTML(html);

    return cleanedHtml;
  };

  const cleanHTML = (html) => {
    // <style>タグを削除
    let cleanedHtml = html.replace(/<style[^>]*>[\s\S]*?<\/style>/gi, '');

    // class属性を削除
    cleanedHtml = cleanedHtml.replace(/\sclass="[^"]*"/gi, '');

    // 不要な空白を削除
    cleanedHtml = cleanedHtml.replace(/\s+/g, ' ').trim();

    // タグ間の空白を削除
    cleanedHtml = cleanedHtml.replace(/>\s+</g, '><');

    return cleanedHtml;
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      fullScreen
      aria-labelledby="custom-prompt-dialog-title"
    >
      <DialogTitle id="custom-prompt-dialog-title">カスタムプロンプトで商品説明を生成</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              autoFocus
              label="カスタムプロンプト"
              type="text"
              fullWidth
              multiline
              rows={4}
              value={customPrompt}
              onChange={(e) => setCustomPrompt(e.target.value)}
              placeholder="ここにカスタムプロンプトを入力してください..."
              variant="outlined"
            />
          </Grid>
          {/* その他のオプションフィールド */}
          <Grid item xs={12} sm={6}>
            <TextField
              label="JP説明の最大文字数"
              type="number"
              fullWidth
              value={customOptions.maxJpDescLength}
              onChange={(e) =>
                setCustomOptions({ ...customOptions, maxJpDescLength: parseInt(e.target.value) })
              }
              variant="outlined"
              InputProps={{ inputProps: { min: 1 } }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Maximum Tokens"
              type="number"
              fullWidth
              value={customOptions.maxTokens}
              onChange={(e) =>
                setCustomOptions({ ...customOptions, maxTokens: parseInt(e.target.value) })
              }
              variant="outlined"
              InputProps={{ inputProps: { min: 1 } }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Temperature"
              type="number"
              fullWidth
              value={customOptions.temperature}
              onChange={(e) =>
                setCustomOptions({ ...customOptions, temperature: parseFloat(e.target.value) })
              }
              variant="outlined"
              InputProps={{ inputProps: { min: 0, max: 1, step: 0.01 } }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Top_p"
              type="number"
              fullWidth
              value={customOptions.top_p}
              onChange={(e) =>
                setCustomOptions({ ...customOptions, top_p: parseFloat(e.target.value) })
              }
              variant="outlined"
              InputProps={{ inputProps: { min: 0, max: 1, step: 0.01 } }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth variant="outlined">
              <InputLabel>モデルを選択</InputLabel>
              <Select
                value={customOptions.model}
                onChange={(e) => setCustomOptions({ ...customOptions, model: e.target.value })}
                label="モデルを選択"
              >
                <MenuItem value="gpt-4o-mini">GPT-4o-mini</MenuItem>
                <MenuItem value="gpt-4o-2024-08-06">gpt-4o-2024-08-06</MenuItem>
                <MenuItem value="o1-mini">o1-mini</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="言語"
              type="text"
              fullWidth
              value={customOptions.language}
              onChange={(e) => setCustomOptions({ ...customOptions, language: e.target.value })}
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="スタイル"
              type="text"
              fullWidth
              value={customOptions.style}
              onChange={(e) => setCustomOptions({ ...customOptions, style: e.target.value })}
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="追加の指示"
              type="text"
              fullWidth
              multiline
              rows={2}
              value={customOptions.additionalInstructions}
              onChange={(e) =>
                setCustomOptions({ ...customOptions, additionalInstructions: e.target.value })
              }
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="システムメッセージテンプレート"
              type="text"
              fullWidth
              multiline
              rows={3}
              value={customOptions.systemMessageTemplate}
              onChange={(e) =>
                setCustomOptions({ ...customOptions, systemMessageTemplate: e.target.value })
              }
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="ユーザーメッセージテンプレート"
              type="text"
              fullWidth
              multiline
              rows={3}
              value={customOptions.userMessageTemplate}
              onChange={(e) =>
                setCustomOptions({ ...customOptions, userMessageTemplate: e.target.value })
              }
              variant="outlined"
            />
          </Grid>
          {/* 進捗状況表示 */}
          {loading && (
            <Grid item xs={12}>
              <LinearProgress variant="determinate" value={setProgress} />
              <Typography variant="body2" color="text.secondary" align="center">
                {`${Math.round(setProgress)}%`}
              </Typography>
            </Grid>
          )}
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>キャンセル</Button>
        <Button
          onClick={handleGenerateWithCustomPrompt}
          variant="contained"
          color="primary"
          disabled={!customPrompt.trim() || loading}
        >
          生成
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DescriptionCustomPrompt;




// // DescriptionCustomPrompt.js

// import React, { useState, useEffect, useCallback, useMemo } from 'react';
// import {
//   Dialog,
//   DialogTitle,
//   DialogContent,
//   DialogActions,
//   Button,
//   Grid,
//   TextField,
//   Select,
//   MenuItem,
//   FormControl,
//   InputLabel,
//   LinearProgress,
//   Typography,
// } from '@mui/material';
// import axios from 'axios';
// import PQueue from 'p-queue';

// const BATCH_SIZE = 5;
// const INITIAL_RETRY_DELAY = 1000;
// const MAX_RETRIES = 5;
// const CONCURRENCY = 2;
// const INTERVAL = 1000;
// const INTERVAL_CAP = 5;

// const DescriptionCustomPrompt = ({
//   open,
//   handleClose,
//   products,
//   setProducts,
//   selectedProducts,
//   apiKey,
//   setSnackbarMessage,
//   setSnackbarSeverity,
//   setSnackbarOpen,
//   setLoading,
//   setError,
//   setMessage,
//   setProgress,
//   queue,
//   selectedTemplate,
//   designTemplates,
//   applyCustomTransformations,
//   sanitizeDescription,
// }) => {
//   const [customPrompt, setCustomPrompt] = useState('');
//   const [customOptions, setCustomOptions] = useState({
//     maxTokens: 200,
//     temperature: 0.1,
//     top_p: 1,
//     frequency_penalty: 0,
//     presence_penalty: 0,
//     model: 'gpt-4',
//     language: '英語',
//     style: '最適化された',
//     additionalInstructions: '',
//     systemMessageTemplate: `You are eBay's AI assistant specializing in product description optimization.`,
//     userMessageTemplate: `以下の日本語の商品説明を分析し、eBayの商品説明として{{style}}{{language}}の説明を生成してください。出力は{{maxTokens}}トークン以内に収めてください：「{{jpDesc}}」`,
//     maxJpDescLength: 200,
//   });

//   const [cache, setCache] = useState({});

//   const handleGenerateWithCustomPrompt = async () => {
//     if (!apiKey) {
//       setError('OpenAI APIキーが設定されていません');
//       setSnackbarMessage('OpenAI APIキーが必要です');
//       setSnackbarSeverity('error');
//       setSnackbarOpen(true);
//       return;
//     }

//     if (selectedProducts.length === 0) {
//       setError('少なくとも一つの商品を選択してください');
//       setSnackbarMessage('商品が選択されていません');
//       setSnackbarSeverity('warning');
//       setSnackbarOpen(true);
//       return;
//     }

//     if (!customPrompt.trim()) {
//       setError('カスタムプロンプトが入力されていません');
//       setSnackbarMessage('カスタムプロンプトを入力してください');
//       setSnackbarSeverity('warning');
//       setSnackbarOpen(true);
//       return;
//     }

//     setLoading(true);
//     setError('');
//     setMessage('');
//     setProgress(0);

//     const selectedProductsData = products.filter((product) => selectedProducts.includes(product.id));
//     let processedCount = 0;

//     try {
//       for (let i = 0; i < selectedProductsData.length; i += BATCH_SIZE) {
//         const batch = selectedProductsData.slice(i, i + BATCH_SIZE);
//         const updatedBatch = await processBatchWithCustomPrompt(batch, customPrompt, customOptions);
//         setProducts((prevProducts) => {
//           const newProducts = [...prevProducts];
//           updatedBatch.forEach((updatedProduct) => {
//             const index = newProducts.findIndex((p) => p.id === updatedProduct.id);
//             if (index !== -1) {
//               newProducts[index] = updatedProduct;
//             }
//           });
//           return newProducts;
//         });
//         processedCount += batch.length;
//         setProgress((processedCount / selectedProductsData.length) * 100);
//       }

//       setMessage('カスタムプロンプトによる商品説明の生成が完了しました');
//       setSnackbarMessage('カスタムプロンプトによる商品説明の生成が完了しました');
//       setSnackbarSeverity('success');
//       setSnackbarOpen(true);
//       handleClose();
//     } catch (err) {
//       console.error('カスタムプロンプトバッチ処理エラー:', err);
//       setError(`カスタムプロンプトバッチ処理に失敗しました: ${err.message}`);
//       setSnackbarMessage(`エラー: ${err.message}`);
//       setSnackbarSeverity('error');
//       setSnackbarOpen(true);
//     } finally {
//       setLoading(false);
//     }
//   };

//   const processBatchWithCustomPrompt = async (batch, prompt, options) => {
//     return await Promise.all(
//       batch.map(async (product) => {
//         let generatedFeatures;
//         const jpDescToUse = product.editableJpDesc || product.jpDesc;
//         if (cache[jpDescToUse]) {
//           generatedFeatures = cache[jpDescToUse];
//         } else {
//           generatedFeatures = await generateFeaturesWithRetryCustomPrompt(jpDescToUse, prompt, options);
//           setCache((prevCache) => ({ ...prevCache, [jpDescToUse]: generatedFeatures }));
//         }

//         const transformedFeatures = applyCustomTransformations(generatedFeatures);

//         const sanitizedDescription = sanitizeDescription(product.description);

//         const updatedDescription = generateFormattedDescription(
//           product.title,
//           transformedFeatures,
//           sanitizedDescription,
//           product.editableFields
//         );

//         return {
//           ...product,
//           generatedFeatures,
//           editableGeneratedFeatures: transformedFeatures,
//           updatedDescription,
//         };
//       })
//     );
//   };

//   const generateFeaturesWithRetryCustomPrompt = async (jpDesc, prompt, options, retryCount = 0) => {
//     try {
//       return await queue.add(() => generateFeaturesCustomPrompt(jpDesc, prompt, options));
//     } catch (error) {
//       if (error.response && error.response.status === 429 && retryCount < MAX_RETRIES) {
//         const retryAfter = error.response.headers['retry-after'];
//         const delay = retryAfter
//           ? parseInt(retryAfter) * 1000
//           : INITIAL_RETRY_DELAY * Math.pow(2, retryCount);
//         const jitter = Math.random() * 1000;
//         await new Promise((resolve) => setTimeout(resolve, delay + jitter));
//         return generateFeaturesWithRetryCustomPrompt(jpDesc, prompt, options, retryCount + 1);
//       }
//       throw error;
//     }
//   };

//   const generateFeaturesCustomPrompt = async (jpDesc, prompt, options) => {
//     const {
//       maxTokens = 200,
//       temperature = 0.1,
//       top_p = 1,
//       frequency_penalty = 0,
//       presence_penalty = 0,
//       model = 'gpt-4',
//       language = '英語',
//       style = '最適化された',
//       additionalInstructions = '',
//       systemMessageTemplate = `You are eBay's AI assistant specializing in product description optimization.`,
//       userMessageTemplate = `以下の日本語の商品説明を分析し、eBayの商品説明として{{style}}{{language}}の説明を生成してください。出力は{{maxTokens}}トークン以内に収めてください：「{{jpDesc}}」`,
//       maxJpDescLength = 200,
//     } = options;

//     const systemMessage = `${systemMessageTemplate} ${prompt} ${additionalInstructions}`;

//     let truncatedJpDesc = jpDesc;
//     if (maxJpDescLength > 0) {
//       truncatedJpDesc = jpDesc.substring(0, maxJpDescLength);
//     }

//     const userMessage = userMessageTemplate
//       .replace('{{style}}', style)
//       .replace('{{language}}', language)
//       .replace('{{maxTokens}}', maxTokens)
//       .replace('{{jpDesc}}', truncatedJpDesc);

//     const response = await axios.post(
//       'https://api.openai.com/v1/chat/completions',
//       {
//         model: model,
//         messages: [
//           {
//             role: 'system',
//             content: systemMessage,
//           },
//           {
//             role: 'user',
//             content: userMessage,
//           },
//         ],
//         max_tokens: maxTokens,
//         temperature: temperature,
//         top_p: top_p,
//         frequency_penalty: frequency_penalty,
//         presence_penalty: presence_penalty,
//       },
//       {
//         headers: {
//           Authorization: `Bearer ${apiKey}`,
//           'Content-Type': 'application/json',
//         },
//       }
//     );

//     return response.data.choices[0].message.content.trim();
//   };

//   const generateFormattedDescription = (title, features, description, specifications) => {
//     const template = designTemplates[selectedTemplate];
//     const html = template.generateHTML(title, features, description, specifications);

//     // 生成されたHTMLをクリーンアップ
//     const cleanedHtml = cleanHTML(html);

//     return cleanedHtml;
//   };

//   const cleanHTML = (html) => {
//     // <style>タグを削除
//     let cleanedHtml = html.replace(/<style[^>]*>[\s\S]*?<\/style>/gi, '');

//     // class属性を削除
//     cleanedHtml = cleanedHtml.replace(/\sclass="[^"]*"/gi, '');

//     // 不要な空白を削除
//     cleanedHtml = cleanedHtml.replace(/\s+/g, ' ').trim();

//     // タグ間の空白を削除
//     cleanedHtml = cleanedHtml.replace(/>\s+</g, '><');

//     return cleanedHtml;
//   };

//   return (
//     <Dialog
//       open={open}
//       onClose={handleClose}
//       fullScreen
//       aria-labelledby="custom-prompt-dialog-title"
//     >
//       <DialogTitle id="custom-prompt-dialog-title">カスタムプロンプトで商品説明を生成</DialogTitle>
//       <DialogContent>
//         <Grid container spacing={2}>
//           <Grid item xs={12}>
//             <TextField
//               autoFocus
//               label="カスタムプロンプト"
//               type="text"
//               fullWidth
//               multiline
//               rows={4}
//               value={customPrompt}
//               onChange={(e) => setCustomPrompt(e.target.value)}
//               placeholder="ここにカスタムプロンプトを入力してください..."
//               variant="outlined"
//             />
//           </Grid>
//           {/* その他のオプションフィールド */}
//           <Grid item xs={12} sm={6}>
//             <TextField
//               label="JP説明の最大文字数"
//               type="number"
//               fullWidth
//               value={customOptions.maxJpDescLength}
//               onChange={(e) =>
//                 setCustomOptions({ ...customOptions, maxJpDescLength: parseInt(e.target.value) })
//               }
//               variant="outlined"
//               InputProps={{ inputProps: { min: 1 } }}
//             />
//           </Grid>
//           <Grid item xs={12} sm={6}>
//             <TextField
//               label="Maximum Tokens"
//               type="number"
//               fullWidth
//               value={customOptions.maxTokens}
//               onChange={(e) => setCustomOptions({ ...customOptions, maxTokens: parseInt(e.target.value) })}
//               variant="outlined"
//               InputProps={{ inputProps: { min: 1 } }}
//             />
//           </Grid>
//           <Grid item xs={12} sm={6}>
//             <TextField
//               label="Temperature"
//               type="number"
//               fullWidth
//               value={customOptions.temperature}
//               onChange={(e) => setCustomOptions({ ...customOptions, temperature: parseFloat(e.target.value) })}
//               variant="outlined"
//               InputProps={{ inputProps: { min: 0, max: 1, step: 0.01 } }}
//             />
//           </Grid>
//           <Grid item xs={12} sm={6}>
//             <TextField
//               label="Top_p"
//               type="number"
//               fullWidth
//               value={customOptions.top_p}
//               onChange={(e) => setCustomOptions({ ...customOptions, top_p: parseFloat(e.target.value) })}
//               variant="outlined"
//               InputProps={{ inputProps: { min: 0, max: 1, step: 0.01 } }}
//             />
//           </Grid>
//           <Grid item xs={12} sm={6}>
//             <FormControl fullWidth variant="outlined">
//               <InputLabel>モデルを選択</InputLabel>
//               <Select
//                 value={customOptions.model}
//                 onChange={(e) => setCustomOptions({ ...customOptions, model: e.target.value })}
//                 label="モデルを選択"
//               >
//                 <MenuItem value="gpt-4">gpt-4</MenuItem>
//                 <MenuItem value="gpt-3.5-turbo">gpt-3.5-turbo</MenuItem>
//               </Select>
//             </FormControl>
//           </Grid>
//           <Grid item xs={12} sm={6}>
//             <TextField
//               label="言語"
//               type="text"
//               fullWidth
//               value={customOptions.language}
//               onChange={(e) => setCustomOptions({ ...customOptions, language: e.target.value })}
//               variant="outlined"
//             />
//           </Grid>
//           <Grid item xs={12} sm={6}>
//             <TextField
//               label="スタイル"
//               type="text"
//               fullWidth
//               value={customOptions.style}
//               onChange={(e) => setCustomOptions({ ...customOptions, style: e.target.value })}
//               variant="outlined"
//             />
//           </Grid>
//           <Grid item xs={12}>
//             <TextField
//               label="追加の指示"
//               type="text"
//               fullWidth
//               multiline
//               rows={2}
//               value={customOptions.additionalInstructions}
//               onChange={(e) => setCustomOptions({ ...customOptions, additionalInstructions: e.target.value })}
//               variant="outlined"
//             />
//           </Grid>
//           <Grid item xs={12}>
//             <TextField
//               label="システムメッセージテンプレート"
//               type="text"
//               fullWidth
//               multiline
//               rows={3}
//               value={customOptions.systemMessageTemplate}
//               onChange={(e) => setCustomOptions({ ...customOptions, systemMessageTemplate: e.target.value })}
//               variant="outlined"
//             />
//           </Grid>
//           <Grid item xs={12}>
//             <TextField
//               label="ユーザーメッセージテンプレート"
//               type="text"
//               fullWidth
//               multiline
//               rows={3}
//               value={customOptions.userMessageTemplate}
//               onChange={(e) => setCustomOptions({ ...customOptions, userMessageTemplate: e.target.value })}
//               variant="outlined"
//             />
//           </Grid>
//           {/* 進捗状況表示 */}
//           {loading && (
//             <Grid item xs={12}>
//               <LinearProgress variant="determinate" value={setProgress} />
//               <Typography variant="body2" color="text.secondary" align="center">
//                 {`${Math.round(setProgress)}%`}
//               </Typography>
//             </Grid>
//           )}
//         </Grid>
//       </DialogContent>
//       <DialogActions>
//         <Button onClick={handleClose}>キャンセル</Button>
//         <Button
//           onClick={handleGenerateWithCustomPrompt}
//           variant="contained"
//           color="primary"
//           disabled={!customPrompt.trim() || loading}
//         >
//           生成
//         </Button>
//       </DialogActions>
//     </Dialog>
//   );
// };

// export default DescriptionCustomPrompt;
