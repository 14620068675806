// CustomPromptDialog.js

import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
  Button,
  LinearProgress,
  Snackbar,
  Alert,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  Grid,
} from '@mui/material';
import axios from 'axios';
import PQueue from 'p-queue';
import {
  loadCacheFromLocalStorage,
  getCachedTitle,
  setCachedTitle,
} from './titleCache'; // キャッシュ関連の関数をインポート

const CustomPromptDialog = ({
  open,
  onClose,
  apiKey,
  selectedProducts,
  updateProducts,
}) => {
  const [customPrompt, setCustomPrompt] = useState('');
  const [customOptions, setCustomOptions] = useState({
    maxTokens: 100,
    temperature: 0.2,
    top_p: 1,
    frequency_penalty: 0,
    presence_penalty: 0,
    model: 'gpt-4o-mini',
    language: '英語',
    style: '最適化された',
    additionalInstructions: '',
    systemMessageTemplate:
      'You are an AI assistant specializing in eBay product title optimization.',
    userMessageTemplate:
      '以下の情報を基に、最適な商品タイトルを生成してください：「{{originalTitle}}」',
  });
  const [loading, setLoading] = useState(false);
  const [progress, setProgress] = useState(0);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('info');

  // キャッシュの読み込み
  useEffect(() => {
    loadCacheFromLocalStorage();
  }, []);

  // p-queueの設定
  const CONCURRENCY = 2;
  const INTERVAL = 1000;
  const INTERVAL_CAP = 5;
  const queue = new PQueue({
    concurrency: CONCURRENCY,
    interval: INTERVAL,
    intervalCap: INTERVAL_CAP,
  });

  const handleGenerate = async () => {
    if (!apiKey) {
      setSnackbarMessage('OpenAI APIキーが必要です');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
      return;
    }

    if (selectedProducts.length === 0) {
      setSnackbarMessage('商品が選択されていません');
      setSnackbarSeverity('warning');
      setSnackbarOpen(true);
      return;
    }

    if (!customPrompt.trim()) {
      setSnackbarMessage('カスタムプロンプトを入力してください');
      setSnackbarSeverity('warning');
      setSnackbarOpen(true);
      return;
    }

    setLoading(true);
    setProgress(0);

    try {
      const total = selectedProducts.length;
      let completed = 0;

      const updatedProducts = [];

      const generateTitleTasks = selectedProducts.map((product) => async () => {
        try {
          const generatedTitle = await generateSingleTitleWithCustomPrompt(
            product.title,
            product.editableJpDesc || product.jpDesc,
            customPrompt,
            customOptions,
            apiKey
          );
          updatedProducts.push({ ...product, generatedTitle });
          completed++;
          setProgress(Math.round((completed / total) * 100));
        } catch (err) {
          console.error(`商品ID ${product.id} のタイトル生成エラー:`, err);
          updatedProducts.push({ ...product, generatedTitle: '' });
        }
      });

      await queue.addAll(generateTitleTasks);

      // 親コンポーネントの products を更新
      updateProducts(updatedProducts);

      setSnackbarMessage('カスタムプロンプトによるタイトルの生成が完了しました');
      setSnackbarSeverity('success');
      setSnackbarOpen(true);
      onClose();
    } catch (err) {
      console.error('タイトル生成エラー:', err);
      setSnackbarMessage(`エラー: ${err.message}`);
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    } finally {
      setLoading(false);
      setProgress(0);
      setCustomPrompt('');
    }
  };

  // カスタムプロンプトを使用して単一のタイトルを生成する関数
  async function generateSingleTitleWithCustomPrompt(
    originalTitle,
    jpDesc,
    prompt,
    options,
    apiKey
  ) {
    const cacheKey = `${originalTitle}_${jpDesc.slice(0, 150)}_${prompt}`;
    const cachedTitle = getCachedTitle(cacheKey);
    if (cachedTitle) {
      return cachedTitle;
    }

    // オプションのデフォルト値を設定
    const {
      maxTokens,
      temperature,
      top_p,
      frequency_penalty,
      presence_penalty,
      model,
      language,
      style,
      additionalInstructions,
      systemMessageTemplate,
      userMessageTemplate,
    } = options;

    const systemMessage = `${systemMessageTemplate} ${prompt} ${additionalInstructions}`;

    const userMessage = userMessageTemplate
      .replace('{{originalTitle}}', originalTitle)
      .replace('{{jpDesc}}', jpDesc)
      .replace('{{language}}', language)
      .replace('{{style}}', style)
      .replace('{{maxTokens}}', maxTokens);

    try {
      const url = 'https://api.openai.com/v1/chat/completions';

      const response = await axios.post(
        url,
        {
          model: model,
          messages: [
            {
              role: 'system',
              content: systemMessage,
            },
            {
              role: 'user',
              content: userMessage,
            },
          ],
          max_tokens: maxTokens,
          temperature: temperature,
          top_p: top_p,
          frequency_penalty: frequency_penalty,
          presence_penalty: presence_penalty,
        },
        {
          headers: { Authorization: `Bearer ${apiKey}` },
        }
      );

      const generatedTitle = response.data.choices[0].message.content.trim();
      setCachedTitle(cacheKey, generatedTitle);
      return generatedTitle;
    } catch (error) {
      throw error;
    }
  }

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullScreen
      aria-labelledby="custom-prompt-dialog-title"
    >
      <DialogTitle id="custom-prompt-dialog-title">
        カスタムプロンプトでタイトルを生成
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          {/* カスタムプロンプト入力フィールド */}
          <Grid item xs={12}>
            <TextField
              autoFocus
              margin="dense"
              label="カスタムプロンプト"
              type="text"
              fullWidth
              multiline
              rows={4}
              value={customPrompt}
              onChange={(e) => setCustomPrompt(e.target.value)}
              placeholder="ここにカスタムプロンプトを入力してください..."
            />
          </Grid>
          {/* その他のオプションフィールド */}
          <Grid item xs={12} sm={6}>
            <TextField
              label="Maximum Tokens"
              type="number"
              fullWidth
              value={customOptions.maxTokens}
              onChange={(e) =>
                setCustomOptions({
                  ...customOptions,
                  maxTokens: parseInt(e.target.value),
                })
              }
              variant="outlined"
              InputProps={{ inputProps: { min: 1 } }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Temperature"
              type="number"
              fullWidth
              value={customOptions.temperature}
              onChange={(e) =>
                setCustomOptions({
                  ...customOptions,
                  temperature: parseFloat(e.target.value),
                })
              }
              variant="outlined"
              InputProps={{ inputProps: { min: 0, max: 1, step: 0.01 } }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Top_p"
              type="number"
              fullWidth
              value={customOptions.top_p}
              onChange={(e) =>
                setCustomOptions({
                  ...customOptions,
                  top_p: parseFloat(e.target.value),
                })
              }
              variant="outlined"
              InputProps={{ inputProps: { min: 0, max: 1, step: 0.01 } }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Frequency Penalty"
              type="number"
              fullWidth
              value={customOptions.frequency_penalty}
              onChange={(e) =>
                setCustomOptions({
                  ...customOptions,
                  frequency_penalty: parseFloat(e.target.value),
                })
              }
              variant="outlined"
              InputProps={{ inputProps: { min: -2, max: 2, step: 0.01 } }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Presence Penalty"
              type="number"
              fullWidth
              value={customOptions.presence_penalty}
              onChange={(e) =>
                setCustomOptions({
                  ...customOptions,
                  presence_penalty: parseFloat(e.target.value),
                })
              }
              variant="outlined"
              InputProps={{ inputProps: { min: -2, max: 2, step: 0.01 } }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth variant="outlined">
              <InputLabel>モデルを選択</InputLabel>
              <Select
                value={customOptions.model}
                onChange={(e) =>
                  setCustomOptions({ ...customOptions, model: e.target.value })
                }
                label="モデルを選択"
              >
                <MenuItem value="gpt-4o-mini">GPT-4o-mini</MenuItem>
                <MenuItem value="gpt-4o-2024-08-06">gpt-4o-2024-08-06</MenuItem>
                <MenuItem value="o1-mini">o1-mini</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="言語"
              type="text"
              fullWidth
              value={customOptions.language}
              onChange={(e) =>
                setCustomOptions({ ...customOptions, language: e.target.value })
              }
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="スタイル"
              type="text"
              fullWidth
              value={customOptions.style}
              onChange={(e) =>
                setCustomOptions({ ...customOptions, style: e.target.value })
              }
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="追加の指示"
              type="text"
              fullWidth
              multiline
              rows={2}
              value={customOptions.additionalInstructions}
              onChange={(e) =>
                setCustomOptions({
                  ...customOptions,
                  additionalInstructions: e.target.value,
                })
              }
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="システムメッセージテンプレート"
              type="text"
              fullWidth
              multiline
              rows={3}
              value={customOptions.systemMessageTemplate}
              onChange={(e) =>
                setCustomOptions({
                  ...customOptions,
                  systemMessageTemplate: e.target.value,
                })
              }
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="ユーザーメッセージテンプレート"
              type="text"
              fullWidth
              multiline
              rows={3}
              value={customOptions.userMessageTemplate}
              onChange={(e) =>
                setCustomOptions({
                  ...customOptions,
                  userMessageTemplate: e.target.value,
                })
              }
              variant="outlined"
            />
          </Grid>
        </Grid>
        {/* 進捗状況 */}
        {loading && (
          <LinearProgress
            variant="determinate"
            value={progress}
            sx={{ mt: 2 }}
          />
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>キャンセル</Button>
        <Button
          onClick={handleGenerate}
          variant="contained"
          color="primary"
          disabled={!customPrompt.trim() || loading}
        >
          生成
        </Button>
      </DialogActions>
      {/* スナックバー通知 */}
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={() => setSnackbarOpen(false)}
      >
        <Alert
          onClose={() => setSnackbarOpen(false)}
          severity={snackbarSeverity}
          sx={{ width: '100%' }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Dialog>
  );
};

export default CustomPromptDialog;



// // CustomPromptDialog.js

// import React, { useState, useEffect } from 'react';
// import {
//   Dialog,
//   DialogTitle,
//   DialogContent,
//   TextField,
//   DialogActions,
//   Button,
//   LinearProgress,
//   Snackbar,
//   Alert,
// } from '@mui/material';
// import axios from 'axios';
// import PQueue from 'p-queue';
// import {
//   loadCacheFromLocalStorage,
//   getCachedTitle,
//   setCachedTitle,
// } from './titleCache'; // キャッシュ関連の関数をインポート

// const CustomPromptDialog = ({ open, onClose, apiKey, selectedProducts, updateProducts }) => {
//   const [customPrompt, setCustomPrompt] = useState('');
//   const [loading, setLoading] = useState(false);
//   const [progress, setProgress] = useState(0);
//   const [snackbarOpen, setSnackbarOpen] = useState(false);
//   const [snackbarMessage, setSnackbarMessage] = useState('');
//   const [snackbarSeverity, setSnackbarSeverity] = useState('info');

//   // キャッシュの読み込み
//   useEffect(() => {
//     loadCacheFromLocalStorage();
//   }, []);

//   // p-queueの設定
//   const CONCURRENCY = 2;
//   const INTERVAL = 1000;
//   const INTERVAL_CAP = 5;
//   const queue = new PQueue({
//     concurrency: CONCURRENCY,
//     interval: INTERVAL,
//     intervalCap: INTERVAL_CAP,
//   });

//   const handleGenerate = async () => {
//     if (!apiKey) {
//       setSnackbarMessage('OpenAI APIキーが必要です');
//       setSnackbarSeverity('error');
//       setSnackbarOpen(true);
//       return;
//     }

//     if (selectedProducts.length === 0) {
//       setSnackbarMessage('商品が選択されていません');
//       setSnackbarSeverity('warning');
//       setSnackbarOpen(true);
//       return;
//     }

//     if (!customPrompt.trim()) {
//       setSnackbarMessage('カスタムプロンプトを入力してください');
//       setSnackbarSeverity('warning');
//       setSnackbarOpen(true);
//       return;
//     }

//     setLoading(true);
//     setProgress(0);

//     try {
//       const total = selectedProducts.length;
//       let completed = 0;

//       const updatedProducts = [];

//       const generateTitleTasks = selectedProducts.map((product) => async () => {
//         try {
//           const generatedTitle = await generateSingleTitleWithCustomPrompt(
//             product.title,
//             product.jpDesc,
//             customPrompt,
//             apiKey
//           );
//           updatedProducts.push({ ...product, generatedTitle });
//           completed++;
//           setProgress(Math.round((completed / total) * 100));
//         } catch (err) {
//           console.error(`商品ID ${product.id} のタイトル生成エラー:`, err);
//           updatedProducts.push({ ...product, generatedTitle: '' });
//         }
//       });

//       await queue.addAll(generateTitleTasks);

//       // 親コンポーネントの products を更新
//       updateProducts(updatedProducts);

//       setSnackbarMessage('カスタムプロンプトによるタイトルの生成が完了しました');
//       setSnackbarSeverity('success');
//       setSnackbarOpen(true);
//       onClose();
//     } catch (err) {
//       console.error('タイトル生成エラー:', err);
//       setSnackbarMessage(`エラー: ${err.message}`);
//       setSnackbarSeverity('error');
//       setSnackbarOpen(true);
//     } finally {
//       setLoading(false);
//       setProgress(0);
//       setCustomPrompt('');
//     }
//   };

//   // カスタムプロンプトを使用して単一のタイトルを生成する関数
//   async function generateSingleTitleWithCustomPrompt(originalTitle, jpDesc, prompt, apiKey) {
//     const cacheKey = `${originalTitle}_${jpDesc.slice(0, 150)}_${prompt}`;
//     const cachedTitle = getCachedTitle(cacheKey);
//     if (cachedTitle) {
//       return cachedTitle;
//     }

//     try {
//       const url = 'https://api.openai.com/v1/chat/completions';

//       const messages = [
//         {
//           role: 'system',
//           content: `あなたはeBayの商品のタイトル最適化を専門とするAIアシスタントです。${prompt}`,
//         },
//         {
//           role: 'user',
//           content: `商品タイトル：「${originalTitle}」\n商品説明：「${jpDesc}」\nこの情報を基に、最適な英語の商品タイトルを生成してください。`,
//         },
//       ];

//       const response = await axios.post(
//         url,
//         {
//           model: 'gpt-4o-2024-08-06',
//           messages: messages,
//           max_tokens: 100,
//           temperature: 0.7,
//         },
//         {
//           headers: { Authorization: `Bearer ${apiKey}` },
//         }
//       );

//       const generatedTitle = response.data.choices[0].message.content.trim();
//       setCachedTitle(cacheKey, generatedTitle);
//       return generatedTitle;
//     } catch (error) {
//       throw error;
//     }
//   }

//   return (
//     <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
//       <DialogTitle>カスタムプロンプトでタイトルを生成</DialogTitle>
//       <DialogContent>
//         <TextField
//           autoFocus
//           margin="dense"
//           label="カスタムプロンプト"
//           type="text"
//           fullWidth
//           multiline
//           rows={4}
//           value={customPrompt}
//           onChange={(e) => setCustomPrompt(e.target.value)}
//           placeholder="ここにカスタムプロンプトを入力してください..."
//         />
//         {/* 進捗状況 */}
//         {loading && (
//           <LinearProgress variant="determinate" value={progress} sx={{ mt: 2 }} />
//         )}
//       </DialogContent>
//       <DialogActions>
//         <Button onClick={onClose}>キャンセル</Button>
//         <Button
//           onClick={handleGenerate}
//           variant="contained"
//           color="primary"
//           disabled={!customPrompt.trim() || loading}
//         >
//           生成
//         </Button>
//       </DialogActions>
//       {/* スナックバー通知 */}
//       <Snackbar
//         open={snackbarOpen}
//         autoHideDuration={6000}
//         onClose={() => setSnackbarOpen(false)}
//       >
//         <Alert
//           onClose={() => setSnackbarOpen(false)}
//           severity={snackbarSeverity}
//           sx={{ width: '100%' }}
//         >
//           {snackbarMessage}
//         </Alert>
//       </Snackbar>
//     </Dialog>
//   );
// };

// export default CustomPromptDialog;
