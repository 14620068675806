// ColumnDeletion.js

import React, { useState, useEffect } from 'react';
import {
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
  Chip,
  Box,
  TextField,
  Alert,
} from '@mui/material';

// テンプレートのキーを定義
const TEMPLATES_KEY = 'columnDeletionTemplates';

function ColumnDeletion({ data, setData, columns, setColumns }) {
  const [selectedColumns, setSelectedColumns] = useState([]);
  const [templates, setTemplates] = useState([]);
  const [selectedTemplate, setSelectedTemplate] = useState('');
  const [newTemplateName, setNewTemplateName] = useState('');
  const [error, setError] = useState(null);
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);

  // テンプレートをローカルストレージから読み込む
  useEffect(() => {
    const storedTemplates = localStorage.getItem(TEMPLATES_KEY);
    if (storedTemplates) {
      setTemplates(JSON.parse(storedTemplates));
    }
  }, []);

  // テンプレートをローカルストレージに保存する関数
  const saveTemplates = (updatedTemplates) => {
    setTemplates(updatedTemplates);
    localStorage.setItem(TEMPLATES_KEY, JSON.stringify(updatedTemplates));
  };

  // テンプレートを適用する
  const applyTemplate = (templateName) => {
    const template = templates.find((t) => t.name === templateName);
    if (template) {
      setSelectedColumns(template.columns);
      setMessage(`テンプレート "${templateName}" を適用しました。`);
    }
  };

  // テンプレートを削除する
  const deleteTemplate = (templateName) => {
    const updatedTemplates = templates.filter((t) => t.name !== templateName);
    saveTemplates(updatedTemplates);
    if (selectedTemplate === templateName) {
      setSelectedTemplate('');
    }
    setMessage(`テンプレート "${templateName}" を削除しました。`);
  };

  // 新しいテンプレートを保存する
  const saveNewTemplate = () => {
    if (!newTemplateName.trim()) {
      setError('テンプレート名を入力してください。');
      return;
    }
    if (templates.some((t) => t.name === newTemplateName.trim())) {
      setError('同じ名前のテンプレートが既に存在します。');
      return;
    }
    if (selectedColumns.length === 0) {
      setError('テンプレートに保存するカラムを選択してください。');
      return;
    }

    const newTemplate = {
      name: newTemplateName.trim(),
      columns: selectedColumns,
    };
    const updatedTemplates = [...templates, newTemplate];
    saveTemplates(updatedTemplates);
    setNewTemplateName('');
    setMessage(`テンプレート "${newTemplate.name}" を保存しました。`);
  };

  const handleColumnSelect = (event) => {
    setSelectedColumns(event.target.value);
  };

  const handleTemplateSelect = (event) => {
    const templateName = event.target.value;
    setSelectedTemplate(templateName);
    if (templateName) {
      applyTemplate(templateName);
    }
  };

  const handleColumnDelete = () => {
    if (selectedColumns.length === 0) {
      setError('削除するカラムを選択してください。');
      return;
    }

    setLoading(true);
    setError(null);
    setMessage('');

    try {
      // 選択されたカラムをデータとカラムリストから削除
      const columnsToDelete = selectedColumns;

      // データから選択されたカラムを削除
      const newData = data.map((row) => {
        const newRow = { ...row };
        columnsToDelete.forEach((col) => {
          delete newRow[col];
        });
        return newRow;
      });

      // カラムリストから削除
      const newColumns = columns.filter((col) => !columnsToDelete.includes(col));

      setData(newData);
      setColumns(newColumns);
      setSelectedColumns([]);

      setMessage(`選択されたカラム (${columnsToDelete.join(', ')}) が削除されました。`);
    } catch (error) {
      console.error('Error in column deletion:', error);
      setError(`カラム削除中にエラーが発生しました: ${error.message}`);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box sx={{ p: 2, mt: 2 }}>
      <Typography variant="h6" gutterBottom>
        カラム削除
      </Typography>

      {/* エラーメッセージ表示 */}
      {error && <Alert severity="error" sx={{ mb: 2 }}>{error}</Alert>}
      {message && <Alert severity="success" sx={{ mb: 2 }}>{message}</Alert>}

      {/* テンプレート選択セクション */}
      <Box sx={{ mb: 3 }}>
        <Typography variant="subtitle1" gutterBottom>
          テンプレート
        </Typography>
        <FormControl fullWidth sx={{ mb: 2 }}>
          <InputLabel>テンプレートを選択</InputLabel>
          <Select
            value={selectedTemplate}
            onChange={handleTemplateSelect}
            displayEmpty
            renderValue={(value) => value || 'テンプレートを選択'}
          >
            <MenuItem value="">
              <em>テンプレートを選択</em>
            </MenuItem>
            {templates.map((template, index) => (
              <MenuItem key={index} value={template.name}>
                {template.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        {selectedTemplate && (
          <Button
            variant="outlined"
            color="error"
            onClick={() => deleteTemplate(selectedTemplate)}
            sx={{ mb: 2 }}
          >
            テンプレート "{selectedTemplate}" を削除
          </Button>
        )}

        {/* 新しいテンプレートを保存するフォーム */}
        <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
          <TextField
            label="新しいテンプレート名"
            value={newTemplateName}
            onChange={(e) => setNewTemplateName(e.target.value)}
            fullWidth
          />
          <Button
            variant="contained"
            color="primary"
            onClick={saveNewTemplate}
            disabled={selectedColumns.length === 0}
          >
            テンプレート保存
          </Button>
        </Box>
      </Box>

      {/* カラム選択セクション */}
      <FormControl fullWidth sx={{ mb: 2 }}>
        <InputLabel>削除するカラムを選択</InputLabel>
        <Select
          multiple
          value={selectedColumns}
          onChange={handleColumnSelect}
          renderValue={(selected) => (
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
              {selected.map((value) => (
                <Chip key={value} label={value} />
              ))}
            </Box>
          )}
        >
          {columns.map((header, index) => (
            <MenuItem key={index} value={header}>
              {header}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <Button
        onClick={handleColumnDelete}
        variant="contained"
        color="secondary"
        disabled={selectedColumns.length === 0 || loading}
      >
        {loading ? '削除中...' : '選択したカラムを削除'}
      </Button>
    </Box>
  );
}

export default ColumnDeletion;






// // ColumnDeletion.js

// import React, { useState, useEffect } from 'react';
// import {
//   Typography,
//   FormControl,
//   InputLabel,
//   Select,
//   MenuItem,
//   Button,
//   Chip,
//   Box,
//   TextField,
// } from '@mui/material';

// // テンプレートのキーを定義
// const TEMPLATES_KEY = 'columnDeletionTemplates';

// function ColumnDeletion({
//   data,
//   setData,
//   columns,
//   setColumns,
//   setError,
//   setMessage,
//   setLoading,
// }) {
//   const [selectedColumns, setSelectedColumns] = useState([]);
//   const [templates, setTemplates] = useState([]);
//   const [selectedTemplate, setSelectedTemplate] = useState('');
//   const [newTemplateName, setNewTemplateName] = useState('');

//   // テンプレートをローカルストレージから読み込む
//   useEffect(() => {
//     const storedTemplates = localStorage.getItem(TEMPLATES_KEY);
//     if (storedTemplates) {
//       setTemplates(JSON.parse(storedTemplates));
//     }
//   }, []);

//   // テンプレートをローカルストレージに保存する関数
//   const saveTemplates = (updatedTemplates) => {
//     setTemplates(updatedTemplates);
//     localStorage.setItem(TEMPLATES_KEY, JSON.stringify(updatedTemplates));
//   };

//   // テンプレートを適用する
//   const applyTemplate = (templateName) => {
//     const template = templates.find((t) => t.name === templateName);
//     if (template) {
//       setSelectedColumns(template.columns);
//       setMessage(`テンプレート "${templateName}" を適用しました。`);
//     }
//   };

//   // テンプレートを削除する
//   const deleteTemplate = (templateName) => {
//     const updatedTemplates = templates.filter((t) => t.name !== templateName);
//     saveTemplates(updatedTemplates);
//     if (selectedTemplate === templateName) {
//       setSelectedTemplate('');
//     }
//     setMessage(`テンプレート "${templateName}" を削除しました。`);
//   };

//   // 新しいテンプレートを保存する
//   const saveNewTemplate = () => {
//     if (!newTemplateName.trim()) {
//       setError('テンプレート名を入力してください。');
//       return;
//     }
//     if (templates.some((t) => t.name === newTemplateName.trim())) {
//       setError('同じ名前のテンプレートが既に存在します。');
//       return;
//     }
//     if (selectedColumns.length === 0) {
//       setError('テンプレートに保存するカラムを選択してください。');
//       return;
//     }

//     const newTemplate = {
//       name: newTemplateName.trim(),
//       columns: selectedColumns,
//     };
//     const updatedTemplates = [...templates, newTemplate];
//     saveTemplates(updatedTemplates);
//     setNewTemplateName('');
//     setMessage(`テンプレート "${newTemplate.name}" を保存しました。`);
//   };

//   const handleColumnSelect = (event) => {
//     setSelectedColumns(event.target.value);
//   };

//   const handleTemplateSelect = (event) => {
//     const templateName = event.target.value;
//     setSelectedTemplate(templateName);
//     if (templateName) {
//       applyTemplate(templateName);
//     }
//   };

//   const handleColumnDelete = () => {
//     if (selectedColumns.length === 0) {
//       setError('削除するカラムを選択してください。');
//       return;
//     }

//     setLoading(true);
//     setError(null);
//     setMessage('');

//     try {
//       // 選択されたカラムをデータとカラムリストから削除
//       const columnsToDelete = selectedColumns;

//       // データから選択されたカラムを削除
//       const newData = data.map((row) => {
//         const newRow = { ...row };
//         columnsToDelete.forEach((col) => {
//           delete newRow[col];
//         });
//         return newRow;
//       });

//       // カラムリストから削除
//       const newColumns = columns.filter((col) => !columnsToDelete.includes(col));

//       setData(newData);
//       setColumns(newColumns);
//       setSelectedColumns([]);

//       setMessage(`選択されたカラム (${columnsToDelete.join(', ')}) が削除されました。`);
//     } catch (error) {
//       console.error('Error in column deletion:', error);
//       setError(`カラム削除中にエラーが発生しました: ${error.message}`);
//     } finally {
//       setLoading(false);
//     }
//   };

//   return (
//     <Box sx={{ p: 2, mt: 2 }}>
//       <Typography variant="h6" gutterBottom>
//         カラム削除
//       </Typography>

//       {/* テンプレート選択セクション */}
//       <Box sx={{ mb: 3 }}>
//         <Typography variant="subtitle1" gutterBottom>
//           テンプレート
//         </Typography>
//         <FormControl fullWidth sx={{ mb: 2 }}>
//           <InputLabel>テンプレートを選択</InputLabel>
//           <Select
//             value={selectedTemplate}
//             onChange={handleTemplateSelect}
//             displayEmpty
//             renderValue={(value) => value || 'テンプレートを選択'}
//           >
//             <MenuItem value="">
//               <em>テンプレートを選択</em>
//             </MenuItem>
//             {templates.map((template, index) => (
//               <MenuItem key={index} value={template.name}>
//                 {template.name}
//               </MenuItem>
//             ))}
//           </Select>
//         </FormControl>
//         {selectedTemplate && (
//           <Button
//             variant="outlined"
//             color="error"
//             onClick={() => deleteTemplate(selectedTemplate)}
//             sx={{ mb: 2 }}
//           >
//             テンプレート "{selectedTemplate}" を削除
//           </Button>
//         )}

//         {/* 新しいテンプレートを保存するフォーム */}
//         <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
//           <TextField
//             label="新しいテンプレート名"
//             value={newTemplateName}
//             onChange={(e) => setNewTemplateName(e.target.value)}
//             fullWidth
//           />
//           <Button
//             variant="contained"
//             color="primary"
//             onClick={saveNewTemplate}
//             disabled={selectedColumns.length === 0}
//           >
//             テンプレート保存
//           </Button>
//         </Box>
//       </Box>

//       {/* カラム選択セクション */}
//       <FormControl fullWidth sx={{ mb: 2 }}>
//         <InputLabel>削除するカラムを選択</InputLabel>
//         <Select
//           multiple
//           value={selectedColumns}
//           onChange={handleColumnSelect}
//           renderValue={(selected) => (
//             <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
//               {selected.map((value) => (
//                 <Chip key={value} label={value} />
//               ))}
//             </Box>
//           )}
//         >
//           {columns.map((header, index) => (
//             <MenuItem key={index} value={header}>
//               {header}
//             </MenuItem>
//           ))}
//         </Select>
//       </FormControl>
//       <Button
//         onClick={handleColumnDelete}
//         variant="contained"
//         color="secondary"
//         disabled={selectedColumns.length === 0}
//       >
//         選択したカラムを削除
//       </Button>
//     </Box>
//   );
// }

// export default ColumnDeletion;




// import React, { useState, useCallback, useEffect } from 'react';
// import axios from 'axios';
// import { 
//   Paper, Typography, FormControl, InputLabel, Select, MenuItem, Button, Chip, Box, TextField 
// } from '@mui/material';

// // テンプレートのキーを定義
// const TEMPLATES_KEY = 'columnDeletionTemplates';

// function ColumnDeletion({ 
//   currentSpreadsheet, 
//   token, 
//   data, 
//   fetchData, 
//   setError,
//   setMessage,
//   setLoading
// }) {
//   const [selectedColumns, setSelectedColumns] = useState([]);
//   const [templates, setTemplates] = useState([]);
//   const [selectedTemplate, setSelectedTemplate] = useState('');
//   const [newTemplateName, setNewTemplateName] = useState('');

//   // テンプレートをローカルストレージから読み込む
//   useEffect(() => {
//     const storedTemplates = localStorage.getItem(TEMPLATES_KEY);
//     if (storedTemplates) {
//       setTemplates(JSON.parse(storedTemplates));
//     }
//   }, []);

//   // テンプレートをローカルストレージに保存する関数
//   const saveTemplates = (updatedTemplates) => {
//     setTemplates(updatedTemplates);
//     localStorage.setItem(TEMPLATES_KEY, JSON.stringify(updatedTemplates));
//   };

//   // テンプレートを適用する
//   const applyTemplate = (templateName) => {
//     const template = templates.find(t => t.name === templateName);
//     if (template) {
//       setSelectedColumns(template.columns);
//       setMessage(`テンプレート "${templateName}" を適用しました。`);
//     }
//   };

//   // テンプレートを削除する
//   const deleteTemplate = (templateName) => {
//     const updatedTemplates = templates.filter(t => t.name !== templateName);
//     saveTemplates(updatedTemplates);
//     if (selectedTemplate === templateName) {
//       setSelectedTemplate('');
//     }
//     setMessage(`テンプレート "${templateName}" を削除しました。`);
//   };

//   // 新しいテンプレートを保存する
//   const saveNewTemplate = () => {
//     if (!newTemplateName.trim()) {
//       setError('テンプレート名を入力してください。');
//       return;
//     }
//     if (templates.some(t => t.name === newTemplateName.trim())) {
//       setError('同じ名前のテンプレートが既に存在します。');
//       return;
//     }
//     if (selectedColumns.length === 0) {
//       setError('テンプレートに保存するカラムを選択してください。');
//       return;
//     }

//     const newTemplate = {
//       name: newTemplateName.trim(),
//       columns: selectedColumns
//     };
//     const updatedTemplates = [...templates, newTemplate];
//     saveTemplates(updatedTemplates);
//     setNewTemplateName('');
//     setMessage(`テンプレート "${newTemplate.name}" を保存しました。`);
//   };

//   const handleColumnSelect = (event) => {
//     setSelectedColumns(event.target.value);
//   };

//   const handleTemplateSelect = (event) => {
//     const templateName = event.target.value;
//     setSelectedTemplate(templateName);
//     if (templateName) {
//       applyTemplate(templateName);
//     }
//   };

//   const handleColumnDelete = useCallback(async () => {
//     if (selectedColumns.length === 0) {
//       setError('削除するカラムを選択してください。');
//       return;
//     }

//     setLoading(true);
//     setError(null);
//     setMessage('');

//     try {
//       // スプレッドシートの情報を取得してシートIDを特定
//       const spreadsheetInfo = await axios.get(
//         `https://sheets.googleapis.com/v4/spreadsheets/${currentSpreadsheet.id}`,
//         {
//           headers: { Authorization: `Bearer ${token}` }
//         }
//       );

//       const sheet = spreadsheetInfo.data.sheets.find(s => s.properties.title === currentSpreadsheet.sheetName);
//       if (!sheet) {
//         throw new Error('指定されたシートが見つかりません。');
//       }

//       const sheetId = sheet.properties.sheetId;

//       // 選択されたカラムのインデックスを取得し、ソート
//       const columnIndexes = selectedColumns
//         .map(column => data[0].indexOf(column))
//         .filter(index => index !== -1)
//         .sort((a, b) => b - a);  // 降順にソート

//       if (columnIndexes.length === 0) {
//         throw new Error('選択されたカラムが見つかりません。');
//       }

//       // 削除リクエストを作成
//       const requests = columnIndexes.map(columnIndex => ({
//         deleteDimension: {
//           range: {
//             sheetId: sheetId,
//             dimension: "COLUMNS",
//             startIndex: columnIndex,
//             endIndex: columnIndex + 1
//           }
//         }
//       }));

//       // 列を削除
//       await axios.post(
//         `https://sheets.googleapis.com/v4/spreadsheets/${currentSpreadsheet.id}:batchUpdate`,
//         { requests },
//         {
//           headers: { 
//             Authorization: `Bearer ${token}`,
//             'Content-Type': 'application/json'
//           }
//         }
//       );

//       console.log('Columns deleted successfully');

//       // データを再取得して表示を更新
//       await fetchData(currentSpreadsheet.id, currentSpreadsheet.sheetName);

//       setMessage(`選択されたカラム (${selectedColumns.join(', ')}) が削除されました。`);
//       setSelectedColumns([]);
//     } catch (error) {
//       console.error('Error in column deletion:', error);
//       setError(`カラム削除中にエラーが発生しました: ${error.response?.data?.error?.message || error.message}`);
//     } finally {
//       setLoading(false);
//     }
//   }, [currentSpreadsheet, token, data, selectedColumns, fetchData, setError, setMessage, setLoading]);

//   return (
//     <Box sx={{ p: 2, mt: 2 }}>
//       <Typography variant="h6" gutterBottom>
//         カラム削除
//       </Typography>

//       {/* テンプレート選択セクション */}
//       <Box sx={{ mb: 3 }}>
//         <Typography variant="subtitle1" gutterBottom>
//           テンプレート
//         </Typography>
//         <FormControl fullWidth sx={{ mb: 2 }}>
//           <InputLabel>テンプレートを選択</InputLabel>
//           <Select
//             value={selectedTemplate}
//             onChange={handleTemplateSelect}
//             displayEmpty
//             renderValue={(value) => value || "テンプレートを選択"}
//           >
//             <MenuItem value="">
//               <em>テンプレートを選択</em>
//             </MenuItem>
//             {templates.map((template, index) => (
//               <MenuItem key={index} value={template.name}>
//                 {template.name}
//               </MenuItem>
//             ))}
//           </Select>
//         </FormControl>
//         {selectedTemplate && (
//           <Button 
//             variant="outlined" 
//             color="error" 
//             onClick={() => deleteTemplate(selectedTemplate)}
//             sx={{ mb: 2 }}
//           >
//             テンプレート "{selectedTemplate}" を削除
//           </Button>
//         )}

//         {/* 新しいテンプレートを保存するフォーム */}
//         <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
//           <TextField
//             label="新しいテンプレート名"
//             value={newTemplateName}
//             onChange={(e) => setNewTemplateName(e.target.value)}
//             fullWidth
//           />
//           <Button 
//             variant="contained" 
//             color="primary" 
//             onClick={saveNewTemplate}
//             disabled={selectedColumns.length === 0}
//           >
//             テンプレート保存
//           </Button>
//         </Box>
//       </Box>

//       {/* カラム選択セクション */}
//       <FormControl fullWidth sx={{ mb: 2 }}>
//         <InputLabel>削除するカラムを選択</InputLabel>
//         <Select
//           multiple
//           value={selectedColumns}
//           onChange={handleColumnSelect}
//           renderValue={(selected) => (
//             <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
//               {selected.map((value) => (
//                 <Chip key={value} label={value} />
//               ))}
//             </Box>
//           )}
//         >
//           {data && data[0]?.map((header, index) => (
//             <MenuItem key={index} value={header}>
//               {header}
//             </MenuItem>
//           ))}
//         </Select>
//       </FormControl>
//       <Button
//         onClick={handleColumnDelete}
//         variant="contained"
//         color="secondary"
//         disabled={selectedColumns.length === 0}
//       >
//         選択したカラムを削除
//       </Button>
//   </Box>
//   );
// }

// export default ColumnDeletion;
