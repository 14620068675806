// filters.js

export const presetFilters = [
    {
      label: '発送に関する情報を除外する',
      patterns: ['発送.*', '送料.*', '配送.*'],
    },
    {
      label: '広告文を除外する',
      patterns: ['ご覧いただきありがとうございます', 'お問い合わせください', 'お買い得'],
    },
  ];
  
  export const applyFilters = (jpDesc, selectedFilters, customFilters) => {
    let filteredDesc = jpDesc;
    selectedFilters.forEach((filterLabel) => {
      const filter = presetFilters.find((f) => f.label === filterLabel);
      if (filter) {
        filter.patterns.forEach((pattern) => {
          const regex = new RegExp(pattern, 'gi');
          filteredDesc = filteredDesc.replace(regex, '');
        });
      }
    });
    customFilters.forEach((pattern) => {
      const regex = new RegExp(pattern, 'gi');
      filteredDesc = filteredDesc.replace(regex, '');
    });
    return filteredDesc;
  };
  