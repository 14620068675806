// CategoryTemplates.js

export const categoryTemplates = {
    '69528 Other Anime': [
      'Action(CC=Cp1252)',
      'CustomLabel',
      'StartPrice',
      'ConditionID',
      'Title',
      'Description',
      'C:Brand',
      'PicURL',
      'UPC',
      'Category',
      'PayPalAccepted',
      'PayPalEmailAddress',
      'PaymentProfileName',
      'ReturnProfileName',
      'ShippingProfileName',
      'Country',
      'Location',
      'Apply Profile Domestic',
      'Apply Profile International',
      'BuyerRequirements:LinkedPayPalAccount',
      'Duration',
      'Format',
      'Quantity',
      'Currency',
      'SiteID',
      'C:Country',
      'BestOfferEnabled',
      'BestOfferAutoAcceptPrice',
      'MinimumBestOfferPrice',
    //   'jp_title',
    //   'jp_desc',
      'C:Franchise',
      'C:TV Show',
      'C:Character',
      'C:Material',
      'C:Color',
      'C:Country/Region of Manufacture',
      'C:Year Manufactured',
      'C:Type',
      'C:California Prop 65 Warning',
      'C:Signed',
      'C:Theme',
      'C:Animation Studio',
      'C:Movie',
      'C:Convention/Event',
      'C:Language',
      'C:Signed By',
      'C:Features',
      'C:Item Height',
      'C:Item Length',
      'C:Item Weight',
      'C:Item Width',
      'C:MPN',
      'C:Unit Quantity',
      'C:Unit Type',
    ],
    // 他のテンプレートをここに追加できます
  };
  