// PriceCalculationHelp.js

import React from 'react';
import { Typography, Box } from '@mui/material';

const PriceCalculationHelp = () => (
  <Box sx={{ p: 2 }}>
    <Typography variant="h5" gutterBottom>
      価格計算機能の具体的な説明
    </Typography>

    <Typography variant="h6" gutterBottom>
      計算の具体例
    </Typography>

    <Typography paragraph>
      以下は、価格計算の具体的な手順を示す例です。
    </Typography>

    <Typography variant="subtitle1" gutterBottom>
      前提条件：
    </Typography>
    <Typography component="div" gutterBottom>
      <ul>
        <li>元の価格（StartPrice）：<strong>¥10,000</strong></li>
        <li>固定コスト：<strong>¥500</strong>（例：梱包材費）</li>
        <li>手数料の合計（パーセンテージ）：<strong>15%</strong>（例：eBay手数料10% + 決済手数料5%）</li>
        <li>送料：<strong>¥1,200</strong></li>
        <li>取引手数料：<strong>¥300</strong></li>
        <li>目標利益率：<strong>20%</strong></li>
        <li>割引率：<strong>0%</strong></li>
      </ul>
    </Typography>

    <Typography variant="subtitle1" gutterBottom>
      ステップ1：総コストの計算
    </Typography>
    <Typography paragraph>
      1. 元の価格に固定コストを追加：
      <br />
      ¥10,000（元の価格） + ¥500（固定コスト） = <strong>¥10,500</strong>
    </Typography>
    <Typography paragraph>
      2. 送料と取引手数料を追加：
      <br />
      ¥10,500 + ¥1,200（送料） + ¥300（取引手数料） = <strong>¥12,000</strong>
    </Typography>

    <Typography variant="subtitle1" gutterBottom>
      ステップ2：総パーセンテージ手数料と目標利益率の合計を計算
    </Typography>
    <Typography paragraph>
      手数料と利益率の合計：
      <br />
      15%（手数料） + 20%（利益率） = <strong>35%</strong>
    </Typography>

    <Typography variant="subtitle1" gutterBottom>
      ステップ3：販売価格の計算
    </Typography>
    <Typography paragraph>
      1. 販売価格を計算：
      <br />
      販売価格 = 総コスト ÷ (1 - 総パーセンテージ手数料)
      <br />
      販売価格 = ¥12,000 ÷ (1 - 0.35) = ¥12,000 ÷ 0.65 ≈ <strong>¥18,461.54</strong>
    </Typography>
    <Typography paragraph>
      2. 価格を「.99」で調整：
      <br />
      ¥18,461.54 → <strong>¥18,461.99</strong>
    </Typography>

    <Typography variant="subtitle1" gutterBottom>
      ステップ4：利益と利益率の計算
    </Typography>
    <Typography paragraph>
      1. 利益を計算：
      <br />
      利益 = 販売価格 - 総コスト
      <br />
      利益 = ¥18,461.99（販売価格） - ¥12,000（総コスト） = <strong>¥6,461.99</strong>
    </Typography>
    <Typography paragraph>
      2. 利益率を計算：
      <br />
      利益率 = (利益 ÷ 販売価格) × 100
      <br />
      利益率 = (¥6,461.99 ÷ ¥18,461.99) × 100 ≈ <strong>35%</strong>
    </Typography>

    <Typography variant="h6" gutterBottom sx={{ mt: 4 }}>
      まとめ
    </Typography>
    <Typography paragraph>
      この価格計算では、元の価格や各種コスト、手数料、目標利益率を基に販売価格を算出します。具体的な数値を使うことで、どのように価格が決定されるかを明確に理解できます。
    </Typography>

    <Typography variant="h6" gutterBottom sx={{ mt: 4 }}>
      ポイント
    </Typography>
    <Typography component="div">
      <ul>
        <li>全てのコストと手数料を考慮することで、予期せぬ損失を防ぎます。</li>
        <li>目標利益率を設定することで、ビジネスの収益目標を達成しやすくします。</li>
        <li>価格を「.99」で調整することで、心理的価格設定の効果を活用できます。</li>
      </ul>
    </Typography>
  </Box>
);

export default PriceCalculationHelp;
