// ItemSpecificsSettings.js

import React, { useState, useEffect, useCallback } from 'react';
import {
  Button,
  TextField,
  Typography,
  Box,
  Grid,
  Snackbar,
  Alert,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  List,
  ListItem,
  ListItemText,
  IconButton,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Pagination,
  InputAdornment,
  Menu,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  ListItemSecondaryAction,
  Autocomplete,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import SaveIcon from '@mui/icons-material/Save';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import SearchIcon from '@mui/icons-material/Search';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import EditIcon from '@mui/icons-material/Edit';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import { getFirestore, doc, getDoc, setDoc } from 'firebase/firestore';

const ItemSpecificsSettings = ({ onSave, onClose, headers, userId }) => {
  const [categories, setCategories] = useState({});
  const [newCategory, setNewCategory] = useState('');
  const [selectedCategory, setSelectedCategory] = useState('');
  const [newColumn, setNewColumn] = useState('');
  const [newValue, setNewValue] = useState('');
  const [bulkInput, setBulkInput] = useState('');
  const [currentColumn, setCurrentColumn] = useState('');
  const [isBulkInputOpen, setIsBulkInputOpen] = useState(false);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'info' });
  const [search, setSearch] = useState('');
  const [page, setPage] = useState(1);
  const [expandedAccordions, setExpandedAccordions] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedItem, setSelectedItem] = useState(null);
  const [editMode, setEditMode] = useState({ active: false, item: null, value: '' });
  const [duplicateDialogOpen, setDuplicateDialogOpen] = useState(false);
  const [duplicateCategory, setDuplicateCategory] = useState('');
  const [isResetDialogOpen, setIsResetDialogOpen] = useState(false);

  const itemsPerPage = 50;

  const [isConversionRuleDialogOpen, setIsConversionRuleDialogOpen] = useState(false);
  const [newRule, setNewRule] = useState({ keyword: '', value: '' });

  const cColumns = Array.isArray(headers)
    ? headers.filter((header) => header.startsWith('C:'))
    : [];

  const db = getFirestore();

  useEffect(() => {
    if (userId) {
      loadItemSpecificsSettings(userId);
    } else {
      setCategories({});
    }
  }, [userId]);

  const loadItemSpecificsSettings = async (userId) => {
    try {
      const docRef = doc(db, 'itemSpecificsSettings', userId);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        const userSettings = docSnap.data();
        setCategories(userSettings.categories || {});
        setSnackbar({ open: true, message: '設定を読み込みました', severity: 'success' });
      } else {
        setCategories({});
      }
    } catch (error) {
      console.error('Failed to load item specifics settings:', error);
      setSnackbar({ open: true, message: '設定の読み込みに失敗しました', severity: 'error' });
    }
  };

  const saveItemSpecificsSettings = async () => {
    if (!userId) {
      setSnackbar({ open: true, message: 'ユーザー情報がありません', severity: 'error' });
      return;
    }

    const settings = {
      categories,
    };

    try {
      const docRef = doc(db, 'itemSpecificsSettings', userId);
      await setDoc(docRef, settings);
      setSnackbar({ open: true, message: '設定を保存しました', severity: 'success' });
    } catch (error) {
      console.error('Failed to save item specifics settings:', error);
      setSnackbar({ open: true, message: '設定の保存に失敗しました', severity: 'error' });
    }
  };

  const handleAddCategory = () => {
    if (newCategory && !categories[newCategory]) {
      setCategories({ ...categories, [newCategory]: { columns: {} } });
      setSelectedCategory(newCategory);
      setNewCategory('');
      setSnackbar({ open: true, message: `カテゴリー "${newCategory}" を追加しました`, severity: 'success' });
    } else if (categories[newCategory]) {
      setSnackbar({ open: true, message: `カテゴリー "${newCategory}" は既に存在します`, severity: 'warning' });
    } else {
      setSnackbar({ open: true, message: '有効なカテゴリー名を入力してください', severity: 'error' });
    }
  };

  const handleSelectCategory = (event) => {
    setSelectedCategory(event.target.value);
    setExpandedAccordions([]);
    setPage(1);
  };

  const handleAddValue = (column) => {
    if (newValue) {
      setCategories((prevCategories) => {
        const existingValues = prevCategories[selectedCategory]?.columns[column]?.values || [];
        const lowerCaseValues = existingValues.map((v) => v.toLowerCase());
        if (!lowerCaseValues.includes(newValue.toLowerCase())) {
          return {
            ...prevCategories,
            [selectedCategory]: {
              ...prevCategories[selectedCategory],
              columns: {
                ...prevCategories[selectedCategory].columns,
                [column]: {
                  ...prevCategories[selectedCategory].columns[column],
                  values: [...existingValues, newValue],
                  conversionRules: prevCategories[selectedCategory].columns[column]?.conversionRules || [],
                },
              },
            },
          };
        } else {
          setSnackbar({ open: true, message: `値 "${newValue}" は既に ${column} に存在します`, severity: 'warning' });
          return prevCategories;
        }
      });
      setSnackbar({ open: true, message: `値 "${newValue}" を ${column} に追加しました`, severity: 'success' });
      setNewValue('');
    } else {
      setSnackbar({ open: true, message: '値を入力してください', severity: 'error' });
    }
  };

  const handleAddColumn = () => {
    const lowerCaseNewColumn = newColumn.toLowerCase();
    if (
      newColumn &&
      !Object.keys(categories[selectedCategory].columns || {}).some(
        (col) => col.toLowerCase() === lowerCaseNewColumn
      )
    ) {
      setCategories({
        ...categories,
        [selectedCategory]: {
          ...categories[selectedCategory],
          columns: {
            ...categories[selectedCategory].columns,
            [newColumn]: { values: [], conversionRules: [] },
          },
        },
      });
      setNewColumn('');
      setSnackbar({
        open: true,
        message: `カラム "${newColumn}" を追加しました`,
        severity: 'success',
      });
    } else if (
      Object.keys(categories[selectedCategory].columns || {}).some(
        (col) => col.toLowerCase() === lowerCaseNewColumn
      )
    ) {
      setSnackbar({
        open: true,
        message: `カラム "${newColumn}" は既に存在します`,
        severity: 'warning',
      });
    } else {
      setSnackbar({
        open: true,
        message: '有効なカラム名を入力してください',
        severity: 'error',
      });
    }
  };

  const handleDeleteValue = useCallback(
    (column, index) => {
      setCategories((prevCategories) => {
        const existingValues = prevCategories[selectedCategory]?.columns[column]?.values || [];
        return {
          ...prevCategories,
          [selectedCategory]: {
            ...prevCategories[selectedCategory],
            columns: {
              ...prevCategories[selectedCategory].columns,
              [column]: {
                ...prevCategories[selectedCategory].columns[column],
                values: existingValues.filter((_, i) => i !== index),
              },
            },
          },
        };
      });
      setSnackbar({ open: true, message: `${column} から値を削除しました`, severity: 'success' });
    },
    [selectedCategory]
  );

  const handleDeleteValueSafe = useCallback(
    (column, index) => {
      if (!column || index === undefined || index === null) {
        console.error('Invalid column or index in handleDeleteValue');
        setSnackbar({ open: true, message: '削除対象の情報が不正です', severity: 'error' });
        return;
      }

      handleDeleteValue(column, index);
    },
    [handleDeleteValue]
  );

  const handleDeleteColumn = (column) => {
    setCategories((prevCategories) => {
      const { [column]: deletedColumn, ...restColumns } = prevCategories[selectedCategory].columns;
      return {
        ...prevCategories,
        [selectedCategory]: {
          ...prevCategories[selectedCategory],
          columns: restColumns,
        },
      };
    });
    setSnackbar({ open: true, message: `カラム "${column}" を削除しました`, severity: 'success' });
  };

  const handleDeleteCategory = () => {
    const { [selectedCategory]: deletedCategory, ...restCategories } = categories;
    setCategories(restCategories);
    setSelectedCategory('');
    setSnackbar({ open: true, message: `カテゴリー "${selectedCategory}" を削除しました`, severity: 'success' });
  };

  const handleBulkInput = () => {
    const values = bulkInput.split('\n').map((v) => v.trim()).filter((v) => v);
    setCategories((prevCategories) => {
      const existingValues = prevCategories[selectedCategory]?.columns[currentColumn]?.values || [];
      return {
        ...prevCategories,
        [selectedCategory]: {
          ...prevCategories[selectedCategory],
          columns: {
            ...prevCategories[selectedCategory].columns,
            [currentColumn]: {
              ...prevCategories[selectedCategory].columns[currentColumn],
              values: [...existingValues, ...values],
            },
          },
        },
      };
    });
    setBulkInput('');
    setIsBulkInputOpen(false);
    setSnackbar({ open: true, message: `${values.length} 件の値を ${currentColumn} に追加しました`, severity: 'success' });
  };

  const handleSaveSettings = () => {
    saveItemSpecificsSettings();
    onSave(categories);
    onClose();
  };

  const handleAccordionChange = (panel) => (event, isExpanded) => {
    setExpandedAccordions((prev) => (isExpanded ? [...prev, panel] : prev.filter((p) => p !== panel)));
  };

  const handleMenuOpen = useCallback((event, item) => {
    setAnchorEl(event.currentTarget);
    setSelectedItem(item);
  }, []);

  const handleMenuClose = () => {
    setAnchorEl(null);
    setSelectedItem(null);
  };

  const handleEdit = () => {
    if (!selectedItem) {
      setSnackbar({ open: true, message: '編集対象が選択されていません', severity: 'error' });
      handleMenuClose();
      return;
    }

    const { column, index, value } = selectedItem;
    if (!column || index === undefined || index === null) {
      setSnackbar({ open: true, message: '編集対象の情報が不正です', severity: 'error' });
      handleMenuClose();
      return;
    }

    setEditMode({ active: true, item: selectedItem, value: value });
    handleMenuClose();
  };

  const handleEditCancel = () => {
    setEditMode({ active: false, item: null, value: '' });
  };

  const handleEditSave = useCallback(() => {
    if (!editMode.item) {
      console.error('editMode.item is null in handleEditSave');
      setSnackbar({ open: true, message: '編集対象が選択されていません', severity: 'error' });
      return;
    }

    const { column, index } = editMode.item;
    if (!column || index === undefined || index === null) {
      console.error('editMode.item does not have valid column or index');
      setSnackbar({ open: true, message: '編集対象の情報が不正です', severity: 'error' });
      return;
    }

    setCategories((prevCategories) => {
      const existingValues = prevCategories[selectedCategory]?.columns[column]?.values || [];
      return {
        ...prevCategories,
        [selectedCategory]: {
          ...prevCategories[selectedCategory],
          columns: {
            ...prevCategories[selectedCategory].columns,
            [column]: {
              ...prevCategories[selectedCategory].columns[column],
              values: existingValues.map((value, i) => (i === index ? editMode.value : value)),
            },
          },
        },
      };
    });
    setEditMode({ active: false, item: null, value: '' });
    setSnackbar({ open: true, message: '値を更新しました', severity: 'success' });
  }, [editMode.item, editMode.value, selectedCategory]);

  const handleDuplicateSettings = () => {
    if (!selectedCategory || !categories[selectedCategory]) {
      setSnackbar({
        open: true,
        message: '複製元のカテゴリーを選択してください',
        severity: 'error',
      });
      setDuplicateDialogOpen(false);
      return;
    }

    if (!duplicateCategory) {
      setSnackbar({
        open: true,
        message: '有効な複製先のカテゴリー名を入力してください',
        severity: 'error',
      });
      return;
    }

    if (categories[duplicateCategory]) {
      setSnackbar({
        open: true,
        message: `カテゴリー "${duplicateCategory}" は既に存在します`,
        severity: 'warning',
      });
      return;
    }

    const newCategoryData = JSON.parse(JSON.stringify(categories[selectedCategory]));

    setCategories((prevCategories) => ({
      ...prevCategories,
      [duplicateCategory]: newCategoryData,
    }));
    setSnackbar({
      open: true,
      message: `設定が "${duplicateCategory}" に複製されました`,
      severity: 'success',
    });
    setDuplicateDialogOpen(false);
    setDuplicateCategory('');
  };

  const renderColumnItems = useCallback(
    (column, items) => {
      const safeItems = Array.isArray(items) ? items : [];

      const filteredItems = safeItems.filter((item) =>
        item.toLowerCase().includes(search.toLowerCase())
      );
      const start = (page - 1) * itemsPerPage;
      const end = start + itemsPerPage;
      const paginatedItems = filteredItems.slice(start, end);

      return (
        <>
          <List>
            {paginatedItems.map((item, index) => {
              const fullIndex = start + index;
              return (
                <ListItem
                  key={fullIndex}
                  secondaryAction={
                    <IconButton
                      edge="end"
                      aria-label="more"
                      onClick={(e) =>
                        handleMenuOpen(e, { column, index: fullIndex, value: item })
                      }
                    >
                      <MoreVertIcon />
                    </IconButton>
                  }
                >
                  <ListItemText
                    primary={
                      editMode.active &&
                      editMode.item &&
                      editMode.item.column === column &&
                      editMode.item.index === fullIndex ? (
                        <TextField
                          value={editMode.value}
                          onChange={(e) =>
                            setEditMode({ ...editMode, value: e.target.value })
                          }
                          onKeyPress={(e) => {
                            if (e.key === 'Enter') {
                              handleEditSave();
                            }
                          }}
                          autoFocus
                          fullWidth
                        />
                      ) : (
                        item
                      )
                    }
                  />
                </ListItem>
              );
            })}
          </List>
          <Pagination
            count={Math.ceil(filteredItems.length / itemsPerPage)}
            page={page}
            onChange={(event, value) => setPage(value)}
            sx={{ mt: 2 }}
          />
        </>
      );
    },
    [search, page, itemsPerPage, editMode, handleMenuOpen, handleEditSave]
  );

  const handleAddConversionRule = (column) => {
    const newId = `rule-${Date.now()}`;
    setCategories((prevCategories) => {
      const existingRules = prevCategories[selectedCategory]?.columns[column]?.conversionRules || [];
      return {
        ...prevCategories,
        [selectedCategory]: {
          ...prevCategories[selectedCategory],
          columns: {
            ...prevCategories[selectedCategory].columns,
            [column]: {
              ...prevCategories[selectedCategory].columns[column],
              conversionRules: [
                ...existingRules,
                { id: newId, ...newRule },
              ],
            },
          },
        },
      };
    });
    setNewRule({ keyword: '', value: '' });
    setSnackbar({ open: true, message: '変換ルールを追加しました', severity: 'success' });
  };

  const handleDeleteConversionRule = (column, ruleId) => {
    setCategories((prevCategories) => {
      const existingRules = prevCategories[selectedCategory]?.columns[column]?.conversionRules || [];
      return {
        ...prevCategories,
        [selectedCategory]: {
          ...prevCategories[selectedCategory],
          columns: {
            ...prevCategories[selectedCategory].columns,
            [column]: {
              ...prevCategories[selectedCategory].columns[column],
              conversionRules: existingRules.filter(
                (rule) => rule.id !== ruleId
              ),
            },
          },
        },
      };
    });
    setSnackbar({ open: true, message: '変換ルールを削除しました', severity: 'info' });
  };

  return (
    <Box sx={{ width: '100vw', height: '100vh', display: 'flex', flexDirection: 'row', overflow: 'hidden' }}>
      {/* 左側のセクション */}
      <Box
        sx={{
          width: '300px',
          backgroundColor: '#f5f5f5',
          borderRight: '1px solid #ddd',
          padding: 2,
          overflowY: 'auto',
        }}
      >
        <Typography variant="h5" gutterBottom>
          Item Specifics 設定
        </Typography>

        {/* カテゴリー選択 */}
        <Grid container spacing={2} alignItems="center" mb={3}>
          <Grid item xs={12}>
            <FormControl fullWidth variant="outlined" size="small">
              <InputLabel>カテゴリーを選択する</InputLabel>
              <Select
                value={selectedCategory}
                onChange={handleSelectCategory}
                label="カテゴリーを選択する"
              >
                {Object.keys(categories).map((category) => (
                  <MenuItem key={category} value={category}>
                    {category}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="新しいカテゴリー名を入力"
              value={newCategory}
              onChange={(e) => setNewCategory(e.target.value)}
              variant="outlined"
              size="small"
            />
          </Grid>
          <Grid item xs={12}>
            <Button
              fullWidth
              variant="contained"
              onClick={handleAddCategory}
              startIcon={<AddIcon />}
            >
              カテゴリーを追加する
            </Button>
          </Grid>
          {selectedCategory && (
            <>
              <Grid item xs={12}>
                <Button
                  fullWidth
                  variant="outlined"
                  color="error"
                  onClick={handleDeleteCategory}
                  startIcon={<DeleteIcon />}
                >
                  カテゴリーを消去する
                </Button>
              </Grid>
              <Grid item xs={12}>
                <Button
                  fullWidth
                  variant="outlined"
                  onClick={() => setDuplicateDialogOpen(true)}
                  startIcon={<FileCopyIcon />}
                  disabled={!selectedCategory}
                >
                  設定の複製をする
                </Button>
              </Grid>
            </>
          )}
        </Grid>

        {/* 保存とリセットボタン */}
        <Box sx={{ mt: 2, display: 'flex', flexDirection: 'column', gap: 1 }}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleSaveSettings}
            startIcon={<SaveIcon />}
            fullWidth
          >
            設定を保存する
          </Button>

          {/* <Button
            variant="outlined"
            color="secondary"
            onClick={() => setIsResetDialogOpen(true)}
            startIcon={<DeleteIcon />}
            fullWidth
          >
            保存データをリセット
          </Button> */}
        </Box>
      </Box>

      {/* 右側のセクション */}
      <Box sx={{ flexGrow: 1, padding: 2, overflowY: 'auto'}}>
        {selectedCategory && (
          <>
            {/* カラム追加 */}
            <Grid container spacing={2} alignItems="center" mb={3}>
              <Grid item xs={4}>
                <Autocomplete
                  freeSolo
                  options={cColumns}
                  inputValue={newColumn}
                  onInputChange={(event, newInputValue) => {
                    setNewColumn(newInputValue);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      fullWidth
                      label="新しいカラム名を入力 (例: C:Brand)"
                      variant="outlined"
                      size="small"
                    />
                  )}
                />
              </Grid>
              <Grid item xs={3}>
                <Button
                  fullWidth
                  variant="contained"
                  onClick={handleAddColumn}
                  startIcon={<AddIcon />}
                >
                  カラムを追加する
                </Button>
              </Grid>

              {/* 検索テキストフィールドを同じGridコンテナ内に追加 */}
              <Grid item xs={4}>
                <TextField
                  fullWidth
                  label="検索"
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                  variant="outlined"
                  size="small"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
            </Grid>

            {/* カラムリスト */}
            <Box sx={{ flexGrow: 1 }}>
              {Object.entries(categories[selectedCategory].columns || {}).map(
                ([column, columnData]) => (
                  <Accordion
                    key={column}
                    expanded={expandedAccordions.includes(column)}
                    onChange={handleAccordionChange(column)}
                  >
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                      <Typography>{column}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Box
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                        mb={2}
                        flexWrap="wrap"
                        gap={1}
                      >
                        <TextField
                          label="新しい値を入力"
                          value={newValue}
                          onChange={(e) => setNewValue(e.target.value)}
                          variant="outlined"
                          size="small"
                          sx={{ flexGrow: 1, minWidth: '200px' }}
                        />
                        <Button
                          variant="contained"
                          onClick={() => handleAddValue(column)}
                          startIcon={<AddIcon />}
                        >
                          値を追加
                        </Button>
                        <Button
                          variant="outlined"
                          onClick={() => {
                            setCurrentColumn(column);
                            setIsBulkInputOpen(true);
                          }}
                        >
                          一括入力する
                        </Button>
                        <Button
                          variant="outlined"
                          color="error"
                          onClick={() => handleDeleteColumn(column)}
                          startIcon={<DeleteIcon />}
                        >
                          カラムを削除する
                        </Button>
                      </Box>
                      {renderColumnItems(column, columnData.values)}
                      <Button
                        variant="outlined"
                        onClick={() => {
                          setCurrentColumn(column);
                          setIsConversionRuleDialogOpen(true);
                        }}
                        sx={{ mt: 2 }}
                      >
                        変換ルールの管理
                      </Button>
                    </AccordionDetails>
                  </Accordion>
                )
              )}
            </Box>
          </>
        )}
      </Box>

      {/* 一括入力ダイアログ */}
      <Dialog
        open={isBulkInputOpen}
        onClose={() => setIsBulkInputOpen(false)}
        aria-labelledby="bulk-input-dialog-title"
        fullWidth
        maxWidth="md"
      >
        <DialogTitle id="bulk-input-dialog-title">{currentColumn} への一括入力</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            multiline
            rows={10}
            fullWidth
            variant="outlined"
            value={bulkInput}
            onChange={(e) => setBulkInput(e.target.value)}
            placeholder="値を入力してください（1行に1つ）"
            sx={{ mt: 2 }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsBulkInputOpen(false)} color="primary">
            キャンセル
          </Button>
          <Button onClick={handleBulkInput} color="primary" variant="contained">
            追加する
          </Button>
        </DialogActions>
      </Dialog>

      {/* 設定の複製ダイアログ */}
      <Dialog
        open={duplicateDialogOpen}
        onClose={() => setDuplicateDialogOpen(false)}
        aria-labelledby="duplicate-dialog-title"
      >
        <DialogTitle id="duplicate-dialog-title">他のカテゴリーに設定を複製する</DialogTitle>
        <DialogContent>
          <TextField
            fullWidth
            label="複製先のカテゴリー名を入力"
            value={duplicateCategory}
            onChange={(e) => setDuplicateCategory(e.target.value)}
            variant="outlined"
            size="small"
            sx={{ mt: 2 }}
            placeholder="新しいカテゴリー名を入力"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDuplicateDialogOpen(false)} color="primary">
            キャンセル
          </Button>
          <Button onClick={handleDuplicateSettings} color="primary" variant="contained">
            複製する
          </Button>
        </DialogActions>
      </Dialog>

      {/* リセット確認ダイアログ */}
      <Dialog
        open={isResetDialogOpen}
        onClose={() => setIsResetDialogOpen(false)}
        aria-labelledby="reset-dialog-title"
      >
        <DialogTitle id="reset-dialog-title">確認</DialogTitle>
        <DialogContent>
          <Typography>
            今までの保存していたデータが全て消去されます。
            <br />
            本当に削除しますか？
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsResetDialogOpen(false)} color="primary">
            キャンセル
          </Button>
          <Button
            onClick={() => {
              setCategories({});
              setSelectedCategory('');
              saveItemSpecificsSettings();
              setIsResetDialogOpen(false);
              setSnackbar({
                open: true,
                message: '保存されたデータをリセットしました',
                severity: 'info',
              });
            }}
            color="primary"
            variant="contained"
          >
            削除
          </Button>
        </DialogActions>
      </Dialog>

      {/* 変換ルール管理ダイアログ */}
      <Dialog
        open={isConversionRuleDialogOpen}
        onClose={() => setIsConversionRuleDialogOpen(false)}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>{currentColumn} の変換ルールの管理</DialogTitle>
        <DialogContent>
          <List>
            {categories[selectedCategory]?.columns[currentColumn]?.conversionRules?.map((rule) => (
              <ListItem key={rule.id}>
                <ListItemText primary={`キーワード: ${rule.keyword}`} secondary={`値: ${rule.value}`} />
                <ListItemSecondaryAction>
                  <IconButton edge="end" onClick={() => handleDeleteConversionRule(currentColumn, rule.id)}>
                    <DeleteIcon />
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>
            ))}
          </List>
          <Box sx={{ mt: 2 }}>
            <TextField
              fullWidth
              label="キーワード"
              value={newRule.keyword}
              onChange={(e) => setNewRule({ ...newRule, keyword: e.target.value })}
              sx={{ mb: 2 }}
            />
            <TextField
              fullWidth
              label="値"
              value={newRule.value}
              onChange={(e) => setNewRule({ ...newRule, value: e.target.value })}
              sx={{ mb: 2 }}
            />
            <Button
              variant="contained"
              onClick={() => handleAddConversionRule(currentColumn)}
              disabled={!newRule.keyword || !newRule.value}
            >
              ルールを追加
            </Button>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsConversionRuleDialogOpen(false)}>閉じる</Button>
        </DialogActions>
      </Dialog>

      {/* Snackbar */}
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      >
        <Alert
          onClose={() => setSnackbar({ ...snackbar, open: false })}
          severity={snackbar.severity}
          variant="filled"
          sx={{ width: '100%' }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>

      {/* コンテキストメニュー */}
      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleMenuClose}>
        <MenuItem onClick={handleEdit}>
          <EditIcon fontSize="small" sx={{ mr: 1 }} />
          編集
        </MenuItem>
        <MenuItem
          onClick={() => {
            if (selectedItem) {
              handleDeleteValueSafe(selectedItem.column, selectedItem.index);
            }
            handleMenuClose();
          }}
        >
          <DeleteIcon fontSize="small" sx={{ mr: 1 }} />
          削除
        </MenuItem>
      </Menu>

      {/* 編集ダイアログ */}
      <Dialog open={editMode.active} onClose={handleEditCancel} aria-labelledby="edit-dialog-title">
        <DialogTitle id="edit-dialog-title">値を編集する</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="値"
            type="text"
            fullWidth
            value={editMode.value}
            onChange={(e) => setEditMode({ ...editMode, value: e.target.value })}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleEditCancel} color="primary">
            キャンセル
          </Button>
          <Button onClick={handleEditSave} color="primary">
            保存
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default ItemSpecificsSettings;





// // ItemSpecificsSettings.js

// import React, { useState, useEffect, useCallback } from 'react';
// import {
//   Button,
//   TextField,
//   Typography,
//   Box,
//   Grid,
//   Snackbar,
//   Alert,
//   Dialog,
//   DialogTitle,
//   DialogContent,
//   DialogActions,
//   List,
//   ListItem,
//   ListItemText,
//   IconButton,
//   Accordion,
//   AccordionSummary,
//   AccordionDetails,
//   Pagination,
//   InputAdornment,
//   Menu,
//   MenuItem,
//   Select,
//   FormControl,
//   InputLabel,
//   ListItemSecondaryAction,
//   Autocomplete,
// } from '@mui/material';
// import DeleteIcon from '@mui/icons-material/Delete';
// import AddIcon from '@mui/icons-material/Add';
// import SaveIcon from '@mui/icons-material/Save';
// import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
// import SearchIcon from '@mui/icons-material/Search';
// import MoreVertIcon from '@mui/icons-material/MoreVert';
// import EditIcon from '@mui/icons-material/Edit';
// import FileCopyIcon from '@mui/icons-material/FileCopy';
// import { getFirestore, doc, getDoc, setDoc } from 'firebase/firestore';

// const ItemSpecificsSettings = ({ onSave, onClose, headers, userId }) => {
//   const [categories, setCategories] = useState({});
//   const [newCategory, setNewCategory] = useState('');
//   const [selectedCategory, setSelectedCategory] = useState('');
//   const [newColumn, setNewColumn] = useState('');
//   const [newValue, setNewValue] = useState('');
//   const [bulkInput, setBulkInput] = useState('');
//   const [currentColumn, setCurrentColumn] = useState('');
//   const [isBulkInputOpen, setIsBulkInputOpen] = useState(false);
//   const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'info' });
//   const [search, setSearch] = useState('');
//   const [page, setPage] = useState(1);
//   const [expandedAccordions, setExpandedAccordions] = useState([]);
//   const [anchorEl, setAnchorEl] = useState(null);
//   const [selectedItem, setSelectedItem] = useState(null);
//   const [editMode, setEditMode] = useState({ active: false, item: null, value: '' });
//   const [duplicateDialogOpen, setDuplicateDialogOpen] = useState(false);
//   const [duplicateCategory, setDuplicateCategory] = useState('');
//   const [isResetDialogOpen, setIsResetDialogOpen] = useState(false);

//   const itemsPerPage = 50;

//   const [isConversionRuleDialogOpen, setIsConversionRuleDialogOpen] = useState(false);
//   const [newRule, setNewRule] = useState({ keyword: '', value: '' });

//   const cColumns = Array.isArray(headers)
//     ? headers.filter((header) => header.startsWith('C:'))
//     : [];

//   const db = getFirestore();

//   useEffect(() => {
//     if (userId) {
//       loadItemSpecificsSettings(userId);
//     } else {
//       setCategories({});
//     }
//   }, [userId]);

//   const loadItemSpecificsSettings = async (userId) => {
//     try {
//       const docRef = doc(db, 'itemSpecificsSettings', userId);
//       const docSnap = await getDoc(docRef);

//       if (docSnap.exists()) {
//         const userSettings = docSnap.data();
//         setCategories(userSettings.categories || {});
//         setSnackbar({ open: true, message: '設定を読み込みました', severity: 'success' });
//       } else {
//         setCategories({});
//       }
//     } catch (error) {
//       console.error('Failed to load item specifics settings:', error);
//       setSnackbar({ open: true, message: '設定の読み込みに失敗しました', severity: 'error' });
//     }
//   };

//   const saveItemSpecificsSettings = async () => {
//     if (!userId) {
//       setSnackbar({ open: true, message: 'ユーザー情報がありません', severity: 'error' });
//       return;
//     }

//     const settings = {
//       categories,
//     };

//     try {
//       const docRef = doc(db, 'itemSpecificsSettings', userId);
//       await setDoc(docRef, settings);
//       setSnackbar({ open: true, message: '設定を保存しました', severity: 'success' });
//     } catch (error) {
//       console.error('Failed to save item specifics settings:', error);
//       setSnackbar({ open: true, message: '設定の保存に失敗しました', severity: 'error' });
//     }
//   };

//   const handleAddCategory = () => {
//     if (newCategory && !categories[newCategory]) {
//       setCategories({ ...categories, [newCategory]: { columns: {} } });
//       setSelectedCategory(newCategory);
//       setNewCategory('');
//       setSnackbar({ open: true, message: `カテゴリー "${newCategory}" を追加しました`, severity: 'success' });
//     } else if (categories[newCategory]) {
//       setSnackbar({ open: true, message: `カテゴリー "${newCategory}" は既に存在します`, severity: 'warning' });
//     } else {
//       setSnackbar({ open: true, message: '有効なカテゴリー名を入力してください', severity: 'error' });
//     }
//   };

//   const handleSelectCategory = (event) => {
//     setSelectedCategory(event.target.value);
//     setExpandedAccordions([]);
//     setPage(1);
//   };

//   const handleAddValue = (column) => {
//     if (newValue) {
//       setCategories((prevCategories) => {
//         const existingValues = prevCategories[selectedCategory]?.columns[column]?.values || [];
//         const lowerCaseValues = existingValues.map((v) => v.toLowerCase());
//         if (!lowerCaseValues.includes(newValue.toLowerCase())) {
//           return {
//             ...prevCategories,
//             [selectedCategory]: {
//               ...prevCategories[selectedCategory],
//               columns: {
//                 ...prevCategories[selectedCategory].columns,
//                 [column]: {
//                   ...prevCategories[selectedCategory].columns[column],
//                   values: [...existingValues, newValue],
//                   conversionRules: prevCategories[selectedCategory].columns[column]?.conversionRules || [],
//                 },
//               },
//             },
//           };
//         } else {
//           setSnackbar({ open: true, message: `値 "${newValue}" は既に ${column} に存在します`, severity: 'warning' });
//           return prevCategories;
//         }
//       });
//       setSnackbar({ open: true, message: `値 "${newValue}" を ${column} に追加しました`, severity: 'success' });
//       setNewValue('');
//     } else {
//       setSnackbar({ open: true, message: '値を入力してください', severity: 'error' });
//     }
//   };

//   const handleAddColumn = () => {
//     const lowerCaseNewColumn = newColumn.toLowerCase();
//     if (
//       newColumn &&
//       !Object.keys(categories[selectedCategory].columns || {}).some(
//         (col) => col.toLowerCase() === lowerCaseNewColumn
//       )
//     ) {
//       setCategories({
//         ...categories,
//         [selectedCategory]: {
//           ...categories[selectedCategory],
//           columns: {
//             ...categories[selectedCategory].columns,
//             [newColumn]: { values: [], conversionRules: [] },
//           },
//         },
//       });
//       setNewColumn('');
//       setSnackbar({
//         open: true,
//         message: `カラム "${newColumn}" を追加しました`,
//         severity: 'success',
//       });
//     } else if (
//       Object.keys(categories[selectedCategory].columns || {}).some(
//         (col) => col.toLowerCase() === lowerCaseNewColumn
//       )
//     ) {
//       setSnackbar({
//         open: true,
//         message: `カラム "${newColumn}" は既に存在します`,
//         severity: 'warning',
//       });
//     } else {
//       setSnackbar({
//         open: true,
//         message: '有効なカラム名を入力してください',
//         severity: 'error',
//       });
//     }
//   };

//   const handleDeleteValue = useCallback(
//     (column, index) => {
//       setCategories((prevCategories) => {
//         const existingValues = prevCategories[selectedCategory]?.columns[column]?.values || [];
//         return {
//           ...prevCategories,
//           [selectedCategory]: {
//             ...prevCategories[selectedCategory],
//             columns: {
//               ...prevCategories[selectedCategory].columns,
//               [column]: {
//                 ...prevCategories[selectedCategory].columns[column],
//                 values: existingValues.filter((_, i) => i !== index),
//               },
//             },
//           },
//         };
//       });
//       setSnackbar({ open: true, message: `${column} から値を削除しました`, severity: 'success' });
//     },
//     [selectedCategory]
//   );

//   const handleDeleteValueSafe = useCallback(
//     (column, index) => {
//       if (!column || index === undefined || index === null) {
//         console.error('Invalid column or index in handleDeleteValue');
//         setSnackbar({ open: true, message: '削除対象の情報が不正です', severity: 'error' });
//         return;
//       }

//       handleDeleteValue(column, index);
//     },
//     [handleDeleteValue]
//   );

//   const handleDeleteColumn = (column) => {
//     setCategories((prevCategories) => {
//       const { [column]: deletedColumn, ...restColumns } = prevCategories[selectedCategory].columns;
//       return {
//         ...prevCategories,
//         [selectedCategory]: {
//           ...prevCategories[selectedCategory],
//           columns: restColumns,
//         },
//       };
//     });
//     setSnackbar({ open: true, message: `カラム "${column}" を削除しました`, severity: 'success' });
//   };

//   const handleDeleteCategory = () => {
//     const { [selectedCategory]: deletedCategory, ...restCategories } = categories;
//     setCategories(restCategories);
//     setSelectedCategory('');
//     setSnackbar({ open: true, message: `カテゴリー "${selectedCategory}" を削除しました`, severity: 'success' });
//   };

//   const handleBulkInput = () => {
//     const values = bulkInput.split('\n').map((v) => v.trim()).filter((v) => v);
//     setCategories((prevCategories) => {
//       const existingValues = prevCategories[selectedCategory]?.columns[currentColumn]?.values || [];
//       return {
//         ...prevCategories,
//         [selectedCategory]: {
//           ...prevCategories[selectedCategory],
//           columns: {
//             ...prevCategories[selectedCategory].columns,
//             [currentColumn]: {
//               ...prevCategories[selectedCategory].columns[currentColumn],
//               values: [...existingValues, ...values],
//             },
//           },
//         },
//       };
//     });
//     setBulkInput('');
//     setIsBulkInputOpen(false);
//     setSnackbar({ open: true, message: `${values.length} 件の値を ${currentColumn} に追加しました`, severity: 'success' });
//   };

//   const handleSaveSettings = () => {
//     saveItemSpecificsSettings();
//     onSave(categories);
//     onClose();
//   };

//   const handleAccordionChange = (panel) => (event, isExpanded) => {
//     setExpandedAccordions((prev) => (isExpanded ? [...prev, panel] : prev.filter((p) => p !== panel)));
//   };

//   const handleMenuOpen = useCallback((event, item) => {
//     setAnchorEl(event.currentTarget);
//     setSelectedItem(item);
//   }, []);

//   const handleMenuClose = () => {
//     setAnchorEl(null);
//     setSelectedItem(null);
//   };

//   const handleEdit = () => {
//     if (!selectedItem) {
//       setSnackbar({ open: true, message: '編集対象が選択されていません', severity: 'error' });
//       handleMenuClose();
//       return;
//     }

//     const { column, index, value } = selectedItem;
//     if (!column || index === undefined || index === null) {
//       setSnackbar({ open: true, message: '編集対象の情報が不正です', severity: 'error' });
//       handleMenuClose();
//       return;
//     }

//     setEditMode({ active: true, item: selectedItem, value: value });
//     handleMenuClose();
//   };

//   const handleEditCancel = () => {
//     setEditMode({ active: false, item: null, value: '' });
//   };

//   const handleEditSave = useCallback(() => {
//     if (!editMode.item) {
//       console.error('editMode.item is null in handleEditSave');
//       setSnackbar({ open: true, message: '編集対象が選択されていません', severity: 'error' });
//       return;
//     }

//     const { column, index } = editMode.item;
//     if (!column || index === undefined || index === null) {
//       console.error('editMode.item does not have valid column or index');
//       setSnackbar({ open: true, message: '編集対象の情報が不正です', severity: 'error' });
//       return;
//     }

//     setCategories((prevCategories) => {
//       const existingValues = prevCategories[selectedCategory]?.columns[column]?.values || [];
//       return {
//         ...prevCategories,
//         [selectedCategory]: {
//           ...prevCategories[selectedCategory],
//           columns: {
//             ...prevCategories[selectedCategory].columns,
//             [column]: {
//               ...prevCategories[selectedCategory].columns[column],
//               values: existingValues.map((value, i) => (i === index ? editMode.value : value)),
//             },
//           },
//         },
//       };
//     });
//     setEditMode({ active: false, item: null, value: '' });
//     setSnackbar({ open: true, message: '値を更新しました', severity: 'success' });
//   }, [editMode.item, editMode.value, selectedCategory]);

//   const handleDuplicateSettings = () => {
//     if (!selectedCategory || !categories[selectedCategory]) {
//       setSnackbar({
//         open: true,
//         message: '複製元のカテゴリーを選択してください',
//         severity: 'error',
//       });
//       setDuplicateDialogOpen(false);
//       return;
//     }

//     if (!duplicateCategory) {
//       setSnackbar({
//         open: true,
//         message: '有効な複製先のカテゴリー名を入力してください',
//         severity: 'error',
//       });
//       return;
//     }

//     if (categories[duplicateCategory]) {
//       setSnackbar({
//         open: true,
//         message: `カテゴリー "${duplicateCategory}" は既に存在します`,
//         severity: 'warning',
//       });
//       return;
//     }

//     const newCategoryData = JSON.parse(JSON.stringify(categories[selectedCategory]));

//     setCategories((prevCategories) => ({
//       ...prevCategories,
//       [duplicateCategory]: newCategoryData,
//     }));
//     setSnackbar({
//       open: true,
//       message: `設定が "${duplicateCategory}" に複製されました`,
//       severity: 'success',
//     });
//     setDuplicateDialogOpen(false);
//     setDuplicateCategory('');
//   };

//   const renderColumnItems = useCallback(
//     (column, items) => {
//       const safeItems = Array.isArray(items) ? items : [];

//       const filteredItems = safeItems.filter((item) =>
//         item.toLowerCase().includes(search.toLowerCase())
//       );
//       const start = (page - 1) * itemsPerPage;
//       const end = start + itemsPerPage;
//       const paginatedItems = filteredItems.slice(start, end);

//       return (
//         <>
//           <List>
//             {paginatedItems.map((item, index) => {
//               const fullIndex = start + index;
//               return (
//                 <ListItem
//                   key={fullIndex}
//                   secondaryAction={
//                     <IconButton
//                       edge="end"
//                       aria-label="more"
//                       onClick={(e) =>
//                         handleMenuOpen(e, { column, index: fullIndex, value: item })
//                       }
//                     >
//                       <MoreVertIcon />
//                     </IconButton>
//                   }
//                 >
//                   <ListItemText
//                     primary={
//                       editMode.active &&
//                       editMode.item &&
//                       editMode.item.column === column &&
//                       editMode.item.index === fullIndex ? (
//                         <TextField
//                           value={editMode.value}
//                           onChange={(e) =>
//                             setEditMode({ ...editMode, value: e.target.value })
//                           }
//                           onKeyPress={(e) => {
//                             if (e.key === 'Enter') {
//                               handleEditSave();
//                             }
//                           }}
//                           autoFocus
//                           fullWidth
//                         />
//                       ) : (
//                         item
//                       )
//                     }
//                   />
//                 </ListItem>
//               );
//             })}
//           </List>
//           <Pagination
//             count={Math.ceil(filteredItems.length / itemsPerPage)}
//             page={page}
//             onChange={(event, value) => setPage(value)}
//             sx={{ mt: 2 }}
//           />
//         </>
//       );
//     },
//     [search, page, itemsPerPage, editMode, handleMenuOpen, handleEditSave]
//   );

//   const handleAddConversionRule = (column) => {
//     const newId = `rule-${Date.now()}`;
//     setCategories((prevCategories) => {
//       const existingRules = prevCategories[selectedCategory]?.columns[column]?.conversionRules || [];
//       return {
//         ...prevCategories,
//         [selectedCategory]: {
//           ...prevCategories[selectedCategory],
//           columns: {
//             ...prevCategories[selectedCategory].columns,
//             [column]: {
//               ...prevCategories[selectedCategory].columns[column],
//               conversionRules: [
//                 ...existingRules,
//                 { id: newId, ...newRule },
//               ],
//             },
//           },
//         },
//       };
//     });
//     setNewRule({ keyword: '', value: '' });
//     setSnackbar({ open: true, message: '変換ルールを追加しました', severity: 'success' });
//   };

//   const handleDeleteConversionRule = (column, ruleId) => {
//     setCategories((prevCategories) => {
//       const existingRules = prevCategories[selectedCategory]?.columns[column]?.conversionRules || [];
//       return {
//         ...prevCategories,
//         [selectedCategory]: {
//           ...prevCategories[selectedCategory],
//           columns: {
//             ...prevCategories[selectedCategory].columns,
//             [column]: {
//               ...prevCategories[selectedCategory].columns[column],
//               conversionRules: existingRules.filter(
//                 (rule) => rule.id !== ruleId
//               ),
//             },
//           },
//         },
//       };
//     });
//     setSnackbar({ open: true, message: '変換ルールを削除しました', severity: 'info' });
//   };

//   return (
//     <Box sx={{ width: '100vw',height: '100vh', display: 'flex', flexDirection: 'row' }}>
//       {/* 左側のセクション */}
//       <Box
//         sx={{
//           width: '250px',
//           backgroundColor: '#f5f5f5',
//           borderRight: '1px solid #ddd',
//           padding: 2,
//           overflowY: 'auto',
//         }}
//       >
//         <Typography variant="h5" gutterBottom>
//           Item Specifics 設定
//         </Typography>

//         {/* カテゴリー選択 */}
//         <Grid container spacing={2} alignItems="center" mb={3}>
//           <Grid item xs={12}>
//             <FormControl fullWidth variant="outlined" size="small">
//               <InputLabel>カテゴリーを選択する</InputLabel>
//               <Select
//                 value={selectedCategory}
//                 onChange={handleSelectCategory}
//                 label="カテゴリーを選択する"
//               >
//                 {Object.keys(categories).map((category) => (
//                   <MenuItem key={category} value={category}>
//                     {category}
//                   </MenuItem>
//                 ))}
//               </Select>
//             </FormControl>
//           </Grid>
//           <Grid item xs={12}>
//             <TextField
//               fullWidth
//               label="新しいカテゴリー名を入力"
//               value={newCategory}
//               onChange={(e) => setNewCategory(e.target.value)}
//               variant="outlined"
//               size="small"
//             />
//           </Grid>
//           <Grid item xs={12}>
//             <Button
//               fullWidth
//               variant="contained"
//               onClick={handleAddCategory}
//               startIcon={<AddIcon />}
//             >
//               カテゴリーを追加する
//             </Button>
//           </Grid>
//           {selectedCategory && (
//             <>
//               <Grid item xs={12}>
//                 <Button
//                   fullWidth
//                   variant="outlined"
//                   color="error"
//                   onClick={handleDeleteCategory}
//                   startIcon={<DeleteIcon />}
//                 >
//                   カテゴリーを消去する
//                 </Button>
//               </Grid>
//               <Grid item xs={12}>
//                 <Button
//                   fullWidth
//                   variant="outlined"
//                   onClick={() => setDuplicateDialogOpen(true)}
//                   startIcon={<FileCopyIcon />}
//                   disabled={!selectedCategory}
//                 >
//                   設定の複製をする
//                 </Button>
//               </Grid>
//             </>
//           )}
//         </Grid>

//         {/* 保存とリセットボタン */}
//         <Box sx={{ mt: 2, display: 'flex', flexDirection: 'column', gap: 1 }}>
//           <Button
//             variant="contained"
//             color="primary"
//             onClick={handleSaveSettings}
//             startIcon={<SaveIcon />}
//             fullWidth
//           >
//             設定を保存する
//           </Button>

//           <Button
//             variant="outlined"
//             color="secondary"
//             onClick={() => setIsResetDialogOpen(true)}
//             startIcon={<DeleteIcon />}
//             fullWidth
//           >
//             保存データをリセット
//           </Button>
//         </Box>
//       </Box>

//       {/* 右側のセクション */}
//       <Box sx={{ flexGrow: 1, padding: 1, overflowY: 'auto' }}>
//         {selectedCategory && (
//           <>
//             {/* カラム追加 */}
//             <Grid container spacing={2} alignItems="center" mb={3}>
//               <Grid item xs={9}>
//                 <Autocomplete
//                   freeSolo
//                   options={cColumns}
//                   inputValue={newColumn}
//                   onInputChange={(event, newInputValue) => {
//                     setNewColumn(newInputValue);
//                   }}
//                   renderInput={(params) => (
//                     <TextField
//                       {...params}
//                       fullWidth
//                       label="新しいカラム名を入力 (例: C:Brand)"
//                       variant="outlined"
//                       size="small"
//                     />
//                   )}
//                 />
//               </Grid>
//               <Grid item xs={3}>
//                 <Button
//                   fullWidth
//                   variant="contained"
//                   onClick={handleAddColumn}
//                   startIcon={<AddIcon />}
//                 >
//                   カラムを追加する
//                 </Button>
//               </Grid>
//             </Grid>

//             {/* 検索 */}
//             <TextField
//               fullWidth
//               label="検索"
//               value={search}
//               onChange={(e) => setSearch(e.target.value)}
//               variant="outlined"
//               size="small"
//               sx={{ mb: 2 }}
//               InputProps={{
//                 startAdornment: (
//                   <InputAdornment position="start">
//                     <SearchIcon />
//                   </InputAdornment>
//                 ),
//               }}
//             />

//             {/* カラムリスト */}
//             <Box sx={{ flexGrow: 1 }}>
//               {Object.entries(categories[selectedCategory].columns || {}).map(
//                 ([column, columnData]) => (
//                   <Accordion
//                     key={column}
//                     expanded={expandedAccordions.includes(column)}
//                     onChange={handleAccordionChange(column)}
//                   >
//                     <AccordionSummary expandIcon={<ExpandMoreIcon />}>
//                       <Typography>{column}</Typography>
//                     </AccordionSummary>
//                     <AccordionDetails>
//                       <Box
//                         display="flex"
//                         justifyContent="space-between"
//                         alignItems="center"
//                         mb={2}
//                         flexWrap="wrap"
//                         gap={1}
//                       >
//                         <TextField
//                           label="新しい値を入力"
//                           value={newValue}
//                           onChange={(e) => setNewValue(e.target.value)}
//                           variant="outlined"
//                           size="small"
//                           sx={{ flexGrow: 1, minWidth: '200px' }}
//                         />
//                         <Button
//                           variant="contained"
//                           onClick={() => handleAddValue(column)}
//                           startIcon={<AddIcon />}
//                         >
//                           値を追加
//                         </Button>
//                         <Button
//                           variant="outlined"
//                           onClick={() => {
//                             setCurrentColumn(column);
//                             setIsBulkInputOpen(true);
//                           }}
//                         >
//                           一括入力する
//                         </Button>
//                         <Button
//                           variant="outlined"
//                           color="error"
//                           onClick={() => handleDeleteColumn(column)}
//                           startIcon={<DeleteIcon />}
//                         >
//                           カラムを削除する
//                         </Button>
//                       </Box>
//                       {renderColumnItems(column, columnData.values)}
//                       <Button
//                         variant="outlined"
//                         onClick={() => {
//                           setCurrentColumn(column);
//                           setIsConversionRuleDialogOpen(true);
//                         }}
//                         sx={{ mt: 2 }}
//                       >
//                         変換ルールの管理
//                       </Button>
//                     </AccordionDetails>
//                   </Accordion>
//                 )
//               )}
//             </Box>
//           </>
//         )}
//       </Box>

//       {/* 一括入力ダイアログ */}
//       <Dialog
//         open={isBulkInputOpen}
//         onClose={() => setIsBulkInputOpen(false)}
//         aria-labelledby="bulk-input-dialog-title"
//         fullWidth
//         maxWidth="md"
//       >
//         <DialogTitle id="bulk-input-dialog-title">{currentColumn} への一括入力</DialogTitle>
//         <DialogContent>
//           <TextField
//             autoFocus
//             multiline
//             rows={10}
//             fullWidth
//             variant="outlined"
//             value={bulkInput}
//             onChange={(e) => setBulkInput(e.target.value)}
//             placeholder="値を入力してください（1行に1つ）"
//             sx={{ mt: 2 }}
//           />
//         </DialogContent>
//         <DialogActions>
//           <Button onClick={() => setIsBulkInputOpen(false)} color="primary">
//             キャンセル
//           </Button>
//           <Button onClick={handleBulkInput} color="primary" variant="contained">
//             追加する
//           </Button>
//         </DialogActions>
//       </Dialog>

//       {/* 設定の複製ダイアログ */}
//       <Dialog
//         open={duplicateDialogOpen}
//         onClose={() => setDuplicateDialogOpen(false)}
//         aria-labelledby="duplicate-dialog-title"
//       >
//         <DialogTitle id="duplicate-dialog-title">他のカテゴリーに設定を複製する</DialogTitle>
//         <DialogContent>
//           <TextField
//             fullWidth
//             label="複製先のカテゴリー名を入力"
//             value={duplicateCategory}
//             onChange={(e) => setDuplicateCategory(e.target.value)}
//             variant="outlined"
//             size="small"
//             sx={{ mt: 2 }}
//             placeholder="新しいカテゴリー名を入力"
//           />
//         </DialogContent>
//         <DialogActions>
//           <Button onClick={() => setDuplicateDialogOpen(false)} color="primary">
//             キャンセル
//           </Button>
//           <Button onClick={handleDuplicateSettings} color="primary" variant="contained">
//             複製する
//           </Button>
//         </DialogActions>
//       </Dialog>

//       {/* リセット確認ダイアログ */}
//       <Dialog
//         open={isResetDialogOpen}
//         onClose={() => setIsResetDialogOpen(false)}
//         aria-labelledby="reset-dialog-title"
//       >
//         <DialogTitle id="reset-dialog-title">確認</DialogTitle>
//         <DialogContent>
//           <Typography>
//             今までの保存していたデータが全て消去されます。
//             <br />
//             本当に削除しますか？
//           </Typography>
//         </DialogContent>
//         <DialogActions>
//           <Button onClick={() => setIsResetDialogOpen(false)} color="primary">
//             キャンセル
//           </Button>
//           <Button
//             onClick={() => {
//               setCategories({});
//               setSelectedCategory('');
//               saveItemSpecificsSettings();
//               setIsResetDialogOpen(false);
//               setSnackbar({
//                 open: true,
//                 message: '保存されたデータをリセットしました',
//                 severity: 'info',
//               });
//             }}
//             color="primary"
//             variant="contained"
//           >
//             削除
//           </Button>
//         </DialogActions>
//       </Dialog>

//       {/* 変換ルール管理ダイアログ */}
//       <Dialog
//         open={isConversionRuleDialogOpen}
//         onClose={() => setIsConversionRuleDialogOpen(false)}
//         maxWidth="sm"
//         fullWidth
//       >
//         <DialogTitle>{currentColumn} の変換ルールの管理</DialogTitle>
//         <DialogContent>
//           <List>
//             {categories[selectedCategory]?.columns[currentColumn]?.conversionRules?.map((rule) => (
//               <ListItem key={rule.id}>
//                 <ListItemText primary={`キーワード: ${rule.keyword}`} secondary={`値: ${rule.value}`} />
//                 <ListItemSecondaryAction>
//                   <IconButton edge="end" onClick={() => handleDeleteConversionRule(currentColumn, rule.id)}>
//                     <DeleteIcon />
//                   </IconButton>
//                 </ListItemSecondaryAction>
//               </ListItem>
//             ))}
//           </List>
//           <Box sx={{ mt: 2 }}>
//             <TextField
//               fullWidth
//               label="キーワード"
//               value={newRule.keyword}
//               onChange={(e) => setNewRule({ ...newRule, keyword: e.target.value })}
//               sx={{ mb: 2 }}
//             />
//             <TextField
//               fullWidth
//               label="値"
//               value={newRule.value}
//               onChange={(e) => setNewRule({ ...newRule, value: e.target.value })}
//               sx={{ mb: 2 }}
//             />
//             <Button
//               variant="contained"
//               onClick={() => handleAddConversionRule(currentColumn)}
//               disabled={!newRule.keyword || !newRule.value}
//             >
//               ルールを追加
//             </Button>
//           </Box>
//         </DialogContent>
//         <DialogActions>
//           <Button onClick={() => setIsConversionRuleDialogOpen(false)}>閉じる</Button>
//         </DialogActions>
//       </Dialog>

//       {/* Snackbar */}
//       <Snackbar
//         open={snackbar.open}
//         autoHideDuration={6000}
//         onClose={() => setSnackbar({ ...snackbar, open: false })}
//         anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
//       >
//         <Alert
//           onClose={() => setSnackbar({ ...snackbar, open: false })}
//           severity={snackbar.severity}
//           variant="filled"
//           sx={{ width: '100%' }}
//         >
//           {snackbar.message}
//         </Alert>
//       </Snackbar>

//       {/* コンテキストメニュー */}
//       <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleMenuClose}>
//         <MenuItem onClick={handleEdit}>
//           <EditIcon fontSize="small" sx={{ mr: 1 }} />
//           編集
//         </MenuItem>
//         <MenuItem
//           onClick={() => {
//             if (selectedItem) {
//               handleDeleteValueSafe(selectedItem.column, selectedItem.index);
//             }
//             handleMenuClose();
//           }}
//         >
//           <DeleteIcon fontSize="small" sx={{ mr: 1 }} />
//           削除
//         </MenuItem>
//       </Menu>

//       {/* 編集ダイアログ */}
//       <Dialog open={editMode.active} onClose={handleEditCancel} aria-labelledby="edit-dialog-title">
//         <DialogTitle id="edit-dialog-title">値を編集する</DialogTitle>
//         <DialogContent>
//           <TextField
//             autoFocus
//             margin="dense"
//             label="値"
//             type="text"
//             fullWidth
//             value={editMode.value}
//             onChange={(e) => setEditMode({ ...editMode, value: e.target.value })}
//           />
//         </DialogContent>
//         <DialogActions>
//           <Button onClick={handleEditCancel} color="primary">
//             キャンセル
//           </Button>
//           <Button onClick={handleEditSave} color="primary">
//             保存
//           </Button>
//         </DialogActions>
//       </Dialog>
//     </Box>
//   );
// };

// export default ItemSpecificsSettings;




// // ItemSpecificsSettings.js

// import React, { useState, useEffect, useCallback } from 'react';
// import {
//   Button,
//   TextField,
//   Typography,
//   Box,
//   Grid,
//   Snackbar,
//   Alert,
//   Dialog,
//   DialogTitle,
//   DialogContent,
//   DialogActions,
//   List,
//   ListItem,
//   ListItemText,
//   IconButton,
//   Accordion,
//   AccordionSummary,
//   AccordionDetails,
//   Pagination,
//   InputAdornment,
//   Menu,
//   MenuItem,
//   Select,
//   FormControl,
//   InputLabel,
//   ListItemSecondaryAction,
//   Autocomplete,
// } from '@mui/material';
// import DeleteIcon from '@mui/icons-material/Delete';
// import AddIcon from '@mui/icons-material/Add';
// import SaveIcon from '@mui/icons-material/Save';
// import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
// import SearchIcon from '@mui/icons-material/Search';
// import MoreVertIcon from '@mui/icons-material/MoreVert';
// import EditIcon from '@mui/icons-material/Edit';
// import FileCopyIcon from '@mui/icons-material/FileCopy';
// import { getFirestore, doc, getDoc, setDoc } from 'firebase/firestore';

// const ItemSpecificsSettings = ({ onSave, onClose, headers, userId }) => {
//   const [categories, setCategories] = useState({});
//   const [newCategory, setNewCategory] = useState('');
//   const [selectedCategory, setSelectedCategory] = useState('');
//   const [newColumn, setNewColumn] = useState('');
//   const [newValue, setNewValue] = useState('');
//   const [bulkInput, setBulkInput] = useState('');
//   const [currentColumn, setCurrentColumn] = useState('');
//   const [isBulkInputOpen, setIsBulkInputOpen] = useState(false);
//   const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'info' });
//   const [search, setSearch] = useState('');
//   const [page, setPage] = useState(1);
//   const [expandedAccordions, setExpandedAccordions] = useState([]);
//   const [anchorEl, setAnchorEl] = useState(null);
//   const [selectedItem, setSelectedItem] = useState(null);
//   const [editMode, setEditMode] = useState({ active: false, item: null, value: '' });
//   const [duplicateDialogOpen, setDuplicateDialogOpen] = useState(false);
//   const [duplicateCategory, setDuplicateCategory] = useState('');
//   const [isResetDialogOpen, setIsResetDialogOpen] = useState(false);

//   const itemsPerPage = 50;

//   const [isConversionRuleDialogOpen, setIsConversionRuleDialogOpen] = useState(false);
//   const [newRule, setNewRule] = useState({ keyword: '', value: '' });

//   const cColumns = Array.isArray(headers)
//     ? headers.filter((header) => header.startsWith('C:'))
//     : [];

//   const db = getFirestore();

//   useEffect(() => {
//     if (userId) {
//       loadItemSpecificsSettings(userId);
//     } else {
//       setCategories({});
//     }
//   }, [userId]);

//   const loadItemSpecificsSettings = async (userId) => {
//     try {
//       const docRef = doc(db, 'itemSpecificsSettings', userId);
//       const docSnap = await getDoc(docRef);

//       if (docSnap.exists()) {
//         const userSettings = docSnap.data();
//         setCategories(userSettings.categories || {});
//         setSnackbar({ open: true, message: '設定を読み込みました', severity: 'success' });
//       } else {
//         setCategories({});
//       }
//     } catch (error) {
//       console.error('Failed to load item specifics settings:', error);
//       setSnackbar({ open: true, message: '設定の読み込みに失敗しました', severity: 'error' });
//     }
//   };

//   const saveItemSpecificsSettings = async () => {
//     if (!userId) {
//       setSnackbar({ open: true, message: 'ユーザー情報がありません', severity: 'error' });
//       return;
//     }

//     const settings = {
//       categories,
//     };

//     try {
//       const docRef = doc(db, 'itemSpecificsSettings', userId);
//       await setDoc(docRef, settings);
//       setSnackbar({ open: true, message: '設定を保存しました', severity: 'success' });
//     } catch (error) {
//       console.error('Failed to save item specifics settings:', error);
//       setSnackbar({ open: true, message: '設定の保存に失敗しました', severity: 'error' });
//     }
//   };

//   const handleAddCategory = () => {
//     if (newCategory && !categories[newCategory]) {
//       setCategories({ ...categories, [newCategory]: { columns: {} } });
//       setSelectedCategory(newCategory);
//       setNewCategory('');
//       setSnackbar({ open: true, message: `カテゴリー "${newCategory}" を追加しました`, severity: 'success' });
//     } else if (categories[newCategory]) {
//       setSnackbar({ open: true, message: `カテゴリー "${newCategory}" は既に存在します`, severity: 'warning' });
//     } else {
//       setSnackbar({ open: true, message: '有効なカテゴリー名を入力してください', severity: 'error' });
//     }
//   };

//   const handleSelectCategory = (event) => {
//     setSelectedCategory(event.target.value);
//     setExpandedAccordions([]);
//     setPage(1);
//   };

//   const handleAddValue = (column) => {
//     if (newValue) {
//       setCategories((prevCategories) => {
//         const existingValues = prevCategories[selectedCategory]?.columns[column]?.values || [];
//         const lowerCaseValues = existingValues.map((v) => v.toLowerCase());
//         if (!lowerCaseValues.includes(newValue.toLowerCase())) {
//           return {
//             ...prevCategories,
//             [selectedCategory]: {
//               ...prevCategories[selectedCategory],
//               columns: {
//                 ...prevCategories[selectedCategory].columns,
//                 [column]: {
//                   ...prevCategories[selectedCategory].columns[column],
//                   values: [...existingValues, newValue],
//                   conversionRules: prevCategories[selectedCategory].columns[column]?.conversionRules || [],
//                 },
//               },
//             },
//           };
//         } else {
//           setSnackbar({ open: true, message: `値 "${newValue}" は既に ${column} に存在します`, severity: 'warning' });
//           return prevCategories;
//         }
//       });
//       setSnackbar({ open: true, message: `値 "${newValue}" を ${column} に追加しました`, severity: 'success' });
//       setNewValue('');
//     } else {
//       setSnackbar({ open: true, message: '値を入力してください', severity: 'error' });
//     }
//   };

//   const handleAddColumn = () => {
//     const lowerCaseNewColumn = newColumn.toLowerCase();
//     if (
//       newColumn &&
//       !Object.keys(categories[selectedCategory].columns || {}).some(
//         (col) => col.toLowerCase() === lowerCaseNewColumn
//       )
//     ) {
//       setCategories({
//         ...categories,
//         [selectedCategory]: {
//           ...categories[selectedCategory],
//           columns: {
//             ...categories[selectedCategory].columns,
//             [newColumn]: { values: [], conversionRules: [] },
//           },
//         },
//       });
//       setNewColumn('');
//       setSnackbar({
//         open: true,
//         message: `カラム "${newColumn}" を追加しました`,
//         severity: 'success',
//       });
//     } else if (
//       Object.keys(categories[selectedCategory].columns || {}).some(
//         (col) => col.toLowerCase() === lowerCaseNewColumn
//       )
//     ) {
//       setSnackbar({
//         open: true,
//         message: `カラム "${newColumn}" は既に存在します`,
//         severity: 'warning',
//       });
//     } else {
//       setSnackbar({
//         open: true,
//         message: '有効なカラム名を入力してください',
//         severity: 'error',
//       });
//     }
//   };

//   const handleDeleteValue = useCallback(
//     (column, index) => {
//       setCategories((prevCategories) => {
//         const existingValues = prevCategories[selectedCategory]?.columns[column]?.values || [];
//         return {
//           ...prevCategories,
//           [selectedCategory]: {
//             ...prevCategories[selectedCategory],
//             columns: {
//               ...prevCategories[selectedCategory].columns,
//               [column]: {
//                 ...prevCategories[selectedCategory].columns[column],
//                 values: existingValues.filter((_, i) => i !== index),
//               },
//             },
//           },
//         };
//       });
//       setSnackbar({ open: true, message: `${column} から値を削除しました`, severity: 'success' });
//     },
//     [selectedCategory]
//   );

//   const handleDeleteValueSafe = useCallback(
//     (column, index) => {
//       if (!column || index === undefined || index === null) {
//         console.error('Invalid column or index in handleDeleteValue');
//         setSnackbar({ open: true, message: '削除対象の情報が不正です', severity: 'error' });
//         return;
//       }

//       handleDeleteValue(column, index);
//     },
//     [handleDeleteValue]
//   );

//   const handleDeleteColumn = (column) => {
//     setCategories((prevCategories) => {
//       const { [column]: deletedColumn, ...restColumns } = prevCategories[selectedCategory].columns;
//       return {
//         ...prevCategories,
//         [selectedCategory]: {
//           ...prevCategories[selectedCategory],
//           columns: restColumns,
//         },
//       };
//     });
//     setSnackbar({ open: true, message: `カラム "${column}" を削除しました`, severity: 'success' });
//   };

//   const handleDeleteCategory = () => {
//     const { [selectedCategory]: deletedCategory, ...restCategories } = categories;
//     setCategories(restCategories);
//     setSelectedCategory('');
//     setSnackbar({ open: true, message: `カテゴリー "${selectedCategory}" を削除しました`, severity: 'success' });
//   };

//   const handleBulkInput = () => {
//     const values = bulkInput.split('\n').map((v) => v.trim()).filter((v) => v);
//     setCategories((prevCategories) => {
//       const existingValues = prevCategories[selectedCategory]?.columns[currentColumn]?.values || [];
//       return {
//         ...prevCategories,
//         [selectedCategory]: {
//           ...prevCategories[selectedCategory],
//           columns: {
//             ...prevCategories[selectedCategory].columns,
//             [currentColumn]: {
//               ...prevCategories[selectedCategory].columns[currentColumn],
//               values: [...existingValues, ...values],
//             },
//           },
//         },
//       };
//     });
//     setBulkInput('');
//     setIsBulkInputOpen(false);
//     setSnackbar({ open: true, message: `${values.length} 件の値を ${currentColumn} に追加しました`, severity: 'success' });
//   };

//   const handleSaveSettings = () => {
//     saveItemSpecificsSettings();
//     onSave(categories);
//     onClose();
//   };

//   const handleAccordionChange = (panel) => (event, isExpanded) => {
//     setExpandedAccordions((prev) => (isExpanded ? [...prev, panel] : prev.filter((p) => p !== panel)));
//   };

//   const handleMenuOpen = useCallback((event, item) => {
//     setAnchorEl(event.currentTarget);
//     setSelectedItem(item);
//   }, []);

//   const handleMenuClose = () => {
//     setAnchorEl(null);
//     setSelectedItem(null);
//   };

//   const handleEdit = () => {
//     if (!selectedItem) {
//       setSnackbar({ open: true, message: '編集対象が選択されていません', severity: 'error' });
//       handleMenuClose();
//       return;
//     }

//     const { column, index, value } = selectedItem;
//     if (!column || index === undefined || index === null) {
//       setSnackbar({ open: true, message: '編集対象の情報が不正です', severity: 'error' });
//       handleMenuClose();
//       return;
//     }

//     setEditMode({ active: true, item: selectedItem, value: value });
//     handleMenuClose();
//   };

//   const handleEditCancel = () => {
//     setEditMode({ active: false, item: null, value: '' });
//   };

//   const handleEditSave = useCallback(() => {
//     if (!editMode.item) {
//       console.error('editMode.item is null in handleEditSave');
//       setSnackbar({ open: true, message: '編集対象が選択されていません', severity: 'error' });
//       return;
//     }

//     const { column, index } = editMode.item;
//     if (!column || index === undefined || index === null) {
//       console.error('editMode.item does not have valid column or index');
//       setSnackbar({ open: true, message: '編集対象の情報が不正です', severity: 'error' });
//       return;
//     }

//     setCategories((prevCategories) => {
//       const existingValues = prevCategories[selectedCategory]?.columns[column]?.values || [];
//       return {
//         ...prevCategories,
//         [selectedCategory]: {
//           ...prevCategories[selectedCategory],
//           columns: {
//             ...prevCategories[selectedCategory].columns,
//             [column]: {
//               ...prevCategories[selectedCategory].columns[column],
//               values: existingValues.map((value, i) => (i === index ? editMode.value : value)),
//             },
//           },
//         },
//       };
//     });
//     setEditMode({ active: false, item: null, value: '' });
//     setSnackbar({ open: true, message: '値を更新しました', severity: 'success' });
//   }, [editMode.item, editMode.value, selectedCategory]);

//   const handleDuplicateSettings = () => {
//     if (!selectedCategory || !categories[selectedCategory]) {
//       setSnackbar({
//         open: true,
//         message: '複製元のカテゴリーを選択してください',
//         severity: 'error',
//       });
//       setDuplicateDialogOpen(false);
//       return;
//     }

//     if (!duplicateCategory) {
//       setSnackbar({
//         open: true,
//         message: '有効な複製先のカテゴリー名を入力してください',
//         severity: 'error',
//       });
//       return;
//     }

//     if (categories[duplicateCategory]) {
//       setSnackbar({
//         open: true,
//         message: `カテゴリー "${duplicateCategory}" は既に存在します`,
//         severity: 'warning',
//       });
//       return;
//     }

//     const newCategoryData = JSON.parse(JSON.stringify(categories[selectedCategory]));

//     setCategories((prevCategories) => ({
//       ...prevCategories,
//       [duplicateCategory]: newCategoryData,
//     }));
//     setSnackbar({
//       open: true,
//       message: `設定が "${duplicateCategory}" に複製されました`,
//       severity: 'success',
//     });
//     setDuplicateDialogOpen(false);
//     setDuplicateCategory('');
//   };

//   const renderColumnItems = useCallback(
//     (column, items) => {
//       const safeItems = Array.isArray(items) ? items : [];

//       const filteredItems = safeItems.filter((item) =>
//         item.toLowerCase().includes(search.toLowerCase())
//       );
//       const start = (page - 1) * itemsPerPage;
//       const end = start + itemsPerPage;
//       const paginatedItems = filteredItems.slice(start, end);

//       return (
//         <>
//           <List>
//             {paginatedItems.map((item, index) => {
//               const fullIndex = start + index;
//               return (
//                 <ListItem
//                   key={fullIndex}
//                   secondaryAction={
//                     <IconButton
//                       edge="end"
//                       aria-label="more"
//                       onClick={(e) =>
//                         handleMenuOpen(e, { column, index: fullIndex, value: item })
//                       }
//                     >
//                       <MoreVertIcon />
//                     </IconButton>
//                   }
//                 >
//                   <ListItemText
//                     primary={
//                       editMode.active &&
//                       editMode.item &&
//                       editMode.item.column === column &&
//                       editMode.item.index === fullIndex ? (
//                         <TextField
//                           value={editMode.value}
//                           onChange={(e) =>
//                             setEditMode({ ...editMode, value: e.target.value })
//                           }
//                           onKeyPress={(e) => {
//                             if (e.key === 'Enter') {
//                               handleEditSave();
//                             }
//                           }}
//                           autoFocus
//                           fullWidth
//                         />
//                       ) : (
//                         item
//                       )
//                     }
//                   />
//                 </ListItem>
//               );
//             })}
//           </List>
//           <Pagination
//             count={Math.ceil(filteredItems.length / itemsPerPage)}
//             page={page}
//             onChange={(event, value) => setPage(value)}
//             sx={{ mt: 2 }}
//           />
//         </>
//       );
//     },
//     [search, page, itemsPerPage, editMode, handleMenuOpen, handleEditSave]
//   );

//   const handleAddConversionRule = (column) => {
//     const newId = `rule-${Date.now()}`;
//     setCategories((prevCategories) => {
//       const existingRules = prevCategories[selectedCategory]?.columns[column]?.conversionRules || [];
//       return {
//         ...prevCategories,
//         [selectedCategory]: {
//           ...prevCategories[selectedCategory],
//           columns: {
//             ...prevCategories[selectedCategory].columns,
//             [column]: {
//               ...prevCategories[selectedCategory].columns[column],
//               conversionRules: [
//                 ...existingRules,
//                 { id: newId, ...newRule },
//               ],
//             },
//           },
//         },
//       };
//     });
//     setNewRule({ keyword: '', value: '' });
//     setSnackbar({ open: true, message: '変換ルールを追加しました', severity: 'success' });
//   };

//   const handleDeleteConversionRule = (column, ruleId) => {
//     setCategories((prevCategories) => {
//       const existingRules = prevCategories[selectedCategory]?.columns[column]?.conversionRules || [];
//       return {
//         ...prevCategories,
//         [selectedCategory]: {
//           ...prevCategories[selectedCategory],
//           columns: {
//             ...prevCategories[selectedCategory].columns,
//             [column]: {
//               ...prevCategories[selectedCategory].columns[column],
//               conversionRules: existingRules.filter(
//                 (rule) => rule.id !== ruleId
//               ),
//             },
//           },
//         },
//       };
//     });
//     setSnackbar({ open: true, message: '変換ルールを削除しました', severity: 'info' });
//   };

//   return (
//     <Box sx={{ height: '80vh', display: 'flex', flexDirection: 'column' }}>
//       <Typography variant="h5" gutterBottom>
//         Item Specifics 設定
//       </Typography>

//       {/* カテゴリー選択 */}
//       <Grid container spacing={2} alignItems="center" mb={3}>
//         <Grid item xs={12} sm={6}>
//           <FormControl fullWidth variant="outlined" size="small">
//             <InputLabel>カテゴリーを選択する</InputLabel>
//             <Select
//               value={selectedCategory}
//               onChange={handleSelectCategory}
//               label="カテゴリーを選択する"
//             >
//               {Object.keys(categories).map((category) => (
//                 <MenuItem key={category} value={category}>
//                   {category}
//                 </MenuItem>
//               ))}
//             </Select>
//           </FormControl>
//         </Grid>
//         <Grid item xs={12} sm={6}>
//           <TextField
//             fullWidth
//             label="新しいカテゴリー名を入力"
//             value={newCategory}
//             onChange={(e) => setNewCategory(e.target.value)}
//             variant="outlined"
//             size="small"
//           />
//         </Grid>
//         <Grid item xs={6} sm={3}>
//           <Button
//             fullWidth
//             variant="contained"
//             onClick={handleAddCategory}
//             startIcon={<AddIcon />}
//           >
//             カテゴリーを追加する
//           </Button>
//         </Grid>
//         {selectedCategory && (
//           <>
//             <Grid item xs={6} sm={3}>
//               <Button
//                 fullWidth
//                 variant="outlined"
//                 color="error"
//                 onClick={handleDeleteCategory}
//                 startIcon={<DeleteIcon />}
//               >
//                 カテゴリーを消去する
//               </Button>
//             </Grid>
//             <Grid item xs={12} sm={6}>
//               <Button
//                 fullWidth
//                 variant="outlined"
//                 onClick={() => setDuplicateDialogOpen(true)}
//                 startIcon={<FileCopyIcon />}
//                 disabled={!selectedCategory}
//               >
//                 設定の複製をする
//               </Button>
//             </Grid>
//           </>
//         )}
//       </Grid>

//       {selectedCategory && (
//         <>
//           <Grid container spacing={2} alignItems="center" mb={3}>
//             <Grid item xs={9}>
//               <Autocomplete
//                 freeSolo
//                 options={cColumns}
//                 inputValue={newColumn}
//                 onInputChange={(event, newInputValue) => {
//                   setNewColumn(newInputValue);
//                 }}
//                 renderInput={(params) => (
//                   <TextField
//                     {...params}
//                     fullWidth
//                     label="新しいカラム名を入力 (例: C:Brand)"
//                     variant="outlined"
//                     size="small"
//                   />
//                 )}
//               />
//             </Grid>
//             <Grid item xs={3}>
//               <Button
//                 fullWidth
//                 variant="contained"
//                 onClick={handleAddColumn}
//                 startIcon={<AddIcon />}
//               >
//                 カラムを追加する
//               </Button>
//             </Grid>
//           </Grid>

//           <TextField
//             fullWidth
//             label="検索"
//             value={search}
//             onChange={(e) => setSearch(e.target.value)}
//             variant="outlined"
//             size="small"
//             sx={{ mb: 2 }}
//             InputProps={{
//               startAdornment: (
//                 <InputAdornment position="start">
//                   <SearchIcon />
//                 </InputAdornment>
//               ),
//             }}
//           />

//           <Box sx={{ flexGrow: 1, overflow: 'auto' }}>
//             {Object.entries(categories[selectedCategory].columns || {}).map(([column, columnData]) => (
//               <Accordion
//                 key={column}
//                 expanded={expandedAccordions.includes(column)}
//                 onChange={handleAccordionChange(column)}
//               >
//                 <AccordionSummary expandIcon={<ExpandMoreIcon />}>
//                   <Typography>{column}</Typography>
//                 </AccordionSummary>
//                 <AccordionDetails>
//                   <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
//                     <TextField
//                       label="新しい値を入力"
//                       value={newValue}
//                       onChange={(e) => setNewValue(e.target.value)}
//                       variant="outlined"
//                       size="small"
//                     />
//                     <Button
//                       variant="contained"
//                       onClick={() => handleAddValue(column)}
//                       startIcon={<AddIcon />}
//                     >
//                       値を追加
//                     </Button>
//                     <Button
//                       variant="outlined"
//                       onClick={() => {
//                         setCurrentColumn(column);
//                         setIsBulkInputOpen(true);
//                       }}
//                     >
//                       一括入力する
//                     </Button>
//                     <Button
//                       variant="outlined"
//                       color="error"
//                       onClick={() => handleDeleteColumn(column)}
//                       startIcon={<DeleteIcon />}
//                     >
//                       カラムを削除する
//                     </Button>
//                   </Box>
//                   {renderColumnItems(column, columnData.values)}
//                   <Button
//                     variant="outlined"
//                     onClick={() => {
//                       setCurrentColumn(column);
//                       setIsConversionRuleDialogOpen(true);
//                     }}
//                     sx={{ mt: 2 }}
//                   >
//                     変換ルールの管理
//                   </Button>
//                 </AccordionDetails>
//               </Accordion>
//             ))}
//           </Box>
//         </>
//       )}

//       <Box sx={{ mt: 2, display: 'flex', justifyContent: 'space-between' }}>
//         <Button
//           variant="contained"
//           color="primary"
//           onClick={handleSaveSettings}
//           startIcon={<SaveIcon />}
//         >
//           設定を保存する
//         </Button>

//         <Button
//           variant="outlined"
//           color="secondary"
//           onClick={() => setIsResetDialogOpen(true)}
//           startIcon={<DeleteIcon />}
//         >
//           保存データをリセット
//         </Button>
//       </Box>

//       {/* 一括入力ダイアログ */}
//       <Dialog
//         open={isBulkInputOpen}
//         onClose={() => setIsBulkInputOpen(false)}
//         aria-labelledby="bulk-input-dialog-title"
//         fullWidth
//         maxWidth="md"
//       >
//         <DialogTitle id="bulk-input-dialog-title">{currentColumn} への一括入力</DialogTitle>
//         <DialogContent>
//           <TextField
//             autoFocus
//             multiline
//             rows={10}
//             fullWidth
//             variant="outlined"
//             value={bulkInput}
//             onChange={(e) => setBulkInput(e.target.value)}
//             placeholder="値を入力してください（1行に1つ）"
//             sx={{ mt: 2 }}
//           />
//         </DialogContent>
//         <DialogActions>
//           <Button onClick={() => setIsBulkInputOpen(false)} color="primary">
//             キャンセル
//           </Button>
//           <Button onClick={handleBulkInput} color="primary" variant="contained">
//             追加する
//           </Button>
//         </DialogActions>
//       </Dialog>

//       {/* 設定の複製ダイアログ */}
//       <Dialog
//         open={duplicateDialogOpen}
//         onClose={() => setDuplicateDialogOpen(false)}
//         aria-labelledby="duplicate-dialog-title"
//       >
//         <DialogTitle id="duplicate-dialog-title">他のカテゴリーに設定を複製する</DialogTitle>
//         <DialogContent>
//           <TextField
//             fullWidth
//             label="複製先のカテゴリー名を入力"
//             value={duplicateCategory}
//             onChange={(e) => setDuplicateCategory(e.target.value)}
//             variant="outlined"
//             size="small"
//             sx={{ mt: 2 }}
//             placeholder="新しいカテゴリー名を入力"
//           />
//         </DialogContent>
//         <DialogActions>
//           <Button onClick={() => setDuplicateDialogOpen(false)} color="primary">
//             キャンセル
//           </Button>
//           <Button onClick={handleDuplicateSettings} color="primary" variant="contained">
//             複製する
//           </Button>
//         </DialogActions>
//       </Dialog>

//       {/* リセット確認ダイアログ */}
//       <Dialog
//         open={isResetDialogOpen}
//         onClose={() => setIsResetDialogOpen(false)}
//         aria-labelledby="reset-dialog-title"
//       >
//         <DialogTitle id="reset-dialog-title">確認</DialogTitle>
//         <DialogContent>
//           <Typography>
//             今までの保存していたデータが全て消去されます。
//             <br />
//             本当に削除しますか？
//           </Typography>
//         </DialogContent>
//         <DialogActions>
//           <Button onClick={() => setIsResetDialogOpen(false)} color="primary">
//             キャンセル
//           </Button>
//           <Button
//             onClick={() => {
//               setCategories({});
//               setSelectedCategory('');
//               saveItemSpecificsSettings();
//               setIsResetDialogOpen(false);
//               setSnackbar({
//                 open: true,
//                 message: '保存されたデータをリセットしました',
//                 severity: 'info',
//               });
//             }}
//             color="primary"
//             variant="contained"
//           >
//             削除
//           </Button>
//         </DialogActions>
//       </Dialog>

//       {/* 変換ルール管理ダイアログ */}
//       <Dialog
//         open={isConversionRuleDialogOpen}
//         onClose={() => setIsConversionRuleDialogOpen(false)}
//         maxWidth="sm"
//         fullWidth
//       >
//         <DialogTitle>{currentColumn} の変換ルールの管理</DialogTitle>
//         <DialogContent>
//           <List>
//             {categories[selectedCategory]?.columns[currentColumn]?.conversionRules?.map((rule) => (
//               <ListItem key={rule.id}>
//                 <ListItemText primary={`キーワード: ${rule.keyword}`} secondary={`値: ${rule.value}`} />
//                 <ListItemSecondaryAction>
//                   <IconButton edge="end" onClick={() => handleDeleteConversionRule(currentColumn, rule.id)}>
//                     <DeleteIcon />
//                   </IconButton>
//                 </ListItemSecondaryAction>
//               </ListItem>
//             ))}
//           </List>
//           <Box sx={{ mt: 2 }}>
//             <TextField
//               fullWidth
//               label="キーワード"
//               value={newRule.keyword}
//               onChange={(e) => setNewRule({ ...newRule, keyword: e.target.value })}
//               sx={{ mb: 2 }}
//             />
//             <TextField
//               fullWidth
//               label="値"
//               value={newRule.value}
//               onChange={(e) => setNewRule({ ...newRule, value: e.target.value })}
//               sx={{ mb: 2 }}
//             />
//             <Button
//               variant="contained"
//               onClick={() => handleAddConversionRule(currentColumn)}
//               disabled={!newRule.keyword || !newRule.value}
//             >
//               ルールを追加
//             </Button>
//           </Box>
//         </DialogContent>
//         <DialogActions>
//           <Button onClick={() => setIsConversionRuleDialogOpen(false)}>閉じる</Button>
//         </DialogActions>
//       </Dialog>

//       {/* Snackbar */}
//       <Snackbar
//         open={snackbar.open}
//         autoHideDuration={6000}
//         onClose={() => setSnackbar({ ...snackbar, open: false })}
//         anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
//       >
//         <Alert
//           onClose={() => setSnackbar({ ...snackbar, open: false })}
//           severity={snackbar.severity}
//           variant="filled"
//           sx={{ width: '100%' }}
//         >
//           {snackbar.message}
//         </Alert>
//       </Snackbar>

//       {/* コンテキストメニュー */}
//       <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleMenuClose}>
//         <MenuItem onClick={handleEdit}>
//           <EditIcon fontSize="small" sx={{ mr: 1 }} />
//           編集
//         </MenuItem>
//         <MenuItem
//           onClick={() => {
//             if (selectedItem) {
//               handleDeleteValueSafe(selectedItem.column, selectedItem.index);
//             }
//             handleMenuClose();
//           }}
//         >
//           <DeleteIcon fontSize="small" sx={{ mr: 1 }} />
//           削除
//         </MenuItem>
//       </Menu>

//       {/* 編集ダイアログ */}
//       <Dialog open={editMode.active} onClose={handleEditCancel} aria-labelledby="edit-dialog-title">
//         <DialogTitle id="edit-dialog-title">値を編集する</DialogTitle>
//         <DialogContent>
//           <TextField
//             autoFocus
//             margin="dense"
//             label="値"
//             type="text"
//             fullWidth
//             value={editMode.value}
//             onChange={(e) => setEditMode({ ...editMode, value: e.target.value })}
//           />
//         </DialogContent>
//         <DialogActions>
//           <Button onClick={handleEditCancel} color="primary">
//             キャンセル
//           </Button>
//           <Button onClick={handleEditSave} color="primary">
//             保存
//           </Button>
//         </DialogActions>
//       </Dialog>
//     </Box>
//   );
// };

// export default ItemSpecificsSettings;