// UserTemplateManager.js

import React, { useState } from 'react';
import {
  List,
  ListItem,
  ListItemText,
  IconButton,
  Typography,
  Snackbar,
  Alert,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { doc, updateDoc } from 'firebase/firestore';
import { db } from './firebaseConfig';

function UserTemplateManager({ userTemplates, setUserTemplates, user }) {
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: 'info',
  });

  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [templateToEdit, setTemplateToEdit] = useState(null);
  const [newTemplateName, setNewTemplateName] = useState('');

  // テンプレートを削除する関数
  const handleDeleteTemplate = async (templateName) => {
    if (!user) {
      alert('ユーザーが認証されていません。再度ログインしてください。');
      return;
    }

    try {
      const userTemplatesRef = doc(db, 'userTemplates', user.uid);
      const updatedTemplates = userTemplates.filter(
        (template) => template.name !== templateName
      );
      await updateDoc(userTemplatesRef, {
        templates: updatedTemplates,
      });
      setUserTemplates(updatedTemplates);
      showSnackbar('テンプレートを削除しました。', 'success');
    } catch (error) {
      console.error('テンプレートの削除に失敗しました:', error);
      alert('テンプレートの削除に失敗しました。');
    }
  };

  // テンプレート名の編集を開始する関数
  const handleEditTemplate = (template) => {
    setTemplateToEdit(template);
    setNewTemplateName(template.name);
    setEditDialogOpen(true);
  };

  // テンプレート名を保存する関数
  const handleSaveTemplateName = async () => {
    if (!user) {
      alert('ユーザーが認証されていません。再度ログインしてください。');
      return;
    }

    if (!newTemplateName) {
      alert('テンプレート名を入力してください。');
      return;
    }

    try {
      const userTemplatesRef = doc(db, 'userTemplates', user.uid);
      const updatedTemplates = userTemplates.map((template) => {
        if (template.name === templateToEdit.name) {
          return { ...template, name: newTemplateName };
        }
        return template;
      });
      await updateDoc(userTemplatesRef, {
        templates: updatedTemplates,
      });
      setUserTemplates(updatedTemplates);
      setEditDialogOpen(false);
      showSnackbar('テンプレート名を更新しました。', 'success');
    } catch (error) {
      console.error('テンプレートの更新に失敗しました:', error);
      alert('テンプレートの更新に失敗しました。');
    }
  };

  // Snackbarを表示する関数
  const showSnackbar = (message, severity = 'info') => {
    setSnackbar({
      open: true,
      message,
      severity,
    });
  };

  // Snackbarを閉じる関数
  const handleSnackbarClose = () => {
    setSnackbar({
      ...snackbar,
      open: false,
    });
  };

  return (
    <div>
      <Typography variant="h6" gutterBottom>
        テンプレートの管理
      </Typography>
      <List>
        {userTemplates.map((template) => (
          <ListItem
            key={template.name}
            secondaryAction={
              <>
                <IconButton edge="end" onClick={() => handleEditTemplate(template)}>
                  <EditIcon />
                </IconButton>
                <IconButton edge="end" onClick={() => handleDeleteTemplate(template.name)}>
                  <DeleteIcon />
                </IconButton>
              </>
            }
          >
            <ListItemText primary={template.name} />
          </ListItem>
        ))}
      </List>

      {/* 編集ダイアログ */}
      <Dialog open={editDialogOpen} onClose={() => setEditDialogOpen(false)}>
        <DialogTitle>テンプレート名を編集</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="テンプレート名"
            type="text"
            fullWidth
            value={newTemplateName}
            onChange={(e) => setNewTemplateName(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setEditDialogOpen(false)}>キャンセル</Button>
          <Button onClick={handleSaveTemplateName}>保存</Button>
        </DialogActions>
      </Dialog>

      {/* Snackbar */}
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity={snackbar.severity}
          sx={{ width: '100%' }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </div>
  );
}

export default UserTemplateManager;
