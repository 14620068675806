import React, { useState } from 'react';
import {
  Box,
  Typography,
  List,
  ListItem,
  ListItemText,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const manualContent = [
  {
    title: "はじめに",
    content: [
      "このアプリケーションは、eBay出品用のスプレッドシートの効率的な管理と編集を支援します。",
      "主な機能:",
      "• テンプレート管理: 複数のスプレッドシートに対して一貫した変更を適用",
      "• AIタイトル生成: AIを使用して商品情報から最適なタイトルを生成",
      "• AI商品説明生成: AIを使用して日本語の商品説明から魅力的な英語の商品説明を生成",
      "• Item Specifics追加機能: 商品の詳細情報を簡単に管理・適用",
      "• 列単位の一括編集: 特定の列に対して一括で操作を実行",
      "• CSVインポート/エクスポート: シート全体のデータをCSVファイルとしてインポート/エクスポート",
      "• カラム削除: 不要なカラムを簡単に削除",
      "• データの可視化: スプレッドシートのデータをわかりやすく表示",
      "• 自動保存機能: 編集内容を自動的に保存し、データの損失を防止",
      "• 価格計算設定: カスタマイズ可能な価格計算ロジックを適用",
      "• 収益シミュレーション: 設定した価格に基づいて収益をシミュレート"
    ]
  },
  {
    title: "スプレッドシートの選択",
    content: [
      "1. 「Spreadsheet ID」欄にGoogle SheetsのIDを入力します。",
      "   (例: https://docs.google.com/spreadsheets/d/[ここがID]/edit)",
      "2. 「Spreadsheet Name」欄に任意の名前を入力します。",
      "3. 「読み込み」ボタンをクリックしてシート情報を取得します。",
      "4. ドロップダウンメニューから編集したい対象のシートを選択します。",
      "5. 「データ取得」ボタンをクリックしてスプレッドシートのデータを読み込みます。",
      "6. 「保存」ボタンをクリックして、このスプレッドシート情報を保存します。",
      "注意: スプレッドシートの共有設定で、このアプリケーションからのアクセスが許可されていることを確認してください。"
    ]
  },
  {
    title: "テンプレート管理",
    content: [
      "1. 右上の「テンプレートを開く」ボタンをクリックしてドロワーを開きます。",
      "2. 「新しいカテゴリー」ボタンをクリックしてカテゴリーを作成します。",
      "3. カテゴリー内で「新しいテンプレート」ボタンをクリックしてテンプレートを作成します。",
      "4. テンプレートを選択し、「新しい操作を追加」セクションでカラム名、アクション、値を入力します。",
      "5. 「操作を追加」ボタンをクリックして、テンプレートに操作を追加します。",
      "6. 必要な操作をすべて追加したら、「テンプレートを適用」ボタンをクリックします。",
      "7. テンプレートのエクスポート/インポート機能を使用して、テンプレートを保存または共有できます。",
      "8. テンプレートやカテゴリーの名前変更、複製、削除は右側の「...」メニューから行えます。"
    ]
  },
  {
    title: "AIタイトル生成",
    content: [
      "1. OpenAI APIキーを設定します（初回のみ）。",
      "2. 「AIタイトル生成」セクションに移動します。",
      "3. 「AIでタイトルを生成」ボタンをクリックします。",
      "4. AIが各商品のタイトルを生成するプロセスが開始されます。進捗状況が表示されます。",
      "5. 生成されたタイトルを確認し、「新タイトルを上書き」ボタンをクリックして既存のタイトルを更新します。",
      "6. 「タイトルを80文字に制限」ボタンを使用して、タイトルの長さを調整できます。",
      "注意: タイトル生成にはOpenAI APIキーが必要です。APIキーが設定されていない場合、生成機能は使用できません。"
    ]
  },
  {
    title: "AI商品説明生成",
    content: [
      "1. OpenAI APIキーを設定します（初回のみ）。",
      "2. 「AI商品説明生成」セクションに移動します。",
      "3. 「商品説明を生成する」ボタンをクリックします。",
      "4. AIが各商品の日本語説明を基に、魅力的な英語の商品説明を生成します。進捗状況が表示されます。",
      "5. 生成された商品説明を確認し、「商品説明をスプレッドシートに更新する」ボタンをクリックして既存の説明を更新します。",
      "6. 必要に応じて、生成された説明をプレビューし、編集することができます。",
      "注意: 商品説明生成にはOpenAI APIキーが必要です。APIキーが設定されていない場合、生成機能は使用できません。"
    ]
  },
  {
    title: "Item Specifics追加機能",
    content: [
      "1. 「Item Specifics設定」セクションに移動します。",
      "2. 既存のカテゴリーを選択するか、新しいカテゴリーを作成します。",
      "3. 適用する列を選択し、関連するItem Specificsの値を追加します。",
      "4. 大文字小文字の区別や部分一致のオプションを設定します。",
      "5. 「スプレッドシートを更新」ボタンをクリックして、設定したItem Specificsをスプレッドシートに適用します。",
      "6. 更新前にプレビューを確認し、必要に応じて調整します。",
      "注意: Item Specificsの設定を誤ると、スプレッドシートのデータに意図しない変更が加わる可能性があります。設定前に必ずバックアップを取ることをお勧めします。"
    ]
  },
  {
    title: "カラム削除",
    content: [
      "1. 「カラム削除」セクションで、削除したいカラムを選択します。",
      "2. 複数のカラムを選択できます。",
      "3. 「選択したカラムを削除」ボタンをクリックして、選択したカラムを削除します。",
      "注意: この操作は元に戻せません。慎重に選択してください。"
    ]
  },
  {
    title: "CSVインポート/エクスポート",
    content: [
      "CSVインポート:",
      "1. 「CSVインポート」セクションで「ファイルを選択」をクリックします。",
      "2. インポートしたいCSVファイルを選択します。",
      "3. 「Import CSV」ボタンをクリックしてインポートを実行します。",
      "注意: この操作はシート全体を置き換えます。既存のデータは上書きされます。",
      "",
      "CSVダウンロード:",
      "1. 「CSVダウンロード」セクションの「ダウンロード」ボタンをクリックします。",
      "2. 現在のシートの内容がCSVファイルとしてダウンロードされます。"
    ]
  },
  {
    title: "データの編集",
    content: [
      "• スプレッドシートのデータは画面下部の表に表示されます。",
      "• セルをクリックすると、直接編集が可能になります。",
      "• 編集後、Enterキーを押すか、他のセルをクリックすると変更が保存されます。",
      "• 変更は自動的にGoogle Sheetsに同期されます。",
      "• 'PicURL'カラムの画像はサムネイルとして表示されます。",
      "",
      "注意: 大量のデータを一度に編集する場合は、パフォーマンスに影響が出る可能性があります。その場合は、テンプレート機能やCSVインポート機能の使用をお勧めします。"
    ]
  },
  {
    title: "価格計算設定",
    content: [
      "価格計算機能を使用して、最適な販売価格を算出できます。",
      "1. 左側のドロワーメニューから「価格計算設定」を選択します。",
      "2. 目標利益率を設定します（例：25%）。",
      "3. 各カテゴリー（追加コスト、経費、手数料、送料）の項目を設定します。",
      "4. 各項目は固定金額か割合（%）で設定可能です。",
      "5. 項目はドラッグ＆ドロップで並べ替えられます。",
      "6. 「設定を保存」ボタンをクリックして設定を保存します。",
      "7. 設定はプリセットとして保存でき、後で再利用できます。",
      "",
      "価格計算のロジック:",
      "",
      "1. 基本コスト計算:",
      "  基本コスト = 仕入れ価格 + Σ(固定コスト項目)",
      "",
      "2. 割合ベースの追加コスト計算:",
      "  割合ベースの追加コスト = 基本コスト * Σ(割合コスト項目%)",
      "",
      "3. 目標販売価格計算:",
      "  目標販売価格 = (基本コスト + 割合ベースの追加コスト) / (1 - 目標利益率 - Σ(割合コスト項目%))",
      "",
      "4. 利益計算:",
      "  予想利益 = 目標販売価格 - (基本コスト + 割合ベースの追加コスト)",
      "",
      "5. 実際の利益率計算:",
      "  実際の利益率 = (予想利益 / 目標販売価格) * 100",
      "",
      "注意事項:",
      "• 固定コスト項目には、輸入関税、取扱手数料、包装費用などが含まれます。",
      "• 割合コスト項目には、販売プラットフォームの手数料、決済手数料などが含まれます。",
      "• 目標利益率は、希望する純利益の割合を設定します。",
      "• 計算結果は概算であり、実際の市場状況や競合他社の価格設定を考慮する必要があります。"
    ]
  },
  {
    title: "収益シミュレーション",
    content: [
      "設定した価格計算ロジックに基づいて、収益をシミュレートできます。",
      "1. 左側のドロワーメニューから「価格計算」を選択します。",
      "2. 現在の設定に基づいて価格が再計算されます。",
      "3. 「価格シミュレーション結果」で各商品の新価格、利益、利益率を確認。",
      "4. 「収益シミュレーション実行」で全体の収益予測を表示。",
      "5. シミュレーション結果には総売上、総利益、平均利益率などが含まれます。",
      "6. 「計算した価格を適用」で新価格をスプレッドシートに反映できます。",
      "",
      "収益シミュレーションの詳細:",
      "1. 個別商品シミュレーション",
      "• 新価格: 価格計算ロジックに基づいて計算された各商品の推奨販売価格",
      "• 予想利益: 新価格 - (仕入れ価格 + 全コスト)",
      "• 利益率: (予想利益 / 新価格) * 100",
      "",
      "2. 全体シミュレーション:",
      "  • 総売上: Σ(各商品の新価格)",
      "  • 総コスト: Σ(各商品の仕入れ価格 + 全コスト)",
      "  • 総利益: 総売上 - 総コスト",
      "  • 平均利益率: (総利益 / 総売上) * 100",
      "  • 商品数: シミュレーション対象の商品総数",
      "",
      "3. 追加分析:",
      "  • 最高利益商品: 最も高い利益を生み出す商品とその詳細",
      "  • 最低利益商品: 最も低い利益を生み出す商品とその詳細",
      "  • 利益率分布: 利益率別の商品数分布",
      "",
      "注意事項:",
      "• シミュレーション結果は理論上の数値であり、実際の販売状況により変動する可能性があります。",
      "• 市場動向、競合他社の価格、季節変動などの外部要因は考慮されていません。",
      "• 定期的にシミュレーションを実行し、実際の販売結果と比較して調整することをお勧めします。"
    ]
  },
  {
    title: "画像管理",
    content: [
      "画像ダウンロード:",
      "1. 「画像ダウンロード」セクションで開始行と終了行を指定します。",
      "2. 「画像ダウンロード」ボタンをクリックします。",
      "3. 指定された範囲の画像がZIPファイルとしてダウンロードされます。",
      "",
      "画像URL置換:",
      "1. 「画像URL置換」セクションで開始行と終了行を指定します。",
      "2. 新しい画像URLを1行につき1つずつ入力します。",
      "3. 「Replace URLs」ボタンをクリックして、指定された範囲の画像URLを更新します。"
    ]
  },
  {
    title: "APIキー管理",
    content: [
      "1. OpenAI APIキーを入力します。",
      "2. 「APIキーを記憶する」オプションを選択すると、ブラウザに暗号化して保存されます。",
      "3. 「30分後に自動的にキーをクリアする」オプションで、セキュリティを強化できます。",
      "4. APIキーは暗号化されて保存されますが、公共のデバイスでの使用は避けてください。",
      "5. 「APIキーを設定」ボタンをクリックして、キーを保存します。",
      "6. 保存されたキーは自動的に読み込まれ、必要に応じて再入力や再設定が可能です。"
    ]
  },
  {
    title: "トラブルシューティング",
    content: [
      "• 操作に失敗する場合:",
      "  - スプレッドシートの権限設定を確認してください。",
      "  - アプリケーションに編集権限が付与されていることを確認してください。",
      "  - OpenAI APIキーが正しく設定されているか確認してください（AIタイトル生成・AI商品説明生成機能使用時）。",
      "",
      "• データが表示されない場合:",
      "  - スプレッドシートIDが正しいか確認してください。",
      "  - 選択したシート名が正しいか確認してください。",
      "  - インターネット接続を確認してください。",
      "",
      "• AIタイトル生成やAI商品説明生成に失敗する場合:",
      "  - OpenAI APIキーが正しいか確認してください。",
      "  - インターネット接続を確認してください。",
      "  - APIの利用制限に達していないか確認してください。",
      "",
      "• CSVインポートに失敗する場合:",
      "  - CSVファイルのフォーマットが正しいか確認してください。",
      "  - ファイルサイズが大きすぎないか確認してください。",
      "",
      "• 価格計算や収益シミュレーションの結果が予期しない場合:",
      "  - 価格計算設定を確認し、各項目が正しく設定されているか確認してください。",
      "  - スプレッドシート内のデータ（特にStartPrice列）が正しいか確認してください。",
      "",
      "• テンプレート管理で問題が発生した場合:",
      "  - ブラウザのローカルストレージをクリアして、テンプレートを再インポートしてみてください。",
      "  - テンプレートの操作が複雑すぎる場合は、より小さな操作に分割することを検討してください。",
      "",
      "• Item Specifics追加機能で問題が発生した場合:",
      "  - 設定したカテゴリーや項目が正しいか確認してください。",
      "  - スプレッドシートの対象列が正しく設定されているか確認してください。",
      "",
      "• その他の問題が発生した場合:",
      "  - ブラウザのページを再読み込みしてみてください。",
      "  - アプリケーションからログアウトし、再度ログインしてみてください。",
      "  - ブラウザのキャッシュとクッキーをクリアしてから再試行してください。",
      "",
      "問題が解決しない場合は、アプリケーションの管理者にお問い合わせください。"
    ]
  }
];


function InteractiveManual() {
  const [openSection, setOpenSection] = useState(null);
  const [isHelpDialogOpen, setIsHelpDialogOpen] = useState(false);
  const [currentHelpContent, setCurrentHelpContent] = useState(null);

  const handleSectionToggle = (index) => {
    setOpenSection(openSection === index ? null : index);
  };

  const handleHelpClick = (content) => {
    setCurrentHelpContent(content);
    setIsHelpDialogOpen(true);
  };

  return (
    <Box sx={{ maxWidth: 600, margin: 'auto', p: 3 }}>
      <Typography variant="h4" gutterBottom>
        使い方ガイド
      </Typography>
      {manualContent.map((section, index) => (
        <Accordion
          key={index}
          expanded={openSection === index}
          onChange={() => handleSectionToggle(index)}
        >
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography variant="h6">{section.title}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <List>
              {section.content.map((item, i) => (
                <ListItem key={i}>
                  <ListItemText
                    primary={item}
                    primaryTypographyProps={{ 
                      variant: 'body2',
                      style: { 
                        overflowWrap: 'break-word',
                        wordWrap: 'break-word',
                        hyphens: 'auto'
                      }
                    }}
                  />
                </ListItem>
              ))}
            </List>
            <Button
              variant="outlined"
              color="primary"
              onClick={() => handleHelpClick(section)}
              sx={{ mt: 2 }}
            >
              詳細ヘルプ
            </Button>
          </AccordionDetails>
        </Accordion>
      ))}

      <Dialog
        open={isHelpDialogOpen}
        onClose={() => setIsHelpDialogOpen(false)}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>{currentHelpContent?.title}</DialogTitle>
        <DialogContent>
          <DialogContentText component="div">
            <List>
              {currentHelpContent?.content.map((item, index) => (
                <ListItem key={index}>
                  <ListItemText
                    primary={item}
                    primaryTypographyProps={{ 
                      variant: 'body2',
                      style: { 
                        overflowWrap: 'break-word',
                        wordWrap: 'break-word',
                        hyphens: 'auto'
                      }
                    }}
                  />
                </ListItem>
              ))}
            </List>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsHelpDialogOpen(false)} color="primary">
            閉じる
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}

export default InteractiveManual;