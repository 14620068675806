// OpenAIDescriptionGenerator.js

import React, { useState, useEffect, useCallback, useMemo } from 'react';
import axios from 'axios';
import {
  Paper,
  Button,
  Box,
  Grid,
  Typography,
  Snackbar,
  Alert,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import CachedIcon from '@mui/icons-material/Cached';
import PQueue from 'p-queue';
import OpenAIDescriptionDialog from './OpenAIDescriptionDialog';
import designTemplates from './designTemplates';

import { applyFilters } from './filters';
import { generateProductFeatures as generateLowCostFeatures } from './LowCostAIGenerator';

// sanitizeDescription と generateFormattedDescription をエクスポート
export const sanitizeDescription = (description) => {
  return description.replace(/<\/?(meta|link)(.|\s)*?>/gi, '');
};

export const cleanHTML = (html) => {
  let cleanedHtml = html.replace(/<style[^>]*>[\s\S]*?<\/style>/gi, '');
  cleanedHtml = cleanedHtml.replace(/\sclass="[^"]*"/gi, '');
  cleanedHtml = cleanedHtml.replace(/\s+/g, ' ').trim();
  cleanedHtml = cleanedHtml.replace(/>\s+</g, '><');
  return cleanedHtml;
};

export const generateFormattedDescription = (
  title,
  features,
  description,
  specifications,
  selectedTemplate
) => {
  const template = designTemplates[selectedTemplate];
  const html = template.generateHTML(title, features, description, specifications);
  const cleanedHtml = cleanHTML(html);
  return cleanedHtml;
};

const BATCH_SIZE = 5;
const INITIAL_RETRY_DELAY = 1000;
const MAX_RETRIES = 5;
const CONCURRENCY = 2;
const INTERVAL = 1000;
const INTERVAL_CAP = 5;

const OpenAIDescriptionGenerator = ({ data, setData, apiKey, user }) => {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [lowCostLoading, setLowCostLoading] = useState(false);
  const [error, setError] = useState('');
  const [message, setMessage] = useState('');
  const [products, setProducts] = useState([]);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [focusedProduct, setFocusedProduct] = useState(null);
  const [showPreviewDialog, setShowPreviewDialog] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('info');
  const [previewProduct, setPreviewProduct] = useState(null);
  const [selectedTemplate, setSelectedTemplate] = useState('professional');
  const [progress, setProgress] = useState(0);
  const [customPromptDialogOpen, setCustomPromptDialogOpen] = useState(false);
  const [resetDialogOpen, setResetDialogOpen] = useState(false);

  // フィルタリングオプションのステート
  const [selectedFilters, setSelectedFilters] = useState([]);
  const [customFilterInput, setCustomFilterInput] = useState('');
  const [customFilters, setCustomFilters] = useState([]);

  // テキスト変換のステート
  const [sentencesToDelete, setSentencesToDelete] = useState([]);
  const [wordsToReplace, setWordsToReplace] = useState([]);

  // 必須キーワードのステート
  const [mandatoryKeywords, setMandatoryKeywords] = useState([]);

  // 画像解析の説明を使用するかどうかのステート
  const [useImageDescription, setUseImageDescription] = useState(false);

  const queue = useMemo(
    () =>
      new PQueue({
        concurrency: CONCURRENCY,
        interval: INTERVAL,
        intervalCap: INTERVAL_CAP,
      }),
    []
  );

  const itemsPerPageOptions = [50, 100, 'All'];
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(itemsPerPageOptions[0]);

  useEffect(() => {
    if (open) {
      initializeProducts();
    }
  }, [open, useImageDescription]);

  const handleOpen = () => {
    if (!data || data.length === 0) {
      setError('データがありません。CSVファイルをインポートしてください。');
      setSnackbarMessage('データがありません');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
      return;
    }
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setFocusedProduct(null);
  };

  // 商品データを初期化する関数
  const initializeProducts = () => {
    const headers = Object.keys(data[0]);
    const titleIndex = headers.findIndex((header) => header.toLowerCase() === 'title');
    const jpDescIndex = headers.findIndex((header) => header.toLowerCase() === 'jp_desc');
    const jpImageDescIndex = headers.findIndex((header) => header.toLowerCase() === 'jp_image_description');
    const descriptionIndex = headers.findIndex((header) => header.toLowerCase() === 'description');

    if (titleIndex === -1 || descriptionIndex === -1) {
      setError('必要な列（Title, Description）が見つかりません');
      return;
    }

    // C:~~で始まるカラムを取得
    const cColumns = headers.filter((header) => header.startsWith('C:'));

    const productData = data.map((row, index) => {
      const productId = index;

      // ここで使用する説明文を選択
      let originalJpDesc = '';
      if (useImageDescription) {
        originalJpDesc = row['jp_image_description'] || '';
      } else {
        originalJpDesc = row['jp_desc'] || '';
      }

      // Specificationsのためのキーと値のパラメータを収集
      const specifications = {};

      cColumns.forEach((header) => {
        const value = row[header];
        if (value && value !== 'NA') {
          specifications[header] = value;
        }
      });

      const filteredJpDesc = applyFilters(originalJpDesc, selectedFilters, customFilters);

      return {
        id: productId,
        title: row['Title'] || row['title'] || '',
        jpDesc: originalJpDesc,
        editableJpDesc: filteredJpDesc,
        originalDescription: row['Description'] || row['description'] || '',
        description: row['Description'] || row['description'] || '',
        generatedFeatures: '',
        editableGeneratedFeatures: '',
        updatedDescription: '',
        selected: false,
        isUpdated: false,
        editableFields: {},
        specifications,
      };
    });

    setProducts(productData);
    setSelectedProducts([]);
    setSnackbarMessage('データを正常に読み込みました');
    setSnackbarSeverity('success');
    setSnackbarOpen(true);
  };

  const handleFilterChange = useCallback(
    (label) => {
      setSelectedFilters((prev) =>
        prev.includes(label) ? prev.filter((item) => item !== label) : [...prev, label]
      );
    },
    []
  );

  const applyFiltersToProducts = useCallback(() => {
    setProducts((prevProducts) =>
      prevProducts.map((product) => {
        const filteredDesc = applyFilters(product.jpDesc, selectedFilters, customFilters);
        return { ...product, editableJpDesc: filteredDesc };
      })
    );
  }, [selectedFilters, customFilters]);

  useEffect(() => {
    applyFiltersToProducts();
  }, [selectedFilters, customFilters, applyFiltersToProducts]);

  const handleAddCustomFilter = () => {
    if (customFilterInput.trim() !== '') {
      setCustomFilters([...customFilters, customFilterInput.trim()]);
      setCustomFilterInput('');
    }
  };

  const handleDeleteCustomFilter = (index) => {
    const updatedFilters = [...customFilters];
    updatedFilters.splice(index, 1);
    setCustomFilters(updatedFilters);
  };

  useEffect(() => {
    applyFiltersToProducts();
  }, [customFilters, applyFiltersToProducts]);

  const handleEditJpDesc = useCallback((productId, newJpDesc) => {
    setProducts((prevProducts) =>
      prevProducts.map((product) =>
        product.id === productId ? { ...product, editableJpDesc: newJpDesc } : product
      )
    );
  }, []);

  const generateProductFeatures = async () => {
    if (!apiKey) {
      setError('OpenAI APIキーが設定されていません');
      setSnackbarMessage('OpenAI APIキーが必要です');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
      return;
    }

    if (selectedProducts.length === 0) {
      setError('少なくとも一つの商品を選択してください');
      setSnackbarMessage('商品が選択されていません');
      setSnackbarSeverity('warning');
      setSnackbarOpen(true);
      return;
    }

    setLoading(true);
    setError('');
    setMessage('');
    setProgress(0);

    const selectedProductsData = products.filter(
      (product) => selectedProducts.includes(product.id) && !product.isUpdated
    );
    let processedCount = 0;

    if (selectedProductsData.length === 0) {
      setLoading(false);
      setMessage('選択された商品はすでに更新されています');
      setSnackbarMessage('選択された商品はすでに更新されています');
      setSnackbarSeverity('info');
      setSnackbarOpen(true);
      return;
    }

    for (let i = 0; i < selectedProductsData.length; i += BATCH_SIZE) {
      const batch = selectedProductsData.slice(i, i + BATCH_SIZE);
      try {
        const updatedBatch = await processBatch(batch);
        setProducts((prevProducts) => {
          const newProducts = [...prevProducts];
          updatedBatch.forEach((updatedProduct) => {
            const index = newProducts.findIndex((p) => p.id === updatedProduct.id);
            if (index !== -1) {
              newProducts[index] = updatedProduct;
            }
          });
          return newProducts;
        });
        processedCount += batch.length;
        setProgress((processedCount / selectedProductsData.length) * 100);
      } catch (err) {
        console.error('バッチ処理エラー:', err);
        setError(`バッチ処理に失敗しました: ${err.message}`);
        setSnackbarMessage(`エラー: ${err.message}`);
        setSnackbarSeverity('error');
        setSnackbarOpen(true);
        break;
      }
    }

    setLoading(false);
    setMessage('商品特徴の生成が完了しました');
    setSnackbarMessage('商品特徴の生成が完了しました');
    setSnackbarSeverity('success');
    setSnackbarOpen(true);
    setShowPreviewDialog(true);
  };

  const processBatch = async (batch) => {
    return await Promise.all(
      batch.map(async (product) => {
        let generatedFeatures;
        const productId = product.id;

        // APIコールを行い、説明を生成
        generatedFeatures = await generateFeaturesWithRetry(product.editableJpDesc);

        const sanitizedDescription = sanitizeDescription(product.originalDescription);

        const updatedDescription = generateFormattedDescription(
          product.title,
          generatedFeatures,
          sanitizedDescription,
          product.specifications,
          selectedTemplate
        );

        return {
          ...product,
          generatedFeatures,
          editableGeneratedFeatures: generatedFeatures,
          updatedDescription,
          isUpdated: true,
        };
      })
    );
  };

  const generateFeaturesWithRetry = async (jpDesc, retryCount = 0) => {
    try {
      return await queue.add(() => generateFeatures(jpDesc));
    } catch (error) {
      if (error.response && error.response.status === 429 && retryCount < MAX_RETRIES) {
        const retryAfter = error.response.headers['retry-after'];
        const delay = retryAfter
          ? parseInt(retryAfter) * 1000
          : INITIAL_RETRY_DELAY * Math.pow(2, retryCount);
        const jitter = Math.random() * 1000;
        await new Promise((resolve) => setTimeout(resolve, delay + jitter));
        return generateFeaturesWithRetry(jpDesc, retryCount + 1);
      }
      throw error;
    }
  };

  const generateFeatures = async (jpDesc, maxTokens = 200) => {
    const mandatoryKeywordsPrompt =
      mandatoryKeywords.length > 0
        ? `Also, be sure to include the following keywords: ${mandatoryKeywords.join(', ')}.`
        : '';

    const response = await axios.post(
      'https://api.openai.com/v1/chat/completions',
      {
        model: 'gpt-4o-2024-08-06',
        messages: [
          {
            role: 'system',
            content: `You are eBay's AI assistant specializing in product description optimization. Create effective English product descriptions based on the given Japanese product information. ${mandatoryKeywordsPrompt}
Please provide accurate information to buyers, including the following elements

1. Product Description (if applicable)
   - Brand name (if applicable)
   - Product type
   - Model or product name
2. Detailed Features (if applicable)
   - Color, material, size
3. Condition of the Item (if applicable)
   - Details of usage, scratches, stains, etc. (if used)

Notes:
- No content other than the above will be output.
- Additional information is not required.
- Do not output information related to shipping or options.
- Use clear and concise English.
- Use bullet points to ensure readability.
- If information is unclear or ambiguous, omit the item.
- Use only reliable information and do not include speculative or uncertain information.
- If there is nothing in the Japanese product description, please do not output anything in particular. Only the title is acceptable.

Analyze the given product information and follow the guidelines above to generate the best possible product description in English. Try to provide a detailed and engaging description that will help buyers make a purchase decision. Stay within the specified token count limit.`,
          },
          {
            role: 'user',
            content: `以下の日本語の商品説明を分析し、eBayの商品説明として最適化された英語の説明を生成してください。出力は${maxTokens}トークン以内に収めてください：「${jpDesc}」`,
          },
        ],
        max_tokens: maxTokens,
        temperature: 0.1,
      },
      {
        headers: {
          Authorization: `Bearer ${apiKey}`,
          'Content-Type': 'application/json',
        },
      }
    );

    return response.data.choices[0].message.content.trim();
  };

  const handleSelectAll = useCallback(
    (event) => {
      const updatedProducts = products.map((product) => ({
        ...product,
        selected: event.target.checked,
      }));
      setProducts(updatedProducts);
      setSelectedProducts(event.target.checked ? updatedProducts.map((p) => p.id) : []);
    },
    [products]
  );

  const handleSelectProduct = useCallback(
    (productId) => {
      const updatedProducts = products.map((product) =>
        product.id === productId ? { ...product, selected: !product.selected } : product
      );
      setProducts(updatedProducts);
      setSelectedProducts(updatedProducts.filter((p) => p.selected).map((p) => p.id));
    },
    [products]
  );

  const handleFocusProduct = useCallback((productId) => {
    setFocusedProduct(productId);
  }, []);

  const applyUpdatedDescriptions = async () => {
    setLoading(true);
    setError('');
    setMessage('');

    try {
      const updatedData = [...data];

      products.forEach((product) => {
        if (product.selected && product.updatedDescription) {
          const index = product.id;
          updatedData[index] = {
            ...updatedData[index],
            Description: product.updatedDescription,
          };
        }
      });

      setData(updatedData);
      setMessage('説明文を正常に更新しました');
      setSnackbarMessage('説明文を正常に更新しました');
      setSnackbarSeverity('success');
      setSnackbarOpen(true);
    } catch (err) {
      console.error('説明文更新エラー:', err);
      setError(`説明文の更新に失敗しました: ${err.message}`);
      setSnackbarMessage(`エラー: ${err.message}`);
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    } finally {
      setLoading(false);
    }
  };

  const handlePreviewOpen = (product) => {
    setPreviewProduct(product);
    setShowPreviewDialog(true);
  };

  const handlePreviewClose = () => {
    setPreviewProduct(null);
    setShowPreviewDialog(false);
  };

  const handleEditFeatures = useCallback(
    (productId, newFeatures) => {
      setProducts((prevProducts) =>
        prevProducts.map((product) =>
          product.id === productId
            ? {
                ...product,
                editableGeneratedFeatures: newFeatures,
                updatedDescription: generateFormattedDescription(
                  product.title,
                  newFeatures,
                  sanitizeDescription(product.originalDescription),
                  product.specifications,
                  selectedTemplate
                ),
              }
            : product
        )
      );
    },
    [selectedTemplate]
  );

  const clearCache = () => {
    clearGeneratedDescriptions();
    setProducts((prevProducts) =>
      prevProducts.map((product) => ({
        ...product,
        generatedFeatures: '',
        editableGeneratedFeatures: '',
        updatedDescription: '',
        isUpdated: false,
      }))
    );
  };

  const resetSelectedProductsToInitialCSVData = () => {
    const initialCsvData = JSON.parse(localStorage.getItem('csvData'));

    if (initialCsvData && initialCsvData.length > 0) {
      const updatedData = [...data];

      const headers = Object.keys(initialCsvData[0]);
      const descriptionIndex = headers.findIndex((header) => header.toLowerCase() === 'description');

      if (descriptionIndex === -1) {
        setError('必要な列（Description）が見つかりません');
        return;
      }

      const updatedProducts = products.map((product) => {
        if (product.selected) {
          const index = product.id;
          const initialRow = initialCsvData[index];
          const originalDescription = initialRow['Description'] || initialRow['description'] || '';

          updatedData[index] = {
            ...updatedData[index],
            Description: originalDescription,
          };

          return {
            ...product,
            originalDescription: originalDescription,
            description: originalDescription,
            generatedFeatures: '',
            editableGeneratedFeatures: '',
            updatedDescription: '',
            isUpdated: false,
          };
        }
        return product;
      });

      setProducts(updatedProducts);
      setData(updatedData);

      clearGeneratedDescriptions(false);

      setSnackbarMessage('選択した商品の説明を初期値にリセットしました');
      setSnackbarSeverity('success');
      setSnackbarOpen(true);
    } else {
      setSnackbarMessage('初期データが見つかりません');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    }
  };

  const clearGeneratedDescriptions = (showMessage = true) => {
    localStorage.removeItem('generatedDescriptions');
    if (showMessage) {
      setSnackbarMessage('生成済みの説明をクリアしました');
      setSnackbarSeverity('success');
      setSnackbarOpen(true);
    }
  };

  const indexOfLastItem =
    currentPage * (itemsPerPage === 'All' ? products.length : itemsPerPage);
  const indexOfFirstItem = indexOfLastItem - (itemsPerPage === 'All' ? products.length : itemsPerPage);
  const currentProducts = products.slice(indexOfFirstItem, indexOfLastItem);

  const handleSelectAllOnPage = useCallback(
    (event) => {
      const isSelected = event.target.checked;
      const updatedProducts = products.map((product) => {
        if (currentProducts.some((p) => p.id === product.id)) {
          return { ...product, selected: isSelected };
        }
        return product;
      });
      setProducts(updatedProducts);
      setSelectedProducts(updatedProducts.filter((p) => p.selected).map((p) => p.id));
    },
    [products, currentProducts]
  );

  const handleEditDescription = (productId, newDescription) => {
    setProducts((prevProducts) =>
      prevProducts.map((p) =>
        p.id === productId ? { ...p, updatedDescription: newDescription } : p
      )
    );
  };

  const applyCustomTransformations = (text) => {
    let transformedText = text;

    sentencesToDelete.forEach((sentence) => {
      if (sentence.trim() !== '') {
        const escapedSentence = sentence.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
        const regex = new RegExp(escapedSentence, 'g');
        transformedText = transformedText.replace(regex, '');
      }
    });

    wordsToReplace.forEach(({ from, to }) => {
      if (from.trim() !== '') {
        const escapedFrom = from.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
        const regex = new RegExp(escapedFrom, 'g');
        transformedText = transformedText.replace(regex, to);
      }
    });

    return transformedText;
  };

  const applyTransformationsToSelectedProducts = () => {
    const updatedProducts = products.map((product) => {
      if (product.selected) {
        const transformedFeatures = applyCustomTransformations(product.editableGeneratedFeatures);
        const updatedDescription = generateFormattedDescription(
          product.title,
          transformedFeatures,
          sanitizeDescription(product.originalDescription),
          product.specifications,
          selectedTemplate
        );
        return {
          ...product,
          editableGeneratedFeatures: transformedFeatures,
          updatedDescription,
        };
      }
      return product;
    });
    setProducts(updatedProducts);
  };

  // LowCostAIGeneratorの機能を直接使用
  const handleLowCostAIGeneration = async () => {
    if (!apiKey) {
      setError('OpenAI APIキーが設定されていません');
      setSnackbarMessage('OpenAI APIキーが必要です');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
      return;
    }

    if (selectedProducts.length === 0) {
      setError('少なくとも一つの商品を選択してください');
      setSnackbarMessage('商品が選択されていません');
      setSnackbarSeverity('warning');
      setSnackbarOpen(true);
      return;
    }

    setLowCostLoading(true);
    setError('');
    setMessage('');
    setProgress(0);

    try {
      await generateLowCostFeatures({
        products,
        setProducts,
        selectedProducts,
        apiKey,
        setSnackbarMessage,
        setSnackbarSeverity,
        setSnackbarOpen,
        setError,
        setMessage,
        setProgress,
        queue,
        selectedTemplate,
        applyCustomTransformations,
        sanitizeDescription,
        mandatoryKeywords,
      });
      setShowPreviewDialog(true);
    } catch (error) {
      console.error('低コストAI生成エラー:', error);
      setError(`低コストAI生成に失敗しました: ${error.message}`);
      setSnackbarMessage(`エラー: ${error.message}`);
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    } finally {
      setLowCostLoading(false);
    }
  };

  return (
    <Paper
      elevation={3}
      sx={{
        p: 4,
        mt: 1,
        mb: 1,
        maxWidth: '100%',
        margin: '0 auto',
        overflow: 'hidden',
      }}
    >
      <Box sx={{ maxWidth: '1600px', margin: '0 auto' }}>
        {/* ヘッダーセクション */}
        <Grid container spacing={3} alignItems="center">
          <Grid item xs={12} md={6}>
            <Typography variant="h5" gutterBottom>
              AIを活用した商品説明生成
            </Typography>
            <Typography variant="body1" paragraph>
              日本語の商品説明から英語の商品特徴を自動生成します。
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography variant="subtitle1" gutterBottom>
              主な機能：
            </Typography>
            <Grid container spacing={1}>
              {[
                '日本語の商品説明から英語の商品特徴を抽出',
                'デザインテンプレートからデザインを選択',
                '複数の商品を一括で処理',
                '説明のプレビューと編集',
              ].map((feature, index) => (
                <Grid item xs={6} key={index}>
                  <Typography variant="body2">• {feature}</Typography>
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Grid>

        {/* アクションボタンセクション */}
        <Box sx={{ mt: 3, display: 'flex', gap: '10px', justifyContent: 'center' }}>
          <Button variant="contained" color="primary" onClick={handleOpen} startIcon={<AddIcon />}>
            商品説明をAIで生成する
          </Button>
          <Button variant="outlined" color="primary" onClick={clearCache} startIcon={<CachedIcon />}>
            キャッシュをクリア
          </Button>
        </Box>

        {/* ダイアログコンポーネントをここで使用 */}
        <OpenAIDescriptionDialog
          open={open}
          handleClose={handleClose}
          products={products}
          setProducts={setProducts}
          data={data}
          setData={setData}
          apiKey={apiKey}
          user={user}
          selectedProducts={selectedProducts}
          setSelectedProducts={setSelectedProducts}
          selectedTemplate={selectedTemplate}
          setSelectedTemplate={setSelectedTemplate}
          loading={loading}
          setLoading={setLoading}
          error={error}
          setError={setError}
          message={message}
          setMessage={setMessage}
          generateProductFeatures={generateProductFeatures}
          applyUpdatedDescriptions={applyUpdatedDescriptions}
          handleSelectAll={handleSelectAll}
          handleSelectProduct={handleSelectProduct}
          handleFocusProduct={handleFocusProduct}
          focusedProduct={focusedProduct}
          setFocusedProduct={setFocusedProduct}
          handleEditJpDesc={handleEditJpDesc}
          handleEditDescription={handleEditDescription}
          handleEditFeatures={handleEditFeatures}
          handlePreviewOpen={handlePreviewOpen}
          currentProducts={currentProducts}
          itemsPerPage={itemsPerPage}
          setItemsPerPage={setItemsPerPage}
          itemsPerPageOptions={itemsPerPageOptions}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          handleSelectAllOnPage={handleSelectAllOnPage}
          applyCustomTransformations={applyCustomTransformations}
          sentencesToDelete={sentencesToDelete}
          setSentencesToDelete={setSentencesToDelete}
          wordsToReplace={wordsToReplace}
          setWordsToReplace={setWordsToReplace}
          selectedFilters={selectedFilters}
          setSelectedFilters={setSelectedFilters}
          handleFilterChange={handleFilterChange}
          customFilterInput={customFilterInput}
          setCustomFilterInput={setCustomFilterInput}
          customFilters={customFilters}
          setCustomFilters={setCustomFilters}
          handleAddCustomFilter={handleAddCustomFilter}
          handleDeleteCustomFilter={handleDeleteCustomFilter}
          progress={progress}
          lowCostLoading={lowCostLoading}
          handleLowCostAIGeneration={handleLowCostAIGeneration}
          setCustomPromptDialogOpen={setCustomPromptDialogOpen}
          customPromptDialogOpen={customPromptDialogOpen}
          setResetDialogOpen={setResetDialogOpen}
          resetDialogOpen={resetDialogOpen}
          resetSelectedProductsToInitialCSVData={resetSelectedProductsToInitialCSVData}
          queue={queue}
          applyTransformationsToSelectedProducts={applyTransformationsToSelectedProducts}
          clearCache={clearCache}
          previewProduct={previewProduct}
          setPreviewProduct={setPreviewProduct}
          showPreviewDialog={showPreviewDialog}
          setShowPreviewDialog={setShowPreviewDialog}
          handlePreviewClose={handlePreviewClose}
          snackbarOpen={snackbarOpen}
          setSnackbarOpen={setSnackbarOpen}
          snackbarMessage={snackbarMessage}
          snackbarSeverity={snackbarSeverity}
          setSnackbarMessage={setSnackbarMessage}
          setSnackbarSeverity={setSnackbarSeverity}
          setProgress={setProgress}
          mandatoryKeywords={mandatoryKeywords}
          setMandatoryKeywords={setMandatoryKeywords}
          useImageDescription={useImageDescription}
          setUseImageDescription={setUseImageDescription}
        />
      </Box>

      {/* スナックバー通知 */}
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={() => setSnackbarOpen(false)}
      >
        <Alert onClose={() => setSnackbarOpen(false)} severity={snackbarSeverity} sx={{ width: '100%' }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Paper>
  );
};

export default OpenAIDescriptionGenerator;






// // OpenAIDescriptionGenerator.js

// import React, { useState, useEffect, useCallback, useMemo } from 'react';
// import axios from 'axios';
// import {
//   Paper,
//   Button,
//   Box,
//   Grid,
//   Typography,
//   Snackbar,
//   Alert,
// } from '@mui/material';
// import AddIcon from '@mui/icons-material/Add';
// import CachedIcon from '@mui/icons-material/Cached';
// import PQueue from 'p-queue';
// import OpenAIDescriptionDialog from './OpenAIDescriptionDialog';
// import designTemplates from './designTemplates';

// import { applyFilters } from './filters';
// import { generateProductFeatures as generateLowCostFeatures } from './LowCostAIGenerator';

// const BATCH_SIZE = 5;
// const INITIAL_RETRY_DELAY = 1000;
// const MAX_RETRIES = 5;
// const CONCURRENCY = 2;
// const INTERVAL = 1000;
// const INTERVAL_CAP = 5;

// const OpenAIDescriptionGenerator = ({ data, setData, apiKey, user }) => {
//   const [open, setOpen] = useState(false);
//   const [loading, setLoading] = useState(false);
//   const [lowCostLoading, setLowCostLoading] = useState(false);
//   const [error, setError] = useState('');
//   const [message, setMessage] = useState('');
//   const [products, setProducts] = useState([]);
//   const [selectedProducts, setSelectedProducts] = useState([]);
//   const [focusedProduct, setFocusedProduct] = useState(null);
//   const [showPreviewDialog, setShowPreviewDialog] = useState(false);
//   const [snackbarOpen, setSnackbarOpen] = useState(false);
//   const [snackbarMessage, setSnackbarMessage] = useState('');
//   const [snackbarSeverity, setSnackbarSeverity] = useState('info');
//   const [previewProduct, setPreviewProduct] = useState(null);
//   const [selectedTemplate, setSelectedTemplate] = useState('professional');
//   const [progress, setProgress] = useState(0);
//   const [customPromptDialogOpen, setCustomPromptDialogOpen] = useState(false);
//   const [resetDialogOpen, setResetDialogOpen] = useState(false);

//   // フィルタリングオプションのステート
//   const [selectedFilters, setSelectedFilters] = useState([]);
//   const [customFilterInput, setCustomFilterInput] = useState('');
//   const [customFilters, setCustomFilters] = useState([]);

//   // テキスト変換のステート
//   const [sentencesToDelete, setSentencesToDelete] = useState([]);
//   const [wordsToReplace, setWordsToReplace] = useState([]);

//   // 必須キーワードのステート
//   const [mandatoryKeywords, setMandatoryKeywords] = useState([]);

//   // 画像解析の説明を使用するかどうかのステート
//   const [useImageDescription, setUseImageDescription] = useState(false);

//   const queue = useMemo(
//     () =>
//       new PQueue({
//         concurrency: CONCURRENCY,
//         interval: INTERVAL,
//         intervalCap: INTERVAL_CAP,
//       }),
//     []
//   );

//   const itemsPerPageOptions = [50, 100, 'All'];
//   const [currentPage, setCurrentPage] = useState(1);
//   const [itemsPerPage, setItemsPerPage] = useState(itemsPerPageOptions[0]);

//   useEffect(() => {
//     if (open) {
//       initializeProducts();
//     }
//   }, [open, useImageDescription]);

//   const handleOpen = () => {
//     if (!data || data.length === 0) {
//       setError('データがありません。CSVファイルをインポートしてください。');
//       setSnackbarMessage('データがありません');
//       setSnackbarSeverity('error');
//       setSnackbarOpen(true);
//       return;
//     }
//     setOpen(true);
//   };

//   const handleClose = () => {
//     setOpen(false);
//     setFocusedProduct(null);
//   };

//   // 商品データを初期化する関数
//   const initializeProducts = () => {
//     const headers = Object.keys(data[0]);
//     const titleIndex = headers.findIndex((header) => header.toLowerCase() === 'title');
//     const jpDescIndex = headers.findIndex((header) => header.toLowerCase() === 'jp_desc');
//     const jpImageDescIndex = headers.findIndex((header) => header.toLowerCase() === 'jp_image_description');
//     const descriptionIndex = headers.findIndex((header) => header.toLowerCase() === 'description');

//     if (titleIndex === -1 || descriptionIndex === -1) {
//       setError('必要な列（Title, Description）が見つかりません');
//       return;
//     }

//     // C:~~で始まるカラムを取得
//     const cColumns = headers.filter((header) => header.startsWith('C:'));

//     const productData = data.map((row, index) => {
//       const productId = index;

//       // ここで使用する説明文を選択
//       let originalJpDesc = '';
//       if (useImageDescription) {
//         originalJpDesc = row['jp_image_description'] || '';
//       } else {
//         originalJpDesc = row['jp_desc'] || '';
//       }

//       // Specificationsのためのキーと値のパラメータを収集
//       const specifications = {};

//       cColumns.forEach((header) => {
//         const value = row[header];
//         if (value && value !== 'NA') {
//           specifications[header] = value;
//         }
//       });

//       const filteredJpDesc = applyFilters(originalJpDesc, selectedFilters, customFilters);

//       return {
//         id: productId,
//         title: row['Title'] || row['title'] || '',
//         jpDesc: originalJpDesc,
//         editableJpDesc: filteredJpDesc,
//         originalDescription: row['Description'] || row['description'] || '',
//         description: row['Description'] || row['description'] || '',
//         generatedFeatures: '',
//         editableGeneratedFeatures: '',
//         updatedDescription: '',
//         selected: false,
//         isUpdated: false,
//         editableFields: {},
//         specifications,
//       };
//     });

//     setProducts(productData);
//     setSelectedProducts([]);
//     setSnackbarMessage('データを正常に読み込みました');
//     setSnackbarSeverity('success');
//     setSnackbarOpen(true);
//   };

//   const handleFilterChange = useCallback(
//     (label) => {
//       setSelectedFilters((prev) =>
//         prev.includes(label) ? prev.filter((item) => item !== label) : [...prev, label]
//       );
//     },
//     []
//   );

//   const applyFiltersToProducts = useCallback(() => {
//     setProducts((prevProducts) =>
//       prevProducts.map((product) => {
//         const filteredDesc = applyFilters(product.jpDesc, selectedFilters, customFilters);
//         return { ...product, editableJpDesc: filteredDesc };
//       })
//     );
//   }, [selectedFilters, customFilters]);

//   useEffect(() => {
//     applyFiltersToProducts();
//   }, [selectedFilters, customFilters, applyFiltersToProducts]);

//   const handleAddCustomFilter = () => {
//     if (customFilterInput.trim() !== '') {
//       setCustomFilters([...customFilters, customFilterInput.trim()]);
//       setCustomFilterInput('');
//     }
//   };

//   const handleDeleteCustomFilter = (index) => {
//     const updatedFilters = [...customFilters];
//     updatedFilters.splice(index, 1);
//     setCustomFilters(updatedFilters);
//   };

//   useEffect(() => {
//     applyFiltersToProducts();
//   }, [customFilters, applyFiltersToProducts]);

//   const handleEditJpDesc = useCallback((productId, newJpDesc) => {
//     setProducts((prevProducts) =>
//       prevProducts.map((product) =>
//         product.id === productId ? { ...product, editableJpDesc: newJpDesc } : product
//       )
//     );
//   }, []);

//   const sanitizeDescription = (description) => {
//     return description.replace(/<\/?(meta|link)(.|\s)*?>/gi, '');
//   };

//   const generateProductFeatures = async () => {
//     if (!apiKey) {
//       setError('OpenAI APIキーが設定されていません');
//       setSnackbarMessage('OpenAI APIキーが必要です');
//       setSnackbarSeverity('error');
//       setSnackbarOpen(true);
//       return;
//     }

//     if (selectedProducts.length === 0) {
//       setError('少なくとも一つの商品を選択してください');
//       setSnackbarMessage('商品が選択されていません');
//       setSnackbarSeverity('warning');
//       setSnackbarOpen(true);
//       return;
//     }

//     setLoading(true);
//     setError('');
//     setMessage('');
//     setProgress(0);

//     const selectedProductsData = products.filter(
//       (product) => selectedProducts.includes(product.id) && !product.isUpdated
//     );
//     let processedCount = 0;

//     if (selectedProductsData.length === 0) {
//       setLoading(false);
//       setMessage('選択された商品はすでに更新されています');
//       setSnackbarMessage('選択された商品はすでに更新されています');
//       setSnackbarSeverity('info');
//       setSnackbarOpen(true);
//       return;
//     }

//     for (let i = 0; i < selectedProductsData.length; i += BATCH_SIZE) {
//       const batch = selectedProductsData.slice(i, i + BATCH_SIZE);
//       try {
//         const updatedBatch = await processBatch(batch);
//         setProducts((prevProducts) => {
//           const newProducts = [...prevProducts];
//           updatedBatch.forEach((updatedProduct) => {
//             const index = newProducts.findIndex((p) => p.id === updatedProduct.id);
//             if (index !== -1) {
//               newProducts[index] = updatedProduct;
//             }
//           });
//           return newProducts;
//         });
//         processedCount += batch.length;
//         setProgress((processedCount / selectedProductsData.length) * 100);
//       } catch (err) {
//         console.error('バッチ処理エラー:', err);
//         setError(`バッチ処理に失敗しました: ${err.message}`);
//         setSnackbarMessage(`エラー: ${err.message}`);
//         setSnackbarSeverity('error');
//         setSnackbarOpen(true);
//         break;
//       }
//     }

//     setLoading(false);
//     setMessage('商品特徴の生成が完了しました');
//     setSnackbarMessage('商品特徴の生成が完了しました');
//     setSnackbarSeverity('success');
//     setSnackbarOpen(true);
//     setShowPreviewDialog(true);
//   };

//   const processBatch = async (batch) => {
//     return await Promise.all(
//       batch.map(async (product) => {
//         let generatedFeatures;
//         const productId = product.id;

//         // APIコールを行い、説明を生成
//         generatedFeatures = await generateFeaturesWithRetry(product.editableJpDesc);

//         const sanitizedDescription = sanitizeDescription(product.originalDescription);

//         const updatedDescription = generateFormattedDescription(
//           product.title,
//           generatedFeatures,
//           sanitizedDescription,
//           product.specifications
//         );

//         return {
//           ...product,
//           generatedFeatures,
//           editableGeneratedFeatures: generatedFeatures,
//           updatedDescription,
//           isUpdated: true,
//         };
//       })
//     );
//   };

//   const generateFeaturesWithRetry = async (jpDesc, retryCount = 0) => {
//     try {
//       return await queue.add(() => generateFeatures(jpDesc));
//     } catch (error) {
//       if (error.response && error.response.status === 429 && retryCount < MAX_RETRIES) {
//         const retryAfter = error.response.headers['retry-after'];
//         const delay = retryAfter
//           ? parseInt(retryAfter) * 1000
//           : INITIAL_RETRY_DELAY * Math.pow(2, retryCount);
//         const jitter = Math.random() * 1000;
//         await new Promise((resolve) => setTimeout(resolve, delay + jitter));
//         return generateFeaturesWithRetry(jpDesc, retryCount + 1);
//       }
//       throw error;
//     }
//   };

//   const generateFeatures = async (jpDesc, maxTokens = 200) => {
//     const mandatoryKeywordsPrompt =
//       mandatoryKeywords.length > 0
//         ? `Also, be sure to include the following keywords: ${mandatoryKeywords.join(', ')}.`
//         : '';

//     const response = await axios.post(
//       'https://api.openai.com/v1/chat/completions',
//       {
//         model: 'gpt-4o-2024-08-06',
//         messages: [
//           {
//             role: 'system',
//             content: `You are eBay's AI assistant specializing in product description optimization. Create effective English product descriptions based on the given Japanese product information. ${mandatoryKeywordsPrompt}
// Please provide accurate information to buyers, including the following elements

// 1. Product Description (if applicable)
//    - Brand name (if applicable)
//    - Product type
//    - Model or product name
// 2. Detailed Features (if applicable)
//    - Color, material, size
// 3. Condition of the Item (if applicable)
//    - Details of usage, scratches, stains, etc. (if used)

// Notes:
// - No content other than the above will be output.
// - Additional information is not required.
// - Do not output information related to shipping or options.
// - Use clear and concise English.
// - Use bullet points to ensure readability.
// - If information is unclear or ambiguous, omit the item.
// - Use only reliable information and do not include speculative or uncertain information.
// - If there is nothing in the Japanese product description, please do not output anything in particular. Only the title is acceptable.

// Analyze the given product information and follow the guidelines above to generate the best possible product description in English. Try to provide a detailed and engaging description that will help buyers make a purchase decision. Stay within the specified token count limit.`,
//           },
//           {
//             role: 'user',
//             content: `以下の日本語の商品説明を分析し、eBayの商品説明として最適化された英語の説明を生成してください。出力は${maxTokens}トークン以内に収めてください：「${jpDesc}」`,
//           },
//         ],
//         max_tokens: maxTokens,
//         temperature: 0.1,
//       },
//       {
//         headers: {
//           Authorization: `Bearer ${apiKey}`,
//           'Content-Type': 'application/json',
//         },
//       }
//     );

//     return response.data.choices[0].message.content.trim();
//   };

//   const cleanHTML = (html) => {
//     let cleanedHtml = html.replace(/<style[^>]*>[\s\S]*?<\/style>/gi, '');
//     cleanedHtml = cleanedHtml.replace(/\sclass="[^"]*"/gi, '');
//     cleanedHtml = cleanedHtml.replace(/\s+/g, ' ').trim();
//     cleanedHtml = cleanedHtml.replace(/>\s+</g, '><');
//     return cleanedHtml;
//   };

//   const generateFormattedDescription = (title, features, description, specifications) => {
//     const template = designTemplates[selectedTemplate];
//     const html = template.generateHTML(title, features, description, specifications);
//     const cleanedHtml = cleanHTML(html);
//     return cleanedHtml;
//   };

//   const handleSelectAll = useCallback(
//     (event) => {
//       const updatedProducts = products.map((product) => ({
//         ...product,
//         selected: event.target.checked,
//       }));
//       setProducts(updatedProducts);
//       setSelectedProducts(event.target.checked ? updatedProducts.map((p) => p.id) : []);
//     },
//     [products]
//   );

//   const handleSelectProduct = useCallback(
//     (productId) => {
//       const updatedProducts = products.map((product) =>
//         product.id === productId ? { ...product, selected: !product.selected } : product
//       );
//       setProducts(updatedProducts);
//       setSelectedProducts(updatedProducts.filter((p) => p.selected).map((p) => p.id));
//     },
//     [products]
//   );

//   const handleFocusProduct = useCallback((productId) => {
//     setFocusedProduct(productId);
//   }, []);

//   const applyUpdatedDescriptions = async () => {
//     setLoading(true);
//     setError('');
//     setMessage('');

//     try {
//       const updatedData = [...data];

//       products.forEach((product) => {
//         if (product.selected && product.updatedDescription) {
//           const index = product.id;
//           updatedData[index] = {
//             ...updatedData[index],
//             Description: product.updatedDescription,
//           };
//         }
//       });

//       setData(updatedData);
//       setMessage('説明文を正常に更新しました');
//       setSnackbarMessage('説明文を正常に更新しました');
//       setSnackbarSeverity('success');
//       setSnackbarOpen(true);
//     } catch (err) {
//       console.error('説明文更新エラー:', err);
//       setError(`説明文の更新に失敗しました: ${err.message}`);
//       setSnackbarMessage(`エラー: ${err.message}`);
//       setSnackbarSeverity('error');
//       setSnackbarOpen(true);
//     } finally {
//       setLoading(false);
//     }
//   };

//   const handlePreviewOpen = (product) => {
//     setPreviewProduct(product);
//     setShowPreviewDialog(true);
//   };

//   const handlePreviewClose = () => {
//     setPreviewProduct(null);
//     setShowPreviewDialog(false);
//   };

//   const handleEditFeatures = useCallback((productId, newFeatures) => {
//     setProducts((prevProducts) =>
//       prevProducts.map((product) =>
//         product.id === productId
//           ? {
//               ...product,
//               editableGeneratedFeatures: newFeatures,
//               updatedDescription: generateFormattedDescription(
//                 product.title,
//                 newFeatures,
//                 sanitizeDescription(product.originalDescription),
//                 product.specifications
//               ),
//             }
//           : product
//       )
//     );
//   }, [selectedTemplate]);

//   const clearCache = () => {
//     clearGeneratedDescriptions();
//     setProducts((prevProducts) =>
//       prevProducts.map((product) => ({
//         ...product,
//         generatedFeatures: '',
//         editableGeneratedFeatures: '',
//         updatedDescription: '',
//         isUpdated: false,
//       }))
//     );
//   };

//   const resetSelectedProductsToInitialCSVData = () => {
//     const initialCsvData = JSON.parse(localStorage.getItem('csvData'));

//     if (initialCsvData && initialCsvData.length > 0) {
//       const updatedData = [...data];

//       const headers = Object.keys(initialCsvData[0]);
//       const descriptionIndex = headers.findIndex((header) => header.toLowerCase() === 'description');

//       if (descriptionIndex === -1) {
//         setError('必要な列（Description）が見つかりません');
//         return;
//       }

//       const updatedProducts = products.map((product) => {
//         if (product.selected) {
//           const index = product.id;
//           const initialRow = initialCsvData[index];
//           const originalDescription = initialRow['Description'] || initialRow['description'] || '';

//           updatedData[index] = {
//             ...updatedData[index],
//             Description: originalDescription,
//           };

//           return {
//             ...product,
//             originalDescription: originalDescription,
//             description: originalDescription,
//             generatedFeatures: '',
//             editableGeneratedFeatures: '',
//             updatedDescription: '',
//             isUpdated: false,
//           };
//         }
//         return product;
//       });

//       setProducts(updatedProducts);
//       setData(updatedData);

//       clearGeneratedDescriptions(false);

//       setSnackbarMessage('選択した商品の説明を初期値にリセットしました');
//       setSnackbarSeverity('success');
//       setSnackbarOpen(true);
//     } else {
//       setSnackbarMessage('初期データが見つかりません');
//       setSnackbarSeverity('error');
//       setSnackbarOpen(true);
//     }
//   };

//   const clearGeneratedDescriptions = (showMessage = true) => {
//     localStorage.removeItem('generatedDescriptions');
//     if (showMessage) {
//       setSnackbarMessage('生成済みの説明をクリアしました');
//       setSnackbarSeverity('success');
//       setSnackbarOpen(true);
//     }
//   };

//   const indexOfLastItem =
//     currentPage * (itemsPerPage === 'All' ? products.length : itemsPerPage);
//   const indexOfFirstItem = indexOfLastItem - (itemsPerPage === 'All' ? products.length : itemsPerPage);
//   const currentProducts = products.slice(indexOfFirstItem, indexOfLastItem);

//   const handleSelectAllOnPage = useCallback(
//     (event) => {
//       const isSelected = event.target.checked;
//       const updatedProducts = products.map((product) => {
//         if (currentProducts.some((p) => p.id === product.id)) {
//           return { ...product, selected: isSelected };
//         }
//         return product;
//       });
//       setProducts(updatedProducts);
//       setSelectedProducts(updatedProducts.filter((p) => p.selected).map((p) => p.id));
//     },
//     [products, currentProducts]
//   );

//   const handleEditDescription = (productId, newDescription) => {
//     setProducts((prevProducts) =>
//       prevProducts.map((p) =>
//         p.id === productId ? { ...p, updatedDescription: newDescription } : p
//       )
//     );
//   };

//   const applyCustomTransformations = (text) => {
//     let transformedText = text;

//     sentencesToDelete.forEach((sentence) => {
//       if (sentence.trim() !== '') {
//         const escapedSentence = sentence.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
//         const regex = new RegExp(escapedSentence, 'g');
//         transformedText = transformedText.replace(regex, '');
//       }
//     });

//     wordsToReplace.forEach(({ from, to }) => {
//       if (from.trim() !== '') {
//         const escapedFrom = from.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
//         const regex = new RegExp(escapedFrom, 'g');
//         transformedText = transformedText.replace(regex, to);
//       }
//     });

//     return transformedText;
//   };

//   const applyTransformationsToSelectedProducts = () => {
//     const updatedProducts = products.map((product) => {
//       if (product.selected) {
//         const transformedFeatures = applyCustomTransformations(product.editableGeneratedFeatures);
//         const updatedDescription = generateFormattedDescription(
//           product.title,
//           transformedFeatures,
//           sanitizeDescription(product.originalDescription),
//           product.specifications
//         );
//         return {
//           ...product,
//           editableGeneratedFeatures: transformedFeatures,
//           updatedDescription,
//         };
//       }
//       return product;
//     });
//     setProducts(updatedProducts);
//   };

//   // LowCostAIGeneratorの機能を直接使用
//   const handleLowCostAIGeneration = async () => {
//     if (!apiKey) {
//       setError('OpenAI APIキーが設定されていません');
//       setSnackbarMessage('OpenAI APIキーが必要です');
//       setSnackbarSeverity('error');
//       setSnackbarOpen(true);
//       return;
//     }

//     if (selectedProducts.length === 0) {
//       setError('少なくとも一つの商品を選択してください');
//       setSnackbarMessage('商品が選択されていません');
//       setSnackbarSeverity('warning');
//       setSnackbarOpen(true);
//       return;
//     }

//     setLowCostLoading(true);
//     setError('');
//     setMessage('');
//     setProgress(0);

//     try {
//       await generateLowCostFeatures({
//         products,
//         setProducts,
//         selectedProducts,
//         apiKey,
//         setSnackbarMessage,
//         setSnackbarSeverity,
//         setSnackbarOpen,
//         setError,
//         setMessage,
//         setProgress,
//         queue,
//         selectedTemplate,
//         applyCustomTransformations,
//         sanitizeDescription,
//         mandatoryKeywords,
//       });
//       setShowPreviewDialog(true);
//     } catch (error) {
//       console.error('低コストAI生成エラー:', error);
//       setError(`低コストAI生成に失敗しました: ${error.message}`);
//       setSnackbarMessage(`エラー: ${error.message}`);
//       setSnackbarSeverity('error');
//       setSnackbarOpen(true);
//     } finally {
//       setLowCostLoading(false);
//     }
//   };

//   return (
//     <Paper
//       elevation={3}
//       sx={{
//         p: 4,
//         mt: 1,
//         mb: 1,
//         maxWidth: '100%',
//         margin: '0 auto',
//         overflow: 'hidden',
//       }}
//     >
//       <Box sx={{ maxWidth: '1600px', margin: '0 auto' }}>
//         {/* ヘッダーセクション */}
//         <Grid container spacing={3} alignItems="center">
//           <Grid item xs={12} md={6}>
//             <Typography variant="h5" gutterBottom>
//               AIを活用した商品説明生成
//             </Typography>
//             <Typography variant="body1" paragraph>
//               日本語の商品説明から英語の商品特徴を自動生成します。
//             </Typography>
//           </Grid>
//           <Grid item xs={12} md={6}>
//             <Typography variant="subtitle1" gutterBottom>
//               主な機能：
//             </Typography>
//             <Grid container spacing={1}>
//               {[
//                 '日本語の商品説明から英語の商品特徴を抽出',
//                 'デザインテンプレートからデザインを選択',
//                 '複数の商品を一括で処理',
//                 '説明のプレビューと編集',
//               ].map((feature, index) => (
//                 <Grid item xs={6} key={index}>
//                   <Typography variant="body2">• {feature}</Typography>
//                 </Grid>
//               ))}
//             </Grid>
//           </Grid>
//         </Grid>

//         {/* アクションボタンセクション */}
//         <Box sx={{ mt: 3, display: 'flex', gap: '10px', justifyContent: 'center' }}>
//           <Button variant="contained" color="primary" onClick={handleOpen} startIcon={<AddIcon />}>
//             商品説明をAIで生成する
//           </Button>
//           <Button variant="outlined" color="primary" onClick={clearCache} startIcon={<CachedIcon />}>
//             キャッシュをクリア
//           </Button>
//         </Box>

//         {/* ダイアログコンポーネントをここで使用 */}
//         <OpenAIDescriptionDialog
//           open={open}
//           handleClose={handleClose}
//           products={products}
//           setProducts={setProducts}
//           data={data}
//           setData={setData}
//           apiKey={apiKey}
//           user={user}
//           selectedProducts={selectedProducts}
//           setSelectedProducts={setSelectedProducts}
//           selectedTemplate={selectedTemplate}
//           setSelectedTemplate={setSelectedTemplate}
//           loading={loading}
//           setLoading={setLoading}
//           error={error}
//           setError={setError}
//           message={message}
//           setMessage={setMessage}
//           generateProductFeatures={generateProductFeatures}
//           applyUpdatedDescriptions={applyUpdatedDescriptions}
//           handleSelectAll={handleSelectAll}
//           handleSelectProduct={handleSelectProduct}
//           handleFocusProduct={handleFocusProduct}
//           focusedProduct={focusedProduct}
//           setFocusedProduct={setFocusedProduct}
//           handleEditJpDesc={handleEditJpDesc}
//           handleEditDescription={handleEditDescription}
//           handleEditFeatures={handleEditFeatures}
//           handlePreviewOpen={handlePreviewOpen}
//           currentProducts={currentProducts}
//           itemsPerPage={itemsPerPage}
//           setItemsPerPage={setItemsPerPage}
//           itemsPerPageOptions={itemsPerPageOptions}
//           currentPage={currentPage}
//           setCurrentPage={setCurrentPage}
//           handleSelectAllOnPage={handleSelectAllOnPage}
//           applyCustomTransformations={applyCustomTransformations}
//           sentencesToDelete={sentencesToDelete}
//           setSentencesToDelete={setSentencesToDelete}
//           wordsToReplace={wordsToReplace}
//           setWordsToReplace={setWordsToReplace}
//           selectedFilters={selectedFilters}
//           handleFilterChange={handleFilterChange}
//           customFilterInput={customFilterInput}
//           setCustomFilterInput={setCustomFilterInput}
//           customFilters={customFilters}
//           handleAddCustomFilter={handleAddCustomFilter}
//           handleDeleteCustomFilter={handleDeleteCustomFilter}
//           progress={progress}
//           lowCostLoading={lowCostLoading}
//           handleLowCostAIGeneration={handleLowCostAIGeneration}
//           setCustomPromptDialogOpen={setCustomPromptDialogOpen}
//           customPromptDialogOpen={customPromptDialogOpen}
//           setResetDialogOpen={setResetDialogOpen}
//           resetDialogOpen={resetDialogOpen}
//           resetSelectedProductsToInitialCSVData={resetSelectedProductsToInitialCSVData}
//           queue={queue}
//           applyTransformationsToSelectedProducts={applyTransformationsToSelectedProducts}
//           clearCache={clearCache}
//           previewProduct={previewProduct}
//           setPreviewProduct={setPreviewProduct}
//           showPreviewDialog={showPreviewDialog}
//           setShowPreviewDialog={setShowPreviewDialog}
//           handlePreviewClose={handlePreviewClose}
//           snackbarOpen={snackbarOpen}
//           setSnackbarOpen={setSnackbarOpen}
//           snackbarMessage={snackbarMessage}
//           snackbarSeverity={snackbarSeverity}
//           setSnackbarMessage={setSnackbarMessage}
//           setSnackbarSeverity={setSnackbarSeverity}
//           setProgress={setProgress}
//           mandatoryKeywords={mandatoryKeywords}
//           setMandatoryKeywords={setMandatoryKeywords}
//           useImageDescription={useImageDescription}
//           setUseImageDescription={setUseImageDescription}
//           setSelectedFilters={setSelectedFilters} // 追加
//           setCustomFilters={setCustomFilters} // 追加
//         />
//       </Box>

//       {/* スナックバー通知 */}
//       <Snackbar
//         anchorOrigin={{
//           vertical: 'bottom',
//           horizontal: 'left',
//         }}
//         open={snackbarOpen}
//         autoHideDuration={6000}
//         onClose={() => setSnackbarOpen(false)}
//       >
//         <Alert onClose={() => setSnackbarOpen(false)} severity={snackbarSeverity} sx={{ width: '100%' }}>
//           {snackbarMessage}
//         </Alert>
//       </Snackbar>
//     </Paper>
//   );
// };

// export default OpenAIDescriptionGenerator;



//---------------------------------------------------------------------------------------------------





// // OpenAIDescriptionGenerator.js

// import React, { useState, useEffect, useCallback, useMemo } from 'react';
// import axios from 'axios';
// import {
//   Paper,
//   Button,
//   Box,
//   Grid,
//   Typography,
//   Snackbar,
//   Alert,
// } from '@mui/material';
// import AddIcon from '@mui/icons-material/Add';
// import CachedIcon from '@mui/icons-material/Cached';
// import PQueue from 'p-queue';
// import OpenAIDescriptionDialog from './OpenAIDescriptionDialog';
// import designTemplates from './designTemplates';

// import { applyFilters } from './filters';
// import { generateProductFeatures as generateLowCostFeatures } from './LowCostAIGenerator';

// const BATCH_SIZE = 5;
// const INITIAL_RETRY_DELAY = 1000;
// const MAX_RETRIES = 5;
// const CONCURRENCY = 2;
// const INTERVAL = 1000;
// const INTERVAL_CAP = 5;

// const OpenAIDescriptionGenerator = ({ data, setData, apiKey, user }) => {
//   const [open, setOpen] = useState(false);
//   const [loading, setLoading] = useState(false);
//   const [lowCostLoading, setLowCostLoading] = useState(false);
//   const [error, setError] = useState('');
//   const [message, setMessage] = useState('');
//   const [products, setProducts] = useState([]);
//   const [selectedProducts, setSelectedProducts] = useState([]);
//   const [focusedProduct, setFocusedProduct] = useState(null);
//   const [showPreviewDialog, setShowPreviewDialog] = useState(false);
//   const [snackbarOpen, setSnackbarOpen] = useState(false);
//   const [snackbarMessage, setSnackbarMessage] = useState('');
//   const [snackbarSeverity, setSnackbarSeverity] = useState('info');
//   const [previewProduct, setPreviewProduct] = useState(null);
//   const [selectedTemplate, setSelectedTemplate] = useState('professional');
//   const [progress, setProgress] = useState(0);
//   const [customPromptDialogOpen, setCustomPromptDialogOpen] = useState(false);
//   const [resetDialogOpen, setResetDialogOpen] = useState(false);

//   // フィルタリングオプションのステート
//   const [selectedFilters, setSelectedFilters] = useState([]);
//   const [customFilterInput, setCustomFilterInput] = useState('');
//   const [customFilters, setCustomFilters] = useState([]);

//   // テキスト変換のステート
//   const [sentencesToDelete, setSentencesToDelete] = useState([]);
//   const [wordsToReplace, setWordsToReplace] = useState([]);

//   // 必須キーワードのステート
//   const [mandatoryKeywords, setMandatoryKeywords] = useState([]);

//   const queue = useMemo(
//     () =>
//       new PQueue({
//         concurrency: CONCURRENCY,
//         interval: INTERVAL,
//         intervalCap: INTERVAL_CAP,
//       }),
//     []
//   );

//   const itemsPerPageOptions = [50, 100, 'All'];
//   const [currentPage, setCurrentPage] = useState(1);
//   const [itemsPerPage, setItemsPerPage] = useState(itemsPerPageOptions[0]);

//   useEffect(() => {
//     if (open) {
//       initializeProducts();
//     }
//   }, [open]);

//   const handleOpen = () => {
//     if (!data || data.length === 0) {
//       setError('データがありません。CSVファイルをインポートしてください。');
//       setSnackbarMessage('データがありません');
//       setSnackbarSeverity('error');
//       setSnackbarOpen(true);
//       return;
//     }
//     setOpen(true);
//   };

//   const handleClose = () => {
//     setOpen(false);
//     setFocusedProduct(null);
//   };

//   // 商品データを初期化する関数
//   const initializeProducts = () => {
//     const headers = Object.keys(data[0]);
//     const titleIndex = headers.findIndex((header) => header.toLowerCase() === 'title');
//     const jpDescIndex = headers.findIndex((header) => header.toLowerCase() === 'jp_desc');
//     const descriptionIndex = headers.findIndex((header) => header.toLowerCase() === 'description');

//     if (titleIndex === -1 || jpDescIndex === -1 || descriptionIndex === -1) {
//       setError('必要な列（Title, JP_Desc, Description）が見つかりません');
//       return;
//     }

//     // C:~~で始まるカラムを取得
//     const cColumns = headers.filter((header) => header.startsWith('C:'));

//     const productData = data.map((row, index) => {
//       const productId = index;
//       const originalJpDesc = row['JP_Desc'] || row['jp_desc'] || '';

//       // Specificationsのためのキーと値のパラメータを収集
//       const specifications = {};

//       cColumns.forEach((header) => {
//         const value = row[header];
//         if (value && value !== 'NA') {
//           specifications[header] = value;
//         }
//       });

//       const filteredJpDesc = applyFilters(originalJpDesc, selectedFilters, customFilters);

//       return {
//         id: productId,
//         title: row['Title'] || row['title'] || '',
//         jpDesc: originalJpDesc,
//         editableJpDesc: filteredJpDesc,
//         originalDescription: row['Description'] || row['description'] || '',
//         description: row['Description'] || row['description'] || '',
//         generatedFeatures: '',
//         editableGeneratedFeatures: '',
//         updatedDescription: '',
//         selected: false,
//         isUpdated: false,
//         editableFields: {},
//         specifications,
//       };
//     });

//     setProducts(productData);
//     setSelectedProducts([]);
//     setSnackbarMessage('データを正常に読み込みました');
//     setSnackbarSeverity('success');
//     setSnackbarOpen(true);
//   };

//   const handleFilterChange = useCallback(
//     (label) => {
//       setSelectedFilters((prev) =>
//         prev.includes(label) ? prev.filter((item) => item !== label) : [...prev, label]
//       );
//     },
//     []
//   );

//   const applyFiltersToProducts = useCallback(() => {
//     setProducts((prevProducts) =>
//       prevProducts.map((product) => {
//         const filteredDesc = applyFilters(product.jpDesc, selectedFilters, customFilters);
//         return { ...product, editableJpDesc: filteredDesc };
//       })
//     );
//   }, [selectedFilters, customFilters]);

//   useEffect(() => {
//     applyFiltersToProducts();
//   }, [selectedFilters, customFilters, applyFiltersToProducts]);

//   const handleAddCustomFilter = () => {
//     if (customFilterInput.trim() !== '') {
//       setCustomFilters([...customFilters, customFilterInput.trim()]);
//       setCustomFilterInput('');
//     }
//   };

//   const handleDeleteCustomFilter = (index) => {
//     const updatedFilters = [...customFilters];
//     updatedFilters.splice(index, 1);
//     setCustomFilters(updatedFilters);
//   };

//   useEffect(() => {
//     applyFiltersToProducts();
//   }, [customFilters, applyFiltersToProducts]);

//   const handleEditJpDesc = useCallback((productId, newJpDesc) => {
//     setProducts((prevProducts) =>
//       prevProducts.map((product) =>
//         product.id === productId ? { ...product, editableJpDesc: newJpDesc } : product
//       )
//     );
//   }, []);

//   const sanitizeDescription = (description) => {
//     return description.replace(/<\/?(meta|link)(.|\s)*?>/gi, '');
//   };

//   const generateProductFeatures = async () => {
//     if (!apiKey) {
//       setError('OpenAI APIキーが設定されていません');
//       setSnackbarMessage('OpenAI APIキーが必要です');
//       setSnackbarSeverity('error');
//       setSnackbarOpen(true);
//       return;
//     }

//     if (selectedProducts.length === 0) {
//       setError('少なくとも一つの商品を選択してください');
//       setSnackbarMessage('商品が選択されていません');
//       setSnackbarSeverity('warning');
//       setSnackbarOpen(true);
//       return;
//     }

//     setLoading(true);
//     setError('');
//     setMessage('');
//     setProgress(0);

//     const selectedProductsData = products.filter(
//       (product) => selectedProducts.includes(product.id) && !product.isUpdated
//     );
//     let processedCount = 0;

//     if (selectedProductsData.length === 0) {
//       setLoading(false);
//       setMessage('選択された商品はすでに更新されています');
//       setSnackbarMessage('選択された商品はすでに更新されています');
//       setSnackbarSeverity('info');
//       setSnackbarOpen(true);
//       return;
//     }

//     for (let i = 0; i < selectedProductsData.length; i += BATCH_SIZE) {
//       const batch = selectedProductsData.slice(i, i + BATCH_SIZE);
//       try {
//         const updatedBatch = await processBatch(batch);
//         setProducts((prevProducts) => {
//           const newProducts = [...prevProducts];
//           updatedBatch.forEach((updatedProduct) => {
//             const index = newProducts.findIndex((p) => p.id === updatedProduct.id);
//             if (index !== -1) {
//               newProducts[index] = updatedProduct;
//             }
//           });
//           return newProducts;
//         });
//         processedCount += batch.length;
//         setProgress((processedCount / selectedProductsData.length) * 100);
//       } catch (err) {
//         console.error('バッチ処理エラー:', err);
//         setError(`バッチ処理に失敗しました: ${err.message}`);
//         setSnackbarMessage(`エラー: ${err.message}`);
//         setSnackbarSeverity('error');
//         setSnackbarOpen(true);
//         break;
//       }
//     }

//     setLoading(false);
//     setMessage('商品特徴の生成が完了しました');
//     setSnackbarMessage('商品特徴の生成が完了しました');
//     setSnackbarSeverity('success');
//     setSnackbarOpen(true);
//     setShowPreviewDialog(true);
//   };

//   const processBatch = async (batch) => {
//     return await Promise.all(
//       batch.map(async (product) => {
//         let generatedFeatures;
//         const productId = product.id;

//         // APIコールを行い、説明を生成
//         generatedFeatures = await generateFeaturesWithRetry(product.editableJpDesc);

//         const sanitizedDescription = sanitizeDescription(product.originalDescription);

//         const updatedDescription = generateFormattedDescription(
//           product.title,
//           generatedFeatures,
//           sanitizedDescription,
//           product.specifications
//         );

//         return {
//           ...product,
//           generatedFeatures,
//           editableGeneratedFeatures: generatedFeatures,
//           updatedDescription,
//           isUpdated: true,
//         };
//       })
//     );
//   };

//   const generateFeaturesWithRetry = async (jpDesc, retryCount = 0) => {
//     try {
//       return await queue.add(() => generateFeatures(jpDesc));
//     } catch (error) {
//       if (error.response && error.response.status === 429 && retryCount < MAX_RETRIES) {
//         const retryAfter = error.response.headers['retry-after'];
//         const delay = retryAfter
//           ? parseInt(retryAfter) * 1000
//           : INITIAL_RETRY_DELAY * Math.pow(2, retryCount);
//         const jitter = Math.random() * 1000;
//         await new Promise((resolve) => setTimeout(resolve, delay + jitter));
//         return generateFeaturesWithRetry(jpDesc, retryCount + 1);
//       }
//       throw error;
//     }
//   };

//   const generateFeatures = async (jpDesc, maxTokens = 200) => {
//     const mandatoryKeywordsPrompt =
//       mandatoryKeywords.length > 0
//         ? `Also, be sure to include the following keywords: ${mandatoryKeywords.join(', ')}.`
//         : '';

//     const response = await axios.post(
//       'https://api.openai.com/v1/chat/completions',
//       {
//         model: 'gpt-4o-2024-08-06',
//         messages: [
//           {
//             role: 'system',
//             content: `You are eBay's AI assistant specializing in product description optimization. Create effective English product descriptions based on the given Japanese product information. ${mandatoryKeywordsPrompt}
// Please provide accurate information to buyers, including the following elements

// 1. Product Description (if applicable)
//    - Brand name (if applicable)
//    - Product type
//    - Model or product name
// 2. Detailed Features (if applicable)
//    - Color, material, size
// 3. Condition of the Item (if applicable)
//    - Details of usage, scratches, stains, etc. (if used)

// Notes:
// - No content other than the above will be output.
// - Additional information is not required.
// - Do not output information related to shipping or options.
// - Use clear and concise English.
// - Use bullet points to ensure readability.
// - If information is unclear or ambiguous, omit the item.
// - Use only reliable information and do not include speculative or uncertain information.
// - If there is nothing in the Japanese product description, please do not output anything in particular. Only the title is acceptable.

// Analyze the given product information and follow the guidelines above to generate the best possible product description in English. Try to provide a detailed and engaging description that will help buyers make a purchase decision. Stay within the specified token count limit.`,
//           },
//           {
//             role: 'user',
//             content: `以下の日本語の商品説明を分析し、eBayの商品説明として最適化された英語の説明を生成してください。出力は${maxTokens}トークン以内に収めてください：「${jpDesc}」`,
//           },
//         ],
//         max_tokens: maxTokens,
//         temperature: 0.1,
//       },
//       {
//         headers: {
//           Authorization: `Bearer ${apiKey}`,
//           'Content-Type': 'application/json',
//         },
//       }
//     );

//     return response.data.choices[0].message.content.trim();
//   };

//   const cleanHTML = (html) => {
//     let cleanedHtml = html.replace(/<style[^>]*>[\s\S]*?<\/style>/gi, '');
//     cleanedHtml = cleanedHtml.replace(/\sclass="[^"]*"/gi, '');
//     cleanedHtml = cleanedHtml.replace(/\s+/g, ' ').trim();
//     cleanedHtml = cleanedHtml.replace(/>\s+</g, '><');
//     return cleanedHtml;
//   };

//   const generateFormattedDescription = (title, features, description, specifications) => {
//     const template = designTemplates[selectedTemplate];
//     const html = template.generateHTML(title, features, description, specifications);
//     const cleanedHtml = cleanHTML(html);
//     return cleanedHtml;
//   };

//   const handleSelectAll = useCallback(
//     (event) => {
//       const updatedProducts = products.map((product) => ({
//         ...product,
//         selected: event.target.checked,
//       }));
//       setProducts(updatedProducts);
//       setSelectedProducts(event.target.checked ? updatedProducts.map((p) => p.id) : []);
//     },
//     [products]
//   );

//   const handleSelectProduct = useCallback(
//     (productId) => {
//       const updatedProducts = products.map((product) =>
//         product.id === productId ? { ...product, selected: !product.selected } : product
//       );
//       setProducts(updatedProducts);
//       setSelectedProducts(updatedProducts.filter((p) => p.selected).map((p) => p.id));
//     },
//     [products]
//   );

//   const handleFocusProduct = useCallback((productId) => {
//     setFocusedProduct(productId);
//   }, []);

//   const applyUpdatedDescriptions = async () => {
//     setLoading(true);
//     setError('');
//     setMessage('');

//     try {
//       const updatedData = [...data];

//       products.forEach((product) => {
//         if (product.selected && product.updatedDescription) {
//           const index = product.id;
//           updatedData[index] = {
//             ...updatedData[index],
//             Description: product.updatedDescription,
//           };
//         }
//       });

//       setData(updatedData);
//       setMessage('説明文を正常に更新しました');
//       setSnackbarMessage('説明文を正常に更新しました');
//       setSnackbarSeverity('success');
//       setSnackbarOpen(true);
//     } catch (err) {
//       console.error('説明文更新エラー:', err);
//       setError(`説明文の更新に失敗しました: ${err.message}`);
//       setSnackbarMessage(`エラー: ${err.message}`);
//       setSnackbarSeverity('error');
//       setSnackbarOpen(true);
//     } finally {
//       setLoading(false);
//     }
//   };

//   const handlePreviewOpen = (product) => {
//     setPreviewProduct(product);
//     setShowPreviewDialog(true);
//   };

//   const handlePreviewClose = () => {
//     setPreviewProduct(null);
//     setShowPreviewDialog(false);
//   };

//   // 修正した handleEditFeatures 関数
//   const handleEditFeatures = useCallback((productId, newFeatures) => {
//     setProducts((prevProducts) =>
//       prevProducts.map((product) =>
//         product.id === productId
//           ? {
//               ...product,
//               editableGeneratedFeatures: newFeatures,
//               updatedDescription: generateFormattedDescription(
//                 product.title,
//                 newFeatures,
//                 sanitizeDescription(product.originalDescription),
//                 product.specifications
//               ),
//             }
//           : product
//       )
//     );
//   }, [selectedTemplate]);

//   const clearCache = () => {
//     // キャッシュをクリアする処理
//     clearGeneratedDescriptions();
//     // 商品データをリセット
//     setProducts((prevProducts) =>
//       prevProducts.map((product) => ({
//         ...product,
//         generatedFeatures: '',
//         editableGeneratedFeatures: '',
//         updatedDescription: '',
//         isUpdated: false,
//       }))
//     );
//   };

//   // 初期値に戻す関数（選択された商品だけをリセット）
//   const resetSelectedProductsToInitialCSVData = () => {
//     const initialCsvData = JSON.parse(localStorage.getItem('csvData'));
  
//     if (initialCsvData && initialCsvData.length > 0) {
//       // メインデータをコピー
//       const updatedData = [...data];
  
//       const headers = Object.keys(initialCsvData[0]);
//       const descriptionIndex = headers.findIndex((header) => header.toLowerCase() === 'description');
  
//       if (descriptionIndex === -1) {
//         setError('必要な列（Description）が見つかりません');
//         return;
//       }
  
//       const updatedProducts = products.map((product) => {
//         if (product.selected) {
//           const index = product.id;
//           const initialRow = initialCsvData[index];
//           const originalDescription = initialRow['Description'] || initialRow['description'] || '';
  
//           // メインデータのDescriptionだけを更新
//           updatedData[index] = {
//             ...updatedData[index],
//             Description: originalDescription,
//           };
  
//           return {
//             ...product,
//             originalDescription: originalDescription,
//             description: originalDescription,
//             generatedFeatures: '',
//             editableGeneratedFeatures: '',
//             updatedDescription: '',
//             isUpdated: false,
//           };
//         }
//         return product;
//       });
  
//       setProducts(updatedProducts);
//       setData(updatedData);
  
//       // 選択された商品の生成済み説明やキャッシュをクリア
//       clearGeneratedDescriptions(false);
  
//       setSnackbarMessage('選択した商品の説明を初期値にリセットしました');
//       setSnackbarSeverity('success');
//       setSnackbarOpen(true);
//     } else {
//       setSnackbarMessage('初期データが見つかりません');
//       setSnackbarSeverity('error');
//       setSnackbarOpen(true);
//     }
//   };  

//   const clearGeneratedDescriptions = (showMessage = true) => {
//     localStorage.removeItem('generatedDescriptions');
//     if (showMessage) {
//       setSnackbarMessage('生成済みの説明をクリアしました');
//       setSnackbarSeverity('success');
//       setSnackbarOpen(true);
//     }
//   };

//   const indexOfLastItem =
//     currentPage * (itemsPerPage === 'All' ? products.length : itemsPerPage);
//   const indexOfFirstItem = indexOfLastItem - (itemsPerPage === 'All' ? products.length : itemsPerPage);
//   const currentProducts = products.slice(indexOfFirstItem, indexOfLastItem);

//   const handleSelectAllOnPage = useCallback(
//     (event) => {
//       const isSelected = event.target.checked;
//       const updatedProducts = products.map((product) => {
//         if (currentProducts.some((p) => p.id === product.id)) {
//           return { ...product, selected: isSelected };
//         }
//         return product;
//       });
//       setProducts(updatedProducts);
//       setSelectedProducts(updatedProducts.filter((p) => p.selected).map((p) => p.id));
//     },
//     [products, currentProducts]
//   );

//   const handleEditDescription = (productId, newDescription) => {
//     setProducts((prevProducts) =>
//       prevProducts.map((p) =>
//         p.id === productId ? { ...p, updatedDescription: newDescription } : p
//       )
//     );
//   };

//   const applyCustomTransformations = (text) => {
//     let transformedText = text;

//     sentencesToDelete.forEach((sentence) => {
//       if (sentence.trim() !== '') {
//         const escapedSentence = sentence.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
//         const regex = new RegExp(escapedSentence, 'g');
//         transformedText = transformedText.replace(regex, '');
//       }
//     });

//     wordsToReplace.forEach(({ from, to }) => {
//       if (from.trim() !== '') {
//         const escapedFrom = from.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
//         const regex = new RegExp(escapedFrom, 'g');
//         transformedText = transformedText.replace(regex, to);
//       }
//     });

//     return transformedText;
//   };

//   const applyTransformationsToSelectedProducts = () => {
//     const updatedProducts = products.map((product) => {
//       if (product.selected) {
//         const transformedFeatures = applyCustomTransformations(product.editableGeneratedFeatures);
//         const updatedDescription = generateFormattedDescription(
//           product.title,
//           transformedFeatures,
//           sanitizeDescription(product.originalDescription),
//           product.specifications
//         );
//         return {
//           ...product,
//           editableGeneratedFeatures: transformedFeatures,
//           updatedDescription,
//         };
//       }
//       return product;
//     });
//     setProducts(updatedProducts);
//   };

//   // LowCostAIGeneratorの機能を直接使用
//   const handleLowCostAIGeneration = async () => {
//     if (!apiKey) {
//       setError('OpenAI APIキーが設定されていません');
//       setSnackbarMessage('OpenAI APIキーが必要です');
//       setSnackbarSeverity('error');
//       setSnackbarOpen(true);
//       return;
//     }

//     if (selectedProducts.length === 0) {
//       setError('少なくとも一つの商品を選択してください');
//       setSnackbarMessage('商品が選択されていません');
//       setSnackbarSeverity('warning');
//       setSnackbarOpen(true);
//       return;
//     }

//     setLowCostLoading(true);
//     setError('');
//     setMessage('');
//     setProgress(0);

//     try {
//       await generateLowCostFeatures({
//         products,
//         setProducts,
//         selectedProducts,
//         apiKey,
//         setSnackbarMessage,
//         setSnackbarSeverity,
//         setSnackbarOpen,
//         setError,
//         setMessage,
//         setProgress,
//         queue,
//         selectedTemplate,
//         applyCustomTransformations,
//         sanitizeDescription,
//         mandatoryKeywords, // 追加
//       });
//       setShowPreviewDialog(true);
//     } catch (error) {
//       console.error('低コストAI生成エラー:', error);
//       setError(`低コストAI生成に失敗しました: ${error.message}`);
//       setSnackbarMessage(`エラー: ${error.message}`);
//       setSnackbarSeverity('error');
//       setSnackbarOpen(true);
//     } finally {
//       setLowCostLoading(false);
//     }
//   };

//   return (
//     <Paper
//       elevation={3}
//       sx={{
//         p: 4,
//         mt: 1,
//         mb: 1,
//         maxWidth: '100%',
//         margin: '0 auto',
//         overflow: 'hidden',
//       }}
//     >
//       <Box sx={{ maxWidth: '1600px', margin: '0 auto' }}>
//         {/* ヘッダーセクション */}
//         <Grid container spacing={3} alignItems="center">
//           <Grid item xs={12} md={6}>
//             <Typography variant="h5" gutterBottom>
//               AIを活用した商品説明生成
//             </Typography>
//             <Typography variant="body1" paragraph>
//               日本語の商品説明から英語の商品特徴を自動生成します。
//             </Typography>
//           </Grid>
//           <Grid item xs={12} md={6}>
//             <Typography variant="subtitle1" gutterBottom>
//               主な機能：
//             </Typography>
//             <Grid container spacing={1}>
//               {[
//                 '日本語の商品説明から英語の商品特徴を抽出',
//                 'デザインテンプレートからデザインを選択',
//                 '複数の商品を一括で処理',
//                 '説明のプレビューと編集',
//               ].map((feature, index) => (
//                 <Grid item xs={6} key={index}>
//                   <Typography variant="body2">• {feature}</Typography>
//                 </Grid>
//               ))}
//             </Grid>
//           </Grid>
//         </Grid>

//         {/* アクションボタンセクション */}
//         <Box sx={{ mt: 3, display: 'flex', gap: '10px', justifyContent: 'center' }}>
//           <Button variant="contained" color="primary" onClick={handleOpen} startIcon={<AddIcon />}>
//             商品説明をAIで生成する
//           </Button>
//           <Button variant="outlined" color="primary" onClick={clearCache} startIcon={<CachedIcon />}>
//             キャッシュをクリア
//           </Button>
//         </Box>

//         {/* ダイアログコンポーネントをここで使用 */}
//         <OpenAIDescriptionDialog
//           open={open}
//           handleClose={handleClose}
//           products={products}
//           setProducts={setProducts}
//           data={data}
//           setData={setData}
//           apiKey={apiKey}
//           user={user} // ここでuserを渡します
//           selectedProducts={selectedProducts}
//           setSelectedProducts={setSelectedProducts}
//           selectedTemplate={selectedTemplate}
//           setSelectedTemplate={setSelectedTemplate}
//           loading={loading}
//           setLoading={setLoading}
//           error={error}
//           setError={setError}
//           message={message}
//           setMessage={setMessage}
//           generateProductFeatures={generateProductFeatures}
//           applyUpdatedDescriptions={applyUpdatedDescriptions}
//           handleSelectAll={handleSelectAll}
//           handleSelectProduct={handleSelectProduct}
//           handleFocusProduct={handleFocusProduct}
//           focusedProduct={focusedProduct}
//           setFocusedProduct={setFocusedProduct}
//           handleEditJpDesc={handleEditJpDesc}
//           handleEditDescription={handleEditDescription}
//           handleEditFeatures={handleEditFeatures}
//           handlePreviewOpen={handlePreviewOpen}
//           currentProducts={currentProducts}
//           itemsPerPage={itemsPerPage}
//           setItemsPerPage={setItemsPerPage}
//           itemsPerPageOptions={itemsPerPageOptions}
//           currentPage={currentPage}
//           setCurrentPage={setCurrentPage}
//           handleSelectAllOnPage={handleSelectAllOnPage}
//           applyCustomTransformations={applyCustomTransformations}
//           sentencesToDelete={sentencesToDelete}
//           setSentencesToDelete={setSentencesToDelete}
//           wordsToReplace={wordsToReplace}
//           setWordsToReplace={setWordsToReplace}
//           selectedFilters={selectedFilters}
//           handleFilterChange={handleFilterChange}
//           customFilterInput={customFilterInput}
//           setCustomFilterInput={setCustomFilterInput}
//           customFilters={customFilters}
//           handleAddCustomFilter={handleAddCustomFilter}
//           handleDeleteCustomFilter={handleDeleteCustomFilter}
//           progress={progress}
//           lowCostLoading={lowCostLoading}
//           handleLowCostAIGeneration={handleLowCostAIGeneration}
//           setCustomPromptDialogOpen={setCustomPromptDialogOpen}
//           customPromptDialogOpen={customPromptDialogOpen}
//           setResetDialogOpen={setResetDialogOpen}
//           resetDialogOpen={resetDialogOpen}
//           resetSelectedProductsToInitialCSVData={resetSelectedProductsToInitialCSVData}
//           queue={queue}
//           applyTransformationsToSelectedProducts={applyTransformationsToSelectedProducts}
//           clearCache={clearCache}
//           previewProduct={previewProduct}
//           setPreviewProduct={setPreviewProduct}
//           showPreviewDialog={showPreviewDialog}
//           setShowPreviewDialog={setShowPreviewDialog}
//           handlePreviewClose={handlePreviewClose}
//           snackbarOpen={snackbarOpen}
//           setSnackbarOpen={setSnackbarOpen}
//           snackbarMessage={snackbarMessage}
//           snackbarSeverity={snackbarSeverity}
//           setSnackbarMessage={setSnackbarMessage}
//           setSnackbarSeverity={setSnackbarSeverity}
//           setProgress={setProgress}
//           // 必須キーワードのプロップスを追加
//           mandatoryKeywords={mandatoryKeywords}
//           setMandatoryKeywords={setMandatoryKeywords}
//         />
//       </Box>

//       {/* スナックバー通知 */}
//       <Snackbar
//         anchorOrigin={{
//           vertical: 'bottom',
//           horizontal: 'left',
//         }}
//         open={snackbarOpen}
//         autoHideDuration={6000}
//         onClose={() => setSnackbarOpen(false)}
//       >
//         <Alert onClose={() => setSnackbarOpen(false)} severity={snackbarSeverity} sx={{ width: '100%' }}>
//           {snackbarMessage}
//         </Alert>
//       </Snackbar>
//     </Paper>
//   );
// };

// export default OpenAIDescriptionGenerator;


//---------------------------------------------------------------------------------------------------



// // OpenAIDescriptionGenerator.js

// import React, { useState, useEffect, useCallback, useMemo } from 'react';
// import axios from 'axios';
// import {
//   Paper,
//   Button,
//   Box,
//   Grid,
//   Typography,
//   Snackbar,
//   Alert,
// } from '@mui/material';
// import AddIcon from '@mui/icons-material/Add';
// import CachedIcon from '@mui/icons-material/Cached';
// import PQueue from 'p-queue';
// import OpenAIDescriptionDialog from './OpenAIDescriptionDialog';
// import designTemplates from './designTemplates';

// import { applyFilters } from './filters';
// import { generateProductFeatures as generateLowCostFeatures } from './LowCostAIGenerator';

// const BATCH_SIZE = 5;
// const INITIAL_RETRY_DELAY = 1000;
// const MAX_RETRIES = 5;
// const CONCURRENCY = 2;
// const INTERVAL = 1000;
// const INTERVAL_CAP = 5;

// const OpenAIDescriptionGenerator = ({ data, setData, apiKey, user }) => {
//   const [open, setOpen] = useState(false);
//   const [loading, setLoading] = useState(false);
//   const [lowCostLoading, setLowCostLoading] = useState(false);
//   const [error, setError] = useState('');
//   const [message, setMessage] = useState('');
//   const [products, setProducts] = useState([]);
//   const [selectedProducts, setSelectedProducts] = useState([]);
//   const [focusedProduct, setFocusedProduct] = useState(null);
//   const [showPreviewDialog, setShowPreviewDialog] = useState(false);
//   const [snackbarOpen, setSnackbarOpen] = useState(false);
//   const [snackbarMessage, setSnackbarMessage] = useState('');
//   const [snackbarSeverity, setSnackbarSeverity] = useState('info');
//   const [previewProduct, setPreviewProduct] = useState(null);
//   const [selectedTemplate, setSelectedTemplate] = useState('professional');
//   const [progress, setProgress] = useState(0);
//   const [customPromptDialogOpen, setCustomPromptDialogOpen] = useState(false);
//   const [resetDialogOpen, setResetDialogOpen] = useState(false);

//   // フィルタリングオプションのステート
//   const [selectedFilters, setSelectedFilters] = useState([]);
//   const [customFilterInput, setCustomFilterInput] = useState('');
//   const [customFilters, setCustomFilters] = useState([]);

//   // テキスト変換のステート
//   const [sentencesToDelete, setSentencesToDelete] = useState([]);
//   const [wordsToReplace, setWordsToReplace] = useState([]);

//   // 必須キーワードのステート
//   const [mandatoryKeywords, setMandatoryKeywords] = useState([]);

//   const queue = useMemo(
//     () =>
//       new PQueue({
//         concurrency: CONCURRENCY,
//         interval: INTERVAL,
//         intervalCap: INTERVAL_CAP,
//       }),
//     []
//   );

//   const itemsPerPageOptions = [50, 100, 'All'];
//   const [currentPage, setCurrentPage] = useState(1);
//   const [itemsPerPage, setItemsPerPage] = useState(itemsPerPageOptions[0]);

//   useEffect(() => {
//     if (open) {
//       initializeProducts();
//     }
//   }, [open]);

//   const handleOpen = () => {
//     if (!data || data.length === 0) {
//       setError('データがありません。CSVファイルをインポートしてください。');
//       setSnackbarMessage('データがありません');
//       setSnackbarSeverity('error');
//       setSnackbarOpen(true);
//       return;
//     }
//     setOpen(true);
//   };

//   const handleClose = () => {
//     setOpen(false);
//     setFocusedProduct(null);
//   };

//   // 商品データを初期化する関数
//   const initializeProducts = () => {
//     const headers = Object.keys(data[0]);
//     const titleIndex = headers.findIndex((header) => header.toLowerCase() === 'title');
//     const jpDescIndex = headers.findIndex((header) => header.toLowerCase() === 'jp_desc');
//     const descriptionIndex = headers.findIndex((header) => header.toLowerCase() === 'description');

//     if (titleIndex === -1 || jpDescIndex === -1 || descriptionIndex === -1) {
//       setError('必要な列（Title, JP_Desc, Description）が見つかりません');
//       return;
//     }

//     // C:~~で始まるカラムを取得
//     const cColumns = headers.filter((header) => header.startsWith('C:'));

//     const productData = data.map((row, index) => {
//       const productId = index;
//       const originalJpDesc = row['JP_Desc'] || row['jp_desc'] || '';

//       // Specificationsのためのキーと値のパラメータを収集
//       const specifications = {};

//       cColumns.forEach((header) => {
//         const value = row[header];
//         if (value && value !== 'NA') {
//           specifications[header] = value;
//         }
//       });

//       const filteredJpDesc = applyFilters(originalJpDesc, selectedFilters, customFilters);

//       return {
//         id: productId,
//         title: row['Title'] || row['title'] || '',
//         jpDesc: originalJpDesc,
//         editableJpDesc: filteredJpDesc,
//         originalDescription: row['Description'] || row['description'] || '',
//         description: row['Description'] || row['description'] || '',
//         generatedFeatures: '',
//         editableGeneratedFeatures: '',
//         updatedDescription: '',
//         selected: false,
//         isUpdated: false,
//         editableFields: {},
//         specifications,
//       };
//     });

//     setProducts(productData);
//     setSelectedProducts([]);
//     setSnackbarMessage('データを正常に読み込みました');
//     setSnackbarSeverity('success');
//     setSnackbarOpen(true);
//   };

//   const handleFilterChange = useCallback(
//     (label) => {
//       setSelectedFilters((prev) =>
//         prev.includes(label) ? prev.filter((item) => item !== label) : [...prev, label]
//       );
//     },
//     []
//   );

//   const applyFiltersToProducts = useCallback(() => {
//     setProducts((prevProducts) =>
//       prevProducts.map((product) => {
//         const filteredDesc = applyFilters(product.jpDesc, selectedFilters, customFilters);
//         return { ...product, editableJpDesc: filteredDesc };
//       })
//     );
//   }, [selectedFilters, customFilters]);

//   useEffect(() => {
//     applyFiltersToProducts();
//   }, [selectedFilters, customFilters, applyFiltersToProducts]);

//   const handleAddCustomFilter = () => {
//     if (customFilterInput.trim() !== '') {
//       setCustomFilters([...customFilters, customFilterInput.trim()]);
//       setCustomFilterInput('');
//     }
//   };

//   const handleDeleteCustomFilter = (index) => {
//     const updatedFilters = [...customFilters];
//     updatedFilters.splice(index, 1);
//     setCustomFilters(updatedFilters);
//   };

//   useEffect(() => {
//     applyFiltersToProducts();
//   }, [customFilters, applyFiltersToProducts]);

//   const handleEditJpDesc = useCallback((productId, newJpDesc) => {
//     setProducts((prevProducts) =>
//       prevProducts.map((product) =>
//         product.id === productId ? { ...product, editableJpDesc: newJpDesc } : product
//       )
//     );
//   }, []);

//   const sanitizeDescription = (description) => {
//     return description.replace(/<\/?(meta|link)(.|\s)*?>/gi, '');
//   };

//   const generateProductFeatures = async () => {
//     if (!apiKey) {
//       setError('OpenAI APIキーが設定されていません');
//       setSnackbarMessage('OpenAI APIキーが必要です');
//       setSnackbarSeverity('error');
//       setSnackbarOpen(true);
//       return;
//     }

//     if (selectedProducts.length === 0) {
//       setError('少なくとも一つの商品を選択してください');
//       setSnackbarMessage('商品が選択されていません');
//       setSnackbarSeverity('warning');
//       setSnackbarOpen(true);
//       return;
//     }

//     setLoading(true);
//     setError('');
//     setMessage('');
//     setProgress(0);

//     const selectedProductsData = products.filter(
//       (product) => selectedProducts.includes(product.id) && !product.isUpdated
//     );
//     let processedCount = 0;

//     if (selectedProductsData.length === 0) {
//       setLoading(false);
//       setMessage('選択された商品はすでに更新されています');
//       setSnackbarMessage('選択された商品はすでに更新されています');
//       setSnackbarSeverity('info');
//       setSnackbarOpen(true);
//       return;
//     }

//     for (let i = 0; i < selectedProductsData.length; i += BATCH_SIZE) {
//       const batch = selectedProductsData.slice(i, i + BATCH_SIZE);
//       try {
//         const updatedBatch = await processBatch(batch);
//         setProducts((prevProducts) => {
//           const newProducts = [...prevProducts];
//           updatedBatch.forEach((updatedProduct) => {
//             const index = newProducts.findIndex((p) => p.id === updatedProduct.id);
//             if (index !== -1) {
//               newProducts[index] = updatedProduct;
//             }
//           });
//           return newProducts;
//         });
//         processedCount += batch.length;
//         setProgress((processedCount / selectedProductsData.length) * 100);
//       } catch (err) {
//         console.error('バッチ処理エラー:', err);
//         setError(`バッチ処理に失敗しました: ${err.message}`);
//         setSnackbarMessage(`エラー: ${err.message}`);
//         setSnackbarSeverity('error');
//         setSnackbarOpen(true);
//         break;
//       }
//     }

//     setLoading(false);
//     setMessage('商品特徴の生成が完了しました');
//     setSnackbarMessage('商品特徴の生成が完了しました');
//     setSnackbarSeverity('success');
//     setSnackbarOpen(true);
//     setShowPreviewDialog(true);
//   };

//   const processBatch = async (batch) => {
//     return await Promise.all(
//       batch.map(async (product) => {
//         let generatedFeatures;
//         const productId = product.id;

//         // APIコールを行い、説明を生成
//         generatedFeatures = await generateFeaturesWithRetry(product.editableJpDesc);

//         const sanitizedDescription = sanitizeDescription(product.originalDescription);

//         const updatedDescription = generateFormattedDescription(
//           product.title,
//           generatedFeatures,
//           sanitizedDescription,
//           product.specifications
//         );

//         return {
//           ...product,
//           generatedFeatures,
//           editableGeneratedFeatures: generatedFeatures,
//           updatedDescription,
//           isUpdated: true,
//         };
//       })
//     );
//   };

//   const generateFeaturesWithRetry = async (jpDesc, retryCount = 0) => {
//     try {
//       return await queue.add(() => generateFeatures(jpDesc));
//     } catch (error) {
//       if (error.response && error.response.status === 429 && retryCount < MAX_RETRIES) {
//         const retryAfter = error.response.headers['retry-after'];
//         const delay = retryAfter
//           ? parseInt(retryAfter) * 1000
//           : INITIAL_RETRY_DELAY * Math.pow(2, retryCount);
//         const jitter = Math.random() * 1000;
//         await new Promise((resolve) => setTimeout(resolve, delay + jitter));
//         return generateFeaturesWithRetry(jpDesc, retryCount + 1);
//       }
//       throw error;
//     }
//   };

//   const generateFeatures = async (jpDesc, maxTokens = 200) => {
//     const mandatoryKeywordsPrompt =
//       mandatoryKeywords.length > 0
//         ? `Also, be sure to include the following keywords: ${mandatoryKeywords.join(', ')}.`
//         : '';

//     const response = await axios.post(
//       'https://api.openai.com/v1/chat/completions',
//       {
//         model: 'gpt-4o-2024-08-06',
//         messages: [
//           {
//             role: 'system',
//             content: `You are eBay's AI assistant specializing in product description optimization. Create effective English product descriptions based on the given Japanese product information. ${mandatoryKeywordsPrompt}
// Please provide accurate information to buyers, including the following elements

// 1. Product Description (if applicable)
//    - Brand name (if applicable)
//    - Product type
//    - Model or product name
// 2. Detailed Features (if applicable)
//    - Color, material, size
// 3. Condition of the Item (if applicable)
//    - Details of usage, scratches, stains, etc. (if used)

// Notes:
// - No content other than the above will be output.
// - Additional information is not required.
// - Do not output information related to shipping or options.
// - Use clear and concise English.
// - Use bullet points to ensure readability.
// - If information is unclear or ambiguous, omit the item.
// - Use only reliable information and do not include speculative or uncertain information.
// - If there is nothing in the Japanese product description, please do not output anything in particular. Only the title is acceptable.

// Analyze the given product information and follow the guidelines above to generate the best possible product description in English. Try to provide a detailed and engaging description that will help buyers make a purchase decision. Stay within the specified token count limit.`,
//           },
//           {
//             role: 'user',
//             content: `以下の日本語の商品説明を分析し、eBayの商品説明として最適化された英語の説明を生成してください。出力は${maxTokens}トークン以内に収めてください：「${jpDesc}」`,
//           },
//         ],
//         max_tokens: maxTokens,
//         temperature: 0.1,
//       },
//       {
//         headers: {
//           Authorization: `Bearer ${apiKey}`,
//           'Content-Type': 'application/json',
//         },
//       }
//     );

//     return response.data.choices[0].message.content.trim();
//   };


//   const cleanHTML = (html) => {
//     let cleanedHtml = html.replace(/<style[^>]*>[\s\S]*?<\/style>/gi, '');
//     cleanedHtml = cleanedHtml.replace(/\sclass="[^"]*"/gi, '');
//     cleanedHtml = cleanedHtml.replace(/\s+/g, ' ').trim();
//     cleanedHtml = cleanedHtml.replace(/>\s+</g, '><');
//     return cleanedHtml;
//   };

//   const generateFormattedDescription = (title, features, description, specifications) => {
//     const template = designTemplates[selectedTemplate];
//     const html = template.generateHTML(title, features, description, specifications);
//     const cleanedHtml = cleanHTML(html);
//     return cleanedHtml;
//   };

//   const handleSelectAll = useCallback(
//     (event) => {
//       const updatedProducts = products.map((product) => ({
//         ...product,
//         selected: event.target.checked,
//       }));
//       setProducts(updatedProducts);
//       setSelectedProducts(event.target.checked ? updatedProducts.map((p) => p.id) : []);
//     },
//     [products]
//   );

//   const handleSelectProduct = useCallback(
//     (productId) => {
//       const updatedProducts = products.map((product) =>
//         product.id === productId ? { ...product, selected: !product.selected } : product
//       );
//       setProducts(updatedProducts);
//       setSelectedProducts(updatedProducts.filter((p) => p.selected).map((p) => p.id));
//     },
//     [products]
//   );

//   const handleFocusProduct = useCallback((productId) => {
//     setFocusedProduct(productId);
//   }, []);

//   const applyUpdatedDescriptions = async () => {
//     setLoading(true);
//     setError('');
//     setMessage('');

//     try {
//       const updatedData = [...data];

//       products.forEach((product) => {
//         if (product.selected && product.updatedDescription) {
//           const index = product.id;
//           updatedData[index] = {
//             ...updatedData[index],
//             Description: product.updatedDescription,
//           };
//         }
//       });

//       setData(updatedData);
//       setMessage('説明文を正常に更新しました');
//       setSnackbarMessage('説明文を正常に更新しました');
//       setSnackbarSeverity('success');
//       setSnackbarOpen(true);
//     } catch (err) {
//       console.error('説明文更新エラー:', err);
//       setError(`説明文の更新に失敗しました: ${err.message}`);
//       setSnackbarMessage(`エラー: ${err.message}`);
//       setSnackbarSeverity('error');
//       setSnackbarOpen(true);
//     } finally {
//       setLoading(false);
//     }
//   };

//   const handlePreviewOpen = (product) => {
//     setPreviewProduct(product);
//     setShowPreviewDialog(true);
//   };

//   const handlePreviewClose = () => {
//     setPreviewProduct(null);
//     setShowPreviewDialog(false);
//   };

//   const handleEditFeatures = useCallback((productId, newFeatures) => {
//     setProducts((prevProducts) =>
//       prevProducts.map((product) =>
//         product.id === productId
//           ? {
//               ...product,
//               editableGeneratedFeatures: newFeatures,
//               updatedDescription: generateFormattedDescription(
//                 product.title,
//                 newFeatures,
//                 sanitizeDescription(product.originalDescription),
//                 product.specifications
//               ),
//             }
//           : product
//       )
//     );
//   }, []);

//   const clearCache = () => {
//     // キャッシュをクリアする処理
//     clearGeneratedDescriptions();
//     // 商品データをリセット
//     setProducts((prevProducts) =>
//       prevProducts.map((product) => ({
//         ...product,
//         generatedFeatures: '',
//         editableGeneratedFeatures: '',
//         updatedDescription: '',
//         isUpdated: false,
//       }))
//     );
//   };

//   // 初期値に戻す関数（選択された商品だけをリセット）
//   const resetSelectedProductsToInitialCSVData = () => {
//     const initialCsvData = JSON.parse(localStorage.getItem('csvData'));
  
//     if (initialCsvData && initialCsvData.length > 0) {
//       // メインデータをコピー
//       const updatedData = [...data];
  
//       const headers = Object.keys(initialCsvData[0]);
//       const descriptionIndex = headers.findIndex((header) => header.toLowerCase() === 'description');
  
//       if (descriptionIndex === -1) {
//         setError('必要な列（Description）が見つかりません');
//         return;
//       }
  
//       const updatedProducts = products.map((product) => {
//         if (product.selected) {
//           const index = product.id;
//           const initialRow = initialCsvData[index];
//           const originalDescription = initialRow['Description'] || initialRow['description'] || '';
  
//           // メインデータのDescriptionだけを更新
//           updatedData[index] = {
//             ...updatedData[index],
//             Description: originalDescription,
//           };
  
//           return {
//             ...product,
//             originalDescription: originalDescription,
//             description: originalDescription,
//             generatedFeatures: '',
//             editableGeneratedFeatures: '',
//             updatedDescription: '',
//             isUpdated: false,
//           };
//         }
//         return product;
//       });
  
//       setProducts(updatedProducts);
//       setData(updatedData);
  
//       // 選択された商品の生成済み説明やキャッシュをクリア
//       clearGeneratedDescriptions(false);
  
//       setSnackbarMessage('選択した商品の説明を初期値にリセットしました');
//       setSnackbarSeverity('success');
//       setSnackbarOpen(true);
//     } else {
//       setSnackbarMessage('初期データが見つかりません');
//       setSnackbarSeverity('error');
//       setSnackbarOpen(true);
//     }
//   };  

//   const clearGeneratedDescriptions = (showMessage = true) => {
//     localStorage.removeItem('generatedDescriptions');
//     if (showMessage) {
//       setSnackbarMessage('生成済みの説明をクリアしました');
//       setSnackbarSeverity('success');
//       setSnackbarOpen(true);
//     }
//   };

//   const indexOfLastItem =
//     currentPage * (itemsPerPage === 'All' ? products.length : itemsPerPage);
//   const indexOfFirstItem = indexOfLastItem - (itemsPerPage === 'All' ? products.length : itemsPerPage);
//   const currentProducts = products.slice(indexOfFirstItem, indexOfLastItem);

//   const handleSelectAllOnPage = useCallback(
//     (event) => {
//       const isSelected = event.target.checked;
//       const updatedProducts = products.map((product) => {
//         if (currentProducts.some((p) => p.id === product.id)) {
//           return { ...product, selected: isSelected };
//         }
//         return product;
//       });
//       setProducts(updatedProducts);
//       setSelectedProducts(updatedProducts.filter((p) => p.selected).map((p) => p.id));
//     },
//     [products, currentProducts]
//   );

//   const handleEditDescription = (productId, newDescription) => {
//     setProducts((prevProducts) =>
//       prevProducts.map((p) =>
//         p.id === productId ? { ...p, updatedDescription: newDescription } : p
//       )
//     );
//   };

//   const applyCustomTransformations = (text) => {
//     let transformedText = text;

//     sentencesToDelete.forEach((sentence) => {
//       if (sentence.trim() !== '') {
//         const escapedSentence = sentence.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
//         const regex = new RegExp(escapedSentence, 'g');
//         transformedText = transformedText.replace(regex, '');
//       }
//     });

//     wordsToReplace.forEach(({ from, to }) => {
//       if (from.trim() !== '') {
//         const escapedFrom = from.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
//         const regex = new RegExp(escapedFrom, 'g');
//         transformedText = transformedText.replace(regex, to);
//       }
//     });

//     return transformedText;
//   };

//   const applyTransformationsToSelectedProducts = () => {
//     const updatedProducts = products.map((product) => {
//       if (product.selected) {
//         const transformedFeatures = applyCustomTransformations(product.editableGeneratedFeatures);
//         const updatedDescription = generateFormattedDescription(
//           product.title,
//           transformedFeatures,
//           sanitizeDescription(product.originalDescription),
//           product.specifications
//         );
//         return {
//           ...product,
//           editableGeneratedFeatures: transformedFeatures,
//           updatedDescription,
//         };
//       }
//       return product;
//     });
//     setProducts(updatedProducts);
//   };

//   // LowCostAIGeneratorの機能を直接使用
//   const handleLowCostAIGeneration = async () => {
//     if (!apiKey) {
//       setError('OpenAI APIキーが設定されていません');
//       setSnackbarMessage('OpenAI APIキーが必要です');
//       setSnackbarSeverity('error');
//       setSnackbarOpen(true);
//       return;
//     }

//     if (selectedProducts.length === 0) {
//       setError('少なくとも一つの商品を選択してください');
//       setSnackbarMessage('商品が選択されていません');
//       setSnackbarSeverity('warning');
//       setSnackbarOpen(true);
//       return;
//     }

//     setLowCostLoading(true);
//     setError('');
//     setMessage('');
//     setProgress(0);

//     try {
//       await generateLowCostFeatures({
//         products,
//         setProducts,
//         selectedProducts,
//         apiKey,
//         setSnackbarMessage,
//         setSnackbarSeverity,
//         setSnackbarOpen,
//         setError,
//         setMessage,
//         setProgress,
//         queue,
//         selectedTemplate,
//         applyCustomTransformations,
//         sanitizeDescription,
//         mandatoryKeywords, // 追加
//       });
//       setShowPreviewDialog(true);
//     } catch (error) {
//       console.error('低コストAI生成エラー:', error);
//       setError(`低コストAI生成に失敗しました: ${error.message}`);
//       setSnackbarMessage(`エラー: ${error.message}`);
//       setSnackbarSeverity('error');
//       setSnackbarOpen(true);
//     } finally {
//       setLowCostLoading(false);
//     }
//   };

//   return (
//     <Paper
//       elevation={3}
//       sx={{
//         p: 4,
//         mt: 1,
//         mb: 1,
//         maxWidth: '100%',
//         margin: '0 auto',
//         overflow: 'hidden',
//       }}
//     >
//       <Box sx={{ maxWidth: '1600px', margin: '0 auto' }}>
//         {/* ヘッダーセクション */}
//         <Grid container spacing={3} alignItems="center">
//           <Grid item xs={12} md={6}>
//             <Typography variant="h5" gutterBottom>
//               AIを活用した商品説明生成
//             </Typography>
//             <Typography variant="body1" paragraph>
//               日本語の商品説明から英語の商品特徴を自動生成します。
//             </Typography>
//           </Grid>
//           <Grid item xs={12} md={6}>
//             <Typography variant="subtitle1" gutterBottom>
//               主な機能：
//             </Typography>
//             <Grid container spacing={1}>
//               {[
//                 '日本語の商品説明から英語の商品特徴を抽出',
//                 'デザインテンプレートからデザインを選択',
//                 '複数の商品を一括で処理',
//                 '説明のプレビューと編集',
//               ].map((feature, index) => (
//                 <Grid item xs={6} key={index}>
//                   <Typography variant="body2">• {feature}</Typography>
//                 </Grid>
//               ))}
//             </Grid>
//           </Grid>
//         </Grid>

//         {/* アクションボタンセクション */}
//         <Box sx={{ mt: 3, display: 'flex', gap: '10px', justifyContent: 'center' }}>
//           <Button variant="contained" color="primary" onClick={handleOpen} startIcon={<AddIcon />}>
//             商品説明をAIで生成する
//           </Button>
//           <Button variant="outlined" color="primary" onClick={clearCache} startIcon={<CachedIcon />}>
//             キャッシュをクリア
//           </Button>
//         </Box>

//         {/* ダイアログコンポーネントをここで使用 */}
//         <OpenAIDescriptionDialog
//           open={open}
//           handleClose={handleClose}
//           products={products}
//           setProducts={setProducts}
//           data={data}
//           setData={setData}
//           apiKey={apiKey}
//           user={user} // ここでuserを渡します
//           selectedProducts={selectedProducts}
//           setSelectedProducts={setSelectedProducts}
//           selectedTemplate={selectedTemplate}
//           setSelectedTemplate={setSelectedTemplate}
//           loading={loading}
//           setLoading={setLoading}
//           error={error}
//           setError={setError}
//           message={message}
//           setMessage={setMessage}
//           generateProductFeatures={generateProductFeatures}
//           applyUpdatedDescriptions={applyUpdatedDescriptions}
//           handleSelectAll={handleSelectAll}
//           handleSelectProduct={handleSelectProduct}
//           handleFocusProduct={handleFocusProduct}
//           focusedProduct={focusedProduct}
//           setFocusedProduct={setFocusedProduct}
//           handleEditJpDesc={handleEditJpDesc}
//           handleEditDescription={handleEditDescription}
//           handleEditFeatures={handleEditFeatures}
//           handlePreviewOpen={handlePreviewOpen}
//           currentProducts={currentProducts}
//           itemsPerPage={itemsPerPage}
//           setItemsPerPage={setItemsPerPage}
//           itemsPerPageOptions={itemsPerPageOptions}
//           currentPage={currentPage}
//           setCurrentPage={setCurrentPage}
//           handleSelectAllOnPage={handleSelectAllOnPage}
//           applyCustomTransformations={applyCustomTransformations}
//           sentencesToDelete={sentencesToDelete}
//           setSentencesToDelete={setSentencesToDelete}
//           wordsToReplace={wordsToReplace}
//           setWordsToReplace={setWordsToReplace}
//           selectedFilters={selectedFilters}
//           handleFilterChange={handleFilterChange}
//           customFilterInput={customFilterInput}
//           setCustomFilterInput={setCustomFilterInput}
//           customFilters={customFilters}
//           handleAddCustomFilter={handleAddCustomFilter}
//           handleDeleteCustomFilter={handleDeleteCustomFilter}
//           progress={progress}
//           lowCostLoading={lowCostLoading}
//           handleLowCostAIGeneration={handleLowCostAIGeneration}
//           setCustomPromptDialogOpen={setCustomPromptDialogOpen}
//           customPromptDialogOpen={customPromptDialogOpen}
//           setResetDialogOpen={setResetDialogOpen}
//           resetDialogOpen={resetDialogOpen}
//           resetSelectedProductsToInitialCSVData={resetSelectedProductsToInitialCSVData}
//           queue={queue}
//           applyTransformationsToSelectedProducts={applyTransformationsToSelectedProducts}
//           clearCache={clearCache}
//           previewProduct={previewProduct}
//           setPreviewProduct={setPreviewProduct}
//           showPreviewDialog={showPreviewDialog}
//           setShowPreviewDialog={setShowPreviewDialog}
//           handlePreviewClose={handlePreviewClose}
//           snackbarOpen={snackbarOpen}
//           setSnackbarOpen={setSnackbarOpen}
//           snackbarMessage={snackbarMessage}
//           snackbarSeverity={snackbarSeverity}
//           setSnackbarMessage={setSnackbarMessage}
//           setSnackbarSeverity={setSnackbarSeverity}
//           setProgress={setProgress}
//           // 必須キーワードのプロップスを追加
//           mandatoryKeywords={mandatoryKeywords}
//           setMandatoryKeywords={setMandatoryKeywords}
//         />
//       </Box>

//       {/* スナックバー通知 */}
//       <Snackbar
//         anchorOrigin={{
//           vertical: 'bottom',
//           horizontal: 'left',
//         }}
//         open={snackbarOpen}
//         autoHideDuration={6000}
//         onClose={() => setSnackbarOpen(false)}
//       >
//         <Alert onClose={() => setSnackbarOpen(false)} severity={snackbarSeverity} sx={{ width: '100%' }}>
//           {snackbarMessage}
//         </Alert>
//       </Snackbar>
//     </Paper>
//   );
// };

// export default OpenAIDescriptionGenerator;





// // OpenAIDescriptionGenerator.js

// import React, { useState, useEffect, useCallback, useMemo } from 'react';
// import axios from 'axios';
// import {
//   Paper,
//   Button,
//   Box,
//   Grid,
//   Typography,
//   Snackbar,
//   Alert,
// } from '@mui/material';
// import AddIcon from '@mui/icons-material/Add';
// import CachedIcon from '@mui/icons-material/Cached';
// import PQueue from 'p-queue';
// import OpenAIDescriptionDialog from './OpenAIDescriptionDialog';
// import designTemplates from './designTemplates';

// import { applyFilters } from './filters';
// import { generateProductFeatures as generateLowCostFeatures } from './LowCostAIGenerator';

// const BATCH_SIZE = 5;
// const INITIAL_RETRY_DELAY = 1000;
// const MAX_RETRIES = 5;
// const CONCURRENCY = 2;
// const INTERVAL = 1000;
// const INTERVAL_CAP = 5;

// const OpenAIDescriptionGenerator = ({ data, setData, apiKey }) => {
//   const [open, setOpen] = useState(false);
//   const [loading, setLoading] = useState(false);
//   const [lowCostLoading, setLowCostLoading] = useState(false);
//   const [error, setError] = useState('');
//   const [message, setMessage] = useState('');
//   const [products, setProducts] = useState([]);
//   const [selectedProducts, setSelectedProducts] = useState([]);
//   const [focusedProduct, setFocusedProduct] = useState(null);
//   const [showPreviewDialog, setShowPreviewDialog] = useState(false);
//   const [snackbarOpen, setSnackbarOpen] = useState(false);
//   const [snackbarMessage, setSnackbarMessage] = useState('');
//   const [snackbarSeverity, setSnackbarSeverity] = useState('info');
//   const [previewProduct, setPreviewProduct] = useState(null);
//   const [selectedTemplate, setSelectedTemplate] = useState('professional');
//   const [progress, setProgress] = useState(0);
//   const [customPromptDialogOpen, setCustomPromptDialogOpen] = useState(false);
//   const [resetDialogOpen, setResetDialogOpen] = useState(false);

//   // フィルタリングオプションのステート
//   const [selectedFilters, setSelectedFilters] = useState([]);
//   const [customFilterInput, setCustomFilterInput] = useState('');
//   const [customFilters, setCustomFilters] = useState([]);

//   // テキスト変換のステート
//   const [sentencesToDelete, setSentencesToDelete] = useState([]);
//   const [wordsToReplace, setWordsToReplace] = useState([]);

//   // 必須キーワードのステート
//   const [mandatoryKeywords, setMandatoryKeywords] = useState([]);

//   const queue = useMemo(
//     () =>
//       new PQueue({
//         concurrency: CONCURRENCY,
//         interval: INTERVAL,
//         intervalCap: INTERVAL_CAP,
//       }),
//     []
//   );

//   const itemsPerPageOptions = [50, 100, 'All'];
//   const [currentPage, setCurrentPage] = useState(1);
//   const [itemsPerPage, setItemsPerPage] = useState(itemsPerPageOptions[0]);

//   useEffect(() => {
//     if (open) {
//       initializeProducts();
//     }
//   }, [open]);

//   const handleOpen = () => {
//     if (!data || data.length === 0) {
//       setError('データがありません。CSVファイルをインポートしてください。');
//       setSnackbarMessage('データがありません');
//       setSnackbarSeverity('error');
//       setSnackbarOpen(true);
//       return;
//     }
//     setOpen(true);
//   };

//   const handleClose = () => {
//     setOpen(false);
//     setFocusedProduct(null);
//   };

//   // 商品データを初期化する関数
//   const initializeProducts = () => {
//     const headers = Object.keys(data[0]);
//     const titleIndex = headers.findIndex((header) => header.toLowerCase() === 'title');
//     const jpDescIndex = headers.findIndex((header) => header.toLowerCase() === 'jp_desc');
//     const descriptionIndex = headers.findIndex((header) => header.toLowerCase() === 'description');

//     if (titleIndex === -1 || jpDescIndex === -1 || descriptionIndex === -1) {
//       setError('必要な列（Title, JP_Desc, Description）が見つかりません');
//       return;
//     }

//     // C:~~で始まるカラムを取得
//     const cColumns = headers.filter((header) => header.startsWith('C:'));

//     const productData = data.map((row, index) => {
//       const productId = index;
//       const originalJpDesc = row['JP_Desc'] || row['jp_desc'] || '';

//       // Specificationsのためのキーと値のペアを収集
//       const specifications = {};

//       cColumns.forEach((header) => {
//         const value = row[header];
//         if (value && value !== 'NA') {
//           specifications[header] = value;
//         }
//       });

//       const filteredJpDesc = applyFilters(originalJpDesc, selectedFilters, customFilters);

//       return {
//         id: productId,
//         title: row['Title'] || row['title'] || '',
//         jpDesc: originalJpDesc,
//         editableJpDesc: filteredJpDesc,
//         originalDescription: row['Description'] || row['description'] || '',
//         description: row['Description'] || row['description'] || '',
//         generatedFeatures: '',
//         editableGeneratedFeatures: '',
//         updatedDescription: '',
//         selected: false,
//         isUpdated: false,
//         editableFields: {},
//         specifications,
//       };
//     });

//     setProducts(productData);
//     setSelectedProducts([]);
//     setSnackbarMessage('データを正常に読み込みました');
//     setSnackbarSeverity('success');
//     setSnackbarOpen(true);
//   };

//   const handleFilterChange = useCallback(
//     (label) => {
//       setSelectedFilters((prev) =>
//         prev.includes(label) ? prev.filter((item) => item !== label) : [...prev, label]
//       );
//     },
//     []
//   );

//   const applyFiltersToProducts = useCallback(() => {
//     setProducts((prevProducts) =>
//       prevProducts.map((product) => {
//         const filteredDesc = applyFilters(product.jpDesc, selectedFilters, customFilters);
//         return { ...product, editableJpDesc: filteredDesc };
//       })
//     );
//   }, [selectedFilters, customFilters]);

//   useEffect(() => {
//     applyFiltersToProducts();
//   }, [selectedFilters, customFilters, applyFiltersToProducts]);

//   const handleAddCustomFilter = () => {
//     if (customFilterInput.trim() !== '') {
//       setCustomFilters([...customFilters, customFilterInput.trim()]);
//       setCustomFilterInput('');
//     }
//   };

//   const handleDeleteCustomFilter = (index) => {
//     const updatedFilters = [...customFilters];
//     updatedFilters.splice(index, 1);
//     setCustomFilters(updatedFilters);
//   };

//   useEffect(() => {
//     applyFiltersToProducts();
//   }, [customFilters, applyFiltersToProducts]);

//   const handleEditJpDesc = useCallback((productId, newJpDesc) => {
//     setProducts((prevProducts) =>
//       prevProducts.map((product) =>
//         product.id === productId ? { ...product, editableJpDesc: newJpDesc } : product
//       )
//     );
//   }, []);

//   const sanitizeDescription = (description) => {
//     return description.replace(/<\/?(meta|link)(.|\s)*?>/gi, '');
//   };

//   const generateProductFeatures = async () => {
//     if (!apiKey) {
//       setError('OpenAI APIキーが設定されていません');
//       setSnackbarMessage('OpenAI APIキーが必要です');
//       setSnackbarSeverity('error');
//       setSnackbarOpen(true);
//       return;
//     }

//     if (selectedProducts.length === 0) {
//       setError('少なくとも一つの商品を選択してください');
//       setSnackbarMessage('商品が選択されていません');
//       setSnackbarSeverity('warning');
//       setSnackbarOpen(true);
//       return;
//     }

//     setLoading(true);
//     setError('');
//     setMessage('');
//     setProgress(0);

//     const selectedProductsData = products.filter(
//       (product) => selectedProducts.includes(product.id) && !product.isUpdated
//     );
//     let processedCount = 0;

//     if (selectedProductsData.length === 0) {
//       setLoading(false);
//       setMessage('選択された商品はすでに更新されています');
//       setSnackbarMessage('選択された商品はすでに更新されています');
//       setSnackbarSeverity('info');
//       setSnackbarOpen(true);
//       return;
//     }

//     for (let i = 0; i < selectedProductsData.length; i += BATCH_SIZE) {
//       const batch = selectedProductsData.slice(i, i + BATCH_SIZE);
//       try {
//         const updatedBatch = await processBatch(batch);
//         setProducts((prevProducts) => {
//           const newProducts = [...prevProducts];
//           updatedBatch.forEach((updatedProduct) => {
//             const index = newProducts.findIndex((p) => p.id === updatedProduct.id);
//             if (index !== -1) {
//               newProducts[index] = updatedProduct;
//             }
//           });
//           return newProducts;
//         });
//         processedCount += batch.length;
//         setProgress((processedCount / selectedProductsData.length) * 100);
//       } catch (err) {
//         console.error('バッチ処理エラー:', err);
//         setError(`バッチ処理に失敗しました: ${err.message}`);
//         setSnackbarMessage(`エラー: ${err.message}`);
//         setSnackbarSeverity('error');
//         setSnackbarOpen(true);
//         break;
//       }
//     }

//     setLoading(false);
//     setMessage('商品特徴の生成が完了しました');
//     setSnackbarMessage('商品特徴の生成が完了しました');
//     setSnackbarSeverity('success');
//     setSnackbarOpen(true);
//     setShowPreviewDialog(true);
//   };

//   const processBatch = async (batch) => {
//     return await Promise.all(
//       batch.map(async (product) => {
//         let generatedFeatures;
//         const productId = product.id;

//         // APIコールを行い、説明を生成
//         generatedFeatures = await generateFeaturesWithRetry(product.editableJpDesc);

//         const sanitizedDescription = sanitizeDescription(product.originalDescription);

//         const updatedDescription = generateFormattedDescription(
//           product.title,
//           generatedFeatures,
//           sanitizedDescription,
//           product.specifications
//         );

//         return {
//           ...product,
//           generatedFeatures,
//           editableGeneratedFeatures: generatedFeatures,
//           updatedDescription,
//           isUpdated: true,
//         };
//       })
//     );
//   };

//   const generateFeaturesWithRetry = async (jpDesc, retryCount = 0) => {
//     try {
//       return await queue.add(() => generateFeatures(jpDesc));
//     } catch (error) {
//       if (error.response && error.response.status === 429 && retryCount < MAX_RETRIES) {
//         const retryAfter = error.response.headers['retry-after'];
//         const delay = retryAfter
//           ? parseInt(retryAfter) * 1000
//           : INITIAL_RETRY_DELAY * Math.pow(2, retryCount);
//         const jitter = Math.random() * 1000;
//         await new Promise((resolve) => setTimeout(resolve, delay + jitter));
//         return generateFeaturesWithRetry(jpDesc, retryCount + 1);
//       }
//       throw error;
//     }
//   };

//   const generateFeatures = async (jpDesc, maxTokens = 200) => {
//     const mandatoryKeywordsPrompt =
//       mandatoryKeywords.length > 0
//         ? `また、以下のキーワードを必ず含めてください: ${mandatoryKeywords.join(', ')}。`
//         : '';

//     const response = await axios.post(
//       'https://api.openai.com/v1/chat/completions',
//       {
//         model: 'gpt-4o-2024-08-06',
//         messages: [
//           {
//             role: 'system',
//             content: `あなたはeBayのAIアシスタントです。日本語の商品情報をもとに効果的な英語の商品説明を作成します。${mandatoryKeywordsPrompt}

// 以下のガイドラインに従ってください：

// 1. 商品説明（該当する場合）
//    - ブランド名（該当する場合）
//    - 製品タイプ
//    - モデルまたは製品名
// 2. 詳細な特徴（該当する場合）
//    - 色、素材、サイズ
// 3. 商品の状態（該当する場合）
//    - 使用状況、傷、汚れなどの詳細（中古の場合）

// 注意事項：
// - 上記以外の内容は出力しないでください。
// - 追加の情報は不要です。
// - 配送やオプションに関する情報は出力しないでください。
// - 明確で簡潔な英語を使用してください。
// - 読みやすさを確保するために箇条書きを使用してください。
// - 情報が不明確または曖昧な場合、その項目は省略してください。
// - 信頼できる情報のみを使用し、推測や不確実な情報は含めないでください。
// - 日本語の商品説明に何もない場合、特に出力する必要はありません。タイトルのみで構いません。`,
//           },
//           {
//             role: 'user',
//             content: `以下の日本語の商品説明を分析し、eBayの商品説明として最適化された英語の説明を生成してください。出力は${maxTokens}トークン以内に収めてください：「${jpDesc}」`,
//           },
//         ],
//         max_tokens: maxTokens,
//         temperature: 0.1,
//       },
//       {
//         headers: {
//           Authorization: `Bearer ${apiKey}`,
//           'Content-Type': 'application/json',
//         },
//       }
//     );

//     return response.data.choices[0].message.content.trim();
//   };

//   const cleanHTML = (html) => {
//     let cleanedHtml = html.replace(/<style[^>]*>[\s\S]*?<\/style>/gi, '');
//     cleanedHtml = cleanedHtml.replace(/\sclass="[^"]*"/gi, '');
//     cleanedHtml = cleanedHtml.replace(/\s+/g, ' ').trim();
//     cleanedHtml = cleanedHtml.replace(/>\s+</g, '><');
//     return cleanedHtml;
//   };

//   const generateFormattedDescription = (title, features, description, specifications) => {
//     const template = designTemplates[selectedTemplate];
//     const html = template.generateHTML(title, features, description, specifications);
//     const cleanedHtml = cleanHTML(html);
//     return cleanedHtml;
//   };

//   const handleSelectAll = useCallback(
//     (event) => {
//       const updatedProducts = products.map((product) => ({
//         ...product,
//         selected: event.target.checked,
//       }));
//       setProducts(updatedProducts);
//       setSelectedProducts(event.target.checked ? updatedProducts.map((p) => p.id) : []);
//     },
//     [products]
//   );

//   const handleSelectProduct = useCallback(
//     (productId) => {
//       const updatedProducts = products.map((product) =>
//         product.id === productId ? { ...product, selected: !product.selected } : product
//       );
//       setProducts(updatedProducts);
//       setSelectedProducts(updatedProducts.filter((p) => p.selected).map((p) => p.id));
//     },
//     [products]
//   );

//   const handleFocusProduct = useCallback((productId) => {
//     setFocusedProduct(productId);
//   }, []);

//   const applyUpdatedDescriptions = async () => {
//     setLoading(true);
//     setError('');
//     setMessage('');

//     try {
//       const updatedData = [...data];

//       products.forEach((product) => {
//         if (product.selected && product.updatedDescription) {
//           const index = product.id;
//           updatedData[index] = {
//             ...updatedData[index],
//             Description: product.updatedDescription,
//           };
//         }
//       });

//       setData(updatedData);
//       setMessage('説明文を正常に更新しました');
//       setSnackbarMessage('説明文を正常に更新しました');
//       setSnackbarSeverity('success');
//       setSnackbarOpen(true);
//     } catch (err) {
//       console.error('説明文更新エラー:', err);
//       setError(`説明文の更新に失敗しました: ${err.message}`);
//       setSnackbarMessage(`エラー: ${err.message}`);
//       setSnackbarSeverity('error');
//       setSnackbarOpen(true);
//     } finally {
//       setLoading(false);
//     }
//   };

//   const handlePreviewOpen = (product) => {
//     setPreviewProduct(product);
//     setShowPreviewDialog(true);
//   };

//   const handlePreviewClose = () => {
//     setPreviewProduct(null);
//     setShowPreviewDialog(false);
//   };

//   const handleEditFeatures = useCallback((productId, newFeatures) => {
//     setProducts((prevProducts) =>
//       prevProducts.map((product) =>
//         product.id === productId
//           ? {
//               ...product,
//               editableGeneratedFeatures: newFeatures,
//               updatedDescription: generateFormattedDescription(
//                 product.title,
//                 newFeatures,
//                 sanitizeDescription(product.originalDescription),
//                 product.specifications
//               ),
//             }
//           : product
//       )
//     );
//   }, []);

//   const clearCache = () => {
//     // キャッシュをクリアする処理
//     clearGeneratedDescriptions();
//     // 商品データをリセット
//     setProducts((prevProducts) =>
//       prevProducts.map((product) => ({
//         ...product,
//         generatedFeatures: '',
//         editableGeneratedFeatures: '',
//         updatedDescription: '',
//         isUpdated: false,
//       }))
//     );
//   };

//   // 初期値に戻す関数（選択された商品だけをリセット）
//   const resetSelectedProductsToInitialCSVData = () => {
//     const initialCsvData = JSON.parse(localStorage.getItem('csvData'));

//     if (initialCsvData && initialCsvData.length > 0) {
//       // メインデータをコピー
//       const updatedData = [...data];

//       const headers = Object.keys(initialCsvData[0]);
//       const titleIndex = headers.findIndex((header) => header.toLowerCase() === 'title');
//       const jpDescIndex = headers.findIndex((header) => header.toLowerCase() === 'jp_desc');
//       const descriptionIndex = headers.findIndex((header) => header.toLowerCase() === 'description');

//       if (titleIndex === -1 || jpDescIndex === -1 || descriptionIndex === -1) {
//         setError('必要な列（Title, JP_Desc, Description）が見つかりません');
//         return;
//       }

//       // "C:"で始まるカラムを取得
//       const cColumns = headers.filter((header) => header.startsWith('C:'));

//       const updatedProducts = products.map((product) => {
//         if (product.selected) {
//           const index = product.id;
//           const initialRow = initialCsvData[index];
//           const originalJpDesc = initialRow['JP_Desc'] || initialRow['jp_desc'] || '';

//           // Specificationsのキーと値を収集
//           const specifications = {};

//           cColumns.forEach((header) => {
//             const value = initialRow[header];
//             if (value && value !== 'NA') {
//               specifications[header] = value;
//             }
//           });

//           const filteredJpDesc = applyFilters(originalJpDesc, selectedFilters, customFilters);

//           // メインデータも更新
//           updatedData[index] = { ...initialRow };

//           return {
//             ...product,
//             title: initialRow['Title'] || initialRow['title'] || '',
//             jpDesc: originalJpDesc,
//             editableJpDesc: filteredJpDesc,
//             originalDescription: initialRow['Description'] || initialRow['description'] || '',
//             description: initialRow['Description'] || initialRow['description'] || '',
//             generatedFeatures: '',
//             editableGeneratedFeatures: '',
//             updatedDescription: '',
//             isUpdated: false,
//             specifications,
//           };
//         }
//         return product;
//       });

//       setProducts(updatedProducts);
//       setData(updatedData);

//       // 選択された商品の生成済み説明やキャッシュをクリア
//       clearGeneratedDescriptions(false);

//       setSnackbarMessage('選択した商品を初期値にリセットしました');
//       setSnackbarSeverity('success');
//       setSnackbarOpen(true);
//     } else {
//       setSnackbarMessage('初期データが見つかりません');
//       setSnackbarSeverity('error');
//       setSnackbarOpen(true);
//     }
//   };

//   const clearGeneratedDescriptions = (showMessage = true) => {
//     localStorage.removeItem('generatedDescriptions');
//     if (showMessage) {
//       setSnackbarMessage('生成済みの説明をクリアしました');
//       setSnackbarSeverity('success');
//       setSnackbarOpen(true);
//     }
//   };

//   const indexOfLastItem =
//     currentPage * (itemsPerPage === 'All' ? products.length : itemsPerPage);
//   const indexOfFirstItem = indexOfLastItem - (itemsPerPage === 'All' ? products.length : itemsPerPage);
//   const currentProducts = products.slice(indexOfFirstItem, indexOfLastItem);

//   const handleSelectAllOnPage = useCallback(
//     (event) => {
//       const isSelected = event.target.checked;
//       const updatedProducts = products.map((product) => {
//         if (currentProducts.some((p) => p.id === product.id)) {
//           return { ...product, selected: isSelected };
//         }
//         return product;
//       });
//       setProducts(updatedProducts);
//       setSelectedProducts(updatedProducts.filter((p) => p.selected).map((p) => p.id));
//     },
//     [products, currentProducts]
//   );

//   const handleEditDescription = (productId, newDescription) => {
//     setProducts((prevProducts) =>
//       prevProducts.map((p) =>
//         p.id === productId ? { ...p, updatedDescription: newDescription } : p
//       )
//     );
//   };

//   const applyCustomTransformations = (text) => {
//     let transformedText = text;

//     sentencesToDelete.forEach((sentence) => {
//       if (sentence.trim() !== '') {
//         const escapedSentence = sentence.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
//         const regex = new RegExp(escapedSentence, 'g');
//         transformedText = transformedText.replace(regex, '');
//       }
//     });

//     wordsToReplace.forEach(({ from, to }) => {
//       if (from.trim() !== '') {
//         const escapedFrom = from.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
//         const regex = new RegExp(escapedFrom, 'g');
//         transformedText = transformedText.replace(regex, to);
//       }
//     });

//     return transformedText;
//   };

//   const applyTransformationsToSelectedProducts = () => {
//     const updatedProducts = products.map((product) => {
//       if (product.selected) {
//         const transformedFeatures = applyCustomTransformations(product.editableGeneratedFeatures);
//         const updatedDescription = generateFormattedDescription(
//           product.title,
//           transformedFeatures,
//           sanitizeDescription(product.originalDescription),
//           product.specifications
//         );
//         return {
//           ...product,
//           editableGeneratedFeatures: transformedFeatures,
//           updatedDescription,
//         };
//       }
//       return product;
//     });
//     setProducts(updatedProducts);
//   };

//   // LowCostAIGeneratorの機能を直接使用
//   const handleLowCostAIGeneration = async () => {
//     if (!apiKey) {
//       setError('OpenAI APIキーが設定されていません');
//       setSnackbarMessage('OpenAI APIキーが必要です');
//       setSnackbarSeverity('error');
//       setSnackbarOpen(true);
//       return;
//     }

//     if (selectedProducts.length === 0) {
//       setError('少なくとも一つの商品を選択してください');
//       setSnackbarMessage('商品が選択されていません');
//       setSnackbarSeverity('warning');
//       setSnackbarOpen(true);
//       return;
//     }

//     setLowCostLoading(true);
//     setError('');
//     setMessage('');
//     setProgress(0);

//     try {
//       await generateLowCostFeatures({
//         products,
//         setProducts,
//         selectedProducts,
//         apiKey,
//         setSnackbarMessage,
//         setSnackbarSeverity,
//         setSnackbarOpen,
//         setError,
//         setMessage,
//         setProgress,
//         queue,
//         selectedTemplate,
//         applyCustomTransformations,
//         sanitizeDescription,
//         mandatoryKeywords, // 追加
//       });
//       setShowPreviewDialog(true);
//     } catch (error) {
//       console.error('低コストAI生成エラー:', error);
//       setError(`低コストAI生成に失敗しました: ${error.message}`);
//       setSnackbarMessage(`エラー: ${error.message}`);
//       setSnackbarSeverity('error');
//       setSnackbarOpen(true);
//     } finally {
//       setLowCostLoading(false);
//     }
//   };

//   return (
//     <Paper
//       elevation={3}
//       sx={{
//         p: 4,
//         mt: 1,
//         mb: 1,
//         maxWidth: '100%',
//         margin: '0 auto',
//         overflow: 'hidden',
//       }}
//     >
//       <Box sx={{ maxWidth: '1600px', margin: '0 auto' }}>
//         {/* ヘッダーセクション */}
//         <Grid container spacing={3} alignItems="center">
//           <Grid item xs={12} md={6}>
//             <Typography variant="h5" gutterBottom>
//               AIを活用した商品説明生成
//             </Typography>
//             <Typography variant="body1" paragraph>
//               日本語の商品説明から英語の商品特徴を自動生成します。
//             </Typography>
//           </Grid>
//           <Grid item xs={12} md={6}>
//             <Typography variant="subtitle1" gutterBottom>
//               主な機能：
//             </Typography>
//             <Grid container spacing={1}>
//               {[
//                 '日本語の商品説明から英語の商品特徴を抽出',
//                 'デザインテンプレートからデザインを選択',
//                 '複数の商品を一括で処理',
//                 '説明のプレビューと編集',
//               ].map((feature, index) => (
//                 <Grid item xs={6} key={index}>
//                   <Typography variant="body2">• {feature}</Typography>
//                 </Grid>
//               ))}
//             </Grid>
//           </Grid>
//         </Grid>

//         {/* アクションボタンセクション */}
//         <Box sx={{ mt: 3, display: 'flex', gap: '10px', justifyContent: 'center' }}>
//           <Button variant="contained" color="primary" onClick={handleOpen} startIcon={<AddIcon />}>
//             商品説明をAIで生成する
//           </Button>
//           <Button variant="outlined" color="primary" onClick={clearCache} startIcon={<CachedIcon />}>
//             キャッシュをクリア
//           </Button>
//         </Box>

//         {/* ダイアログコンポーネントをここで使用 */}
//         <OpenAIDescriptionDialog
//           open={open}
//           handleClose={handleClose}
//           products={products}
//           setProducts={setProducts}
//           data={data}
//           setData={setData}
//           apiKey={apiKey}
//           selectedProducts={selectedProducts}
//           setSelectedProducts={setSelectedProducts}
//           selectedTemplate={selectedTemplate}
//           setSelectedTemplate={setSelectedTemplate}
//           loading={loading}
//           setLoading={setLoading}
//           error={error}
//           setError={setError}
//           message={message}
//           setMessage={setMessage}
//           generateProductFeatures={generateProductFeatures}
//           applyUpdatedDescriptions={applyUpdatedDescriptions}
//           handleSelectAll={handleSelectAll}
//           handleSelectProduct={handleSelectProduct}
//           handleFocusProduct={handleFocusProduct}
//           focusedProduct={focusedProduct}
//           setFocusedProduct={setFocusedProduct}
//           handleEditJpDesc={handleEditJpDesc}
//           handleEditDescription={handleEditDescription}
//           handleEditFeatures={handleEditFeatures}
//           handlePreviewOpen={handlePreviewOpen}
//           currentProducts={currentProducts}
//           itemsPerPage={itemsPerPage}
//           setItemsPerPage={setItemsPerPage}
//           itemsPerPageOptions={itemsPerPageOptions}
//           currentPage={currentPage}
//           setCurrentPage={setCurrentPage}
//           handleSelectAllOnPage={handleSelectAllOnPage}
//           applyCustomTransformations={applyCustomTransformations}
//           sentencesToDelete={sentencesToDelete}
//           setSentencesToDelete={setSentencesToDelete}
//           wordsToReplace={wordsToReplace}
//           setWordsToReplace={setWordsToReplace}
//           selectedFilters={selectedFilters}
//           handleFilterChange={handleFilterChange}
//           customFilterInput={customFilterInput}
//           setCustomFilterInput={setCustomFilterInput}
//           customFilters={customFilters}
//           handleAddCustomFilter={handleAddCustomFilter}
//           handleDeleteCustomFilter={handleDeleteCustomFilter}
//           progress={progress}
//           lowCostLoading={lowCostLoading}
//           handleLowCostAIGeneration={handleLowCostAIGeneration}
//           setCustomPromptDialogOpen={setCustomPromptDialogOpen}
//           customPromptDialogOpen={customPromptDialogOpen}
//           setResetDialogOpen={setResetDialogOpen}
//           resetDialogOpen={resetDialogOpen}
//           resetSelectedProductsToInitialCSVData={resetSelectedProductsToInitialCSVData}
//           queue={queue}
//           applyTransformationsToSelectedProducts={applyTransformationsToSelectedProducts}
//           clearCache={clearCache}
//           previewProduct={previewProduct}
//           setPreviewProduct={setPreviewProduct}
//           showPreviewDialog={showPreviewDialog}
//           setShowPreviewDialog={setShowPreviewDialog}
//           handlePreviewClose={handlePreviewClose}
//           snackbarOpen={snackbarOpen}
//           setSnackbarOpen={setSnackbarOpen}
//           snackbarMessage={snackbarMessage}
//           snackbarSeverity={snackbarSeverity}
//           setSnackbarMessage={setSnackbarMessage}
//           setSnackbarSeverity={setSnackbarSeverity}
//           setProgress={setProgress}
//           // 必須キーワードのプロップスを追加
//           mandatoryKeywords={mandatoryKeywords}
//           setMandatoryKeywords={setMandatoryKeywords}
//         />
//       </Box>

//       {/* スナックバー通知 */}
//       <Snackbar
//         anchorOrigin={{
//           vertical: 'bottom',
//           horizontal: 'left',
//         }}
//         open={snackbarOpen}
//         autoHideDuration={6000}
//         onClose={() => setSnackbarOpen(false)}
//       >
//         <Alert onClose={() => setSnackbarOpen(false)} severity={snackbarSeverity} sx={{ width: '100%' }}>
//           {snackbarMessage}
//         </Alert>
//       </Snackbar>
//     </Paper>
//   );
// };

// export default OpenAIDescriptionGenerator;






// // OpenAIDescriptionGenerator.js

// import React, { useState, useEffect, useCallback, useMemo } from 'react';
// import axios from 'axios';
// import {
//   Button,
//   Dialog,
//   DialogActions,
//   DialogContent,
//   DialogTitle,
//   Typography,
//   TextField,
//   Checkbox,
//   FormControlLabel,
//   List,
//   ListItem,
//   ListItemText,
//   ListItemSecondaryAction,
//   IconButton,
//   Paper,
//   Grid,
//   Snackbar,
//   Alert,
//   Select,
//   MenuItem,
//   Box,
//   LinearProgress,
//   InputLabel,
//   FormControl,
//   ListItemIcon,
//   Pagination,
// } from '@mui/material';
// import VisibilityIcon from '@mui/icons-material/Visibility';
// import AddIcon from '@mui/icons-material/Add';
// import CachedIcon from '@mui/icons-material/Cached';
// import EditIcon from '@mui/icons-material/Edit';
// import MoneyOffIcon from '@mui/icons-material/MoneyOff';
// import ReplayIcon from '@mui/icons-material/Replay';
// import designTemplates from './designTemplates';
// import DescriptionValidator from './DescriptionValidator';
// import PQueue from 'p-queue';
// import DescriptionCustomPrompt from './DescriptionCustomPrompt';
// import OptionsPanel from './OptionsPanel';
// import { applyFilters } from './filters';
// import LowCostAIGenerator, { generateProductFeatures as generateLowCostFeatures } from './LowCostAIGenerator';
// import ResetConfirmationDialog from './ResetConfirmationDialog';

// const BATCH_SIZE = 5;
// const INITIAL_RETRY_DELAY = 1000;
// const MAX_RETRIES = 5;
// const CONCURRENCY = 2;
// const INTERVAL = 1000;
// const INTERVAL_CAP = 5;

// const OpenAIDescriptionGenerator = ({ data, setData, apiKey }) => {
//   const [open, setOpen] = useState(false);
//   const [loading, setLoading] = useState(false);
//   const [lowCostLoading, setLowCostLoading] = useState(false);
//   const [error, setError] = useState('');
//   const [message, setMessage] = useState('');
//   const [products, setProducts] = useState([]);
//   const [selectedProducts, setSelectedProducts] = useState([]);
//   const [focusedProduct, setFocusedProduct] = useState(null);
//   const [showPreviewDialog, setShowPreviewDialog] = useState(false);
//   const [snackbarOpen, setSnackbarOpen] = useState(false);
//   const [snackbarMessage, setSnackbarMessage] = useState('');
//   const [snackbarSeverity, setSnackbarSeverity] = useState('info');
//   const [previewProduct, setPreviewProduct] = useState(null);
//   const [selectedTemplate, setSelectedTemplate] = useState('professional');
//   const [progress, setProgress] = useState(0);
//   const [customPromptDialogOpen, setCustomPromptDialogOpen] = useState(false);
//   const [resetDialogOpen, setResetDialogOpen] = useState(false);

//   // フィルタリングオプションのステート
//   const [selectedFilters, setSelectedFilters] = useState([]);
//   const [customFilterInput, setCustomFilterInput] = useState('');
//   const [customFilters, setCustomFilters] = useState([]);

//   // テキスト変換のステート
//   const [sentencesToDelete, setSentencesToDelete] = useState([]);
//   const [wordsToReplace, setWordsToReplace] = useState([]);

//   const queue = useMemo(
//     () =>
//       new PQueue({
//         concurrency: CONCURRENCY,
//         interval: INTERVAL,
//         intervalCap: INTERVAL_CAP,
//       }),
//     []
//   );

//   const itemsPerPageOptions = [50, 100, 'All'];
//   const [currentPage, setCurrentPage] = useState(1);
//   const [itemsPerPage, setItemsPerPage] = useState(itemsPerPageOptions[0]);

//   useEffect(() => {
//     if (open) {
//       initializeProducts();
//     }
//   }, [open]);

//   const handleOpen = () => {
//     if (!data || data.length === 0) {
//       setError('データがありません。CSVファイルをインポートしてください。');
//       setSnackbarMessage('データがありません');
//       setSnackbarSeverity('error');
//       setSnackbarOpen(true);
//       return;
//     }
//     setOpen(true);
//   };

//   const handleClose = () => {
//     setOpen(false);
//     setFocusedProduct(null);
//   };

//   // 商品データを初期化する関数
//   const initializeProducts = () => {
//     const headers = Object.keys(data[0]);
//     const titleIndex = headers.findIndex((header) => header.toLowerCase() === 'title');
//     const jpDescIndex = headers.findIndex((header) => header.toLowerCase() === 'jp_desc');
//     const descriptionIndex = headers.findIndex((header) => header.toLowerCase() === 'description');

//     if (titleIndex === -1 || jpDescIndex === -1 || descriptionIndex === -1) {
//       setError('必要な列（Title, JP_Desc, Description）が見つかりません');
//       return;
//     }

//     // C:~~で始まるカラムを取得
//     const cColumns = headers.filter((header) => header.startsWith('C:'));

//     const productData = data.map((row, index) => {
//       const productId = index;
//       const originalJpDesc = row['JP_Desc'] || row['jp_desc'] || '';

//       // Specificationsのためのキーと値のペアを収集
//       const specifications = {};

//       cColumns.forEach((header) => {
//         const value = row[header];
//         if (value && value !== 'NA') {
//           specifications[header] = value;
//         }
//       });

//       const filteredJpDesc = applyFilters(originalJpDesc, selectedFilters, customFilters);

//       return {
//         id: productId,
//         title: row['Title'] || row['title'] || '',
//         jpDesc: originalJpDesc,
//         editableJpDesc: filteredJpDesc,
//         originalDescription: row['Description'] || row['description'] || '',
//         description: row['Description'] || row['description'] || '',
//         generatedFeatures: '',
//         editableGeneratedFeatures: '',
//         updatedDescription: '',
//         selected: false,
//         isUpdated: false,
//         editableFields: {},
//         specifications,
//       };
//     });

//     setProducts(productData);
//     setSelectedProducts([]);
//     setSnackbarMessage('データを正常に読み込みました');
//     setSnackbarSeverity('success');
//     setSnackbarOpen(true);
//   };

//   const handleFilterChange = useCallback(
//     (label) => {
//       setSelectedFilters((prev) =>
//         prev.includes(label) ? prev.filter((item) => item !== label) : [...prev, label]
//       );
//     },
//     []
//   );

//   const applyFiltersToProducts = useCallback(() => {
//     setProducts((prevProducts) =>
//       prevProducts.map((product) => {
//         const filteredDesc = applyFilters(product.jpDesc, selectedFilters, customFilters);
//         return { ...product, editableJpDesc: filteredDesc };
//       })
//     );
//   }, [selectedFilters, customFilters]);

//   useEffect(() => {
//     applyFiltersToProducts();
//   }, [selectedFilters, customFilters, applyFiltersToProducts]);

//   const handleAddCustomFilter = () => {
//     if (customFilterInput.trim() !== '') {
//       setCustomFilters([...customFilters, customFilterInput.trim()]);
//       setCustomFilterInput('');
//     }
//   };

//   const handleDeleteCustomFilter = (index) => {
//     const updatedFilters = [...customFilters];
//     updatedFilters.splice(index, 1);
//     setCustomFilters(updatedFilters);
//   };

//   useEffect(() => {
//     applyFiltersToProducts();
//   }, [customFilters, applyFiltersToProducts]);

//   const handleEditJpDesc = useCallback((productId, newJpDesc) => {
//     setProducts((prevProducts) =>
//       prevProducts.map((product) =>
//         product.id === productId ? { ...product, editableJpDesc: newJpDesc } : product
//       )
//     );
//   }, []);

//   const sanitizeDescription = (description) => {
//     return description.replace(/<\/?(meta|link)(.|\s)*?>/gi, '');
//   };

//   const generateProductFeatures = async () => {
//     if (!apiKey) {
//       setError('OpenAI APIキーが設定されていません');
//       setSnackbarMessage('OpenAI APIキーが必要です');
//       setSnackbarSeverity('error');
//       setSnackbarOpen(true);
//       return;
//     }

//     if (selectedProducts.length === 0) {
//       setError('少なくとも一つの商品を選択してください');
//       setSnackbarMessage('商品が選択されていません');
//       setSnackbarSeverity('warning');
//       setSnackbarOpen(true);
//       return;
//     }

//     setLoading(true);
//     setError('');
//     setMessage('');
//     setProgress(0);

//     const selectedProductsData = products.filter(
//       (product) => selectedProducts.includes(product.id) && !product.isUpdated
//     );
//     let processedCount = 0;

//     if (selectedProductsData.length === 0) {
//       setLoading(false);
//       setMessage('選択された商品はすでに更新されています');
//       setSnackbarMessage('選択された商品はすでに更新されています');
//       setSnackbarSeverity('info');
//       setSnackbarOpen(true);
//       return;
//     }

//     for (let i = 0; i < selectedProductsData.length; i += BATCH_SIZE) {
//       const batch = selectedProductsData.slice(i, i + BATCH_SIZE);
//       try {
//         const updatedBatch = await processBatch(batch);
//         setProducts((prevProducts) => {
//           const newProducts = [...prevProducts];
//           updatedBatch.forEach((updatedProduct) => {
//             const index = newProducts.findIndex((p) => p.id === updatedProduct.id);
//             if (index !== -1) {
//               newProducts[index] = updatedProduct;
//             }
//           });
//           return newProducts;
//         });
//         processedCount += batch.length;
//         setProgress((processedCount / selectedProductsData.length) * 100);
//       } catch (err) {
//         console.error('バッチ処理エラー:', err);
//         setError(`バッチ処理に失敗しました: ${err.message}`);
//         setSnackbarMessage(`エラー: ${err.message}`);
//         setSnackbarSeverity('error');
//         setSnackbarOpen(true);
//         break;
//       }
//     }

//     setLoading(false);
//     setMessage('商品特徴の生成が完了しました');
//     setSnackbarMessage('商品特徴の生成が完了しました');
//     setSnackbarSeverity('success');
//     setSnackbarOpen(true);
//     setShowPreviewDialog(true);
//   };

//   const processBatch = async (batch) => {
//     return await Promise.all(
//       batch.map(async (product) => {
//         let generatedFeatures;
//         const productId = product.id;

//         // APIコールを行い、説明を生成
//         generatedFeatures = await generateFeaturesWithRetry(product.editableJpDesc);

//         const sanitizedDescription = sanitizeDescription(product.originalDescription);

//         const updatedDescription = generateFormattedDescription(
//           product.title,
//           generatedFeatures,
//           sanitizedDescription,
//           product.specifications
//         );

//         return {
//           ...product,
//           generatedFeatures,
//           editableGeneratedFeatures: generatedFeatures,
//           updatedDescription,
//           isUpdated: true,
//         };
//       })
//     );
//   };

//   const generateFeaturesWithRetry = async (jpDesc, retryCount = 0) => {
//     try {
//       return await queue.add(() => generateFeatures(jpDesc));
//     } catch (error) {
//       if (error.response && error.response.status === 429 && retryCount < MAX_RETRIES) {
//         const retryAfter = error.response.headers['retry-after'];
//         const delay = retryAfter
//           ? parseInt(retryAfter) * 1000
//           : INITIAL_RETRY_DELAY * Math.pow(2, retryCount);
//         const jitter = Math.random() * 1000;
//         await new Promise((resolve) => setTimeout(resolve, delay + jitter));
//         return generateFeaturesWithRetry(jpDesc, retryCount + 1);
//       }
//       throw error;
//     }
//   };

//   const generateFeatures = async (jpDesc, maxTokens = 200) => {
//     const response = await axios.post(
//       'https://api.openai.com/v1/chat/completions',
//       {
//         model: 'gpt-4o-2024-08-06',
//         messages: [
//           {
//             role: 'system',
//             content: `You are eBay's AI assistant specializing in product description optimization. Create effective English product descriptions based on the given Japanese product information. Please provide accurate information to buyers, including the following elements

// 1. product description (if applicable)
//    - Brand name (if applicable)
//    - Product type
//    - Model or product name
// 2. detailed features (if applicable)
//    - Color, material, size
// 3. condition of the item (if applicable)
//    - Details of usage, scratches, stains, etc. (if used)

// Notes:
// - No content other than the above will be output.
// - Additional information is not required.
// - Do not output information related to shipping or options.
// - Use clear and concise English.
// - Use bullet points to ensure readability.
// - If information is unclear or ambiguous, omit the item.
// - Use only reliable information and do not include speculative or uncertain information
// - If there is nothing in the Japanese product description, please do not output anything in particular. Only the title is acceptable.

// Analyze the given product information and follow the guidelines above to generate the best possible product description in English. Try to provide a detailed and engaging description that will help buyers make a purchase decision. Stay within the specified token count limit.`,
//           },
//           {
//             role: 'user',
//             content: `以下の日本語の商品説明を分析し、eBayの商品説明として最適化された英語の説明を生成してください。出力は${maxTokens}トークン以内に収めてください：「${jpDesc}」`,
//           },
//         ],
//         max_tokens: maxTokens,
//         temperature: 0.1,
//       },
//       {
//         headers: {
//           Authorization: `Bearer ${apiKey}`,
//           'Content-Type': 'application/json',
//         },
//       }
//     );

//     return response.data.choices[0].message.content.trim();
//   };

//   const cleanHTML = (html) => {
//     let cleanedHtml = html.replace(/<style[^>]*>[\s\S]*?<\/style>/gi, '');
//     cleanedHtml = cleanedHtml.replace(/\sclass="[^"]*"/gi, '');
//     cleanedHtml = cleanedHtml.replace(/\s+/g, ' ').trim();
//     cleanedHtml = cleanedHtml.replace(/>\s+</g, '><');
//     return cleanedHtml;
//   };

//   const generateFormattedDescription = (title, features, description, specifications) => {
//     const template = designTemplates[selectedTemplate];
//     const html = template.generateHTML(title, features, description, specifications);
//     const cleanedHtml = cleanHTML(html);
//     return cleanedHtml;
//   };

//   const handleSelectAll = useCallback(
//     (event) => {
//       const updatedProducts = products.map((product) => ({
//         ...product,
//         selected: event.target.checked,
//       }));
//       setProducts(updatedProducts);
//       setSelectedProducts(event.target.checked ? updatedProducts.map((p) => p.id) : []);
//     },
//     [products]
//   );

//   const handleSelectProduct = useCallback(
//     (productId) => {
//       const updatedProducts = products.map((product) =>
//         product.id === productId ? { ...product, selected: !product.selected } : product
//       );
//       setProducts(updatedProducts);
//       setSelectedProducts(updatedProducts.filter((p) => p.selected).map((p) => p.id));
//     },
//     [products]
//   );

//   const handleFocusProduct = useCallback((productId) => {
//     setFocusedProduct(productId);
//   }, []);

//   const applyUpdatedDescriptions = async () => {
//     setLoading(true);
//     setError('');
//     setMessage('');

//     try {
//       const updatedData = [...data];

//       products.forEach((product) => {
//         if (product.selected && product.updatedDescription) {
//           const index = product.id;
//           updatedData[index] = {
//             ...updatedData[index],
//             Description: product.updatedDescription,
//           };
//         }
//       });

//       setData(updatedData);
//       setMessage('説明文を正常に更新しました');
//       setSnackbarMessage('説明文を正常に更新しました');
//       setSnackbarSeverity('success');
//       setSnackbarOpen(true);
//     } catch (err) {
//       console.error('説明文更新エラー:', err);
//       setError(`説明文の更新に失敗しました: ${err.message}`);
//       setSnackbarMessage(`エラー: ${err.message}`);
//       setSnackbarSeverity('error');
//       setSnackbarOpen(true);
//     } finally {
//       setLoading(false);
//     }
//   };

//   const handlePreviewOpen = (product) => {
//     setPreviewProduct(product);
//     setShowPreviewDialog(true);
//   };

//   const handlePreviewClose = () => {
//     setPreviewProduct(null);
//     setShowPreviewDialog(false);
//   };

//   const handleEditFeatures = useCallback((productId, newFeatures) => {
//     setProducts((prevProducts) =>
//       prevProducts.map((product) =>
//         product.id === productId
//           ? {
//               ...product,
//               editableGeneratedFeatures: newFeatures,
//               updatedDescription: generateFormattedDescription(
//                 product.title,
//                 newFeatures,
//                 sanitizeDescription(product.originalDescription),
//                 product.specifications
//               ),
//             }
//           : product
//       )
//     );
//   }, []);

//   const clearCache = () => {
//     // キャッシュをクリアする処理
//     clearGeneratedDescriptions();
//     // 商品データをリセット
//     setProducts((prevProducts) =>
//       prevProducts.map((product) => ({
//         ...product,
//         generatedFeatures: '',
//         editableGeneratedFeatures: '',
//         updatedDescription: '',
//         isUpdated: false,
//       }))
//     );
//   };

//   // 初期値に戻す関数（選択された商品だけをリセット）
//   const resetSelectedProductsToInitialCSVData = () => {
//     const initialCsvData = JSON.parse(localStorage.getItem('csvData'));

//     if (initialCsvData && initialCsvData.length > 0) {
//       // メインデータをコピー
//       const updatedData = [...data];

//       const headers = Object.keys(initialCsvData[0]);
//       const titleIndex = headers.findIndex((header) => header.toLowerCase() === 'title');
//       const jpDescIndex = headers.findIndex((header) => header.toLowerCase() === 'jp_desc');
//       const descriptionIndex = headers.findIndex((header) => header.toLowerCase() === 'description');

//       if (titleIndex === -1 || jpDescIndex === -1 || descriptionIndex === -1) {
//         setError('必要な列（Title, JP_Desc, Description）が見つかりません');
//         return;
//       }

//       // "C:"で始まるカラムを取得
//       const cColumns = headers.filter((header) => header.startsWith('C:'));

//       const updatedProducts = products.map((product) => {
//         if (product.selected) {
//           const index = product.id;
//           const initialRow = initialCsvData[index];
//           const originalJpDesc = initialRow['JP_Desc'] || initialRow['jp_desc'] || '';

//           // Specificationsのキーと値を収集
//           const specifications = {};

//           cColumns.forEach((header) => {
//             const value = initialRow[header];
//             if (value && value !== 'NA') {
//               specifications[header] = value;
//             }
//           });

//           const filteredJpDesc = applyFilters(originalJpDesc, selectedFilters, customFilters);

//           // メインデータも更新
//           updatedData[index] = { ...initialRow };

//           return {
//             ...product,
//             title: initialRow['Title'] || initialRow['title'] || '',
//             jpDesc: originalJpDesc,
//             editableJpDesc: filteredJpDesc,
//             originalDescription: initialRow['Description'] || initialRow['description'] || '',
//             description: initialRow['Description'] || initialRow['description'] || '',
//             generatedFeatures: '',
//             editableGeneratedFeatures: '',
//             updatedDescription: '',
//             isUpdated: false,
//             specifications,
//           };
//         }
//         return product;
//       });

//       setProducts(updatedProducts);
//       setData(updatedData);

//       // 選択された商品の生成済み説明やキャッシュをクリア
//       clearGeneratedDescriptions(false);

//       setSnackbarMessage('選択した商品を初期値にリセットしました');
//       setSnackbarSeverity('success');
//       setSnackbarOpen(true);
//     } else {
//       setSnackbarMessage('初期データが見つかりません');
//       setSnackbarSeverity('error');
//       setSnackbarOpen(true);
//     }
//   };

//   const clearGeneratedDescriptions = (showMessage = true) => {
//     localStorage.removeItem('generatedDescriptions');
//     if (showMessage) {
//       setSnackbarMessage('生成済みの説明をクリアしました');
//       setSnackbarSeverity('success');
//       setSnackbarOpen(true);
//     }
//   };

//   const indexOfLastItem =
//     currentPage * (itemsPerPage === 'All' ? products.length : itemsPerPage);
//   const indexOfFirstItem = indexOfLastItem - (itemsPerPage === 'All' ? products.length : itemsPerPage);
//   const currentProducts = products.slice(indexOfFirstItem, indexOfLastItem);

//   const handleSelectAllOnPage = useCallback(
//     (event) => {
//       const isSelected = event.target.checked;
//       const updatedProducts = products.map((product) => {
//         if (currentProducts.some((p) => p.id === product.id)) {
//           return { ...product, selected: isSelected };
//         }
//         return product;
//       });
//       setProducts(updatedProducts);
//       setSelectedProducts(updatedProducts.filter((p) => p.selected).map((p) => p.id));
//     },
//     [products, currentProducts]
//   );

//   const handleEditDescription = (productId, newDescription) => {
//     setProducts((prevProducts) =>
//       prevProducts.map((p) =>
//         p.id === productId ? { ...p, updatedDescription: newDescription } : p
//       )
//     );
//   };

//   const applyCustomTransformations = (text) => {
//     let transformedText = text;

//     sentencesToDelete.forEach((sentence) => {
//       if (sentence.trim() !== '') {
//         const escapedSentence = sentence.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
//         const regex = new RegExp(escapedSentence, 'g');
//         transformedText = transformedText.replace(regex, '');
//       }
//     });

//     wordsToReplace.forEach(({ from, to }) => {
//       if (from.trim() !== '') {
//         const escapedFrom = from.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
//         const regex = new RegExp(escapedFrom, 'g');
//         transformedText = transformedText.replace(regex, to);
//       }
//     });

//     return transformedText;
//   };

//   const applyTransformationsToSelectedProducts = () => {
//     const updatedProducts = products.map((product) => {
//       if (product.selected) {
//         const transformedFeatures = applyCustomTransformations(product.editableGeneratedFeatures);
//         const updatedDescription = generateFormattedDescription(
//           product.title,
//           transformedFeatures,
//           sanitizeDescription(product.originalDescription),
//           product.specifications
//         );
//         return {
//           ...product,
//           editableGeneratedFeatures: transformedFeatures,
//           updatedDescription,
//         };
//       }
//       return product;
//     });
//     setProducts(updatedProducts);
//   };

//   // LowCostAIGeneratorの機能を直接使用
//   const handleLowCostAIGeneration = async () => {
//     if (!apiKey) {
//       setError('OpenAI APIキーが設定されていません');
//       setSnackbarMessage('OpenAI APIキーが必要です');
//       setSnackbarSeverity('error');
//       setSnackbarOpen(true);
//       return;
//     }

//     if (selectedProducts.length === 0) {
//       setError('少なくとも一つの商品を選択してください');
//       setSnackbarMessage('商品が選択されていません');
//       setSnackbarSeverity('warning');
//       setSnackbarOpen(true);
//       return;
//     }

//     setLowCostLoading(true);
//     setError('');
//     setMessage('');
//     setProgress(0);

//     try {
//       await generateLowCostFeatures({
//         products,
//         setProducts,
//         selectedProducts,
//         apiKey,
//         setSnackbarMessage,
//         setSnackbarSeverity,
//         setSnackbarOpen,
//         setError,
//         setMessage,
//         setProgress,
//         queue,
//         selectedTemplate,
//         applyCustomTransformations,
//         sanitizeDescription,
//       });
//       setShowPreviewDialog(true);
//     } catch (error) {
//       console.error('低コストAI生成エラー:', error);
//       setError(`低コストAI生成に失敗しました: ${error.message}`);
//       setSnackbarMessage(`エラー: ${error.message}`);
//       setSnackbarSeverity('error');
//       setSnackbarOpen(true);
//     } finally {
//       setLowCostLoading(false);
//     }
//   };

//   return (
//     <Paper
//       elevation={3}
//       sx={{
//         p: 4,
//         mt: 1,
//         mb: 1,
//         maxWidth: '100%',
//         margin: '0 auto',
//         overflow: 'hidden',
//       }}
//     >
//       <Box sx={{ maxWidth: '1600px', margin: '0 auto' }}>
//         {/* ヘッダーセクション */}
//         <Grid container spacing={3} alignItems="center">
//           <Grid item xs={12} md={6}>
//             <Typography variant="h5" gutterBottom>
//               AIを活用した商品説明生成
//             </Typography>
//             <Typography variant="body1" paragraph>
//               日本語の商品説明から英語の商品特徴を自動生成します。
//             </Typography>
//           </Grid>
//           <Grid item xs={12} md={6}>
//             <Typography variant="subtitle1" gutterBottom>
//               主な機能：
//             </Typography>
//             <Grid container spacing={1}>
//               {[
//                 '日本語の商品説明から英語の商品特徴を抽出',
//                 'デザインテンプレートからデザインを選択',
//                 '複数の商品を一括で処理',
//                 '説明のプレビューと編集',
//               ].map((feature, index) => (
//                 <Grid item xs={6} key={index}>
//                   <Typography variant="body2">• {feature}</Typography>
//                 </Grid>
//               ))}
//             </Grid>
//           </Grid>
//         </Grid>

//         {/* アクションボタンセクション */}
//         <Box sx={{ mt: 3, display: 'flex', gap: '10px', justifyContent: 'center' }}>
//           <Button variant="contained" color="primary" onClick={handleOpen} startIcon={<AddIcon />}>
//             商品説明をAIで生成する
//           </Button>
//           <Button variant="outlined" color="primary" onClick={clearCache} startIcon={<CachedIcon />}>
//             キャッシュをクリア
//           </Button>
//         </Box>

//         {/* ダイアログセクション */}
//         <Dialog open={open} onClose={handleClose} fullScreen>
//           <DialogTitle>AIで商品説明を生成する</DialogTitle>
//           <DialogContent>
//             {/* レイアウトを左右に分割 */}
//             <Grid container spacing={2}>
//               {/* 左側のセクション */}
//               <Grid
//                 item
//                 xs={12}
//                 md={3}
//                 sx={{
//                   maxHeight: 'calc(100vh - 32px)',
//                   overflowY: 'auto',
//                 }}
//               >
//                 {/* OptionsPanel をここで使用 */}
//                 <OptionsPanel
//                   selectedFilters={selectedFilters}
//                   handleFilterChange={handleFilterChange}
//                   customFilterInput={customFilterInput}
//                   setCustomFilterInput={setCustomFilterInput}
//                   customFilters={customFilters}
//                   handleAddCustomFilter={handleAddCustomFilter}
//                   handleDeleteCustomFilter={handleDeleteCustomFilter}
//                   sentencesToDelete={sentencesToDelete}
//                   setSentencesToDelete={setSentencesToDelete}
//                   wordsToReplace={wordsToReplace}
//                   setWordsToReplace={setWordsToReplace}
//                 />

//                 {/* 選択オプション */}
//                 <Grid container spacing={2} alignItems="center" style={{ marginTop: '20px' }}>
//                   <Grid item xs={12}>
//                     <FormControlLabel
//                       control={
//                         <Checkbox
//                           checked={selectedProducts.length === products.length}
//                           onChange={handleSelectAll}
//                           indeterminate={
//                             selectedProducts.length > 0 && selectedProducts.length < products.length
//                           }
//                         />
//                       }
//                       label="全商品の選択"
//                     />
//                   </Grid>
//                   <Grid item xs={12}>
//                     <FormControlLabel
//                       control={
//                         <Checkbox
//                           checked={currentProducts.every((product) => product.selected)}
//                           onChange={handleSelectAllOnPage}
//                           indeterminate={
//                             currentProducts.some((product) => product.selected) &&
//                             !currentProducts.every((product) => product.selected)
//                           }
//                         />
//                       }
//                       label="表示されている商品の一括選択"
//                     />
//                   </Grid>

//                   <Grid item xs={12}>
//                     <FormControl fullWidth>
//                       <InputLabel>デザインテンプレートを選択</InputLabel>
//                       <Select
//                         value={selectedTemplate}
//                         onChange={(e) => setSelectedTemplate(e.target.value)}
//                         fullWidth
//                         label="デザインテンプレートを選択"
//                       >
//                         {Object.entries(designTemplates).map(([key, template]) => (
//                           <MenuItem key={key} value={key}>
//                             {template.name}
//                           </MenuItem>
//                         ))}
//                       </Select>
//                     </FormControl>
//                   </Grid>

//                   {/* 表示件数選択コンポーネントを追加 */}
//                   <Grid item xs={12}>
//                     <FormControl variant="outlined" size="small" fullWidth>
//                       <InputLabel id="items-per-page-label">表示件数</InputLabel>
//                       <Select
//                         labelId="items-per-page-label"
//                         value={itemsPerPage}
//                         onChange={(e) => {
//                           setItemsPerPage(e.target.value);
//                           setCurrentPage(1);
//                         }}
//                         label="表示件数"
//                       >
//                         {itemsPerPageOptions.map((option, index) => (
//                           <MenuItem key={index} value={option}>
//                             {option === 'All' ? '全て' : `${option}件ずつ`}
//                           </MenuItem>
//                         ))}
//                       </Select>
//                     </FormControl>
//                   </Grid>
//                 </Grid>

//                 {/* 商品リスト */}
//                 <List>
//                   {currentProducts.map((product) => (
//                     <ListItem
//                       key={product.id}
//                       button
//                       onClick={() => handleFocusProduct(product.id)}
//                       selected={focusedProduct === product.id}
//                     >
//                       <ListItemIcon>
//                         <Checkbox
//                           edge="start"
//                           checked={product.selected}
//                           tabIndex={-1}
//                           disableRipple
//                           onChange={() => handleSelectProduct(product.id)}
//                           onClick={(e) => e.stopPropagation()}
//                         />
//                       </ListItemIcon>
//                       <ListItemText
//                         primary={product.title}
//                         secondary={product.isUpdated ? '更新済み' : ''}
//                       />
//                       <ListItemSecondaryAction>
//                         <IconButton edge="end" onClick={() => handlePreviewOpen(product)}>
//                           <VisibilityIcon />
//                         </IconButton>
//                       </ListItemSecondaryAction>
//                     </ListItem>
//                   ))}
//                 </List>

//                 {/* ページネーションコンポーネント */}
//                 {itemsPerPage !== 'All' && (
//                   <Pagination
//                     count={Math.ceil(products.length / itemsPerPage)}
//                     page={currentPage}
//                     onChange={(event, value) => setCurrentPage(value)}
//                     color="primary"
//                     style={{ marginTop: '20px', display: 'flex', justifyContent: 'center' }}
//                   />
//                 )}
//               </Grid>

//               {/* 右側のセクション */}
//               <Grid
//                 item
//                 xs={12}
//                 md={9}
//                 sx={{
//                   maxHeight: 'calc(100vh - 32px)',
//                   overflowY: 'auto',
//                 }}
//               >
//                 {/* アクションボタン */}
//                 <Grid container spacing={2} style={{ marginBottom: '20px' }}>
//                   <Grid item>
//                     <Button
//                       variant="contained"
//                       color="primary"
//                       onClick={generateProductFeatures}
//                       disabled={loading || !apiKey || selectedProducts.length === 0}
//                     >
//                       AIで商品説明を生成する
//                     </Button>
//                   </Grid>
//                   {/* 変換を適用するボタンを追加 */}
//                   <Grid item>
//                     <Button
//                       variant="contained"
//                       color="primary"
//                       onClick={applyTransformationsToSelectedProducts}
//                       disabled={loading || selectedProducts.length === 0}
//                     >
//                       変換を適用
//                     </Button>
//                   </Grid>

//                   <Grid item>
//                     <Button
//                       variant="contained"
//                       color="secondary"
//                       onClick={applyUpdatedDescriptions}
//                       disabled={loading || selectedProducts.length === 0}
//                     >
//                       商品説明をデータに更新する
//                     </Button>
//                   </Grid>
//                   {/* 低コストAI生成ボタン */}
//                   <Grid item>
//                     <Button
//                       variant="contained"
//                       color="primary"
//                       onClick={handleLowCostAIGeneration}
//                       startIcon={<MoneyOffIcon />}
//                       disabled={lowCostLoading || !apiKey || selectedProducts.length === 0}
//                     >
//                       低コストAI生成
//                     </Button>
//                   </Grid>
//                   {/* カスタムプロンプトボタン */}
//                   <Grid item>
//                     <Button
//                       variant="contained"
//                       color="success"
//                       onClick={() => setCustomPromptDialogOpen(true)}
//                       startIcon={<EditIcon />}
//                       disabled={loading || selectedProducts.length === 0}
//                     >
//                       カスタムプロンプトで生成
//                     </Button>
//                   </Grid>
//                   {/* 初期値にリセットボタンを追加 */}
//                   <Grid item>
//                     <Button
//                       variant="outlined"
//                       color="secondary"
//                       onClick={() => setResetDialogOpen(true)}
//                       startIcon={<ReplayIcon />}
//                       disabled={loading}
//                     >
//                       初期値にリセット
//                     </Button>
//                   </Grid>
//                 </Grid>

//                 {/* 進捗状況表示 */}
//                 {(loading || lowCostLoading) && (
//                   <Box sx={{ width: '100%', mb: 2 }}>
//                     <LinearProgress variant="determinate" value={progress} />
//                     <Typography variant="body2" color="text.secondary" align="center">
//                       {`${Math.round(progress)}%`}
//                     </Typography>
//                   </Box>
//                 )}

//                 {/* エラーメッセージ */}
//                 {error && (
//                   <Typography color="error" style={{ marginTop: '20px' }}>
//                     {error}
//                   </Typography>
//                 )}
//                 {/* 成功メッセージ */}
//                 {message && (
//                   <Typography color="primary" style={{ marginTop: '20px' }}>
//                     {message}
//                   </Typography>
//                 )}

//                 {/* 選択された商品の詳細情報 */}
//                 {focusedProduct !== null ? (
//                   (() => {
//                     const product = products.find((p) => p.id === focusedProduct);
//                     return (
//                       <Grid container spacing={2}>
//                         <Grid item xs={12}>
//                           <Typography variant="h6">{product.title}</Typography>
//                         </Grid>
//                         {/* 編集可能なjpDescフィールド */}
//                         <Grid item xs={12}>
//                           <Typography variant="subtitle1">Japanese Description (Editable)</Typography>
//                           <TextField
//                             multiline
//                             rows={4}
//                             fullWidth
//                             variant="outlined"
//                             value={product.editableJpDesc}
//                             onChange={(e) => handleEditJpDesc(product.id, e.target.value)}
//                           />
//                         </Grid>

//                         <Grid item xs={12}>
//                           <Typography variant="subtitle1">Final Description (Editable)</Typography>
//                           <TextField
//                             multiline
//                             rows={10}
//                             fullWidth
//                             variant="outlined"
//                             value={
//                               product.updatedDescription ||
//                               product.editableDescription ||
//                               product.description
//                             }
//                             onChange={(e) => handleEditDescription(product.id, e.target.value)}
//                           />
//                         </Grid>

//                         {/* 生成されたFeatures */}
//                         {product.generatedFeatures && (
//                           <Grid item xs={12}>
//                             <Typography variant="subtitle1">Generated Features (Editable)</Typography>
//                             <TextField
//                               multiline
//                               rows={8}
//                               fullWidth
//                               variant="outlined"
//                               value={product.editableGeneratedFeatures}
//                               onChange={(e) => handleEditFeatures(product.id, e.target.value)}
//                             />
//                           </Grid>
//                         )}
//                         {/* 更新されたDescription */}
//                         {product.updatedDescription && (
//                           <>
//                             <Grid item xs={12}>
//                               <Typography variant="subtitle1">Updated Description</Typography>
//                               <div
//                                 dangerouslySetInnerHTML={{ __html: product.updatedDescription }}
//                               />
//                             </Grid>
//                             {/* DescriptionValidator を追加 */}
//                             <Grid item xs={12}>
//                               <DescriptionValidator
//                                 htmlContent={product.updatedDescription || product.description}
//                                 onUpdate={(updatedHtml) => {
//                                   // 修正後のHTMLで商品データを更新
//                                   setProducts((prevProducts) =>
//                                     prevProducts.map((p) =>
//                                       p.id === product.id ? { ...p, updatedDescription: updatedHtml } : p
//                                     )
//                                   );
//                                 }}
//                               />
//                             </Grid>
//                           </>
//                         )}
//                       </Grid>
//                     );
//                   })()
//                 ) : (
//                   <Typography variant="body1">商品を選択してください</Typography>
//                 )}
//               </Grid>
//             </Grid>
//           </DialogContent>
//           <DialogActions>
//             <Button onClick={handleClose}>閉じる</Button>
//           </DialogActions>
//         </Dialog>

//         {/* カスタムプロンプトダイアログを追加 */}
//         <DescriptionCustomPrompt
//           open={customPromptDialogOpen}
//           handleClose={() => setCustomPromptDialogOpen(false)}
//           products={products}
//           setProducts={setProducts}
//           selectedProducts={selectedProducts}
//           apiKey={apiKey}
//           setSnackbarMessage={setSnackbarMessage}
//           setSnackbarSeverity={setSnackbarSeverity}
//           setSnackbarOpen={setSnackbarOpen}
//           loading={loading}
//           setLoading={setLoading}
//           setError={setError}
//           setMessage={setMessage}
//           setProgress={setProgress}
//           queue={queue}
//           selectedTemplate={selectedTemplate}
//           designTemplates={designTemplates}
//           applyCustomTransformations={applyCustomTransformations}
//           sanitizeDescription={sanitizeDescription}
//           progress={progress}
//         />

//         {/* リセット確認ダイアログ */}
//         <ResetConfirmationDialog
//           open={resetDialogOpen}
//           handleClose={() => setResetDialogOpen(false)}
//           handleConfirm={() => {
//             resetSelectedProductsToInitialCSVData();
//             setResetDialogOpen(false);
//           }}
//           selectedCount={selectedProducts.length}
//         />

//         {/* プレビューダイアログ */}
//         <Dialog open={showPreviewDialog} onClose={handlePreviewClose} maxWidth="md" fullWidth>
//           <DialogTitle>eBay Preview</DialogTitle>
//           <DialogContent>
//             {previewProduct ? (
//               <div dangerouslySetInnerHTML={{ __html: previewProduct.updatedDescription }} />
//             ) : (
//               '商品説明の生成が完了しました'
//             )}
//           </DialogContent>
//           <DialogActions>
//             <Button onClick={handlePreviewClose}>閉じる</Button>
//           </DialogActions>
//         </Dialog>

//         {/* スナックバー通知 */}
//         <Snackbar
//           anchorOrigin={{
//             vertical: 'bottom',
//             horizontal: 'left',
//           }}
//           open={snackbarOpen}
//           autoHideDuration={6000}
//           onClose={() => setSnackbarOpen(false)}
//         >
//           <Alert onClose={() => setSnackbarOpen(false)} severity={snackbarSeverity} sx={{ width: '100%' }}>
//             {snackbarMessage}
//           </Alert>
//         </Snackbar>
//       </Box>
//     </Paper>
//   );
// };

// export default OpenAIDescriptionGenerator;





// // OpenAIDescriptionGenerator.js

// import React, { useState, useEffect, useCallback, useMemo } from 'react';
// import axios from 'axios';
// import {
//   Button,
//   Dialog,
//   DialogActions,
//   DialogContent,
//   DialogTitle,
//   Typography,
//   TextField,
//   Checkbox,
//   FormControlLabel,
//   List,
//   ListItem,
//   ListItemText,
//   ListItemSecondaryAction,
//   IconButton,
//   Paper,
//   Grid,
//   Snackbar,
//   Alert,
//   Select,
//   MenuItem,
//   Box,
//   LinearProgress,
//   InputLabel,
//   FormControl,
//   ListItemIcon,
//   Pagination,
// } from '@mui/material';
// import VisibilityIcon from '@mui/icons-material/Visibility';
// import AddIcon from '@mui/icons-material/Add';
// import CachedIcon from '@mui/icons-material/Cached';
// import EditIcon from '@mui/icons-material/Edit';
// import MoneyOffIcon from '@mui/icons-material/MoneyOff';
// import ReplayIcon from '@mui/icons-material/Replay';
// import designTemplates from './designTemplates';
// import DescriptionValidator from './DescriptionValidator';
// import PQueue from 'p-queue';
// import DescriptionCustomPrompt from './DescriptionCustomPrompt';
// import OptionsPanel from './OptionsPanel';
// import { applyFilters } from './filters';
// import LowCostAIGenerator, { generateProductFeatures as generateLowCostFeatures } from './LowCostAIGenerator';
// import ResetConfirmationDialog from './ResetConfirmationDialog';

// const BATCH_SIZE = 5;
// const INITIAL_RETRY_DELAY = 1000;
// const MAX_RETRIES = 5;
// const CONCURRENCY = 2;
// const INTERVAL = 1000;
// const INTERVAL_CAP = 5;

// const OpenAIDescriptionGenerator = ({ data, setData, apiKey }) => {
//   const [open, setOpen] = useState(false);
//   const [loading, setLoading] = useState(false);
//   const [lowCostLoading, setLowCostLoading] = useState(false);
//   const [error, setError] = useState('');
//   const [message, setMessage] = useState('');
//   const [products, setProducts] = useState([]);
//   const [selectedProducts, setSelectedProducts] = useState([]);
//   const [focusedProduct, setFocusedProduct] = useState(null);
//   const [showPreviewDialog, setShowPreviewDialog] = useState(false);
//   const [snackbarOpen, setSnackbarOpen] = useState(false);
//   const [snackbarMessage, setSnackbarMessage] = useState('');
//   const [snackbarSeverity, setSnackbarSeverity] = useState('info');
//   const [previewProduct, setPreviewProduct] = useState(null);
//   const [selectedTemplate, setSelectedTemplate] = useState('professional');
//   const [progress, setProgress] = useState(0);
//   const [customPromptDialogOpen, setCustomPromptDialogOpen] = useState(false);
//   const [resetDialogOpen, setResetDialogOpen] = useState(false);

//   // フィルタリングオプションのステート
//   const [selectedFilters, setSelectedFilters] = useState([]);
//   const [customFilterInput, setCustomFilterInput] = useState('');
//   const [customFilters, setCustomFilters] = useState([]);

//   // テキスト変換のステート
//   const [sentencesToDelete, setSentencesToDelete] = useState([]);
//   const [wordsToReplace, setWordsToReplace] = useState([]);

//   const queue = useMemo(
//     () =>
//       new PQueue({
//         concurrency: CONCURRENCY,
//         interval: INTERVAL,
//         intervalCap: INTERVAL_CAP,
//       }),
//     []
//   );

//   const itemsPerPageOptions = [50, 100, 'All'];
//   const [currentPage, setCurrentPage] = useState(1);
//   const [itemsPerPage, setItemsPerPage] = useState(itemsPerPageOptions[0]);

//   useEffect(() => {
//     if (open) {
//       initializeProducts();
//     }
//   }, [open]);

//   const handleOpen = () => {
//     if (!data || data.length === 0) {
//       setError('データがありません。CSVファイルをインポートしてください。');
//       setSnackbarMessage('データがありません');
//       setSnackbarSeverity('error');
//       setSnackbarOpen(true);
//       return;
//     }
//     setOpen(true);
//   };

//   const handleClose = () => {
//     setOpen(false);
//     setFocusedProduct(null);
//   };

//   // 商品データを初期化する関数
//   const initializeProducts = () => {
//     const headers = Object.keys(data[0]);
//     const titleIndex = headers.findIndex((header) => header.toLowerCase() === 'title');
//     const jpDescIndex = headers.findIndex((header) => header.toLowerCase() === 'jp_desc');
//     const descriptionIndex = headers.findIndex((header) => header.toLowerCase() === 'description');

//     if (titleIndex === -1 || jpDescIndex === -1 || descriptionIndex === -1) {
//       setError('必要な列（Title, JP_Desc, Description）が見つかりません');
//       return;
//     }

//     // C:~~で始まるカラムを取得
//     const cColumns = headers.filter((header) => header.startsWith('C:'));

//     const productData = data.map((row, index) => {
//       const productId = index;
//       const originalJpDesc = row['JP_Desc'] || row['jp_desc'] || '';

//       // Specificationsのためのキーと値のペアを収集
//       const specifications = {};

//       cColumns.forEach((header) => {
//         const value = row[header];
//         if (value && value !== 'NA') {
//           specifications[header] = value;
//         }
//       });

//       const filteredJpDesc = applyFilters(originalJpDesc, selectedFilters, customFilters);

//       return {
//         id: productId,
//         title: row['Title'] || row['title'] || '',
//         jpDesc: originalJpDesc,
//         editableJpDesc: filteredJpDesc,
//         originalDescription: row['Description'] || row['description'] || '',
//         description: row['Description'] || row['description'] || '',
//         generatedFeatures: '',
//         editableGeneratedFeatures: '',
//         updatedDescription: '',
//         selected: false,
//         isUpdated: false,
//         editableFields: {},
//         specifications,
//       };
//     });

//     setProducts(productData);
//     setSelectedProducts([]);
//     setSnackbarMessage('データを正常に読み込みました');
//     setSnackbarSeverity('success');
//     setSnackbarOpen(true);
//   };

//   const handleFilterChange = useCallback(
//     (label) => {
//       setSelectedFilters((prev) =>
//         prev.includes(label) ? prev.filter((item) => item !== label) : [...prev, label]
//       );
//     },
//     []
//   );

//   const applyFiltersToProducts = useCallback(() => {
//     setProducts((prevProducts) =>
//       prevProducts.map((product) => {
//         const filteredDesc = applyFilters(product.jpDesc, selectedFilters, customFilters);
//         return { ...product, editableJpDesc: filteredDesc };
//       })
//     );
//   }, [selectedFilters, customFilters]);

//   useEffect(() => {
//     applyFiltersToProducts();
//   }, [selectedFilters, customFilters, applyFiltersToProducts]);

//   const handleAddCustomFilter = () => {
//     if (customFilterInput.trim() !== '') {
//       setCustomFilters([...customFilters, customFilterInput.trim()]);
//       setCustomFilterInput('');
//     }
//   };

//   const handleDeleteCustomFilter = (index) => {
//     const updatedFilters = [...customFilters];
//     updatedFilters.splice(index, 1);
//     setCustomFilters(updatedFilters);
//   };

//   useEffect(() => {
//     applyFiltersToProducts();
//   }, [customFilters, applyFiltersToProducts]);

//   const handleEditJpDesc = useCallback((productId, newJpDesc) => {
//     setProducts((prevProducts) =>
//       prevProducts.map((product) =>
//         product.id === productId ? { ...product, editableJpDesc: newJpDesc } : product
//       )
//     );
//   }, []);

//   const sanitizeDescription = (description) => {
//     return description.replace(/<\/?(meta|link)(.|\s)*?>/gi, '');
//   };

//   const generateProductFeatures = async () => {
//     if (!apiKey) {
//       setError('OpenAI APIキーが設定されていません');
//       setSnackbarMessage('OpenAI APIキーが必要です');
//       setSnackbarSeverity('error');
//       setSnackbarOpen(true);
//       return;
//     }

//     if (selectedProducts.length === 0) {
//       setError('少なくとも一つの商品を選択してください');
//       setSnackbarMessage('商品が選択されていません');
//       setSnackbarSeverity('warning');
//       setSnackbarOpen(true);
//       return;
//     }

//     setLoading(true);
//     setError('');
//     setMessage('');
//     setProgress(0);

//     const selectedProductsData = products.filter(
//       (product) => selectedProducts.includes(product.id) && !product.isUpdated
//     );
//     let processedCount = 0;

//     if (selectedProductsData.length === 0) {
//       setLoading(false);
//       setMessage('選択された商品はすでに更新されています');
//       setSnackbarMessage('選択された商品はすでに更新されています');
//       setSnackbarSeverity('info');
//       setSnackbarOpen(true);
//       return;
//     }

//     for (let i = 0; i < selectedProductsData.length; i += BATCH_SIZE) {
//       const batch = selectedProductsData.slice(i, i + BATCH_SIZE);
//       try {
//         const updatedBatch = await processBatch(batch);
//         setProducts((prevProducts) => {
//           const newProducts = [...prevProducts];
//           updatedBatch.forEach((updatedProduct) => {
//             const index = newProducts.findIndex((p) => p.id === updatedProduct.id);
//             if (index !== -1) {
//               newProducts[index] = updatedProduct;
//             }
//           });
//           return newProducts;
//         });
//         processedCount += batch.length;
//         setProgress((processedCount / selectedProductsData.length) * 100);
//       } catch (err) {
//         console.error('バッチ処理エラー:', err);
//         setError(`バッチ処理に失敗しました: ${err.message}`);
//         setSnackbarMessage(`エラー: ${err.message}`);
//         setSnackbarSeverity('error');
//         setSnackbarOpen(true);
//         break;
//       }
//     }

//     setLoading(false);
//     setMessage('商品特徴の生成が完了しました');
//     setSnackbarMessage('商品特徴の生成が完了しました');
//     setSnackbarSeverity('success');
//     setSnackbarOpen(true);
//     setShowPreviewDialog(true);
//   };

//   const processBatch = async (batch) => {
//     return await Promise.all(
//       batch.map(async (product) => {
//         let generatedFeatures;
//         const productId = product.id;

//         // APIコールを行い、説明を生成
//         generatedFeatures = await generateFeaturesWithRetry(product.editableJpDesc);

//         const sanitizedDescription = sanitizeDescription(product.originalDescription);

//         const updatedDescription = generateFormattedDescription(
//           product.title,
//           generatedFeatures,
//           sanitizedDescription,
//           product.specifications
//         );

//         return {
//           ...product,
//           generatedFeatures,
//           editableGeneratedFeatures: generatedFeatures,
//           updatedDescription,
//           isUpdated: true,
//         };
//       })
//     );
//   };

//   const generateFeaturesWithRetry = async (jpDesc, retryCount = 0) => {
//     try {
//       return await queue.add(() => generateFeatures(jpDesc));
//     } catch (error) {
//       if (error.response && error.response.status === 429 && retryCount < MAX_RETRIES) {
//         const retryAfter = error.response.headers['retry-after'];
//         const delay = retryAfter
//           ? parseInt(retryAfter) * 1000
//           : INITIAL_RETRY_DELAY * Math.pow(2, retryCount);
//         const jitter = Math.random() * 1000;
//         await new Promise((resolve) => setTimeout(resolve, delay + jitter));
//         return generateFeaturesWithRetry(jpDesc, retryCount + 1);
//       }
//       throw error;
//     }
//   };

//   const generateFeatures = async (jpDesc, maxTokens = 200) => {
//     const response = await axios.post(
//       'https://api.openai.com/v1/chat/completions',
//       {
//         model: 'gpt-4o-2024-08-06',
//         messages: [
//           {
//             role: 'system',
//             content: `You are eBay's AI assistant specializing in product description optimization. Create effective English product descriptions based on the given Japanese product information. Please provide accurate information to buyers, including the following elements

// 1. product description (if applicable)
//    - Brand name (if applicable)
//    - Product type
//    - Model or product name
// 2. detailed features (if applicable)
//    - Color, material, size
// 3. condition of the item (if applicable)
//    - Details of usage, scratches, stains, etc. (if used)

// Notes:
// - No content other than the above will be output.
// - Additional information is not required.
// - Do not output information related to shipping or options.
// - Use clear and concise English.
// - Use bullet points to ensure readability.
// - If information is unclear or ambiguous, omit the item.
// - Use only reliable information and do not include speculative or uncertain information
// - If there is nothing in the Japanese product description, please do not output anything in particular. Only the title is acceptable.

// Analyze the given product information and follow the guidelines above to generate the best possible product description in English. Try to provide a detailed and engaging description that will help buyers make a purchase decision. Stay within the specified token count limit.`,
//           },
//           {
//             role: 'user',
//             content: `以下の日本語の商品説明を分析し、eBayの商品説明として最適化された英語の説明を生成してください。出力は${maxTokens}トークン以内に収めてください：「${jpDesc}」`,
//           },
//         ],
//         max_tokens: maxTokens,
//         temperature: 0.1,
//       },
//       {
//         headers: {
//           Authorization: `Bearer ${apiKey}`,
//           'Content-Type': 'application/json',
//         },
//       }
//     );

//     return response.data.choices[0].message.content.trim();
//   };

//   const cleanHTML = (html) => {
//     let cleanedHtml = html.replace(/<style[^>]*>[\s\S]*?<\/style>/gi, '');
//     cleanedHtml = cleanedHtml.replace(/\sclass="[^"]*"/gi, '');
//     cleanedHtml = cleanedHtml.replace(/\s+/g, ' ').trim();
//     cleanedHtml = cleanedHtml.replace(/>\s+</g, '><');
//     return cleanedHtml;
//   };

//   const generateFormattedDescription = (title, features, description, specifications) => {
//     const template = designTemplates[selectedTemplate];
//     const html = template.generateHTML(title, features, description, specifications);
//     const cleanedHtml = cleanHTML(html);
//     return cleanedHtml;
//   };

//   const handleSelectAll = useCallback(
//     (event) => {
//       const updatedProducts = products.map((product) => ({
//         ...product,
//         selected: event.target.checked,
//       }));
//       setProducts(updatedProducts);
//       setSelectedProducts(event.target.checked ? updatedProducts.map((p) => p.id) : []);
//     },
//     [products]
//   );

//   const handleSelectProduct = useCallback(
//     (productId) => {
//       const updatedProducts = products.map((product) =>
//         product.id === productId ? { ...product, selected: !product.selected } : product
//       );
//       setProducts(updatedProducts);
//       setSelectedProducts(updatedProducts.filter((p) => p.selected).map((p) => p.id));
//     },
//     [products]
//   );

//   const handleFocusProduct = useCallback((productId) => {
//     setFocusedProduct(productId);
//   }, []);

//   const applyUpdatedDescriptions = async () => {
//     setLoading(true);
//     setError('');
//     setMessage('');

//     try {
//       const updatedData = [...data];

//       products.forEach((product) => {
//         if (product.selected && product.updatedDescription) {
//           const index = product.id;
//           updatedData[index] = {
//             ...updatedData[index],
//             Description: product.updatedDescription,
//           };
//         }
//       });

//       setData(updatedData);
//       setMessage('説明文を正常に更新しました');
//       setSnackbarMessage('説明文を正常に更新しました');
//       setSnackbarSeverity('success');
//       setSnackbarOpen(true);
//     } catch (err) {
//       console.error('説明文更新エラー:', err);
//       setError(`説明文の更新に失敗しました: ${err.message}`);
//       setSnackbarMessage(`エラー: ${err.message}`);
//       setSnackbarSeverity('error');
//       setSnackbarOpen(true);
//     } finally {
//       setLoading(false);
//     }
//   };

//   const handlePreviewOpen = (product) => {
//     setPreviewProduct(product);
//     setShowPreviewDialog(true);
//   };

//   const handlePreviewClose = () => {
//     setPreviewProduct(null);
//     setShowPreviewDialog(false);
//   };

//   const handleEditFeatures = useCallback((productId, newFeatures) => {
//     setProducts((prevProducts) =>
//       prevProducts.map((product) =>
//         product.id === productId
//           ? {
//               ...product,
//               editableGeneratedFeatures: newFeatures,
//               updatedDescription: generateFormattedDescription(
//                 product.title,
//                 newFeatures,
//                 sanitizeDescription(product.originalDescription),
//                 product.specifications
//               ),
//             }
//           : product
//       )
//     );
//   }, []);

//   const clearCache = () => {
//     // キャッシュをクリアする処理
//     clearGeneratedDescriptions();
//     // 商品データをリセット
//     setProducts((prevProducts) =>
//       prevProducts.map((product) => ({
//         ...product,
//         generatedFeatures: '',
//         editableGeneratedFeatures: '',
//         updatedDescription: '',
//         isUpdated: false,
//       }))
//     );
//   };

//   // 初期値に戻す関数
//   const resetToInitialCSVData = () => {
//     const initialCsvData = JSON.parse(localStorage.getItem('csvData'));

//     if (initialCsvData && initialCsvData.length > 0) {
//       // メインデータを更新
//       setData(initialCsvData);

//       // 商品データを再初期化
//       const headers = Object.keys(initialCsvData[0]);
//       const titleIndex = headers.findIndex((header) => header.toLowerCase() === 'title');
//       const jpDescIndex = headers.findIndex((header) => header.toLowerCase() === 'jp_desc');
//       const descriptionIndex = headers.findIndex((header) => header.toLowerCase() === 'description');

//       if (titleIndex === -1 || jpDescIndex === -1 || descriptionIndex === -1) {
//         setError('必要な列（Title, JP_Desc, Description）が見つかりません');
//         return;
//       }

//       // C:~~で始まるカラムを取得
//       const cColumns = headers.filter((header) => header.startsWith('C:'));

//       const productData = initialCsvData.map((row, index) => {
//         const productId = index;
//         const originalJpDesc = row['JP_Desc'] || row['jp_desc'] || '';

//         // Specificationsのためのキーと値のペアを収集
//         const specifications = {};

//         cColumns.forEach((header) => {
//           const value = row[header];
//           if (value && value !== 'NA') {
//             specifications[header] = value;
//           }
//         });

//         const filteredJpDesc = applyFilters(originalJpDesc, selectedFilters, customFilters);

//         return {
//           id: productId,
//           title: row['Title'] || row['title'] || '',
//           jpDesc: originalJpDesc,
//           editableJpDesc: filteredJpDesc,
//           originalDescription: row['Description'] || row['description'] || '',
//           description: row['Description'] || row['description'] || '',
//           generatedFeatures: '',
//           editableGeneratedFeatures: '',
//           updatedDescription: '',
//           selected: false,
//           isUpdated: false,
//           editableFields: {},
//           specifications,
//         };
//       });

//       setProducts(productData);
//       setSelectedProducts([]);
//       setFocusedProduct(null);

//       // 生成された説明やキャッシュをクリア
//       clearGeneratedDescriptions(false);

//       setSnackbarMessage('データを初期値にリセットしました');
//       setSnackbarSeverity('success');
//       setSnackbarOpen(true);
//     } else {
//       setSnackbarMessage('初期データが見つかりません');
//       setSnackbarSeverity('error');
//       setSnackbarOpen(true);
//     }
//   };

//   const clearGeneratedDescriptions = (showMessage = true) => {
//     localStorage.removeItem('generatedDescriptions');
//     if (showMessage) {
//       setSnackbarMessage('生成済みの説明をクリアしました');
//       setSnackbarSeverity('success');
//       setSnackbarOpen(true);
//     }
//   };

//   const indexOfLastItem =
//     currentPage * (itemsPerPage === 'All' ? products.length : itemsPerPage);
//   const indexOfFirstItem = indexOfLastItem - (itemsPerPage === 'All' ? products.length : itemsPerPage);
//   const currentProducts = products.slice(indexOfFirstItem, indexOfLastItem);

//   const handleSelectAllOnPage = useCallback(
//     (event) => {
//       const isSelected = event.target.checked;
//       const updatedProducts = products.map((product) => {
//         if (currentProducts.some((p) => p.id === product.id)) {
//           return { ...product, selected: isSelected };
//         }
//         return product;
//       });
//       setProducts(updatedProducts);
//       setSelectedProducts(updatedProducts.filter((p) => p.selected).map((p) => p.id));
//     },
//     [products, currentProducts]
//   );

//   const handleEditDescription = (productId, newDescription) => {
//     setProducts((prevProducts) =>
//       prevProducts.map((p) =>
//         p.id === productId ? { ...p, updatedDescription: newDescription } : p
//       )
//     );
//   };

//   const applyCustomTransformations = (text) => {
//     let transformedText = text;

//     sentencesToDelete.forEach((sentence) => {
//       if (sentence.trim() !== '') {
//         const escapedSentence = sentence.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
//         const regex = new RegExp(escapedSentence, 'g');
//         transformedText = transformedText.replace(regex, '');
//       }
//     });

//     wordsToReplace.forEach(({ from, to }) => {
//       if (from.trim() !== '') {
//         const escapedFrom = from.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
//         const regex = new RegExp(escapedFrom, 'g');
//         transformedText = transformedText.replace(regex, to);
//       }
//     });

//     return transformedText;
//   };

//   const applyTransformationsToSelectedProducts = () => {
//     const updatedProducts = products.map((product) => {
//       if (product.selected) {
//         const transformedFeatures = applyCustomTransformations(product.editableGeneratedFeatures);
//         const updatedDescription = generateFormattedDescription(
//           product.title,
//           transformedFeatures,
//           sanitizeDescription(product.originalDescription),
//           product.specifications
//         );
//         return {
//           ...product,
//           editableGeneratedFeatures: transformedFeatures,
//           updatedDescription,
//         };
//       }
//       return product;
//     });
//     setProducts(updatedProducts);
//   };

//   // LowCostAIGeneratorの機能を直接使用
//   const handleLowCostAIGeneration = async () => {
//     if (!apiKey) {
//       setError('OpenAI APIキーが設定されていません');
//       setSnackbarMessage('OpenAI APIキーが必要です');
//       setSnackbarSeverity('error');
//       setSnackbarOpen(true);
//       return;
//     }

//     if (selectedProducts.length === 0) {
//       setError('少なくとも一つの商品を選択してください');
//       setSnackbarMessage('商品が選択されていません');
//       setSnackbarSeverity('warning');
//       setSnackbarOpen(true);
//       return;
//     }

//     setLowCostLoading(true);
//     setError('');
//     setMessage('');
//     setProgress(0);

//     try {
//       await generateLowCostFeatures({
//         products,
//         setProducts,
//         selectedProducts,
//         apiKey,
//         setSnackbarMessage,
//         setSnackbarSeverity,
//         setSnackbarOpen,
//         setError,
//         setMessage,
//         setProgress,
//         queue,
//         selectedTemplate,
//         applyCustomTransformations,
//         sanitizeDescription,
//       });
//       setShowPreviewDialog(true);
//     } catch (error) {
//       console.error('低コストAI生成エラー:', error);
//       setError(`低コストAI生成に失敗しました: ${error.message}`);
//       setSnackbarMessage(`エラー: ${error.message}`);
//       setSnackbarSeverity('error');
//       setSnackbarOpen(true);
//     } finally {
//       setLowCostLoading(false);
//     }
//   };

//   return (
//     <Paper
//       elevation={3}
//       sx={{
//         p: 4,
//         mt: 1,
//         mb: 1,
//         maxWidth: '100%',
//         margin: '0 auto',
//         overflow: 'hidden',
//       }}
//     >
//       <Box sx={{ maxWidth: '1600px', margin: '0 auto' }}>
//         {/* ヘッダーセクション */}
//         <Grid container spacing={3} alignItems="center">
//           <Grid item xs={12} md={6}>
//             <Typography variant="h5" gutterBottom>
//               AIを活用した商品説明生成
//             </Typography>
//             <Typography variant="body1" paragraph>
//               日本語の商品説明から英語の商品特徴を自動生成します。
//             </Typography>
//           </Grid>
//           <Grid item xs={12} md={6}>
//             <Typography variant="subtitle1" gutterBottom>
//               主な機能：
//             </Typography>
//             <Grid container spacing={1}>
//               {[
//                 '日本語の商品説明から英語の商品特徴を抽出',
//                 'デザインテンプレートからデザインを選択',
//                 '複数の商品を一括で処理',
//                 '説明のプレビューと編集',
//               ].map((feature, index) => (
//                 <Grid item xs={6} key={index}>
//                   <Typography variant="body2">• {feature}</Typography>
//                 </Grid>
//               ))}
//             </Grid>
//           </Grid>
//         </Grid>

//         {/* アクションボタンセクション */}
//         <Box sx={{ mt: 3, display: 'flex', gap: '10px', justifyContent: 'center' }}>
//           <Button variant="contained" color="primary" onClick={handleOpen} startIcon={<AddIcon />}>
//             商品説明をAIで生成する
//           </Button>
//           <Button variant="outlined" color="primary" onClick={clearCache} startIcon={<CachedIcon />}>
//             キャッシュをクリア
//           </Button>
//         </Box>

//         {/* リセット確認ダイアログ */}
//         <ResetConfirmationDialog
//           open={resetDialogOpen}
//           handleClose={() => setResetDialogOpen(false)}
//           handleConfirm={() => {
//             resetToInitialCSVData();
//             setResetDialogOpen(false);
//           }}
//         />

//         {/* ダイアログセクション */}
//         <Dialog open={open} onClose={handleClose} fullScreen>
//           <DialogTitle>AIで商品説明を生成する</DialogTitle>
//           <DialogContent>
//             {/* レイアウトを左右に分割 */}
//             <Grid container spacing={2}>
//               {/* 左側のセクション */}
//               <Grid
//                 item
//                 xs={12}
//                 md={3}
//                 sx={{
//                   maxHeight: 'calc(100vh - 32px)',
//                   overflowY: 'auto',
//                 }}
//               >
//                 {/* OptionsPanel をここで使用 */}
//                 <OptionsPanel
//                   selectedFilters={selectedFilters}
//                   handleFilterChange={handleFilterChange}
//                   customFilterInput={customFilterInput}
//                   setCustomFilterInput={setCustomFilterInput}
//                   customFilters={customFilters}
//                   handleAddCustomFilter={handleAddCustomFilter}
//                   handleDeleteCustomFilter={handleDeleteCustomFilter}
//                   sentencesToDelete={sentencesToDelete}
//                   setSentencesToDelete={setSentencesToDelete}
//                   wordsToReplace={wordsToReplace}
//                   setWordsToReplace={setWordsToReplace}
//                 />

//                 {/* 選択オプション */}
//                 <Grid container spacing={2} alignItems="center" style={{ marginTop: '20px' }}>
//                   <Grid item xs={12}>
//                     <FormControlLabel
//                       control={
//                         <Checkbox
//                           checked={selectedProducts.length === products.length}
//                           onChange={handleSelectAll}
//                           indeterminate={
//                             selectedProducts.length > 0 && selectedProducts.length < products.length
//                           }
//                         />
//                       }
//                       label="全商品の選択"
//                     />
//                   </Grid>
//                   <Grid item xs={12}>
//                     <FormControlLabel
//                       control={
//                         <Checkbox
//                           checked={currentProducts.every((product) => product.selected)}
//                           onChange={handleSelectAllOnPage}
//                           indeterminate={
//                             currentProducts.some((product) => product.selected) &&
//                             !currentProducts.every((product) => product.selected)
//                           }
//                         />
//                       }
//                       label="表示されている商品の一括選択"
//                     />
//                   </Grid>

//                   <Grid item xs={12}>
//                     <FormControl fullWidth>
//                       <InputLabel>デザインテンプレートを選択</InputLabel>
//                       <Select
//                         value={selectedTemplate}
//                         onChange={(e) => setSelectedTemplate(e.target.value)}
//                         fullWidth
//                         label="デザインテンプレートを選択"
//                       >
//                         {Object.entries(designTemplates).map(([key, template]) => (
//                           <MenuItem key={key} value={key}>
//                             {template.name}
//                           </MenuItem>
//                         ))}
//                       </Select>
//                     </FormControl>
//                   </Grid>

//                   {/* 表示件数選択コンポーネントを追加 */}
//                   <Grid item xs={12}>
//                     <FormControl variant="outlined" size="small" fullWidth>
//                       <InputLabel id="items-per-page-label">表示件数</InputLabel>
//                       <Select
//                         labelId="items-per-page-label"
//                         value={itemsPerPage}
//                         onChange={(e) => {
//                           setItemsPerPage(e.target.value);
//                           setCurrentPage(1);
//                         }}
//                         label="表示件数"
//                       >
//                         {itemsPerPageOptions.map((option, index) => (
//                           <MenuItem key={index} value={option}>
//                             {option === 'All' ? '全て' : `${option}件ずつ`}
//                           </MenuItem>
//                         ))}
//                       </Select>
//                     </FormControl>
//                   </Grid>
//                 </Grid>

//                 {/* 商品リスト */}
//                 <List>
//                   {currentProducts.map((product) => (
//                     <ListItem
//                       key={product.id}
//                       button
//                       onClick={() => handleFocusProduct(product.id)}
//                       selected={focusedProduct === product.id}
//                     >
//                       <ListItemIcon>
//                         <Checkbox
//                           edge="start"
//                           checked={product.selected}
//                           tabIndex={-1}
//                           disableRipple
//                           onChange={() => handleSelectProduct(product.id)}
//                           onClick={(e) => e.stopPropagation()}
//                         />
//                       </ListItemIcon>
//                       <ListItemText
//                         primary={product.title}
//                         secondary={product.isUpdated ? '更新済み' : ''}
//                       />
//                       <ListItemSecondaryAction>
//                         <IconButton edge="end" onClick={() => handlePreviewOpen(product)}>
//                           <VisibilityIcon />
//                         </IconButton>
//                       </ListItemSecondaryAction>
//                     </ListItem>
//                   ))}
//                 </List>

//                 {/* ページネーションコンポーネント */}
//                 {itemsPerPage !== 'All' && (
//                   <Pagination
//                     count={Math.ceil(products.length / itemsPerPage)}
//                     page={currentPage}
//                     onChange={(event, value) => setCurrentPage(value)}
//                     color="primary"
//                     style={{ marginTop: '20px', display: 'flex', justifyContent: 'center' }}
//                   />
//                 )}
//               </Grid>

//               {/* 右側のセクション */}
//               <Grid
//                 item
//                 xs={12}
//                 md={9}
//                 sx={{
//                   maxHeight: 'calc(100vh - 32px)',
//                   overflowY: 'auto',
//                 }}
//               >
//                 {/* アクションボタン */}
//                 <Grid container spacing={2} style={{ marginBottom: '20px' }}>
//                   <Grid item>
//                     <Button
//                       variant="contained"
//                       color="primary"
//                       onClick={generateProductFeatures}
//                       disabled={loading || !apiKey || selectedProducts.length === 0}
//                     >
//                       AIで商品説明を生成する
//                     </Button>
//                   </Grid>
//                   {/* 変換を適用するボタンを追加 */}
//                   <Grid item>
//                     <Button
//                       variant="contained"
//                       color="primary"
//                       onClick={applyTransformationsToSelectedProducts}
//                       disabled={loading || selectedProducts.length === 0}
//                     >
//                       変換を適用
//                     </Button>
//                   </Grid>

//                   <Grid item>
//                     <Button
//                       variant="contained"
//                       color="secondary"
//                       onClick={applyUpdatedDescriptions}
//                       disabled={loading || selectedProducts.length === 0}
//                     >
//                       商品説明をデータに更新する
//                     </Button>
//                   </Grid>
//                   {/* 新しく追加する低コストAI生成ボタン */}
//                   <Grid item>
//                     <Button
//                       variant="contained"
//                       color="primary"
//                       onClick={handleLowCostAIGeneration}
//                       startIcon={<MoneyOffIcon />}
//                       disabled={lowCostLoading || !apiKey || selectedProducts.length === 0}
//                     >
//                       低コストAI生成
//                     </Button>
//                   </Grid>
//                   <Grid item>
//                     <Button
//                       variant="contained"
//                       color="success"
//                       onClick={() => setCustomPromptDialogOpen(true)}
//                       startIcon={<EditIcon />}
//                       disabled={loading || selectedProducts.length === 0}
//                     >
//                       カスタムプロンプトで生成
//                     </Button>
//                   </Grid>
//                   {/* 「初期値にリセット」ボタンをここに移動 */}
//                   <Grid item>
//                     <Button
//                       variant="outlined"
//                       color="secondary"
//                       onClick={() => setResetDialogOpen(true)}
//                       startIcon={<ReplayIcon />}
//                       disabled={loading}
//                     >
//                       初期値にリセット
//                     </Button>
//                   </Grid>
//                 </Grid>

//                 {/* 進捗状況表示 */}
//                 {(loading || lowCostLoading) && (
//                   <Box sx={{ width: '100%', mb: 2 }}>
//                     <LinearProgress variant="determinate" value={progress} />
//                     <Typography variant="body2" color="text.secondary" align="center">
//                       {`${Math.round(progress)}%`}
//                     </Typography>
//                   </Box>
//                 )}

//                 {/* エラーメッセージ */}
//                 {error && (
//                   <Typography color="error" style={{ marginTop: '20px' }}>
//                     {error}
//                   </Typography>
//                 )}
//                 {/* 成功メッセージ */}
//                 {message && (
//                   <Typography color="primary" style={{ marginTop: '20px' }}>
//                     {message}
//                   </Typography>
//                 )}

//                 {/* 選択された商品の詳細情報 */}
//                 {focusedProduct !== null ? (
//                   (() => {
//                     const product = products.find((p) => p.id === focusedProduct);
//                     return (
//                       <Grid container spacing={2}>
//                         <Grid item xs={12}>
//                           <Typography variant="h6">{product.title}</Typography>
//                         </Grid>
//                         {/* 編集可能なjpDescフィールド */}
//                         <Grid item xs={12}>
//                           <Typography variant="subtitle1">Japanese Description (Editable)</Typography>
//                           <TextField
//                             multiline
//                             rows={4}
//                             fullWidth
//                             variant="outlined"
//                             value={product.editableJpDesc}
//                             onChange={(e) => handleEditJpDesc(product.id, e.target.value)}
//                           />
//                         </Grid>

//                         <Grid item xs={12}>
//                           <Typography variant="subtitle1">Final Description (Editable)</Typography>
//                           <TextField
//                             multiline
//                             rows={10}
//                             fullWidth
//                             variant="outlined"
//                             value={
//                               product.updatedDescription ||
//                               product.editableDescription ||
//                               product.description
//                             }
//                             onChange={(e) => handleEditDescription(product.id, e.target.value)}
//                           />
//                         </Grid>

//                         {/* 生成されたFeatures */}
//                         {product.generatedFeatures && (
//                           <Grid item xs={12}>
//                             <Typography variant="subtitle1">Generated Features (Editable)</Typography>
//                             <TextField
//                               multiline
//                               rows={8}
//                               fullWidth
//                               variant="outlined"
//                               value={product.editableGeneratedFeatures}
//                               onChange={(e) => handleEditFeatures(product.id, e.target.value)}
//                             />
//                           </Grid>
//                         )}
//                         {/* 更新されたDescription */}
//                         {product.updatedDescription && (
//                           <>
//                             <Grid item xs={12}>
//                               <Typography variant="subtitle1">Updated Description</Typography>
//                               <div
//                                 dangerouslySetInnerHTML={{ __html: product.updatedDescription }}
//                               />
//                             </Grid>
//                             {/* DescriptionValidator を追加 */}
//                             <Grid item xs={12}>
//                               <DescriptionValidator
//                                 htmlContent={product.updatedDescription || product.description}
//                                 onUpdate={(updatedHtml) => {
//                                   // 修正後のHTMLで商品データを更新
//                                   setProducts((prevProducts) =>
//                                     prevProducts.map((p) =>
//                                       p.id === product.id ? { ...p, updatedDescription: updatedHtml } : p
//                                     )
//                                   );
//                                 }}
//                               />
//                             </Grid>
//                           </>
//                         )}
//                       </Grid>
//                     );
//                   })()
//                 ) : (
//                   <Typography variant="body1">商品を選択してください</Typography>
//                 )}
//               </Grid>
//             </Grid>
//           </DialogContent>
//           <DialogActions>
//             <Button onClick={handleClose}>閉じる</Button>
//           </DialogActions>
//         </Dialog>

//         {/* カスタムプロンプトダイアログを追加 */}
//         <DescriptionCustomPrompt
//           open={customPromptDialogOpen}
//           handleClose={() => setCustomPromptDialogOpen(false)}
//           products={products}
//           setProducts={setProducts}
//           selectedProducts={selectedProducts}
//           apiKey={apiKey}
//           setSnackbarMessage={setSnackbarMessage}
//           setSnackbarSeverity={setSnackbarSeverity}
//           setSnackbarOpen={setSnackbarOpen}
//           loading={loading}
//           setLoading={setLoading}
//           setError={setError}
//           setMessage={setMessage}
//           setProgress={setProgress}
//           queue={queue}
//           selectedTemplate={selectedTemplate}
//           designTemplates={designTemplates}
//           applyCustomTransformations={applyCustomTransformations}
//           sanitizeDescription={sanitizeDescription}
//           progress={progress}
//         />

//         {/* プレビューダイアログ */}
//         <Dialog open={showPreviewDialog} onClose={handlePreviewClose} maxWidth="md" fullWidth>
//           <DialogTitle>eBay Preview</DialogTitle>
//           <DialogContent>
//             {previewProduct ? (
//               <div dangerouslySetInnerHTML={{ __html: previewProduct.updatedDescription }} />
//             ) : (
//               '商品説明の生成が完了しました'
//             )}
//           </DialogContent>
//           <DialogActions>
//             <Button onClick={handlePreviewClose}>閉じる</Button>
//           </DialogActions>
//         </Dialog>

//         {/* スナックバー通知 */}
//         <Snackbar
//           anchorOrigin={{
//             vertical: 'bottom',
//             horizontal: 'left',
//           }}
//           open={snackbarOpen}
//           autoHideDuration={6000}
//           onClose={() => setSnackbarOpen(false)}
//         >
//           <Alert onClose={() => setSnackbarOpen(false)} severity={snackbarSeverity} sx={{ width: '100%' }}>
//             {snackbarMessage}
//           </Alert>
//         </Snackbar>
//       </Box>
//     </Paper>
//   );
// };

// export default OpenAIDescriptionGenerator;




// // OpenAIDescriptionGenerator.js

// import React, { useState, useEffect, useCallback, useMemo } from 'react';
// import axios from 'axios';
// import {
//   Button,
//   Dialog,
//   DialogActions,
//   DialogContent,
//   DialogTitle,
//   Typography,
//   TextField,
//   Checkbox,
//   FormControlLabel,
//   List,
//   ListItem,
//   ListItemText,
//   ListItemSecondaryAction,
//   IconButton,
//   Paper,
//   Grid,
//   Snackbar,
//   Alert,
//   Select,
//   MenuItem,
//   Box,
//   LinearProgress,
//   InputLabel,
//   FormControl,
//   ListItemIcon,
//   Pagination,
// } from '@mui/material';
// import VisibilityIcon from '@mui/icons-material/Visibility';
// import AddIcon from '@mui/icons-material/Add';
// import CachedIcon from '@mui/icons-material/Cached';
// import EditIcon from '@mui/icons-material/Edit';
// import MoneyOffIcon from '@mui/icons-material/MoneyOff';
// import designTemplates from './designTemplates';
// import DescriptionValidator from './DescriptionValidator';
// import PQueue from 'p-queue';
// import DescriptionCustomPrompt from './DescriptionCustomPrompt';
// import OptionsPanel from './OptionsPanel';
// import { applyFilters } from './filters';
// import LowCostAIGenerator, { generateProductFeatures as generateLowCostFeatures } from './LowCostAIGenerator';

// const BATCH_SIZE = 5;
// const INITIAL_RETRY_DELAY = 1000;
// const MAX_RETRIES = 5;
// const CONCURRENCY = 2;
// const INTERVAL = 1000;
// const INTERVAL_CAP = 5;

// const OpenAIDescriptionGenerator = ({ data, setData, apiKey }) => {
//   const [open, setOpen] = useState(false);
//   const [loading, setLoading] = useState(false);
//   const [lowCostLoading, setLowCostLoading] = useState(false); // 低コストAI生成用のローディング状態
//   const [error, setError] = useState('');
//   const [message, setMessage] = useState('');
//   const [products, setProducts] = useState([]);
//   const [selectedProducts, setSelectedProducts] = useState([]);
//   const [focusedProduct, setFocusedProduct] = useState(null);
//   const [showPreviewDialog, setShowPreviewDialog] = useState(false);
//   const [snackbarOpen, setSnackbarOpen] = useState(false);
//   const [snackbarMessage, setSnackbarMessage] = useState('');
//   const [snackbarSeverity, setSnackbarSeverity] = useState('info');
//   const [previewProduct, setPreviewProduct] = useState(null);
//   const [selectedTemplate, setSelectedTemplate] = useState('professional');
//   const [progress, setProgress] = useState(0);
//   const [customPromptDialogOpen, setCustomPromptDialogOpen] = useState(false);

//   // フィルタリングオプションのステート
//   const [selectedFilters, setSelectedFilters] = useState([]);
//   const [customFilterInput, setCustomFilterInput] = useState('');
//   const [customFilters, setCustomFilters] = useState([]);

//   // テキスト変換のステート
//   const [sentencesToDelete, setSentencesToDelete] = useState([]);
//   const [wordsToReplace, setWordsToReplace] = useState([]);

//   const queue = useMemo(
//     () =>
//       new PQueue({
//         concurrency: CONCURRENCY,
//         interval: INTERVAL,
//         intervalCap: INTERVAL_CAP,
//       }),
//     []
//   );

//   const itemsPerPageOptions = [50, 100, 'All'];
//   const [currentPage, setCurrentPage] = useState(1);
//   const [itemsPerPage, setItemsPerPage] = useState(itemsPerPageOptions[0]);

//   // ローカルストレージから生成された説明をロード
//   const loadGeneratedDescriptions = () => {
//     return JSON.parse(localStorage.getItem('generatedDescriptions')) || {};
//   };

//   // 生成された説明を保存する関数
//   const saveGeneratedDescription = (productId, description) => {
//     const existingData = JSON.parse(localStorage.getItem('generatedDescriptions')) || {};
//     existingData[productId] = description;
//     localStorage.setItem('generatedDescriptions', JSON.stringify(existingData));
//   };

//   // キャッシュをクリアする関数
//   const clearGeneratedDescriptions = () => {
//     localStorage.removeItem('generatedDescriptions');
//     setSnackbarMessage('生成済みの説明をクリアしました');
//     setSnackbarSeverity('success');
//     setSnackbarOpen(true);
//   };

//   useEffect(() => {
//     if (open) {
//       initializeProducts();
//     }
//   }, [open]);

//   const handleOpen = () => {
//     if (!data || data.length === 0) {
//       setError('データがありません。CSVファイルをインポートしてください。');
//       setSnackbarMessage('データがありません');
//       setSnackbarSeverity('error');
//       setSnackbarOpen(true);
//       return;
//     }
//     setOpen(true);
//   };

//   const handleClose = () => {
//     setOpen(false);
//     setFocusedProduct(null);
//   };

//   const initializeProducts = () => {
//     const headers = Object.keys(data[0]);
//     const titleIndex = headers.findIndex((header) => header.toLowerCase() === 'title');
//     const jpDescIndex = headers.findIndex((header) => header.toLowerCase() === 'jp_desc');
//     const descriptionIndex = headers.findIndex((header) => header.toLowerCase() === 'description');

//     if (titleIndex === -1 || jpDescIndex === -1 || descriptionIndex === -1) {
//       setError('必要な列（Title, JP_Desc, Description）が見つかりません');
//       return;
//     }

//     // C:~~で始まるカラムを取得
//     const cColumns = headers.filter((header) => header.startsWith('C:'));

//     const generatedDescriptions = loadGeneratedDescriptions();

//     const productData = data.map((row, index) => {
//       const productId = index;
//       const originalJpDesc = row['JP_Desc'] || row['jp_desc'] || '';

//       // Specificationsのためのキーと値のペアを収集
//       const specifications = {};

//       cColumns.forEach((header) => {
//         const value = row[header];
//         if (value && value !== 'NA') {
//           specifications[header] = value;
//         }
//       });

//       const filteredJpDesc = applyFilters(originalJpDesc, selectedFilters, customFilters);

//       const generatedFeatures = generatedDescriptions[productId] || '';
//       const updatedDescription = generatedFeatures
//         ? generateFormattedDescription(
//             row['Title'] || row['title'] || '',
//             generatedFeatures,
//             row['Description'] || row['description'] || '',
//             specifications // ここでspecificationsを渡します
//           )
//         : '';

//       return {
//         id: productId,
//         title: row['Title'] || row['title'] || '',
//         jpDesc: originalJpDesc,
//         editableJpDesc: filteredJpDesc,
//         originalDescription: row['Description'] || row['description'] || '',
//         description: row['Description'] || row['description'] || '',
//         generatedFeatures,
//         editableGeneratedFeatures: generatedFeatures,
//         updatedDescription,
//         selected: false,
//         isUpdated: !!generatedFeatures,
//         editableFields: {},
//         specifications, // ここでspecificationsを保存します
//       };
//     });

//     setProducts(productData);
//     setSelectedProducts([]); // 空の配列に設定
//     setSnackbarMessage('データを正常に読み込みました');
//     setSnackbarSeverity('success');
//     setSnackbarOpen(true);
//   };

//   const handleFilterChange = useCallback(
//     (label) => {
//       setSelectedFilters((prev) =>
//         prev.includes(label) ? prev.filter((item) => item !== label) : [...prev, label]
//       );
//     },
//     []
//   );

//   const applyFiltersToProducts = useCallback(() => {
//     setProducts((prevProducts) =>
//       prevProducts.map((product) => {
//         const filteredDesc = applyFilters(product.jpDesc, selectedFilters, customFilters);
//         return { ...product, editableJpDesc: filteredDesc };
//       })
//     );
//   }, [selectedFilters, customFilters]);

//   useEffect(() => {
//     applyFiltersToProducts();
//   }, [selectedFilters, customFilters, applyFiltersToProducts]);

//   const handleAddCustomFilter = () => {
//     if (customFilterInput.trim() !== '') {
//       setCustomFilters([...customFilters, customFilterInput.trim()]);
//       setCustomFilterInput('');
//     }
//   };

//   const handleDeleteCustomFilter = (index) => {
//     const updatedFilters = [...customFilters];
//     updatedFilters.splice(index, 1);
//     setCustomFilters(updatedFilters);
//   };

//   useEffect(() => {
//     applyFiltersToProducts();
//   }, [customFilters, applyFiltersToProducts]);

//   const handleEditJpDesc = useCallback((productId, newJpDesc) => {
//     setProducts((prevProducts) =>
//       prevProducts.map((product) =>
//         product.id === productId ? { ...product, editableJpDesc: newJpDesc } : product
//       )
//     );
//   }, []);

//   const sanitizeDescription = (description) => {
//     return description.replace(/<\/?(meta|link)(.|\s)*?>/gi, '');
//   };

//   const generateProductFeatures = async () => {
//     if (!apiKey) {
//       setError('OpenAI APIキーが設定されていません');
//       setSnackbarMessage('OpenAI APIキーが必要です');
//       setSnackbarSeverity('error');
//       setSnackbarOpen(true);
//       return;
//     }

//     if (selectedProducts.length === 0) {
//       setError('少なくとも一つの商品を選択してください');
//       setSnackbarMessage('商品が選択されていません');
//       setSnackbarSeverity('warning');
//       setSnackbarOpen(true);
//       return;
//     }

//     setLoading(true);
//     setError('');
//     setMessage('');
//     setProgress(0);

//     const selectedProductsData = products.filter(
//       (product) => selectedProducts.includes(product.id) && !product.isUpdated // 未更新の商品に限定
//     );
//     let processedCount = 0;

//     if (selectedProductsData.length === 0) {
//       setLoading(false);
//       setMessage('選択された商品はすでに更新されています');
//       setSnackbarMessage('選択された商品はすでに更新されています');
//       setSnackbarSeverity('info');
//       setSnackbarOpen(true);
//       return;
//     }

//     for (let i = 0; i < selectedProductsData.length; i += BATCH_SIZE) {
//       const batch = selectedProductsData.slice(i, i + BATCH_SIZE);
//       try {
//         const updatedBatch = await processBatch(batch);
//         setProducts((prevProducts) => {
//           const newProducts = [...prevProducts];
//           updatedBatch.forEach((updatedProduct) => {
//             const index = newProducts.findIndex((p) => p.id === updatedProduct.id);
//             if (index !== -1) {
//               newProducts[index] = updatedProduct;
//             }
//           });
//           return newProducts;
//         });
//         processedCount += batch.length;
//         setProgress((processedCount / selectedProductsData.length) * 100);
//       } catch (err) {
//         console.error('バッチ処理エラー:', err);
//         setError(`バッチ処理に失敗しました: ${err.message}`);
//         setSnackbarMessage(`エラー: ${err.message}`);
//         setSnackbarSeverity('error');
//         setSnackbarOpen(true);
//         break;
//       }
//     }

//     setLoading(false);
//     setMessage('商品特徴の生成が完了しました');
//     setSnackbarMessage('商品特徴の生成が完了しました');
//     setSnackbarSeverity('success');
//     setSnackbarOpen(true);
//     setShowPreviewDialog(true);
//   };

//   const processBatch = async (batch) => {
//     const generatedDescriptions = loadGeneratedDescriptions();

//     return await Promise.all(
//       batch.map(async (product) => {
//         let generatedFeatures;
//         const productId = product.id;

//         // 生成済みかチェック
//         if (generatedDescriptions[productId]) {
//           generatedFeatures = generatedDescriptions[productId];
//         } else {
//           // APIコールを行い、説明を生成
//           generatedFeatures = await generateFeaturesWithRetry(product.editableJpDesc);
//           // 生成された説明を保存
//           saveGeneratedDescription(productId, generatedFeatures);
//         }

//         const sanitizedDescription = sanitizeDescription(product.originalDescription); // 元の説明文を使用

//         const updatedDescription = generateFormattedDescription(
//           product.title,
//           generatedFeatures,
//           sanitizedDescription,
//           product.specifications // specificationsを渡す
//         );

//         return {
//           ...product,
//           generatedFeatures,
//           editableGeneratedFeatures: generatedFeatures,
//           updatedDescription,
//           isUpdated: true, // 更新済みに設定
//         };
//       })
//     );
//   };

//   const generateFeaturesWithRetry = async (jpDesc, retryCount = 0) => {
//     try {
//       return await queue.add(() => generateFeatures(jpDesc));
//     } catch (error) {
//       if (error.response && error.response.status === 429 && retryCount < MAX_RETRIES) {
//         const retryAfter = error.response.headers['retry-after'];
//         const delay = retryAfter
//           ? parseInt(retryAfter) * 1000
//           : INITIAL_RETRY_DELAY * Math.pow(2, retryCount);
//         const jitter = Math.random() * 1000;
//         await new Promise((resolve) => setTimeout(resolve, delay + jitter));
//         return generateFeaturesWithRetry(jpDesc, retryCount + 1);
//       }
//       throw error;
//     }
//   };

//   const generateFeatures = async (jpDesc, maxTokens = 200) => {
//     const response = await axios.post(
//       'https://api.openai.com/v1/chat/completions',
//       {
//         model: 'gpt-4o-2024-08-06',
//         messages: [
//           {
//             role: 'system',
//             content: `You are eBay's AI assistant specializing in product description optimization. Create effective English product descriptions based on the given Japanese product information. Please provide accurate information to buyers, including the following elements

// 1. product description (if applicable)
//    - Brand name (if applicable)
//    - Product type
//    - Model or product name
// 2. detailed features (if applicable)
//    - Color, material, size
// 3. condition of the item (if applicable)
//    - Details of usage, scratches, stains, etc. (if used)

// Notes:
// - No content other than the above will be output.
// - Additional information is not required.
// - Do not output information related to shipping or options.
// - Use clear and concise English.
// - Use bullet points to ensure readability.
// - If information is unclear or ambiguous, omit the item.
// - Use only reliable information and do not include speculative or uncertain information
// - If there is nothing in the Japanese product description, please do not output anything in particular. Only the title is acceptable.

// Analyze the given product information and follow the guidelines above to generate the best possible product description in English. Try to provide a detailed and engaging description that will help buyers make a purchase decision. Stay within the specified token count limit.`,
//           },
//           {
//             role: 'user',
//             content: `以下の日本語の商品説明を分析し、eBayの商品説明として最適化された英語の説明を生成してください。出力は${maxTokens}トークン以内に収めてください：「${jpDesc}」`,
//           },
//         ],
//         max_tokens: maxTokens,
//         temperature: 0.1,
//       },
//       {
//         headers: {
//           Authorization: `Bearer ${apiKey}`,
//           'Content-Type': 'application/json',
//         },
//       }
//     );

//     return response.data.choices[0].message.content.trim();
//   };

//   const cleanHTML = (html) => {
//     let cleanedHtml = html.replace(/<style[^>]*>[\s\S]*?<\/style>/gi, '');
//     cleanedHtml = cleanedHtml.replace(/\sclass="[^"]*"/gi, '');
//     cleanedHtml = cleanedHtml.replace(/\s+/g, ' ').trim();
//     cleanedHtml = cleanedHtml.replace(/>\s+</g, '><');
//     return cleanedHtml;
//   };

//   const generateFormattedDescription = (title, features, description, specifications) => {
//     const template = designTemplates[selectedTemplate];
//     const html = template.generateHTML(title, features, description, specifications);
//     const cleanedHtml = cleanHTML(html);
//     return cleanedHtml;
//   };

//   const handleSelectAll = useCallback(
//     (event) => {
//       const updatedProducts = products.map((product) => ({
//         ...product,
//         selected: event.target.checked,
//       }));
//       setProducts(updatedProducts);
//       setSelectedProducts(event.target.checked ? updatedProducts.map((p) => p.id) : []);
//     },
//     [products]
//   );

//   const handleSelectProduct = useCallback(
//     (productId) => {
//       const updatedProducts = products.map((product) =>
//         product.id === productId ? { ...product, selected: !product.selected } : product
//       );
//       setProducts(updatedProducts);
//       setSelectedProducts(updatedProducts.filter((p) => p.selected).map((p) => p.id));
//     },
//     [products]
//   );

//   const handleFocusProduct = useCallback((productId) => {
//     setFocusedProduct(productId);
//   }, []);

//   const applyUpdatedDescriptions = async () => {
//     setLoading(true);
//     setError('');
//     setMessage('');

//     try {
//       const updatedData = [...data];

//       products.forEach((product) => {
//         if (product.selected && product.updatedDescription) {
//           const index = product.id;
//           updatedData[index] = {
//             ...updatedData[index],
//             Description: product.updatedDescription,
//           };
//         }
//       });

//       setData(updatedData);
//       setMessage('説明文を正常に更新しました');
//       setSnackbarMessage('説明文を正常に更新しました');
//       setSnackbarSeverity('success');
//       setSnackbarOpen(true);
//     } catch (err) {
//       console.error('説明文更新エラー:', err);
//       setError(`説明文の更新に失敗しました: ${err.message}`);
//       setSnackbarMessage(`エラー: ${err.message}`);
//       setSnackbarSeverity('error');
//       setSnackbarOpen(true);
//     } finally {
//       setLoading(false);
//     }
//   };

//   const handlePreviewOpen = (product) => {
//     setPreviewProduct(product);
//     setShowPreviewDialog(true);
//   };

//   const handlePreviewClose = () => {
//     setPreviewProduct(null);
//     setShowPreviewDialog(false);
//   };

//   const handleEditFeatures = useCallback((productId, newFeatures) => {
//     setProducts((prevProducts) =>
//       prevProducts.map((product) =>
//         product.id === productId
//           ? {
//               ...product,
//               editableGeneratedFeatures: newFeatures,
//               updatedDescription: generateFormattedDescription(
//                 product.title,
//                 newFeatures,
//                 sanitizeDescription(product.originalDescription), // 元の説明文を使用
//                 product.specifications // specificationsを渡す
//               ),
//             }
//           : product
//       )
//     );
//   }, []);

//   const clearCache = () => {
//     clearGeneratedDescriptions();
//     // 商品データをリセット
//     setProducts((prevProducts) =>
//       prevProducts.map((product) => ({
//         ...product,
//         generatedFeatures: '',
//         editableGeneratedFeatures: '',
//         updatedDescription: '',
//         isUpdated: false,
//       }))
//     );
//   };

//   const indexOfLastItem =
//     currentPage * (itemsPerPage === 'All' ? products.length : itemsPerPage);
//   const indexOfFirstItem = indexOfLastItem - (itemsPerPage === 'All' ? products.length : itemsPerPage);
//   const currentProducts = products.slice(indexOfFirstItem, indexOfLastItem);

//   const handleSelectAllOnPage = useCallback(
//     (event) => {
//       const isSelected = event.target.checked;
//       const updatedProducts = products.map((product) => {
//         if (currentProducts.some((p) => p.id === product.id)) {
//           return { ...product, selected: isSelected };
//         }
//         return product;
//       });
//       setProducts(updatedProducts);
//       setSelectedProducts(updatedProducts.filter((p) => p.selected).map((p) => p.id));
//     },
//     [products, currentProducts]
//   );

//   const handleEditDescription = (productId, newDescription) => {
//     setProducts((prevProducts) =>
//       prevProducts.map((p) =>
//         p.id === productId ? { ...p, updatedDescription: newDescription } : p
//       )
//     );
//   };

//   const applyCustomTransformations = (text) => {
//     let transformedText = text;

//     sentencesToDelete.forEach((sentence) => {
//       if (sentence.trim() !== '') {
//         const escapedSentence = sentence.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
//         const regex = new RegExp(escapedSentence, 'g');
//         transformedText = transformedText.replace(regex, '');
//       }
//     });

//     wordsToReplace.forEach(({ from, to }) => {
//       if (from.trim() !== '') {
//         const escapedFrom = from.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
//         const regex = new RegExp(escapedFrom, 'g');
//         transformedText = transformedText.replace(regex, to);
//       }
//     });

//     return transformedText;
//   };

//   const applyTransformationsToSelectedProducts = () => {
//     const updatedProducts = products.map((product) => {
//       if (product.selected) {
//         const transformedFeatures = applyCustomTransformations(product.editableGeneratedFeatures);
//         const updatedDescription = generateFormattedDescription(
//           product.title,
//           transformedFeatures,
//           sanitizeDescription(product.originalDescription), // 元の説明文を使用
//           product.specifications // specificationsを渡す
//         );
//         return {
//           ...product,
//           editableGeneratedFeatures: transformedFeatures,
//           updatedDescription,
//         };
//       }
//       return product;
//     });
//     setProducts(updatedProducts);
//   };

//   // LowCostAIGeneratorの機能を直接使用
//   const handleLowCostAIGeneration = async () => {
//     if (!apiKey) {
//       setError('OpenAI APIキーが設定されていません');
//       setSnackbarMessage('OpenAI APIキーが必要です');
//       setSnackbarSeverity('error');
//       setSnackbarOpen(true);
//       return;
//     }

//     if (selectedProducts.length === 0) {
//       setError('少なくとも一つの商品を選択してください');
//       setSnackbarMessage('商品が選択されていません');
//       setSnackbarSeverity('warning');
//       setSnackbarOpen(true);
//       return;
//     }

//     setLowCostLoading(true);
//     setError('');
//     setMessage('');
//     setProgress(0);

//     try {
//       // generateLowCostFeatures を直接呼び出します
//       await generateLowCostFeatures({
//         products,
//         setProducts,
//         selectedProducts,
//         apiKey,
//         setSnackbarMessage,
//         setSnackbarSeverity,
//         setSnackbarOpen,
//         setError,
//         setMessage,
//         setProgress,
//         queue,
//         selectedTemplate,
//         applyCustomTransformations,
//         sanitizeDescription,
//       });
//       setShowPreviewDialog(true);
//     } catch (error) {
//       console.error('低コストAI生成エラー:', error);
//       setError(`低コストAI生成に失敗しました: ${error.message}`);
//       setSnackbarMessage(`エラー: ${error.message}`);
//       setSnackbarSeverity('error');
//       setSnackbarOpen(true);
//     } finally {
//       setLowCostLoading(false);
//     }
//   };

//   return (
//     <Paper
//       elevation={3}
//       sx={{
//         p: 4,
//         mt: 1,
//         mb: 1,
//         maxWidth: '100%',
//         margin: '0 auto',
//         overflow: 'hidden',
//       }}
//     >
//       <Box sx={{ maxWidth: '1600px', margin: '0 auto' }}>
//         {/* ヘッダーセクション */}
//         <Grid container spacing={3} alignItems="center">
//           <Grid item xs={12} md={6}>
//             <Typography variant="h5" gutterBottom>
//               AIを活用した商品説明生成
//             </Typography>
//             <Typography variant="body1" paragraph>
//               日本語の商品説明から英語の商品特徴を自動生成します。
//             </Typography>
//           </Grid>
//           <Grid item xs={12} md={6}>
//             <Typography variant="subtitle1" gutterBottom>
//               主な機能：
//             </Typography>
//             <Grid container spacing={1}>
//               {[
//                 '日本語の商品説明から英語の商品特徴を抽出',
//                 'デザインテンプレートからデザインを選択',
//                 '複数の商品を一括で処理',
//                 '説明のプレビューと編集',
//               ].map((feature, index) => (
//                 <Grid item xs={6} key={index}>
//                   <Typography variant="body2">• {feature}</Typography>
//                 </Grid>
//               ))}
//             </Grid>
//           </Grid>
//         </Grid>

//         {/* アクションボタンセクション */}
//         <Box sx={{ mt: 3, display: 'flex', gap: '10px', justifyContent: 'center' }}>
//           <Button variant="contained" color="primary" onClick={handleOpen} startIcon={<AddIcon />}>
//             商品説明をAIで生成する
//           </Button>
//           <Button variant="outlined" color="primary" onClick={clearCache} startIcon={<CachedIcon />}>
//             キャッシュをクリア
//           </Button>
//         </Box>

//         {/* ダイアログセクション */}
//         <Dialog open={open} onClose={handleClose} fullScreen>
//           <DialogTitle>AIで商品説明を生成する</DialogTitle>
//           <DialogContent>
//             {/* レイアウトを左右に分割 */}
//             <Grid container spacing={2}>
//               {/* 左側のセクション */}
//               <Grid
//                 item
//                 xs={12}
//                 md={3}
//                 sx={{
//                   maxHeight: 'calc(100vh - 32px)',
//                   overflowY: 'auto',
//                 }}
//               >
//                 {/* OptionsPanel をここで使用 */}
//                 <OptionsPanel
//                   selectedFilters={selectedFilters}
//                   handleFilterChange={handleFilterChange}
//                   customFilterInput={customFilterInput}
//                   setCustomFilterInput={setCustomFilterInput}
//                   customFilters={customFilters}
//                   handleAddCustomFilter={handleAddCustomFilter}
//                   handleDeleteCustomFilter={handleDeleteCustomFilter}
//                   sentencesToDelete={sentencesToDelete}
//                   setSentencesToDelete={setSentencesToDelete}
//                   wordsToReplace={wordsToReplace}
//                   setWordsToReplace={setWordsToReplace}
//                 />

//                 {/* 選択オプション */}
//                 <Grid container spacing={2} alignItems="center" style={{ marginTop: '20px' }}>
//                   <Grid item xs={12}>
//                     <FormControlLabel
//                       control={
//                         <Checkbox
//                           checked={selectedProducts.length === products.length}
//                           onChange={handleSelectAll}
//                           indeterminate={
//                             selectedProducts.length > 0 && selectedProducts.length < products.length
//                           }
//                         />
//                       }
//                       label="全商品の選択"
//                     />
//                   </Grid>
//                   <Grid item xs={12}>
//                     <FormControlLabel
//                       control={
//                         <Checkbox
//                           checked={currentProducts.every((product) => product.selected)}
//                           onChange={handleSelectAllOnPage}
//                           indeterminate={
//                             currentProducts.some((product) => product.selected) &&
//                             !currentProducts.every((product) => product.selected)
//                           }
//                         />
//                       }
//                       label="表示されている商品の一括選択"
//                     />
//                   </Grid>

//                   <Grid item xs={12}>
//                     <FormControl fullWidth>
//                       <InputLabel>デザインテンプレートを選択</InputLabel>
//                       <Select
//                         value={selectedTemplate}
//                         onChange={(e) => setSelectedTemplate(e.target.value)}
//                         fullWidth
//                         label="デザインテンプレートを選択"
//                       >
//                         {Object.entries(designTemplates).map(([key, template]) => (
//                           <MenuItem key={key} value={key}>
//                             {template.name}
//                           </MenuItem>
//                         ))}
//                       </Select>
//                     </FormControl>
//                   </Grid>

//                   {/* 表示件数選択コンポーネントを追加 */}
//                   <Grid item xs={12}>
//                     <FormControl variant="outlined" size="small" fullWidth>
//                       <InputLabel id="items-per-page-label">表示件数</InputLabel>
//                       <Select
//                         labelId="items-per-page-label"
//                         value={itemsPerPage}
//                         onChange={(e) => {
//                           setItemsPerPage(e.target.value);
//                           setCurrentPage(1);
//                         }}
//                         label="表示件数"
//                       >
//                         {itemsPerPageOptions.map((option, index) => (
//                           <MenuItem key={index} value={option}>
//                             {option === 'All' ? '全て' : `${option}件ずつ`}
//                           </MenuItem>
//                         ))}
//                       </Select>
//                     </FormControl>
//                   </Grid>
//                 </Grid>

//                 {/* 商品リスト */}
//                 <List>
//                   {currentProducts.map((product) => (
//                     <ListItem
//                       key={product.id}
//                       button
//                       onClick={() => handleFocusProduct(product.id)}
//                       selected={focusedProduct === product.id}
//                     >
//                       <ListItemIcon>
//                         <Checkbox
//                           edge="start"
//                           checked={product.selected}
//                           tabIndex={-1}
//                           disableRipple
//                           onChange={() => handleSelectProduct(product.id)}
//                           onClick={(e) => e.stopPropagation()}
//                         />
//                       </ListItemIcon>
//                       <ListItemText
//                         primary={product.title}
//                         secondary={product.isUpdated ? '更新済み' : ''}
//                       />
//                       <ListItemSecondaryAction>
//                         <IconButton edge="end" onClick={() => handlePreviewOpen(product)}>
//                           <VisibilityIcon />
//                         </IconButton>
//                       </ListItemSecondaryAction>
//                     </ListItem>
//                   ))}
//                 </List>

//                 {/* ページネーションコンポーネント */}
//                 {itemsPerPage !== 'All' && (
//                   <Pagination
//                     count={Math.ceil(products.length / itemsPerPage)}
//                     page={currentPage}
//                     onChange={(event, value) => setCurrentPage(value)}
//                     color="primary"
//                     style={{ marginTop: '20px', display: 'flex', justifyContent: 'center' }}
//                   />
//                 )}
//               </Grid>

//               {/* 右側のセクション */}
//               <Grid
//                 item
//                 xs={12}
//                 md={9}
//                 sx={{
//                   maxHeight: 'calc(100vh - 32px)',
//                   overflowY: 'auto',
//                 }}
//               >
//                 {/* アクションボタン */}
//                 <Grid container spacing={2} style={{ marginBottom: '20px' }}>
//                   <Grid item>
//                     <Button
//                       variant="contained"
//                       color="primary"
//                       onClick={generateProductFeatures}
//                       disabled={loading || !apiKey || selectedProducts.length === 0}
//                     >
//                       AIで商品説明を生成する
//                     </Button>
//                   </Grid>
//                   {/* 変換を適用するボタンを追加 */}
//                   <Grid item>
//                     <Button
//                       variant="contained"
//                       color="primary"
//                       onClick={applyTransformationsToSelectedProducts}
//                       disabled={loading || selectedProducts.length === 0}
//                     >
//                       変換を適用
//                     </Button>
//                   </Grid>

//                   <Grid item>
//                     <Button
//                       variant="contained"
//                       color="secondary"
//                       onClick={applyUpdatedDescriptions}
//                       disabled={loading || selectedProducts.length === 0}
//                     >
//                       商品説明をデータに更新する
//                     </Button>
//                   </Grid>
//                   {/* 新しく追加する低コストAI生成ボタン */}
//                   <Grid item>
//                     <Button
//                       variant="contained"
//                       color="primary"
//                       onClick={handleLowCostAIGeneration}
//                       startIcon={<MoneyOffIcon />}
//                       disabled={lowCostLoading || !apiKey || selectedProducts.length === 0}
//                     >
//                       低コストAI生成
//                     </Button>
//                   </Grid>
//                   <Grid item>
//                     <Button
//                       variant="contained"
//                       color="success"
//                       onClick={() => setCustomPromptDialogOpen(true)}
//                       startIcon={<EditIcon />}
//                       disabled={loading || selectedProducts.length === 0}
//                     >
//                       カスタムプロンプトで生成
//                     </Button>
//                   </Grid>
//                 </Grid>

//                 {/* 進捗状況表示 */}
//                 {(loading || lowCostLoading) && (
//                   <Box sx={{ width: '100%', mb: 2 }}>
//                     <LinearProgress variant="determinate" value={progress} />
//                     <Typography variant="body2" color="text.secondary" align="center">
//                       {`${Math.round(progress)}%`}
//                     </Typography>
//                   </Box>
//                 )}

//                 {/* エラーメッセージ */}
//                 {error && (
//                   <Typography color="error" style={{ marginTop: '20px' }}>
//                     {error}
//                   </Typography>
//                 )}
//                 {/* 成功メッセージ */}
//                 {message && (
//                   <Typography color="primary" style={{ marginTop: '20px' }}>
//                     {message}
//                   </Typography>
//                 )}

//                 {/* 選択された商品の詳細情報 */}
//                 {focusedProduct !== null ? (
//                   (() => {
//                     const product = products.find((p) => p.id === focusedProduct);
//                     return (
//                       <Grid container spacing={2}>
//                         <Grid item xs={12}>
//                           <Typography variant="h6">{product.title}</Typography>
//                         </Grid>
//                         {/* 編集可能なjpDescフィールド */}
//                         <Grid item xs={12}>
//                           <Typography variant="subtitle1">Japanese Description (Editable)</Typography>
//                           <TextField
//                             multiline
//                             rows={4}
//                             fullWidth
//                             variant="outlined"
//                             value={product.editableJpDesc}
//                             onChange={(e) => handleEditJpDesc(product.id, e.target.value)}
//                           />
//                         </Grid>

//                         <Grid item xs={12}>
//                           <Typography variant="subtitle1">Final Description (Editable)</Typography>
//                           <TextField
//                             multiline
//                             rows={10}
//                             fullWidth
//                             variant="outlined"
//                             value={
//                               product.updatedDescription ||
//                               product.editableDescription ||
//                               product.description
//                             }
//                             onChange={(e) => handleEditDescription(product.id, e.target.value)}
//                           />
//                         </Grid>

//                         {/* 生成されたFeatures */}
//                         {product.generatedFeatures && (
//                           <Grid item xs={12}>
//                             <Typography variant="subtitle1">Generated Features (Editable)</Typography>
//                             <TextField
//                               multiline
//                               rows={8}
//                               fullWidth
//                               variant="outlined"
//                               value={product.editableGeneratedFeatures}
//                               onChange={(e) => handleEditFeatures(product.id, e.target.value)}
//                             />
//                           </Grid>
//                         )}
//                         {/* 更新されたDescription */}
//                         {product.updatedDescription && (
//                           <>
//                             <Grid item xs={12}>
//                               <Typography variant="subtitle1">Updated Description</Typography>
//                               <div
//                                 dangerouslySetInnerHTML={{ __html: product.updatedDescription }}
//                               />
//                             </Grid>
//                             {/* DescriptionValidator を追加 */}
//                             <Grid item xs={12}>
//                               <DescriptionValidator
//                                 htmlContent={product.updatedDescription || product.description}
//                                 onUpdate={(updatedHtml) => {
//                                   // 修正後のHTMLで商品データを更新
//                                   setProducts((prevProducts) =>
//                                     prevProducts.map((p) =>
//                                       p.id === product.id ? { ...p, updatedDescription: updatedHtml } : p
//                                     )
//                                   );
//                                 }}
//                               />
//                             </Grid>
//                           </>
//                         )}
//                       </Grid>
//                     );
//                   })()
//                 ) : (
//                   <Typography variant="body1">商品を選択してください</Typography>
//                 )}
//               </Grid>
//             </Grid>
//           </DialogContent>
//           <DialogActions>
//             <Button onClick={handleClose}>閉じる</Button>
//           </DialogActions>
//         </Dialog>

//         {/* カスタムプロンプトダイアログを追加 */}
//         <DescriptionCustomPrompt
//           open={customPromptDialogOpen}
//           handleClose={() => setCustomPromptDialogOpen(false)}
//           products={products}
//           setProducts={setProducts}
//           selectedProducts={selectedProducts}
//           apiKey={apiKey}
//           setSnackbarMessage={setSnackbarMessage}
//           setSnackbarSeverity={setSnackbarSeverity}
//           setSnackbarOpen={setSnackbarOpen}
//           loading={loading}
//           setLoading={setLoading}
//           setError={setError}
//           setMessage={setMessage}
//           setProgress={setProgress}
//           queue={queue}
//           selectedTemplate={selectedTemplate}
//           designTemplates={designTemplates}
//           applyCustomTransformations={applyCustomTransformations}
//           sanitizeDescription={sanitizeDescription}
//           progress={progress}
//         />

//         {/* プレビューダイアログ */}
//         <Dialog open={showPreviewDialog} onClose={handlePreviewClose} maxWidth="md" fullWidth>
//           <DialogTitle>eBay Preview</DialogTitle>
//           <DialogContent>
//             {previewProduct ? (
//               <div dangerouslySetInnerHTML={{ __html: previewProduct.updatedDescription }} />
//             ) : (
//               '商品説明の生成が完了しました'
//             )}
//           </DialogContent>
//           <DialogActions>
//             <Button onClick={handlePreviewClose}>閉じる</Button>
//           </DialogActions>
//         </Dialog>

//         {/* スナックバー通知 */}
//         <Snackbar
//           anchorOrigin={{
//             vertical: 'bottom',
//             horizontal: 'left',
//           }}
//           open={snackbarOpen}
//           autoHideDuration={6000}
//           onClose={() => setSnackbarOpen(false)}
//         >
//           <Alert onClose={() => setSnackbarOpen(false)} severity={snackbarSeverity} sx={{ width: '100%' }}>
//             {snackbarMessage}
//           </Alert>
//         </Snackbar>
//       </Box>
//     </Paper>
//   );
// };

// export default OpenAIDescriptionGenerator;






// // OpenAIDescriptionGenerator.js

// import React, { useState, useEffect, useCallback, useMemo } from 'react';
// import axios from 'axios';
// import {
//   Button,
//   Dialog,
//   DialogActions,
//   DialogContent,
//   DialogTitle,
//   Typography,
//   TextField,
//   Checkbox,
//   FormControlLabel,
//   List,
//   ListItem,
//   ListItemText,
//   ListItemSecondaryAction,
//   IconButton,
//   Paper,
//   Grid,
//   Snackbar,
//   Alert,
//   Select,
//   MenuItem,
//   Box,
//   LinearProgress,
//   InputLabel,
//   FormControl,
//   ListItemIcon,
//   Pagination,
// } from '@mui/material';
// import VisibilityIcon from '@mui/icons-material/Visibility';
// import AddIcon from '@mui/icons-material/Add';
// import CachedIcon from '@mui/icons-material/Cached';
// import EditIcon from '@mui/icons-material/Edit';
// import designTemplates from './designTemplates';
// import DescriptionValidator from './DescriptionValidator';
// import PQueue from 'p-queue';
// import DescriptionCustomPrompt from './DescriptionCustomPrompt';
// import OptionsPanel from './OptionsPanel';
// import { applyFilters } from './filters';

// const BATCH_SIZE = 5;
// const INITIAL_RETRY_DELAY = 1000;
// const MAX_RETRIES = 5;
// const CONCURRENCY = 2;
// const INTERVAL = 1000;
// const INTERVAL_CAP = 5;

// const OpenAIDescriptionGenerator = ({ data, setData, apiKey }) => {
//   const [open, setOpen] = useState(false);
//   const [loading, setLoading] = useState(false);
//   const [error, setError] = useState('');
//   const [message, setMessage] = useState('');
//   const [products, setProducts] = useState([]);
//   const [selectedProducts, setSelectedProducts] = useState([]);
//   const [focusedProduct, setFocusedProduct] = useState(null);
//   const [showPreviewDialog, setShowPreviewDialog] = useState(false);
//   const [snackbarOpen, setSnackbarOpen] = useState(false);
//   const [snackbarMessage, setSnackbarMessage] = useState('');
//   const [snackbarSeverity, setSnackbarSeverity] = useState('info');
//   const [previewProduct, setPreviewProduct] = useState(null);
//   const [selectedTemplate, setSelectedTemplate] = useState('professional');
//   const [progress, setProgress] = useState(0);
//   const [customPromptDialogOpen, setCustomPromptDialogOpen] = useState(false);

//   // フィルタリングオプションのステート
//   const [selectedFilters, setSelectedFilters] = useState([]);
//   const [customFilterInput, setCustomFilterInput] = useState('');
//   const [customFilters, setCustomFilters] = useState([]);

//   // テキスト変換のステート
//   const [sentencesToDelete, setSentencesToDelete] = useState([]);
//   const [wordsToReplace, setWordsToReplace] = useState([]);

//   const queue = useMemo(
//     () =>
//       new PQueue({
//         concurrency: CONCURRENCY,
//         interval: INTERVAL,
//         intervalCap: INTERVAL_CAP,
//       }),
//     []
//   );

//   const itemsPerPageOptions = [50, 100, 'All'];
//   const [currentPage, setCurrentPage] = useState(1);
//   const [itemsPerPage, setItemsPerPage] = useState(itemsPerPageOptions[0]);

//   // ローカルストレージから生成された説明をロード
//   const loadGeneratedDescriptions = () => {
//     return JSON.parse(localStorage.getItem('generatedDescriptions')) || {};
//   };

//   // 生成された説明を保存する関数
//   const saveGeneratedDescription = (productId, description) => {
//     const existingData = JSON.parse(localStorage.getItem('generatedDescriptions')) || {};
//     existingData[productId] = description;
//     localStorage.setItem('generatedDescriptions', JSON.stringify(existingData));
//   };

//   // キャッシュをクリアする関数
//   const clearGeneratedDescriptions = () => {
//     localStorage.removeItem('generatedDescriptions');
//     setSnackbarMessage('生成済みの説明をクリアしました');
//     setSnackbarSeverity('success');
//     setSnackbarOpen(true);
//   };

//   useEffect(() => {
//     if (open) {
//       initializeProducts();
//     }
//   }, [open]);

//   const handleOpen = () => {
//     if (!data || data.length === 0) {
//       setError('データがありません。CSVファイルをインポートしてください。');
//       setSnackbarMessage('データがありません');
//       setSnackbarSeverity('error');
//       setSnackbarOpen(true);
//       return;
//     }
//     setOpen(true);
//   };

//   const handleClose = () => {
//     setOpen(false);
//     setFocusedProduct(null);
//   };

//   const initializeProducts = () => {
//     const headers = Object.keys(data[0]);
//     const titleIndex = headers.findIndex((header) => header.toLowerCase() === 'title');
//     const jpDescIndex = headers.findIndex((header) => header.toLowerCase() === 'jp_desc');
//     const descriptionIndex = headers.findIndex((header) => header.toLowerCase() === 'description');

//     if (titleIndex === -1 || jpDescIndex === -1 || descriptionIndex === -1) {
//       setError('必要な列（Title, JP_Desc, Description）が見つかりません');
//       return;
//     }

//     // C:~~で始まるカラムを取得
//     const cColumns = headers.filter((header) => header.startsWith('C:'));

//     const generatedDescriptions = loadGeneratedDescriptions();

//     const productData = data.map((row, index) => {
//       const productId = index;
//       const originalJpDesc = row['JP_Desc'] || row['jp_desc'] || '';

//       // Specificationsのためのキーと値のペアを収集
//       const specifications = {};

//       cColumns.forEach((header) => {
//         const value = row[header];
//         if (value && value !== 'NA') {
//           specifications[header] = value;
//         }
//       });

//       const filteredJpDesc = applyFilters(originalJpDesc, selectedFilters, customFilters);

//       const generatedFeatures = generatedDescriptions[productId] || '';
//       const updatedDescription = generatedFeatures
//         ? generateFormattedDescription(
//             row['Title'] || row['title'] || '',
//             generatedFeatures,
//             row['Description'] || row['description'] || '',
//             specifications // ここでspecificationsを渡します
//           )
//         : '';

//       return {
//         id: productId,
//         title: row['Title'] || row['title'] || '',
//         jpDesc: originalJpDesc,
//         editableJpDesc: filteredJpDesc,
//         originalDescription: row['Description'] || row['description'] || '',
//         description: row['Description'] || row['description'] || '',
//         generatedFeatures,
//         editableGeneratedFeatures: generatedFeatures,
//         updatedDescription,
//         selected: false,
//         isUpdated: !!generatedFeatures,
//         editableFields: {},
//         specifications, // ここでspecificationsを保存します
//       };
//     });

//     setProducts(productData);
//     setSelectedProducts([]); // 空の配列に設定
//     // setSelectedProducts(productData.map((product) => product.id));
//     setSnackbarMessage('データを正常に読み込みました');
//     setSnackbarSeverity('success');
//     setSnackbarOpen(true);
//   };

//   const handleFilterChange = useCallback(
//     (label) => {
//       setSelectedFilters((prev) =>
//         prev.includes(label) ? prev.filter((item) => item !== label) : [...prev, label]
//       );
//     },
//     []
//   );

//   const applyFiltersToProducts = useCallback(() => {
//     setProducts((prevProducts) =>
//       prevProducts.map((product) => {
//         const filteredDesc = applyFilters(product.jpDesc, selectedFilters, customFilters);
//         return { ...product, editableJpDesc: filteredDesc };
//       })
//     );
//   }, [selectedFilters, customFilters]);

//   useEffect(() => {
//     applyFiltersToProducts();
//   }, [selectedFilters, customFilters, applyFiltersToProducts]);

//   const handleAddCustomFilter = () => {
//     if (customFilterInput.trim() !== '') {
//       setCustomFilters([...customFilters, customFilterInput.trim()]);
//       setCustomFilterInput('');
//     }
//   };

//   const handleDeleteCustomFilter = (index) => {
//     const updatedFilters = [...customFilters];
//     updatedFilters.splice(index, 1);
//     setCustomFilters(updatedFilters);
//   };

//   useEffect(() => {
//     applyFiltersToProducts();
//   }, [customFilters, applyFiltersToProducts]);

//   const handleEditJpDesc = useCallback((productId, newJpDesc) => {
//     setProducts((prevProducts) =>
//       prevProducts.map((product) =>
//         product.id === productId ? { ...product, editableJpDesc: newJpDesc } : product
//       )
//     );
//   }, []);

//   const sanitizeDescription = (description) => {
//     return description.replace(/<\/?(meta|link)(.|\s)*?>/gi, '');
//   };

//   const generateProductFeatures = async () => {
//     if (!apiKey) {
//       setError('OpenAI APIキーが設定されていません');
//       setSnackbarMessage('OpenAI APIキーが必要です');
//       setSnackbarSeverity('error');
//       setSnackbarOpen(true);
//       return;
//     }

//     if (selectedProducts.length === 0) {
//       setError('少なくとも一つの商品を選択してください');
//       setSnackbarMessage('商品が選択されていません');
//       setSnackbarSeverity('warning');
//       setSnackbarOpen(true);
//       return;
//     }

//     setLoading(true);
//     setError('');
//     setMessage('');
//     setProgress(0);

//     const selectedProductsData = products.filter(
//       (product) => selectedProducts.includes(product.id) && !product.isUpdated // 未更新の商品に限定
//     );
//     let processedCount = 0;

//     if (selectedProductsData.length === 0) {
//       setLoading(false);
//       setMessage('選択された商品はすでに更新されています');
//       setSnackbarMessage('選択された商品はすでに更新されています');
//       setSnackbarSeverity('info');
//       setSnackbarOpen(true);
//       return;
//     }

//     for (let i = 0; i < selectedProductsData.length; i += BATCH_SIZE) {
//       const batch = selectedProductsData.slice(i, i + BATCH_SIZE);
//       try {
//         const updatedBatch = await processBatch(batch);
//         setProducts((prevProducts) => {
//           const newProducts = [...prevProducts];
//           updatedBatch.forEach((updatedProduct) => {
//             const index = newProducts.findIndex((p) => p.id === updatedProduct.id);
//             if (index !== -1) {
//               newProducts[index] = updatedProduct;
//             }
//           });
//           return newProducts;
//         });
//         processedCount += batch.length;
//         setProgress((processedCount / selectedProductsData.length) * 100);
//       } catch (err) {
//         console.error('バッチ処理エラー:', err);
//         setError(`バッチ処理に失敗しました: ${err.message}`);
//         setSnackbarMessage(`エラー: ${err.message}`);
//         setSnackbarSeverity('error');
//         setSnackbarOpen(true);
//         break;
//       }
//     }

//     setLoading(false);
//     setMessage('商品特徴の生成が完了しました');
//     setSnackbarMessage('商品特徴の生成が完了しました');
//     setSnackbarSeverity('success');
//     setSnackbarOpen(true);
//     setShowPreviewDialog(true);
//   };

//   const processBatch = async (batch) => {
//     const generatedDescriptions = loadGeneratedDescriptions();

//     return await Promise.all(
//       batch.map(async (product) => {
//         let generatedFeatures;
//         const productId = product.id;

//         // 生成済みかチェック
//         if (generatedDescriptions[productId]) {
//           generatedFeatures = generatedDescriptions[productId];
//         } else {
//           // APIコールを行い、説明を生成
//           generatedFeatures = await generateFeaturesWithRetry(product.editableJpDesc);
//           // 生成された説明を保存
//           saveGeneratedDescription(productId, generatedFeatures);
//         }

//         const sanitizedDescription = sanitizeDescription(product.originalDescription); // 元の説明文を使用

//         const updatedDescription = generateFormattedDescription(
//           product.title,
//           generatedFeatures,
//           sanitizedDescription,
//           product.specifications // specificationsを渡す
//         );

//         return {
//           ...product,
//           generatedFeatures,
//           editableGeneratedFeatures: generatedFeatures,
//           updatedDescription,
//           isUpdated: true, // 更新済みに設定
//         };
//       })
//     );
//   };

//   const generateFeaturesWithRetry = async (jpDesc, retryCount = 0) => {
//     try {
//       return await queue.add(() => generateFeatures(jpDesc));
//     } catch (error) {
//       if (error.response && error.response.status === 429 && retryCount < MAX_RETRIES) {
//         const retryAfter = error.response.headers['retry-after'];
//         const delay = retryAfter
//           ? parseInt(retryAfter) * 1000
//           : INITIAL_RETRY_DELAY * Math.pow(2, retryCount);
//         const jitter = Math.random() * 1000;
//         await new Promise((resolve) => setTimeout(resolve, delay + jitter));
//         return generateFeaturesWithRetry(jpDesc, retryCount + 1);
//       }
//       throw error;
//     }
//   };

//   const generateFeatures = async (jpDesc, maxTokens = 200) => {
//     const response = await axios.post(
//       'https://api.openai.com/v1/chat/completions',
//       {
//         model: 'gpt-4o-2024-08-06',
//         messages: [
//           {
//             role: 'system',
//             content: `You are eBay's AI assistant specializing in product description optimization. Create effective English product descriptions based on the given Japanese product information. Please provide accurate information to buyers, including the following elements

// 1. product description (if applicable)
//    - Brand name (if applicable)
//    - Product type
//    - Model or product name
// 2. detailed features (if applicable)
//    - Color, material, size
// 3. condition of the item (if applicable)
//    - Details of usage, scratches, stains, etc. (if used)

// Notes:
// - No content other than the above will be output.
// - Additional information is not required.
// - Do not output information related to shipping or options.
// - Use clear and concise English.
// - Use bullet points to ensure readability.
// - If information is unclear or ambiguous, omit the item.
// - Use only reliable information and do not include speculative or uncertain information
// - If there is nothing in the Japanese product description, please do not output anything in particular. Only the title is acceptable.

// Analyze the given product information and follow the guidelines above to generate the best possible product description in English. Try to provide a detailed and engaging description that will help buyers make a purchase decision. Stay within the specified token count limit.`,
//           },
//           {
//             role: 'user',
//             content: `以下の日本語の商品説明を分析し、eBayの商品説明として最適化された英語の説明を生成してください。出力は${maxTokens}トークン以内に収めてください：「${jpDesc}」`,
//           },
//         ],
//         max_tokens: maxTokens,
//         temperature: 0.1,
//       },
//       {
//         headers: {
//           Authorization: `Bearer ${apiKey}`,
//           'Content-Type': 'application/json',
//         },
//       }
//     );

//     return response.data.choices[0].message.content.trim();
//   };

//   const cleanHTML = (html) => {
//     let cleanedHtml = html.replace(/<style[^>]*>[\s\S]*?<\/style>/gi, '');
//     cleanedHtml = cleanedHtml.replace(/\sclass="[^"]*"/gi, '');
//     cleanedHtml = cleanedHtml.replace(/\s+/g, ' ').trim();
//     cleanedHtml = cleanedHtml.replace(/>\s+</g, '><');
//     return cleanedHtml;
//   };

//   const generateFormattedDescription = (title, features, description, specifications) => {
//     const template = designTemplates[selectedTemplate];
//     const html = template.generateHTML(title, features, description, specifications);
//     const cleanedHtml = cleanHTML(html);
//     return cleanedHtml;
//   };

//   const handleSelectAll = useCallback(
//     (event) => {
//       const updatedProducts = products.map((product) => ({
//         ...product,
//         selected: event.target.checked,
//       }));
//       setProducts(updatedProducts);
//       setSelectedProducts(event.target.checked ? updatedProducts.map((p) => p.id) : []);
//     },
//     [products]
//   );

//   const handleSelectProduct = useCallback(
//     (productId) => {
//       const updatedProducts = products.map((product) =>
//         product.id === productId ? { ...product, selected: !product.selected } : product
//       );
//       setProducts(updatedProducts);
//       setSelectedProducts(updatedProducts.filter((p) => p.selected).map((p) => p.id));
//     },
//     [products]
//   );

//   const handleFocusProduct = useCallback((productId) => {
//     setFocusedProduct(productId);
//   }, []);

//   const applyUpdatedDescriptions = async () => {
//     setLoading(true);
//     setError('');
//     setMessage('');

//     try {
//       const updatedData = [...data];

//       products.forEach((product) => {
//         if (product.selected && product.updatedDescription) {
//           const index = product.id;
//           updatedData[index] = {
//             ...updatedData[index],
//             Description: product.updatedDescription,
//           };
//         }
//       });

//       setData(updatedData);
//       setMessage('説明文を正常に更新しました');
//       setSnackbarMessage('説明文を正常に更新しました');
//       setSnackbarSeverity('success');
//       setSnackbarOpen(true);
//     } catch (err) {
//       console.error('説明文更新エラー:', err);
//       setError(`説明文の更新に失敗しました: ${err.message}`);
//       setSnackbarMessage(`エラー: ${err.message}`);
//       setSnackbarSeverity('error');
//       setSnackbarOpen(true);
//     } finally {
//       setLoading(false);
//     }
//   };

//   const handlePreviewOpen = (product) => {
//     setPreviewProduct(product);
//     setShowPreviewDialog(true);
//   };

//   const handlePreviewClose = () => {
//     setPreviewProduct(null);
//     setShowPreviewDialog(false);
//   };

//   const handleEditFeatures = useCallback((productId, newFeatures) => {
//     setProducts((prevProducts) =>
//       prevProducts.map((product) =>
//         product.id === productId
//           ? {
//               ...product,
//               editableGeneratedFeatures: newFeatures,
//               updatedDescription: generateFormattedDescription(
//                 product.title,
//                 newFeatures,
//                 sanitizeDescription(product.originalDescription), // 元の説明文を使用
//                 product.specifications // specificationsを渡す
//               ),
//             }
//           : product
//       )
//     );
//   }, []);

//   const clearCache = () => {
//     clearGeneratedDescriptions();
//     // 商品データをリセット
//     setProducts((prevProducts) =>
//       prevProducts.map((product) => ({
//         ...product,
//         generatedFeatures: '',
//         editableGeneratedFeatures: '',
//         updatedDescription: '',
//         isUpdated: false,
//       }))
//     );
//   };

//   const indexOfLastItem =
//     currentPage * (itemsPerPage === 'All' ? products.length : itemsPerPage);
//   const indexOfFirstItem = indexOfLastItem - (itemsPerPage === 'All' ? products.length : itemsPerPage);
//   const currentProducts = products.slice(indexOfFirstItem, indexOfLastItem);

//   const handleSelectAllOnPage = useCallback(
//     (event) => {
//       const isSelected = event.target.checked;
//       const updatedProducts = products.map((product) => {
//         if (currentProducts.some((p) => p.id === product.id)) {
//           return { ...product, selected: isSelected };
//         }
//         return product;
//       });
//       setProducts(updatedProducts);
//       setSelectedProducts(updatedProducts.filter((p) => p.selected).map((p) => p.id));
//     },
//     [products, currentProducts]
//   );

//   const handleEditDescription = (productId, newDescription) => {
//     setProducts((prevProducts) =>
//       prevProducts.map((p) =>
//         p.id === productId ? { ...p, updatedDescription: newDescription } : p
//       )
//     );
//   };

//   const applyCustomTransformations = (text) => {
//     let transformedText = text;

//     sentencesToDelete.forEach((sentence) => {
//       if (sentence.trim() !== '') {
//         const escapedSentence = sentence.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
//         const regex = new RegExp(escapedSentence, 'g');
//         transformedText = transformedText.replace(regex, '');
//       }
//     });

//     wordsToReplace.forEach(({ from, to }) => {
//       if (from.trim() !== '') {
//         const escapedFrom = from.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
//         const regex = new RegExp(escapedFrom, 'g');
//         transformedText = transformedText.replace(regex, to);
//       }
//     });

//     return transformedText;
//   };

//   const applyTransformationsToSelectedProducts = () => {
//     const updatedProducts = products.map((product) => {
//       if (product.selected) {
//         const transformedFeatures = applyCustomTransformations(product.editableGeneratedFeatures);
//         const updatedDescription = generateFormattedDescription(
//           product.title,
//           transformedFeatures,
//           sanitizeDescription(product.originalDescription), // 元の説明文を使用
//           product.specifications // specificationsを渡す
//         );
//         return {
//           ...product,
//           editableGeneratedFeatures: transformedFeatures,
//           updatedDescription,
//         };
//       }
//       return product;
//     });
//     setProducts(updatedProducts);
//   };

//   return (
//     <Paper
//       elevation={3}
//       sx={{
//         p: 4,
//         mt: 1,
//         mb: 1,
//         maxWidth: '100%',
//         margin: '0 auto',
//         overflow: 'hidden',
//       }}
//     >
//       <Box sx={{ maxWidth: '1600px', margin: '0 auto' }}>
//         {/* ヘッダーセクション */}
//         <Grid container spacing={3} alignItems="center">
//           <Grid item xs={12} md={6}>
//             <Typography variant="h5" gutterBottom>
//               AIを活用した商品説明生成
//             </Typography>
//             <Typography variant="body1" paragraph>
//               日本語の商品説明から英語の商品特徴を自動生成します。
//             </Typography>
//           </Grid>
//           <Grid item xs={12} md={6}>
//             <Typography variant="subtitle1" gutterBottom>
//               主な機能：
//             </Typography>
//             <Grid container spacing={1}>
//               {[
//                 '日本語の商品説明から英語の商品特徴を抽出',
//                 'デザインテンプレートからデザインを選択',
//                 '複数の商品を一括で処理',
//                 '説明のプレビューと編集',
//               ].map((feature, index) => (
//                 <Grid item xs={6} key={index}>
//                   <Typography variant="body2">• {feature}</Typography>
//                 </Grid>
//               ))}
//             </Grid>
//           </Grid>
//         </Grid>

//         {/* アクションボタンセクション */}
//         <Box sx={{ mt: 3, display: 'flex', gap: '10px', justifyContent: 'center' }}>
//           <Button variant="contained" color="primary" onClick={handleOpen} startIcon={<AddIcon />}>
//             商品説明をAIで生成する
//           </Button>
//           <Button variant="outlined" color="primary" onClick={clearCache} startIcon={<CachedIcon />}>
//             キャッシュをクリア
//           </Button>
//         </Box>

//         {/* ダイアログセクション */}
//         <Dialog open={open} onClose={handleClose} fullScreen>
//           <DialogTitle>AIで商品説明を生成する</DialogTitle>
//           <DialogContent>
//             {/* レイアウトを左右に分割 */}
//             <Grid container spacing={2}>
//               {/* 左側のセクション */}
//               <Grid
//                 item
//                 xs={12}
//                 md={3}
//                 sx={{
//                   maxHeight: 'calc(100vh - 32px)',
//                   overflowY: 'auto',
//                 }}
//               >
//                 {/* OptionsPanel をここで使用 */}
//                 <OptionsPanel
//                   selectedFilters={selectedFilters}
//                   handleFilterChange={handleFilterChange}
//                   customFilterInput={customFilterInput}
//                   setCustomFilterInput={setCustomFilterInput}
//                   customFilters={customFilters}
//                   handleAddCustomFilter={handleAddCustomFilter}
//                   handleDeleteCustomFilter={handleDeleteCustomFilter}
//                   sentencesToDelete={sentencesToDelete}
//                   setSentencesToDelete={setSentencesToDelete}
//                   wordsToReplace={wordsToReplace}
//                   setWordsToReplace={setWordsToReplace}
//                 />

//                 {/* 選択オプション */}
//                 <Grid container spacing={2} alignItems="center" style={{ marginTop: '20px' }}>
//                   <Grid item xs={12}>
//                     <FormControlLabel
//                       control={
//                         <Checkbox
//                           checked={selectedProducts.length === products.length}
//                           onChange={handleSelectAll}
//                           indeterminate={
//                             selectedProducts.length > 0 && selectedProducts.length < products.length
//                           }
//                         />
//                       }
//                       label="全商品の選択"
//                     />
//                   </Grid>
//                   <Grid item xs={12}>
//                     <FormControlLabel
//                       control={
//                         <Checkbox
//                           checked={currentProducts.every((product) => product.selected)}
//                           onChange={handleSelectAllOnPage}
//                           indeterminate={
//                             currentProducts.some((product) => product.selected) &&
//                             !currentProducts.every((product) => product.selected)
//                           }
//                         />
//                       }
//                       label="表示されている商品の一括選択"
//                     />
//                   </Grid>

//                   <Grid item xs={12}>
//                     <FormControl fullWidth>
//                       <InputLabel>デザインテンプレートを選択</InputLabel>
//                       <Select
//                         value={selectedTemplate}
//                         onChange={(e) => setSelectedTemplate(e.target.value)}
//                         fullWidth
//                         label="デザインテンプレートを選択"
//                       >
//                         {Object.entries(designTemplates).map(([key, template]) => (
//                           <MenuItem key={key} value={key}>
//                             {template.name}
//                           </MenuItem>
//                         ))}
//                       </Select>
//                     </FormControl>
//                   </Grid>

//                   {/* 表示件数選択コンポーネントを追加 */}
//                   <Grid item xs={12}>
//                     <FormControl variant="outlined" size="small" fullWidth>
//                       <InputLabel id="items-per-page-label">表示件数</InputLabel>
//                       <Select
//                         labelId="items-per-page-label"
//                         value={itemsPerPage}
//                         onChange={(e) => {
//                           setItemsPerPage(e.target.value);
//                           setCurrentPage(1);
//                         }}
//                         label="表示件数"
//                       >
//                         {itemsPerPageOptions.map((option, index) => (
//                           <MenuItem key={index} value={option}>
//                             {option === 'All' ? '全て' : `${option}件ずつ`}
//                           </MenuItem>
//                         ))}
//                       </Select>
//                     </FormControl>
//                   </Grid>
//                 </Grid>

//                 {/* 商品リスト */}
//                 <List>
//                   {currentProducts.map((product) => (
//                     <ListItem
//                       key={product.id}
//                       button
//                       onClick={() => handleFocusProduct(product.id)}
//                       selected={focusedProduct === product.id}
//                     >
//                       <ListItemIcon>
//                         <Checkbox
//                           edge="start"
//                           checked={product.selected}
//                           tabIndex={-1}
//                           disableRipple
//                           onChange={() => handleSelectProduct(product.id)}
//                           onClick={(e) => e.stopPropagation()}
//                         />
//                       </ListItemIcon>
//                       <ListItemText
//                         primary={product.title}
//                         secondary={product.isUpdated ? '更新済み' : ''}
//                       />
//                       <ListItemSecondaryAction>
//                         <IconButton edge="end" onClick={() => handlePreviewOpen(product)}>
//                           <VisibilityIcon />
//                         </IconButton>
//                       </ListItemSecondaryAction>
//                     </ListItem>
//                   ))}
//                 </List>

//                 {/* ページネーションコンポーネント */}
//                 {itemsPerPage !== 'All' && (
//                   <Pagination
//                     count={Math.ceil(products.length / itemsPerPage)}
//                     page={currentPage}
//                     onChange={(event, value) => setCurrentPage(value)}
//                     color="primary"
//                     style={{ marginTop: '20px', display: 'flex', justifyContent: 'center' }}
//                   />
//                 )}
//               </Grid>

//               {/* 右側のセクション */}
//               <Grid
//                 item
//                 xs={12}
//                 md={9}
//                 sx={{
//                   maxHeight: 'calc(100vh - 32px)',
//                   overflowY: 'auto',
//                 }}
//               >
//                 {/* アクションボタン */}
//                 <Grid container spacing={2} style={{ marginBottom: '20px' }}>
//                   <Grid item>
//                     <Button
//                       variant="contained"
//                       color="primary"
//                       onClick={generateProductFeatures}
//                       disabled={loading || !apiKey || selectedProducts.length === 0}
//                     >
//                       AIで商品説明を生成する
//                     </Button>
//                   </Grid>
//                   {/* 変換を適用するボタンを追加 */}
//                   <Grid item>
//                     <Button
//                       variant="contained"
//                       color="primary"
//                       onClick={applyTransformationsToSelectedProducts}
//                       disabled={loading || selectedProducts.length === 0}
//                     >
//                       変換を適用
//                     </Button>
//                   </Grid>

//                   <Grid item>
//                     <Button
//                       variant="contained"
//                       color="secondary"
//                       onClick={applyUpdatedDescriptions}
//                       disabled={loading || selectedProducts.length === 0}
//                     >
//                       商品説明をデータに更新する
//                     </Button>
//                   </Grid>
//                   <Grid item>
//                     <Button
//                       variant="contained"
//                       color="success"
//                       onClick={() => setCustomPromptDialogOpen(true)}
//                       startIcon={<EditIcon />}
//                       disabled={loading || selectedProducts.length === 0}
//                     >
//                       カスタムプロンプトで生成
//                     </Button>
//                   </Grid>
//                 </Grid>

//                 {/* 進捗状況表示 */}
//                 {loading && (
//                   <Box sx={{ width: '100%', mb: 2 }}>
//                     <LinearProgress variant="determinate" value={progress} />
//                     <Typography variant="body2" color="text.secondary" align="center">
//                       {`${Math.round(progress)}%`}
//                     </Typography>
//                   </Box>
//                 )}

//                 {/* エラーメッセージ */}
//                 {error && (
//                   <Typography color="error" style={{ marginTop: '20px' }}>
//                     {error}
//                   </Typography>
//                 )}
//                 {/* 成功メッセージ */}
//                 {message && (
//                   <Typography color="primary" style={{ marginTop: '20px' }}>
//                     {message}
//                   </Typography>
//                 )}

//                 {/* 選択された商品の詳細情報 */}
//                 {focusedProduct !== null ? (
//                   (() => {
//                     const product = products.find((p) => p.id === focusedProduct);
//                     return (
//                       <Grid container spacing={2}>
//                         <Grid item xs={12}>
//                           <Typography variant="h6">{product.title}</Typography>
//                         </Grid>
//                         {/* 編集可能なjpDescフィールド */}
//                         <Grid item xs={12}>
//                           <Typography variant="subtitle1">Japanese Description (Editable)</Typography>
//                           <TextField
//                             multiline
//                             rows={4}
//                             fullWidth
//                             variant="outlined"
//                             value={product.editableJpDesc}
//                             onChange={(e) => handleEditJpDesc(product.id, e.target.value)}
//                           />
//                         </Grid>

//                         <Grid item xs={12}>
//                           <Typography variant="subtitle1">Final Description (Editable)</Typography>
//                           <TextField
//                             multiline
//                             rows={10}
//                             fullWidth
//                             variant="outlined"
//                             value={
//                               product.updatedDescription ||
//                               product.editableDescription ||
//                               product.description
//                             }
//                             onChange={(e) => handleEditDescription(product.id, e.target.value)}
//                           />
//                         </Grid>

//                         {/* 生成されたFeatures */}
//                         {product.generatedFeatures && (
//                           <Grid item xs={12}>
//                             <Typography variant="subtitle1">Generated Features (Editable)</Typography>
//                             <TextField
//                               multiline
//                               rows={8}
//                               fullWidth
//                               variant="outlined"
//                               value={product.editableGeneratedFeatures}
//                               onChange={(e) => handleEditFeatures(product.id, e.target.value)}
//                             />
//                           </Grid>
//                         )}
//                         {/* 更新されたDescription */}
//                         {product.updatedDescription && (
//                           <>
//                             <Grid item xs={12}>
//                               <Typography variant="subtitle1">Updated Description</Typography>
//                               <div
//                                 dangerouslySetInnerHTML={{ __html: product.updatedDescription }}
//                               />
//                             </Grid>
//                             {/* DescriptionValidator を追加 */}
//                             <Grid item xs={12}>
//                               <DescriptionValidator
//                                 htmlContent={product.updatedDescription || product.description}
//                                 onUpdate={(updatedHtml) => {
//                                   // 修正後のHTMLで商品データを更新
//                                   setProducts((prevProducts) =>
//                                     prevProducts.map((p) =>
//                                       p.id === product.id ? { ...p, updatedDescription: updatedHtml } : p
//                                     )
//                                   );
//                                 }}
//                               />
//                             </Grid>
//                           </>
//                         )}
//                       </Grid>
//                     );
//                   })()
//                 ) : (
//                   <Typography variant="body1">商品を選択してください</Typography>
//                 )}
//               </Grid>
//             </Grid>
//           </DialogContent>
//           <DialogActions>
//             <Button onClick={handleClose}>閉じる</Button>
//           </DialogActions>
//         </Dialog>

//         {/* カスタムプロンプトダイアログを追加 */}
//         <DescriptionCustomPrompt
//           open={customPromptDialogOpen}
//           handleClose={() => setCustomPromptDialogOpen(false)}
//           products={products}
//           setProducts={setProducts}
//           selectedProducts={selectedProducts}
//           apiKey={apiKey}
//           setSnackbarMessage={setSnackbarMessage}
//           setSnackbarSeverity={setSnackbarSeverity}
//           setSnackbarOpen={setSnackbarOpen}
//           loading={loading}
//           setLoading={setLoading}
//           setError={setError}
//           setMessage={setMessage}
//           setProgress={setProgress}
//           queue={queue}
//           selectedTemplate={selectedTemplate}
//           designTemplates={designTemplates}
//           applyCustomTransformations={applyCustomTransformations}
//           sanitizeDescription={sanitizeDescription}
//           progress={progress}
//         />

//         {/* プレビューダイアログ */}
//         <Dialog open={showPreviewDialog} onClose={handlePreviewClose} maxWidth="md" fullWidth>
//           <DialogTitle>eBay Preview</DialogTitle>
//           <DialogContent>
//             {previewProduct ? (
//               <div dangerouslySetInnerHTML={{ __html: previewProduct.updatedDescription }} />
//             ) : (
//               '商品説明の生成が完了しました'
//             )}
//           </DialogContent>
//           <DialogActions>
//             <Button onClick={handlePreviewClose}>閉じる</Button>
//           </DialogActions>
//         </Dialog>

//         {/* スナックバー通知 */}
//         <Snackbar
//           anchorOrigin={{
//             vertical: 'bottom',
//             horizontal: 'left',
//           }}
//           open={snackbarOpen}
//           autoHideDuration={6000}
//           onClose={() => setSnackbarOpen(false)}
//         >
//           <Alert onClose={() => setSnackbarOpen(false)} severity={snackbarSeverity} sx={{ width: '100%' }}>
//             {snackbarMessage}
//           </Alert>
//         </Snackbar>
//       </Box>
//     </Paper>
//   );
// };

// export default OpenAIDescriptionGenerator;